export const defaultProps = {};
export const defaultState = {
    edit: false,
    closing_message: null,
    closingModal: false,
    attachmentModal: false,
    file: null,
    attachment: null,
    usage_history: null,
    activeTab: localStorage.getItem('wodTab') ?? '1',
    openPm: null,
    related_id: false
};
