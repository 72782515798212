// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const partService = {};

const apiUrl = baseUrl;

partService.createPart = (part) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(part)
    };

    return fetch(`${apiUrl}/part`, requestOptions).then(handleResponse);
};

partService.updatePart = (part) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(part)
    };


    return fetch(`${apiUrl}/part/` + part.id, requestOptions).then(handleResponse);
};

partService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/parts`, requestOptions).then(handleResponse);
};

partService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/part/` + id, requestOptions).then(handleResponse);
};
