export const yardConstants = {
    CREATE_YARD_REQUEST: 'YARD_CREATE_REQUEST',
    CREATE_YARD_SUCCESS: 'YARD_CREATE_SUCCESS',
    CREATE_YARD_FAILURE: 'YARD_CREATE_FAILURE',

    GET_ALL_YARDS_REQUEST: 'YARDS_GET_ALL_REQUEST',
    GET_ALL_YARDS_SUCCESS: 'YARDS_GET_ALL_SUCCESS',
    GET_ALL_YARDS_FAILURE: 'YARDS_GET_ALL_FAILURE',

    DETAIL_YARD_REQUEST: 'YARD_DETAIL_REQUEST',
    DETAIL_YARD_SUCCESS: 'YARD_DETAIL_SUCCESS',
    DETAIL_YARD_FAILURE: 'YARD_DETAIL_FAILURE',

    UPDATE_YARD_REQUEST: 'YARD_UPDATE_REQUEST',
    UPDATE_YARD_SUCCESS: 'YARD_UPDATE_SUCCESS',
    UPDATE_YARD_FAILURE: 'YARD_UPDATE_FAILURE',

    DELETE_YARD_REQUEST: 'YARD_DELETE_REQUEST',
    DELETE_YARD_SUCCESS: 'YARD_DELETE_SUCCESS',
    DELETE_YARD_FAILURE: 'YARD_DELETE_FAILURE',
};
