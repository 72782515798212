import {userConstants} from '../constants';

export const loginRequest = (user) => ({type: userConstants.LOGIN_REQUEST, user});
export const loginSuccess = (user) => ({type: userConstants.LOGIN_SUCCESS, user});
export const loginFailure = (error) => ({type: userConstants.LOGIN_FAILURE, error});
export const createUserRequest = () => ({type: userConstants.CREATE_USER_REQUEST});
export const createUserSuccess = (user) => ({type: userConstants.CREATE_USER_SUCCESS, user});
export const createUserFailure = (error) => ({type: userConstants.CREATE_USER_FAILURE, error});
export const updateUserRequest = () => ({type: userConstants.UPDATE_USER_REQUEST});
export const updateUserSuccess = (user) => ({type: userConstants.UPDATE_USER_SUCCESS, user});
export const updateUserFailure = (error) => ({type: userConstants.UPDATE_USER_FAILURE, error});
export const getAllUsersRequest = () => ({type: userConstants.GET_ALL_REQUEST});
export const getAllUsersSuccess = (users) => ({type: userConstants.GET_ALL_SUCCESS, users});
export const getAllUsersFailure = (error) => ({type: userConstants.GET_ALL_FAILURE, error});
export const getPagUsersRequest = () => ({type: userConstants.GET_PAG_USERS_REQUEST});
export const getPagUsersSuccess = (pag_users) => ({type: userConstants.GET_PAG_USERS_SUCCESS, pag_users});
export const getPagUsersFailure = (error) => ({type: userConstants.GET_PAG_USERS_FAILURE, error});
export const getLoginExceptionsRequest = () => ({type: userConstants.GET_ALL_LOGIN_EXCEPTIONS_REQUEST});
export const getLoginExceptionsSuccess = (login_exceptions) => ({type: userConstants.GET_ALL_LOGIN_EXCEPTIONS_SUCCESS, login_exceptions});
export const getLoginExceptionsFailure = (error) => ({type: userConstants.GET_ALL_LOGIN_EXCEPTIONS_FAILURE, error});
export const getUserRequest = () => ({type: userConstants.USER_DETAIL_REQUEST});
export const getUserSuccess = (user) => ({type: userConstants.USER_DETAIL_SUCCESS, user});
export const getUserFailure = (error) => ({type: userConstants.USER_DETAIL_FAILURE, error});
export const logoutRequest = () => ({type: userConstants.LOGOUT});

