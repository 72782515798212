import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TabPaneContext} from './DropdownContext';
import { mapToCssModules, tagPropType } from './utils';
const propTypes = {
    tag: tagPropType,
    activetab: PropTypes.any,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};
const defaultProps = {
    tag: 'div',
};
const TabContent = ({className, cssModule, tag: Tag, ...attributes}) => {
        const classes = mapToCssModules(classNames('bot-tab-content', className), cssModule);
        return (
            <TabPaneContext.Provider value={{activeTabId: attributes.activetab}}>
                <Tag {...attributes} className={classes} />
            </TabPaneContext.Provider>
        );
}

export default TabContent;
TabContent.propTypes = propTypes;
TabContent.defaultProps = defaultProps;
