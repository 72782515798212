/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from 'prop-types';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup} from '../../components/bot-components';
import {notEmptyText, validateNumber} from '../../helpers';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    workorder: PropTypes.object,
    unit: PropTypes.object,
    vendorList: PropTypes.array,
    onSavePart: PropTypes.func
};
let table_key_iterator = 0;

const CreatePart = (props) => {
    const [state, updateState] = React.useState({
        number: null,
        number_valid: false,
        description: null,
        description_valid: false,
        vendor_id: null,
        vendor_id_valid: false,
        unit_cost: '0.00',
        unit_cost_valid: true,
        qty: null,
        qty_valid: false,
        workorder_id: null,
        unit_id: null,
        filter: false,
        ordered: true,
        received: true
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const nRef = React.useRef();
    React.useEffect(() => {
        setState({
            unit_id: props.unit?.items?.id ?? props.workorder?.items?.unit_id,
            workorder_id: props.workorder?.items?.id
        });
    }, []);

    const table_keys = () => {
        return `woc_${table_key_iterator++}`;
    };

    const focusInput = () => {
        setTimeout(() => {
            nRef?.current?.focus();
        }, 350);
    };

    const changeNumber = async (e) => {
        const {value} = e.target;
        setState({
            number: value,
            number_valid: await notEmptyText(value)
        });
    };

    const changeDescription = async (e) => {
        const {value} = e.target;
        setState({
            description: value,
            description_valid: await notEmptyText(value)
        });
    };

    const changeVendor = async (e) => {
        const {value} = e.target;
        setState({
            vendor_id: parseInt(value, 0),
            vendor_id_valid: await validateNumber(value)
        });
    };

    const changeQty = async (e) => {
        const {value} = e.target;
        setState({
            qty: parseInt(value, 0),
            qty_valid: await validateNumber(value)
        });
    };

    const changeCost = async (e) => {
        const {value} = e.target;
        setState({
            unit_cost: parseFloat(value),
            unit_cost_valid: await validateNumber(value)
        });
    };

    const changeBool = (e) => {
        const {name, checked} = e.target;
        setState({
            [name]: checked
        });
    }

    const onSavePart = () => {
        const part = {
            number: state.number,
            description: state.description,
            vendor_id: state.vendor_id,
            unit_cost: state.unit_cost,
            qty: state.qty,
            workorder_id: null,
            unit_id: null,
            filter: state.filter,
            ordered: true,
            received: true,
            requested: true,
        };
        if (props.unit?.items && !props.workorder?.items) {
            part.unit_id = props.unit.items.id;
            part.workorder_id = null;
        } else if (props.workorder.items) {
            part.unit_id = props.workorder.items.unit_id;
            part.workorder_id = props.workorder.items.id;
        }
        props.onSavePart(part);
    };

    const {number, number_valid, description, description_valid, vendor_id, vendor_id_valid, qty, qty_valid, filter, workorder_id, unit_cost} = state;
    let canSave = description_valid && vendor_id_valid && number_valid && qty_valid;
    return (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle()} onOpened={() => focusInput()}>
            <ModalHeader toggle={() => props.toggle()}>
                Create Part for Work Order# {workorder_id}...
            </ModalHeader>
            <ModalBody>
                <Label>Part#</Label>
                <Input ref={nRef} type={'textarea'} placeholder={'Enter Part#'} value={number ? number : ''} invalid={!number_valid} onChange={(e) => changeNumber(e)}/>
                <Label>Description</Label>
                <Input type={'textarea'} value={description ? description : ''} invalid={!description_valid} placeholder={'Enter Part description'} onChange={(e) => changeDescription(e)}/>
                <Label>Cost</Label>
                <Input type={'number'} value={unit_cost ? unit_cost : ''} placeholder={'Enter Cost'} onChange={(e) => changeCost(e)}/>
                <Label>Qty</Label>
                <Input type={'number'} value={qty ? qty : ''} invalid={!qty_valid} placeholder={'Enter Qty'} onChange={(e) => changeQty(e)}/>
                <Label>Vendor</Label>
                <Input type={'select'} value={vendor_id ? vendor_id : ''} invalid={!vendor_id_valid} onChange={(e) => changeVendor(e)}>
                    <option key={table_keys()} value={null}>Select Vendor</option>
                    {props.vendorList.map(v => {return(<option key={table_keys()} value={v.value}>{v.label}</option>)})}
                </Input>
                <FormGroup check>
                    <Label check>
                        <Input name={'filter'} type={'checkbox'} checked={filter} onChange={(e) => changeBool(e)}/> Filter
                    </Label>
                </FormGroup>
                {/*<FormGroup check>*/}
                {/*    <Input name={'ordered'} type={'checkbox'} checked={ordered} onChange={(e) => this.changeBool(e)}/> Ordered*/}
                {/*</FormGroup>*/}
                {/*<FormGroup check>*/}
                {/*    <Input name={'received'} type={'checkbox'} checked={received} onChange={(e) => this.changeBool(e)}/> Received*/}
                {/*</FormGroup>*/}
            </ModalBody>
            <ModalFooter>
                <Button disabled={!canSave} color={'texts'} onClick={() => onSavePart()}>Save</Button><Button color={'texts'} onClick={() => props.toggle()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

CreatePart.propTypes = propTypes;
export default CreatePart;
