/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {_dateFormat} from '../../helpers';
import {Button, Row, Col, Card, CardHeader, CardBody, CardFooter, Input, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter} from '../../components/bot-components';
import Select from 'react-select';
import {vendorActions} from '../../network/actions';
import {taskPriorityActions, userActions} from '../../network/actions';
import {taskActions} from '../../network/actions';
import {workorderActions} from '../../network/actions/';

const TaskDetail1 = (props) => {
    const [state, updateState] = React.useState({
        selectedVendor: undefined,
        selectedMech: undefined,
        selectedPriority: undefined,
        vendor_id: undefined,
        mechanic_id: undefined,
        complete: undefined,
        vendor: undefined,
        description: undefined,
        completed_by: undefined,
        completed_on: undefined,
        arrived_at_vendor: undefined,
        assigned_to_mechanic: undefined,
        est_complete_date: undefined,
        picked_up_at: undefined,
        edit_comp_date: false,
        edit_priority: false,
        gps_location: undefined,
        task_priority_id: undefined,
        task_priority: undefined,
        modal: false,
        closing_message: null,
        modal_title: ''
    });
    const setState = (newState) => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    React.useEffect(() => {
        if (!props.task.items && !props.task.loading) {
            const t_id = localStorage.getItem('task_id');
            const w_id = localStorage.getItem('workorder_id');
            t_id && props.getTask(t_id);
            w_id && props.getMyWorkorder(w_id);
        }
        !props.vendors?.items && props.getVendors();
        !props.mechanics?.items && props.getMechanics();
        !props.task_priorities?.items && props.getTaskPriorities();

    }, []);

    React.useEffect(() => {
        setState({
            complete: props.task.items?.complete,
            vendor: props.task.items?.vendor,
            description: props.task.items?.description,
            arrived_at_vendor: props.task.items?.arrived_at_vendor,
            assigned_to_mechanic: props.task.items?.assigned_to_mechanic,
            est_complete_date: props.task.items?.est_complete_date,
            picked_up_at: props.task.items?.picked_up_at,
            task_priority_id: props.task.items?.task_priority_id,
            task_priority: props.task.items?.task_priority,
            selectedPriority: props.task.items?.task_priority_id,
            completed_by: props.task.items?.completed_by?.name,
            mechanic_id: props.task.items?.mechanic?.[0]?.id,
            vendor_id: props.task.items?.vendors?.[0]?.id,
            completed_on: _dateFormat(props.task.items?.completed_on),
            gps_location: props.task.items?.gps_location,
        });
    }, [props.task, props.workorder]);

    const setVendor = () => {
        const Task = {
            id: props.task.items.id,
            vendor: state.vendor_id !== null,
            vendor_id: state.vendor_id,
            mechanic_id: state.mechanic_id
        };
        props.isModal ? props.updateTask(Task) : props.assignToVendor(Task);
        setState({
            vendor_id: null,
        });
    };

    const setPriority = () => {
        if (state.selectedPriority) {
            const Task = {
                id: props.task.items.id,
                task_priority_id: state.selectedPriority.value,
            };
            setState({
                edit_priority: false,
            });
            props.updateTask(Task);
        }
    };

    const setMech = () => {
        let assigned_to_mechanic = new Date();
        if (state.assigned_to_mechanic !== undefined) {
            assigned_to_mechanic = state.assigned_to_mechanic;
        }
        const Task = {
            id: props.task.items.id,
            mechanic_id: state.mechanic_id,
            vendor: state.vendor_id !== null,
            vendor_id: state.vendor_id,
            assigned_to_mechanic: state.mechanic_id !== null ? assigned_to_mechanic : null,
        };
        props.isModal ? props.updateTask(Task) : props.assignToMechanic(Task);
        setState({
                mechanic_id: null,
        });
    };

    const complete_task = (value) => {
        if (props.task.items && value !== undefined) {
            setState({
                complete: value,
            });
            const Task = {
                id: props.task.items.id,
                complete: value,
                closing_message: state.closing_message,
            };
            setState({
                completed_on: undefined,
            });
            props.completeTask(Task);
            if (state.modal) {
                toggle();
            }
        }
    };

    const toggle = () => {
        setState({
            modal: !state.modal,
        })
    };

    const closeTask = () => {
        setState({
            modal_title: 'Close Task'
        });
        toggle();
    };

    const reopenTask = () => {
        setState({
            modal_title: 'Reopen Task'
        });
        toggle();
    };

    const setCompletionDate = (task) => {
        if (props.task.items && task) {
            props.completeTask(task);
        }
    };

        const {task, mechList, vendorList, workorder, arrivedAtVendor, priorityList} = props;
        const {complete, completed_by, completed_on, est_complete_date, edit_comp_date,
            task_priority, edit_priority, selectedPriority, mechanic_id, vendor_id} = state;

        function changeProps(e) {
            const {value, name} = e.target;
            if (name === 'est_complete_date') {
                setState({
                    edit_comp_date: false,
                    [name]: value,
                });
                const Task = {
                    id: task.items.id,
                    [name]: value,
                };
                setCompletionDate(Task);
            } else {
                setState({
                    [name]: value,
                });
            }
        }

        return (
            <div>
                <Row>
                    <Col xs={'12'} sm={'12'}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        {complete ? <Button color={'danger'} onClick={() => {
                                            reopenTask()
                                        }}>Reopen Task</Button> : <Button color={'danger'} onClick={() => {
                                            closeTask();
                                        }}>Close Task</Button>}
                                        <Modal isOpen={state.modal} toggle={() => {toggle()}}
                                               className={props.className}>
                                            <ModalHeader toggle={() => {toggle()}}>{state.modal_title}</ModalHeader>
                                            <ModalBody>
                                                <label style={{textAlign: 'center'}} htmlFor={'closing_message'}>Notes</label>
                                                <Input name={'closing_message'} onChange={changeProps.bind(this)} type="textarea" id={'closing_message'}/>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button outline color={'success'} onClick={() => {
                                                    complete_task(state.modal_title === 'Close Task');
                                                }}>Submit</Button>
                                                <Button outline color={'warning'} onClick={() => {
                                                    toggle();
                                                }}>Cancel</Button>
                                            </ModalFooter>
                                        </Modal>
                                    </Col>
                                    <Col>
                                        <h3 style={{textAlign: 'center'}}>Task Info for
                                            workorder# {workorder.items && workorder.items.id}</h3>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={'12'}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <h3>{task.items ? task.items.description : ''}</h3>
                                                </Row>
                                                <hr/>
                                                <Row>
                                                    <Col sm={'6'}>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Status:</label>
                                                            <label className={'task_detail-field'}>{task.items ? (complete ? ' Complete' : ' Incomplete') : ''}</label>
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Priority:</label>
                                                            {!edit_priority ?
                                                                <label className={'task_detail-field'} onClick={() => {setState({edit_priority: true})}}>{task.items ? task_priority : ''}
                                                                <i style={{paddingLeft: 5, backgroundColor: 'var(--thrintary)'}} className={'fa fa-question-circle'} id={'task_priority_tool'}/>
                                                                <UncontrolledTooltip placement={'right'} target={'task_priority_tool'}>Click here to edit priority</UncontrolledTooltip>
                                                                </label> :
                                                                <div>
                                                                    <Select placeholder={'Select Priority'}
                                                                            options={priorityList}
                                                                            matchProp={'label'}
                                                                            onChange={(selectedPriority) => setState({selectedPriority: selectedPriority})}
                                                                            value={selectedPriority}
                                                                    />
                                                                    <Button color={'warning'} onClick={() => {setState({edit_priority: false})}}>Cancel</Button><Button color={'success'} onClick={setPriority}>Save</Button>
                                                                </div>
                                                            }
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Provider: </label>
                                                            <label className={'task_detail-field'}>{task.items ? (task.items.vendors && task.items.vendors.length > 0 ? task.items.vendors[0].name : '') : ''}</label>
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Mechanic:</label>
                                                            <label className={'task_detail-field'}>{task.items ? (task.items.mechanic && task.items.mechanic.length > 0 ? task.items.mechanic[0].name : '') : ''}</label>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Est. Completion Date: </label>
                                                            {!edit_comp_date ?
                                                                <label className={'task_detail-field'} onClick={() => setState({edit_comp_date: true})}>{task.items ? _dateFormat(est_complete_date) : ''}
                                                                <i className={'fa fa-question-circle'} id={'est_comp_date_tool'}/>
                                                                <UncontrolledTooltip placement={'right'} target={'est_comp_date_tool'}>Click here to edit date</UncontrolledTooltip>
                                                                </label> :
                                                                <div>
                                                                    <Input type={'date'} name={'est_complete_date'} onChange={changeProps.bind(this)}/>
                                                                    <Button color={'warning'} onClick={() => {setState({edit_comp_date: false})}}>Cancel</Button><Button color={'success'} onClick={setCompletionDate}>Save</Button>
                                                                </div>

                                                            }
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>GPS Location: </label>
                                                            {task.items && task.items.gps_location ?
                                                                <lable className={'task_detail-field'}><a name="gps_location" href={`https://maps.google.com/?q=${task.items.gps_location}`} target={'_blank'} rel={'noopener noreferrer'}>{workorder.items.gps_location}</a></lable> : null
                                                            }
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Arrived at Provider:</label>
                                                            <label className={'task_detail-field'}>{task.items && (task.items.vendors && task.items.vendors.length > 0 && _dateFormat(task.items['arrived_at_vendor']))}</label>
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Picked Up:</label>
                                                            <label className={'task_detail-field'}>{task.items && (task.items.vendors && task.items.vendors.length > 0 && _dateFormat(task.items['picked_up_at']))}</label>
                                                        </Row>
                                                        <Row className={'task_detail-row'}>
                                                            <label className={'task_detail-label'}>Assigned On:</label>
                                                            <label className={'task_detail-field'}>{task.items && (task.items.mechanic && task.items.mechanic.length > 0 && _dateFormat(task.items['assigned_to_mechanic']))}</label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                {task.items && (complete &&
                                                    <div>
                                                        <label style={{fontSize: 18, fontWeight: 'bold'}}>Completed By: </label>
                                                        <label style={{fontSize: 18, paddingLeft: 5}}>{completed_by}</label>
                                                        <label style={{fontWeight: 'bold', fontSize: 18, paddingLeft: 20}}>On:</label>
                                                        <label style={{fontSize: 18, paddingLeft: 5}}>{_dateFormat(completed_on)}</label>
                                                    </div>
                                                )}
                                            </CardFooter>
                                        </Card>
                                        <Row>
                                            {task.items && (task.items.vendor && <Col>
                                                <Input type={'date'} name={'arrived_at_vendor'}
                                                       onChange={changeProps.bind(this)}/>
                                                <Button color={'info'} onClick={() => {
                                                    arrivedAtVendor({
                                                        id: task.items.id,
                                                        arrived_at_vendor: state.arrived_at_vendor
                                                    })
                                                }}>Arrived At Service Provider</Button>
                                            </Col>)}
                                            {task.items && (task.items.vendor && <Col>
                                                <Input type={'date'} name={'picked_up_at'}
                                                       onChange={changeProps.bind(this)}/>
                                                <Button color={'info'} onClick={() => {
                                                    props.pickupUnit({
                                                        id: task.items.id,
                                                        picked_up_at: state.picked_up_at
                                                    })
                                                }}>Picked Up</Button>
                                            </Col>)}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={'6'}>
                                        <Card>
                                            <CardHeader>
                                                <h5 style={{textAlign: 'center'}}>Assign to Service Provider</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Col>
                                                    <Select options={vendorList}
                                                            placeholder={'Please select a service provider from the list'}
                                                            matchProp={'label'}
                                                            onChange={(selectedVendor) => {setState({vendor_id: selectedVendor ? selectedVendor.value : null, vendor: !!selectedVendor, mechanic_id: selectedVendor ? null : state.mechanic_id})}}
                                                            value={vendor_id ? vendor_id : null}/>
                                                    <Button color={'info'}
                                                            onClick={() => setVendor()}>Save</Button><Button
                                                    color={'warning'}>Cancel</Button>
                                                </Col>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm={'6'}>
                                        <Card>
                                            <CardHeader>
                                                <h5 style={{textAlign: 'center'}}>Assign to Mechanic</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Col>
                                                    <Select options={mechList}
                                                            placeholder={'Please select a mechanic from the list'}
                                                            matchProp={'label'}
                                                            onChange={(selectedMechanic) => {setState({mechanic_id: selectedMechanic ? selectedMechanic.value : null, vendor: selectedMechanic ? false : state.vendor, vendor_id: selectedMechanic ? null : state.vendor_id})}}
                                                            value={mechanic_id ? mechanic_id : null}/>
                                                    <Input type={'date'} name={'assigned_to_mechanic'}
                                                           onChange={changeProps.bind(this)}/>
                                                    <Button color={'info'}
                                                            onClick={() => setMech()}>Save</Button><Button
                                                    color={'warning'}>Cancel</Button>
                                                </Col>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
};

function mapStateToProps(state) {
    const {task, mechanics, vendors, workorder, task_priorities} = state;
    let mechList = [];
    let vendorList = [];
    let priorityList = [];

    if (task_priorities.items) {
        task_priorities.items.map(priority => {
            return priorityList.push({label: priority.priority, value: priority.id});
        })
    }

    if (vendors.items) {
        vendors.items.map(vendor => {
            return vendorList.push({label: vendor.name, value: vendor.id});
        });
    }

    if (mechanics.items) {
        mechanics.items.map(mechanic => {
            return mechList.push({label: mechanic.name, value: mechanic.id});
        });
    }

    return {task, mechanics, vendors, mechList, vendorList, workorder, priorityList}
}
function mapDispatchToProps(dispatch) {
    return {
        getMechanics: () => dispatch(userActions.getMechanics()),
        getVendors: () => dispatch(vendorActions.getAll()),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        completeTask: (task) => dispatch(taskActions.updateTask(task)),
        assignToVendor: (task) => dispatch(taskActions.assignToVendor(task)),
        assignToMechanic: (task) => dispatch(taskActions.assignToMechanic(task)),
        arrivedAtVendor: (task) => dispatch(taskActions.arrivedAtVendor(task)),
        pickupUnit: (task) => dispatch(taskActions.pickup(task)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail1);
