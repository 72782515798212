/*Handles all actions for the fault model on the server*/
import {faultConstants} from '../constants';
import {faultService} from '../services';

export const faultActions = {};
/*Get full list of faults request.*/
faultActions.getFaults = () => {
    return dispatch => {
        dispatch(request());

        faultService.getAll()
            .then(
                faults => {
                    dispatch(success(faults))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: faultConstants.GET_ALL_FAULTS_REQUEST}
    }

    function success(faults) {
        return {type: faultConstants.GET_ALL_FAULTS_SUCCESS, faults}
    }

    function failure(error) {
        return {type: faultConstants.GET_ALL_FAULTS_FAILURE, error}
    }

    //</editor-fold>
};
/*Get list of unassigned faults request.*/
faultActions.getUnassigned = () => {
    return dispatch => {
        dispatch(request());

        faultService.getUnassigned()
            .then(
                unassigned_faults => {
                    dispatch(success(unassigned_faults))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: faultConstants.GET_UNASSIGNED_FAULTS_REQUEST}
    }

    function success(unassigned_faults) {
        return {type: faultConstants.GET_UNASSIGNED_FAULTS_SUCCESS, unassigned_faults}
    }

    function failure(error) {
        return {type: faultConstants.GET_UNASSIGNED_FAULTS_FAILURE, error}
    }

    //</editor-fold>
};
/*Get fault detail request.*/
faultActions.getFault = (id) => {
    return dispatch => {
        dispatch(request());

        faultService.getDetail(id)
            .then(
                fault => {
                    dispatch(success(fault))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: faultConstants.DETAIL_FAULT_REQUEST}
    }

    function success(fault) {
        return {type: faultConstants.DETAIL_FAULT_SUCCESS, fault}
    }

    function failure(error) {
        return {type: faultConstants.DETAIL_FAULT_FAILURE, error}
    }

    //</editor-fold>
};
/*Create new fault request*/
faultActions.createFault = (fault) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after fault is created.
        faultService.createFault(fault)
            .then(
                fault => {
                    dispatch(success(fault));
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: faultConstants.CREATE_FAULT_REQUEST}
    }

    function success(fault) {
        return {type: faultConstants.CREATE_FAULT_SUCCESS, fault}
    }

    function failure(error) {
        return {type: faultConstants.CREATE_FAULT_FAILURE, error}
    }

    //</editor-fold>
};
/*Update fault request.*/
faultActions.updateFault = (fault) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after fault is updated.
        faultService.updateFault(fault)
            .then(
                fault => {
                    dispatch(success(fault));
                    if (fault) {
                        dispatch(faultActions.getFault(fault.id));
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: faultConstants.UPDATE_FAULT_REQUEST}
    }

    function success(fault) {
        return {type: faultConstants.UPDATE_FAULT_SUCCESS, fault}
    }

    function failure(error) {
        return {type: faultConstants.UPDATE_FAULT_FAILURE, error}
    }

    //</editor-fold>
};
