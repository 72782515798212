import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TabPaneContext} from './DropdownContext';
import { mapToCssModules, tagPropType } from './utils';
const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    tabId: PropTypes.any,
};
const defaultProps = {
    tag: 'div',
};
const contextTypes = {
    activeTabId: PropTypes.any
};
const TabPane = ({ className, cssModule, tabId, tag: Tag, ...attributes }) => {
    return (
        <TabPaneContext.Consumer>
            {({activeTabId}) => {
                return(<Tag {...attributes} className={mapToCssModules(classNames('bot-tab-pane', className, { active: tabId === activeTabId }), cssModule)} />);
            }}
        </TabPaneContext.Consumer>
    );
}
TabPane.propTypes = propTypes;
TabPane.defaultProps = defaultProps;
TabPane.contextTypes = contextTypes;
export default TabPane;
