/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Button, FormGroup, Col, Row, Label, Input} from '../../components/bot-components';
import Select from 'react-select';
import {unitPmActions, logActions, unitActions, roleActions} from '../../network/actions';

const EquipmentPm = (props) => {
    const [state, updateState] = React.useState({
        selectedMechanic: '',
        hours: undefined,
        mileage: undefined,
        unit_id: undefined,
        job_id: undefined,
        mechanic_id: undefined,
        workorder_status_id: undefined,
        notes: '',
        items_checked: undefined,
        attributeList: []
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    React.useEffect(() => {
        props.getMechanics();
    }, []);

    const toggleItemsChecked = (e) => {
        const {name, checked} = e.target;
        let items_checked = {...state.items_checked};
        items_checked[name].value = checked;
        setState({
            items_checked
        });
    };

    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value,
        });
    };

    const createPm = () => {
        const PM = {
            hours: parseFloat(state.hours),
            mileage: parseFloat(state.mileage),
            items_checked: state.items_checked,
            notes: state.notes,
            job_id: props.unit.items.job_id,
            unit_id: props.unit.items.id,
            mechanic_id: '',
            workorder_status_id: 1
        };
        if (state.selectedMechanic) {
            PM.mechanic_id = state.selectedMechanic.value;
        } else {
            delete PM.mechanic_id
        }
        props.createPM(PM);
        props.onSavePm(true);
    };

    const createForm = () => {
        let col1 = [];
        let col2 = [];
        Object.keys(state.items_checked).map((item, i) => {
            if (i < (Object.keys(state.items_checked).length / 2)) {
                col1.push(
                    <div key={`pm-${[item]}`}>
                        <FormGroup check inline>
                            <Input type="checkbox" id={[item]}
                                   name={[item]} onChange={toggleItemsChecked}
                                   defaultChecked={state.items_checked[item].value}
                            />
                            <Label className="form-check-label" check
                                   htmlFor={[item]}>{state.items_checked[item].text}</Label>
                        </FormGroup>
                    </div>
                )
            } else {
                col2.push(
                    <div key={`pm-${[item]}`}>
                        <FormGroup check inline>
                            <Input type="checkbox" id={[item]}
                                   name={[item]} onChange={toggleItemsChecked}
                                   defaultChecked={state.items_checked[item].value}
                            />
                            <Label className="form-check-label" check
                                   htmlFor={[item]}>{state.items_checked[item].text}</Label>
                        </FormGroup>
                    </div>
                )
            }
            return true
        });
        setState({
            attributeList: [
                <Col sm={'6'}>
                    {col1}
                </Col>,
                <Col sm={'6'}>
                    {col2}
                </Col>
            ]
        });
    };

        const {
            selectedMechanic, hours, mileage, notes, attributeList
        } = state;
        const {mechanicList, attributes} = props;

        if (attributes.items && state.items_checked === undefined) {
            setState({
                items_checked: props.attributes.items
            });
        }
        if (state.items_checked && attributeList.length < 1) {
            createForm();
        }

        return (
            <>
                <Row>
                    <Col sm={'4'}>
                        <Label style={{fontWeight: 'bold'}}>Miles:</Label>
                        <Input type={'textField'} name={'mileage'} value={mileage}
                               onChange={changeProps}/>
                    </Col>
                    <Col sm={'4'}>
                        <Label style={{fontWeight: 'bold'}}>Hours:</Label>
                        <Input type={'textField'} name={'hours'} value={hours} onChange={changeProps}/>
                    </Col>
                    <Col sm={'4'}>
                        <Label style={{fontWeight: 'bold'}}>Mechanic:</Label>
                        <Select options={mechanicList} placeholder={'Please select operator from list'} matchProp={'label'}
                                onChange={(selectedMechanic) => setState({selectedMechanic: selectedMechanic})}
                                value={selectedMechanic}/>
                    </Col>
                </Row>
                <Row>
                    {attributeList}
                </Row>
                <Row>
                    <Col sm={'6'}>
                        <Label style={{fontWeight: 'bold'}}>Notes:</Label>
                        <Input type={'textArea'} id={'notes'} name={'notes'}
                               onChange={changeProps} value={notes}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color={'texts'} onClick={() => {
                            createPm()
                        }}>Submit</Button>
                    </Col>
                </Row>
            </>
        );
};
const mapStateToProps = (state) => {
    const {units, unit, mechanics} = state;
    let unitList = units?.items?.map(u => ({label: u.number, value: u.id}));
    let mechanicList = mechanics?.items?.map(m => ({label: m.name, value: m.id}));
    let attributes = {};
    attributes.items = {
        changed_engine_oil: {text: "Engine Oil",
            value: false},
        changed_engine_oil_filter: {text: "Engine Oil Filter",
            value: false},
        changed_air_filter: {text: "Air Oil",
            value: false},
        changed_fuel_filter: {text: "Fuel Oil",
            value: false},
        checked_levels: {text: "Check Levels",
            value: false},
        greased: {text: "Greased",
            value: false},
        other_1: {text: "Other",
            value: false},
        other_3: {text: "Other",
            value: false},
        changed_hydraulic_filter: {text: "Hydraulic Oil",
            value: false},
        engine_oil_sample_taken: {text: "Engine Oil Sample",
            value: false},
        hydraulic_oil_sample_taken: {text: "Hydraulic Oil Sample",
            value: false},
        transmission_oil_sample_taken: {text: "Transmission Oil Sample",
            value: false},
        right_final_drive_sample_taken: {text: "Right Final Drive Sample",
            value: false},
        left_final_drive_sample_taken: {text: "Left Final Drive Sample",
            value: false},
        other_2: {text: "Other",
            value: false},
        other_4: {text: "Other",
            value: false},
    };

    return {units, unitList, unit, mechanicList, attributes};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUnits: () => dispatch(unitActions.getUnits()),
        createLog: (log) => dispatch(logActions.createLog(log)),
        createPM: (unit_pm) => dispatch(unitPmActions.createPm(unit_pm)),
        getMechanics: () => dispatch(roleActions.getMechanics()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentPm);
