export const purchaseOrderConstants = {
    CREATE_PURCHASE_ORDER_REQUEST: 'PURCHASE_ORDER_CREATE_REQUEST',
    CREATE_PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDER_CREATE_SUCCESS',
    CREATE_PURCHASE_ORDER_FAILURE: 'PURCHASE_ORDER_CREATE_FAILURE',

    UPDATE_PURCHASE_ORDER_REQUEST: 'PURCHASE_ORDER_UPDATE_REQUEST',
    UPDATE_PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDER_UPDATE_SUCCESS',
    UPDATE_PURCHASE_ORDER_FAILURE: 'PURCHASE_ORDER_UPDATE_FAILURE',

    GET_ALL_PURCHASE_ORDERS_REQUEST: 'PURCHASE_ORDERS_GET_ALL_REQUEST',
    GET_ALL_PURCHASE_ORDERS_SUCCESS: 'PURCHASE_ORDERS_GET_ALL_SUCCESS',
    GET_ALL_PURCHASE_ORDERS_FAILURE: 'PURCHASE_ORDERS_GET_ALL_FAILURE',

    DETAIL_PURCHASE_ORDER_REQUEST: 'PURCHASE_ORDER_DETAIL_REQUEST',
    DETAIL_PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDER_DETAIL_SUCCESS',
    DETAIL_PURCHASE_ORDER_FAILURE: 'PURCHASE_ORDER_DETAIL_FAILURE',
};
