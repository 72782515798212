import {unitSubTypeConstants} from '../constants';

export const unit_subtypes = (state = {}, action) => {
    switch (action.type) {
        case unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_REQUEST:
            return {
                loading: true
            };
        case unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_SUCCESS:
            return {
                items: action.unit_subtypes
            };
        case unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const unit_subtype = (state = {}, action) => {
    switch (action.type) {
        case unitSubTypeConstants.CREATE_UNIT_SUBTYPE_REQUEST:
            return {
                loading: true
            };
        case unitSubTypeConstants.CREATE_UNIT_SUBTYPE_SUCCESS:
            return {
                items: action.unit_type
            };
        case unitSubTypeConstants.CREATE_UNIT_SUBTYPE_FAILURE:
            return {
                error: action.error
            };
        case unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_REQUEST:
            return {
                loading: true
            };
        case unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_SUCCESS:
            return {
                items: action.unit_subtype
            };
        case unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
