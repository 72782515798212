import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, deprecated, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    inverse: PropTypes.bool,
    color: PropTypes.string,
    block: deprecated(PropTypes.bool, 'Please use the props "body"'),
    body: PropTypes.bool,
    outline: PropTypes.bool,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
};

const defaultProps = {
    tag: 'div'
};

const Card = ({className, cssModule, color, block, body, inverse, outline, style, tag: Tag, innerRef, ...attributes}) => {
        const classes = mapToCssModules(classNames(
            className,
            'bot-card',
            inverse ? 'text-white' : false,
            block || body ? 'bot-card-body' : false,
            color ? `${outline ? 'border' : 'bg'}-${color}` : false
        ), cssModule);

        return (
            <Tag {...attributes} className={classes} style={style} ref={innerRef} />
        );
}

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
