// noinspection JSValidateTypes

import React, { useRef, useState } from 'react';
import { BotTable } from '../../components/bot-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { _dateFormat } from '../../helpers';
import { GenericSelectEditor, GenericTextEditor } from '../Editors';

const propTypes = {
  ref:PropTypes.node,
  title:PropTypes.string,
  data:PropTypes.array,
  getUnitSubTypes:PropTypes.func.isRequired,
  getUnitType:PropTypes.func.isRequired,
  getUnitSubType:PropTypes.func.isRequired,
  unit_subtype:PropTypes.object,
  editAction:PropTypes.bool,
  viewAction:PropTypes.bool,
  popoutAction:PropTypes.bool,
  alertAction:PropTypes.bool,
  actionCount:PropTypes.number,
  updateUnitSubType:PropTypes.func,
  csvFile:PropTypes.string,
  csvUrl:PropTypes.string,
  lStorage:PropTypes.string,
  remote:PropTypes.bool,
  tblId:PropTypes.string,
  pageSizes:PropTypes.array,
  canCreate:PropTypes.bool,
  unitTypeList:PropTypes.any,
  loading:PropTypes.bool,
  u_preferences:PropTypes.any,
  rowSelected:PropTypes.func,
  checkListCreated:PropTypes.bool,
  CreateNewBtn:PropTypes.any
};
let columns = [];
const defaultFilters = {
  subtype:'',
  unit_type:'',
  list_count:'',
  unit_count:'',
  pm_count:'',
  updated_at:'',
  updated_by:''
};
const myFilters = _.cloneDeep( defaultFilters );
const defaultState = {
  sortName:'',
  sortOrder:'',
  currSize:20,
  currPage:1,
  filters:myFilters
};

const UnitClassTable = React.forwardRef( ( props, ref ) => {
  const {
    remote,
    tblId,
    getUnitSubType,
    title,
    data,
    showTitle,
    loading,
    lStorage,
    csvUrl,
    csvFile,
    pageSizes,
    checkListCreated
  } = props;
  //<editor-fold desc="Column Refs">
  const subtypeRef = React.useRef();
  const uTypeRef = React.useRef();
  const listRef = React.useRef();
  const unitRef = useRef();
  const pmRef = useRef();
  const updatedByRef = useRef();
  const updatedAtRef = useRef();
  //</editor-fold>

  //<editor-fold desc="State">
  const [selectedRow, setSelectedRow] = useState( null );
  const [selectedColumn, setSelectedColumn] = useState( null );
  const [showEditor, setShowEditor] = useState( false );
  const [selectEditorOpen, setSelectEditorOpen] = useState( false );
  const [extraProps, setExtraProps] = useState( null );
  const [selectedCell, setSelectedCell] = useState( null );
  const [selectedName, setSelectedName] = useState( null );
  //</editor-fold>

  const refreshUnitClasses = () => {
    const tbl = ref?.current;
    const req = {
      sortName:tbl?.state.sortName,
      sortOrder:tbl?.state.sortOrder,
      currentPage:tbl?.state.currPage,
      sizePerPage:tbl?.state.currSize,
      filters:tbl?.state.filters
    };

    remote ? getUnitSubTypes( req ) : getUnitSubTypes();
  };
  React.useEffect( () => {
    checkListCreated && refreshUnitClasses();
  }, [checkListCreated] );
  const prepareList = ( cell ) => {
    return cell ? 'Yes' : 'No';
  };
  const rowSelected = ( id ) => {
    getUnitSubType( id );
  };

  const selects = ['list_count'];
  const getUnitSubTypes = async ( req ) => {
    props.getUnitSubTypes( req );
  };

  const toggleEditor = ( row, column ) => {
    setSelectedRow( row );
    setSelectedColumn( column );
    setShowEditor( prevState => !prevState );
    rowSelected( row?.id );
  };

  const toggleSelectEditor = ( row, cell, name ) => {
    console.log( props.unit_types );
    setSelectedRow( row );
    setSelectedCell( cell );
    setSelectedName( name );
    setSelectEditorOpen( prevState => !prevState );
    setExtraProps( props.unit_types?.map( i => ({ label:i.utype, value:i.id }) ) );
  };

  columns = props?.columns ?? [
    {
      field:'subtype',
      text:'Equipment Class',
      filter:{
        type:'text',
        placeholder:'Equipment Class',
        defaultValue:defaultFilters.subtype
      },
      editable:{
        value:true,
        onClick:( row ) => {
          toggleEditor( row, 'subtype' );
        }
      },
      width:150,
      hidden:false,
      refProp:subtypeRef,
      sortable:true
    },
    {
      field:'unit_type',
      text:'Equipment Type',
      filter:{
        type:'text',
        placeholder:'Type',
        defaultValue:defaultFilters.unit_type
      },
      editable:{
        value:true,
        onClick:( row, cell ) => toggleSelectEditor( row, 'unit_type_id', cell )
      },
      width:200,
      hidden:false,
      sortable:true,
      refProp:uTypeRef
    },
    {
      field:'unit_count',
      text:'Equipment Count',
      filter:{
        type:'text',
        placeholder:'Equipment Count',
        defaultValue:defaultFilters.unit_count
      },
      width:200,
      hidden:false,
      sortable:true,
      refProp:unitRef
    },
    {
      field:'list_count',
      text:'Class Level Inspection',
      filter:{
        type:'select',
        placeholder:'Check List',
        options:[{ value:'', label:'Select List' }, { value:true, label:'Yes' }, {
          value:false,
          label:'No'
        }],
        defaultValue:defaultFilters.list_count
      },
      width:200,
      hidden:false,
      sortable:true,
      format:cell => prepareList( cell ),
      refProp:listRef
    },
    {
      field:'pm_count',
      text:'PM Count',
      filter:{
        type:'text',
        placeholder:'PM Count',
        defaultValue:defaultFilters.pm_count
      },
      width:150,
      hidden:false,
      sortable:true,
      refProp:pmRef
    },
    {
      field:'updated_by',
      text:'Modified By',
      filter:{
        type:'text',
        placeholder:'Last modified person',
        defaultValue:defaultFilters.updated_by
      },
      width:250,
      hidden:false,
      sortable:true,
      format:cell => cell?.name,
      refProp:updatedByRef
    },
    {
      field:'updated_at',
      text:'Last Modified',
      filter:{
        type:'text',
        placeholder:'Last modified date',
        defaultValue:defaultFilters.updated_at
      },
      width:250,
      hidden:false,
      sortable:true,
      format:cell => _dateFormat( cell ),
      refProp:updatedAtRef
    }
  ];

  return (
    <>
      <GenericTextEditor row={ selectedRow } toggle={ () => toggleEditor() } isOpen={ showEditor }
                         cell={ selectedColumn } onUpdate={ ( ops ) => {
        const _unit_type = {
          ...selectedRow,
          [selectedColumn]:ops[selectedColumn]
        };
        props.updateUnitType( _unit_type );
      } }/>
      <GenericSelectEditor row={ selectedRow } toggle={ () => toggleSelectEditor() }
                           isOpen={ selectEditorOpen } extraProps={ extraProps }
                           cell={ selectedCell } name={ selectedName }
                           onUpdate={ ( ops ) => props.updateUnitType( { ...selectedRow, ...ops } ) }/>
      <BotTable ref={ ref }
                columns={ columns }
                title={ title }
                showTitle={ showTitle }
                header={ true }
                data={ data }
                getData={ ( req ) => getUnitSubTypes( req ) }
                pageSizes={ pageSizes }
                ClearFiltersBtn={ true }
                ClearFiltersIcon={ true }
                ClearSortBtn={ true }
                ClearSortIcon={ true }
                CsvDownloadBtn={ true }
                CsvDownloadIcon={ true }
                pagination={ 'both' }
                headers={ true }
                expandable={ false }
                lStorage={ lStorage }
                loading={ loading }
                tblId={ tblId }
                csvFile={ csvFile }
                csvUrl={ csvUrl }
                CreateNewBtn={ props.CreateNewBtn }
                doRowUpdate={ () => Promise.reject() }
                refreshData={ () => refreshUnitClasses() }
                btnGroupDirection={ 'left' }
                remote={ false }
                defaultFilters={ defaultFilters }
                defaultState={ defaultState }
                onRowDoubleClick={ ( row ) => rowSelected( row.id ) }
                selects={ selects }
      />
    </>
  );
} );

UnitClassTable.propTypes = propTypes;

export default UnitClassTable;
