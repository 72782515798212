import {unitConstants} from '../constants/';
import {unitService} from '../services/';

export const unitActions = {};
unitActions.getUnits = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.GET_ALL_UNITS_REQUEST});
    const success = (units) => ({type: unitConstants.GET_ALL_UNITS_SUCCESS, units});
    const failure = (error) => ({type: unitConstants.GET_ALL_UNITS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.getAll(req)
            .then(units => dispatch(success(units)), error => dispatch(failure(error)));
    };
};
unitActions.getNoLog = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.GET_ALL_UNITS_REQUEST});
    const success = (units) => ({type: unitConstants.GET_ALL_UNITS_SUCCESS, units});
    const failure = (error) => ({type: unitConstants.GET_ALL_UNITS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.getNoLog()
            .then(units => dispatch(success(units)), error => dispatch(failure(error)));
    };
};
unitActions.getUnit = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.DETAIL_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.DETAIL_UNIT_SUCCESS, unit});
    const failure = (error) => ({type: unitConstants.DETAIL_UNIT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        id && unitService.getDetail(id)
            .then(unit => {
                dispatch(success(unit));
                unit && localStorage.setItem('unit_id', unit.id)
            }, error => dispatch(failure(error)));
    };
};
unitActions.getNext = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.DETAIL_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.DETAIL_UNIT_SUCCESS, unit});
    const failure = (error) => ({type: unitConstants.DETAIL_UNIT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        id && unitService.getNext(id)
            .then(unit => {
                dispatch(success(unit));
                unit && localStorage.setItem('unit_id', unit.id)
            }, error => dispatch(failure(error)));
    };
};
unitActions.getPrevious = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.DETAIL_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.DETAIL_UNIT_SUCCESS, unit});
    const failure = (error) => ({type: unitConstants.DETAIL_UNIT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        id && unitService.getPrevious(id)
            .then(unit => {
                dispatch(success(unit));
                unit && localStorage.setItem('unit_id', unit.id);
            }, error => dispatch(failure(error)));
    };
};
unitActions.clearUnit = () => {
    return dispatch => dispatch({type: unitConstants.DETAIL_UNIT_SUCCESS});
};
unitActions.updateUnit = (unit) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.UPDATE_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.UPDATE_UNIT_SUCCESS, unit});
    const failure = (error) => ({type: unitConstants.UPDATE_UNIT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.updateUnit(unit)
            .then(unit => {
                dispatch(success(unit));
                unit && dispatch(unitActions.getUnit(unit.id))
            }, error => dispatch(failure(error)));
    };
};
unitActions.createUnit = (unit) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.CREATE_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.CREATE_UNIT_REQUEST, unit});
    const failure = (error) => ({type: unitConstants.CREATE_UNIT_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.createUnit(unit)
            .then(unit => {
                dispatch(success(unit));
                unit && dispatch(unitActions.getUnit(unit.id));
            }, error => dispatch(failure(error)));
    };
};
unitActions.dot_report = (values) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.DOT_REPORT_REQUEST});
    const success = (values) => ({type: unitConstants.DOT_REPORT_SUCCESS, values});
    const failure = (error) => ({type: unitConstants.DOT_REPORT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.dotReport(values)
            .then(dot_report => dispatch(success(dot_report)), error => dispatch(failure(error)));
    };
};
unitActions.clearDotReport = () => {
    const success = (values) => ({type: unitConstants.DOT_REPORT_SUCCESS, values});
    return dispatch => {
        dispatch(success(null));
    };
};
unitActions.attachFiles = (file, unit_id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitConstants.UPDATE_UNIT_REQUEST});
    const success = (unit) => ({type: unitConstants.UPDATE_UNIT_SUCCESS, unit});
    const failure = (error) => ({type: unitConstants.UPDATE_UNIT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitService.attachFiles(file)
            .then(attachment => {
                dispatch(unitActions.getUnit(unit_id));
                dispatch(success(attachment));
                }, error => dispatch(failure(error)));
    };
};
