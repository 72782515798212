import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';
const propTypes = {
    tabs: PropTypes.bool,
    pills: PropTypes.bool,
    vertical: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    horizontal: PropTypes.string,
    justified: PropTypes.bool,
    fill: PropTypes.bool,
    navbar: PropTypes.bool,
    card: PropTypes.bool,
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};
const defaultProps = {
    tag: 'ul',
    vertical: false,
};

const Nav = ({ className, cssModule, tabs, pills, vertical, horizontal, justified, fill, navbar, card, tag: Tag, ...attributes }) => {

    const getVerticalClass = (vertical) => {
        if (vertical === false) {
            return false;
        } else if (vertical === true || vertical === 'xs') {
            return 'flex-column';
        }

        return `flex-${vertical}-column`;
    };

    const classes = mapToCssModules(classNames(
        className,
        navbar ? 'bot-navbar-nav' : 'bot-nav',
        horizontal ? `justify-content-${horizontal}` : false,
        getVerticalClass(vertical),
        {
            'bot-nav-tabs': tabs,
            'bot-card-header-tabs': card && tabs,
            'bot-nav-pills': pills,
            'bot-card-header-pills': card && pills,
            'bot-nav-justified': justified,
            'bot-nav-fill': fill,
        }
        ), cssModule);

    return (
        <Tag {...attributes} className={classes} />
        );
}

Nav.propTypes = propTypes;
Nav.defaultProps = defaultProps;
export default Nav;
