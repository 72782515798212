import {validateUniqueSerialNum, validateUniqueUnitNum} from '../../../helpers';
import {
    assetTypeActions,
    companyActions,
    jobActions, tireSizeActions,
    unitActions,
    unitStatusActions,
    unitSubTypeActions,
    unitTypeActions
} from '../../../network/actions';

/**
 * @namespace ut.utype
 */
export const submitUnit = async (state, props) => {
    const uNum = await validateUniqueUnitNum(state.unit_number, props?.unit?.items?.id, true);
    const uSer = await validateUniqueSerialNum(state.vin_sn, props?.unit?.items?.id, true);

    if (uNum.pass && uNum.exists && uSer.pass && uNum.exists) {
        const updatedUnit = {
            id: state.unit_id,
            unit_type_id: state.unit_type_id,
            unit_subtype_id: state.unit_subtype_id,
            unit_status_id: state.unit_status_id,
            company_id: state.company_id,
            number: state.unit_number,
            plate: state.plate,
            vin_sn: state.vin_sn,
            registration_date: state.registration_date,
            description: state.description,
            location: state.location,
            mileage: state.mileage,
            hours: state.hours,
            cadr: state.cadr,
            dot: state.dot,
            asset_type_id: state.asset_type_id,
            tire_size_id: state.tire_size_id,
            active: state.active,
            tire_size_2_id: state.tire_size_2_id,
            tire_size_3_id: state.tire_size_3_id,
            tire_size_4_id: state.tire_size_4_id,
            tare_weight: state.tare_weight,
            gross_weight: state.gross_weight,
            height: state.height,
            wheelbase: state.wheelbase,
            axles: state.axles,
            width: state.width,
            overall_length: state.overall_length,
            horsepower: state.horsepower
        };
        props.saveUnit(updatedUnit);
        props.cancelUpdate();
    } else {
        alert('The equipment# is already in use in the system.')
    }
};
export const mapStateToProps = (state) => {
    const {authentication, unit, unit_types, unit_statuses, companies, unit_type, asset_types, tire_sizes} = state;
    const {user} = authentication;
    let unitTypes = unit_types?.items?.map(ut => ({label: ut.utype, text: ut.utype, value: ut.id}));
    let companyList = companies?.items?.map(c => ({label: c.name, text: c.name, value: c.id}));
    let unitStatuses = unit_statuses?.items?.map(us => ({label: us.status, text: us.status, value: us.id}));
    let unitSubtypes = unit_type?.items?.unit_subtypes?.map(ust => ({label: ust.subtype, text: ust.subtype, value: ust.id}));
    let assetTypeList = asset_types?.items?.map(at => ({label: at.description, text: at.description, value: at.id}));
    let tireSizeList = tire_sizes?.items?.map(ts => ({label: ts.size, text: ts.size, value: ts.id}));

    unit.items && localStorage.setItem('unit_id', unit.items.id);

    return {user, unit, unit_types, unitSubtypes, unitStatuses, companyList, unitTypes, tireSizeList, assetTypeList};
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        saveUnit: (unit) => dispatch(unitActions.updateUnit(unit)),
        getUnitTypes: () => dispatch(unitTypeActions.getAll()),
        getUnitSubTypes: () => dispatch(unitSubTypeActions.getAll()),
        getUnitStatuses: () => dispatch(unitStatusActions.getAll()),
        getUnitJobs: () => dispatch(jobActions.getAll()),
        getCompanies: () => dispatch(companyActions.getAll()),
        getUnitType: (id) => dispatch(unitTypeActions.getType(id)),
        getJobs: () => dispatch(jobActions.getAll()),
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes())
    }
};
