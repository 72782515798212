import {authHeader} from '../../helpers/index';
import {baseUrl} from "../constants/";
import {handleResponse} from './responseHandler';

export const unitSubTypeService = {};

const apiUrl = baseUrl;

unitSubTypeService.createUnitSubType = (unit_subtype) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(unit_subtype)
    };

    return fetch(`${apiUrl}/unit_subtype`, requestOptions).then(handleResponse);
};

unitSubTypeService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_subtypes`, requestOptions).then(handleResponse)
};

unitSubTypeService.getSubType = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_subtype/` + id, requestOptions).then(handleResponse);
};

unitSubTypeService.updateSubType = (unit_subtype) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(unit_subtype)
    };

    return fetch(`${apiUrl}/unit_subtype/${unit_subtype.id}`, requestOptions).then(handleResponse);
};
