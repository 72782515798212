import {authHeader} from '../../helpers';
import {baseUrl} from "../constants/";
import {handleResponse} from './responseHandler';

export const workorderService = {};

const apiUrl = baseUrl;

workorderService.getAll = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/`, requestOptions).then(handleResponse)
};

workorderService.getAllVendor = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/vendor_all/`, requestOptions).then(handleResponse)
};

workorderService.getAllLog = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/by_log/`, requestOptions).then(handleResponse)
};

workorderService.getAllOpen = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/open_all/`, requestOptions).then(handleResponse)
};

workorderService.getOpenCount = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/workorders/open_count/`, requestOptions).then(handleResponse)
};

workorderService.getAllNew = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/open_new/`, requestOptions).then(handleResponse)
};

workorderService.getAllOld = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/workorders/open_old/`, requestOptions).then(handleResponse)
};

workorderService.getReport = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/workorders/weekly_report/`, requestOptions).then(handleResponse)
};

workorderService.getLightReport = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/workorders/light_report/`, requestOptions).then(handleResponse)
};

workorderService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/workorder/` + id, requestOptions).then(handleResponse)
};

workorderService.getNext = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/workorder/next/` + id, requestOptions).then(handleResponse)
};

workorderService.getPrevious = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/workorder/previous/` + id, requestOptions).then(handleResponse)
};

workorderService.createWorkorder = (workorder) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(workorder),
    };

    return fetch(`${apiUrl}/workorder`, requestOptions).then(handleResponse)
};

workorderService.updateWorkorder = (workorder) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(workorder),
    };

    return fetch(`${apiUrl}/workorder/` + workorder.id, requestOptions).then(handleResponse)
};

workorderService.confirmWorkorder = () => {
    const workorder_id = localStorage.getItem('workorder_id');
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
    };

    return fetch(`${apiUrl}/workorder/confirm/` + workorder_id, requestOptions).then(handleResponse)
};

workorderService.updateMechanic = (workorder) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(workorder),
    };

    return fetch(`${apiUrl}/workorder/mechanic/` + workorder.id, requestOptions).then(handleResponse)
};

workorderService.updateVendor = (workorder) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(workorder),
    };

    return fetch(`${apiUrl}/workorder/vendor/` + workorder.id, requestOptions).then(handleResponse)
};

workorderService.attachFiles = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('GET'),
        contentLength: data.size,
        body: data,
    };

    return fetch(`${apiUrl}/attachment/workorder`, requestOptions).then(handleResponse);
};
