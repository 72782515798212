import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const logService = {};

const apiUrl = baseUrl;

logService.getAll = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/logs/`, requestOptions).then(handleResponse)
};

logService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/log/` + id, requestOptions).then(handleResponse)
};

logService.getNext = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/log/next/` + id, requestOptions).then(handleResponse)
};

logService.getPrevious = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/log/previous/` + id, requestOptions).then(handleResponse)
};

logService.createLog = (log) => {
    // let formData = new FormData();
    // for (let name in log) {
    //     if (log.hasOwnProperty(name)) {
    //         formData.append(name, log[name]);
    //     }
    // }
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(log)
    };

    return fetch(`${apiUrl}/log`, requestOptions).then(handleResponse);
};

logService.getChecks = (unit_type) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({unit_type: unit_type})
    };

    return fetch(`${apiUrl}/inspection_checks`, requestOptions).then(handleResponse);
};

logService.createInspection = (log) => {
    // let formData = new FormData();
    // for (let name in log) {
    //     if (log.hasOwnProperty(name)) {
    //         formData.append(name, log[name]);
    //     }
    // }
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(log)
    };

    return fetch(`${apiUrl}/inspection`, requestOptions).then(handleResponse);
};
