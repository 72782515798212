// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const companyRoleService = {};

const apiUrl = baseUrl;

companyRoleService.createCompanyRole = (company_role) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({company_role})
    };

    return fetch(`${apiUrl}/company_role`, requestOptions).then(handleResponse)
};

companyRoleService.updateCompanyRole = (company_role_id, company_role) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({company_role})
    };

    return fetch(`${apiUrl}/company_role/` + company_role_id, requestOptions).then(handleResponse);
};

companyRoleService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/company_roles`, requestOptions).then(handleResponse);
};

companyRoleService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/company_role/` + id, requestOptions).then(handleResponse);
};
