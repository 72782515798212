import {Col, NavItem, NavLink, Row, TabPane} from '../../../components/bot-components';
import {_dateFormat} from '../../../helpers';
import {
    attachmentActions,
    commentActions, logActions, purchaseOrderActions,
    roleActions, taskActions, taskPriorityActions,
    unitActions,
    unitStatusActions, vendorActions,
    workorderActions,
    workorderStatusActions, yardActions
} from '../../../network/actions';
import React from 'react';
import classnames from 'classnames';
import WorkOrderDetail from '../BotWorkOrderDetail/BotWorkOrderDetail';
import WorkOrderEdit from '../BotWorkOrderEdit/BotWorkOrderEdit';
import WorkorderMessages from '../WorkOrderMessages/WorkOrderMessages';
// import WorkorderCalls from '../WorkOrderCalls/WorkOrderCalls';
import BotAttachments from '../../Attachments/BotAttachments/BotAttachments';
import BotTasks from '../../Tasks/BotTasks/BotTasks';
import BotInspections from '../../Inspections/BotInspections';
import BotGpsDataTable from '../../GpsData/BotGpsDataTable';
import BotPurchaseOrders from '../../PurchaseOrders/BotPurchaseOrders';
import BotParts from '../../Parts/BotParts';
import {plmAlertActions, partActions} from '../../../network/actions';

export const mapStateToProps = (state) => {
    /**
     * @param workorder.items.logs
     */
    const {authentication, workorder, unit, workorder_statuses, unit_statuses, mechanics, vendors, yards, task_priorities,
        plm_alert, task, attachment, log, user_preference} = state;
    const {user} = authentication;
    let workorder_statusList = [];
    // let unit_statusList = [];
    let mechList = mechanics?.items?.map(m => ({text: m.name, value: m.id}));
    let vendorList = vendors?.items?.map(v => ({text: v.name, value: v.id}));
    let yardList = yards?.items?.map(yard => ({value: yard.id, label: yard.name}));
    let mechanicList = mechanics?.items?.map(m => ({label: m.name, value: m.id}));
    let vendList = vendors?.items?.map(v => ({label: v.name, value: v.id}));
    let PriorityList = task_priorities?.items?.map(t => ({label: t.priority, value: t.id}));
    let calls = [];
    let po_statuses = {items: [{id: 1, status: 'Requested'}, {id: 2, status: 'Approved'}, {id: 3, status: 'Denied'}]};
    let po_statusList = po_statuses?.items?.map(p => ({label: p.status, value: p.id}));
    let parts = [];
    let inspections = [];
    let attachments = [];
    let poStatusList = [];
    const BotModules = user_preference.items?.bot_modules?.filter(m => m.enabled);

    if (workorder.items) {
        localStorage.setItem('workorder_id', workorder.items.id);
        localStorage.setItem('unit_id', workorder.items['unit_id']);
        if (workorder.items['call_logs']) {
            for (let i = 0; i < workorder.items['call_logs'].length; i++) {
                calls.push(<Col key={workorder.items['call_logs'][i].id}>
                    <Row>
                        <label style={{fontWeight: 'bold', fontSize: 18, paddingRight: 2}}>Made By:</label>
                        <label style={{fontSize: 18}}>{workorder.items['call_logs'][i]['created_by']['name'] ?
                            workorder.items['call_logs'][i]['created_by']['name'] :
                            workorder.items['call_logs'][i]['created_by']}
                        </label>
                    </Row>
                    {workorder.items['call_logs'][i].followup_date &&
                    <Row>
                        <label style={{fontWeight: 'bold', fontSize: 18}}>
                            Followup Date:
                        </label>
                        <label style={{fontSize: 18}}>{_dateFormat(workorder.items['call_logs'][i].followup_date)}</label>
                    </Row>}
                    <hr/>
                    <Row>
                        <label style={{fontWeight: 'bold', fontSize: 18, paddingRight: 2}}>Called: </label>
                        <label style={{fontSize: 18}}>{workorder.items['call_logs'][i]['vendor']}</label>
                        <label style={{fontSize: 18, fontWeight: 'bold', paddingLeft: 2, paddingRight: 2}}> On: </label>
                        <label style={{fontSize: 18}}>{_dateFormat(workorder.items['call_logs'][i]['created_at'])}</label>
                    </Row>
                    <hr/>
                    <Row>
                        <label style={{fontWeight: 'bold', fontSize: 18}}>{workorder.items['call_logs'][i].message}</label>
                    </Row>
                    <hr className={'big-hr'}/>
                </Col>)
            }
        }

        if (workorder.items.parts) {
            parts = workorder.items.parts;
        }

        if (workorder.items.logs) {
            inspections = workorder.items.logs;
        }

        if (workorder.items.images) {
            attachments = workorder.items.images;
        }
    }

    if (workorder.items) {
        localStorage.setItem('workorder_id', workorder.items.id);
        localStorage.setItem('unit_id', workorder.items['unit_id']);
    }

    if (workorder_statuses?.items && workorder.items) {
        for (let i = 0; i < workorder_statuses.items.length; i++) {
            const workorder_status_id = workorder_statuses.items[i].id;
            const workorder_status_name = workorder_statuses.items[i].status;
            workorder_statusList.push(<option key={workorder_status_name}
                                              value={workorder_status_id}>{workorder_status_name}</option>)
        }
    }

    const unit_statusList = unit_statuses?.items?.map(us => ({value: us.id, label: us.status}));
    return {user, unit, workorder, workorder_statusList, unit_statusList, mechList, mechanicList, vendorList, vendList,
        yards, yardList, PriorityList, plm_alert, calls, po_statuses, po_statusList, vendors, task, attachment, parts,
        attachments, inspections, log, poStatusList, user_preference, BotModules
    };
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getNextWorkorder: (id) => dispatch(workorderActions.getNext(id)),
        getPreviousWorkorder: (id) => dispatch(workorderActions.getPrevious(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getWorkorderStatuses: () => dispatch(workorderStatusActions.getAll()),
        getUnitStatuses: () => dispatch(unitStatusActions.getAll()),
        updateUnitStatus: (unit) => dispatch(unitActions.updateUnit(unit)),
        updateWorkorder: (workorder) => dispatch(workorderActions.updateWorkorder(workorder)),
        getMechanics: () => dispatch(roleActions.getMechanics()),
        updateMechanic: (workorder) => dispatch(workorderActions.updateMechanic(workorder)),
        getVendors: () => dispatch(vendorActions.getAll()),
        updateVendor: (workorder) => dispatch(workorderActions.updateVendor(workorder)),
        confirmWorkorder: () => dispatch(workorderActions.confirmWorkorder()),
        closeWorkorder: (workorder) => dispatch(workorderActions.closeWorkorder(workorder)),
        getYards: () => dispatch(yardActions.getAll()),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        updateTask: (task) => dispatch(taskActions.updateTask(task)),
        createTask: (task) => dispatch(taskActions.createTask(task)),
        getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
        getMyLog: (id) => dispatch(logActions.getDetail(id)),
        createPart: (part) => dispatch(partActions.createPart(part)),
        updatePart: (part) => dispatch(partActions.updatePart(part)),
        attachFiles: (data, workorder_id) => dispatch(workorderActions.attachFiles(data, workorder_id)),
        getAttachment: (id) => dispatch(attachmentActions.getAttachment(id)),
        saveComment: (comment) => dispatch(commentActions.createComment(comment)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
        createPurchaseOrder: (po) => dispatch(purchaseOrderActions.createPurchaseOrder(po)),
        updatePurchaseOrder: (po) => dispatch(purchaseOrderActions.updatePurchaseOrder(po))
    }
};
export const getTabs = (modules, state, toggleTab) => (<>
    <NavItem>
        <NavLink className={classnames({active: state.activeTab === '1'})} onClick={() => {toggleTab('1')}}>
            Detail
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink className={classnames({active: state.activeTab === '2'})} onClick={() => {toggleTab('2')}}>
            Tasks
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink className={classnames({active: state.activeTab === '3'})} onClick={() => {toggleTab('3')}}>
            History
        </NavLink>
    </NavItem>
    {/*<NavItem>
        <NavLink className={classnames({active: state.activeTab === '4'})} onClick={() => {toggleTab('4')}}>
            Call Logs
        </NavLink>
    </NavItem>*/}
    {modules?.find(m => m.name === 'purchase_orders') ? <NavItem>
        <NavLink className={classnames({active: state.activeTab === '5'})} onClick={() => {toggleTab('5')}}>
            PO's
        </NavLink>
    </NavItem> : null}
    {modules?.find(m => m.name === 'parts') ? <NavItem>
            <NavLink className={classnames({active: state.activeTab === '6'})} onClick={() => {toggleTab('6')}}>
                Parts
            </NavLink>
        </NavItem> : null}
    {modules?.find(m => m.name === 'inspections') ? <NavItem>
        <NavLink className={classnames({active: state.activeTab === '7'})} onClick={() => {toggleTab('7')}}>
            Inspections
        </NavLink>
    </NavItem> : null}
    <NavItem>
        <NavLink className={classnames({active: state.activeTab === '8'})} onClick={() => {toggleTab('8')}}>
            Attachments
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink className={classnames({active: state.activeTab === '10'})} onClick={() => {toggleTab('10')}}>
            Inspection Attachments
        </NavLink>
    </NavItem>
    {modules?.find(m => m.name === 'gps') ? <NavItem>
        <NavLink className={classnames({active: state.activeTab === '9'})} onClick={() => {toggleTab('9')}}>
            GPS History
        </NavLink>
    </NavItem> : null}
</>);
export const getPanes = (modules, state, toggleTab, cancelUpdate, updateWo, props, wiRef, liRef, wtRef, onSaveComment, updateTask, createTask, inspections, wpRef, onCreatePo, onUpdatePo, wpaRef, onCreatePart, onUpdatePart) => {
    const {mechanicList, yardList, unit_statusList, vendList, workorder_statusList, workorder/*, calls*/, attachments, attachment, PriorityList, task} = props;
    const {myWo, openPm} = state;
    return (<>
        <TabPane tabId={'1'}>
            {state.activeTab === '1' && !state.edit ?
                <WorkOrderDetail myWo={myWo} openPm={openPm} modules={props.BotModules}/> : state.activeTab === '1' && state.edit &&
                <WorkOrderEdit myWo={myWo}
                               cancelUpdate={() => cancelUpdate()}
                               updateWorkOrder={(wo) => updateWo(wo)}
                               getMechanics={() => props.getMechanics()}
                               getVendors={() => props.getVendors()}
                               getWorkorderStatuses={() => props.getWorkorderStatuses()}
                               getUnitStatuses={() => props.getUnitStatuses()}
                               getTaskPriorities={() => props.getTaskPriorities()}
                               mechanicList={mechanicList}
                               yardList={yardList}
                               unit_statusList={unit_statusList}
                               vendorList={vendList}
                               workorder_statusList={workorder_statusList}/>}
        </TabPane>
        <TabPane tabId={'2'}>
            {state.activeTab === '2' && vendList && mechanicList &&
            <BotTasks ref={wtRef}
                      data={myWo?.tasks}
                      workorder={workorder}
                      popoutAction={true}
                      viewAction={true}
                      actionCount={2}
                      title={'Work Order Tasks'}
                      vendorList={vendList}
                      mechanicList={mechanicList}
                      pageSizes={['20', '50', '100', '500', '1000']}
                      getTasks={() => null}
                      unit={props.unit}
                      remote={false}
                      priorityList={PriorityList}
                      getMyWorkOrder={() => null}
                      getMyUnit={(id) => props.getMyUnit(id)}
                      updateTask={(t) => updateTask(t)}
                      createTask={(t) => createTask(t)}
                      task={task} canCreate={true}
                      lStorage={'wo_detail_task_storage'}
                      csvFile={`work_order_${myWo?.id}_tasks.csv`}
                      u_preferences={props.user_preference}
                      getMyAlert={(id) => props.getAlert(id)}
                      parentComp={'workorder'}
                      getMyTask={(id) => props.getTask(id)}/>}
        </TabPane>
        <TabPane tabId={'3'}>
            {state.activeTab === '3' && <WorkorderMessages workorder={workorder}/>}
        </TabPane>
        {/*<TabPane tabId={'4'}>
            {state.activeTab === '4' &&
            <WorkorderCalls calls={calls} workorder={workorder} vendorList={vendList}/>}
        </TabPane>*/}
        {modules?.find(m => m.name === 'purchase_orders') ? <TabPane tabId={'5'}>
            {state.activeTab === '5' && vendList && vendList.length > 0 && props.po_statusList && props.po_statusList.length > 0 &&
            <BotPurchaseOrders ref={wpRef}
                               data={myWo ? myWo.purchase_orders : null}
                               workorder={props.workorder}
                               tblId={'wpRef'}
                               title={`Work Order PO's`}
                               vendorList={props.vendList}
                               poStatusList={props.poStatusList}
                               pageSizes={['20', '50', '100', '250', '500', '1000']}
                               getPos={() => null}
                               canCreate={true}
                               remote={false} updatePo={(po) => onUpdatePo(po)}
                               lStorage={'wo_detail_po_storage'}
                               csvFile={`work_order_${workorder.items && workorder.items.id}_pos.csv`}
                               u_preferences={props.user_preference}
                               createPo={(po) => onCreatePo(po)}/>}
        </TabPane> : null}
        {modules?.find(m => m.name === 'parts') ? <TabPane tabId={'6'}>
            {state.activeTab === '6' &&
            <BotParts ref={wpaRef}
                      data={myWo ? myWo.parts : null}
                      workorder={props.workorder}
                      tblId={'wpaRef'}
                      title={`Work Order Part's`}
                      vendorList={vendList}
                      pageSizes={['20', '50', '100', '250', '500', '1000']}
                      getParts={() => null}
                      canCreate={true}
                      remote={false} updatePart={(pa) => onUpdatePart(pa)}
                      lStorage={'wo_detail_part_storage'}
                      csvFile={`work_order_${workorder.items && workorder.items.id}_parts.csv`}
                      u_preferences={props.user_preference}
                      createPart={(pa) => onCreatePart(pa)}/>}
        </TabPane> : null}
        {modules?.find(m => m.name === 'inspections') ?
            <TabPane tabId={'7'}>
            {state.activeTab === '7' &&
            <BotInspections ref={wiRef}
                            getInspections={() => null}
                            getMyInspection={(id) => props.getMyLog(id)}
                            getMyUnit={(id) => props.getMyUnit(id)}
                            getMyWorkOrder={() => null}
                            title={'Work Order Inspections'}
                            actionCount={2}
                            data={inspections}
                            viewAction={true}
                            popoutAction={true}
                            onRowSelected={(id) => {props.getMyLog(id)}}
                            unit={props.unit}
                            tblId={'wiRef'}
                            remote={false}
                            pageSizes={['20', '50', '100', '500', '1000']}
                            workorder={workorder}
                            lStorage={'wo_detail_inspections_storage'}
                            csvFile={`work_order_${workorder.items && workorder.items.id}_inspections.csv`}
                            u_preferences={props.user_preference}
                            inspection={props.log}/>}
        </TabPane> : null}
        <TabPane tabId={'8'}>
            {state.activeTab === '8' &&
            <BotAttachments data={attachments ? attachments : null}
                            attachment={attachment}
                            title={'Work Order Attachments'} ref={wiRef}
                            getInspections={() => null}
                            getMyInspection={(id) => props.getMyLog(id)}
                            getMyUnit={(id) => props.getMyUnit(id)}
                            getAttachment={(id) => props.getAttachment(id)}
                            tblId={'waRef'}
                            remote={false}
                            pageSizes={['20', '50', '100', '500', '1000']}
                            lStorage={'wo_detail_attachment_storage'}
                            u_preferences={props.user_preference}
                            parentComp={'workorder'}
                            onSaveComment={(Comment, image_id) => {
                                onSaveComment(Comment, image_id)
                            }}/>}
        </TabPane>
        <TabPane tabId={'10'}>
            {state.activeTab === '10' &&
                <BotAttachments data={myWo ? myWo.log_images : null}
                                attachment={attachment}
                                title={'Work Order Attachments'} ref={wiRef}
                                getInspections={() => null}
                                getMyInspection={(id) => props.getMyLog(id)}
                                getMyUnit={(id) => props.getMyUnit(id)}
                                getAttachment={(id) => props.getAttachment(id)}
                                tblId={'liRef'}
                                remote={false}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                lStorage={'wo_detail_log_attachment_storage'}
                                u_preferences={props.user_preference}
                                parentComp={'workorder'}
                                onSaveComment={(Comment, image_id) => {
                                    onSaveComment(Comment, image_id)
                                }}/>}
        </TabPane>
        {modules?.find(m => m.name === 'gps') ? <TabPane tabId={'9'}>
            {state.activeTab === '9' &&
            <BotGpsDataTable data={myWo?.usage_history ?? []}
                             lStorage={'work_order_gps_table'}
                             unit={props.unit}
                             getExceptions={() => null}
                             getUnit={(id) => props.getMyUnit(id)}
                             remote={false}
                             includeDateRange={false}
                             csvFile={`work_order#${myWo?.id}-gps_history`}/>}
        </TabPane> : null}
    </>);
};
