import React, {useState, useEffect} from 'react';
import {socketUrl} from "../../network/constants";
import {Row} from "./index";
import ImageViewerImage from './ImageViewerImage';

const ImageViewer = ({id, idx, images, isOpen, toggle}) => {
    const [index, setIndex] = useState(0);

    const moveNext = (e) => {
        e.stopPropagation();
        console.log(index);
        setIndex(prevState => {
            console.log(prevState);

            return prevState === images.length - 1 ? 0 : prevState + 1
        });
    };

    const movePrevious = (e) => {
        e.stopPropagation();
        setIndex(prevState => prevState > 0 ? (prevState - 1) : images.length - 1);
    };

    useEffect(() => {
        setIndex(idx);
    }, [idx]);
    console.log(images?.[index]);

    const prepareSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (parseInt(bytes, 0) === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(bytes / Math.pow(1024, i)).toString() + ' ' + sizes[i];
    };

    return (
        <div className={'image-viewer'} style={{}} hidden={!isOpen}>
            <span className={'close'} onClick={e => {
                e.stopPropagation();
                toggle();
            }}>&times;</span>
            <span className={'image-viewer-right'} onClick={moveNext}>&rsaquo;</span>
            <span className={'image-viewer-left'} onClick={movePrevious}>&lsaquo;</span>
            {images &&
                <ImageViewerImage id={`${id}-full-size-images`} src={`${socketUrl}/${images[index]?.image_path}`}/>
            }
            {images &&
                <span className={'image-viewer-info'}>
                <Row>
                    Filename: <a href={`${socketUrl}/${images[index]?.image_path}`} rel={'noopener noreferrer'}
                                 className={'image-viewer-link'}
                                 target={'_blank'}>{images[index]?.image_name}</a>
                </Row>
                <Row>
                     Filesize: {prepareSize(images[index]?.size)}
                </Row>
            </span>}
            {images &&
                <div className={'image-viewer-thumbnails'}>
                    {/*{images[index] && <ul>*/}
                    {/*    {images[index].comments.map(m => m)}*/}
                    {/*</ul>}*/}
                    {images.map((i, _index) => <img className={'image-viewer-thumbnail'} onClick={() => setIndex(_index)} id={`${i.id}-thumbnail-image`}
                                          src={`${socketUrl}/${i.image_path}`} alt={''}/>)}
                </div>
            }
        </div>
    )
};

export default ImageViewer;
