/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import * as funcs from './AllTasks.helpers';
import BotTasks from '../BotTasks/BotTasks';

const defaultProps = {};
const AllTasks = (props) => {
    const atRef = React.useRef();
    const setupData = () => funcs.setupData(props);
    React.useEffect(() => {
        setupData();
    }, []);
    const {tasks, PriorityList, workorder, task, unit} = props;
    AllTasks.defaultProps = props;

    return (
        <BotTasks ref={atRef} data={tasks ? tasks : null}
                  workorder={workorder}
                  tblId={'atRef'}
                  priorityList={PriorityList}
                  popoutAction={true} viewAction={true} actionCount={2}
                  title={'All Tasks'}
                  pageSizes={['20', '50', '100', '500', '1000']}
                  getTasks={(req) => props.getTasks(req, 'tasks')}
                  unit={unit}
                  remote={true}
                  vendorList={props.vendorList}
                  mechanicList={props.mechanicList}
                  lStorage={'all_task_storage'}
                  getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                  csvUrl={'tasks/'}
                  csvFile={'all_task.csv'}
                  getMyUnit={(id) => props.getMyUnit(id)}
                  updateTask={(t) => props.updateTask(t)}
                  task={task}
                  plm_alert={props.plm_alert}
                  loading={props.loading}
                  u_preferences={props.user_preference}
                  // getMyAlert={(id) => props.getAlert(id)}
                  getMyTask={(id) => props.getTask(id)}/>
    );
};

AllTasks.defaultProps = defaultProps;
export default connect(funcs.mapStateToProps, funcs.mapDispatchToProps)(AllTasks);
