import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { mapToCssModules, omit, pick, TransitionTimeouts, TransitionPropTypeKeys, TransitionStatuses, tagPropType } from './utils';

const propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    tag: tagPropType,
    className: PropTypes.node,
    navbar: PropTypes.bool,
    cssModule: PropTypes.object,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.object
    ]),
};

const defaultProps = {
    ...Transition.defaultProps,
    isOpen: false,
    appear: false,
    enter: true,
    exit: true,
    tag: 'div',
    timeout: TransitionTimeouts.Collapse,
};

const transitionStatusToClassHash = {
    [TransitionStatuses.ENTERING]: 'collapsing',
    [TransitionStatuses.ENTERED]: 'collapse show',
    [TransitionStatuses.EXITING]: 'collapsing',
    [TransitionStatuses.EXITED]: 'collapse',
};

const getTransitionClass = (status) => {
    return transitionStatusToClassHash[status] || 'collapse';
}

const getHeight = (node) => {
    return node.scrollHeight;
}

const Collapse = ({ tag: Tag, isOpen, className, navbar, cssModule, children, innerRef, ...otherProps }) => {
    const [state, updateState] = useState({
        height: null
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    // constructor(props) {
    //     super(props);
    //
    //     this.state = {
    //         height: null
    //     };
    //
    //     ['onEntering', 'onEntered', 'onExit', 'onExiting', 'onExited'].forEach((name) => {
    //         this[name] = this[name].bind(this);
    //     });
    // }

    const onEntering = (node, isAppearing) => {
        setState({ height: getHeight(node) });
        otherProps.onEntering(node, isAppearing);
    }

    const onEntered = (node, isAppearing) => {
        setState({ height: null });
        otherProps.onEntered(node, isAppearing);
    }

    const onExit = (node) => {
        setState({ height: getHeight(node) });
        otherProps.onExit(node);
    }

    const onExiting = (node) => {
        // getting this variable triggers a reflow
        setState({ height: 0 });
        otherProps.onExiting(node);
    }

    const onExited = (node) => {
        setState({height: null});
        otherProps.onExited(node);
    }

    const {height} = state;
    const transitionProps = pick(otherProps, TransitionPropTypeKeys);
    const childProps = omit(otherProps, TransitionPropTypeKeys);

    return (
        <Transition
            {...transitionProps}
            in={isOpen}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
        >
            {(status) => {
                let collapseClass = getTransitionClass(status);
                const classes = mapToCssModules(classNames(
                    className,
                    collapseClass,
                    navbar && 'navbar-collapse'
                ), cssModule);
                const style = height === null ? null : {height};
                return (
                    <Tag
                        {...childProps}
                        style={{...childProps.style, ...style}}
                        className={classes}
                        ref={otherProps.innerRef}
                    >
                        {children}
                    </Tag>
                );
            }}
        </Transition>
    );
}

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;
export default Collapse;
