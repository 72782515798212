// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const plmAlertService = {};

const apiUrl = baseUrl;

plmAlertService.getAll = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/plm_alerts`, requestOptions).then(handleResponse);
};

plmAlertService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/plm_alert/` + id, requestOptions).then(handleResponse);
};
