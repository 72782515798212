import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
// import {createLogger} from 'redux-logger';
import rootReducer from '../network/reducers';
import {history} from './history';
import {connectRouter, routerMiddleware} from 'connected-react-router'

// const loggerMiddleware = createLogger();

// noinspection JSCheckFunctionSignatures
export const store = createStore(
    connectRouter(history)(rootReducer),
    applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        // loggerMiddleware
    )
);
