import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
    editButton,
    prepareDOT,
    prepareLink,
    prepareUnit,
    prepareWorkorder,
    styleUnitStatus
} from './BotWorkOrders.helpers';
import {_dateFormat, getColumn} from '../../../helpers';
export const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    data: PropTypes.array,
    getWorkorders: PropTypes.func.isRequired,
    getMyWorkOrder: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    unit: PropTypes.object,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    updateWorkorder: PropTypes.func,
    jobList: PropTypes.array,
    yardList: PropTypes.array,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    workorder: PropTypes.object,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    toggleUnitModal: PropTypes.func,
    toggleWorkorderModal: PropTypes.func,
    canCreate: PropTypes.bool,
    unitStatusList: PropTypes.any,
    createWorkorder: PropTypes.func,
    includeDateRange: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    parentComp: PropTypes.string
};
export const defaultFilters = {
    id: "",
    workorder_status: "",
    general_status: "",
    unit_number: "",
    unit_status: "",
    workorder_priority: "",
    dot: "",
    deficient_properties: "",
    unit_type: "",
    unit_class: "",
    unit_description: "",
    other_description: "",
    vendor: "",
    mechanic: "",
    job: "",
    yard: ""
};
export const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: _.cloneDeep(defaultFilters)
};
export const getColumns = (props, refs, toggleWorkOrderModal, toggleUnitModal, editGeneralStatus, editJob, editYard) => props.columns ? props.columns : [
    getColumn('expand', '', 40, null, false, null, false, null),
    getColumn('button', 'Views', props.actionCount * 40, (cell, row) => editButton(cell, row, props, toggleWorkOrderModal), false, null, null, null),
    getColumn('id', 'Work Order#', 180, cell => prepareWorkorder(cell, props, toggleWorkOrderModal), false, refs[0], true, {
        type: 'text',
        placeholder: 'Work Order#'
    }, null),
    getColumn('created_at', 'Created', 150, cell => _dateFormat(cell), false, refs[1], true, null),
    getColumn('updated_at', 'Last Updated', 150, cell => _dateFormat(cell), false, refs[2], true, null, null),
    getColumn('completed_at', 'Completed', 150, cell => _dateFormat(cell), false, refs[3], true, null, null),
    getColumn('workorder_status', 'WO Status', 180, null, false, refs[4], true, {
        type: 'select',
        placeholder: 'WO Status',
        options: [{value: "", label: 'Select Status'}, {value: 'Open', label: 'Open'}, {value: 'Closed', label: 'Closed'}]
    }, null),
    getColumn('general_status', 'Status Notes', 200, null, false, refs[5], true, {
        type: 'text',
        placeholder: 'Status Notes',
    }, {
        value: true,
        onClick: (row) => editGeneralStatus(row)
    }),
    getColumn('unit_number', 'Equipment#', 150, props.parentComp !== 'unit' ? (cell, row) => prepareUnit(cell, row, props, toggleUnitModal) : null, false, refs[6], true, {
        type: 'text',
        placeholder: 'Equipment#',
    }, null),
    getColumn('unit_status', 'Equip. Status', 150, null, false, refs[7], true, {
        type: 'text',
        placeholder: 'Equip. Status',
    }, null, (cell) => styleUnitStatus(cell)),
    getColumn('workorder_priority', 'Priority', 150, null, false, refs[8], true, {
        type: 'select',
        placeholder: 'Priority',
        options: [{value: '""', label: 'Select Priority'}, {value: 'High', label: 'High'}, {value: 'Medium', label: 'Medium'}, {value: 'Low', label: 'Low'}]
    }, null),
    getColumn('dot', 'D.O.T.', 150, cell => prepareDOT(cell), false, refs[9], true, {
        type: 'select',
        placeholder: 'D.O.T.',
        options: [{value: "", label: 'Select D.O.T.'}, {value: true, label: 'D.O.T.'}, {value: false, label: 'Not-D.O.T.'}]
    }, null),
    getColumn('deficient_properties', 'Tasks', 150, null, false, refs[10], true, {
        type: 'text',
        placeholder: 'Tasks'
    }, null),
    getColumn('unit_type', 'Type', 150, null, false, refs[11], true, {
        type: 'text',
        placeholder: 'Type'
    }, null),
    getColumn('unit_description', 'Equipment Description', 150, null, false, refs[12], true, {
        type: 'text',
        placeholder: 'Equipment Description'
    }, null),
    getColumn('other_description', 'Repair Description', 150, null, false, refs[13], true, {
        type: 'text',
        placeholder: 'Repair Description',
    }, null),
    getColumn('vendor', 'Service Provider Name', 150, null, false, refs[14], true, {
        type: 'text',
        placeholder: 'Service Provider Name',
    }, null),
    getColumn('mechanic', 'Mechanic', 150, null, false, refs[15], true, {
        type: 'text',
        placeholder: 'Mechanic',
    }, null),
    getColumn('job', 'Job', 150, null, false, refs[16], true, {
        type: 'text',
        placeholder: 'Job',
    }, {
        value: true,
        onClick: (row) => editJob(row)
    }),
    getColumn('yard', 'Location', 150, null, false, refs[17], true, {
        type: 'text',
        placeholder: 'Location',
    }, {
        value: true,
        onClick: (row) => editYard(row)
    }),
    getColumn('unit_class', 'Class', 150, null, false, refs[18], true, {
        type: 'text',
        placeholder: 'Class',
    }, null),
    getColumn('last_known_gps', 'Map Link', 150, cell => prepareLink(cell), false, refs[19], true, null, null)
];
