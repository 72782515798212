/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Label
} from '../../../components/bot-components';
import {roleActions, unitActions, userActions} from '../../../network/actions';
import ReduxBlockUi from 'react-block-ui';
import EquipmentPm from "../../PmSheets/EquipmentPm";
import PropTypes from 'prop-types';
import {authHeader, store} from '../../../helpers';
import {baseUrl} from '../../../network/constants';
import {logOut} from '../../../network/services/responseHandler';

const propTypes = {
    toggle: PropTypes.func,
    detail: PropTypes.bool
};
const PmCreate = (props) => {
    const [state, updateState] = React.useState({
        selectedUnit: '',
        unit_type: '',
        unit_id: '',
        unit_description: null,
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    const setSelectedUnit = (event) => {
        if (event) {
            props.getUnit(event.value);
            setState({
                selectedUnit: event,
                unit_type: event.unit_type,
                unit_id: event.value,
                unit_description: event.unit_description
            });
        } else {
            setState({
                selectedUnit: '',
                unit_type: '',
                unit_id: '',
                unit_description: null,
            })
        }
    };

    const handleUnitTextChange = (newValue) => {
        setState({unitTextValue: newValue});
    };

    const getUnits = () => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify({query: `select u.id, u.number, u.description, u.unit_type from pumpco_backend.public.units_view u ${state.unitTextValue ? `where lower(u.number) like lower('%${state.unitTextValue}%') order by u.number;` : ` order by u.number limit 25;`}`})
        };
        return fetch(`${baseUrl}/hyperbolic/`, requestOptions).then(response => {
            return response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(data => {
                if (!response.ok) {
                    return null;
                }
                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    store.dispatch(userActions.updateAuth(Auth, null));
                }
                return data.map(u => ({label: u.number, value: u.id, unit_type: u.unit_type, unit_description: u.description}));
            }).catch(() => null) : null
        });
    };

    React.useEffect(async () => {
        const list = await getUnits();
        setState({
            list: list
        });
        props.getOperators();
        if (props.unit.items) {
            const unit = props.detail && props.unit.items;
            setState({
                selectedUnit: {label: unit.number, value: unit.id, unit_type: unit.unit_type},
                unit_type: unit.unit_type,
                unit_id: unit.id,
                unit_description: unit.description,
            })
        }
    }, []);

    const pmSaved = (saved) => {
        if (saved) {
            setState({
                selectedUnit: '',
                unit_type: '',
                unit_id: '',
                unit_description: null,
            });
            props.detail && props.toggle();
        }
    };

    const {selectedUnit, unit_type, unit_description, list} = state;

    return (
        <ReduxBlockUi tag={'div'} blocking={props.units?.loading} message={<Label style={{paddingTop: '2rem'}} className={'blocking-loading-lbl'}>Please Wait</Label>}>
            <InputGroup>
                <InputGroupAddon addonType={'prepend'}><InputGroupText>New PM for Equipment</InputGroupText></InputGroupAddon>
                <Input type={'react-async-select'} loadOptions={getUnits}
                       onChange={(selectedUnit) => setSelectedUnit(selectedUnit)}
                       defaultOptions={list}
                       value={selectedUnit}
                       cacheOptions
                       onInputChange={handleUnitTextChange}/>
                {unit_description &&
                <InputGroupAddon
                    addonType={'append'}><InputGroupText>{unit_description}</InputGroupText></InputGroupAddon>}
            </InputGroup>
            <hr/>
            {unit_type && <EquipmentPm onSavePm={pmSaved}/>}
        </ReduxBlockUi>
    );
}
const mapStateToProps = (state) => {
    const {unit} = state;
    return {unit};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOperators: () => dispatch(roleActions.getOperators()),
        getUnit: (id) => dispatch(unitActions.getUnit(id)),
    }
};
PmCreate.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(PmCreate);
