import React, {useEffect, useState} from 'react'
import {
  CustomInput,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, SaveCancel
} from '../bot-components';

const defaultItem = {
  name: '',
  photo: false,
  photo_required: false,
  photo_count: 0,
  photo_only: false,
  details: false,
  details_only: false,
  details_required: false,
  deleted: false,
  static: false,
  sort_order: 0
};

const CreateItemModal = ({isOpen, toggle, saveItem}) => {
  const [item, setItem] = useState(null);

  const _toggle = () => {
    setItem(defaultItem);
    toggle?.();
  };

  const onSave = () => {
    saveItem(item);
    setItem(defaultItem);
    toggle?.();
  };

  useEffect(() => {
    setItem(defaultItem);
  }, []);

  return(
    <Modal isOpen={isOpen} toggle={_toggle}>
      <ModalHeader>
        Create New PM Item
      </ModalHeader>
      <ModalBody>
        <Label for={'inspectionCheck'}>Item: </Label>
        <Input name={'inspectionCheck'} type={'text'} value={item?.name}
               onChange={(e) => setItem(prevState => ({
                 ...prevState,
                 name: e.target.value
               }))}/>
        <Row style={{margin: '0.5rem'}}>
          <CustomInput id={'details_checkbox'} type={'checkbox'} label={'Details'}
                       checked={item?.details} onChange={() => setItem(prevState => ({
            ...prevState,
            details: !prevState.details,
            details_required: false
          }))}/>
          {item?.details &&
            <CustomInput id={'details_only_checkbox'} type={'checkbox'}
                         label={'Details Only'}
                         defaultChecked={item?.details_only}
                         onChange={() => setItem(prevState => ({
                           ...prevState,
                           details_only: !prevState.details_required
                         }))}/>}
          {item?.details &&
            <CustomInput id={'details_required_checkbox'} type={'checkbox'}
                         label={'Details Required'}
                         defaultChecked={item?.details_required}
                         onChange={() => setItem(prevState => ({
                           ...prevState,
                           details_required: !prevState.details_required
                         }))}/>}
          <CustomInput id={'photo_checkbox'} type={'checkbox'} label={'Photo'}
                       checked={item?.photo} onChange={() => setItem(prevState => ({
            ...prevState,
            photo: !prevState.photo,
            photo_count: !prevState.photo ? 1 : 0
          }))}/>
          {item?.photo &&
            <CustomInput id={'photo_required_checkbox'} type={'checkbox'}
                         label={'Photo Required'}
                         checked={item?.photo_required}
                         onChange={() => setItem(prevState => ({
                           ...prevState,
                           photo_required: !prevState.photo_required
                         }))}/>}
          {item?.photo &&
            <CustomInput id={'photo_only_checkbox'} type={'checkbox'} label={'Photo Only'}
                         checked={item?.photo_only}
                         onChange={() => setItem(prevState => ({
                           ...prevState,
                           photo: !prevState.photo_only ? true : prevState.photo,
                           photo_only: !prevState.photo_only
                         }))}/>}
        </Row>
        {item?.photo &&
          <>
            Photo count<Input type={'number'} min={1} max={4}
                              id={`${item.id}_photo_count`}
                              onChange={(e) => setItem(prevState => ({
                                ...prevState,
                                photo_count: e.target.value
                              }))}
                              value={item.photo_count}/>
          </>}
      </ModalBody>
      <ModalFooter>
        <SaveCancel onSaveClick={onSave} onCancelClick={_toggle}/>
      </ModalFooter>
    </Modal>
  );
};

export default CreateItemModal;