import {jobStatusConstants} from '../constants/';
import {jobStatusService} from '../services/';

export const jobStatusActions = {};
jobStatusActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: jobStatusConstants.GET_ALL_JOB_STATUSES_REQUEST});
    const success = (job_statuses) => ({type: jobStatusConstants.GET_ALL_JOB_STATUSES_SUCCESS, job_statuses});
    const failure = (error) => ({type: jobStatusConstants.GET_ALL_JOB_STATUSES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobStatusService.getAll()
            .then(job_statuses => dispatch(success(job_statuses)), error => dispatch(failure(error)));
    };
};
jobStatusActions.getJobStatus = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: jobStatusConstants.DETAIL_JOB_STATUS_REQUEST});
    const success = (job_status) => ({type: jobStatusConstants.DETAIL_JOB_STATUS_SUCCESS, job_status});
    const failure = (error) => ({type: jobStatusConstants.DETAIL_JOB_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobStatusService.getDetail(id)
            .then(job_status => dispatch(success(job_status)), error => dispatch(failure(error)));
    };
};
jobStatusActions.createJobStatus = (job_status) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: jobStatusConstants.CREATE_JOB_STATUS_REQUEST});
    const success = (job_status) => ({type: jobStatusConstants.CREATE_JOB_STATUS_SUCCESS, job_status});
    const failure = (error) => ({type: jobStatusConstants.CREATE_JOB_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobStatusService.createJobStatus(job_status)
            .then(job_status => dispatch(success(job_status)), error => dispatch(failure(error)));
    };
};
jobStatusActions.updateJobStatus = (job_status) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: jobStatusConstants.UPDATE_JOB_STATUS_REQUEST});
    const success = (job_status) => ({type: jobStatusConstants.UPDATE_JOB_STATUS_SUCCESS, job_status});
    const failure = (error) => ({type: jobStatusConstants.UPDATE_JOB_STATUS_SUCCESS, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobStatusService.updateJobStatus(job_status)
            .then(job_status => dispatch(success(job_status)), error => dispatch(failure(error)));
    };
};
