import React, {useEffect, useCallback} from 'react';
import usePanAndZoom from './ImageViewerImage.hook';
const ImageViewerImage = ({ src }) => {
    const {
        containerRef,
        onMouseDown,
        onWheel,
        onDoubleClick,
        translateX,
        translateY,
        scale,
        reset: _reset,
        panning
    } = usePanAndZoom();
    const reset = useCallback(() => _reset(), [_reset]);
    useEffect(() => {
        reset();
    }, [src, reset]);
    return (
        <div
            className="image-viewer-content"
            ref={containerRef}
            onMouseDown={onMouseDown}
            onWheel={onWheel}
            onDoubleClick={onDoubleClick}
            style={{
                transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
            }}
        >
                <img
                    className={`image-viewer-image ${panning ? 'panning' : ''}`}
                    alt="panable-and-zoomable"
                    src={src}
                />
        </div>
    );
};

export default ImageViewerImage;
