/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Label,
  Col,
  Row,
  Input
} from '../../components/bot-components';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import { jobActions, reportActions } from '../../network/actions';
import { authHeader } from '../../helpers';
import { handleResponse } from '../../network/services/responseHandler';
import { baseUrl } from '../../network/constants';

const defaultForeState = {
  foreSD:new moment().subtract( 30, 'days' ).format( 'l' ),
  foreED:new moment().format( 'l' ),
  foreEmail:'',
  foreSelectedJob:'',
};

const defaultInsState = {
  insSD:new moment().subtract( 30, 'days' ).format( 'l' ),
  insED:new moment().format( 'l' ),
  insEmail:'',
  insSelectedJob:''
};

const defaultCWOState = {
  cwoSD:new moment().subtract( 7, 'days' ).format( 'l' ),
  cwoED:new moment().format( 'l' ),
};

const defaultWoState = {
  woEmail:'',
  woSelectedJob:''
};

const defaultMaintState = {
  maintEmail:''
};

const defaultMaint1State = {
  maint1Email:''
};

const ReportDashboard = ( props ) => {
  const [state, updateState] = React.useState( {
    ...defaultForeState,
    ...defaultInsState,
    ...defaultWoState,
    ...defaultMaintState,
    ...defaultMaint1State,
    ...defaultCWOState
  } );

  /**
   *
   * @param {Object} newState
   */

  const setState = newState => updateState( ( prevState ) => ({
    ...prevState,
    ...newState
  }) );

  React.useEffect( () => {
    props.getJobs();
  }, [] );

  const getWorkOrderReport = () => {
    const body = {
      job_id:state.woSelectedJob.value,
      email:state.woEmail
    };
    props.getWorkOrderByJobReport( body );
    alert( `An email of the requested data will be set to ${ state.woEmail }` );
    setState( defaultWoState );
  };

  const getInsReport = () => {
    const startDate = new moment( state.insSD ).format( 'YYYY-MM-DD' );
    const endDate = new moment( state.insED ).format( 'YYYY-MM-DD' );
    const body = {
      startDate:endDate,
      endDate:startDate,
      job_id:state.insSelectedJob.value,
      email:state.insEmail
    };
    props.getInsReport( body );
    alert( `An email of the requested data will be sent to ${ state.insEmail }` );
    setState( defaultInsState );
  };

  const getCWOReport = () => {
    const startDate = new moment( state.cwoSD ).format( 'YYYY-MM-DD' );
    const endDate = new moment( state.cwoED ).format( 'YYYY-MM-DD' );
    const body = {
      startDate:endDate,
      endDate:startDate
    };
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( body )
    };
    return fetch( `${ baseUrl }/reports/work_orders/closed_report/`, requestOptions ).then( response => {
      response.blob().then( blob => {
        const url = window.URL.createObjectURL( new Blob( [blob] ) );
        const link = document.createElement( 'a' );
        link.href = url;
        link.setAttribute( 'download', `work_orders_closed_week_of_${ startDate }.csv` );
        document.body.appendChild( link );
        link.click();
        link.parentNode.removeChild( link );
        setState( defaultCWOState );
      } );
    } );
  };

  const getMaintReport1 = () => {
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( { email:state.maint1Email } )
    };

    fetch( `${ baseUrl }/workorders/ht_report`, requestOptions ).then( handleResponse );
    alert( `An email of the requested data will be set to ${ state.maint1Email }` );
    setState( defaultMaint1State );
  };

  const changeProps = ( e ) => {
    const { value, name } = e.target;
    setState( {
      [name]:value
    } );
  };

  const handleInsSDChange = ( date ) => {
    setState( {
      insSD:date
    } );
  };

  const handleInsEDChange = ( date ) => {
    setState( {
      insED:date
    } );
  };

  const handleCWOSDChange = ( date ) => {
    setState( {
      cwoSD:date
    } );
  };

  const handleCWOEDChange = ( date ) => {
    setState( {
      cwoED:date
    } );
  };

  const insValid = () => state.insSelectedJob === '' || state.insEmail === '';

  const woValid = () => state.woSelectedJob === '' || state.woEmail === '';

  const { authentication, BotModules } = props;

  return (
    <div>
      { !!BotModules?.find( m => m.name === 'workorders' ) && <Card style={ { overflow:'visible' } }>
        <CardHeader>
          Closed Work Order Report
        </CardHeader>
        <CardBody style={ { overflow:'visible' } }>
          <Row>
            <Col sm={ 12 }>
              <Row>
                <Col sm={ 12 }>
                  <Row>
                    <Col sm={ 6 }><Label>Start Date:</Label></Col><Col sm={ 6 }><Label>End
                    Date:</Label></Col>
                  </Row>
                  <Row>
                    <Col sm={ 6 }>
                      <DatePicker onChange={ handleCWOSDChange }
                                  dateFormatCalendar={ 'yyyy-MM-dd' }
                                  dateFormat={ 'yyyy-MM-dd' }
                                  selected={ new Date( state.cwoSD ) }
                                  onMonthChange={ handleCWOSDChange }
                                  dropdownMode={ 'select' }
                                  style={ { paddingLeft:'0' } }
                      />
                    </Col>
                    <Col sm={ 6 }>
                      <DatePicker onChange={ handleCWOEDChange }
                                  dateFormatCalendar={ 'yyyy-MM-dd' }
                                  dateFormat={ 'yyyy-MM-dd' }
                                  selected={ new Date( state.cwoED ) }
                                  onMonthChange={ handleCWOEDChange }
                                  dropdownMode={ 'select' }
                                  style={ { paddingLeft:'0' } }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button outline color={ 'texts' } onClick={ () => {
            return getCWOReport();
          } }>Submit</Button><Button outline color={ 'texts' } onClick={ () => {
          setState( defaultCWOState );
        } }>Reset</Button>
        </CardFooter>
      </Card> }
      { !!BotModules?.find( m => m.name === 'inspections' ) && <Card style={ { overflow:'visible' } }>
        <CardHeader>
          Inspection Report
        </CardHeader>
        <CardBody style={ { overflow:'visible' } }>
          <Row>
            <Col sm={ 12 }>
              <Row>
                <Col sm={ 4 }>
                  <Row>
                    <Col sm={ 6 }><Label>Start Date:</Label></Col><Col sm={ 6 }><Label>End
                    Date:</Label></Col>
                  </Row>
                  <Row>
                    <Col sm={ 6 }>
                      <DatePicker onChange={ handleInsSDChange }
                                  dateFormatCalendar={ 'yyyy-MM-dd' }
                                  dateFormat={ 'yyyy-MM-dd' }
                                  selected={ new Date( state.insSD ) }
                                  onMonthChange={ handleInsSDChange }
                                  dropdownMode={ 'select' }
                                  style={ { paddingLeft:'0' } }
                      />
                    </Col>
                    <Col sm={ 6 }>
                      <DatePicker onChange={ handleInsEDChange }
                                  dateFormatCalendar={ 'yyyy-MM-dd' }
                                  dateFormat={ 'yyyy-MM-dd' }
                                  selected={ new Date( state.insED ) }
                                  onMonthChange={ handleInsEDChange }
                                  dropdownMode={ 'select' }
                                  style={ { paddingLeft:'0' } }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={ 8 }>
                  <Row>
                    <Col sm={ 6 }>
                      <Label>Job:</Label><span
                      style={ { color:'red', float:'right' } }>Required *</span>
                    </Col>
                    <Col sm={ 6 }>
                      <Label>Email:</Label><span
                      style={ { color:'red', float:'right' } }>Required *</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={ 6 }>
                      <Select multi={ false } options={ props.jobList }
                              placeholder={ 'Select Job' }
                              matchProp={ 'label' }
                              value={ state.insSelectedJob }
                              onChange={ ( selectedJob ) => {
                                setState( { insSelectedJob:selectedJob } );
                              } }
                      />
                    </Col>
                    <Col sm={ 6 }>
                      <Input type={ 'textarea' } name={ 'insEmail' } autoComplete={ 'none' }
                             value={ state.insEmail } onChange={ changeProps }
                             placeholder={ 'Enter multiple emails separated by a comma(,)' }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button outline color={ 'texts' } disabled={ insValid() } onClick={ () => {
            getInsReport();
          } }>Submit</Button><Button outline color={ 'texts' } onClick={ () => {
          setState( defaultInsState );
        } }>Reset</Button>
        </CardFooter>
      </Card> }
      { !!BotModules?.find( m => m.name === 'workorders' ) && <Card style={ { overflow:'visible' } }>
        <CardHeader>
          Work Order By Job Report
        </CardHeader>
        <CardBody style={ { overflow:'visible' } }>
          <Row>
            <Col sm={ 12 }>
              <Row>
                <Col sm={ 6 }>
                  <Label>Job:</Label><span
                  style={ { color:'red', float:'right' } }>Required *</span>
                </Col>
                <Col sm={ 6 }>
                  <Label>Email:</Label><span
                  style={ { color:'red', float:'right' } }>Required *</span>
                </Col>
              </Row>
              <Row>
                <Col sm={ 6 }>
                  <Select multi={ false } options={ props.jobList }
                          placeholder={ 'Select Job' }
                          matchProp={ 'label' }
                          value={ state.woSelectedJob }
                          onChange={ ( selectedJob ) => {
                            setState( { woSelectedJob:selectedJob } );
                          } }
                  />
                </Col>
                <Col sm={ 6 }>
                  <Input type={ 'textarea' } name={ 'woEmail' } autoComplete={ 'none' }
                         value={ state.woEmail } onChange={ changeProps }
                         placeholder={ 'Enter multiple emails separated by a comma(,)' }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button outline color={ 'texts' } disabled={ woValid() } onClick={ () => {
            getWorkOrderReport();
          } }>Submit</Button><Button outline color={ 'texts' } onClick={ () => {
          setState( defaultWoState );
        } }>Reset</Button>
        </CardFooter>
      </Card> }
      { !!BotModules?.find( m => m.name === 'workorders' ) && authentication.user && (authentication.user.user.id === 1 || authentication.user.user.id === 6609) &&
        <Card style={ { overflow:'visible' } }>
          <CardHeader>
            Work Order Heavy Truck & Trailer
          </CardHeader>
          <CardBody style={ { overflow:'visible' } }>
            <Row>
              <Col sm={ 12 }>
                <Row>
                  <Col sm={ 12 }>
                    <Label>Email:</Label><span
                    style={ { color:'red', float:'right' } }>Required *</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={ 12 }>
                    <Input type={ 'textarea' } name={ 'maint1Email' } autoComplete={ 'none' }
                           value={ state.maint1Email } onChange={ changeProps }
                           placeholder={ 'Enter multiple emails separated by a comma(,)' }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button outline color={ 'texts' } disabled={ state.maint1Email === '' } onClick={ () => {
              return getMaintReport1();
            } }>Submit</Button><Button outline color={ 'texts' } onClick={ () => {
            setState( defaultMaint1State );
          } }>Reset</Button>
          </CardFooter>
        </Card> }
    </div>
  );
};
const mapStateToProps = ( state ) => {
  const { jobs, authentication, user_preference } = state;
  let jobList = [];
  if (jobs.items) {
    for (let i = 0; i < jobs.items.length; i++) {
      jobList.push( { key:jobs.items[i].id, label:jobs.items[i].name, value:jobs.items[i].id } );
    }
  }
  const BotModules = user_preference.items?.bot_modules;
  return { jobList, authentication, BotModules };
};
const mapDispatchToProps = ( dispatch ) => {
  return ({
    getJobs:() => dispatch( jobActions.getAll() ),
    getInsReport:( body ) => dispatch( reportActions.getInsReport( body ) ),
    getForeReport:( body ) => dispatch( reportActions.getForeReport( body ) ),
    getWorkOrderByJobReport:( body ) => dispatch( reportActions.getWorkOrderByJobReport( body ) )
  });
};
export default connect( mapStateToProps, mapDispatchToProps )( ReportDashboard );
