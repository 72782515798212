import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Col, Row} from '../../components/bot-components';
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    extraProps: PropTypes.any
};

const VendorMechanicAssignedEditor = React.forwardRef((props, ref) => {
        const {row, toggle, isOpen, onUpdate, extraProps} = props;
    const [state, updateState] = React.useState({
        id: null,
        mechanic_id: null,
        vendor_id: null,
        vendor: false,
        selectedMechanic: {label: row.mechanic_name, value: row.mechanic_id},
        selectedVendor: {label: row.vendor_name, value: row.vendor_id}
    });
    function setState(newState) {
        updateState(prevState => ({
            ...prevState,
            ...newState
        }));
    }
    const mechInputRef = React.useRef();
    const vendInputRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        state: state,
        setState: (newState) => setState(newState)
    }));

    const focusInput = () => {
        mechInputRef.current?.focus();
    };

    const updateData = () => {
        toggle();
        onUpdate({
            id: state.id,
            vendor_id: state.vendor_id,
            mechanic_id: state.mechanic_id
        });
    };

    return (
        <Modal size={'xl'} toggle={() => toggle()} centered={true} isOpen={isOpen} onOpened={() => focusInput()}>
            <ModalHeader>
                Edit Mechanic or Service Provider
            </ModalHeader>
            <ModalBody>
                <Col sm={12}>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col sm={3}>
                                    <Label>Mechanic</Label>
                                </Col>
                                <Col sm={9}>
                                    <Select options={extraProps.mechanicList}
                                            ref={mechInputRef}
                                            placeholder={'Select the Mechanic.'}
                                            matchProp={'label'}
                                            value={state.selectedMechanic}
                                            onChange={(selectedMechanic) => {setState({selectedMechanic: selectedMechanic, mechanic_id: selectedMechanic ? selectedMechanic.value : null, vendor: selectedMechanic ? false : state.vendor, vendor_id: selectedMechanic ? null : state.vendor_id})}}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <Col sm={3}>
                                    <Label>Service Provider</Label>
                                </Col>
                                <Col sm={9}>
                                    <Select options={extraProps.vendorList}
                                            ref={vendInputRef}
                                            placeholder={'Select the Service Provider.'}
                                            matchProp={'label'}
                                            value={state.selectedVendor}
                                            onChange={(selectedVendor) => {setState({selectedVendor: selectedVendor, vendor_id: selectedVendor ? selectedVendor.value : null, vendor: !!selectedVendor, mechanic_id: selectedVendor ? null : state.mechanic_id})}}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button color={'texts'} outline onClick={updateData}>Save</Button>
                <Button color={'texts'} outline onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
});

VendorMechanicAssignedEditor.propTypes = propTypes;
export default VendorMechanicAssignedEditor;
