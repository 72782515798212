export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_ALL_REQUEST: 'USERS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USERS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USERS_GET_ALL_FAILURE',

    GET_PAG_USERS_REQUEST: 'GET_PAG_USERS_REQUEST',
    GET_PAG_USERS_SUCCESS: 'GET_PAG_USERS_SUCCESS',
    GET_PAG_USERS_FAILURE: 'GET_PAG_USERS_FAILURE',

    GET_MECHANICS_REQUEST: 'USERS_GET_MECHANICS_REQUEST',
    GET_MECHANICS_SUCCESS: 'USERS_GET_MECHANICS_SUCCESS',
    GET_MECHANICS_FAILURE: 'USERS_GET_MECHANICS_FAILURE',

    GET_MANAGERS_REQUEST: 'USERS_GET_MANAGERS_REQUEST',
    GET_MANAGERS_SUCCESS: 'USERS_GET_MANAGERS_SUCCESS',
    GET_MANAGERS_FAILURE: 'USERS_GET_MANAGERS_FAILURE',

    GET_OPERATORS_REQUEST: 'USERS_GET_OPERATORS_REQUEST',
    GET_OPERATORS_SUCCESS: 'USERS_GET_OPERATORS_SUCCESS',
    GET_OPERATORS_FAILURE: 'USERS_GET_OPERATORS_FAILURE',

    UPDATE_USER_REQUEST: 'USERS_UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'USERS_UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'USERS_UPDATE_USER_FAILURE',

    CREATE_USER_REQUEST: 'USERS_CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'USERS_CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'USERS_CREATE_USER_FAILURE',

    USER_DETAIL_REQUEST: 'USER_DETAIL_REQUEST',
    USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
    USER_DETAIL_FAILURE: 'USER_DETAIL_FAILURE',

    USER_PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',

    GET_ALL_TIME_SHEET_USERS_REQUEST: 'GET_ALL_TIME_SHEET_USERS_REQUEST',
    GET_ALL_TIME_SHEET_USERS_SUCCESS: 'GET_ALL_TIME_SHEET_USERS_SUCCESS',
    GET_ALL_TIME_SHEET_USERS_FAILURE: 'GET_ALL_TIME_SHEET_USERS_USERS_FAILURE',

    GET_ALL_LOGIN_EXCEPTIONS_REQUEST: 'GET_ALL_LOGIN_EXCEPTIONS_REQUEST',
    GET_ALL_LOGIN_EXCEPTIONS_SUCCESS: 'GET_ALL_LOGIN_EXCEPTIONS_SUCCESS',
    GET_ALL_LOGIN_EXCEPTIONS_FAILURE: 'GET_ALL_LOGIN_EXCEPTIONS_FAILURE',
};
