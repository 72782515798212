import {
    roleActions,
    taskActions,
    taskPriorityActions,
    unitActions,
    vendorActions,
    workorderActions
} from '../../../network/actions';

export const mapStateToProps = (state) => {
    const {mechanics, plm_alert, unit, workorder, task_priorities, vendors, task, user_preference} = state;
    let taskStatuses = [false, true];
    let mechanicList = mechanics?.items?.map(m => ({label: m.name, text: m.name, value: m.id}));
    let vendorList = vendors?.items?.map(v => ({label: v.name, text: v.name, value: v.id}));
    let PriorityList = task_priorities?.items?.map(t => ({label: t.priority, text: t.priority, value: t.id}));
    return {workorder, taskStatuses, mechanicList, vendorList, PriorityList, plm_alert, unit, task, user_preference}
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getAllOpen: (req) => dispatch(taskActions.getOpen(req)),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
        updateTask: (task) => dispatch(taskActions.updateTask(task)),
        // getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
        getMechanics: () => dispatch(roleActions.getMechanics()),
        getVendors: () => dispatch(vendorActions.getAll()),
        assignToMechanic: (task) => dispatch(taskActions.assignToMechanic(task)),
        assignToVendor: (task) => dispatch(taskActions.assignToVendor(task))
    }
};
