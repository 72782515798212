import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    display: PropTypes.any,
    mobile: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    type: PropTypes.string
};

let defaultProps = {
    display: 'lg',
    mobile: false,
    tag: 'button',
    type: 'button'
};

let AppSidebarToggler = function (_Component) {
    _inherits(AppSidebarToggler, _Component);

    function AppSidebarToggler(props) {
        _classCallCheck(this, AppSidebarToggler);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.sidebarToggle = _this.sidebarToggle.bind(_this);
        return _this;
    }

    AppSidebarToggler.prototype.sidebarToggle = function sidebarToggle(e) {
        e.preventDefault();

        // document.body.classList.toggle('sidebar-minimized');
        // let sidebar = document.querySelector('.sidebar-nav');
        // if (sidebar) {
        //     let toggleOn = sidebar.classList.toggle('ps');
        //     sidebar.classList.toggle('scrollbar-container', toggleOn);
        //     sidebar.classList.toggle('ps--active-y', toggleOn);
        // }

        if (this.props.mobile) {
            document.body.classList.toggle('sidebar-show');
        } else {
            // let display = this.props.display;
            // let cssTemplate = 'sidebar-' + display + '-show';
            // let _sidebarCssClasses$ = sidebarCssClasses[0],
            //     cssClass = _sidebarCssClasses$[0];
            //
            // if (display && sidebarCssClasses.indexOf(cssTemplate) > -1) {
            //     cssClass = cssTemplate;
            // }
            // toggleClasses(cssClass, sidebarCssClasses);
            document.body.classList.toggle('sidebar-minimized');
            let sidebar = document.querySelector('.sidebar-nav');
            if (sidebar) {
                let toggleOn = sidebar.classList.toggle('ps');
                sidebar.classList.toggle('scrollbar-container', toggleOn);
                sidebar.classList.toggle('ps--active-y', toggleOn);
            }
        }
    };

    AppSidebarToggler.prototype.render = function render() {
        let _this2 = this;

        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        delete attributes.mobile;
        delete attributes.display;

        let classes = classNames(className, 'navbar-toggler');

        return React.createElement(
            Tag,
            _extends({ type: 'button', className: classes }, attributes, { onClick: function onClick(event) {
                    return _this2.sidebarToggle(event);
                } }),
            children || React.createElement('span', { className: 'navbar-toggler-icon' })
        );
    };

    return AppSidebarToggler;
}(Component);

AppSidebarToggler.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSidebarToggler.defaultProps = defaultProps;

export default AppSidebarToggler;
