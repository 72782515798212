// noinspection ES6RedundantAwait

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  BotTable,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PmAvailableCheck,
  PmConfirmationModal,
  PmList,
  PmListDeleteConfirmationModal,
  Row,
  SaveCancel
} from '../../components/bot-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  createPmItem,
  createPMList,
  createPmListItem,
  deletePmList,
  getPmChecks,
  getPmListItems,
  getPmLists,
  getUnitSubtypes,
  getUnitTypes,
  updateAvailableCheck,
  updatePMList,
  updatePmListItems
} from './BotPms.helpers';
import _ from 'lodash';
import { CreateItemModal, CreateListModal } from '../../components/PmComponents';
import update from 'immutability-helper';
import PmCheckListDetail from './PmCheckListDetail';
import TableActions from '../../containers/table_action_component';
import { blockNavigation } from '../../helpers';

//<editor-fold desc="globals">
const defaultFilters = {
  name:'',
  unit_subtype_id:'',
  unit_type_id:'',
  unit_type:'',
  use_hours:'',
  use_mileage:'',
  hours_interval:'',
  hours_threshold:'',
  past_due_hours_threshold:'',
  starting_hours:'',
  mileage_interval:'',
  mileage_threshold:'',
  past_due_mileage_threshold:'',
  starting_mileage:''
};
const myFilters = _.cloneDeep( defaultFilters );
const defaultState = {
  sortName:'',
  sortOrder:'',
  currSize:20,
  currPage:1,
  filters:defaultFilters
};
//</editor-fold>
const BotPms = ( { ...props } ) => {
  //<editor-fold desc="State">
  const [unitTypes, setUnitTypes] = useState( [] );
  const [unitType, setUnitType] = useState( null );
  const [unitSubtypes, setUnitSubtypes] = useState( [] );
  const [unitSubtype, setUnitSubtype] = useState( null );
  const [filteredList, setFilteredList] = useState( [] );
  const [lists, setLists] = useState( [] );
  const [list, setList] = useState( null );
  const [newList, toggleNewList] = useState( false );
  const [confModal, setModal] = useState( { isOpen:false, type:'add', item:null } );
  const [cItemModal, setCItemModal] = useState( false );
  const [confDelModal, setConfDelModal] = useState( false );
  const [delList, setDelList] = useState( null );
  const [selectedChecks, setChecks] = useState( [] );
  const [sortedList, setSortedList] = useState( [] );
  const [attributeList, setAttributeList] = useState( [] );
  const [unfilteredAttributes, setUnfilteredAttributes] = useState( null );
  const [availableSearch, setAvailableSearch] = useState( '' );
  const [listModal, setListModal] = useState( false );
  const [showConfirmCancel, setShowConfirmCancel] = useState( false );
  const [possibleList, setPossibleList] = useState( null );
  //</editor-fold>
  //<editor-fold desc="Column Refs">
  const uhRef = useRef();
  const umRef = useRef();
  const hiRef = useRef();
  const htRef = useRef();
  const pdhRef = useRef();
  const sphRef = useRef();
  const miRef = useRef();
  const mtRef = useRef();
  const pdmRef = useRef();
  const spmRef = useRef();
  const tblRef = useRef();
  const selectRef = useRef();
  //</editor-fold>

  const editButton = ( cell, row ) => {
    return (
      <TableActions ref={ selectRef } key={ 'pms-select-row' } row={ row } edit delete
                    onEdit={ () => {
                      selectList( row );
                      setListModal( true );
                      !disableButtons && setShowConfirmCancel( true );
                    } }
                    onDelete={ () => showListDelModal( row )
                    }/>
    );
  };

  const selectList = ( l ) => {
    if (disableButtons) {
      const ust = unitSubtypes?.find( u => u.id === l.unit_subtype_id );
      const ut = unitTypes?.find( u => u.id === ust.unit_type_id );
      setUnitType( ut );
      setUnitSubtype( ust );
      setList( () => l );
    } else {
      setPossibleList( () => l );
      setShowConfirmCancel( true );
    }
  };

  useEffect( () => {
    getUnitTypes().then( types => setUnitTypes( types ) );
    getUnitSubtypes().then( types => setUnitSubtypes( types ) );
    getPmLists( { unit_type:null, unit_subtype:null } ).then( l => setLists( l ) );
    _getPmChecks();
  }, [] );

  useEffect( () => {
    setFilteredList( unitType ? unitSubtypes.filter( s => s.unit_type_id === unitType.id ) : unitSubtypes );
  }, [unitType, unitSubtypes] );

  useEffect( () => {
    _getPmListItems();
  }, [unitSubtype] );

  useEffect( () => {
    if (list) {
      _getPmListItems();
    }
  }, [list] );

  const _updateAvailableCheck = ( item ) => {
    updateAvailableCheck( item ).then( final => {

      setAttributeList( prevState => {
        const nl = prevState.filter( p => p.id !== final.id );
        return prevState.length > nl.length ? [...nl, final] : nl;
      } );
      setUnfilteredAttributes( prevState => {
        const nl = prevState.filter( p => p.id !== final.id );
        return prevState.length > nl.length ? [...nl, final] : nl;
      } );
    } );
  };

  const onFilterAvailable = ( event ) => {
    const { value } = event.target;

    function setFilteredList() {
      const sc = [...selectedChecks.map( c => c.name )];
      const myList = [...unfilteredAttributes];
      setAttributeList( myList.filter( att => !sc.includes( att.name ) ).filter( attribute => attribute.name.toLowerCase().includes( value.toLowerCase() ) ) );
      setAvailableSearch( value );
    }

    if (!unfilteredAttributes) {
      setUnfilteredAttributes( [...attributeList] );
      setAvailableSearch( value );
      setFilteredList();
    } else {
      setFilteredList();
    }
  };

  const getLists = () => {
    unitSubtype ? getPmLists( { unit_subtype:null } ).then( l => setLists( l ) ) : setLists( [] );
  };

  const columns = [
    {
      field:'button',
      text:'Views',
      width:2 * 40,
      hidden:false,
      format:( cell, row ) => editButton( cell, row ),
      sortable:false
    },
    {
      field:'name',
      text:'Name',
      filter:{
        type:'text',
        placeholder:'Name',
        defaultValue:''
      },
      width:200,
      hidden:false,
      sortable:true
    },
    {
      field:'unit_subtype_id',
      text:'Unit Class',
      filter:{
        type:'select',
        placeholder:'Unit Class',
        options:[{
          value:'',
          label:'Select Value'
        }, ...unitSubtypes.filter( u => lists.map( l => l.unit_subtype_id ).includes( u.id ) ).map( u => ({
          value:u.id,
          label:u.subtype
        }) )],
        defaultValue:myFilters.unit_subtype_id
      },
      format:( cell, row ) => row.unit_subtype,
      width:200,
      hidden:false,
      sortable:true
    },
    {
      field:'unit_type_id',
      text:'Unit Type',
      filter:{
        type:'select',
        placeholder:'Unit Type',
        options:[{
          value:'',
          label:'Select Value'
        }, ...unitTypes.filter( u => lists.map( l => l.unit_type_id ).includes( u.id ) ).map( u => ({
          value:u.id,
          label:u.utype
        }) )],
        defaultValue:''
      },
      format:( cell, row ) => row.unit_type,
      width:200,
      hidden:false,
      sortable:true
    },
    {
      field:'use_hours',
      text:'Use Hours',
      filter:{
        type:'select',
        placeholder:'Use Hours',
        options:[{ value:'', label:'Select Value' }, { value:true, label:'Yes' }, { value:false, label:'No' }],
        defaultValue:''
      },
      width:150,
      hidden:false,
      refProp:uhRef,
      sortable:true,
      format:( cell ) => cell ? 'Yes' : 'No'
    },
    {
      field:'use_mileage',
      text:'Use Mileage',
      filter:{
        type:'select',
        placeholder:'Use Mileage',
        options:[{ value:'', label:'Select Value' }, { value:true, label:'Yes' }, { value:false, label:'No' }],
        defaultValue:''
      },
      format:( cell ) => cell ? 'Yes' : 'No',
      width:150,
      hidden:false,
      sortable:true,
      refProp:umRef
    },
    {
      field:'hours_interval',
      text:'Hours(Interval)',
      filter:{
        type:'text',
        placeholder:'Hours(Interval)',
        defaultValue:''
      },
      width:150,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_hours === true ? cell : '',
      refProp:hiRef
    },
    {
      field:'hours_threshold',
      text:'Hours(Threshold)',
      filter:{
        type:'text',
        placeholder:'Hours(Threshold)',
        defaultValue:''
      },
      width:150,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_hours === true ? cell : '',
      refProp:htRef
    },
    {
      field:'past_due_hours_threshold',
      text:'Past Due Hours(Threshold)',
      filter:{
        type:'text',
        placeholder:'Past Due Hours(Threshold)',
        defaultValue:''
      },
      width:250,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_hours === true ? cell : '',
      refProp:pdhRef
    },
    {
      field:'starting_hours',
      text:'Starting Point(Hours)',
      filter:{
        type:'text',
        placeholder:'Starting Point(hours)',
        defaultValue:''
      },
      width:200,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_hours === true ? cell : '',
      refProp:sphRef
    },
    {
      field:'mileage_interval',
      text:'Mileage(Interval)',
      filter:{
        type:'text',
        placeholder:'Mileage(Interval)',
        defaultValue:''
      },
      width:150,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_mileage === true ? cell : '',
      refProp:miRef
    },
    {
      field:'mileage_threshold',
      text:'Mileage(Threshold)',
      filter:{
        type:'text',
        placeholder:'Mileage(Threshold)',
        defaultValue:''
      },
      width:150,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_mileage === true ? cell : '',
      refProp:mtRef
    },
    {
      field:'past_due_mileage_threshold',
      text:'Past Due Mileage(Threshold)',
      filter:{
        type:'text',
        placeholder:'Past Due Mileage(Threshold)',
        defaultValue:''
      },
      width:250,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_mileage === true ? cell : '',
      refProp:pdmRef
    },
    {
      field:'starting_mileage',
      text:'Starting Point(Mileage)',
      filter:{
        type:'text',
        placeholder:'Starting Point(Mileage)',
        defaultValue:''
      },
      width:200,
      hidden:false,
      sortable:true,
      format:( cell, row ) => row.use_mileage === true ? cell : '',
      refProp:spmRef
    },
  ];

  const finalizeRemoveCheck = async ( item ) => {
    let sc = _.cloneDeep( selectedChecks );
    sc.splice( item.sort_order, 1 );
    sc = await sc.map( ( c, i ) => ({ ...c, sort_order:i }) );
    setChecks( sc?.sort?.( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) );
    const tempItem = {
      ...item,
      id:item.pm_item_id ? item.pm_item_id : item.id
    };
    delete tempItem.pm_item_id;
    delete tempItem.sort_order;
    setAttributeList( prevState => [...prevState, tempItem] );
    setModal( { isOpen:false, type:'remove', item:item } );
  };

  const removeCheck = async ( item ) => {
    setModal( { isOpen:true, type:'remove', item:item } );
  };

  const finalizeAddCheck = async ( item ) => {
    let sc = _.cloneDeep( selectedChecks );
    const check = {
      pm_item_id:item.id,
      pm_list_id:list.id,
      name:item.name,
      photo:item.photo,
      photo_required:item.photo_required ?? false,
      photo_count:item.photo ? (item.photo_count > 0 ? item.photo_count : 1) : 0,
      photo_only:item.photo_only,
      details:item.details,
      details_only:item.details_only ?? false,
      details_required:item.details_required ?? false,
      static:item.static,
      sort_order:item.photo_only ? sc.length : item.sort_order
    };
    !item.photo_only ? sc.splice( item.sort_order, 0, check ) : sc.push( check );
    sc = await sc.map( ( c, i ) => ({ ...c, sort_order:i }) );

    const available = attributeList.map( function ( e ) {
      let exists = false;
      const atts = sc.map( function ( att ) {
        if (att.id === e.id) {
          exists = true;
        }
        return true;
      } );
      return Promise.all( atts ).then( function () {
        if (!exists) {
          // attList.push(e);
        }
      } );
    } );
    Promise.all( available ).then( () => {
      setChecks( sc?.sort?.( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) );
      setAttributeList( prevState => prevState.filter( p => p.name !== item.name ) );
    } );
    setModal( { isOpen:false, type:'add', item:null } );
  };

  const addCheck = ( item ) => {
    setModal( { isOpen:true, type:'add', item:item } );
  };

  const handleDrop = useCallback( ( item, dragIndex, hoverIndex ) => {
    if (item?.isNew) {
      return addCheck( item );
    } else if (dragIndex !== hoverIndex) {
      item.sort_order = hoverIndex;
      setChecks( prevState => {
        return update( prevState, {
          $splice:[
            [dragIndex, 1],
            [hoverIndex, 0, prevState[dragIndex]]
          ]
        } ).map( ( s, i ) => ({ ...s, sort_order:i }) );
      } );
    }
  }, [selectedChecks] );

  const updateDetails = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.details = !c.details;
      c.details_required = c.details ? c.details_required : false;
      _list[index] = c;
      return _list;
    } );
  };

  const updateDetailsRequired = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.details_required = !c.details_required;
      _list[index] = c;
      return _list;
    } );
  };

  const updateDetailsOnly = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.details_only = !c.details_only;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhoto = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      if (c.photo) {
        c.photo = false;
        c.photo_count = 1;
        c.photo_required = false;
      } else c.photo = !c.photo;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoOnly = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.photo_only = !c.photo_only;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoRequired = async ( item ) => {
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.photo_required = !c.photo_required;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoCount = async ( e, item ) => {
    let { value, min, max } = e.target;
    value = Math.max( Number( min ), Math.min( Number( max ), Number( value ) ) );
    setChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.pm_item_id === item.pm_item_id );
      const index = c && prevState.indexOf( c );
      c.photo_count = value;
      _list[index] = c;
      return _list;
    } );
  };

  const _getPmListItems = useCallback( () => {
    if (unitSubtype && list) {
      list?.id && (unitSubtype?.id || unitType?.id) && getPmListItems( {
        unit_type:unitType,
        unit_subtype:unitSubtype,
        list
      } ).then( data => {
        const names = data.map( d => d.name );
        setChecks( data?.map( c => ({
          ...c,
          sort_order:Math.floor( c.sort_order )
        }) ).sort?.( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) );
        setSortedList( data?.map( c => ({
          ...c,
          sort_order:Math.floor( c.sort_order )
        }) ).sort?.( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) );
        setAttributeList( unfilteredAttributes.filter( p => !names.includes( p.name ) ) );
      } ).catch( () => {
        setChecks( [] );
        setSortedList( [] );
      } );
    } else {
      setChecks( [] );
      setSortedList( [] );
      setList( null );
    }
  }, [unitSubtype, unitType, list] );

  const _getPmChecks = useCallback( () => {
    getPmChecks().then( data => {
      setAttributeList( data );
      setUnfilteredAttributes( data );
    } ).catch( () => {
      setAttributeList( [] );
      setUnfilteredAttributes( [] );
    } );
  }, [] );

  const createListItem = async ( item ) => {
    const _item = await createPmItem( item );
    const body = {
      ...item,
      pm_list_id:list.id,
      pm_item_id:_item.id
    };
    await createPmListItem( body );
    await _getPmChecks();
    return _getPmListItems();
  };

  const updateList = async () => {
    await updatePmListItems( list.id, selectedChecks.filter( c => c.included !== true ) );
    possibleList ? setList( () => possibleList ) : _getPmListItems();
  };

  const disableButtons = selectedChecks.length === 0
    ||
    _.isEqual( sortedList, selectedChecks );

  const deleteList = async () => {
    if (delList) {
      const deleted = await deletePmList( delList.id ).catch( e => alert( e.message ) );
      if (deleted === true) {
        setLists( prevState => prevState.filter( l => l.id !== delList.id ) );
      }
      if (list?.value === delList.id) {
        setList( null );
        setChecks( [] );
      }
      setDelList( null );
      setConfDelModal( false );
    }
  };

  const showListDelModal = ( l ) => {
    if (l) {
      setDelList( l );
      setConfDelModal( true );
    }
  };

  const performListUpdate = ( body ) => {
    updatePMList( body ).then( () => {
      getLists();
      _getPmListItems();
    } );
  };

  const createNewList = ( data ) => {
    const list = {
      ...data,
      unit_type_id:unitType?.id,
      unit_subtype_id:unitSubtype?.id
    };
    createPMList( { body:list } ).then( data => {
      setLists( prevState => ([...prevState, data]) );
      setList( { label:data.name, value:data.id } );
    } );
  };

  const includeLists = lists.filter( l => l.unit_subtype_id === list?.unit_subtype_id && !l.includes?.map( i => i.id ).includes( list?.id ) );

  useEffect( () => {
    blockNavigation( props.history, !disableButtons, props.history.location.pathname );
  }, [disableButtons] );

  return (
    <Card className={ 'bot-pms' }>
      <CardHeader>
        <PmConfirmationModal isOpen={ confModal.isOpen } type={ confModal.type } item={ confModal.item }
                             finalize={ confModal.type === 'add' ? finalizeAddCheck : finalizeRemoveCheck }
                             cancel={ () => setModal( { isOpen:false, type:'add', item:null } ) }/>
        <CreateListModal isOpen={ newList } toggle={ toggleNewList } unitSubtype={ unitSubtype } unitType={ unitType }
                         saveData={ createNewList }/>
        <PmListDeleteConfirmationModal list={ delList } cancel={ () => setConfDelModal( false ) }
                                       isOpen={ confDelModal } finalize={ deleteList }/>
        <CreateItemModal toggle={ () => setCItemModal( false ) } isOpen={ cItemModal } saveItem={ createListItem }/>
        <PmCheckListDetail currentList={ list }
                           allLists={ includeLists }
                           updateList={ performListUpdate } isOpen={ listModal }
                           toggle={ () => setListModal( prevState => !prevState ) }
                           deleteList={ showListDelModal } history={ props.history }/>
        <Modal isOpen={ showConfirmCancel }>
          <ModalHeader>
            Are you sure you want to lose your current changes?
          </ModalHeader>
          <ModalBody>
            <Col>(Yes) undo all changes and close</Col>
            <Col>(No) Save current changes and close</Col>
            <Col>(Cancel) continue editing.</Col>
          </ModalBody>
          <ModalFooter>
            <Button color={ 'text' } onClick={ () => {
              setPossibleList( null );
              setList( () => possibleList );
              setChecks( () => sortedList );
              setShowConfirmCancel( false );
            } }>Yes</Button>
            <Button color={ 'text' } onClick={ () => {
              updateList().catch();
              setShowConfirmCancel( false );
            } }>No</Button>
            <Button color={ 'text' } onClick={ () => {
              setPossibleList( () => null );
              setShowConfirmCancel( false );
            } }>Cancel</Button>
          </ModalFooter>
        </Modal>
        <h3>Manage PM Checklists</h3>
      </CardHeader>
      <CardHeader>
        <Row>
          <Col>
            <InputGroup>
              <InputGroupAddon addonType={ 'prepend' }>
                <InputGroupText>
                  Equipment Type
                </InputGroupText>
              </InputGroupAddon>
              <Input id={ 'unit_type_select' } type={ 'react-select' } isClearable
                     onChange={ e => setUnitType( unitTypes?.find( u => u.id === e?.value ) ) }
                     value={ unitType && { label:unitType.utype, value:unitType.id } }
                     options={ unitTypes.map( u => ({ label:u.utype, value:u.id }) ) }/>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroupAddon addonType={ 'prepend' }>
                <InputGroupText>
                  Equipment Class
                </InputGroupText>
              </InputGroupAddon>
              <Input id={ 'unit_class_select' } type={ 'react-select' } isClearable
                     onChange={ e => {
                       setUnitSubtype( unitSubtypes?.find( u => u.id === e?.value ) );
                       tblRef.current?.setFilter( 'unit_subtype_id', e?.value ?? '' );
                     } }
                     value={ unitSubtype && { label:unitSubtype.subtype, value:unitSubtype.id } }
                     options={ filteredList.map( u => ({ label:u.subtype, value:u.id }) ) } invalid={ !unitSubtype }/>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroupAddon addonType={ 'prepend' }>
                <InputGroupText>
                  Selected List
                </InputGroupText>
              </InputGroupAddon>
              <Input type={ 'text' } disabled={ true } value={ list?.name }/>
              <InputGroupAddon addonType={ 'append' }>
                <Button color={ 'texts' } outline disabled={ !unitSubtype } onClick={ toggleNewList }><i
                  className={ 'fa fa-plus' }/> Add List</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <DndProvider backend={ HTML5Backend }>
          <Row>
            <Col sm={ 4 }>
              <Row>
                <Col sm={ 9 }>
                  <InputGroup>
                    { availableSearch !== '' &&
                      <InputGroupAddon addonType={ 'prepend' }>
                        <InputGroupText style={ { height:'25px' } }
                                        onClick={ () => onFilterAvailable( { target:{ value:'' } } ) }>X</InputGroupText>
                      </InputGroupAddon> }
                    <Input style={ { height:'25px' } } placeholder={ 'Search...' } value={ availableSearch }
                           onChange={ onFilterAvailable }/>
                  </InputGroup>
                </Col>
                <Col sm={ 3 }>
                  <Button outline color={ 'texts' } size={ 'sm' } style={ { float:'right', marginTop:'0.5rem' } }
                          onClick={ () => setCItemModal( true ) }><i
                    className={ 'fa fa-plus' }/> Create New PM Item</Button>
                </Col>
              </Row>
              <div style={ {
                maxHeight:'20rem',
                overflow:'scroll',
                border:'1px solid grey',
                marginTop:'0.5rem',
                minHeight:'5em',
                width:'100%'
              } }>
                { attributeList.map( c => (
                  <PmAvailableCheck key={ c.id } data={ c } updateItem={ ( i ) => _updateAvailableCheck( i ) }/>) ) }
              </div>
            </Col>
            <Col sm={ 8 }>
              <SaveCancel saveDisabled={ disableButtons } onSaveClick={ updateList }
                          onCancelClick={ () => setChecks( sortedList ) }
                          cancelDisabled={ disableButtons } paddingBottom={ '0.5rem' }/>
              <div style={ {
                maxHeight:'20rem', overflow:'scroll', border:'1px solid grey'
              } }>
                <PmList
                  data={ selectedChecks }
                  unit_type={ unitType }
                  unit_subtype={ unitSubtype }
                  list={ list }
                  path={ 'selected_checks' }
                  handleDrop={ handleDrop }
                  updatePhoto={ updatePhoto }
                  updatePhotoOnly={ updatePhotoOnly }
                  updatePhotoRequired={ updatePhotoRequired }
                  updatePhotoCount={ updatePhotoCount }
                  updateDetails={ updateDetails }
                  updateDetailsOnly={ updateDetailsOnly }
                  updateDetailsRequired={ updateDetailsRequired }
                  removeCheck={ removeCheck }/>
              </div>
            </Col>
          </Row>
        </DndProvider>
        <hr/>
        <BotTable refProp={ tblRef }
                  tblId={ 'pms' }
                  columns={ columns }
                  pageSizes={ ['20', '50', '100', '500', '1000'] }
                  remote={ false }
                  getData={ getLists }
                  data={ _.cloneDeep( lists ) }
                  title={ 'PM Lists' }
                  collapsedHeader={ true }
                  csvFile={ 'pm_lists ' }
                  showTitle={ false }
                  headers={ true }
                  ClearFiltersBtn={ true }
                  ClearFiltersIcon={ true }
                  ClearSortBtn={ true }
                  ClearSortIcon={ true }
                  CsvDownloadBtn={ true }
                  CsvDownloadIcon={ true }
                  pagination={ 'bottom' }
                  defaultState={ defaultState }
                  btnGroupDirection={ 'left' }
                  defaultFilters={ myFilters }
                  onFiltersChanged={ ( e ) => {
                    const { value, name } = e.target;
                    if (name === 'unit_subtype_id' && value) setUnitSubtype( unitSubtypes.find( u => u.id === value ) );
                  } }
                  onRowDoubleClick={ row => selectList( row ) }
                  expandable={ false }/>
      </CardBody>
    </Card>
  );
};

export default connect()( BotPms );