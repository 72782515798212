import React from 'react';
import {
    BotTable,
    Button,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../components/bot-components';
import PropTypes from 'prop-types';
import TableActions from '../../../containers/table_action_component';
import BotPm from '../BotPm/BotPm';
import UnitDetail from "../../Units/BotUnit/BotUnit";
import * as _ from "lodash";
import PmCreate from '../PmCreate/PmCreate';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    getPms: PropTypes.func.isRequired,
    getMyPm: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    unit: PropTypes.object,
    unit_pm: PropTypes.object,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool.isRequired,
    tblId: PropTypes.string.isRequired,
    pageSizes: PropTypes.array,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    canCreate: PropTypes.bool,
    detail: PropTypes.bool,
    parentComp: PropTypes.string
};

let columns = [];

const defaultFilters = {
    id: "",
    unit_number: "",
    workorder_status: "",
    unit_type: "",
    unit_status: "",
};

const myFilters = _.cloneDeep(defaultFilters);

const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const BotPms = React.forwardRef(
    /**
     *
     * @param props
     * @param props.editAction
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.alertAction
     * @param props.getMyPm
     * @param props.getMyUnit
     * @param props.remote
     * @param props.getPms
     * @param props.columns
     * @param props.className
     * @param props.actionCount
     * @param props.detail
     * @param props.canCreate
     * @param props.lStorage
     * @param props.showTitle
     * @param props.loading
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.parentComp
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const [state, updateState] = React.useState({
        ...defaultState,
        pmModal: false,
        unitModal: false,
        newPmModal: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    //<editor-fold desc="Column Refs">
    const idRef = React.useRef();
    const unit_numberRef = React.useRef();
    const workorder_statusRef = React.useRef();
    const unit_typeRef = React.useRef();
    const unit_statusRef = React.useRef();
    //</editor-fold>

    const toggleUnitModal = () => {
        setState({
            unitModal: !state.unitModal
        });
    };

    const togglePmModal = () => {
        setState({
            pmModal: !state.pmModal
        });
    };

    const toggleNewPmModal = () => {
        setState({
            newPmModal: !state.newPmModal
        });
    };

    const editButton = (cell, row) => {
        return (
            <TableActions row={row} edit={props.editAction} view={props.viewAction}
                          popout={props.popoutAction} alert={props.alertAction}
                          onView={() => {
                              props.getMyPm(row.id);
                              window.location.href = '/#/pms/detail';
                          }}
                          onPopout={() => {
                              props.getMyPm(row.id);
                              togglePmModal()
                          }}
            />
        )
    };

    const prepareUnit = (cell, row) => {
        const modalUnitClick = () => {
            props.getMyUnit(row.unit_id);
            toggleUnitModal();
        };

        return props.parentComp !== 'unit' ? (
            <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                modalUnitClick()
            }}>{cell}</div>
        ) : cell;
    };

    const prepareId = (cell, row) => {
        const handleClick = () => {
            props.getMyPm(cell);
            props.getMyUnit(row.unit_id)
            localStorage.setItem('pm_id', cell);
            togglePmModal();
        }

        return (
            <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                handleClick()
            }}>{cell}</div>
        )
    };

    const styleUnitStatus = (cell) => {
        switch (cell) {
            case 'Down':
                return {color: 'black', backgroundColor: 'var(--light-red)', fontWeight: 'bold', textAlign: 'center'};
            case 'Needs Attention':
                return {
                    color: 'black',
                    backgroundColor: 'var(--light-yellow)',
                    fontWeight: 'bold',
                    textAlign: 'center'
                };
            case 3:
                return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
            default:
                return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
        }
    };

    const createNewBtn = () => {
        return(
            <DropdownItem outline color={'texts'} onClick={() => {
                toggleNewPmModal();
            }}><i className={'fa fa-plus'}/> New</DropdownItem>
        )
    };

    const refreshPms = () => {
        const tbl = ref?.current?.state;
        const req = {
            sortName: tbl?.sortName,
            sortOrder: tbl?.sortOrder,
            currentPage: tbl?.currPage,
            sizePerPage: tbl?.currSize,
            filters: tbl?.filters
        };
        props.remote ? props.getPms(req) : props.getPms();
    };

    const {pageSizes} = state;
    const {title, data, unit} = props;
    columns = props.columns ? props.columns : [
        {
            field: 'button',
            text: 'Views',
            width: props.actionCount * 40,
            hidden: false,
            format: (cell, row) => editButton(cell, row),
            sortable: false
        },
        {
            field: 'id',
            text: 'PM#',
            filter: {
                type: 'text',
                placeholder: 'Work Order#',
                defaultValue: defaultFilters.id
            },
            width: 180,
            format: (cell, row) => prepareId(cell, row),
            hidden: false,
            refProp: idRef,
            sortable: true
        },
        {
            field: 'unit_number',
            text: 'Equipment#',
            filter: {
                type: 'text',
                placeholder: 'Equipment#',
                defaultValue: defaultFilters.unit_number
            },
            width: 150,
            hidden: false,
            format: (cell, row) => prepareUnit(cell, row),
            refProp: unit_numberRef,
            sortable: true
        },
        {
            field: 'workorder_status',
            text: 'WO Status',
            filter: {
                type: 'select',
                placeholder: 'WO Status',
                defaultValue: defaultFilters.workorder_status,
                options: [{value: "", label: 'Select Status'}, {value: 'Open', label: 'Open'}, {
                    value: 'Closed',
                    label: 'Closed'
                }]
            },
            width: 180,
            hidden: false,
            refProp: workorder_statusRef,
            sortable: true
        },
        {
            field: 'unit_type',
            text: 'Type',
            filter: {
                type: 'text',
                placeholder: 'Type',
                defaultValue: defaultFilters.unit_type
            },
            width: 150,
            hidden: false,
            refProp: unit_typeRef,
            sortable: true
        },
        {
            field: 'unit_status',
            text: 'Equip. Status',
            filter: {
                type: 'text',
                placeholder: 'Equip. Status',
                defaultValue: defaultFilters.unit_status
            },
            width: 150,
            hidden: false,
            tdStyle: (cell) => styleUnitStatus(cell),
            refProp: unit_statusRef,
            sortable: true
        }
    ];

    return (
        <div>
            <Modal size={'lg'} isOpen={state.pmModal} toggle={() => togglePmModal()} className={props.className}>
                <ModalHeader toggle={() => togglePmModal()}/>
                <ModalBody>
                    <BotPm modal/>
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={() => togglePmModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal size={'xl'} isOpen={state.unitModal} toggle={() => {toggleUnitModal()}} className={props.className}>
                <ModalHeader toggle={() => {toggleUnitModal()}}>
                    Unit detail for {unit.items ? unit.items.number : ''}
                </ModalHeader>
                <ModalBody>
                    <UnitDetail isModal/>
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={() => {
                        toggleUnitModal()
                    }}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal size={'lg'} isOpen={state.newPmModal} toggle={() => {toggleNewPmModal()}} className={props.className}>
                <ModalHeader toggle={() => {toggleNewPmModal()}}>
                    New PM for {unit.items ? unit.items.number : ''}
                </ModalHeader>
                <ModalBody>
                    <PmCreate toggle={() => toggleNewPmModal()} detail={props.detail}/>
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={() => {
                        toggleNewPmModal()
                    }}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <BotTable ref={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      getData={(req) => props.getPms(req)}
                      pageSizes={pageSizes}
                      ClearFiltersBtn
                      ClearFiltersIcon
                      ClearSortBtn
                      ClearSortIcon
                      CsvDownloadBtn
                      CsvDownloadIcon
                      CreateNewBtn={props.canCreate && createNewBtn}
                      pagination={'both'}
                      headers={true}
                      doRowUpdate={(wo) => console.log(wo)}
                      refreshData={() => refreshPms()}
                      btnGroupDirection={'left'}
                      remote={props.remote}
                      defaultFilters={defaultFilters}
                      onRowDoubleClick={(row) => {
                           props.getMyPm(row.id);
                           togglePmModal();
                       }}
                      defaultState={defaultState}
                      lStorage={props.lStorage}
                      showTitle={props.showTitle}
                      loading={props.loading}
                      csvFile={props.csvFile}
                      csvUrl={props.csvUrl}/>
        </div>
    );
});

BotPms.propTypes = propTypes;

export default BotPms;
