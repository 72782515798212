import {combineReducers} from 'redux';
import {attachments, attachment} from './attachment.reducer';
import authentication from './authentication.reducer';
import {users, pag_users, user, login_exceptions} from './users.reducer';
import {mechanics, managers, operators, roles} from './roles.reducer';
import {user_preference, user_preferences} from './user_preference.reducer';
import {workorders, log_workorders, new_workorders, old_workorders, open_workorders, open_count, report, light_report, workorder, vendor_all_workorders} from './workorders.reducer';
import {call_log} from './call_log.reducer';
import {message} from './message.reducer';
import {comment, comments} from './comment.reducer';
import {company, companies} from './company.reducer';
import {company_role, company_roles} from './company_role.reducer';
import {data_plate, data_plates} from './data_plate.reducer';
import {job, jobs} from './job.reducer';
import {job_status, job_statuses} from './job_status.reducer';
import {purchase_order, purchase_orders} from './purchase_order.reducer';
import {task, tasks} from './task.reducer';
import {task_priorities, task_priority} from './task_priority.reducer';
import {unit, dot_report, units} from './unit.reducer';
import {unit_pm, unit_pms} from './unit_pm.reducer';
import {unit_status, unit_statuses} from './unit_status.reducer';
import {unit_subtype, unit_subtypes} from './unit_subtype.reducer';
import {unit_type, unit_types} from './unit_type.reducer';
import {vendor, vendors} from './vendor.reducer';
import {workorder_status, workorder_statuses} from './workorder_status.reducer';
import {yard, yards} from './yard.reducer';
import {asset_types, asset_type} from './asset_type.reducer';
import {tire_sizes, tire_size} from './tire_size.reducer';
import {logs, log} from './log.reducer';
import {insDashboard, woDashboard, dotDashboard} from './dashboard.reducer';
import {parts, part} from './part.reducer';
import {plm_alerts, plm_alert} from './plm_alert.reducer';
import {workorder_report, maint_report_wo, maint_report_wo_detail, maint_report_task_detail, maint_report_task, dot_task_exceptions, dot_inspection_exceptions, kpi_data} from './report.reducer';
import {attribute, attributes, unit_attributes, class_attributes} from './attribute.reducer';
import {faults, fault, unassigned_faults} from './fault.reducer';

const rootReducer = combineReducers({
    authentication, user, users, pag_users, managers, mechanics, operators, roles, login_exceptions, user_preference, user_preferences, workorder, workorders, log_workorders, new_workorders, old_workorders,
    open_workorders, open_count, report, light_report, vendor_all_workorders, call_log, message, comment, comments, company, companies, company_role, company_roles, data_plates, data_plate, job, jobs, job_status,
    job_statuses, purchase_orders, purchase_order, task, tasks, task_priority, task_priorities, unit, units, dot_report, unit_pms, unit_pm, unit_status, unit_statuses, unit_subtypes, unit_subtype, unit_types,
    unit_type, vendor, vendors, workorder_status, workorder_statuses, yards, yard, attachments, attachment, asset_type, asset_types, tire_sizes, tire_size, log, logs, attributes, insDashboard, woDashboard,
    dotDashboard, part, parts, plm_alert, plm_alerts, workorder_report, maint_report_wo, maint_report_wo_detail, maint_report_task_detail, maint_report_task, dot_task_exceptions, dot_inspection_exceptions, kpi_data,
    attribute, unit_attributes, class_attributes, faults, fault, unassigned_faults
});

export default rootReducer;
