import React from 'react';
import {_dateFormat} from '../../helpers/';
import PropTypes from 'prop-types';
const propTypes = {
    plm_alert: PropTypes.object
};

const PlmAlertDetail = (props) => {
    const [state, updateState] = React.useState({
        message_id: '',
        date_received: '',
        date_inserted: '',
        sn: '',
        model: '',
        hours: '',
        map_link: '',
        lat: '',
        long: '',
        status: '',
        job_location: '',
        miscellaneous: '',
        unit_number: '',
        sales_person: '',
        fault_code: '',
        description: '',
        time_date_of_fault: '',
        occurrences_per_day: '',
        occurrences_per_week: '',
        recommended_actions: '',
        possible_solutions: ''
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
        // if (props.plm_alert && props.plm_alert.loading === undefined) {
        //     if (localStorage.getItem('alert_id') !== null) {
        //         const alert_id = localStorage.getItem('alert_id');
        //         props.getAlert(alert_id);
        //     } else {
        //         window.location.href = '/#/plm_alerts';
        //     }
        // }
    React.useEffect(() => {
        setState({
            message_id: props.plm_alert?.items?.message_id ?? '',
            date_received: props.plm_alert?.items?.date_received ?? '',
            date_inserted: props.plm_alert?.items?.date_inserted ?? '',
            sn: props.plm_alert?.items?.sn ?? '',
            model: props.plm_alert?.items?.model ?? '',
            hours: props.plm_alert?.items?.hours ?? '',
            map_link: props.plm_alert?.items?.map_link ?? '',
            lat: props.plm_alert?.items?.lat ?? '',
            long: props.plm_alert?.items?.long ?? '',
            status: props.plm_alert?.items?.status ?? '',
            job_location: props.plm_alert?.items?.job_location ?? '',
            miscellaneous: props.plm_alert?.items?.miscellaneous ?? '',
            unit_number: props.plm_alert?.items?.unit_number ?? '',
            sales_person: props.plm_alert?.items?.sales_person ?? '',
            fault_code: props.plm_alert?.items?.fault_code ?? '',
            description: props.plm_alert?.items?.description ?? '',
            time_date_of_fault: props.plm_alert?.items?.time_date_of_fault ?? '',
            occurrences_per_day: props.plm_alert?.items?.occurrences_per_day ?? '',
            occurrences_per_week: props.plm_alert?.items?.occurrences_per_week ?? '',
            recommended_actions: props.plm_alert?.items?.recommended_actions ?? '',
            possible_solutions: props.plm_alert?.items?.possible_solutions ?? ''
        })
    }, [props.plm_alert]);

        const {message_id, date_received, date_inserted, sn, model, hours, map_link, lat, long, status, job_location, miscellaneous,
            unit_number, sales_person, fault_code, description, time_date_of_fault, occurrences_per_day, occurrences_per_week,
            recommended_actions, possible_solutions} = state;

        return (
            <div>
                <ul>
                    <li><h4><b>Message ID: </b>{message_id}</h4></li>
                    <li><h4><b>Date Received: </b>{_dateFormat(date_received)}</h4></li>
                    <li><h4><b>Date Inserted: </b>{_dateFormat(date_inserted)}</h4></li>
                    <li><h4><b>Serial#: </b>{sn}</h4></li>
                    <li><h4><b>Model: </b>{model}</h4></li>
                    <li><h4><b>Hours: </b>{hours}</h4></li>
                    <li><h4><b>Location: </b><a href={map_link} target={'_blank'} rel={'noopener noreferrer'}>location</a></h4></li>
                    <li><h4><b>Latitude: </b>{lat}</h4></li>
                    <li><h4><b>Longitude: </b>{long}</h4></li>
                    <li><h4><b>Status: </b>{status}</h4></li>
                    <li><h4><b>Job/Location: </b>{job_location}</h4></li>
                    <li><h4><b>Misc.: </b>{miscellaneous}</h4></li>
                    <li><h4><b>Equipment#: </b>{unit_number}</h4></li>
                    <li><h4><b>Sales Rep.: </b>{sales_person}</h4></li>
                    <li><h4><b>Code: </b>{fault_code}</h4></li>
                    <li><h4><b>Description: </b>{description}</h4></li>
                    <li><h4><b>Time and Date: </b>{time_date_of_fault}</h4></li>
                    <li><h4><b>Per Day: </b>{occurrences_per_day}</h4></li>
                    <li><h4><b>Per Week: </b>{occurrences_per_week}</h4></li>
                    <li><h4><b>Recommended Actions: </b>{recommended_actions}</h4></li>
                    <li><h4><b>Possible Solutions: </b>{possible_solutions}</h4></li>
                </ul>
            </div>
        );
};

PlmAlertDetail.propTypes = propTypes;
export default PlmAlertDetail;
