import {hasRoles} from '../../../helpers';
import {companyRoleActions, jobActions, roleActions, userActions, yardActions} from '../../../network/actions';
import {
    Col, CustomInput,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from '../../../components/bot-components';
import React from 'react';

export const savePassword = (state, props, savedPassword) => {
    const buff = new Buffer(state.password);
    const pass = buff.toString('base64');
    const conBuff = new Buffer(state.password_confirmation);
    const passCon = conBuff.toString('base64');
    const user = {
        id: props.user.items.id,
        password: pass,
        password_confirmation: passCon,
    };
    // props.updatePassword(user);
    props.updatePassword(user);
    savedPassword();
};
export const saveUser = (state, props) => {
    let roles = [];
    state.selectedRoles.map(r => {
        return roles.push(r.value);
    });
    const User = {
        id: props.user.items.id,
        roles: roles,
        first_name: state.first_name,
        last_name: state.last_name,
        phone_number: state.phone_number,
        email: state.user_email,
        employee_number: state.user_number,
        user_status_id: state.selectedStatus.value,
        // crew: this.state.crew,
        daily_inspections: state.daily_inspections
    };
    state.enums && state.enums.map(e => {
        if (state[e.field]!== undefined) User[e.field] = state[e.field];
        return null;
    });
    state._strings && state._strings.map(s => {
        if (state[s.field] !== undefined) User[s.field] = state[s.field];
        return null;
    });
    state.bools && state.bools.map(b => {
        if (state[b.field] !== undefined) User[b.field] = state[b.field];
        return null;
    });
    if (state.selectedJob) {
        if (typeof state.selectedJob === "object") {
            User.job_id = state.selectedJob.value;
        } else {
            User.job_id = state.selectedJob;
        }
    } else {
        User.job_id = null;
    }
    if (state.selectedYard) {
        if (typeof state.selectedYard === "object") {
            User.yard_id = state.selectedYard.value;
        } else {
            User.yard_id = state.selectedYard;
        }
    } else {
        User.yard_id = null;
    }
    props.updateUser(User);
    props.cancelUserEdit();
};
export const mapStateToProps = (state) => {
    const {user, roles, jobs, crews, yards, authentication} = state;
    const jobList = jobs?.items?.filter(j => j.deleted === false).map(j => ({key: j.id, label: `${j.name} -- ${j.number}(${yards.items?.find(y => y.id === j.yard_id).name})`, value: j.id}));
    const yardList = yards?.items?.filter(y => y.deleted === false).map(y => ({key: y.id, label: y.name, value: y.id}));
    let roleList = [];
    const userRoles = user?.items?.roles?.map(r => ({label: r.authority, value: r.id}));
    let crewList = [];
    const user_statuses = {items: [{id: 1, status: 'Active'}, {id: 2, status: 'Inactive'}]};
    const statusList = user_statuses?.items?.map(us => ({label: us.status, value: us.id}));
    const uStat = user_statuses?.items?.find(us => us.id === user?.items?.user_status_id);
    const userStatus = uStat && ({value: uStat.id, label: uStat.status});

    const Admin = hasRoles(['Administrator', 'SuperAdmin'], authentication.user?.user);
    const Super = hasRoles(['SuperAdmin'], authentication.user?.user);
    if (roles.items) {
        for (let i = 0; i < roles.items.length; i++) {
            const role_id = roles.items[i].id;
            const name = roles.items[i]['authority'];
            const adminRoles = ['Payroll', 'Manager'];
            const superRoles = ['Administrator', 'SuperAdmin'];
            if (adminRoles.includes(name)) {
                if (Admin || Super) roleList.push({label: name, value: role_id})
            } else if (superRoles.includes(name)) {
                if (Super) roleList.push({label: name, value: role_id});
            } else roleList.push({label: name, value: role_id});
        }
    }

    return {user, roles, jobList, roleList, userRoles, user_statuses, statusList, crewList, crews, userStatus, yardList, authentication, Admin, Super};
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: () => dispatch(roleActions.getRoles()),
        getUser: (id) => dispatch(userActions.getDetail(id)),
        updateUser: (user) => dispatch(userActions.updateUser(user)),
        updatePassword: (user) => dispatch(userActions.updatePassword(user)),
        getJobs: () => dispatch(jobActions.getAll()),
        getYards: () => dispatch(yardActions.getAll()),
        // getCrews: () => dispatch(crewActions.getAll()),
        getCompRoles: () => dispatch(companyRoleActions.getAll())
    }
};
export const getExtraInputs = (Super, state, setState, changeEnumValues, changeExtraTextField) => {
    return (
        <>
            <Row>
                <Col sm={6}>
                    {state.bools?.map((e, index) =>
                        (index % 2 === 0) ? (<InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>{e['table_header']}</InputGroupText>
                            </InputGroupAddon>
                            <CustomInput id={`${e.field}-${index}`} className={'user-custom-checkbox'} addon type={'checkbox'} name={e.field}
                                   checked={state[e.alias] ?? false}
                                   disabled={!Super}
                                   onChange={() => setState({
                                [e.field]: !state[e.alias],
                                [e.alias]: !state[e.alias]
                            })}/>
                        </InputGroup>) : null
                    )}
                </Col>
                <Col sm={6}>
                    {state.bools && state.bools.map((e, index) =>
                        (index % 2 !== 0) ? (<InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>{e['table_header']}</InputGroupText>
                            </InputGroupAddon>
                            <Input style={{marginLeft: '.5rem', marginTop: '.7rem'}} addon type={'checkbox'} name={e.field}
                                   checked={state[e.field]}
                                   disabled={!Super}
                                   onChange={() => setState({
                                       [e.field]: !state[e.alias],
                                       [e.alias]: !state[e.alias]
                                   })}/>
                        </InputGroup>) : null
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    {state.enums?.map((e, index) =>
                        (index % 2 === 0) ? (
                            <>
                                <InputGroup>
                                    <InputGroupAddon addonType={'prepend'}><InputGroupText>{e['table_header']}</InputGroupText></InputGroupAddon>
                                    <Input type={'react-select'}
                                           multi={false}
                                           options={state[`enum${(index + 1)}`] && state[`enum${(index + 1)}`].map?.(cr => {
                                               return ({label: cr === "" ? `Select ${e.table_header}` : cr, value: cr})
                                           })}
                                           isDisabled={!Super}
                                           placeholder={`Select ${e['table_header']}.`}
                                           matchProp={'label'}
                                           value={state[e.alias] ? ({label: state[e.alias], value: state[e.alias]}) : ({label: `Select ${e.table_header}`, value: null})}
                                           style={{borderColor: !state[e.alias] && 'var(--red)'}}
                                           onChange={(selectedValue) => {
                                               changeEnumValues(e.field, e.alias, selectedValue ? selectedValue.value : null)
                                           }}/>
                                </InputGroup>
                                {!state[e.alias] &&
                                <small style={{color: 'var(--red)'}}>*You must select a {e['table_header']}.</small>}
                            </>
                        ) : null
                    )}
                    {state._strings?.map((s, index) =>
                        (index % 2 === 0) ? (
                            <>
                                <InputGroup>
                                    <InputGroupAddon addonType={'prepend'}><InputGroupText>{s.table_header}</InputGroupText></InputGroupAddon>
                                    <Input type={'text'} name={s.field} value={state[s.alias]}
                                           placeholder={`Enter ${s.table_header}`}
                                           disabled={!Super}
                                           onChange={(e) => {
                                               changeExtraTextField(s.field, s.alias, e.target.value)
                                           }} invalid={state[`${s.field}_valid`] === false}/>
                                </InputGroup>
                                {state[`${s.field}_valid`] === false && Super &&
                                <small style={{color: 'var(--red)'}}>*You must enter
                                    a {s['table_header']}.</small>}
                            </>
                        ) : null
                    )}
                </Col>
                <Col sm={6}>
                    {state.enums?.map((e, index) =>
                        (index % 2 !== 0) ? (
                            <>
                                <InputGroup>
                                    <InputGroupAddon addonType={'prepend'}><InputGroupText>{e['table_header']}</InputGroupText></InputGroupAddon>
                                    <Input type={'react-select'}
                                           multi={false}
                                           options={state[`enum${(index + 1)}`] && state[`enum${(index + 1)}`].map(cr => {
                                               return ({label: cr === "" ? `Select ${e.table_header}` : cr, value: cr})
                                           })}
                                           isDisabled={!Super}
                                           placeholder={`Select ${e['table_header']}.`}
                                           matchProp={'label'}
                                           value={state[e.alias] ? ({label: state[e.alias], value: state[e.alias]}) : ({label: `Select ${e.table_header}`, value: null})}
                                           style={{borderColor: !state[e.alias] && 'var(--red)'}}
                                           onChange={(selectedValue) => {
                                               changeEnumValues(e.field, e.alias, selectedValue ? selectedValue.value : null)
                                           }}/>
                                </InputGroup>
                                {!state[e.alias] &&
                                <small style={{color: 'var(--red)'}}>*You must select a {e['table_header']}.</small>}
                            </>
                        ) : null
                    )}
                    {state._strings && state._strings.map((s, index) =>
                        (index % 2 !== 0) ? (
                            <>
                                <InputGroup>
                                    <InputGroupAddon addonType={'prepend'}><InputGroupText>{s.table_header}</InputGroupText></InputGroupAddon>
                                    <Input type={'text'} name={s.field} value={state[s.alias]}
                                           placeholder={`Enter ${s.table_header}`}
                                           disabled={!Super}
                                           onChange={(e) => {
                                               changeExtraTextField(s.field, s.alias, e.target.value)
                                           }} invalid={state[`${s.field}_valid`] === false}/>
                                </InputGroup>
                                {state[`${s.field}_valid`] === false && Super &&
                                <small style={{color: 'var(--red)'}}>*You must enter
                                    a {s['table_header']}.</small>}
                            </>
                        ) : null
                    )}
                </Col>
            </Row>
        </>
    )
}
