import React from 'react';
import PropTypes from 'prop-types';
import {_dateFormat} from '../../helpers';
import AlertModal from './AlertModal';
import {BotTable} from '../../components/bot-components';
import _ from "lodash";
import WorkOrderDetailModal from '../WorkOrders/WorkOrderDetail/WorkOrderDetailModal';
import BotUnitModal from '../Units/BotUnit/BotUnitModal';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    getMyAlert: PropTypes.func,
    getAlerts: PropTypes.func.isRequired,
    getMyWorkOrder: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    unit: PropTypes.object,
    plm_alert: PropTypes.object,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    workorder: PropTypes.object,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    parentComp: PropTypes.string
};

let defaultFilters = {
    comment: "",
    workorder_id: "",
    description: ""
};

const myFilters = _.cloneDeep(defaultFilters);

const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const BotPlmAlerts = React.forwardRef(
    /**
     *
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getMyWorkOrder
     * @param props.getMyAlert
     * @param props.getMyUnit
     * @param props.getAlerts
     * @param props.columns
     * @param props.className
     * @param props.showTitle
     * @param props.loading
     * @param props.csvUrl
     * @param props.csvFile
     * @param props.parentComp
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {

    //<editor-fold desc="Column Definitions">
    const idRef = React.useRef()
    const unitRef = React.useRef();
    const woRef = React.useRef();
    const dateRef = React.useRef();
    const descriptionRef = React.useRef();
    const mapRef = React.useRef();
    //</editor-fold>
    const [state, updateState] = React.useState({
        ...defaultState,
        tblId: props.tblId,
        lStorage: props.lStorage,
        remote: props.remote,
        pageSizes: props.pageSizes,
        unitModal: false,
        workorderModal: false,
        alertModal: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    const toggleUnitModal = () => {
        setState({
            unitModal: !state.unitModal
        });
    };

    const toggleAlertModal = () => {
        setState({
            alertModal: !state.alertModal
        });
    };

    const toggleWorkorderModal = () => {
        setState({
            workorderModal: !state.workorderModal
        });
    };

    const prepareLink = (cell) => {
        return (
            <a href={cell} target={'_blank'} rel={'noopener noreferrer'}>location</a>
        )
    };

    const prepareWorkorder = (cell) => {
        function handleWorkorderClick() {
            props.getMyWorkOrder(cell);
            localStorage.setItem('workorder_id', cell);
            toggleWorkorderModal();
        }
        return props.parentComp === 'workorder' ? cell : (
            <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {handleWorkorderClick()}}>{cell}</div>
        )
    };

    const prepareId = (cell, row) => {
        function handleIdClick() {
            props.getMyAlert(row.id);
            localStorage.setItem('alert_id', row.id);
            toggleAlertModal();
        }
        return (
            <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {handleIdClick()}}>{row.message_id}</div>
        );
    };

    const prepareUnit = (cell, row) => {
        function modalUnitClick() {
            props.getMyUnit(row.unit_id);
            localStorage.setItem('unit_id', row.unit_id);
            localStorage.setItem('unitTab', '6');
            toggleUnitModal();
        }
        if (row.unit_id) {
            return props.parentComp === 'unit' ? cell : (
                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {modalUnitClick()}}>{cell}</div>
            )
        } else {
            return cell;
        }
    };

    const refreshAlerts = () => {
        const tbl = ref?.current;
        const req = {
            sortName: tbl?.state.sortName,
            sortOrder: tbl?.state.sortOrder,
            currentPage: tbl?.state.currPage,
            sizePerPage: tbl?.state.currSize,
            filters: tbl?.state.filters
        };
        props.remote ? props.getAlerts(req) : props.getAlerts();
    };

    const filters = defaultFilters;
        const {title, data, unit, plm_alert, pageSizes} = props;
        const columns = props.columns ? props.columns : [
            {
                field: 'id',
                text: 'Record#',
                width: 180,
                format: (cell, row) => prepareId(cell, row),
                hidden: false,
                refProp: idRef,
                sortable: true
            },
            {
                field: 'comment',
                text: 'Equipment#',
                refProp: unitRef,
                filter: {
                    type: 'text',
                    placeholder: 'Equipment#',
                    defaultValue: filters.comment
                },
                width: 150,
                hidden: false,
                format: (cell, row) => prepareUnit(cell, row),
                sortable: true
            },
            {
                field: 'workorder_id',
                text: 'Work Order#',
                filter: {
                    type: 'text',
                    placeholder: 'Work Order#',
                    defaultValue: filters.workorder_id
                },
                width: 180,
                format: (cell) => prepareWorkorder(cell),
                hidden: false,
                refProp: woRef,
                sortable: true
            },
            {
                field: 'time_date_of_fault',
                text: 'Date',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: dateRef
            },
            {
                field: 'description',
                text: 'Description',
                filter: {
                    type: 'text',
                    placeholder: 'Description',
                    defaultValue: filters.description
                },
                width: 300,
                hidden: false,
                refProp: descriptionRef,
                sortable: true
            },
            {
                field: 'map_link',
                text: 'Map Link',
                width: 150,
                hidden: false,
                refProp: mapRef,
                sortable: false,
                format: (cell) => prepareLink(cell)
            }
        ];
        return (
            <div>
                <AlertModal isOpen={state.alertModal} toggle={() => toggleAlertModal()} plm_alert={plm_alert}/>
                <WorkOrderDetailModal isOpen={state.workorderModal} toggle={toggleWorkorderModal}/>
                <BotUnitModal isOpen={state.unitModal} toggle={() => toggleUnitModal()} unit={unit} parentComp={'unit'}/>
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => props.getAlerts(req)}
                          pageSizes={pageSizes}
                          ClearFiltersBtn={true}
                          ClearFiltersIcon={true}
                          ClearSortBtn={true}
                          ClearSortIcon={true}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          pagination={'both'}
                          headers={true}
                          doRowUpdate={(t) => console.log(t)}
                          refreshData={() => refreshAlerts()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={filters}
                          onRowDoubleClick={(row) => {
                              props.getMyAlert(row.id);
                              localStorage.setItem('alert_id', row.id);
                              toggleAlertModal();
                          }}
                          defaultState={defaultState}
                          showTitle={props.showTitle}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                />
            </div>
        );
});

BotPlmAlerts.propTypes = propTypes;
export default BotPlmAlerts;
