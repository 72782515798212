export const taskPriorityConstants = {
    CREATE_TASK_PRIORITY_REQUEST: 'TASK_PRIORITY_CREATE_REQUEST',
    CREATE_TASK_PRIORITY_SUCCESS: 'TASK_PRIORITY_CREATE_SUCCESS',
    CREATE_TASK_PRIORITY_FAILURE: 'TASK_PRIORITY_CREATE_FAILURE',

    UPDATE_TASK_PRIORITY_REQUEST: 'TASK_PRIORITY_UPDATE_REQUEST',
    UPDATE_TASK_PRIORITY_SUCCESS: 'TASK_PRIORITY_UPDATE_SUCCESS',
    UPDATE_TASK_PRIORITY_FAILURE: 'TASK_PRIORITY_UPDATE_FAILURE',

    GET_ALL_TASK_PRIORITIES_REQUEST: 'TASK_PRIORITIES_GET_ALL_REQUEST',
    GET_ALL_TASK_PRIORITIES_SUCCESS: 'TASK_PRIORITIES_GET_ALL_SUCCESS',
    GET_ALL_TASK_PRIORITIES_FAILURE: 'TASK_PRIORITIES_GET_ALL_FAILURE',

    DETAIL_TASK_PRIORITY_REQUEST: 'TASK_PRIORITY_DETAIL_REQUEST',
    DETAIL_TASK_PRIORITY_SUCCESS: 'TASK_PRIORITY_DETAIL_SUCCESS',
    DETAIL_TASK_PRIORITY_FAILURE: 'TASK_PRIORITY_DETAIL_FAILURE',
};
