import {roleConstants} from '../constants';

export const getRolesRequest = () => ({type: roleConstants.GET_ALL_ROLES_REQUEST});

export const getRolesSuccess = (roles) => ({type: roleConstants.GET_ALL_ROLES_SUCCESS, roles});

export const getRolesFailure = (error) => ({type: roleConstants.GET_ALL_ROLES_FAILURE, error});

export const getMechanicsRequest = () => ({type: roleConstants.GET_MECHANICS_REQUEST});

export const getMechanicsSuccess = (mechanics) => ({type: roleConstants.GET_MECHANICS_SUCCESS, mechanics});

export const getMechanicsFailure = (error) => ({type: roleConstants.GET_MECHANICS_FAILURE, error});

export const getManagersRequest = () => ({type: roleConstants.GET_MANAGERS_REQUEST});

export const getManagersSuccess = (managers) => ({type: roleConstants.GET_MANAGERS_SUCCESS, managers});

export const getManagersFailure = (error) => ({type: roleConstants.GET_MANAGERS_FAILURE, error});

export const getOperatorsRequest = () => ({type: roleConstants.GET_OPERATORS_REQUEST});

export const getOperatorsSuccess = (operators) => ({type: roleConstants.GET_OPERATORS_SUCCESS, operators});

export const getOperatorsFailure = (error) => ({type: roleConstants.GET_OPERATORS_FAILURE, error});

