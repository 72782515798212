import {workorderConstants} from "../constants/";
import {workorderService} from "../services/";
import {unitActions} from './unit.actions';
import {purchaseOrderActions} from './purchase_order.actions';

export const workorderActions = {};
workorderActions.getWorkorders = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.GET_ALL_WORKORDERS_REQUEST});
    const success = (workorders) => ({type: workorderConstants.GET_ALL_WORKORDERS_SUCCESS, workorders});
    const failure = (error) => ({type: workorderConstants.GET_ALL_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAll(req).then(workorders => dispatch(success(workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getAllVendor = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.VENDOR_ALL_WORKORDERS_REQUEST});
    const success = (vendor_all_workorders) => ({type: workorderConstants.VENDOR_ALL_WORKORDERS_SUCCESS, vendor_all_workorders});
    const failure = (error) => ({type: workorderConstants.VENDOR_ALL_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAllVendor(req).then(vendor_all_workorders => dispatch(success(vendor_all_workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getLogWorkorders = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.LOG_WORKORDERS_REQUEST});
    const success = (log_workorders) => ({type: workorderConstants.LOG_WORKORDERS_SUCCESS, log_workorders});
    const failure = (error) => ({type: workorderConstants.LOG_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAllLog(req).then(log_workorders => dispatch(success(log_workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getOpenWorkorders = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.OPEN_WORKORDERS_REQUEST});
    const success = (open_workorders) => ({type: workorderConstants.OPEN_WORKORDERS_SUCCESS, open_workorders});
    const failure = (error) => ({type: workorderConstants.OPEN_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAllOpen(req).then(open_workorders => dispatch(success(open_workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getNewWorkorders = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.NEW_WORKORDERS_REQUEST});
    const success = (new_workorders) => ({type: workorderConstants.NEW_WORKORDERS_SUCCESS, new_workorders});
    const failure = (error) => ({type: workorderConstants.NEW_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAllNew(req).then(new_workorders => dispatch(success(new_workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getOldWorkorders = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.OLD_WORKORDERS_REQUEST});
    const success = (old_workorders) => ({type: workorderConstants.OLD_WORKORDERS_SUCCESS, old_workorders});
    const failure = (error) => ({type: workorderConstants.OLD_WORKORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getAllOld(req).then(old_workorders => dispatch(success(old_workorders)), error => dispatch(failure(error)));
    };
};
workorderActions.getOpenCount = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.GET_OPEN_COUNT_REQUEST});
    const success = (open_count) => ({type: workorderConstants.GET_OPEN_COUNT_SUCCESS, open_count});
    const failure = (error) => ({type: workorderConstants.GET_OPEN_COUNT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getOpenCount().then(open_count => dispatch(success(open_count)), error => dispatch(failure(error)));
    };
};
workorderActions.getReport = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.GET_REPORT_DATA_REQUEST});
    const success = (report) => ({type: workorderConstants.GET_REPORT_DATA_SUCCESS, report});
    const failure = (error) => ({type: workorderConstants.GET_REPORT_DATA_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getReport().then(report => dispatch(success(report)), error => dispatch(failure(error)));
    };
};
workorderActions.getLightReport = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.GET_LIGHT_REPORT_DATA_REQUEST});
    const success = (light_report) => ({type: workorderConstants.GET_LIGHT_REPORT_DATA_SUCCESS, light_report});
    const failure = (error) => ({type: workorderConstants.GET_LIGHT_REPORT_DATA_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getLightReport().then(light_report => dispatch(success(light_report)), error => dispatch(failure(error)));
    };
};
workorderActions.getDetail = (id, wo_only) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getDetail(id)
            .then(
                workorder => {
                    if (workorder) {
                        //*TODO Replace interlinked actions and services **/
                        dispatch(purchaseOrderActions.load(workorder.purchase_orders));
                        if (!wo_only) {
                            dispatch(unitActions.getUnit(workorder.unit_id));
                        }
                        localStorage.setItem('workorder_id', workorder.id);
                        dispatch(success(workorder));
                    } else {
                        dispatch(success(workorder));
                    }
                },
                error => dispatch(failure(error)));
    };
};
workorderActions.getNext = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getNext(id).then(
            workorder => {
                dispatch(success(workorder));
                if (workorder) {
                    //*TODO Replace interlinked actions and services **/
                    dispatch(purchaseOrderActions.load(workorder.purchase_orders));
                    dispatch(unitActions.getUnit(workorder.unit_id));
                    localStorage.setItem('workorder_id', workorder.id);
                }}, error => dispatch(failure(error)));
    };
};
workorderActions.getPrevious = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.getPrevious(id).then(workorder => {
            dispatch(success(workorder));
            if (workorder) {
                //*TODO Replace interlinked actions and services **/
                dispatch(purchaseOrderActions.load(workorder.purchase_orders));
                dispatch(unitActions.getUnit(workorder.unit_id));
                localStorage.setItem('workorder_id', workorder.id);
            }
            }, error => dispatch(failure(error)));
    };
};
workorderActions.updateWorkorder = (workorder) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.updateWorkorder(workorder).then(workorder => dispatch(success(workorder) , error => dispatch(failure(error))));
    };
};
workorderActions.closeWorkorder = (wo) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.updateWorkorder(wo).then(workorder => dispatch(success(workorder)), error => dispatch(failure(error)))
    };
};
workorderActions.confirmWorkorder = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.confirmWorkorder().then(workorder => dispatch(success(workorder)), error => dispatch(failure(error)));
    };
};
workorderActions.updateMechanic = (wo) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.updateWorkorder(wo).then(workorder => dispatch(success(workorder)), error => dispatch(failure(error)));
    };
};
workorderActions.updateVendor = (workorder) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.DETAIL_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.DETAIL_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.DETAIL_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.updateWorkorder(workorder).then(workorder => dispatch(success(workorder)), error => dispatch(failure(error)));
    };
};
workorderActions.createWorkorder = (workorder) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderConstants.CREATE_WORKORDER_REQUEST});
    const success = (workorder) => ({type: workorderConstants.CREATE_WORKORDER_SUCCESS, workorder});
    const failure = (error) => ({type: workorderConstants.CREATE_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.createWorkorder(workorder).then(workorder => {
            dispatch(success(workorder));
            //*TODO Replace interlinked actions and services **/
            workorder && dispatch(unitActions.getUnit(workorder.unit_id));
        }, error => dispatch(failure(error)));
    };
};
workorderActions.attachFiles = (file, workorder_id) => {
    //<editor-fold desc="Return functions">
    const request = () => ({type: workorderConstants.UPDATE_WORKORDER_REQUEST});
    const success = (attachment) => ({type: workorderConstants.UPDATE_WORKORDER_SUCCESS, attachment});
    const failure = (error) => ({type: workorderConstants.UPDATE_WORKORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderService.attachFiles(file).then(attachment => {
            dispatch(workorderActions.getDetail(workorder_id));
            dispatch(success(attachment));
            }, error => dispatch(failure(error)));
    };
};
