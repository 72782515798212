import {taskPriorityConstants} from '../constants/';
import {taskPriorityService} from '../services/';

export const taskPriorityActions = {};
taskPriorityActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskPriorityConstants.GET_ALL_TASK_PRIORITIES_REQUEST});
    const success = (task_priorities) => ({type: taskPriorityConstants.GET_ALL_TASK_PRIORITIES_SUCCESS, task_priorities});
    const failure = (error) => ({type: taskPriorityConstants.GET_ALL_TASK_PRIORITIES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskPriorityService.getAll()
            .then(task_priorities => dispatch(success(task_priorities)), error => dispatch(failure(error)));
    };
};
taskPriorityActions.getTaskPriority = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskPriorityConstants.DETAIL_TASK_PRIORITY_REQUEST});
    const success = (task_priority) => ({type: taskPriorityConstants.DETAIL_TASK_PRIORITY_SUCCESS, task_priority});
    const failure = (error) => ({type: taskPriorityConstants.DETAIL_TASK_PRIORITY_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskPriorityService.getDetail(id)
            .then(task_priority => dispatch(success(task_priority)), error => dispatch(failure(error)));
    };
};
taskPriorityActions.createTaskPriority = (task_priority) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskPriorityConstants.CREATE_TASK_PRIORITY_REQUEST});
    const success = (task_priority) => ({type: taskPriorityConstants.CREATE_TASK_PRIORITY_SUCCESS, task_priority});
    const failure = (error) => ({type: taskPriorityConstants.CREATE_TASK_PRIORITY_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskPriorityService.createTaskPriority(task_priority)
            .then(task_priority => dispatch(success(task_priority)), error => dispatch(failure(error)));
    };
};
taskPriorityActions.updateTaskPriority = (task_priority) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskPriorityConstants.UPDATE_TASK_PRIORITY_REQUEST});
    const success = (task_priority) => ({type: taskPriorityConstants.UPDATE_TASK_PRIORITY_SUCCESS, task_priority});
    const failure = (error) => ({type: taskPriorityConstants.UPDATE_TASK_PRIORITY_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskPriorityService.updateTaskPriority(task_priority)
            .then(task_priority => dispatch(success(task_priority)), error => dispatch(failure(error)));
    };
};
