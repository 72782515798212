/*Handles all actions for asset_type model on server.*/
import {assetTypeConstants} from '../constants/';
import {assetTypeService} from '../services/';
export const assetTypeActions = {};

/*Get full list of asset_types request.*/
assetTypeActions.getAssetTypes = () => {
    return dispatch => {
        dispatch(request());

        assetTypeService.getAll()
            .then(
                asset_types => {
                    dispatch(success(asset_types))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: assetTypeConstants.GET_ALL_ASSET_TYPES_REQUEST}
    }

    function success(asset_types) {
        return {type: assetTypeConstants.GET_ALL_ASSET_TYPES_SUCCESS, asset_types}
    }

    function failure(error) {
        return {type: assetTypeConstants.GET_ALL_ASSET_TYPES_FAILURE, error}
    }

    //</editor-fold>
};

/*Get asset_type detail request.*/
assetTypeActions.getAssetType = (id) => {
    return dispatch => {
        dispatch(request());

        assetTypeService.getDetail(id)
            .then(
                asset_type => {
                    dispatch(success(asset_type));
                    if (asset_type) {
                        localStorage.setItem('asset_type_id', asset_type.id)
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: assetTypeConstants.ASSET_TYPE_DETAIL_REQUEST}
    }

    function success(asset_type) {
        return {type: assetTypeConstants.ASSET_TYPE_DETAIL_SUCCESS, asset_type}
    }

    function failure(error) {
        return {type: assetTypeConstants.ASSET_TYPE_DETAIL_FAILURE, error}
    }

    //</editor-fold>
};

/*Clear asset_type detail request.*/
assetTypeActions.clearAssetType = () => {
    return dispatch => {
        dispatch(success(null));
    };

    //<editor-fold desc="Dispatch results to state">

    function success(asset_type) {
        return {type: assetTypeConstants.ASSET_TYPE_DETAIL_SUCCESS, asset_type}
    }

    //</editor-fold>
};

/*Update asset_type request.*/
assetTypeActions.updateAssetType = (asset_type) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after asset_type updated.
        assetTypeService.updateAssetType(asset_type)
            .then(
                asset_type => {
                    dispatch(success(asset_type));
                    if (asset_type) {
                        dispatch(assetTypeActions.getAssetType(asset_type.id));
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: assetTypeConstants.ASSET_TYPE_UPDATE_REQUEST}
    }

    function success(asset_type) {
        return {type: assetTypeConstants.ASSET_TYPE_UPDATE_SUCCESS, asset_type}
    }

    function failure(error) {
        return {type: assetTypeConstants.ASSET_TYPE_UPDATE_FAILURE, error}
    }

    //</editor-fold>
};

/*Create new asset_type request.*/
assetTypeActions.createAssetType = (asset_type) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after asset_type creation.
        assetTypeService.createAssetType(asset_type)
            .then(
                asset_type => {
                    dispatch(success(asset_type));
                    if (asset_type) {
                        dispatch(assetTypeActions.getAssetType(asset_type.id))
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: assetTypeConstants.ASSET_TYPE_CREATE_REQUEST}
    }

    function success(asset_type) {
        return {type: assetTypeConstants.ASSET_TYPE_CREATE_SUCCESS, asset_type}
    }

    function failure(error) {
        return {type: assetTypeConstants.ASSET_TYPE_CREATE_FAILURE, error}
    }

    //</editor-fold>
};
