export const saveWO = (props, state) => {
    console.log(state);
    const wo = {
        id: state.id,
        other_description: state.other_description,
        general_status: state.general_status,
        est_complete_date: state.est_complete_date,
        mechanic_id: state.mechanic_id,
        vendor_id: state.vendor_id,
        yard_id: state.yard_id,
        unit_id: state.unit_id,
        unit_status_id: state.unit_status_id
    }
    props.updateWorkOrder(wo);
    console.log(wo);
};
