import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    noGutters: PropTypes.bool,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    form: PropTypes.bool,
    innerRef: PropTypes.node
};

const defaultProps = {
    tag: 'div'
};

const Row = ({ className, cssModule, noGutters, tag: Tag, form, style, innerRef, ...attributes }) => {
    const classes = mapToCssModules(classNames(
        className,
        noGutters ? 'no-gutters' : null,
        form ? 'form-row' : 'row'
    ), cssModule);

    return (
        <Tag ref={innerRef} {...attributes} className={classes} style={style} />
    );
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
