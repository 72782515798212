import React from 'react';
import {useState} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Card,
    CardHeader,
    CardBody,
    CardGroup,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from '../../components/bot-components';
import { connect } from 'react-redux';
import {userActions} from '../../network/actions';

const Login = ({ login, loggingIn }) => {

    const [state, updateState] = useState({
        email: '',
        password: '',
        submitted: false
    });

    const {email, password, submitted} = state;

    const setState = (newState) => {
        updateState(currentState => ({
            ...currentState,
            ...newState
        }));
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState({[name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setState({submitted: true});
        if (email && password) {
            const buff = new Buffer(password);
            const pass = buff.toString('base64');
            login(email, pass);
        }
    };

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <CardGroup>
                            <Card className="p-4">
                                <CardHeader className={'login-header'}>
                                    <h1 style={{textAlign: 'center', color: 'white', fontWeight: 'bold'}}>Login</h1>
                                    <p style={{textAlign: 'center', color: 'black'}}>Sign In to your
                                        account</p>
                                </CardHeader>
                                <CardBody>
                                    <Form action={''} method={'post'}>
                                        <FormGroup>
                                            <InputGroup
                                                className={"mb-3" + (submitted && !email ? ' has-error' : '')}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder="email" name="email" value={email}
                                                       onChange={handleChange}/>
                                                       {submitted && !email &&
                                                       <div className="help-block">email is required</div>}
                                            </InputGroup>
                                            <InputGroup
                                                className={"mb-4" + (submitted && !password ? ' has-error' : '')}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" placeholder="Password" name="password"
                                                       value={password} onChange={handleChange}/>
                                                       {submitted && !password &&
                                                       <div className="help-block">Password is required</div>}
                                            </InputGroup>
                                        </FormGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button type={'submit'} color="primary" className="px-4"
                                                        onClick={handleSubmit}>Login</Button>
                                                    {loggingIn &&
                                                    <img alt={''}
                                                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>}
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStateToProps = state => {
    const {loggingIn} = state.authentication;
    return { loggingIn };
}

const mapDispatchToProps = dispatch => {
    return {
        login: (email, password) => dispatch(userActions.login(email, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
