export const defaultState = {
    unit_type_id: null,
    company_id: null,
    unit_status_id: null,
    unit_subtype_id: null,
    job_id: null,
    unit_number: null,
    plate: null,
    vin_sn: null,
    registration_date: null,
    description: null,
    location: null,
    cadr: null,
    meter: null,
    mileage: null,
    hours: null,
    dot: false,
    tire_size_id: null,
    asset_type_id: null,
    active: null,
    tire_size_2_id: null,
    tire_size_3_id: null,
    tire_size_4_id: null,
    tare_weight: null,
    gross_weight: null,
    height: null,
    wheelbase: null,
    axles: null,
    width: null,
    overall_length: null,
    horsepower: null,
    showModal: false,
    descriptionValid: true,
    typeValid: true,
    hoursValid: true,
    milesValid: true,
    tare_weight_valid: true,
    gross_weight_valid: true,
    axles_valid: true,
    unitNumValid: true,
    unitSerialValid: true,
    unitNumExists: true,
    unitSerialExists: true,
    selectedSize: null,
    selectedSize2: null,
    selectedSize3: null,
    selectedSize4: null,
    selectedStatus: null,
    selectedAssetType: null,
    selectedUnitType: null,
    selectedUnitSubType: null,
    selectedCompany: null
};
