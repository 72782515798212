export const commentConstants = {
    CREATE_COMMENT_REQUEST: 'COMMENT_CREATE_REQUEST',
    CREATE_COMMENT_SUCCESS: 'COMMENT_CREATE_SUCCESS',
    CREATE_COMMENT_FAILURE: 'COMMENT_CREATE_FAILURE',

    UPDATE_COMMENT_REQUEST: 'COMMENT_UPDATE_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'COMMENT_UPDATE_SUCCESS',
    UPDATE_COMMENT_FAILURE: 'COMMENT_UPDATE_FAILURE',

    GET_ALL_COMMENTS_REQUEST: 'COMMENTS_GET_ALL_REQUEST',
    GET_ALL_COMMENTS_SUCCESS: 'COMMENTS_GET_ALL_SUCCESS',
    GET_ALL_COMMENTS_FAILURE: 'COMMENTS_GET_ALL_FAILURE',

    DETAIL_COMMENT_REQUEST: 'COMMENT_DETAIL_REQUEST',
    DETAIL_COMMENT_SUCCESS: 'COMMENT_DETAIL_SUCCESS',
    DETAIL_COMMENT_FAILURE: 'COMMENT_DETAIL_FAILURE',
};
