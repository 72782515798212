import {unitStatusConstants} from '../constants';

export const unit_statuses = (state = {}, action) => {
    switch (action.type) {
        case unitStatusConstants.GET_ALL_UNIT_STATUSES_REQUEST:
            return {
                loading: true
            };
        case unitStatusConstants.GET_ALL_UNIT_STATUSES_SUCCESS:
            return {
                items: action.unit_statuses
            };
        case unitStatusConstants.GET_ALL_UNIT_STATUSES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const unit_status = (state = {}, action) => {
    switch (action.type) {
        case unitStatusConstants.CREATE_UNIT_STATUS_REQUEST:
            return {
                loading: true
            };
        case unitStatusConstants.CREATE_UNIT_STATUS_SUCCESS:
            return {
                items: action.unit_status
            };
        case unitStatusConstants.CREATE_UNIT_STATUS_FAILURE:
            return {
                error: action.error
            };
        case unitStatusConstants.DETAIL_UNIT_STATUS_REQUEST:
            return {
                loading: true
            };
        case unitStatusConstants.DETAIL_UNIT_STATUS_SUCCESS:
            return {
                items: action.unit_status
            };
        case unitStatusConstants.DETAIL_UNIT_STATUS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
