import {unitStatusConstants} from '../constants/';
import {unitStatusService} from '../services/';

export const unitStatusActions = {};
unitStatusActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitStatusConstants.GET_ALL_UNIT_STATUSES_REQUEST});
    const success = (unit_statuses) => ({type: unitStatusConstants.GET_ALL_UNIT_STATUSES_SUCCESS, unit_statuses});
    const failure = (error) => ({type: unitStatusConstants.GET_ALL_UNIT_STATUSES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitStatusService.getAll()
            .then(unit_statuses => dispatch(success(unit_statuses)), error => dispatch(failure(error)));
    };
};
unitStatusActions.getStatus = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitStatusConstants.DETAIL_UNIT_STATUS_REQUEST});
    const success = (unit_status) => ({type: unitStatusConstants.DETAIL_UNIT_STATUS_SUCCESS, unit_status});
    const failure = (error) => ({type: unitStatusConstants.DETAIL_UNIT_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitStatusService.getStatus(id)
            .then(unit_status => dispatch(success(unit_status)), error => dispatch(failure(error)));
    };
};
unitStatusActions.createStatus = (unit_status) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitStatusConstants.CREATE_UNIT_STATUS_REQUEST});
    const success = (unit_status) => ({type: unitStatusConstants.CREATE_UNIT_STATUS_SUCCESS, unit_status});
    const failure = (error) => ({type: unitStatusConstants.CREATE_UNIT_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitStatusService.createUnitStatus(unit_status)
            .then(unit_status => {
                dispatch(success(unit_status));
                dispatch(unitStatusActions.getAll())
            }, error => dispatch(failure(error)));
    };
};
