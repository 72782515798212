import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import BotWorkOrders from '../WorkOrders/BotWorkOrders/BotWorkOrders';
import BotGpsDataTable from '../GpsData/BotGpsDataTable';
import BotInspectionTable from '../Inspections/BotInspections';
import BotTasks from '../Tasks/BotTasks/BotTasks';
import BotUsers from '../UserManagement/BotUsers/BotUsers';

const propTypes = {
    ref: PropTypes.node,
    data: PropTypes.array,
    field: PropTypes.string,
    title: PropTypes.string,
    modal: PropTypes.string
};

const ModalBodies = forwardRef((props, ref) => {
    const {data, field, title, modal, jobList, yardList, updateWorkOrder, getMyUnit, getMyWorkorder, user_preference, getMyLog, log, vendList, mechList,
        mechanicList, PriorityList, updateTask, task, getAlert, getTask, getUser, unit, workorder} = props;

    const myModal = () => {
        switch (modal) {
            case 'log':
                return createLogModalBody(data, field, title);
            case 'ta':
                return createTaskModalBody(data, field, title);
            case 'us':
                return createUserModalBody(data, field, title);
            case 'un':
                return createUnitModalBody(data, field, title);
            case 'wo':
                return createWoModalBody(data, title);
            default:
                break;
        }
    };

    const createWoModalBody = (data, title) => {
        return (
            <BotWorkOrders
                ref={ref}
                data={data}
                tblId={'woModRef'}
                remote={false}
                unit={unit}
                actionCount={2}
                viewAction={true}
                popoutAction={true}
                title={'Unit Work Orders'}
                jobList={jobList}
                yardList={yardList}
                pageSizes={['20', '50', '100', '500', '1000']}
                updateWorkorder={(wo) => updateWorkOrder(wo)}
                getWorkorders={(id) => getMyUnit(id)}
                getMyWorkOrder={(id) => getMyWorkorder(id)}
                canCreate={false}
                u_preferences={user_preference}
                csvFile={`${title}.csv`}
                getMyUnit={(id) => getMyUnit(id)}
                includeDateRange={false}/>
        );
    };

    const createUnitModalBody = (data, field) => {
        return(
            <BotGpsDataTable
                ref={ref}
                data={data}
                includeDateRange={false}
                getExceptions={() => null}
                csvFile={`${field}.csv`}
                lStorage={'dashboard_inspection_exceptions_storage'}
                title={'Inspection Exceptions'}
                loading={false}
                pageSizes={['20', '50', '100', '500', '1000']}
                tblId={'insTblRef'}
                remote={false}
                u_preferences={props['user_preference']}
                getUnit={(id) => getMyUnit(id)}
                unit={unit}
            />
        )
    };

    const createLogModalBody = (data, field, title) => {
        return (
            <BotInspectionTable ref={ref}
                                getInspections={() => null}
                                getMyInspection={(id) => getMyLog(id)}
                                getMyUnit={(id) => getMyUnit(id)}
                                getMyWorkOrder={(id) => getMyWorkorder(id)}
                                title={title}
                                actionCount={2}
                                data={data ?? null}
                                viewAction={true}
                                popoutAction={true}
                                onRowSelected={(id) => {getMyLog(id)}}
                                unit={unit}
                                tblId={'woModRef'}
                                remote={false}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                workorder={null}
                                csvFile={`${field}.csv`}
                                u_preferences={user_preference}
                                inspection={log}/>
        );
    };

    const createTaskModalBody = (data, field, title) => {
        return (
            <BotTasks ref={ref}
                      data={data}
                      workorder={workorder}
                      tblId={'woModRef'}
                      popoutAction={true}
                      viewAction={true}
                      actionCount={2}
                      title={title}
                      vendList={vendList}
                      vendorList={vendList}
                      mechList={mechList}
                      mechanicList={mechanicList}
                      pageSizes={['20', '50', '100', '500', '1000']}
                      getTasks={() => null}
                      unit={unit}
                      remote={false}
                      priorityList={PriorityList}
                      getMyWorkOrder={(id) => getMyWorkorder(id)}
                      getMyUnit={(id) => getMyUnit(id)}
                      updateTask={(t) => updateTask(t)}
                      createTask={(t) => console.debug(t)}
                      task={task} canCreate={false}
                      csvFile={`${field}.csv`}
                      u_preferences={user_preference}
                      getMyAlert={(id) => getAlert(id)}
                      getMyTask={(id) => getTask(id)}/>
        );
    };

    const createUserModalBody = (data, field, title) => {
        return (
            <BotUsers ref={ref}
                      getUsers={() => null}
                      onUserSelected={(id) => getUser(id)}
                      getMyUser={(id) => getUser(id)}
                      data={data}
                      remote={false}
                      pageSizes={['20', '50', '100', '250', '500', '1000']}
                      title={title}
                      csvFile={`${field}.csv`}
                      u_preferences={user_preference}
                      extraColumns={[]}
                      tblId={'woModRef'}/>
        );
    };

    return myModal();
});

ModalBodies.propTypes = propTypes;
export default ModalBodies;
