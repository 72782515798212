import {JobConstants} from '../constants/';
import {jobService} from '../services/';
import {yardActions} from "./";

export const jobActions = {};
jobActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: JobConstants.GET_ALL_JOBS_REQUEST});
    const success = (jobs) => ({type: JobConstants.GET_ALL_JOBS_SUCCESS, jobs});
    const failure = (error) => ({type: JobConstants.GET_ALL_JOBS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobService.getAll()
            .then(jobs => dispatch(success(jobs.jobs)), error => dispatch(failure(error)));
    };
};
jobActions.getJob = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: JobConstants.DETAIL_JOB_REQUEST});
    const success = (job) => ({type: JobConstants.DETAIL_JOB_SUCCESS, job});
    const failure = (error) => ({type: JobConstants.DETAIL_JOB_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobService.getJob(id)
            .then(job => dispatch(success(job)), error => dispatch(failure(error)));
    };
};
jobActions.createJob = (job) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: JobConstants.CREATE_JOB_REQUEST});
    const success = (job) => ({type: JobConstants.CREATE_JOB_SUCCESS, job});
    const failure = (error) => ({type: JobConstants.CREATE_JOB_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobService.createJob(job)
            .then(job => {
                dispatch(success(job));
                job && dispatch(yardActions.getYard(job['yard_id']))
            }, error => dispatch(failure(error)));
    };
};
jobActions.updateJob = (job) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: JobConstants.UPDATE_JOB_REQUEST});
    const success = (job) => ({type: JobConstants.UPDATE_JOB_SUCCESS, job});
    const failure = (error) => ({type: JobConstants.UPDATE_JOB_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobService.updateJob(job)
            .then(job => {
                job && dispatch(success(job));
                job && dispatch(yardActions.getYard(job.yard_id))
            }, error => dispatch(failure(error)));
    };
};
jobActions.deleteJob = (job) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: JobConstants.DELETE_JOB_REQUEST});
    const success = (job) => ({type: JobConstants.DELETE_JOB_SUCCESS, job});
    const failure = (error) => ({type: JobConstants.DELETE_JOB_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        jobService.deleteJob(job)
            .then(job => {
                dispatch(success(job));
                dispatch(jobActions.getAll());
                }, error => dispatch(failure(error)));
    };
};
jobActions.clearJobs = () => {
    const clear = (yard_jobs) => ({type: JobConstants.CLEAR_JOBS, yard_jobs});
    return dispatch => {
        dispatch(clear([]))
    };
};
jobActions.load = (yard_jobs) => {
    const success = (yard_jobs) => ({type: JobConstants.GET_YARD_JOBS_SUCCESS, yard_jobs});
    return dispatch => dispatch(success(yard_jobs));
};
