import React from 'react';
import PropTypes from 'prop-types';
import { BotTable, Modal, ModalBody, ModalHeader } from '../../../components/bot-components';
import BotAttachment from '../BotAttachment/BotAttachment';

const propTypes = {
  title:PropTypes.string,
  data:PropTypes.array,
  csvFile:PropTypes.string,
  csvUrl:PropTypes.string,
  lStorage:PropTypes.string,
  remote:PropTypes.bool,
  tblId:PropTypes.string,
  pageSizes:PropTypes.array,
  columns:PropTypes.array,
  getAttachment:PropTypes.func,
  updateAttachment:PropTypes.func,
  createAttachment:PropTypes.func,
  canCreate:PropTypes.bool,
  onSaveComment:PropTypes.func,
  loading:PropTypes.bool,
  u_preferences:PropTypes.any,
  parentComp:PropTypes.string
};
let columns = [];
const defaultFilters = {
  filename:''
};
const myFilters = {};
Object.assign( myFilters, defaultFilters );
// let table_key_iterator = 0;
const defaultState = {
  sortName:'',
  sortOrder:'',
  currSize:20,
  currPage:1,
  filters:myFilters
};

const BotAttachments = React.forwardRef(
  /**
   *
   * @param props
   * @param props.attachment
   * @param props.getAttachment
   * @param props.onSaveComment
   * @param props.columns
   * @param props.title
   * @param props.showTitle
   * @param props.data
   * @param props.pageSizes
   * @param props.loading
   * @param props.remote
   * @param props.lStorage
   * @param props.getAttachments
   * @param props.user_preference
   * @param ref
   * @returns {JSX.Element}
   */
  ( props, ref ) => {
    const [state, updateState] = React.useState( {
      detailModal:false,
      attachment:null
    } );

    /**
     *
     * @param {Object} newState
     */
    const setState = newState => (updateState( ( prevState ) => ({
      ...prevState,
      ...newState
    }) ));
    const filenameRef = React.useRef();

    React.useEffect( () => {
      setState( {
        attachment:props.attachment?.items
      } );
    }, [props.attachment] );

    const toggleDetailModal = () => {
      setState( {
        detailModal:!state.detailModal
      } );
    };

    const prepareType = ( cell, row ) => {
      if (row.mimetype === 'application/pdf') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'red' } } className={ 'fa fa-file-pdf-o' }/> { row.image_name }</div>;
      } else if (row.mimetype === 'image/jpeg') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'black' } } className={ 'fa fa-file-image-o' }/> { row.image_name }</div>;
      } else if (row.mimetype === 'image/png') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'black' } } className={ 'fa fa-file-image-o' }/> { row.image_name }</div>;
      } else if (row.mimetype === 'text/csv' || row.mimetype === 'application/vnd.ms-excel' || row.mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || row.mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' || row.mimetype === 'application/vnd.ms-excel.sheet.macroEnabled.12' || row.mimetype === 'application/vnd.ms-excel.template.macroEnabled.12' || row.mimetype === 'application/vnd.ms-excel.addin.macroEnabled.12' || row.mimetype === 'application/vnd.ms-excel.sheet.binary.macroEnabled.12') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'green' } } className={ 'fa fa-file-excel-o' }/> { row.image_name }</div>;
      } else if (row.mimetype === 'application/msword' || row.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || row.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' || row.mimetype === 'application/vnd.ms-word.document.macroEnabled.12' || row.mimetype === 'application/vnd.ms-word.template.macroEnabled.12') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'blue' } } className={ 'fa fa-file-word-o' }/> { row.image_name }</div>;
      } else if (row.mimetype === 'application/vnd.ms-powerpoint' || row.mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || row.mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.template' || row.mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' || row.mimetype === 'application/vnd.ms-powerpoint.addin.macroEnabled.12' || row.mimetype === 'application/vnd.ms-powerpoint.presentation.macroEnabled.12' || row.mimetype === 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12') {
        return <div className={ 'attachment-link' } onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i style={ { color:'orange' } } className={ 'fa fa-file-powerpoint-o' }/> { row.image_name }</div>;
      } else {
        /** @namespace row.image_name */
        return <div onClick={ () => {
          props.getAttachment( row.id );
          toggleDetailModal();
        } }><i className={ 'fa fa-file-o' }/> { row.image_name }</div>;
      }
    };

    const prepareComment = ( cell ) => {
      if (cell && cell.length > 0) {
        return cell.map( comment => {
          return comment && comment.comment;
        } ).join( ', ' );
      }
    };

    const prepareSize = ( bytes ) => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (parseInt( bytes, 0 ) === 0) return '0 Byte';
      let i = parseInt( Math.floor( Math.log( bytes ) / Math.log( 1024 ) ).toString() );
      return Math.round( bytes / Math.pow( 1024, i ) ).toString() + ' ' + sizes[i];
    };

    const onSaveComment = ( Comment ) => {
      const image_id = state.attachment.id;
      Promise.resolve( image_id ).then( () => {
        Comment.image_id = image_id;
        props.onSaveComment( Comment );
      } );
    };

    columns = props.columns ? props.columns : [
      {
        field:'filename',
        text:'Filename',
        width:500,
        filter:{
          type:'text',
          defaultValue:defaultFilters.filename,
          placeholder:'Enter Filename'
        },
        hidden:false,
        sortable:true,
        refProp:filenameRef,
        format:( cell, row ) => prepareType( cell, row )
      },
      {
        field:'comments',
        text:'Comments',
        width:900,
        hidden:false,
        sortable:false,
        format:( cell ) => prepareComment( cell )
      },
      {
        field:'size',
        text:'Size',
        width:200,
        hidden:false,
        sortable:false,
        format:( cell ) => prepareSize( cell )
      }
    ];
    return (
      <>
        <Modal size={ 'xl' } isOpen={ state.detailModal } toggle={ toggleDetailModal }>
          <ModalHeader toggle={ toggleDetailModal }>
            { state.attachment && state.attachment.image_name }
          </ModalHeader>
          <ModalBody>
            <BotAttachment attachment={ state.attachment }
                           onSaveComment={ ( Comment ) => {
                             onSaveComment( Comment );
                           } }
            />
          </ModalBody>
        </Modal>
        <BotTable ref={ ref }
                  columns={ columns }
                  title={ props.title }
                  data={ props.data }
                  getData={ ( req ) => props.getAttachments?.( req ) }
                  pageSizes={ props.pageSizes }
                  ClearFiltersBtn={ true }
                  ClearFiltersIcon={ true }
                  pagination={ 'both' }
                  headers={ true }
                  doRowUpdate={ () => null }
                  btnGroupDirection={ 'left' }
                  showTitle={ props.showTitle }
                  defaultState={ defaultState }
                  loading={ props.loading }
                  remote={ props.remote }
                  defaultFilters={ defaultFilters }
                  lStorage={ props.lStorage }
                  user_preference={ props.user_preference }/>
      </>
    );
  } );

BotAttachments.propTypes = propTypes;
export default BotAttachments;
