import React from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from '../../../components/bot-components';
import classnames from 'classnames';
import OpenTasks from "../OpenTasks/OpenTasks";
import AllTasks from "../AllTasks/AllTasks";
import * as funcs from './DashboardTasks.helpers';
// import TaskExceptions from '../Exceptions/task_exceptions';

const defaultProps = {};
const DashboardTasks = (props) => {
    const [state, updateState] = React.useState({
        activeTab: localStorage.getItem('dbTasksTab') ?? '1',
        loading: false,
        myTasks: []
    });
    const setState = (newState) => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    React.useEffect(() => {
        localStorage.setItem('lastComp', '/workorders/tasks');
    })
    const toggle = (tab) => funcs.toggle(tab, state, setState);
    const getTasks = (req, uri) => funcs.getTasks(req, uri, setState);
    Object.assign(defaultProps, props);

    return (
        <div className="animated fadeIn">
            <Nav tabs>
                <NavItem>
                    <NavLink style={{textDecoration: 'underline'}} className={classnames({active: state.activeTab === '1'})}
                             onClick={() => {
                                 toggle('1');
                             }}>
                        Open Tasks
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{textDecoration: 'underline'}} className={classnames({active: state.activeTab === '2'})}
                             onClick={() => {
                                 toggle('2');
                             }}>
                        All Tasks
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink style={{textDecoration: 'underline'}} className={classnames({active: state.activeTab === '3'})}
                             onClick={() => {
                                 toggle('3');
                             }}>
                        Task Exceptions
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activetab={state.activeTab}>
                <TabPane tabId={'1'}>
                    {state.activeTab === '1' && <OpenTasks getTasks={(req, uri) => getTasks(req, uri)} loading={state.loading} tasks={state.myTasks}/>}
                </TabPane>
                <TabPane tabId={'2'}>
                    {state.activeTab === '2' && <AllTasks getTasks={(req, uri) => getTasks(req, uri)} loading={state.loading} tasks={state.myTasks}/>}
                </TabPane>
                <TabPane tabId={'3'}>
                    {/*{state.activeTab === '3' && <TaskExceptions/>}*/}
                </TabPane>
            </TabContent>
        </div>
    );
};

DashboardTasks.defaultProps = defaultProps;
export default DashboardTasks;
