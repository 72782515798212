/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {unitActions} from "../../network/actions";
import BotUnits from './BotUnits/BotUnits';
import {assetTypeActions, tireSizeActions} from "../../network/actions";

const AllUnits = (props) => {
    const auRef = React.useRef();
    React.useEffect(() => {
        localStorage.setItem('lastComp', props.history?.location?.pathname);
        props.getTireSizes();
        props.getAssetTypes();
    }, []);
    const updateUnit = (u) => {
        delete u.show;
        props.updateUnit(u);
    };

    const {unit, units, asset_types, tire_sizes, saveUnit} = props;

    return (
        <BotUnits ref={auRef}
                  history={props.history}
                  canCreate={true}
                  alertAction={false}
                  updateUnit={(u) => updateUnit(u)}
                  getUnits={(req) => props.getUnits(req)}
                  getMyUnit={(id) => props.getMyUnit(id)}
                  saveUnit={saveUnit}
                  lStorage={'all_unit_storage'}
                  viewAction
                  editAction
                  popoutAction
                  tblId={'auRef'}
                  data={units?.items}
                  csvFile={'equipment_list.csv'}
                  csvUrl={'units'}
                  title={'All Equipment'}
                  actionCount={3}
                  remote={true}
                  unit={unit}
                  pageSizes={['20', '50', '100', '250', '500', '1000']}
                  asset_types={asset_types}
                  loading={units?.loading}
                  u_preferences={props.user_preference}
                  tire_sizes={tire_sizes}/>
    );
};
const mapStateToProps = (state) => {
    const {unit, units, tire_sizes, asset_types, user_preference} = state;

    return {unit, units, asset_types, tire_sizes, user_preference};
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveUnit: (unit) => dispatch(unitActions.createUnit(unit)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getUnits: (req) => dispatch(unitActions.getUnits(req)),
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes()),
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUnits);
