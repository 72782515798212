import React, { useState } from 'react';
import classnames from 'classnames';
import { unitSubTypeActions, unitTypeActions, userActions } from '../../network/actions';
import connect from 'react-redux/es/connect/connect';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from '../../components/bot-components';
import UnitTypeTable from './BotUnitTypes';
import UnitClasses from '../UnitClasses/UnitClasses';
import { authHeader, store } from '../../helpers';
import { baseUrl } from '../../network/constants';
import ReduxBlockUi from 'react-block-ui';

const UnitTypes = ( props ) => {
  const [pagTypes, setPagTypes] = useState( [] );
  const [newName, setNewName] = useState( '' );
  const [newModal, setNewModal] = useState( false );
  const [loading, setLoading] = useState( false );
  const [activeTab, setActiveTab] = useState( 'Types' );
  const tblRef = React.useRef();
  React.useEffect( () => {
    props.getUnitSubTypes();
    props.getUnitTypes();
    localStorage.setItem( 'lastComp', props.history?.location?.pathname );
  }, [] );

  const getUnitTypes = ( req ) => {
    setLoading( true );
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( req )
    };
    const url = new URL( `${ baseUrl }/unit_types` );

    return fetch( url, requestOptions ).then( response => {
      return response.status !== 404 ? response.json().then( async data => {
        if (!response.ok) {
          if (response.status === 401) {
            alert( 'You are not authorized to access this information. This incident has been logged, please contact the administrator!' );
          }
          alert( 'There was an error loading the work orders, please try again...' );
          setPagTypes( [] );
          setLoading( false );
        }
        const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
        if (Auth) {
          await store.dispatch( userActions.updateAuth( Auth, null ) );
        }
        if (data) {
          setPagTypes( data );
          setLoading( false );
        } else {
          setPagTypes( [] );
          setLoading( false );
        }
      } ).catch( error => Promise.reject( error ) ) : Promise.reject( {
        message:response.statusText,
        status:response.status
      } );
    } );
  };

  const updateUnitType = ( unit_type ) => {
    setLoading( true );
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( unit_type )
    };
    return fetch( `${ baseUrl }/unit_type/${ unit_type.id }`, requestOptions ).then( response => {
      return response.status !== 404 ? response.json().then( async data => {
        if (!response.ok) {
          if (response.status === 401) {
            alert( 'You are not authorized to access this information. This incident has been logged, please contact the administrator!' );
          }
          alert( 'There was an error loading the work orders, please try again...' );
          return setLoading( false );
        }
        const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
        if (Auth) {
          await store.dispatch( userActions.updateAuth( Auth, null ) );
        }
        if (data) {
          return getUnitTypes();
        } else return setLoading( false );
      } ).catch( error => Promise.reject( error ) ) : Promise.reject( {
        message:response.statusText,
        status:response.status
      } );
    } );
  };

  const toggleNew = () => {
    setNewModal( prevState => !prevState );
  };

  const createUnitType = async () => {
    setLoading( true );
    await props.createUnitType( { utype:newName } );
    await setNewName( '' );
    await setNewModal( false );
    setTimeout( () => {
      return getUnitTypes();
    }, 500 );
  };

  const { unit_type } = props;

  const CreateNewTypeBtn = () => (
    <DropdownItem color={ 'texts' } outline onClick={ toggleNew }><i className={ 'fa fa-plus' } style={ {
      marginRight:'0.9rem',
      paddingLeft:'0.1rem'
    } }/>New Type</DropdownItem>);

  return (
    <>
      <ReduxBlockUi blocking={ loading } tag={ 'div' }
                    message={ <Label className={ 'blocking-loading-lbl' }>Please Wait</Label> }>
        <Card>
          <Modal centered isOpen={ newModal } toggle={ () => toggleNew() }>
            <ModalHeader toggle={ () => toggleNew() }>
              Create New Equipment Type
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={ 12 }>
                  <Label for={ 'new-type' }>Enter a new name for the Type</Label>
                </Col>
              </Row>
              <Row>
                <Col sm={ 12 }>
                  <Input id={ 'new-type' } type={ 'text' }
                         onChange={ e => setNewName( e.target.value ) }/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button disabled={ !newName } outline color={ 'texts' }
                      onClick={ () => createUnitType() }>Save</Button>
            </ModalFooter>
          </Modal>
          <CardHeader>
            <Nav tabs style={ { width:'max-content' } }>
              <NavItem>
                <NavLink className={ classnames( { active:activeTab === 'Types' } ) }
                         onClick={ () => setActiveTab( 'Types' ) }>
                  Types
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={ classnames( { active:activeTab === 'Classes' } ) }
                         onClick={ () => setActiveTab( 'Classes' ) }>
                  Classes
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activetab={ activeTab }>
              <TabPane tabId={ 'Types' } className={ activeTab === 'Types' ? 'active' : '' }>
                <UnitTypeTable
                  ref={ tblRef }
                  getUnitTypes={ ( req ) => getUnitTypes( req ) }
                  getUnitType={ ( id ) => props.getUnitType( id ) }
                  title={ 'All Unit Types' }
                  unit_type={ unit_type }
                  actionCount={ 2 }
                  data={ pagTypes }
                  viewAction={ true }
                  popoutAction={ true }
                  canCreate={ true }
                  createNewBtn={ CreateNewTypeBtn }
                  createUnitType={ props.createUnitType }
                  tblId={ 'tblRef' }
                  updateUnitType={ ( ut ) => updateUnitType( ut ) }
                  csvFile={ 'equipment_types.csv' }
                  csvUrl={ 'unit_types' }
                  lStorage={ 'unit_type_storage' }
                  remote={ false }
                  pageSizes={ ['20', '50', '100', '500', '1000'] }
                  loading={ false }
                  u_preferences={ props.user_preference }
                  history={ props.history }
                />
              </TabPane>
              <TabPane tabId={ 'Classes' } className={ activeTab === 'Classes' ? 'active' : '' }>
                <UnitClasses unit_types={pagTypes} />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </ReduxBlockUi>
    </>
  );
};

const mapStateToProps = ( state ) => {
  const { unit_type, unit_types, unit_subtypes } = state;
  const typeList = unit_types?.items?.map( ut => ({ label:ut.utype, value:ut.id }) );
  return { unit_type, unit_types, unit_subtypes, typeList };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    getUnitTypes:() => dispatch( unitTypeActions.getAll() ),
    getUnitType:( id ) => dispatch( unitTypeActions.getType( id ) ),
    updateUnitType:( t ) => dispatch( unitTypeActions.updateType( t ) ),
    getUnitSubTypes:() => dispatch( unitSubTypeActions.getAll() ),
    createUnitType:( unit_type ) => dispatch( unitTypeActions.createType( unit_type ) ),
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( UnitTypes );
