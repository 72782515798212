/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Row, Col, Card, CardHeader, CardBody, Label, Button} from '../../../components/bot-components';
import * as moment from 'moment';
import DatePicker from 'react-datepicker'
import BotTasks from '../../Tasks/BotTasks/BotTasks';
import "react-datepicker/dist/react-datepicker.css";
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import PropTypes from 'prop-types';
drilldown(Highcharts);
const propTypes = {
    maint_report_task: PropTypes.any,
    maint_report_task_detail: PropTypes.any,
    taskStatuses: PropTypes.any,
    vendorList: PropTypes.any,
    mechList: PropTypes.any,
    vendList: PropTypes.any,
    mechanicList: PropTypes.any,
    PriorityList: PropTypes.any,
    task: PropTypes.any,
    getKPIData: PropTypes.func.isRequired,
    getMaintReportTaskDetail: PropTypes.func.isRequired,
    clearMaintReportTaskDetail: PropTypes.func.isRequired,
    updateTask: PropTypes.func,
    getTask: PropTypes.func.isRequired,
    getMechanics: PropTypes.func,
    getVendors: PropTypes.func,
    assignToMechanic: PropTypes.func,
    assignToVendor: PropTypes.func,
    getTaskPriorities: PropTypes.func,
    getMyWorkorder: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    getAlert: PropTypes.func.isRequired,
};
const TaskStatus = (props) => {
    const [state, updateState] = React.useState({
        maint_report_task: {},
        now: new Date(),
        yards: [],
        drilldown: [],
        selectedYard: '',
        selectedStatus: '',
        month: null,
        active: false,
        myChart: undefined
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    Highcharts.setOptions({
            lang: {
                drillUpText: 'Back to Yards',
            }
        });
    const tRef = React.useRef();
    const {myChart} = state;
    const handleDateChange = (date) => {
        const newDate = moment(date).format('YYYY-MM');
        setState({
            now: date,
            month: newDate,
        });
        props.getKPIData(newDate);
    };
    const showAllClick = () => {
        setState({
            now: null,
            month: null
        });
        props.getKPIData();
        props.clearMaintReportTaskDetail();
    };
    const getTaskStatusDetail = () => {
        const body = {
            date: state.month,
            yard: state.selectedYard,
            status: state.selectedStatus
        };
        props.getMaintReportTaskDetail(body);
        const tbl = document.querySelector('#taskStatTbl');
        state.selectedStatus && setTimeout(() => window.scrollTo(0, tbl.offsetTop - 60), 500);
    };
    React.useEffect(() => {
        updateChart().catch();
        const newDate = moment().format('YYYY-MM');
        setState({month: newDate});
        props.getKPIData(newDate);
        return () => props.clearMaintReportTaskDetail();
    }, []);
    const updateChart = () => {
        let yData = [];
        let drillData = [];
        let total = 0;
        function mapYard (Yard) {
            return new Promise(function (resolve) {
                yData.push({name: Yard.yard, data: [{name: Yard.yard, id: Yard.yard, drilldown: Yard.yard, y: parseInt(Yard.count, 0)}]});
                total = parseInt(total) + parseInt(Yard.count);
                mapTasks(Yard.details, Yard).then(function () {
                    resolve();
                });
            });
        }
        function mapTasks (Tasks, yard) {
            return new Promise(function (resolve) {
                let tasks = [];
                const set = Tasks.map(function (task) {
                    return tasks.push({name: task.unit_status, id: task.unit_status, y: parseInt(task.count, 0)});
                });
                Promise.all(set).then(function () {
                    drillData.push({name: yard.yard, id: yard.yard, data: tasks});
                    resolve();
                });
            });
        }
        const Yards = props.maint_report_task?.maint_report?.map(function (yard) {
                return mapYard(yard);
            }) ?? [];
        return Promise.all(Yards).then(function () {
            let thisMonth = state.now ? moment(state.now).format("MMMM YYYY") : 'All Tasks';
            return setState({
                myChart: Highcharts.chart("maint_report_task", {
                    chart: {
                        type: 'column',
                        events: {
                            drilldown: function (e) {
                                setState({
                                    selectedYard: e.seriesOptions.name,
                                });
                            },
                            drillup: function() {
                                props.clearMaintReportTaskDetail();
                            }
                        }
                    },
                    title: {
                        text: `Task Status Report (${total}) Tasks`
                    },
                    subtitle: {
                        text: thisMonth
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        title: {
                            text: 'Total Tasks'
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            grouping: false,
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true
                            },
                            point: {
                                events: {
                                    click: function (e) {
                                        setState({
                                            selectedStatus: e.point.name,
                                        });
                                    }
                                }
                            }
                        },
                    },
                    series: yData,
                    drilldown: {
                        series: drillData
                    }
                })
            });
        });
    };
    const addLoadingLabelToChart = chart => chart.renderer.label('Chart loading', 100, 120).attr({fill: Highcharts.getOptions().colors[0], padding: 10, r: 5, zIndex: 8}).css({color: '#FFFFFF'}).add();
    myChart && !props.maint_report_task?.maint_report && addLoadingLabelToChart(myChart);
    React.useEffect(() => {
        setState({
            maint_report_task: props.maint_report_task.maint_report
        });
    }, [props.maint_report_task]);
    React.useEffect(() => {
        props.maint_report_task_detail?.items && setState({active: true});
    }, [props.maint_report_task_detail]);
    React.useEffect(() => {
        updateChart().catch();
    }, [state.maint_report_task]);
    React.useEffect(() => {
        if (state.active) {
            const newDate = moment().format('YYYY-MM');
            setState({month: newDate});
            props.getKPIData(newDate);
        }
    }, [state.active]);
    React.useEffect(() => {
        state.selectedStatus && getTaskStatusDetail();
    }, [state.selectedStatus]);
    const updateTask = (task) => {
        delete task.show;
        if (task.mechanic_id) {
            props.assignToMechanic(task);
        } else if (task.vendor_id) {
            props.assignToVendor(task);
        } else {
            props.updateTask(task);
        }
    };

        const {maint_report_task_detail, yardList, vendorList, mechanicList, PriorityList, task, unit} = props;
        return (
            <div className={'wo-status-container'}>
                <Card>
                    <CardHeader>
                        <Col sm={'12'}>
                            <Row>
                                <Col sm={'4'}/>
                                <Col sm={'4'}>
                                    <Row>
                                        <Col sm={6}>
                                            <Label className={'select-date-label'} style={{fontSize: '18', fontWeight: 'bold', paddingTop: '2px'}}>Select Date</Label>
                                        </Col>
                                        <Col sm={6}>
                                            <DatePicker onChange={handleDateChange}
                                                        dateFormatCalendar={'MMMM yyyy'}
                                                        dateFormat={'MMMM yyyy'}
                                                        selected={state.now}
                                                        onMonthChange={handleDateChange}
                                                        dropdownMode={'select'}
                                                        style={{paddingLeft: '0'}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={'4'}>
                                    <Button className={'show-all-btn'} outline color={'texts'} onClick={() => {showAllClick()}}>Show All</Button>
                                </Col>
                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <Col className={'wo-status-report-container'} sm={'12'}>
                            <div className={'wo-status-report'} style={{width: '100%', margin: '0 auto'}}  id="maint_report_task">loading</div>
                        </Col>
                    </CardBody>
                </Card>
                <div id={'taskStatTbl'}>
                    <BotTasks ref={tRef}
                              data={maint_report_task_detail.maint_report ? maint_report_task_detail.maint_report : null}
                              tblId={'tRef'}
                              popoutAction={true}
                              viewAction={true}
                              actionCount={2}
                              title={'Task Status Report'}
                              yardList={yardList}
                              vendorList={vendorList}
                              mechanicList={mechanicList}
                              priorityList={PriorityList}
                              pageSizes={['20', '50', '100', '500', '1000']}
                              remote={false}
                              unit={unit}
                              getTasks={() => props.getMaintReportTaskDetail()}
                              getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                              getMyUnit={(id) => props.getMyUnit(id)}
                              updateTask={(t) => updateTask(t)}
                              task={task}
                              u_preferences={props.user_preference}
                              csvFile={`${state.selectedYard && state.selectedYard}_${state.selectedStatus && state.selectedStatus}_tasks.csv`}
                              lStorage={'task_status_storage'}
                              getMyTask={(id) => props.getTask(id)}/>
                </div>

            </div>
        );
};
TaskStatus.propTypes = propTypes;
export default TaskStatus;
