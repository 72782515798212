import PropTypes from 'prop-types';

export const propTypes = {
    myWo: PropTypes.object.isRequired,
    getMechanics: PropTypes.func.isRequired,
    getVendors: PropTypes.func.isRequired,
    getWorkorderStatuses: PropTypes.func.isRequired,
    getUnitStatuses: PropTypes.func.isRequired,
    getTaskPriorities: PropTypes.func.isRequired,
    workorder_statusList: PropTypes.any,
    mechanicList: PropTypes.any,
    vendList: PropTypes.any,
    unit_statusList: PropTypes.any,
    yardList: PropTypes.any,
    updateWorkOrder: PropTypes.func,
    cancelUpdate: PropTypes.func
};
