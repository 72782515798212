import {dataPlateConstants} from '../constants';

export const data_plates = (state = {}, action) => {
    switch (action.type) {
        case dataPlateConstants.GET_ALL_DATA_PLATES_REQUEST:
            return {
                loading: true
            };
        case dataPlateConstants.GET_ALL_DATA_PLATES_SUCCESS:
            return {
                items: action.data_plates
            };
        case dataPlateConstants.GET_ALL_DATA_PLATES_FAILURE:
            return {
                error: action.error
            };
        case dataPlateConstants.CREATE_DATA_PLATE_REQUEST:
            return {
                loading: true
            };
        case dataPlateConstants.CREATE_DATA_PLATE_SUCCESS:
            return {
                items: action.data_plates
            };
        case dataPlateConstants.CREATE_DATA_PLATE_FAILURE:
            return {
                error: action.error
            };
        case dataPlateConstants.UPDATE_DATA_PLATE_REQUEST:
            return {
                loading: true
            };
        case dataPlateConstants.UPDATE_DATA_PLATE_SUCCESS:
            return {
                items: action.data_plates
            };
        case dataPlateConstants.UPDATE_DATA_PLATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const data_plate = (state = {}, action) => {
    switch (action.type) {
        case dataPlateConstants.DETAIL_DATA_PLATE_REQUEST:
            return {
                loading: true
            };
        case dataPlateConstants.DETAIL_DATA_PLATE_SUCCESS:
            return {
                items: action.data_plate
            };
        case dataPlateConstants.DETAIL_DATA_PLATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
