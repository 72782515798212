export const taskConstants = {
    CREATE_TASK_REQUEST: 'TASK_CREATE_REQUEST',
    CREATE_TASK_SUCCESS: 'TASK_CREATE_SUCCESS',
    CREATE_TASK_FAILURE: 'TASK_CREATE_FAILURE',

    UPDATE_TASK_REQUEST: 'TASK_UPDATE_REQUEST',
    UPDATE_TASK_SUCCESS: 'TASK_UPDATE_SUCCESS',
    UPDATE_TASK_FAILURE: 'TASK_UPDATE_FAILURE',

    GET_ALL_TASKS_REQUEST: 'TASKS_GET_ALL_REQUEST',
    GET_ALL_TASKS_SUCCESS: 'TASKS_GET_ALL_SUCCESS',
    GET_ALL_TASKS_FAILURE: 'TASKS_GET_ALL_FAILURE',

    GET_OPEN_TASKS_REQUEST: 'TASKS_GET_OPEN_REQUEST',
    GET_OPEN_TASKS_SUCCESS: 'TASKS_GET_OPEN_SUCCESS',
    GET_OPEN_TASKS_FAILURE: 'TASKS_GET_OPEN_FAILURE',

    DETAIL_TASK_REQUEST: 'TASK_DETAIL_REQUEST',
    DETAIL_TASK_SUCCESS: 'TASK_DETAIL_SUCCESS',
    DETAIL_TASK_FAILURE: 'TASK_DETAIL_FAILURE',
};
