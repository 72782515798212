import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import {
  CustomInput,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  SaveCancel
} from '../bot-components';

const PmAvailableCheck = ( { data, updateItem } ) => {
  const [modal, setModal] = useState( false );
  const [myData, setData] = useState( {
    id:data.id,
    name:data.name,
    photo:data.photo,
    photo_only:data.photo_only,
    photo_required:data.photo_required,
    photo_count:data.photo_count,
    details:data.details,
    details_only:data.details_only,
    details_required:data.details_required,
    deleted:data.deleted,
    static:data.static,
    included:data.included
  } );

  const [{ opacity }, drag] = useDrag( {
    canDrag:!myData.included,
    item:{
      ...data,
      isNew:true
    },
    type:'div',
    accept:'div',
    collect:monitor => ({
      opacity:monitor.isDragging() ? 0.4 : 1
    })
  } );
  const toggle = () => setModal( !modal );

  useEffect( () => {
    setData( {
      id:data.id,
      name:data.name,
      dot:data.dot,
      photo:data.photo,
      photo_only:data.photo_only,
      photo_required:data.photo_required,
      photo_count:data.photo_count,
      details:data.details,
      details_only:data.details_only,
      details_required:data.details_required,
      deleted:data.deleted,
      static:data.static,
      included:data.included
    } );

  }, [data] );

  const ref = useRef( null );
  const itemStyle = {
    border:'1px solid gray',
    padding:'0.5rem 1rem',
    backgroundColor:'white',
    cursor:'move'
  };

  const save = () => {
    setData( data );
    updateItem( myData );
    toggle();
  };

  const cancel = () => {
    setData( data );
    toggle();
  };

  drag( ref );
  return (
    <div className="availableCheck" ref={ ref } style={ { ...itemStyle, opacity } }>
      <Modal isOpen={ modal } toggle={ toggle }>
        <ModalHeader toggle={ toggle }>
          Edit { data.name }
        </ModalHeader>
        <ModalBody>
          <Label for={ 'inspectionCheck' }>PM Item: </Label>
          <Input name={ 'inspectionCheck' } type={ 'text' } value={ myData.name }
                 onChange={ ( e ) => setData( prevState => ({
                   ...prevState,
                   name:e.target.value
                 }) ) }/>
          <Row style={ { margin:'0.5rem' } }>
            <CustomInput id={ 'photo_checkbox' }
                         type={ 'checkbox' } color={ 'texts' }
                         label={ 'Photo' }
                         defaultChecked={ myData.photo }
                         onChange={ () => setData( prevState => ({
                           ...prevState,
                           photo:!prevState.photo,
                           photo_count:!prevState.photo ? 1 : 0
                         }) ) }/>
            { myData.photo && <CustomInput id={ 'photo_only_checkbox' }
                                           type={ 'checkbox' } color={ 'texts' }
                                           label={ 'Photo Only' }
                                           defaultChecked={ myData.photo_only }
                                           onChange={ () => setData( prevState => ({
                                             ...prevState,
                                             photo:!prevState.photo_only ? true : prevState.photo,
                                             photo_only:!prevState.photo_only
                                           }) ) }/> }
            { myData.photo && <CustomInput id={ 'photo_required_checkbox' }
                                           type={ 'checkbox' } color={ 'texts' }
                                           label={ 'Photo Required' }
                                           defaultChecked={ myData.photo_required }
                                           onChange={ () => setData( prevState => ({
                                             ...prevState,
                                             photo_required:!prevState.photo_required
                                           }) ) }/> }
            <CustomInput id={ 'details_checkbox' }
                         type={ 'checkbox' } color={ 'texts' }
                         label={ 'Details' }
                         defaultChecked={ myData.details }
                         onChange={ () => setData( prevState => ({
                           ...prevState,
                           details:!prevState.details,
                           details_required:!prevState.details ? prevState.details_required : false
                         }) ) }/>
            { myData.details && <CustomInput id={ 'details_required_checkbox' }
                                             type={ 'checkbox' } color={ 'texts' }
                                             label={ 'Details Required' }
                                             defaultChecked={ myData.details_required }
                                             onChange={ () => setData( prevState => ({
                                               ...prevState,
                                               details_required:!prevState.details_required
                                             }) ) }/> }
            { myData.details && <CustomInput id={ 'details_only_checkbox' }
                                             type={ 'checkbox' } color={ 'texts' }
                                             label={ 'Details Only' }
                                             defaultChecked={ myData.details_only }
                                             onChange={ () => setData( prevState => ({
                                               ...prevState,
                                               details_only:!prevState.details_only
                                             }) ) }/> }
          </Row>
          <Row style={ { margin:'0.5rem' } }>
            { myData.photo && (<>Photo count<Input type={ 'number' } min={ 1 } max={ 4 }
                                                   id={ `${ data.id }_photo_count` }
                                                   onChange={ ( e ) => {
                                                     let { value, min, max } = e.target;
                                                     value = Math.max( Number( min ), Math.min( Number( max ), Number( value ) ) );
                                                     setData( prevState => ({
                                                       ...prevState,
                                                       photo_count:value
                                                     }) );
                                                   } }
                                                   value={ myData.photo_count }/></>) }
          </Row>
        </ModalBody>
        <ModalFooter>
          <SaveCancel onSaveClick={ save } onCancelClick={ cancel }/>
        </ModalFooter>
      </Modal>
      <Row style={ { justifyContent:'space-between' } }>
        <text>
          <text style={ { paddingLeft:'1rem' } }>{ myData.name }</text>
          { myData.photo && <text style={ { paddingLeft:'1rem' } }>(photo)</text> }
          { myData.photo_only && <text style={ { paddingLeft:'1rem' } }>(photo only)</text> }
          { myData.photo_required && <text style={ { paddingLeft:'1rem' } }>(photo required)</text> }
          { myData.details && <text style={ { paddingLeft:'1rem' } }>(details)</text> }
          { myData.details_required && <text style={ { paddingLeft:'1rem' } }>(details required)</text> }
          { myData.static && <text style={ { paddingLeft:'1rem' } }>(static)</text> }
        </text>
        <i
          className={ 'fa fa-pencil' }
          onClick={ () => toggle() }
          style={ { cursor:'pointer', paddingRight:'1rem' } }/>
      </Row>
    </div>
  );
};
export default PmAvailableCheck;
