import {authHeader} from "./auth-header";
import {baseUrl} from "../network/constants";

const getReqHeaders = (method, headers, body) => ({method: method, headers: authHeader(headers), body: JSON.stringify(body)});

function checkUniqueUnitNum(text, id) {
    const requestOptions = getReqHeaders('PUT', 'PUT', {number: text, unit_id: id});
    return fetch(`${baseUrl}/unit/validate_number/`, requestOptions).then(response => response.json().then(data => data.valid));
}

function checkUniqueSerialNum(text, id) {
    const requestOptions = getReqHeaders('PUT', 'PUT', {vin_sn: text, unit_id: id});
    return fetch(`${baseUrl}/unit/validate_serial/`, requestOptions).then(response => response.json().then(data => data.valid));
}

function checkUniqueEmail(text, id) {
    const requestOptions = getReqHeaders('PUT', 'PUT', {email: text, user_id: id});
    return fetch(`${baseUrl}/user/validate_email/`, requestOptions).then(response => response.json().then(data => data.valid));
}

function checkUniqueEmpNum(text, id) {
    const requestOptions = getReqHeaders('PUT', 'PUT', {employee_number: text, user_id: id});
    return fetch(`${baseUrl}/user/validate_emp_num/`, requestOptions).then(response => response.json().then(data => data.valid));
}

function checkUniqueJobNum(text, id) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({number: text, id: id})
    };
    return fetch(`${baseUrl}/job/validate_num/`, requestOptions).then(response => {
        return response.json().then(data => {
            return data.valid;
        });
    });
}

export function validateUnitNum(text) {
    return {pass: text !== null && text !== '' && text !== undefined, exists: true};
}

export async function validateUniqueUnitNum(text, id, un) {
    return {pass: await text !== null && text !== '' && text !== undefined, exists: un ? await checkUniqueUnitNum(text, id) : true};
}

export async function validateUniqueSerialNum(text, id, un) {
    return {pass: await text !== null && text !== '' && text !== undefined, exists: un ? await checkUniqueSerialNum(text, id) : true};
}

export const validateNumber = (numText) => (numText !== '' && !isNaN(parseFloat(numText)) && numText.length > 0 && parseFloat(numText) > -1 && parseFloat(numText) < 99999999.99);

export function validateNullableNumber(numText) {
    return (numText.length > 0 && !isNaN(parseFloat(numText)) && parseFloat(numText) > -1 && parseFloat(numText) < 99999999.99);
}

export function validateUnitType(type) {
    return !!type && type !== '0';
}

export function validateUnitDescription(text) {
    return text !== null && text !== '' && text !== undefined
}

export function notEmptyText(text) {
    return text !== null && text !== '' && text !== undefined;
}

export async function validateUniqueEmail(text, id) {
    return checkUniqueEmail(text, id);
}

export async function validateUniqueEmpNum(text, id) {
    return checkUniqueEmpNum(text, id);
}

export async function validateUniqueJobNum(text, id) {
    return new Promise(resolve => {
        checkUniqueJobNum(text, id).then(done => {
            resolve(done);
        });
    });
}
