/*Handles all actions for the log model on the server.*/
import {logConstants} from "../constants/";
import {logService} from "../services/";
import {unitActions, workorderActions} from "./";

export const logActions = {};
/*Get full list of logs request.*/
logActions.getAll = (req) => {
    return dispatch => {
        dispatch(request());

        logService.getAll(req)
            .then(
                logs => {
                    dispatch(success(logs))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.GET_ALL_LOGS_REQUEST}
    }

    function success(logs) {
        return {type: logConstants.GET_ALL_LOGS_SUCCESS, logs}
    }

    function failure(error) {
        return {type: logConstants.GET_ALL_LOGS_FAILURE, error}
    }

    //</editor-fold>
};
/*Get log detail request, includes unit and workorder detail data.*/
logActions.getDetail = (id) => {
    return dispatch => {
        dispatch(request({id}));

        logService.getDetail(id)
            .then(
                log => {
                    dispatch(success(log));
                    if (log.unit_id) {
                        dispatch(unitActions.getUnit(log.unit_id));
                    }
                    if (log.workorder_id) {
                        dispatch(workorderActions.getDetail(log.workorder_id));
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.DETAIL_LOG_REQUEST}
    }

    function success(log) {
        return {type: logConstants.DETAIL_LOG_SUCCESS, log}
    }

    function failure(error) {
        return {type: logConstants.DETAIL_LOG_FAILURE, error}
    }

    //</editor-fold>
};
/*Get log detail request, includes unit and workorder detail data.*/
logActions.getNext = (id) => {
    return dispatch => {
        dispatch(request({id}));

        logService.getNext(id)
            .then(
                log => {
                    dispatch(success(log));
                    if (log.unit_id) {
                        dispatch(unitActions.getUnit(log.unit_id));
                    }
                    if (log.workorder_id) {
                        dispatch(workorderActions.getDetail(log.workorder_id));
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.DETAIL_LOG_REQUEST}
    }

    function success(log) {
        return {type: logConstants.DETAIL_LOG_SUCCESS, log}
    }

    function failure(error) {
        return {type: logConstants.DETAIL_LOG_FAILURE, error}
    }

    //</editor-fold>
};
/*Get log detail request, includes unit and workorder detail data.*/
logActions.getPrevious = (id) => {
    return dispatch => {
        dispatch(request({id}));

        logService.getPrevious(id)
            .then(
                log => {
                    dispatch(success(log));
                    if (log.unit_id) {
                        dispatch(unitActions.getUnit(log.unit_id));
                    }
                    if (log.workorder_id) {
                        dispatch(workorderActions.getDetail(log.workorder_id));
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.DETAIL_LOG_REQUEST}
    }

    function success(log) {
        return {type: logConstants.DETAIL_LOG_SUCCESS, log}
    }

    function failure(error) {
        return {type: logConstants.DETAIL_LOG_FAILURE, error}
    }

    //</editor-fold>
};
/*Create new log request.*/
logActions.createLog = (log) => {
    return dispatch => {
        dispatch(request({log}));
        //TODO Need to implement Socket.io to update ui after log creation.
        logService.createLog(log)
            .then(
                log => {
                    dispatch(success(log))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.CREATE_LOG_REQUEST}
    }

    function success(log) {
        return {type: logConstants.CREATE_LOG_SUCCESS, log}
    }

    function failure(error) {
        return {type: logConstants.CREATE_LOG_FAILURE, error}
    }

    //</editor-fold>
};

/*Create new inspection request.*/
logActions.createLog = (log) => {
    return dispatch => {
        dispatch(request({log}));
        //TODO Need to implement Socket.io to update ui after log creation.
        logService.createInspection(log)
            .then(
                log => {
                    dispatch(success(log))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: logConstants.CREATE_LOG_REQUEST}
    }

    function success(log) {
        return {type: logConstants.CREATE_LOG_SUCCESS, log}
    }

    function failure(error) {
        return {type: logConstants.CREATE_LOG_FAILURE, error}
    }

    //</editor-fold>
};

logActions.getChecks = (unit_type) => {
    return dispatch => {
        dispatch(request());
        logService.getChecks(unit_type)
            .then(
                inspection_checks => {
                    dispatch(success(inspection_checks));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: logConstants.GET_ALL_CHECKS_REQUEST}
    }

    function success(inspection_checks) {
        return {type: logConstants.GET_ALL_CHECKS_SUCCESS, inspection_checks}
    }

    function failure(error) {
        return {type: logConstants.GET_ALL_CHECKS_FAILURE, error}
    }
};
