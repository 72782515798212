import {vendorConstants} from '../constants';

export const vendors = (state = {}, action) => {
    switch (action.type) {
        case vendorConstants.GET_ALL_VENDORS_REQUEST:
            return {
                loading: true
            };
        case vendorConstants.GET_ALL_VENDORS_SUCCESS:
            return {
                items: action.vendors
            };
        case vendorConstants.GET_ALL_VENDORS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const vendor = (state = {}, action) => {
    switch (action.type) {
        case vendorConstants.CREATE_VENDOR_REQUEST:
            return {
                loading: true
            };
        case vendorConstants.CREATE_VENDOR_SUCCESS:
            return {
                items: action.vendor
            };
        case vendorConstants.CREATE_VENDOR_FAILURE:
            return {
                error: action.error
            };
        case vendorConstants.DETAIL_VENDOR_REQUEST:
            return {
                loading: true
            };
        case vendorConstants.DETAIL_VENDOR_SUCCESS:
            return {
                items: action.vendor
            };
        case vendorConstants.DETAIL_VENDOR_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
