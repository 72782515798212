import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, deprecated, tagPropType } from './utils';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl'];
const stringOrNumberProp = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const columnProps = PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
        size: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
        push: deprecated(stringOrNumberProp, 'Please use the prop "order"'),
        pull: deprecated(stringOrNumberProp, 'Please use the prop "order"'),
        order: stringOrNumberProp,
        offset: stringOrNumberProp
    }),
    PropTypes.node
]);

const propTypes = {
    tag: tagPropType,
    xs: columnProps,
    sm: columnProps,
    md: columnProps,
    lg: columnProps,
    xl: columnProps,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    widths: PropTypes.array,
    innerRef: PropTypes.node
};

const defaultProps = {
    tag: 'div',
    widths: colWidths,
};

const getColumnSizeClass = (isXs, colWidth, colSize) => {
    if (colSize === true || colSize === '') {
        return isXs ? 'col' : `col-${colWidth}`;
    } else if (colSize === 'auto') {
        return isXs ? 'col-auto' : `col-${colWidth}-auto`;
    }

    return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
};

const Col = ({ className, cssModule, widths, innerRef, tag: Tag, ...attributes }) => {
    const colClasses = [];

    widths.forEach((colWidth, i) => {
        let columnProp = attributes[colWidth];

        delete attributes[colWidth];

        if (!columnProp && columnProp !== '') {
            return;
        }

        const isXs = !i;

        if (_.isObject(columnProp)) {
            const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;
            const colClass = getColumnSizeClass(isXs, colWidth, columnProp.size);

            colClasses.push(mapToCssModules(classNames({
                [colClass]: columnProp.size || columnProp.size === '',
                [`order${colSizeInterfix}${columnProp.order}`]: columnProp.order || columnProp.order === 0,
                [`offset${colSizeInterfix}${columnProp.offset}`]: columnProp.offset || columnProp.offset === 0
            }), cssModule));
        } else {
            const colClass = getColumnSizeClass(isXs, colWidth, columnProp);
            colClasses.push(colClass);
        }
    });

    if (!colClasses.length) {
        colClasses.push('col');
    }

    const classes = mapToCssModules(classNames(
        className,
        colClasses
    ), cssModule);

    return (
        <Tag {...attributes} ref={innerRef} className={classes} />
    );
};

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default Col;
