import { authHeader, store } from '../../helpers';
import { baseUrl } from '../../network/constants';
import { attributeActions, unitSubTypeActions, unitTypeActions, userActions } from '../../network/actions';

const mapStateToProps = ( state ) => {
  let { attributes, unit_subtypes, unit_type, unit_subtype } = state;
  let attributeList = [];
  let classList = [];

  if (unit_subtypes.items) {
    unit_subtypes.items.map( unit_subtype => {
      let subtype, utype, subtype_id;
      subtype = unit_subtype.subtype;
      subtype_id = unit_subtype.id;
      if (unit_subtype.unit_type) {
        utype = unit_subtype.unit_type;
      } else {
        utype = 'UNASSIGNED';
      }
      return classList.push( { label:`${ subtype } (${ utype })`, value:subtype_id } );
    } );
  }

  if (attributes.items) {
    attributes.items.map( attribute => {
      return attributeList.push( attribute );
    } );
  }
  const loading = unit_type?.loading || unit_subtype?.loading || unit_subtypes.loading || attributes.loading;

  const _unit_type = unit_type?.items;
  const _unit_subtype = unit_subtype?.items;

  return { classList, attributeList, unit_type:_unit_type, unit_subtype:_unit_subtype, loading };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    updateAttribute:att => dispatch( attributeActions.updateAttribute( att ) ),
    getAttributes:() => dispatch( attributeActions.getAll() ),
    getUnitSubTypes:() => dispatch( unitSubTypeActions.getAll() ),
    getUnitType:( id ) => dispatch( unitTypeActions.getType( id ) ),
    createUnitSubType:unit_subtype => dispatch( unitSubTypeActions.createSubType( unit_subtype ) ),
    updateUnitSubType:ust => dispatch( unitSubTypeActions.updateSubType( ust ) ),
    getUnitSubtype:( id ) => dispatch( unitSubTypeActions.getSubType( id ) ),
  };
};

const getUnitTypes = () => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/unit_types` );
  return sendRequest( url, options );
};

const getUnitSubtypes = ( { unit_type_id } ) => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/unit_subtypes` );
  return sendRequest( url, options ).then( ust => {
    return unit_type_id ? ust.filter( u => u.unit_type_id === unit_type_id ) : ust;
  } );
};

const updateUnitSubType = ( { checks, unit_subtype, unit_type } ) => {
  const saveType = {
    checks:checks,
  };
  if (unit_subtype) {
    saveType.id = unit_subtype.id;
    saveType.subtype = unit_subtype.subtype;
    saveType.unit_type_id = unit_subtype.unit_type_id
  } else if (unit_type) {
    saveType.id = unit_type.id;
    saveType.utype = unit_type.utype;
  }
    const options = {
    method:'PUT',
    headers:authHeader( 'PUT' ),
    body:JSON.stringify( saveType )
  };
  const url = new URL( `${ baseUrl }/${unit_subtype ? 'unit_subtype' : 'unit_type'}/${ unit_subtype ? unit_subtype.id : unit_type.id }` );
  return sendRequest( url, options );
};

const createClassInspectionCheck = ( req ) => {
  const options = {
    method:'POST',
    headers:authHeader( 'POST' ),
    body:JSON.stringify( req )
  };
  const url = new URL( `${ baseUrl }/inspection_check` );

  return sendRequest( url, options );
};

const sendRequest = ( url, options ) => fetch( url, options ).then( handleResponse );

const handleResponse = ( response ) => new Promise( ( resolve, reject ) => {
  response.status !== 404 ? response.json().then( data => {
    if (!response.ok) {
      reject();
    }
    const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
    if (Auth) {
      store.dispatch( userActions.updateAuth( Auth, null ) );
    }
    resolve( data );
  } ) : reject();
} );

export {
  getUnitTypes,
  createClassInspectionCheck,
  updateUnitSubType,
  mapDispatchToProps,
  mapStateToProps,
  getUnitSubtypes
};