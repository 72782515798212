import React from 'react';
import PropTypes from 'prop-types';
import {Button, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from '../../components/bot-components';
import {Modal, ModalBody} from '../../components/bot-components';
const propTypes = {
    ref: PropTypes.node,
    row: PropTypes.object,
    cell: PropTypes.any,
    priorityList: PropTypes.any,
    onUpdate: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func
};

const PriorityEditor = React.forwardRef((props, ref) => {
    const {row, toggle, onUpdate, isOpen, priorityList} = props;
    const [state, updateState] = React.useState({
        id: row.id,
        task_priority_id: row.task_priority_id,
        selectedPriority: row.task_priority_id ? {value: row.task_priority_id, label: row.task_priority} : null
    });

    const setState = (newState) => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));

    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        state: state,
        setState: (newState) => setState(newState)
    }));

    const inputRef = React.useRef();

    const focusInput = () => inputRef.current?.focus();

    const updateData = () => {
        toggle();
        onUpdate(state);
    }
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');
    return (
        <Modal toggle={() => toggle()} centered={true} isOpen={isOpen} onOpened={() => focusInput()}>
            <ModalBody>
                <InputGroup>
                    <Input type={'react-select'}
                           options={priorityList}
                           ref={inputRef}
                           placeholder={'Select Priority'}
                           matchProp={'label'}
                           value={state.selectedPriority}
                           onChange={(selectedPriority) => setState({selectedPriority: selectedPriority, task_priority_id: selectedPriority ? selectedPriority.value : null})}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
                </InputGroup>
            </ModalBody>
        </Modal>
    );
});

PriorityEditor.propTypes = propTypes;
export default PriorityEditor;
