import Dashboard from './js/views/Dashboard/Dashboard';
import BotSettings from './js/views/Settings/BotSettings';
import WorkorderTable from './js/views/WorkOrders/WorkOrders';
import DashboardTasks from './js/views/Tasks/DashboardTasks/DashboardTasks';
import Inspections from './js/views/Inspections/Inspections';
import Yards from './js/views/Yards/Yards';
import AllUnits from './js/views/Units/all_units';
import BotUnit from './js/views/Units/BotUnit/BotUnit';
import InspectionCheckList from './js/views/InspectionCheckList/InspectionCheckList';
import AllAlerts from './js/views/PlmAlerts/AllAlerts';
import MaintenanceStatusReports from './js/views/WorkOrders/MaintenanceStatusReports';
import Vendors from './js/views/Vendors/Vendors';
import Users from './js/views/UserManagement/BotUsers/Users';
import WorkOrderDetail from './js/views/WorkOrders/WorkOrderDetail/WorkOrderDetail';
import UnitTypes from './js/views/UnitTypes/UnitTypes';
import BotTireSizes from './js/views/TireSizes/BotTireSizes';
import BotAssetTypes from './js/views/AssetTypes/BotAssetTypes';
import AllHistory from './js/views/XPI-History/AllHistory';
import { UserIntegration } from './js/views/UserIntegration/';
import FaultTable from './js/views/Faults/faults';
import FaultDetail from './js/views/Faults/fault';
import Reports from './js/views/Reports/Reports';
import BotPms from './js/views/PmChecklists/BotPms';

const workorderRoutes = ( mods ) => mods?.includes( 'workorders' ) ? [
  { path:'/workorders/lists', exact:true, name:'Work Orders', component:WorkorderTable },
  { path:'/workorders/tasks', exact:true, name:'Tasks', component:DashboardTasks },
  { path:'/workorders/detail', exact:true, name:'Work Order Detail', component:WorkOrderDetail },
  { path:'/workorders/status', exact:true, name:'Maintenance Status Report', component:MaintenanceStatusReports },
  { path:'/reports', exact:true, name:'Reports', component:Reports },
] : [];

const inspectionRoutes = ( mods ) => mods?.includes( 'inspections' ) ? [
  { path:'/inspections', exact:true, name:'Inspections', component:Inspections },
  { path:'/equipment/inspections', exact:true, name:'Equipment Inspections', component:InspectionCheckList },
  { path:'/reports', exact:true, name:'Reports', component:Reports },
] : [];

const xpiRoutes = ( mods ) => mods?.includes( 'blueocean_xpi_integrations' ) ? [
  { path:'/blueocean_xpi_integrations/history', exact:true, name:'BlueOceanXPI History', component:AllHistory },
  ...vistaUserRoutes( mods ),
  ...plmRoutes( mods )
] : [];

const vistaUserRoutes = ( mods ) => mods?.includes( 'vista-users' ) ? [
  {
    path:'/blueocean_xpi_integrations/user_integration',
    exact:true,
    name:'BlueOceanXPI Integrations',
    component:UserIntegration
  },
] : [];

const plmRoutes = ( mods ) => mods?.includes( 'plm' ) ? [
  { path:'/blueocean_xpi_integrations/plm/plm_alerts', exact:true, name:'PLM Alerts', component:AllAlerts },
  { path:'/blueocean_xpi_integrations/plm/faults', exact:true, name:'Fault Codes', component:FaultTable },
  { path:'/blueocean_xpi_integrations/plm/faults/detail', exact:true, name:'Fault Detail', component:FaultDetail },
] : [];

const pmRoutes = ( mods ) => mods?.includes( 'preventative_maintenance' ) ? [
  { path:'/equipment/preventative_maintenance', exact:true, name:`Preventative Maintenance`, component:BotPms },
] : [];

const routes = ( mods ) => [
  { path:'/dashboard', exact:true, name:'Dashboard', component:Dashboard },
  { path:'/configurations', exact:true, name:'Configurations', component:BotSettings },
  { path:'/equipment/', exact:true, name:'Equipment', component:AllUnits },
  { path:'/equipment/detail', exact:true, name:'Equipment Detail', component:BotUnit },
  { path:'/setup/service_providers', exact:true, name:'Service Provider Management', component:Vendors },
  { path:'/setup/users', exact:true, name:'User Management', component:Users },
  { path:'/equipment/equipment_types', exact:true, name:'Equipment Types', component:UnitTypes },
  { path:'/equipment/tire_sizes', exact:true, name:'Tire Sizes', component:BotTireSizes },
  { path:'/equipment/asset_types', exact:true, name:'Asset Types', component:BotAssetTypes },
  { path:'/setup/job_sites', exact:true, name:'Locations', component:Yards },
  ...workorderRoutes( mods ),
  ...inspectionRoutes( mods ),
  ...xpiRoutes( mods ),
  ...pmRoutes( mods )
];

export default routes;
