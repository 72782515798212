/* eslint-disable react-hooks/exhaustive-deps */
import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
// import {validateUniqueJobNum} from '../../helpers';
import {BotTable, DropdownItem} from '../../components/bot-components';
import CreateYard from './CreateYard';
import {GenericSelectEditor} from '../Editors';
import TableActions from '../../containers/table_action_component';

let propTypes = {
    title: PropTypes.string,
    yards: PropTypes.array,
    selectAction: PropTypes.bool,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    canEdit: PropTypes.bool,
    canCreate: PropTypes.bool,
    canDelete: PropTypes.bool,
    getYards: PropTypes.func,
    getYard: PropTypes.func,
    jobStatusList: PropTypes.array,
    managerList: PropTypes.array,
    updateYard: PropTypes.func,
    createYard: PropTypes.func,
    deleteYards: PropTypes.func,
    yard: PropTypes.object,
    job: PropTypes.object,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    job_statuses: PropTypes.array
};
let columns = [];
const defaultFilters = {
    name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone_number: '',
    deleted: ''
};
let myFilters = {...defaultFilters};
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 5,
    currPage: 1,
    filters: {...myFilters}
};
let table_key_iterator = 0;

const YardTable = forwardRef(
    /**
     *
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.editAction
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.selectAction
     * @param props.alertAction
     * @param props.getYards
     * @param props.getYard
     * @param props.selectedYard
     * @param props.createYard
     * @param props.deleteYard
     * @param props.updateYard
     * @param props.canSelect
     * @param props.canDelete
     * @param props.data
     * @param props.canCreate
     * @param props.job_statuses
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param props.createYard
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        //<editor-fold desc="Column Refs">
        const btnRef = React.useRef();
        const nameRef = React.useRef();
        const addressRef = React.useRef();
        const cityRef = React.useRef();
        const stateRef = React.useRef();
        const zipRef = React.useRef();
        const phoneRef = React.useRef();
        const deletedRef = React.createRef();
        //</editor-fold>

        const [state, setState] = useState({
            loaded: false,
            ...defaultState,
            jobs: null,
            yardModal: false,
            editorOpen: false
        });
        const [editor, toggleEditor] = useState(false);
        const [editRow, setRow] = useState(null);

        const updateState = (newState) => {
            setState({
                ...state,
                ...newState
            });
        };

        const setSelectedRows = (row, e) => {
            console.log(row);
            console.log(ref);
            const {checked} = e.target;
            row.selected = checked;
            ref?.current?.setSelectedRows(row, e);
        };

        const table_keys = () => `${state.tblId}_${table_key_iterator++}`;

        const editButton = (cell, row) => {
            return(
                <TableActions ref={btnRef} key={table_keys()} row={row} edit={props.editAction} view={props.viewAction}
                              popout={props.popoutAction} alert={props.alertAction} select={props.selectAction}
                              onPopout={() => {props.getYard(row.id)}}
                              onSelect={(row, e) => ref?.current?.setSelectedRows(row, e)}
                />
            )
        };

        const refreshYards = () => {
            const tbl = ref?.current;
            const req = {
                sortName: tbl?.state.sortName,
                sortOrder: tbl?.state.sortOrder,
                currentPage: tbl?.state.currPage,
                sizePerPage: tbl?.state.currSize,
                filters: tbl?.state.filters
            };
            props.remote ? props.getYards(req) : props.getYards();
        };

        const createNewBtn = () => {
            return (
                <DropdownItem outline color={'texts'} onClick={() => {
                    toggleYardModal();
                }}><i className={'fa fa-plus'}/> New</DropdownItem>
            );
        };

        const deleteYards = (ids) => {
            Promise.all(ids.map(i => props.deleteYard(i))).then(() => props.getYards());
        };

        const toggleYardModal = () => {
            updateState({
                yardModal: !state.yardModal
            });
        };

        const updateYard = (yard) => {
            props.updateYard(yard);
            setTimeout(() => props.getYards(), 500);
        };

        const {title} = props;
        const {yardModal} = state;
        const filters = defaultFilters;
        columns = [
            props.canDelete && {
                field: 'button',
                text: 'Views',
                width: 1.5 * 40,
                hidden: false,
                format: (cell, row) => editButton(cell, row),
                sortable: false
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: filters.name
                },
                width: 180,
                hidden: false,
                refProp: nameRef,
                sortable: true
            },
            {
                field: 'address',
                text: 'Address',
                filter: {
                    type: 'text',
                    placeholder: 'Address',
                    defaultValue: filters.address
                },
                width: 200,
                hidden: false,
                refProp: addressRef,
                sortable: true
            },
            {
                field: 'city',
                text: 'City',
                filter: {
                    type: 'text',
                    placeholder: 'City',
                    defaultValue: filters.city
                },
                width: 150,
                hidden: false,
                refProp: cityRef,
                sortable: true
            },
            {
                field: 'state',
                text: 'State',
                filter: {
                    type: 'text',
                    placeholder: 'State',
                    defaultValue: filters.state
                },
                width: 150,
                hidden: false,
                refProp: stateRef,
                sortable: true
            },
            {
                field: 'zip_code',
                text: 'Zip',
                filter: {
                    type: 'text',
                    placeholder: 'Zip',
                    defaultValue: filters.zip_code
                },
                width: 150,
                hidden: false,
                refProp: zipRef,
                sortable: true
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: filters.phone_number
                },
                width: 150,
                hidden: false,
                refProp: phoneRef,
                sortable: true
            },
            {
                field: 'deleted',
                text: 'Deleted',
                filter: {
                    type: 'select',
                    placeholder: 'Deleted',
                    defaultValue: filters.deleted,
                    options: [{label: 'Yes', value: true}, {label: 'No', value: false}]
                },
                format: cell => cell === true ? 'Yes' : 'No',
                width: 150,
                hidden: false,
                refProp: deletedRef,
                editable: {
                    value: true,
                    onClick: (row) => {
                        setRow(editor ? null : row);
                        toggleEditor(!editor);
                    }
                },
                sortable: true
            }
        ] ;

        const filterActiveOnly = () => {
            const filter = deletedRef.current;
            const currValue = filter?.state.value;
            return (currValue === 1 || currValue === true) ? filter?.setFilter("") : filter?.setFilter(true);
        };
        const FilterDeletedBtn = () => (<DropdownItem outline color={'texts'} className={ref?.current?.state?.filters?.deleted === true ? 'active' : ''} key={table_keys()} onClick={() => {filterActiveOnly()}}>Deleted Only</DropdownItem>);


        return (
            <>
                <CreateYard isOpen={yardModal} createYard={props.createYard} toggle={toggleYardModal}/>
                <GenericSelectEditor row={editRow} cell={'deleted'} extraProps={[{label: 'Yes', value: true}, {label: 'No', value: false}]} isOpen={editor} toggle={toggleEditor} onUpdate={(ops) => updateYard({...editRow, ...ops})}/>
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          header={true}
                          data={props.data}
                          getData={(req) => props.getYards(req)}
                          pageSizes={props.pageSizes}
                          ClearFiltersBtn={true}
                          ClearFiltersIcon={true}
                          ClearSortBtn={true}
                          ClearSortIcon={true}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          pagination={'both'}
                          headers={true}
                          expandable={false}
                          doRowUpdate={(yard) => updateYard(yard)}
                          refreshData={() => refreshYards()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={defaultFilters}
                          defaultState={defaultState}
                          CreateNewBtn={props.canCreate && createNewBtn}
                          onlyFiltersIcons={[]}
                          canSelect={props.canDelete}
                          setSelectedRows={(row, e) => setSelectedRows(row, e)}
                          deleteRows={(rows) => deleteYards(rows)}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                          loading={props.loading}
                          showTitle={props.showTitle}
                          lStorage={props.lStorage}
                          onRowDoubleClick={(row) => props.getYard(row.id)}
                          onlyFilters={props.canDelete && [FilterDeletedBtn]}
                />
            </>
        );
    });

YardTable.propTypes = propTypes;
export default YardTable;
