import React from "react";
import PropTypes from 'prop-types';
import {BotTable, DropdownItem} from '../../../components/bot-components';
import CreateDataPlate from './CreateDataPlate';
import {GenericTextEditor} from '../../Editors';
import * as _ from 'lodash';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    columns: PropTypes.array,
    getDataPlates: PropTypes.func,
    updateDataPlate: PropTypes.func,
    createDataPlate: PropTypes.func,
    data_plate: PropTypes.object,
    canCreate: PropTypes.bool,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any
};
let columns = [];
const defaultFilters = {
    description: '',
    number: ''
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const DataPlates = React.forwardRef(
    /**
     * @param props
     * @param props.remote
     * @param props.getDataPlates
     * @param props.updateDataPlate
     * @param props.columns
     * @param props.createDataPlate
     * @param props.canCreate
     * @param props.lStorage
     * @param props.showTitle
     * @param props.loading
     * @param props.csvUrl
     * @param props.csvFile
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const [state, updateState] = React.useState({
        ...defaultState,
        newModal: false,
        numberEditor: false,
        descriptionEditor: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    //<editor-fold desc="Column Refs">
    const descriptionRef = React.useRef();
    const numberRef = React.useRef();
    //</editor-fold>
    const dRef = React.useRef();
    const nRef = React.useRef();

    const editNumber = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell,
            numberEditor: true
        });
    };

    const editDescription = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell,
            descriptionEditor: true
        });
    };

    const toggleNewModal = () => setState({newModal: !state.newModal});

    const refreshDataPlates = () => {
        const tbl = ref?.current?.state;
        const req = {
            sortName: tbl?.sortName,
            sortOrder: tbl?.sortOrder,
            currentPage: tbl?.currPage,
            sizePerPage: tbl?.currSize,
            filters: tbl?.filters
        };
        props.remote ? props.getDataPlates?.(req) : props.getDataPlates?.();
    };

    const createNewBtn = () => {
        return(
            <DropdownItem outline color={'texts'} onClick={() => {
                toggleNewModal();
            }}><i className={'fa fa-plus'}/> New</DropdownItem>
        )
    };

    const updateDataPlate = (dp) => {
        props.updateDataPlate(dp);
        setState({
            selectedRow: null,
            selectedCell: null
        });
    };

    let {pageSizes, selectedRow, selectedCell} = state;
    let {title, data, unit} = props;
    const filters = defaultFilters;
    columns = props.columns ? props.columns : [
        {
            field: 'description',
            text: 'Description',
            width: 1200,
            filter: {
                type: 'text',
                placeholder: 'Enter Description',
                defaultValue: filters.description
            },
            hidden: false,
            sortable: true,
            editable: {
                value: true,
                onClick: (row, cell) => editDescription(row, cell)
            },
            refProp: descriptionRef
        },
        {
            field: 'number',
            text: 'Serial Number',
            width: 400,
            filter: {
                type: 'text',
                defaultValue: filters.number,
                placeholder: 'Enter Serial Number'
            },
            hidden: false,
            sortable: true,
            editable: {
                value: true,
                onClick: (row, cell) => editNumber(row, cell)
            },
            refProp: numberRef
        }
    ];
    return(
        <div>
            {selectedRow && <GenericTextEditor ref={nRef} onUpdate={(dp) => updateDataPlate(dp)} row={selectedRow} cell={selectedCell}/>}
            {selectedRow && <GenericTextEditor ref={dRef} onUpdate={(dp) => updateDataPlate(dp)} row={selectedRow} cell={selectedCell}/>}
            {unit.items &&
            <CreateDataPlate unit={unit} toggle={() => toggleNewModal()} isOpen={state.newModal} onSaveDataPlate={(dp) => props.createDataPlate(dp)}/>}
            <BotTable ref={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      getData={(req) => props.getDataPlates?.(req)}
                      pageSizes={pageSizes}
                      ClearFiltersBtn
                      ClearFiltersIcon
                      ClearSortBtn
                      ClearSortIcon
                      CsvDownloadBtn
                      CsvDownloadIcon
                      CreateNewBtn={props.canCreate && createNewBtn}
                      pagination={'both'}
                      headers={true}
                      doRowUpdate={(dp) => updateDataPlate(dp)}
                      refreshData={() => refreshDataPlates()}
                      btnGroupDirection={'left'}
                      remote={props.remote}
                      defaultFilters={filters}
                      defaultState={defaultState}
                      lStorage={props.lStorage}
                      showTitle={props.showTitle}
                      loading={props.loading}
                      csvFile={props.csvFile}
                      csvUrl={props.csvUrl}/>
        </div>
    );
});

DataPlates.propTypes = propTypes;
export default DataPlates;
