import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    InputGroup, Input, InputGroupAddon, UncontrolledTooltip
} from '../../components/bot-components';
import PropTypes from 'prop-types';
const propTypes = {
    row: PropTypes.object,
    cell: PropTypes.object,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    extraProps: PropTypes.array,
    onUpdate: PropTypes.func
};
const GenericSelectEditor = (props) => {
    const {cell, name, row, isOpen, toggle, extraProps} = props;
    const [state, updateState] = React.useState({
        id: null,
        show: false,
        selectedValue: null
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const inputRef = React.useRef();
    React.useEffect(() => {
        row && setState({
            id: row.id,
            [cell]: row[cell],
            selectedValue: {label: row[name], value: row[cell]}
        });
    }, [row, cell, name]);
    const focusInput = () => {
        inputRef?.current?.focus();
    };
    const handleChange = (e) => {
        const {value} = e;
        setState({
            [cell]: value,
            selectedValue: e
        });
    };
    const updateData = () => {
        toggle();
        props.onUpdate({
            [cell]: state[cell]
        });
    };
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');
    return (
        <Modal toggle={toggle} centered={true} isOpen={isOpen} onOpened={focusInput}>
            <ModalBody>
                <InputGroup>
                    <Input type={'react-select'} options={extraProps}
                           ref={inputRef}
                           placeholder={'Select an option.'}
                           matchProp={'label'}
                           value={state.selectedValue}
                           onChange={(e) => handleChange(e)}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                </InputGroup>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
            </ModalBody>
        </Modal>
    );
};
GenericSelectEditor.propTypes = propTypes;
export default GenericSelectEditor;
