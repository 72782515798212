/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Col, Row, Label, Input, Button} from '../../components/bot-components';
import {userActions} from "../../network/actions";
import {authHeader, store} from "../../helpers";
import {baseUrl} from "../../network/constants";
import ReduxBlockUi from 'react-block-ui';
const UserFields = () => {
    const [state, updateState] = React.useState({
        loading: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const getExtraFields = () => {
        setState({
            loading: true,
            getExtraFields: null
        });

        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };
        return fetch(`${baseUrl}/users/list_fields/`, requestOptions).then(response => {
            return response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    setState({
                        loading: false
                    });
                    return  alert('There was an error loading the data, please try again...');
                }
                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    // noinspection JSCheckFunctionSignatures
                    await store.dispatch(userActions.updateAuth(Auth, null));
                }
                if (data) {
                    const tmp = {};
                    await data.map(f => {
                        tmp[`${f.field}_alias`] = f.alias;
                        tmp[`${f.field}_table_header`] = f['table_header'];
                        return null;
                    });
                    return setState({
                        loading: false,
                        extraFields: data,
                        ...tmp
                    });
                } else return setState({
                    loading: false
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };
    React.useEffect(() => {
        getExtraFields().catch();
    }, []);
    const updateField = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };
    const saveField = (key, field) => {
        setState({
            loading: true
        });
        const Field = {
            id: key,
            alias: state[`${field}_alias`],
            table_header: state[`${field}_table_header`]
        };
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify({
                ...Field
            })
        };

        return fetch(`${baseUrl}/users/alias_update/`, requestOptions).then(response => {
            return response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    setState({
                        loading: false
                    });
                    return alert('There was an error saving the data, please try again...');
                }
                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    // noinspection JSCheckFunctionSignatures
                    await store.dispatch(userActions.updateAuth(Auth, null));
                }
                if (data) {
                    setState({
                        loading: false
                    });
                    return getExtraFields();
                } else return setState({
                    loading: false
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };
    const resetField = (field) => {
        const Field = state.extraFields.filter(f => f.field === field)[0];
        setState({
            [`${Field.field}_alias`]: Field.alias,
            [`${Field.field}_table_header`]: Field.table_header
        });
    };
    const {loading} = state;
    return(
        <ReduxBlockUi blocking={loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
            {state.extraFields?.map?.((f, index) => {
                return(
                    <Col sm={12} style={{borderTop: index === 0 && '1px solid gray'}}>
                        <Row key={f.id}  style={{borderBottom: '1px solid gray', borderRight: '1px solid gray', borderLeft: '1px solid gray'}}>
                            <Col sm={2} style={{borderRight: '1px solid gray'}}>
                                <Label style={{float: 'right', marginTop: '.5rem'}}>{f.field}</Label>
                            </Col>
                            <Col sm={4} style={{borderRight: '1px solid gray'}}>
                                <Row>
                                    <Col sm={3}>
                                        <Label style={{float: 'right', marginTop: '.5rem'}}>Name</Label>
                                    </Col>
                                    <Col sm={9}>
                                        <Input type={'text'}
                                               name={`${f.field}_alias`}
                                               onChange={(e) => updateField(e)}
                                               value={state[`${f.field}_alias`]}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={4} style={{borderRight: '1px solid gray'}}>
                                <Row>
                                    <Col sm={3}>
                                        <Label style={{float: 'right', marginTop: '.5rem'}}>Header</Label>
                                    </Col>
                                    <Col sm={9}>
                                        <Input type={'text'}
                                               name={`${f.field}_table_header`}
                                               onChange={(e) => updateField(e)}
                                               invalid={state[`${f.field}_table_header`] === f.field}
                                               value={state[`${f.field}_table_header`]}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2}>
                                <Row>
                                    <Col sm={6}>
                                        <Button outline color={'texts'} block={true} onClick={() => saveField(f.id, f.field)}>Save</Button>
                                    </Col>
                                    <Col sm={6}>
                                        <Button outline color={'texts'} block={true} onClick={() => resetField(f.field)}>Reset</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                )
            })}
        </ReduxBlockUi>
    );
};
const mapStateToProps = (state) => {
    const {authentication, pag_users, user_preference} = state;
    const user = authentication.user;
    return {user, pag_users, user_preference};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (req) => dispatch(userActions.getPag(req)),
        getUser: (id) => dispatch(userActions.getDetail(id)),
        importUsers: (file) => dispatch(userActions.importUsers(file)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFields);
