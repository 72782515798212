export const unitSubTypeConstants = {
    CREATE_UNIT_SUBTYPE_REQUEST: 'UNIT_SUBTYPE_CREATE_REQUEST',
    CREATE_UNIT_SUBTYPE_SUCCESS: 'UNIT_SUBTYPE_CREATE_SUCCESS',
    CREATE_UNIT_SUBTYPE_FAILURE: 'UNIT_SUBTYPE_CREATE_FAILURE',

    GET_ALL_UNIT_SUBTYPES_REQUEST: 'UNIT_SUBTYPES_GET_ALL_REQUEST',
    GET_ALL_UNIT_SUBTYPES_SUCCESS: 'UNIT_SUBTYPES_GET_ALL_SUCCESS',
    GET_ALL_UNIT_SUBTYPES_FAILURE: 'UNIT_SUBTYPES_GET_ALL_FAILURE',

    DETAIL_UNIT_SUBTYPE_REQUEST: 'UNIT_SUBTYPE_DETAIL_REQUEST',
    DETAIL_UNIT_SUBTYPE_SUCCESS: 'UNIT_SUBTYPE_DETAIL_SUCCESS',
    DETAIL_UNIT_SUBTYPE_FAILURE: 'UNIT_SUBTYPE_DETAIL_FAILURE',

    UPDATE_UNIT_SUBTYPE_REQUEST: 'UNIT_SUBTYPE_UPDATE_REQUEST',
    UPDATE_UNIT_SUBTYPE_SUCCESS: 'UNIT_SUBTYPE_UPDATE_SUCCESS',
    UPDATE_UNIT_SUBTYPE_FAILURE: 'UNIT_SUBTYPE_UPDATE_FAILURE',
};
