/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Card, CardHeader, CardBody, Input, Button, Nav, NavLink, TabContent, TabPane} from '../../components/bot-components';
import {userActions} from "../../network/actions";
import {authHeader, store} from "../../helpers";
import {baseUrl} from "../../network/constants";
import classnames from "classnames";
import ReduxBlockUi from 'react-block-ui';
import {Label} from "../../components/bot-components";

const UserEnums = () => {
    const [state, updateState] = React.useState({
        loading: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const getEnums = (field) => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify({
                field: field
            })
        };
        return fetch(`${baseUrl}/users/list_enum/`, requestOptions).then(response => {
            return response.status !== 404 ? response.json().then(data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    return null;
                }
                return data;
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };
    const getExtraFields = () => {
        setState({
            loading: true
        });

        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };
        return fetch(`${baseUrl}/users/list_fields/`, requestOptions).then(response => {
            return response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    setState({
                        loading: false
                    });
                    return alert('There was an error loading the data, please try again...');
                }
                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    // noinspection JSCheckFunctionSignatures
                    await store.dispatch(userActions.updateAuth(Auth, null));
                }
                if (data) {
                    const fields = data;
                    getEnums('enum_field_1').then((done, err) => {
                        if (err) console.log(err); else setState({enum_field_1: done});
                    });
                    getEnums('enum_field_2').then((done, err) => {
                        if (err) console.log(err); else setState({enum_field_2: done});
                    });
                    getEnums('enum_field_3').then((done, err) => {
                        if (err) console.log(err); else setState({enum_field_3: done});
                    });
                    getEnums('enum_field_4').then((done, err) => {
                        if (err) console.log(err); else setState({enum_field_4: done});
                    });
                    const enums = data.filter(d => d.field.startsWith('enum'));
                    console.log(enums);
                    setState({
                        fields: fields,
                        enums: enums,
                        activeTab: !state.activeTab ? `'${enums?.[0]?.id}'` : state.activeTab
                    });
                    return setState({
                        loading: false
                    });
                } else return setState({
                    loading: false
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };
    React.useEffect(() => {
        getExtraFields().catch();
    }, []);

    const toggleTab = (tab) => {
        console.log(tab);
        setState({
            activeTab: tab
        });
    };

    const addEnum = (field) => {
        const cancel = () => {
            alert('You must enter a value to save.');
            setState({loading: false});
        };
        setState({
            loading: true
        });
        const existing = Array.from(state[field]);
        const newValue = state[`new_${field}`];
        const valueSet = new Set(existing);
        valueSet.add(newValue);
        const values = Array.from(valueSet);
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify({
                field: field,
                values: values
            })
        };
        const valid = newValue && newValue !== "" && newValue !== '""';
        return !valid ? cancel() : fetch(`${baseUrl}/users/update_enum/`, requestOptions).then(async response => {
            if (!response.ok) {
                if (response.status === 401) {
                    alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                }
                setState({
                    loading: false
                });
                return  alert('There was an error saving the data, please try again...');
            }
            const Auth = JSON.parse(response.headers.get('Authentication'));
            if (Auth) {
                // noinspection JSCheckFunctionSignatures
                await store.dispatch(userActions.updateAuth(Auth, null));
            }
            getEnums(field).then((done, err) => {
                if (err) console.log(err); else setState({
                    loading: false,
                    [state[field]]: done,
                    [`new_${field}`]: null
                });
            }).catch(error => Promise.reject(error));
        }).catch(error => Promise.reject(error));
    };

    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };

        const {loading} = state;
        return(
            <ReduxBlockUi blocking={loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                <Card>
                    <CardBody>
                        <Nav tabs>
                            {state.enums?.map?.((e) => {
                                return(
                                    <NavLink className={classnames({active: state.activeTab === `'${e.id}'`})}
                                             onClick={() => toggleTab(`'${e.id}'`)}>
                                        {e.table_header}
                                    </NavLink>
                                )
                            })}
                        </Nav>
                        <TabContent activetab={state.activeTab}>
                            {state.enums?.map?.((e) => {
                                if (state.activeTab === `'${e.id}'`)
                                    return (
                                        <TabPane tabId={`'${e.id}'`}>
                                            <Card>
                                                <CardHeader>
                                                    <Input type={'text'} name={[`new_${e.field}`]} value={state[`new_${e.field}`]} onChange={(e) => changeProps(e)}/>
                                                    <Button outline color={'texts'} block={true} onClick={() => addEnum(e.field)}>Add</Button>
                                                </CardHeader>
                                                <CardBody>
                                                    <ul>
                                                        {state[e.field]?.map?.(f => {
                                                            return(
                                                                <li>{f === "" ? '""' : f}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    ); else return null;
                            })}
                        </TabContent>
                    </CardBody>
                </Card>
            </ReduxBlockUi>

        );
};

const mapStateToProps = (state) => {
    const {authentication, user_preference} = state;
    const user = authentication.user;
    return {user, user_preference};
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: (req) => dispatch(userActions.getPag(req)),
        getUser: (id) => dispatch(userActions.getDetail(id)),
        importUsers: (file) => dispatch(userActions.importUsers(file)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEnums);
