// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const purchaseOrderService = {};

const apiUrl = baseUrl;

purchaseOrderService.createPurchaseOrder = (purchase_order) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(purchase_order)
    };

    return fetch(`${apiUrl}/purchase_order/`, requestOptions).then(handleResponse);
};

purchaseOrderService.updatePurchaseOrder = (purchase_order) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(purchase_order)
    };

    return fetch(`${apiUrl}/purchase_order/` + purchase_order.id, requestOptions).then(handleResponse);
};

purchaseOrderService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/purchase_orders`, requestOptions).then(handleResponse);
};

purchaseOrderService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/purchase_order/` + id, requestOptions).then(handleResponse);
};
