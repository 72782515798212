import _ from 'lodash';
import PropTypes from 'prop-types';
import {_dateFormat, getColumn} from '../../../helpers';
import React from 'react';

const defaultSort = {
    sortName: '',
    sortOrder: '',
};
const defaultPaging = {
    currSize: 20,
    currPage: 1
};
const prepareId = (cell, getMyUser, onUserSelected) => {
    const handleClick = () => {
        localStorage.setItem('edit_user', cell);
        getMyUser(cell);
        onUserSelected();
    };
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleClick()}>{cell}</div>
    );
};
const prepareName = (cell, row, getMyUser, onUserSelected) => {
    const handleClick = () => {
        localStorage.setItem('edit_user', row.id);
        getMyUser(row.id);
        onUserSelected();
    };
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleClick()}>{cell}</div>
    );
};
/**
 *
 * @param cell
 * @param row
 * @param row.user_status
 * @returns {JSX.Element}
 */
const prepareStatus = (cell, row) => {
    if (cell) return <div>{row.user_status}</div>;
};
const prepareDaily = (cell) => {
    if (cell) return <div>Y</div>; else return <div>N</div>;
};
export const defaultFilters = {
    id: '',
    name: '',
    employee_number: '',
    crew: '',
    supervisor: '',
    first_name: '',
    last_name: '',
    user_status_id: '',
    daily_inspections: '',
    phone_number: '',
    company_role: '',
    email: '',
    sign_in_count: '',
    last_sign_in_at: '',
    job_number: '',
    yard_name: '',
    role_string: ''
};
export const myFilters = _.cloneDeep(defaultFilters);
export const defaultState = {
    ...defaultSort,
    ...defaultPaging,
    filters: myFilters
};
/**
 * @param props
 * @param props.extraColumns
 * @param props.tblId
 * @param props.getMyUser
 * @param props.columns
 * @param props.onUserSelected
 * @param props.showTitle
 * @param props.getUsers
 * @param props.loading
 * @param props.csvUrl
 * @param props.csvFile
 * @param props.remote
 * @param props.lStorage
 * @param props.user_preference
 * @param ref
 * @returns {JSX.Element}
 */
export const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    showTitle: PropTypes.bool,
    data: PropTypes.any,
    getUsers: PropTypes.func.isRequired,
    getMyUser: PropTypes.func.isRequired,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    updateUser: PropTypes.func,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool.isRequired,
    tblId: PropTypes.string.isRequired,
    pageSizes: PropTypes.array,
    columns: PropTypes.array,
    extraColumns: PropTypes.array,
    onUserSelected: PropTypes.func,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any
};
const standardColumns = (columns, extraColumns, defaultFilters, refs, getMyUser, onUserSelected) => [
    getColumn('id', 'ID', 180, (cell) => prepareId(cell, getMyUser, onUserSelected), false, refs[0], true, {
        type: 'text',
        placeholder: 'ID',
        defaultValue: defaultFilters.id,
    }),
    getColumn('name', 'Name', 200, (cell, row) => prepareName(cell, row, getMyUser, onUserSelected), false, refs[1], true, {
        type: 'text',
        placeholder: 'Name',
        defaultValue: defaultFilters.name
    }),
    getColumn('employee_number', 'Employee#', 120, null, false, refs[2], true, {
        type: 'text',
        placeholder: 'Employee#',
        defaultValue: defaultFilters.employee_number
    }),
    getColumn('supervisor', 'Supervisor', 200, null, false, refs[3], true, {
        type: 'text',
        placeholder: 'Supervisor',
        defaultValue: defaultFilters.supervisor
    }),
    getColumn('first_name', 'First Name', 200, null, false, refs[4], true, {
        type: 'text',
        placeholder: 'First Name',
        defaultValue: defaultFilters.first_name
    }),
    getColumn('last_name', 'Last Name', 200, null, false, refs[5], true, {
        type: 'text',
        placeholder: 'Last Name',
        defaultValue: defaultFilters.last_name
    }),
    getColumn('user_status_id', 'Status', 120, (cell, row) => prepareStatus(cell, row), false, refs[6], true, {
        type: 'select',
        placeholder: 'Status',
        defaultValue: defaultFilters.user_status_id,
        options: [{value: '', label: 'Select Status'}, {value: 1, label: 'Active'}, {value: 2, label: 'Inactive'}]
    }),
    getColumn('daily_inspections', 'Daily', 100, (cell) => prepareDaily(cell), false, refs[7], true, {
        type: 'select',
        placeholder: 'Select Y/N',
        defaultValue: defaultFilters.daily_inspections,
        options: [{value: '', label: 'Select Y/N'}, {value: true, label: 'Y'}, {value: false, label: 'N'}]
    }),
    getColumn('phone_number', 'Phone#', 150, null, false, refs[8], true, {
        type: 'text',
        placeholder: 'Phone#',
        defaultValue: defaultFilters.phone_number
    }),
    getColumn('email', 'Username', 200, null, false, refs[9], true, {
        type: 'text',
        placeholder: 'Username',
        defaultValue: defaultFilters.email
    }),
    getColumn('sign_in_count', `Sign In's`, 80, null, false, refs[10], true, {
        type: 'text',
        placeholder: `Sign In's`,
        defaultValue: defaultFilters.sign_in_count
    }),
    getColumn('last_sign_in_at', 'Last Sign In', 150, (cell) => cell && _dateFormat(cell), false, refs[11], true, null),
    getColumn('job_number', 'Job', 225, null, false, refs[12], true, {
        type: 'text',
        placeholder: 'Job',
        defaultValue: defaultFilters.job_number
    }),
    getColumn('yard_name', 'Location', 200, null, false, refs[13], true, {
        type: 'text',
        placeholder: 'Yard',
        defaultValue: defaultFilters.yard_name
    }),
    getColumn('role_string', 'Roles', 300, null, false, refs[14], true, {
        type: 'text',
        placeholder: 'Roles',
        defaultValue: defaultFilters.role_string
    }),
    getColumn('created_at', 'Created', 200, cell => _dateFormat(cell), false, refs[15], true, null),
    getColumn('updated_at', 'Last Updated', 200, cell => _dateFormat(cell), false, refs[16], true, null)
];
export const getColumns = (columns, extraColumns, defaultFilters, refs, getMyUser, onUserSelected) => extraColumns ? [...standardColumns(columns, extraColumns, defaultFilters, refs, getMyUser, onUserSelected), ...extraColumns] : standardColumns(columns, extraColumns, defaultFilters, refs, getMyUser, onUserSelected);
