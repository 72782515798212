import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};

const Footer = () => {

        return (
            <React.Fragment>
                <span className="ml-auto">Powered by <a href="https://blueoceanaustin.com" target={'_blank'} rel={'noopener noreferrer'}>Blue<img className={'footer-logo'} src={"https://web-pumpco-t.fleetmatrix.cc/assets/img/BlueOceanLogo-O.png"} alt=""/>cean</a> &copy; 2018 - {new moment().format('YYYY')}</span>
            </React.Fragment>
        );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
