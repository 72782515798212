export const logConstants = {
    GET_ALL_LOGS_REQUEST: 'LOGS_GET_ALL_REQUEST',
    GET_ALL_LOGS_SUCCESS: 'LOGS_GET_ALL_SUCCESS',
    GET_ALL_LOGS_FAILURE: 'LOGS_GET_ALL_FAILURE',

    DETAIL_LOG_REQUEST: 'LOG_DETAIL_REQUEST',
    DETAIL_LOG_SUCCESS: 'LOG_DETAIL_SUCCESS',
    DETAIL_LOG_FAILURE: 'LOG_DETAIL_FAILURE',

    CREATE_LOG_REQUEST: 'LOG_CREATE_REQUEST',
    CREATE_LOG_SUCCESS: 'LOG_CREATE_SUCCESS',
    CREATE_LOG_FAILURE: 'LOG_CREATE_FAILURE',

    UPDATE_LOG_REQUEST: 'LOG_UPDATE_REQUEST',
    UPDATE_LOG_SUCCESS: 'LOG_UPDATE_SUCCESS',
    UPDATE_LOG_FAILURE: 'LOG_UPDATE_FAILURE',

    GET_ALL_CHECKS_REQUEST: 'CHECKS_GET_ALL_REQUEST',
    GET_ALL_CHECKS_SUCCESS: 'CHECKS_GET_ALL_SUCCESS',
    GET_ALL_CHECKS_FAILURE: 'CHECKS_GET_ALL_FAILURE',
};
