import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { mapToCssModules, deprecated, warnOnce, tagPropType } from './utils';
const propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    size: PropTypes.string,
    bsSize: PropTypes.string,
    state: deprecated(
        PropTypes.string,
        'Please use the props "valid" and "invalid" to indicate the state.'
    ),
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    tag: tagPropType,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.string
    ]),
    static: deprecated(PropTypes.bool, 'Please use the prop "plaintext"'),
    plaintext: PropTypes.bool,
    addon: PropTypes.bool,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

const defaultProps = {
    type: 'text'
};
const { Option } = components;
const IconOption = props => {
    const { icon } = props.data;
    const { onClick: iconClick, className} = icon ?? {};
    const performClick = (e) => {
        if (iconClick) {
            e.stopPropagation();
            iconClick(e);
        }
    };
    return (
      <Option {...props}>
          {props.data.icon && <i style={ { paddingRight:'0.5rem' } } className={ className }
                                 onClick={ performClick }/> }
          {props.data.label}
      </Option>
    )
};
class Input extends React.Component {
    constructor(props) {
        super(props);
        this.getRef = this.getRef.bind(this);
        this.focus = this.focus.bind(this);
    }

    getRef(ref) {
        if (this.props.innerRef) {
            this.props.innerRef(ref);
        }
        this.ref = ref;
    }

    focus() {
        if (this.ref) {
            this.ref.focus();
        }
    }

    render() {
        let {
            className,
            cssModule,
            type,
            bsSize,
            state,
            valid,
            invalid,
            tag,
            addon,
            static: staticInput,
            plaintext,
            innerRef,
            ...attributes
        } = this.props;

        const checkInput = ['radio', 'checkbox'].indexOf(type) > -1;
        const isNotaNumber = new RegExp('\\D', 'g');

        const fileInput = type === 'file';
        const textareaInput = type === 'textarea';
        const selectInput = type === 'select';
        const reactSelect = type === 'react-select';
        const asyncSelect = type === 'react-async-select'
        let Tag = tag || (selectInput || textareaInput ? type : 'input');

        let formControlClass = 'form-control';

        if (plaintext || staticInput) {
            formControlClass = `${formControlClass}-plaintext`;
            Tag = tag || 'input';
        } else if (fileInput) {
            formControlClass = `${formControlClass}-file`;
        } else if (checkInput) {
            if (addon) {
                formControlClass = null;
            } else {
                formControlClass = 'form-check-input';
            }
        }

        if (
            state &&
            typeof valid === 'undefined' &&
            typeof invalid === 'undefined'
        ) {
            if (state === 'danger') {
                invalid = true;
            } else if (state === 'success') {
                valid = true;
            }
        }

        if (attributes.size && isNotaNumber.test(attributes.size)) {
            warnOnce(
                'Please use the prop "bsSize" instead of the "size" to bootstrap\'s input sizing.'
            );
            bsSize = attributes.size;
            delete attributes.size;
        }

        const classes = mapToCssModules(
            classNames(
                className,
                invalid && 'is-invalid',
                valid && 'is-valid',
                bsSize ? `form-control-${bsSize}` : false,
                reactSelect && 'react-select',
                asyncSelect && 'react-select',
                formControlClass
            ),
            cssModule
        );

        if (Tag === 'input' || (tag && typeof tag === 'function')) {
            attributes.type = type;
        }

        if (
            attributes.children &&
            !(
                plaintext ||
                staticInput ||
                type === 'select' ||
                typeof Tag !== 'string' ||
                Tag === 'select'
            )
        ) {
            warnOnce(
                `Input with a type of "${type}" cannot have children. Please use "value"/"defaultValue" instead.`
            );
            delete attributes.children;
        }

        const customStyles = {
            control: styles => ({ ...styles, height: '100%', minHeight: 'unset', border: '0' }),
        };

        return reactSelect ? <Select styles={customStyles} {...attributes} ref={innerRef} className={classes} components={{ Option: IconOption }}/> : asyncSelect ? <AsyncSelect styles={customStyles} {...attributes} ref={innerRef} className={classes}/> : <Tag {...attributes} ref={innerRef} className={classes} />;
    }
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
