import {Col, Row} from '../../components/bot-components';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    yard: PropTypes.object
};
const YardDetail = (props) => {
    const {yard} = props;
    return (
        <Row>
            <Col sm={'6'}>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>Name:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.name}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>Address:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.address}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>City:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.city}</label>}
                    </Col>
                </Row>
            </Col>
            <Col sm={'6'}>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>State:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.state}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>Zip:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.zip_code}</label>}
                    </Col>
                </Row>
                <Row>
                    <Col sm={'4'}>
                        <label className={'float-right'}
                               style={{
                                   fontSize: 18,
                                   fontWeight: 'bold'
                               }}>Phone:</label>
                    </Col>
                    <Col sm={'8'}>
                        {yard &&
                        <label
                            style={{fontSize: 18}}>{yard.phone_number}</label>}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
YardDetail.propTypes = propTypes;
export default YardDetail;
