import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'div'
};

let AppSidebarForm = function (_Component) {
    _inherits(AppSidebarForm, _Component);

    function AppSidebarForm() {
        _classCallCheck(this, AppSidebarForm);

        return _possibleConstructorReturn(this, _Component.apply(this, arguments));
    }

    AppSidebarForm.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        let classes = classNames(className, 'sidebar-form');
        return children ? React.createElement(
            Tag,
            _extends({className: classes}, attributes),
            children
        ) : null;
    };

    return AppSidebarForm;
}(Component);

AppSidebarForm.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSidebarForm.defaultProps = defaultProps;

export default AppSidebarForm;
