import {store} from './';

export function authHeader(type) {
    const user = store.getState().authentication.user;

    if (user && user.token) {
        switch (type) {
            case 'GET':
                return {'Authorization': `Bearer ${user.token}`, 'Accept': '*/*'};
            case 'POST':
                return {'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json'};
            case 'PUT':
                return {'Authorization': `Bearer ${user.token}`, 'Content-Type': 'application/json'};
            case 'FORM':
                return {'Authorization': `Bearer ${user.token}`};
            default:
                return {};
        }
    } else {
        return {};
    }
}
