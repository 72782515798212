// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const companyService = {};

const apiUrl = baseUrl;

companyService.createCompany = (company) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({company})
    };

    return fetch(`${apiUrl}/company`, requestOptions).then(handleResponse)
}
;

companyService.updateCompany = (company_id, company) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({company})
    };

    return fetch(`${apiUrl}/company/` + company_id, requestOptions).then(handleResponse);
};

companyService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/companies`, requestOptions).then(handleResponse);
};

companyService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/company/` + id, requestOptions).then(handleResponse);
};
