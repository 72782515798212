import React from 'react';
import {
    BotTable,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput, DropdownItem
} from '../../../components/bot-components';
import WorkOrderDetail from "../../WorkOrders/WorkOrderDetail/WorkOrderDetail";
import BotUnit from "../../Units/BotUnit/BotUnit";
import {VendorMechanicAssignedEditor, GenericTextEditor, PriorityEditor} from '../../Editors';
import TaskDetail from "../TaskDetail/TaskDetail";
import CreateTask from '../CreateTask/CreateTask';
import {defaultState, defaultFilters, propTypes, getColumns} from './BotTasks.constants';
import TaskDetail1 from '../TaskDetail1';
// import AlertModal from '../PLMAlerts/alert-modal';
let columns = [];
let table_key_iterator = 0;

const BotTasks = React.forwardRef(
    /**
     * @param props
     * @param props.remote
     * @param props.includeDateRange
     * @param props.lStorage
     * @param props.pageSizes
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.editAction
     * @param props.alertAction
     * @param props.task
     * @param props.getMyTask
     * @param props.getMyAlert
     * @param props.getTasks
     * @param props.updateTask
     * @param props.createTask
     * @param props.vendorList
     * @param props.mechanicList
     * @param props.canCreate
     * @param props.priorityList
     * @param props.workorder
     * @param props.className
     * @param props.csvUrl
     * @param props.csvFile
     * @param props.loading
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        //<editor-fold desc="Column Refs">
        const idRef = React.useRef();
        const workorderRef = React.useRef();
        const numberRef = React.useRef();
        const descriptionRef = React.useRef();
        const yardRef = React.useRef();
        const dotRef = React.useRef();
        const certifiedRef = React.useRef();
        const certByRef = React.useRef();
        const uStatusRef = React.useRef();
        const priorityRef = React.useRef();
        const completeRef = React.useRef();
        const sourceRef = React.useRef();
        const completedByRef = React.useRef();
        const vendorRef = React.useRef();
        const columnRefs = [idRef, workorderRef, numberRef, descriptionRef, yardRef, sourceRef, completeRef, dotRef, certifiedRef, certByRef, uStatusRef, priorityRef, vendorRef, completedByRef];
        //</editor-fold>
    !props.includeDateRange && delete defaultState.filters.date_range;
    const [state, updateState] = React.useState({
        ...defaultState,
        lStorage: props.lStorage,
        remote: props.remote,
        priorityEditor: false,
        AssignedEditor: false,
        descriptionEditor: false,
        selectedRow: null,
        selectedCell: null,
        taskModal: false,
        workorderModal: false,
        unitModal: false,
        newModal: false,
        pageSizes: props.pageSizes ?? ['20', '40', '80', '160']
    });
    const setState = newState => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    const vRef = React.useRef();
    const dRef = React.useRef();
    const pRef = React.useRef();
    const table_keys = () => `${state.tblId}_${table_key_iterator++}`;
    const toggleModal = (modal, row) => {
        setState({
            [modal]: !state[modal],
            selectedRow: row
        });
    };
    const toggleWorkorderModal = () => toggleModal('workorderModal');
    const toggleUnitModal = () => toggleModal('unitModal');
    const toggleTaskModal = (row) => toggleModal('taskModal', row);
    const toggleAlertModal = () => toggleModal('alertModal');
    const toggleNewModal = () => toggleModal('newModal');
    const toggleEditor = (editor) => {
        setState({
            selectedRow: null,
            selectedCell: null,
            [editor]: false
        });
    };
    const togglePriorityEditor = () => toggleEditor('priorityEditor');
    const toggleAssignedEditor = () => toggleEditor('AssignedEditor');
    const toggleDescriptionEditor = () => toggleEditor('descriptionEditor');
    const FilterDotBtn = () => (<DropdownItem outline color={'texts'} className={(ref?.current?.state?.filters?.dot === true || ref?.current?.state?.filters?.dot === 'true') ? 'active' : ''} key={table_keys()} onClick={() => {filterDotOnly()}}>DOT Only</DropdownItem>);
    const FilterDotIcon = () => (<span className={'clear-dot-icon'}><span onClick={() => filterDotOnly()}>DOT</span><span><i className={'clear-icon'} hidden={!(ref?.current?.state?.filters?.dot === true || ref?.current?.state?.filters?.dot === 'true')} key={table_keys()} onClick={() => {ref?.current?.setFilter('dot', "")}}/></span></span>);
    const filterDotOnly = () => {
        const filter = dotRef.current;
        const currValue = filter?.state.value;
        return (currValue === true || currValue === false) ? filter?.setFilter("") : filter?.setFilter(true);
    };
    const FilterCompBtn = () => (<DropdownItem outline color={'texts'} className={(ref?.current?.state?.filters?.complete === true || ref?.current?.state?.filters?.complete === 'true') ? 'active' : ''} key={table_keys()} onClick={() => {filterCompOnly()}}>Complete Only</DropdownItem>);
    const FilterCompIcon = () => (<span className={'clear-dot-icon'}><span onClick={() => filterCompOnly()}>Complete</span><span><i className={'clear-icon'} hidden={!(ref?.current?.state?.filters?.complete === true || ref?.current?.state?.filters?.complete === 'true')} key={table_keys()} onClick={() => ref?.current?.setFilter('complete', "")}/></span></span>);
    const filterCompOnly = () => {
        const filter = completeRef.current;
        const currValue = filter?.state.value;
        return (currValue === true || currValue === false) ? filter?.setFilter("") : filter?.setFilter(true);
    };
    const FilterCertBtn = () => (<DropdownItem outline color={'texts'} className={(ref?.current?.state?.filters?.certified === true || ref?.current?.state?.filters?.certified === 'true') ? 'focus' : ''} key={table_keys()} onClick={() => {filterCertOnly()}}>Certified Only</DropdownItem>);
    const FilterCertIcon = () => (<span className={'clear-dot-icon'}><span onClick={() => filterCertOnly()}>Certified</span><span><i className={'clear-icon'} hidden={!(ref?.current?.state?.filters?.certified === true || ref?.current?.state?.filters?.certified === 'true')} key={table_keys()} onClick={() => {ref?.current?.setFilter('certified', "")}}/></span></span>);
    const filterCertOnly = () => {
        const filter = certifiedRef.current;
        const currValue = filter?.state.value;
        return (currValue === true || currValue === false) ? ref?.current?.setFilter('certified', "") : ref?.current?.setFilter('certified', true);
    };
    const createNewBtn = () => {
        return(
            <DropdownItem outline color={'texts'} onClick={() => {
                toggleNewModal();
            }}><i className={'fa fa-plus'}/> New</DropdownItem>
        )
    };
    const refreshTasks = () => {
        const tblState = ref?.current?.state;
        const req = {
            sortName: tblState.sortName,
            sortOrder: tblState.sortOrder,
            currentPage: tblState.currPage,
            sizePerPage: tblState.currSize,
            filters: tblState.filters
        };
        props.remote && tblState && props.getTasks(req);
    };
    const updateTask = async (t) => {
        await props.updateTask(t);
        async function reloadData() {
            if (props.task?.items) {
                await refreshTasks();
            } else {
                setTimeout(reloadData, 250);
            }
        }
        await reloadData();
    };
    const createTask = (t) => {
        props.createTask(t);
        toggleNewModal();
    };


    const {pageSizes, selectedRow, selectedCell, priorityEditor, AssignedEditor, unitModal} = state;
    const {title, data, task, unit} = props;
    columns = getColumns(props, defaultFilters, columnRefs, setState, toggleTaskModal, toggleWorkorderModal, toggleUnitModal, toggleAlertModal);
    return (
        <div>
            {selectedRow && <GenericTextEditor ref={dRef} isOpen={state.descriptionEditor} toggle={() => toggleDescriptionEditor()} onUpdate={(t) => updateTask(t)} row={selectedRow} cell={selectedCell}/>}
            {selectedRow && <VendorMechanicAssignedEditor ref={vRef} isOpen={AssignedEditor} toggle={() => toggleAssignedEditor()} onUpdate={(t) => updateTask(t)} row={selectedRow} extraProps={{vendorList: props.vendorList, mechanicList: props.mechanicList}}/>}
            {selectedRow && <PriorityEditor isOpen={priorityEditor} toggle={() => togglePriorityEditor()} ref={pRef} onUpdate={(t) => updateTask(t)} row={selectedRow} cell={selectedCell} priorityList={props.priorityList}/>}
            {props.canCreate && props.workorder.items && props.vendorList && props.mechanicList && props.priorityList &&
            <CreateTask priorityList={props.priorityList} mechanicList={props.mechanicList} vendorList={props.vendorList}
                        toggle={() => toggleNewModal()} isOpen={state.newModal} workorder={props.workorder} onSaveTask={(t) => createTask(t)}/>}
            <Modal size={'xl'} isOpen={state.workorderModal} toggle={toggleWorkorderModal} className={props.className}>
                <ModalHeader toggle={toggleWorkorderModal}/>
                <ModalBody>
                    <WorkOrderDetail modal/>
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={toggleWorkorderModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal size={'xl'} isOpen={unitModal} className={props.className}>
                <ModalHeader toggle={() => {toggleUnitModal()}}>
                    Unit detail for {unit.items ? unit.items.number : ''}
                </ModalHeader>
                <ModalBody>
                    <BotUnit unit={unit} isModal parentComp={'workorder'}/>
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={() => {toggleUnitModal()}}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal size={'xl'} isOpen={state.taskModal} className={props.className} toggle={toggleTaskModal}>
                <ModalHeader toggle={toggleTaskModal}>
                    Task detail for{selectedRow ? `Work Order# ${selectedRow.workorder_id} -- ` : null} Task# {task && task.items ? task.items.id : ''}<CustomInput id={'task-detail-selector'} type={'switch'} label={'New Task Detail'} checked={state.detailType} onChange={(e) => setState({detailType: e.target.checked})}/>
                </ModalHeader>
                <ModalBody>
                    {state.detailType ? <TaskDetail isModal={true} updateTask={(t) => updateTask(t)}/> : <TaskDetail1 isModdal/>}
                </ModalBody>
                <ModalFooter>
                    <Button color={'texts'} outline onClick={toggleTaskModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            {/*<AlertModal isOpen={alertModal} toggle={() => toggleAlertModal()} plm_alert={props.plm_alert}/>*/}
            <BotTable ref={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      getData={(req) => props.getTasks(req)}
                      pageSizes={pageSizes}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      CreateNewBtn={props.canCreate ? createNewBtn : null}
                      pagination={'both'}
                      headers={true}
                      expandable={false}
                      doRowUpdate={(wo) => updateTask(wo)}
                      refreshData={() => refreshTasks()}
                      btnGroupDirection={'left'}
                      remote={state.remote}
                      defaultFilters={defaultFilters}
                      defaultState={defaultState}
                      lStorage={props.lStorage}
                      loading={props.loading}
                      onlyFilters={[FilterDotBtn, FilterCompBtn, FilterCertBtn]}
                      onRowDoubleClick={(row) => {props.getMyTask(row.id); toggleTaskModal()}}
                      onlyFiltersIcons={[FilterDotIcon, FilterCompIcon, FilterCertIcon]}
                      dateRangeBtns={props.includeDateRange ? ["30", "60", "90", "All"] : []}
                      dateRangeIcons={props.includeDateRange ? ["30", "60", "90", "All"] : []}
                      csvFile={props.csvFile}
                      csvUrl={props.csvUrl}
            />
        </div>
    );
});

BotTasks.propTypes = propTypes;
export default BotTasks;
