import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const tireSizeService = {};

const apiUrl = baseUrl;

tireSizeService.createTireSize = (tire_size) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(tire_size)
    };

    return fetch(`${apiUrl}/tire_size`, requestOptions).then(handleResponse);
};

tireSizeService.updateTireSize = (tire_size) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(tire_size)
    };

    return fetch(`${apiUrl}/tire_size/${tire_size.id}`, requestOptions).then(handleResponse);
};

tireSizeService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/tire_sizes/`, requestOptions).then(handleResponse);
};

tireSizeService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/tire_size/${id}`, requestOptions).then(handleResponse);
};
