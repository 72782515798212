import {authHeader} from '../../helpers/index';
import {baseUrl} from "../constants/";
import {handleResponse} from './responseHandler';

const apiUrl = baseUrl;

export const roleService = {

    getRoles: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };

        return fetch(`${apiUrl}/roles`, requestOptions).then(handleResponse);
    },

    getMechanics: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };
        const url = new URL(`${apiUrl}/roles/users/`);
        url.searchParams.append('id', '3');
        return fetch(url, requestOptions).then(handleResponse);
    },

    getManagers: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };

        const url = new URL(`${apiUrl}/roles/users/`);
        url.searchParams.append('id', '4');
        return fetch(url, requestOptions).then(handleResponse);
    },

    getOperators: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };
        const url = new URL(`${apiUrl}/roles/users/`);
        url.searchParams.append('id', '2');
        return fetch(url, requestOptions).then(handleResponse);
    }
};
