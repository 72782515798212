import React from 'react';
import {Button, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from '../../components/bot-components';
import {Modal, ModalBody} from '../../components/bot-components';
import PropTypes from 'prop-types';

const GenericTextEditor = (props) => {
    const {row, cell, toggle, isOpen, onUpdate} = props;
    const [state, updateState] = React.useState({
        id: null,
        show: false
    });
    const setState = (newState) => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    React.useEffect(() => {
        row && setState({
            id: row.id,
            [cell]: row[cell]
        });
    }, [row, cell]);
    const inputRef = React.useRef();
    const focusInput = () => {
        inputRef.current?.focus();
    };
    const handleChange = (e) => {
        const {value} = e.target;
        setState({
            [cell]: value
        });
    };
    const updateData = () => {
        toggle();
        onUpdate(state);
    };
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');
    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} centered={true} onOpened={() => focusInput()}>
            <ModalBody>
                <InputGroup>
                    <Input type={'textarea'}
                           ref={inputRef}
                           value={state[cell] ? state[cell] : ''}
                           onChange={handleChange}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
                </InputGroup>
            </ModalBody>
        </Modal>
    );
};
GenericTextEditor.propTypes = {
    ref: PropTypes.node,
    row: PropTypes.object,
    cell: PropTypes.any,
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    onUpdate: PropTypes.func
};
export default GenericTextEditor;
