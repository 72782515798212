import {userActions} from '../../../network/actions';
import {getExtraFields, getEnums} from '../shared.helpers';

export const filterActiveOnly = (userStatusRef) => {
    const filter = userStatusRef.current;
    const currValue = filter?.state.value;
    return (currValue === 1 || currValue === '1') ? filter?.setFilter('') : filter?.setFilter(1);
};
export const mapStateToListProps = (state) => {
    const {authentication, user_preference, pag_users} = state;
    const user = authentication.user;
    return {user, user_preference, pag_users};
};
export const mapDispatchToListProps = (dispatch) => {
    return {
        getUser: (id) => dispatch(userActions.getDetail(id)),
        importUsers: (file) => dispatch(userActions.importUsers(file)),
        getUsers: (req) => dispatch(userActions.getPag(req)),
        getLoginExceptions: () => dispatch(userActions.getLoginExceptions())
    };
};
const getEnumOptions = (e, header) => e?.map?.(o => ({value: o, label: o === '' ? `Select ${header}` : o})) ?? [];
export const setupEnums = async (state, setState) => {
    await getEnums('enum_field_1').then((done, err) => !err && setState({enum1: done}));
    await getEnums('enum_field_2').then((done, err) => !err && setState({enum2: done}));
    await getEnums('enum_field_3').then((done, err) => !err && setState({enum3: done}));
    await getEnums('enum_field_4').then((done, err) => !err && setState({enum4: done}));
};
export const setupExtraFields = async (state, setState) => {
    await getExtraFields().then(async (done, err) => {
        if (!err) {
            const enums = [];
            const _strings = [];
            let bools = [];
            await done.filter(
                /**
                 * @param d
                 * @param d.field
                 * @param d.table_header
                 * @returns {number|null}
                 */
                async d => {
                    if (d.field.startsWith('enum') && d.field !== d['table_header']) {
                        const f = `enum${d.field.replace(/[^0-9]/g, '')}`;
                        const options = await getEnumOptions(state[f], d.table_header);
                        const filter = {
                            field: d.field,
                            text: d.table_header,
                            filter: {
                                type: 'select',
                                placeholder: d.table_header,
                                options: options
                            },
                            width: 210,
                            hidden: false,
                            sortable: true
                        };
                        return enums.push(filter);
                    } else return null;
                });
            await done.filter(d => (d.field.startsWith('string') && d.field !== d['table_header']) ? _strings.push({
                field: d.field,
                text: d.table_header,
                filter: {
                    type: 'text',
                    placeholder: d.table_header,
                },
                width: 210,
                hidden: false,
                sortable: true
            }) : null);
            await done.filter(
                d => (d.field.startsWith('bool') && d.field !== d['table_header']) ? bools.push({
                    field: d.field,
                    text: d.table_header,
                    filter: {
                        type: 'select',
                        placeholder: d.table_header,
                        options: [{value: '', label: `Select ${d.table_header}`}, {
                            value: true,
                            label: 'Yes'
                        }, {value: false, label: 'No'}]
                    },
                    format: (cell, row) => row[d.alias] === true ? 'Yes' : 'No',
                    width: 210,
                    hidden: false,
                    sortable: true
                }) : null);
            bools = Array.from(new Set(bools));
            setState({
                extraColumns: [...enums, ..._strings, ...bools],
                enums: enums,
                _strings: _strings,
                bools: bools
            });
        }
    });
};
