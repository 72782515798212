import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  SaveCancel,
  UncontrolledTooltip
} from '../../components/bot-components';
import _ from 'lodash';
import { blockNavigation } from '../../helpers';

const PmCheckListDetail = ( { currentList, allLists, updateList, deleteList, isOpen, toggle, history } ) => {
  const [checkList, setCheckList] = useState( currentList );
  const [confirmCancel, setConfirmCancel] = useState( false );

  const cancelEdit = () => {
    if (!_.isEqual( currentList, checkList )) {
      setConfirmCancel( true );
    } else {
      setCheckList( currentList );
      isOpen && toggle();
    }
  };
  const save = () => {
    updateList && updateList( checkList );
    isOpen && toggle();
  };

  useEffect( () => {
    setCheckList( currentList );
  }, [currentList] );

  const mileageValid = checkList?.use_mileage ? checkList?.mileage_interval > 0 : checkList?.use_hours ? checkList?.hours_interval > 0 : false;
  const mileageThresholdValid = checkList?.use_mileage ? checkList?.mileage_threshold > 0 : true;
  const pastDueMileageThresholdValid = checkList?.use_mileage ? checkList?.past_due_mileage_threshold > 0 : true;
  const hoursValid = checkList?.use_hours ? checkList?.hours_interval > 0 : checkList?.use_mileage ? checkList?.mileage_interval > 0 : false;
  const hoursThresholdValid = checkList?.use_hours ? checkList?.hours_threshold > 0 : true;
  const pastDueHoursThresholdValid = checkList?.use_hours ? checkList?.past_due_hours_threshold > 0 : true;
  const nameValid = checkList?.name?.length > 0;
  const sthValid = checkList?.starting_hours >= 0;
  const stmValid = checkList?.starting_mileage >= 0;
  const stValid = sthValid || stmValid;
  const canSave = nameValid && mileageValid && mileageThresholdValid && pastDueMileageThresholdValid && hoursValid && hoursThresholdValid && pastDueHoursThresholdValid && stValid && !_.isEqual( currentList, checkList );

  useEffect( () => {
    blockNavigation( history, !_.isEqual( currentList, checkList ), history.location );
  }, [canSave] );

  const editComponent = () => (
    <Modal isOpen={ isOpen } size={ 'lg' }>
      <Modal isOpen={ confirmCancel && !_.isEqual( currentList, checkList ) }>
        <ModalHeader>
          Are you sure you want to cancel your changes?
        </ModalHeader>
        <ModalBody>
          <Col>(Yes) undo all changes and close</Col>
          <Col>
            <div style={ { textDecoration:!canSave ? 'line-through' : 'unset' } }>(No) Save current changes and close
            </div>
            { !canSave &&
              <small style={ { color:'red', paddingLeft:'0.25rem' } }>*You must fill in required values to be able to
                save.</small> }</Col>
          <Col>(Cancel) continue editing.</Col>
        </ModalBody>
        <ModalFooter>
          <Button color={ 'text' } onClick={ () => {
            setCheckList( currentList );
            setConfirmCancel( false );
            toggle();
          } }>Yes</Button>
          <Button color={ 'text' } disabled={ !canSave } onClick={ () => {
            save();
            setConfirmCancel( false );
          } }>No</Button>
          <Button color={ 'text' } onClick={ () => setConfirmCancel( false ) }>Cancel</Button>
        </ModalFooter>
      </Modal>
      <ModalHeader toggle={ cancelEdit }>
        <h3>Editing Checklist for { checkList?.name }. <i className={ 'fa fa-trash' } style={ {
          fontSize:'1rem',
          cursor:'pointer',
          paddingLeft:'0.5rem'
        } }
                                                          onClick={ () => deleteList( checkList ) }/></h3>
      </ModalHeader>
      <ModalBody>
        <InputGroup>
          <InputGroupAddon addonType={ 'prepend' }>
            <InputGroupText>
              Included PM's
            </InputGroupText>
          </InputGroupAddon>
          <Input type={ 'react-select' }
                 value={ checkList.includes && [...checkList.includes.map( i => ({ label:i.name, value:i.id }) )] }
                 isMulti
                 options={ allLists.filter( l => l.id !== checkList.id ).map( l => ({ label:l.name, value:l.id }) ) }
                 onChange={ e => {
                   setCheckList( prevState => ({
                     ...prevState,
                     includes:[...e.map( v => ({ id:v.value, name:v.label }) )]
                   }) );
                 } }
          />
        </InputGroup>
        <Row>
          <Col sm={ 6 }>
            <InputGroup className={ 'max-content' }>
              <InputGroupAddon addonType={ 'prepend' }>
                Use Hours
              </InputGroupAddon>
              <CustomInput addon
                           onChange={ e => {
                             setCheckList( prevState => ({
                               ...prevState,
                               hours_interval:!e.target.checked ? 0 : checkList.hours_interval,
                               use_hours:e.target.checked
                             }) );
                           } }
                           invalid={ !checkList.use_hours && !checkList.use_mileage }
                           checked={ checkList.use_hours }
                           id={ 'use_hours_checkbox' }
                           className={ `custom-checkbox form-control ${ (!checkList.use_hours && !checkList.use_mileage) ? 'is-invalid' : 'is-valid' }` }
                           type={ 'checkbox' }/>
            </InputGroup>
            { checkList.use_hours && <>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Hours(Frequency)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ ( e ) => setCheckList( prevState => ({
                  ...prevState,
                  hours_interval:e.target.value
                }) ) } value={ checkList.hours_interval ?? 0 }
                       invalid={ !hoursValid } valid={ hoursValid }/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Hours(Threshold)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  hours_threshold:e.target.value
                }) ) } value={ checkList.hours_threshold ?? 0 }
                       invalid={ !hoursThresholdValid } valid={ hoursThresholdValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'hours-threshold-help' } style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'hours-threshold-help' } placement={ 'right' }>Number of hours
                    before { checkList.hours_interval } to create the PM</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Past Due Hours(Threshold)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  past_due_hours_threshold:e.target.value
                }) ) } value={ checkList.past_due_hours_threshold ?? 0 }
                       invalid={ !pastDueHoursThresholdValid } valid={ pastDueHoursThresholdValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'past-due-hours-threshold-help' }
                     style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'past-due-hours-threshold-help' } placement={ 'right' }>Number of hours
                    after { checkList.hours_interval } to mark the PM as Overdue</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Starting Hours
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  starting_hours:e.target.value
                }) ) } value={ checkList.starting_hours ?? 0 }
                       invalid={ !sthValid } valid={ sthValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'starting-hours-help' } style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'starting-hours-help' } placement={ 'right' }>0 calculates all hours
                    from 0 on the hour meter</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
            </> }
          </Col>
          <Col sm={ 6 }>
            <InputGroup className={ 'max-content' }>
              <InputGroupAddon addonType={ 'prepend' }>
                Use Mileage
              </InputGroupAddon>
              <CustomInput addon
                           onChange={ e => {
                             setCheckList( prevState => ({
                               ...prevState,
                               mileage_interval:!e.target.checked ? 0 : checkList.mileage_interval,
                               use_mileage:e.target.checked
                             }) );
                           } }
                           invalid={ !checkList.use_hours && !checkList.use_mileage }
                           checked={ checkList.use_mileage }
                           id={ 'use_mileage_checkbox' }
                           className={ `custom-checkbox form-control ${ (!checkList.use_hours && !checkList.use_mileage) ? 'is-invalid' : 'is-valid' }` }
                           type={ 'checkbox' }
                           style={ { marginLeft:'.5rem', marginTop:'.7rem' } }/>
            </InputGroup>
            { checkList.use_mileage && <>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Mileage(Frequency)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  mileage_interval:e.target.value
                }) ) }
                       value={ checkList.mileage_interval ?? 0 } invalid={ !mileageValid } valid={ mileageValid }/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Mileage(Threshold)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  mileage_threshold:e.target.value
                }) ) } value={ checkList.mileage_threshold ?? 0 }
                       invalid={ !mileageThresholdValid } valid={ mileageThresholdValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'mileage-threshold-help' } style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'mileage-threshold-help' } placement={ 'right' }>Number of miles
                    before { checkList.mileage_interval } to create the PM</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Past Due Mileage(Threshold)
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  past_due_mileage_threshold:e.target.value
                }) ) } value={ checkList.past_due_mileage_threshold ?? 0 }
                       invalid={ !pastDueMileageThresholdValid } valid={ pastDueMileageThresholdValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'past-due-mileage-threshold-help' }
                     style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'past-due-mileage-threshold-help' } placement={ 'right' }>Number of
                    miles after { checkList.mileage_interval } to mark the PM as Overdue</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                  Starting Mileage
                </InputGroupAddon>
                <Input type={ 'number' } min={ 0 } step={ 10 } onChange={ e => setCheckList( prevState => ({
                  ...prevState,
                  starting_mileage:e.target.value
                }) ) } value={ checkList.starting_mileage ?? 0 }
                       invalid={ !stmValid } valid={ stmValid }/>
                <InputGroupAddon addonType={ 'append' }>
                  <i id={ 'starting-mileage-help' } style={ { paddingLeft:'0.25rem', color:'blue', paddingTop:'45%' } }
                     className={ 'fa fa-question-circle-o' }/>
                  <UncontrolledTooltip target={ 'starting-mileage-help' } placement={ 'right' }>0 calculates all miles
                    from 0 on the mileage meter</UncontrolledTooltip>
                </InputGroupAddon>
              </InputGroup>
            </> }
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={ { justifyContent:'space-between' } }>
        <small style={ { float:'left' } }>*If you add an included list any duplicate items will be removed from this
          list.</small> <SaveCancel saveDisabled={ !canSave } onSaveClick={ save } onCancelClick={ cancelEdit }/>
      </ModalFooter>
    </Modal>
  );

  useEffect( () => {
    setCheckList( currentList );
  }, [currentList] );

  const renderComponent = () => editComponent();

  return checkList ? renderComponent() : <></>;
};

export default PmCheckListDetail;