import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants/';
import {handleResponse} from './responseHandler';

export const dashboardService = {};

const apiUrl = baseUrl;

dashboardService.getWo = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/reports/current_dashboard/`, requestOptions).then(handleResponse);
};

dashboardService.getSnapshot = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/report/${id}`, requestOptions).then(handleResponse);
};

dashboardService.getIns = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/reports/current_inspection_dashboard/`, requestOptions).then(handleResponse);
};

dashboardService.getDot = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/reports/current_dot_dashboard/`, requestOptions).then(handleResponse);
};
