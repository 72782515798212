import {unitPmConstants} from '../constants';

export const unit_pms = (state = {}, action) => {
    switch (action.type) {
        case unitPmConstants.GET_ALL_UNIT_PMS_REQUEST:
            return {
                loading: true
            };
        case unitPmConstants.GET_ALL_UNIT_PMS_SUCCESS:
            return {
                items: action.unit_pms
            };
        case unitPmConstants.GET_ALL_UNIT_PMS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const unit_pm = (state = {}, action) => {
    switch (action.type) {
        case unitPmConstants.CREATE_UNIT_PM_REQUEST:
            return {
                loading: true
            };
        case unitPmConstants.CREATE_UNIT_PM_SUCCESS:
            return {
                items: action.unit_pm
            };
        case unitPmConstants.CREATE_UNIT_PM_FAILURE:
            return {
                error: action.error
            };
        case unitPmConstants.DETAIL_UNIT_PM_REQUEST:
            return {
                loading: true
            };
        case unitPmConstants.DETAIL_UNIT_PM_SUCCESS:
            return {
                items: action.unit_pm
            };
        case unitPmConstants.DETAIL_UNIT_PM_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
