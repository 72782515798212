import {userPreferenceConstants} from '../constants/';
import {userPreferenceService} from '../services/';

export const userPreferenceActions = {};
userPreferenceActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: userPreferenceConstants.GET_ALL_USER_PREFERENCES_REQUEST});
    const success = (user_preferences) => ({type: userPreferenceConstants.GET_ALL_USER_PREFERENCES_SUCCESS, user_preferences});
    const failure = (error) => ({type: userPreferenceConstants.GET_ALL_USER_PREFERENCES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        userPreferenceService.getAll()
            .then(user_preferences => dispatch(success(user_preferences)), error => dispatch(failure(error))).catch(error => dispatch(error))
    };
};
userPreferenceActions.getUserPreference = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: userPreferenceConstants.DETAIL_USER_PREFERENCE_REQUEST});
    const success = (user_preference) => ({type: userPreferenceConstants.DETAIL_USER_PREFERENCE_SUCCESS, user_preference});
    const failure = (error) => ({type: userPreferenceConstants.DETAIL_USER_PREFERENCE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        userPreferenceService.getUserPreference(id)
            .then(user_preference => {
                localStorage.setItem('user_preference', JSON.stringify(user_preference));
                return dispatch(success(user_preference));
            }, error => dispatch(failure(error)))
            .catch(error => dispatch(failure(error)));
    };

};
userPreferenceActions.createUserPreference = (user_preference) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: userPreferenceConstants.CREATE_USER_PREFERENCE_REQUEST});
    const success = (user_preference) => ({type: userPreferenceConstants.CREATE_USER_PREFERENCE_SUCCESS, user_preference});
    const failure = (error) => ({type: userPreferenceConstants.CREATE_USER_PREFERENCE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        userPreferenceService.createUserPreference(user_preference)
            .then(user_preference => dispatch(success(user_preference)), error => dispatch(failure(error)))
            .catch(error => dispatch(failure(error)));
    };

};
userPreferenceActions.updateUserPreference = (user_preference) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: userPreferenceConstants.UPDATE_USER_PREFERENCE_REQUEST});
    const success = (user_preference) => ({type: userPreferenceConstants.UPDATE_USER_PREFERENCE_SUCCESS, user_preference});
    const failure = (error) => ({type: userPreferenceConstants.UPDATE_USER_PREFERENCE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request(user_preference));
        userPreferenceService.updateUserPreference(user_preference)
            .then(user_preference => {
                localStorage.setItem('user_preference', JSON.stringify(user_preference));
                return dispatch(success(user_preference));
            }, error => dispatch(failure(error)))
            .catch(error => dispatch(failure(error)));
    };
};
