// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from "../constants/";
import {handleResponse} from './responseHandler';

const apiUrl = baseUrl;

export const userService = {
    login: (email, password) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password})
        };

        return fetch(`${apiUrl}/auth/sign_in/`, requestOptions)
            .then(handleResponse)
            .then(
                /**
                 *
                 * @param user
                 * @param user.token
                 * @param user.user
                 * @param user.user.role_string
                 * @param user.user.
                 * @returns {function(*): void|*|Promise<never>}
                 */
                user => {
                    // login successful if there's a jwt token in the response
                    if (user.token) {
                        if (user.user.role_string) {
                            const _roles = user.user.role_string.split(',');
                            if (_roles.includes('Administrator') || _roles.includes('Manager') || _roles.includes('Staff') || _roles.includes('Payroll')) {
                                // store user details and jwt token in local storage to keep user logged in between page refreshes
                                let newUser = user.user;
                                newUser.token = user.token;
                                localStorage.setItem('user', JSON.stringify(newUser));
                            } else {
                                alert('You are not authorized to access this resource. This incident has been logged.');
                            }
                        } else {
                            if (user.user.roles.includes('Administrator') || user.user.roles.includes('Manager') || user.user.roles.includes('Staff') || user.user.roles.includes('Payroll')) {
                                // store user details and jwt token in local storage to keep user logged in between page refreshes
                                let newUser = user.user;
                                newUser.token = user.token;
                                localStorage.setItem('user', JSON.stringify(newUser));
                            } else {
                                alert('You are not authorized to access this resource. This incident has been logged.');
                            }
                        }
                    }
                    return user;
                });
    },

    updateAuth: (user) => {
        if (user.user.role_string) {
            const _roles = user.user.role_string.split(',');
            if (_roles.includes('Administrator') || _roles.includes('Manager') || _roles.includes('Staff') || _roles.includes('Payroll')) {
                let newUser = user.user;
                newUser.token = user.token;
                return localStorage.setItem('user', JSON.stringify(newUser));
            } else {
                return alert('You are not authorized to access this resource. This incident has been logged.');
            }
        } else {
            if (user.user.roles.includes('Administrator') || user.user.roles.includes('Manager') || user.user.roles.includes('Staff') || user.user.roles.includes('Payroll')) {
                let newUser = user.user;
                newUser.token = user.token;
                return localStorage.setItem('user', JSON.stringify(newUser));
            } else {
                return alert('You are not authorized to access this resource. This incident has been logged.');
            }
        }
    },

    updateInfo: (user) => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify(user)
        };

        return fetch(`${apiUrl}/user/info/` + user.id, requestOptions).then(handleResponse)
    },

    createUser: (user) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader('POST'),
            body: JSON.stringify(user)
        };

        return fetch(`${apiUrl}/user`, requestOptions).then(handleResponse)
    },

    updatePassword: (user) => {
        const user_id = localStorage.getItem('edit_user');
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify(user),
        };

        return fetch(`${apiUrl}/user/password/${user_id}`, requestOptions).then(handleResponse)
    },

    logout: () => {
        // remove user from local storage to log user out
        localStorage.clear();
        // window.location.href = '/#/login'
    },

    getAll: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };

        return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
    },

    getPag: (req) => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT')
        }
        const url = new URL(`${apiUrl}/users`);
        const {filters} = req;
        Object.keys(req).forEach(k => {
            if (k === 'filters') Object.keys(filters).forEach(k1 => filters[k1] !== '' && url.searchParams.append(k1, filters[k1]))
            else if (k === 'currentPage' && !isNaN(parseInt(req[k]) && !isNaN(parseInt(req.sizePerPage)))) {
                const page = parseInt(req[k]);
                const limit = parseInt(req.sizePerPage);
                const offset = ((page - 1) * limit).toString();
                url.searchParams.append('offset', offset);
            }
                else if (k === 'sizePerPage') url.searchParams.append('limit', req[k])
            else req[k] !== '' && url.searchParams.append(k, req[k]);
        });

        return fetch(url, requestOptions).then(handleResponse);
    },

    getDetail: (id) => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET'),
        };

        return fetch(`${apiUrl}/user/` + id, requestOptions).then(handleResponse);
    },

    getLoginExceptions: () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };

        return fetch(`${apiUrl}/exceptions/detail`, requestOptions).then(handleResponse);
    },

    importUsers: (data) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader('GET'),
            contentLength: data.size,
            body: data,
        };

        return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
    },
};
