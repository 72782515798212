import React, {Component} from 'react';
import {Route, Link, matchPath} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from './bot-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let routes = void 0;

let getPaths = function getPaths(pathname) {
    let paths = ['/'];

    if (pathname === '/') return paths;

    pathname.split('/').reduce(function (prev, curr) {
        let currPath = prev + '/' + curr;
        paths.push(currPath);
        return currPath;
    });
    return paths;
};

let findRouteName = function findRouteName(url) {
    let aroute = routes.find(function (route) {
        return matchPath(url, {path: route.path, exact: route.exact});
    });
    return aroute && aroute.name ? aroute.name : null;
};

/**
 * @return {null}
 */
let BreadcrumbsItem = function BreadcrumbsItem(_ref) {
    let match = _ref.match;

    let routeName = findRouteName(match.url);
    if (routeName) {
        return match.isExact ? React.createElement(
            BreadcrumbItem,
            {active: true},
            routeName
        ) : React.createElement(
            BreadcrumbItem,
            null,
            React.createElement(
                Link,
                {to: match.url || ''},
                routeName
            )
        );
    }
    return null;
};

BreadcrumbsItem.propTypes = process.env.NODE_ENV !== "production" ? {
    match: PropTypes.shape({
        url: PropTypes.string
    })
} : {};

let Breadcrumbs = function Breadcrumbs(args) {
    let paths = getPaths(args.location.pathname);
    let items = paths.map(function (path, i) {
        return React.createElement(Route, {key: i.toString(), path: path, component: BreadcrumbsItem});
    });
    return React.createElement(
        Breadcrumb,
        null,
        items
    );
};

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    appRoutes: PropTypes.any,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'div',
    className: '',
    appRoutes: [{path: '/', exact: true, name: 'Home', component: null}]
};

let AppBreadcrumb = function (_Component) {
    _inherits(AppBreadcrumb, _Component);

    function AppBreadcrumb(props) {
        _classCallCheck(this, AppBreadcrumb);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.state = {routes: props.appRoutes};
        routes = _this.state.routes;
        return _this;
    }

    AppBreadcrumb.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'tag']);

        delete attributes.children;
        delete attributes.appRoutes;

        let classes = classNames(className);

        return React.createElement(
            Tag,
            {className: classes},
            React.createElement(Route, _extends({path: '/:path', component: Breadcrumbs}, attributes))
        );
    };

    return AppBreadcrumb;
}(Component);

AppBreadcrumb.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppBreadcrumb.defaultProps = defaultProps;

export default AppBreadcrumb;
