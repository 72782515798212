import {jobStatusConstants} from '../constants';

export const job_status = (state = {}, action) => {
    switch (action.type) {
        case jobStatusConstants.DETAIL_JOB_STATUS_REQUEST:
            return {
                loading: true
            };
        case jobStatusConstants.DETAIL_JOB_STATUS_SUCCESS:
            return {
                items: action.job_status
            };
        case jobStatusConstants.DETAIL_JOB_STATUS_FAILURE:
            return {
                error: action.error
            };
        case jobStatusConstants.CREATE_JOB_STATUS_REQUEST:
            return {
                loading: true
            };
        case jobStatusConstants.CREATE_JOB_STATUS_SUCCESS:
            return {
                items: action.job_status
            };
        case jobStatusConstants.CREATE_JOB_STATUS_FAILURE:
            return {
                error: action.error
            };
        case jobStatusConstants.UPDATE_JOB_STATUS_REQUEST:
            return {
                loading: true
            };
        case jobStatusConstants.UPDATE_JOB_STATUS_SUCCESS:
            return {
                items: action.job_status
            };
        case jobStatusConstants.UPDATE_JOB_STATUS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const job_statuses = (state = {}, action) => {
    switch (action.type) {
        case jobStatusConstants.GET_ALL_JOB_STATUSES_REQUEST:
            return {
                loading: true
            };
        case jobStatusConstants.GET_ALL_JOB_STATUSES_SUCCESS:
            return {
                items: action.job_statuses
            };
        case jobStatusConstants.GET_ALL_JOB_STATUSES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
