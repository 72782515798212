// import config from 'config';
import {authHeader} from '../../helpers';
import {baseUrl} from "../constants";
import {handleResponse} from './responseHandler';

export const attachmentService = {};

const apiUrl = baseUrl;

attachmentService.createComment = (attachment) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(attachment)
    };

    return fetch(`${apiUrl}/image`, requestOptions).then(handleResponse);
};

attachmentService.updateComment = (attachment) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(attachment)
    };


    return fetch(`${apiUrl}/image/` + attachment.id, requestOptions).then(handleResponse);
};

attachmentService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/images`, requestOptions).then(handleResponse);
};

attachmentService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/image/` + id, requestOptions).then(handleResponse);
};
