export const partConstants = {
    CREATE_PART_REQUEST: 'PART_CREATE_REQUEST',
    CREATE_PART_SUCCESS: 'PART_CREATE_SUCCESS',
    CREATE_PART_FAILURE: 'PART_CREATE_FAILURE',

    UPDATE_PART_REQUEST: 'PART_UPDATE_REQUEST',
    UPDATE_PART_SUCCESS: 'PART_UPDATE_SUCCESS',
    UPDATE_PART_FAILURE: 'PART_UPDATE_FAILURE',

    GET_ALL_PARTS_REQUEST: 'PARTS_GET_ALL_REQUEST',
    GET_ALL_PARTS_SUCCESS: 'PARTS_GET_ALL_SUCCESS',
    GET_ALL_PARTS_FAILURE: 'PARTS_GET_ALL_FAILURE',

    DETAIL_PART_REQUEST: 'PART_DETAIL_REQUEST',
    DETAIL_PART_SUCCESS: 'PART_DETAIL_SUCCESS',
    DETAIL_PART_FAILURE: 'PART_DETAIL_FAILURE',
};
