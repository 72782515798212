import {yardConstants} from '../constants';
import {yardService} from '../services/';
import {jobActions} from './job.actions';

export const yardActions = {};
yardActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: yardConstants.GET_ALL_YARDS_REQUEST});
    const success = (yards) => ({type: yardConstants.GET_ALL_YARDS_SUCCESS, yards});
    const failure = (error) => ({type: yardConstants.GET_ALL_YARDS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        yardService.getAll()
            .then(yards => dispatch(success(yards.yards)), error => dispatch(failure(error)));
    };
};
yardActions.getYard = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: yardConstants.DETAIL_YARD_REQUEST});
    const success = (yard) => ({type: yardConstants.DETAIL_YARD_SUCCESS, yard});
    const failure = (error) => ({type: yardConstants.DETAIL_YARD_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        yardService.getYard(id)
            .then(yard => {
                    dispatch(success(yard));
                    if (yard) {
                        dispatch(jobActions.load(yard.jobs));
                        localStorage.setItem('edit_yard', yard.id);
                    }
                }, error => dispatch(failure(error)));
    };
};
yardActions.createYard = (yard) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: yardConstants.CREATE_YARD_REQUEST});
    const success = (yard) => ({type: yardConstants.CREATE_YARD_SUCCESS, yard});
    const failure = (error) => ({type: yardConstants.CREATE_YARD_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        yardService.createYard(yard)
            .then(yard => {
                dispatch(success(yard));
                dispatch(yardActions.getAll());
                if (yard) {
                    localStorage.setItem('edit_yard', yard.id);
                }
                }, error => dispatch(failure(error)));
    };
};
yardActions.updateYard = (yard) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: yardConstants.UPDATE_YARD_REQUEST});
    const success = (yard) => ({type: yardConstants.UPDATE_YARD_SUCCESS, yard});
    const failure = (error) => ({type: yardConstants.UPDATE_YARD_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        yardService.updateYard(yard)
            .then(yard => {
                dispatch(success(yard));
                dispatch(yardActions.getYard(yard.id));
                dispatch(yardActions.getAll());
                window.location.href = '/#/setup/job_sites';
                }, error => dispatch(failure(error)));
    };
};
yardActions.deleteYard = (yard) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: yardConstants.DELETE_YARD_REQUEST});
    const success = (yard) => ({type: yardConstants.DELETE_YARD_SUCCESS, yard});
    const failure = (error) => ({type: yardConstants.DELETE_YARD_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        yardService.deleteYard(yard)
            .then(yard => {
                dispatch(success(yard));
                }, error => dispatch(failure(error)));
    };
};
