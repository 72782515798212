import {attributeConstants} from '../constants/';

export const attributes = (state = {}, action) => {
    switch (action.type) {
        case attributeConstants.GET_ALL_ATTRIBUTES_REQUEST:
            return {
                loading: true
            };
        case attributeConstants.GET_ALL_ATTRIBUTES_SUCCESS:
            return {
                items: action.attributes
            };
        case attributeConstants.GET_ALL_ATTRIBUTES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const unit_attributes = (state = {}, action) => {
    switch (action.type) {
        case attributeConstants.GET_ALL_UNIT_ATTRIBUTES_REQUEST:
            return {
                loading: true
            };
        case attributeConstants.GET_ALL_UNIT_ATTRIBUTES_SUCCESS:
            return {
                items: action.unit_attributes
            };
        case attributeConstants.GET_ALL_UNIT_ATTRIBUTES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const class_attributes = (state = {}, action) => {
    switch (action.type) {
        case attributeConstants.GET_ALL_CLASS_ATTRIBUTES_REQUEST:
            return {
                loading: true
            };
        case attributeConstants.GET_ALL_CLASS_ATTRIBUTES_SUCCESS:
            return {
                items: action.class_attributes
            };
        case attributeConstants.GET_ALL_CLASS_ATTRIBUTES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const attribute = (state = {}, action) => {
    switch (action.type) {
        case attributeConstants.CREATE_ATTRIBUTE_REQUEST:
            return {
                loading: true
            };
        case attributeConstants.CREATE_ATTRIBUTE_SUCCESS:
            return {
                items: action.attribute
            };
        case attributeConstants.CREATE_ATTRIBUTE_FAILURE:
            return {
                error: action.error
            };
        case attributeConstants.DETAIL_ATTRIBUTE_REQUEST:
            return {
                loading: true
            };
        case attributeConstants.DETAIL_ATTRIBUTE_SUCCESS:
            return {
                items: action.attribute
            };
        case attributeConstants.DETAIL_ATTRIBUTE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
