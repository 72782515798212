import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader} from '../../components/bot-components';
import PlmAlertDetail from './PlmAlertDetail';
const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    plm_alert: PropTypes.object
};
const AlertModal = (props) => {
    return (
        <Modal size={'xl'} isOpen={props.isOpen} className={props.className} toggle={() => props.toggle()}>
            <ModalHeader toggle={() => props.toggle()}/>
            <ModalBody>
                <PlmAlertDetail plm_alert={props.plm_alert}/>
            </ModalBody>
        </Modal>
    );
};
AlertModal.propTypes = propTypes;
export default AlertModal;
