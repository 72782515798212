export const unitConstants = {
    CREATE_UNIT_REQUEST: 'UNIT_CREATE_REQUEST',
    CREATE_UNIT_SUCCESS: 'UNIT_CREATE_SUCCESS',
    CREATE_UNIT_FAILURE: 'UNIT_CREATE_FAILURE',

    UPDATE_UNIT_REQUEST: 'UNIT_UPDATE_REQUEST',
    UPDATE_UNIT_SUCCESS: 'UNIT_UPDATE_SUCCESS',
    UPDATE_UNIT_FAILURE: 'UNIT_UPDATE_FAILURE',

    GET_ALL_UNITS_REQUEST: 'UNITS_GET_ALL_REQUEST',
    GET_ALL_UNITS_SUCCESS: 'UNITS_GET_ALL_SUCCESS',
    GET_ALL_UNITS_FAILURE: 'UNITS_GET_ALL_FAILURE',

    DETAIL_UNIT_REQUEST: 'UNIT_DETAIL_REQUEST',
    DETAIL_UNIT_SUCCESS: 'UNIT_DETAIL_SUCCESS',
    DETAIL_UNIT_FAILURE: 'UNIT_DETAIL_FAILURE',

    DOT_REPORT_REQUEST: 'DOT_REPORT_REQUEST',
    DOT_REPORT_SUCCESS: 'DOT_REPORT_SUCCESS',
    DOT_REPORT_FAILURE: 'DOT_REPORT_FAILURE',
};
