import React, { Fragment, useRef } from 'react';
import { useDrop } from 'react-dnd';
import PmCheckItem from './PmCheckItem';

const checkListStyle = {
  width:'100%',
  height:'100%',
  display:'flex',
  flexWrap:'wrap'
};
const PmList = ( {
                   data,
                   list,
                   handleDrop,
                   path,
                   updatePhoto,
                   updatePhotoOnly,
                   updatePhotoRequired,
                   updatePhotoCount,
                   updateDetails,
                   updateDetailsOnly,
                   updateDetailsRequired,
                   removeCheck
                 } ) => {
  const ref = useRef( null );


  const [{}, drop] = useDrop( {
    type:'div',
    accept:'div',
    collect:( monitor ) => ({
      isOver:monitor.isOver()
    })
  } );

  drop( ref );

  const renderComponent = ( component, currentPath ) => {
    return (
      <PmCheckItem
        index={ component.sort_order }
        data={ component }
        path={ currentPath }
        updatePhoto={ updatePhoto }
        updatePhotoOnly={ updatePhotoOnly }
        updatePhotoRequired={ updatePhotoRequired }
        updatePhotoCount={ updatePhotoCount }
        updateDetails={ updateDetails }
        updateDetailsOnly={ updateDetailsOnly }
        updateDetailsRequired={ updateDetailsRequired }
        handleDrop={ handleDrop }
        removeCheck={ removeCheck }
        placeholder={ component.placeholder }
      />
    );
  };

  return (
    <div
      ref={ ref }
      style={ { ...checkListStyle } }
    >
      { data?.length > 0 ? data.map( ( component, index ) => {
        const currentPath = `${ path }-${ index }`;

        return (
          <Fragment key={ `${ component.pm_item_id }-${ component.included }` }>
            { renderComponent( component, currentPath ) }
          </Fragment>
        );
      } ) : list && <Fragment key={ '0000.empty' }>
        { renderComponent( { name:'Drag item to add', placeholder:true, sort_order:0 }, '' ) }
      </Fragment> }
    </div>
  );
};
export default PmList;
