import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

const defaultProps = {
    tag: 'div',
};

const ModalFooter = ({ className, cssModule, tag: Tag, ...attributes }) => {
    const classes = mapToCssModules(classNames(
        className,
        'modal-footer'
    ), cssModule);

    return (
        <Tag {...attributes} className={classes} />
    );
};

ModalFooter.propTypes = propTypes;
ModalFooter.defaultProps = defaultProps;

export default ModalFooter;
