import {reportConstants} from '../constants';

export const kpi_data = (state = {}, action) => {
  switch (action.type) {
    case reportConstants.KPI_DATA_REQUEST:
      return {
        loading: true
      };
    case reportConstants.KPI_DATA_SUCCESS:
      return {
        kpi_data: action.data
      };
    case reportConstants.KPI_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const workorder_report = (state = {}, action) => {
  switch (action.type) {
    case reportConstants.WORKORDER_REPORT_REQUEST:
      return {
        loading: true
      };
    case reportConstants.WORKORDER_REPORT_SUCCESS:
      return {
        workorder_report: action.data
      };
    case reportConstants.WORKORDER_REPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const maint_report_wo = (state = maint_report_wo, action) => {
  switch (action.type) {
    case reportConstants.MAINT_REPORT_WO_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MAINT_REPORT_WO_SUCCESS:
      return {
        maint_report: action.data
      };
    case reportConstants.MAINT_REPORT_WO_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const maint_report_task = (state = maint_report_task, action) => {
  switch (action.type) {
    case reportConstants.MAINT_REPORT_TASK_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MAINT_REPORT_TASK_SUCCESS:
      return {
        maint_report: action.data
      };
    case reportConstants.MAINT_REPORT_TASK_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const maint_report_wo_detail = (state = maint_report_wo_detail, action) => {
  switch (action.type) {
    case reportConstants.MAINT_REPORT_WO_DETAIL_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MAINT_REPORT_WO_DETAIL_SUCCESS:
      return {
        items: action.data
      };
    case reportConstants.MAINT_REPORT_WO_DETAIL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const maint_report_task_detail = (state = maint_report_task_detail, action) => {
  switch (action.type) {
    case reportConstants.MAINT_REPORT_TASK_DETAIL_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MAINT_REPORT_TASK_DETAIL_SUCCESS:
      return {
        maint_report: action.data
      };
    case reportConstants.MAINT_REPORT_TASK_DETAIL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
};

export const dot_inspection_exceptions = (state = {}, action) => {
  switch (action.type) {
    case reportConstants.DOT_INSPECTION_REPORT_REQUEST:
      return {
        loading: true
      };
    case reportConstants.DOT_INSPECTION_REPORT_SUCCESS:
      return {
        items: action.data
      };
    case reportConstants.DOT_INSPECTION_REPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
};

export const dot_task_exceptions = (state = {}, action) => {
  switch (action.type) {
    case reportConstants.DOT_TASK_REPORT_REQUEST:
      return {
        loading: true
      };
    case reportConstants.DOT_TASK_REPORT_SUCCESS:
      return {
        items: action.data
      };
    case reportConstants.DOT_TASK_REPORT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
};
