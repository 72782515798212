import { types } from './ImageViewerImage.actions';

export const initialState = {
    translateX: 0,
    translateY: 0,
    prevMouseX: 0,
    prevMouseY: 0,
    scale: 1,
    panning: false
};
const reducer = (state, action) => {
    let deltaMouseX;
    let deltaMouseY;
    let scaledTranslate;
    let mousePositionOnScreen;
    let zoomOffset;
    let _scaledTranslate;
    let _mousePositionOnScreen;
    let _zoomOffset;

    switch(action.type) {
        case types.RESET:
            return initialState;
        case types.PAN_START:
            return {
                ...state,
                panning: true,
                prevMouseX: action.clientX,
                prevMouseY: action.clientY,
            };
        case types.PAN:
            deltaMouseX = action.clientX - state.prevMouseX;
            deltaMouseY = action.clientY - state.prevMouseY;
            return {
                ...state,
                translateX: state.translateX + deltaMouseX,
                translateY: state.translateY + deltaMouseY,
                prevMouseX: action.clientX,
                prevMouseY: action.clientY,
            };
        case types.END_PAN:
            return {
                ...state,
                panning: false,
                prevMouseX: action.clientX,
                prevMouseY: action.clientY,
            };
        case types.ZOOM:
            scaledTranslate = getScaledTranslate(state, action.zoomFactor);
            mousePositionOnScreen = { x: action.clientX, y: action.clientY };
            zoomOffset = getZoomOffset(action.containerRect, mousePositionOnScreen, action.zoomFactor);
            return {
                ...state,
                scale: state.scale * action.zoomFactor,
                translateX: state.scale > 1 ? scaledTranslate.x + zoomOffset.x : 0,
                translateY: state.scale > 1 ? scaledTranslate.y + zoomOffset.y : 0,
            };
        case types.CLICK_ZOOM:
            _scaledTranslate = getScaledTranslate(state, action.zoomFactor);
            _mousePositionOnScreen = { x: action.clientX, y: action.clientY };
            _zoomOffset = getZoomOffset(action.containerRect, _mousePositionOnScreen, action.zoomFactor);
            return {
                ...state,
                scale: action.zoomFactor,
                translateX: action.zoomFactor === 1 ? 0 : _scaledTranslate.x + _zoomOffset.x,
                translateY: action.zoomFactor === 1 ? 0 : _scaledTranslate.y + _zoomOffset.y,
            };
        default:
            return state;
    }
};
const getZoomOffset = (containerRect, mousePositionOnScreen, zoomFactor) => {
    const zoomOrigin = {
        x: mousePositionOnScreen.x - containerRect.left,
        y: mousePositionOnScreen.y - containerRect.top,
    }
    const currentDistanceToCenter = {
        x: containerRect.width / 2 - zoomOrigin.x,
        y: containerRect.height / 2 - zoomOrigin.y,
    };
    const scaledDistanceToCenter = {
        x: currentDistanceToCenter.x * zoomFactor,
        y: currentDistanceToCenter.y * zoomFactor,
    }
    return {
        x:currentDistanceToCenter.x - scaledDistanceToCenter.x,
        y:currentDistanceToCenter.y - scaledDistanceToCenter.y,
    };
};
const getScaledTranslate = (state, zoomFactor) => ({
    x: state.translateX * zoomFactor,
    y: state.translateY * zoomFactor,
});

export default reducer;
