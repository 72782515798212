import React from 'react';
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from './index';
import Button from './bot-button';
import {createColumnMenu} from './bot-table-column-menu.helpers';
import PropTypes from 'prop-types';

const propTypes = {
    columnMenuOpen: PropTypes.bool,
    toggleColumnMenu: PropTypes.func,
    columns: PropTypes.array,
    panes: PropTypes.array,
    table_keys: PropTypes.func,
    changeHidden: PropTypes.func,
    setShowAll: PropTypes.func,
    resetTable: PropTypes.func
};

const ColumnMenu = ({columnMenuOpen, toggleColumnMenu, columns, panes, table_keys, changeHidden, setShowAll, resetTable}) => {
    return (
        <Dropdown isOpen={columnMenuOpen}
                  toggle={() => toggleColumnMenu()}
                  direction={'left'}
                  className={'bot-table-column-menu-button'}>
            <DropdownToggle className={'bot-table-column-menu-button'}>
                <i className="fa fa-align-center"  onClick={() => toggleColumnMenu()}/>
            </DropdownToggle>
            <DropdownMenu modifiers={{
                computeStyle: {
                    gpuAcceleration: false
                }
            }}>
                <DropdownItem className={'bot-table-column-menu-header'} header>
                    Hide Columns
                </DropdownItem>
                <div className={'bot-table-column-menu-controls'}>
                    <Col sm={12}>
                        {createColumnMenu(columns, panes, table_keys, changeHidden)}
                    </Col>
                </div>
                <Col sm={12}>
                    <Row>
                        <Button name={'show-all'} block outline color={'texts'}
                                onClick={() => setShowAll()}>
                            Show All
                        </Button>
                    </Row>
                    <Row>
                        <Button name={'reset-table'} block outline color={'texts'}
                                onClick={() => resetTable()}>Reset List</Button>
                    </Row>
                </Col>
            </DropdownMenu>
        </Dropdown>
    )
}

ColumnMenu.propTypes = propTypes;
export default ColumnMenu;
