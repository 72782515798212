import {
    roleActions,
    taskActions,
    taskPriorityActions,
    unitActions,
    vendorActions,
    workorderActions
} from '../../../network/actions';

export const mapStateToProps = (state) => {
    const {mechanics, plm_alert, unit, workorder, task_priorities, vendors, task, user_preference} = state;
    let taskStatuses = [false, true];
    let vendorList = vendors?.items?.map(v => ({text: v.name, label: v.name, value: v.id}));
    let mechanicList = mechanics?.items?.map(m => ({text: m.name, label: m.name, value: m.id}));
    let PriorityList = task_priorities?.items?.map(priority => ({text: priority.priority, label: priority.priority, value: priority.id}));
    return {workorder, taskStatuses, mechanicList, vendorList, PriorityList, plm_alert, unit, task, user_preference}
};

export const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (req) => dispatch(taskActions.getAll(req)),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
        updateTask: (task) => dispatch(taskActions.updateTask(task)),
        // getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
        getMechanics: () => dispatch(roleActions.getMechanics()),
        getVendors: () => dispatch(vendorActions.getAll()),
    };
};

export const setupData = (props) => {
    !props.task_priorities?.items && !props.task_priorities?.loading && props.getTaskPriorities();
    !props.mechanics?.items && !props.mechanics?.loading && props.getMechanics();
    !props.vendors?.items && !props.vendors?.loading && props.getVendors();
};
