import {callLogConstants} from '../constants/';
import {callLogService} from '../services/';
import {workorderActions} from "./";

export const callLogActions = {};
callLogActions.createCallLog = (call_log) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: callLogConstants.CREATE_CALL_LOG_REQUEST});
    const success = (call_log) => ({type: callLogConstants.CREATE_CALL_LOG_SUCCESS, call_log});
    const failure = (error) => ({type: callLogConstants.CREATE_CALL_LOG_FAILURE, error});
    //</editor-fold>
    const workorder_id = call_log.workorder_id;
    return dispatch => {
        dispatch(request());
        callLogService.createCallLog(call_log)
            .then(call_log => {
                dispatch(success(call_log));
                dispatch(workorderActions.getDetail(workorder_id))
            }, error => dispatch(failure(error)));
    };
};
