// noinspection JSValidateTypes,JSCheckFunctionSignatures

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    BotTable, Button, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    DropdownItem
} from '../../components/bot-components';

const propTypes = {
    ref: PropTypes.node,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.object,
    columns: PropTypes.object,
    getVendors: PropTypes.func,
    getVendor: PropTypes.func,
    canCreate: PropTypes.bool,
    lStorage: PropTypes.string,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    data: PropTypes.object,
    vendor: PropTypes.object,
    loading: PropTypes.bool,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    createVendor: PropTypes.func
};
const defaultFilters = {
    name: '',
    city: '',
    state: '',
    deleted: ''
};
const defaultSort = {
    sortName: '',
    sortOrder: '',
};
const defaultPaging = {
    currSize: 20,
    currPage: 1,
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    name: null,
    address: '',
    contact_name: '',
    city: '',
    state: '',
    zip_code: '',
    phone_number: '',
    deleted: '',
    ...defaultSort,
    ...defaultPaging,
    filters: myFilters
};
let table_key_iterator = 0;

const BotVendors = React.forwardRef(
    /**
     * @param props
     * @param props.remote
     * @param props.tblId
     * @param props.columns
     * @param props.getVendors
     * @param props.getVendor
     * @param props.canCreate
     * @param props.lStorage
     * @param props.showTitle
     * @param props.loading
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.createVendor
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        const [state, updateState] = React.useState({
            ...defaultState,
            newModal: false
        });
        const setState = newState => updateState((prevState) => ({
            ...prevState,
            ...newState
        }));

        const table_keys = () => {
            return `${props.tblId}_${table_key_iterator++}`;
        };
        const createNewBtn = () => {
            return (
                <DropdownItem outline color={'texts'} onClick={() => {
                    toggleNewModal();
                }}><i className={'fa fa-plus'}/> New</DropdownItem>
            );
        };
        const nameRef = React.useRef();
        const cityRef = React.useRef();
        const stateRef = React.useRef();
        const deletedRef = React.createRef();
        const toggleNewModal = () => setState({newModal: !state.newModal});
        const changeProps = (e) => {
            const {name, value} = e.target;
            setState({
                [name]: value
            });
        };
        const createVendor = () => {
            const _vendor = {
                name: state.name,
                address: state.address,
                contact_name: state.contact_name,
                city: state.city,
                state: state.state,
                zip_code: state.zip_code,
                phone_number: state.phone_number,
                deleted: false
            };
            props.createVendor(_vendor);
            toggleNewModal();
        };
        const refreshVendors = () => {
            const tbl = ref?.current;
            const req = {
                sortName: tbl?.sortName,
                sortOrder: tbl?.sortOrder,
                currentPage: tbl?.currPage,
                sizePerPage: tbl?.currSize,
                filters: tbl?.filters
            };
            props.remote ? props.getVendors(req) : props.getVendors();
        };

        const FilterDeletedBtn = () => (<DropdownItem outline color={'texts'}
                                                      className={ref?.current?.state?.filters?.deleted === true ? 'active' : ''}
                                                      key={table_keys()} onClick={() => {
            filterDeletedOnly();
        }}>Deleted Only</DropdownItem>);

        const filterDeletedOnly = () => {
            const filter = deletedRef.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter('');
        };

        const selects = ['deleted'];
        const getVendors = async (req) => {
            const qParams = _.cloneDeep(req);
            qParams?.filters && Object.keys(qParams.filters).forEach(k => {
                if ((selects.includes(k) || _.startsWith(k, 'bool_field')) && qParams.filters[k] !== '') qParams.filters[k] = JSON.stringify({
                    op: '=',
                    value: qParams.filters[k]
                });
            });
            await props.getVendors(qParams);
        };
        const {title, data, pageSizes} = props;
        const columns = [
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: defaultFilters.name
                },
                width: 500,
                hidden: false,
                refProp: nameRef,
                sortable: true
            },
            {
                field: 'city',
                text: 'City',
                filter: {
                    type: 'text',
                    placeholder: 'City',
                    defaultValue: defaultFilters.city
                },
                width: 200,
                hidden: false,
                refProp: cityRef,
                sortable: true
            },
            {
                field: 'state',
                text: 'State',
                filter: {
                    type: 'text',
                    placeholder: 'State',
                    defaultValue: defaultFilters.state
                },
                width: 180,
                hidden: false,
                refProp: stateRef,
                sortable: true
            },
            {
                field: 'deleted',
                text: 'Deleted',
                filter: {
                    type: 'select',
                    placeholder: 'deleted',
                    options: [{label: 'Yes', value: true}, {label: 'No', value: false}]
                },
                width: 150,
                hidden: false,
                format: cell => cell === true ? 'Yes' : 'No',
                refProp: deletedRef,
                sortable: true
            }
        ];
        return (
            <>
                <Modal isOpen={state.newModal} toggle={toggleNewModal}>
                    <ModalHeader toggle={toggleNewModal}>
                        Create new Service Provider
                    </ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>Name</InputGroupText></InputGroupAddon>
                            <Input name={'name'} value={state.name} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>Address</InputGroupText></InputGroupAddon>
                            <Input name={'address'} value={state.address} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>Contact</InputGroupText></InputGroupAddon>
                            <Input name={'contact_name'} value={state.contact_name} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>City</InputGroupText></InputGroupAddon>
                            <Input name={'city'} value={state.city} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>State</InputGroupText></InputGroupAddon>
                            <Input name={'state'} value={state.state} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>Zip</InputGroupText></InputGroupAddon>
                            <Input name={'zip_code'} value={state.zip_code} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon
                                addonType={'prepend'}><InputGroupText>Phone</InputGroupText></InputGroupAddon>
                            <Input name={'phone_number'} value={state.phone_number} onChange={changeProps}/>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color={'texts'} onClick={createVendor}>Save</Button><Button outline
                                                                                                    color={'texts'}
                                                                                                    onClick={toggleNewModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => getVendors(req)}
                          pageSizes={pageSizes}
                          ClearFiltersBtn
                          ClearFiltersIcon
                          ClearSortBtn
                          ClearSortIcon
                          CsvDownloadBtn
                          CsvDownloadIcon
                          CreateNewBtn={props.canCreate && createNewBtn}
                          pagination={'both'}
                          headers={true}
                          doRowUpdate={(wo) => console.log(wo)}
                          refreshData={() => refreshVendors()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={defaultFilters}
                          onRowDoubleClick={(row) => {
                              props.getVendor(row.id);
                          }}
                          onlyFilters={[FilterDeletedBtn]}
                          defaultState={defaultState}
                          lStorage={props.lStorage}
                          showTitle={props.showTitle}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                          selects={selects}/>
            </>
        );
    });

BotVendors.propTypes = propTypes;
export default BotVendors;
