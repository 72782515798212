import {taskPriorityConstants} from '../constants';

export const task_priority = (state = {}, action) => {
    switch (action.type) {
        case taskPriorityConstants.DETAIL_TASK_PRIORITY_REQUEST:
            return {
                loading: true
            };
        case taskPriorityConstants.DETAIL_TASK_PRIORITY_SUCCESS:
            return {
                items: action.task_priority
            };
        case taskPriorityConstants.DETAIL_TASK_PRIORITY_FAILURE:
            return {
                error: action.error
            };
        case taskPriorityConstants.CREATE_TASK_PRIORITY_REQUEST:
            return {
                loading: true
            };
        case taskPriorityConstants.CREATE_TASK_PRIORITY_SUCCESS:
            return {
                items: action.task_priority
            };
        case taskPriorityConstants.CREATE_TASK_PRIORITY_FAILURE:
            return {
                error: action.error
            };
        case taskPriorityConstants.UPDATE_TASK_PRIORITY_REQUEST:
            return {
                loading: true
            };
        case taskPriorityConstants.UPDATE_TASK_PRIORITY_SUCCESS:
            return {
                items: action.task_priority
            };
        case taskPriorityConstants.UPDATE_TASK_PRIORITY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const task_priorities = (state = {}, action) => {
    switch (action.type) {
        case taskPriorityConstants.GET_ALL_TASK_PRIORITIES_REQUEST:
            return {
                loading: true
            };
        case taskPriorityConstants.GET_ALL_TASK_PRIORITIES_SUCCESS:
            return {
                items: action.task_priorities
            };
        case taskPriorityConstants.GET_ALL_TASK_PRIORITIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
