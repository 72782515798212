import React, {useState} from 'react'
import {
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, SaveCancel
} from '../bot-components'

/**
 *
 * @param {boolean} isOpen
 * @param {Function} toggle()
 * @param {Object=} unitType
 * @param {string} unitType.utype
 * @param {Object=} unitSubtype
 * @param {string} unitSubtype.subtype
 * @param {Function} saveData
 * @returns {JSX.Element}
 * @constructor
 */
const CreateListModal = ({isOpen, toggle, unitType, unitSubtype, saveData}) => {
  const [name, setName] = useState('');
  const [UHours, setUHours] = useState(false);
  const [UMileage, setUMileage] = useState(false);
  const [mileage, setMileage] = useState(0);
  const [hours, setHours] = useState(0);
  const [mileageThreshold, setMileageThreshold] = useState(0);
  const [hoursThreshold, setHoursThreshold] = useState(0);
  const [pastDueMileageThreshold, setPastDueMileageThreshold] = useState(0);
  const [pastDueHoursThreshold, setPastDueHoursThreshold] = useState(0);
  const [startingHours, setStartingHours] = useState(0);
  const [startingMileage, setStartingMileage] = useState(0);

  const cancel = () => {
    setName('');
    setUHours(false);
    setUMileage(false);
    setHours(0);
    setMileage(0.00);
    setStartingHours(0.00);
    toggle(false);
  };

  const save = () => {
    const data = {
      name,
      use_hours: UHours,
      use_mileage: UMileage,
      hours_interval: hours,
      hours_threshold: hoursThreshold,
      past_due_hours_threshold: pastDueHoursThreshold,
      mileage_interval: mileage,
      mileage_threshold: mileageThreshold,
      past_due_mileage_threshold: pastDueMileageThreshold,
      starting_mileage: startingMileage,
      starting_hours: startingHours
    };
    saveData(data);
    cancel();
  }

  const mileageValid = UMileage ? mileage > 0 : UHours ? hours > 0 : false;
  const mileageThresholdValid = UMileage ? mileageThreshold > 0 : true;
  const pastDueMileageThresholdValid = UMileage ? pastDueMileageThreshold > 0 : true;
  const hoursValid = UHours ? hours > 0 : UMileage ? mileage > 0 : false;
  const hoursThresholdValid = UHours ? hoursThreshold > 0 : true;
  const pastDueHoursThresholdValid = UHours ? pastDueHoursThreshold > 0 : true;
  const nameValid = name.length > 0;
  const tValid = !!unitType || !!unitSubtype;
  const sthValid = startingHours >= 0;
  const stmValid = startingHours >= 0;
  const stValid = sthValid || stmValid;
  const canSave = nameValid && tValid && mileageValid && mileageThresholdValid && pastDueMileageThresholdValid && hoursValid && hoursThresholdValid && pastDueHoursThresholdValid && stValid;

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader>
        Create new List for {unitType?.utype ?? unitSubtype?.subtype}
      </ModalHeader>
      <ModalBody>
        <InputGroup>
          <InputGroupAddon addonType={'prepend'}>
            Name
          </InputGroupAddon>
          <Input type={'text'} onChange={e => setName(e.target.value)} invalid={!nameValid} valid={nameValid}
                 value={name}/>
        </InputGroup>
        <Row style={{marginLeft: 0, marginRight: 0, justifyContent: 'space-between'}}>
          <InputGroup className={'max-content'}>
            <InputGroupAddon addonType={'prepend'}>
              Use Hours
            </InputGroupAddon>
            <CustomInput addon
                         onChange={e => {
                           !e.target.checked && setHours(0);
                           setUHours(e.target.checked)
                         }}
                         invalid={!UHours && !UMileage}
                         checked={UHours}
                         id={'use_hours_checkbox'}
                         className={`custom-checkbox form-control ${(!UMileage && !UHours) ? 'is-invalid' : 'is-valid'}`}
                         type={'checkbox'}/>
          </InputGroup>
          {UHours && <InputGroup>
            <InputGroupAddon addonType={'prepend'}>
              Hours(Frequency)
            </InputGroupAddon>
            <Input type={'number'} min={0} step={10} onChange={e => setHours(e.target.value)} value={hours}
                   invalid={!hoursValid} valid={hoursValid}/>
          </InputGroup>}
          {UHours && <InputGroup>
            <InputGroupAddon addonType={'prepend'}>
              Hours(Threshold)
            </InputGroupAddon>
            <Input type={'number'} min={0} step={10} onChange={e => setHoursThreshold(e.target.value)} value={hoursThreshold}
                   invalid={!hoursThresholdValid} valid={hoursThresholdValid}/>
          </InputGroup>}
          {UHours && <InputGroup>
            <InputGroupAddon addonType={'prepend'}>
              Past Due Hours(Threshold)
            </InputGroupAddon>
            <Input type={'number'} min={0} step={10} onChange={e => setPastDueHoursThreshold(e.target.value)} value={pastDueHoursThreshold}
                   invalid={!pastDueHoursThresholdValid} valid={pastDueHoursThresholdValid}/>
          </InputGroup>}
          {UHours && <InputGroup>
            <InputGroupAddon addonType={'prepend'}>
              Starting Hours
            </InputGroupAddon>
            <Input type={'number'} min={0} step={10} onChange={e => setStartingHours(e.target.value)} value={startingHours}
                   invalid={!sthValid} valid={sthValid}/>
          </InputGroup>}
          <InputGroup className={'max-content'}>
            <InputGroupAddon addonType={'prepend'}>
              Use Mileage
            </InputGroupAddon>
            <CustomInput addon
                         onChange={e => {
                           !e.target.checked && setMileage(0);
                           setUMileage(e.target.checked);
                         }}
                         invalid={!UMileage && !UHours}
                         checked={UMileage}
                         id={'use_mileage_checkbox'}
                         className={`custom-checkbox form-control ${(!UMileage && !UHours) ? 'is-invalid' : 'is-valid'}`}
                         type={'checkbox'}
                         style={{marginLeft: '.5rem', marginTop: '.7rem'}}/>
          </InputGroup>
        </Row>
        {UMileage && <InputGroup>
          <InputGroupAddon addonType={'prepend'}>
            Mileage(Frequency)
          </InputGroupAddon>
          <Input type={'number'} min={0} step={10} onChange={e => setMileage(e.target.value)}
                 value={mileage} invalid={!mileageValid} valid={mileageValid}/>
        </InputGroup>}
        {UMileage && <InputGroup>
          <InputGroupAddon addonType={'prepend'}>
            Mileage(Threshold)
          </InputGroupAddon>
          <Input type={'number'} min={0} step={10} onChange={e => setMileageThreshold(e.target.value)} value={mileageThreshold}
                 invalid={!mileageThresholdValid} valid={mileageThresholdValid}/>
        </InputGroup>}
        {UMileage && <InputGroup>
          <InputGroupAddon addonType={'prepend'}>
            Past Due Mileage(Threshold)
          </InputGroupAddon>
          <Input type={'number'} min={0} step={10} onChange={e => setPastDueMileageThreshold(e.target.value)} value={pastDueMileageThreshold}
                 invalid={!pastDueMileageThresholdValid} valid={pastDueMileageThresholdValid}/>
        </InputGroup>}
        {UMileage && <InputGroup>
          <InputGroupAddon addonType={'prepend'}>
            Starting Mileage
          </InputGroupAddon>
          <Input type={'number'} min={0} step={10} onChange={e => setStartingMileage(e.target.value)} value={startingMileage}
                 invalid={!stmValid} valid={stmValid}/>
        </InputGroup>}
      </ModalBody>
      <ModalFooter>
        <SaveCancel onSaveClick={save} onCancelClick={cancel} saveDisabled={!canSave}/>
      </ModalFooter>
    </Modal>
  );
};

export default CreateListModal;