import {vendorConstants} from '../constants';
import {vendorService} from '../services';

export const vendorActions = {};
vendorActions.getAll = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: vendorConstants.GET_ALL_VENDORS_REQUEST});
    const success = (vendors) => ({type: vendorConstants.GET_ALL_VENDORS_SUCCESS, vendors});
    const failure = (error) => ({type: vendorConstants.GET_ALL_VENDORS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        vendorService.getAll(req)
            .then(vendors => dispatch(success(vendors)), error => dispatch(failure(error)));
    };
};
vendorActions.getVendor = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: vendorConstants.DETAIL_VENDOR_REQUEST});
    const success = (vendor) => ({type: vendorConstants.DETAIL_VENDOR_SUCCESS, vendor});
    const failure = (error) => ({type: vendorConstants.DETAIL_VENDOR_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        vendorService.getVendor(id)
            .then(vendor => {
                dispatch(success(vendor));
                vendor && localStorage.setItem('edit_vendor', vendor.id);
            }, error => dispatch(failure(error)));
    };
};
vendorActions.createVendor = (Vendor) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: vendorConstants.CREATE_VENDOR_REQUEST});
    const success = (vendor) => ({type: vendorConstants.CREATE_VENDOR_SUCCESS, vendor});
    const failure = (error) => ({type: vendorConstants.CREATE_VENDOR_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        vendorService.createVendor(Vendor)
            .then(vendor => {
                dispatch(success(vendor));
                dispatch(vendorActions.getAll());
                vendor && localStorage.setItem('edit_vendor', vendor.id);
                }, error => dispatch(failure(error)));
    };
};
vendorActions.updateVendor = (vendor) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: vendorConstants.UPDATE_VENDOR_REQUEST});
    const success = (vendor) => ({type: vendorConstants.UPDATE_VENDOR_SUCCESS, vendor});
    const failure = (error) => ({type: vendorConstants.UPDATE_VENDOR_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        vendorService.updateVendor(vendor)
            .then(vendor => {
                dispatch(success(vendor));
                vendor && dispatch(vendorActions.getAll());
                vendor && dispatch(vendorActions.getVendor(vendor.id));
                }, error => dispatch(failure(error)));
    };
};
