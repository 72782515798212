/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {_dateFormat} from '../../../helpers';
import classnames from "classnames";
import {
    Card,
    CardHeader,
    Col,
    Label,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent
} from '../../../components/bot-components';
import {mapDispatchToDetailProps, mapStateToDetailProps} from './BotUser.helpers';
import {getExtraFields} from '../shared.helpers';
import {getUserDetail} from './BotUser.helpers';
import UserEdit from '../UserEdit/UserEdit';
import ReduxBlockUi from 'react-block-ui';
import BotWorkOrders from '../../WorkOrders/BotWorkOrders/BotWorkOrders'

let defaultProps = {};
const UserDetail = (props) => {
    const {BotModules} = props;
    const [activeTab, toggle] = React.useState(!!BotModules?.find(m => m.name === 'workorders') ? localStorage.getItem('userTab') ?? '1' : '1');
    const edit_user = localStorage.getItem('edit_user') ? parseInt(localStorage.getItem('edit_user')) : null;
    const [edit, setEdit] = React.useState(false);
    const [extraFields, setFields] = React.useState({
        fields: undefined,
        enums: undefined,
        bools: undefined,
        _strings: undefined
    });
    if (props.user) {
        if (props.user.items) {
            if (edit_user && props.user.items.id !== edit_user) {
                !props.modal && props.getUser(edit_user);
            }
        } else if (!props.user.loading && edit_user) {
            !props.modal && props.getUser(edit_user);
        }
    }
    const cwoRef = React.useRef();
    const owoRef = React.useRef();

    React.useEffect(() => {
        getExtraFields().then((done, err) => {
            if (!err) {
                const fields = done;
                const enums = done.filter(d => {
                    if (d.field.startsWith('enum') && d.field !== d['table_header']) return d; else return null;
                });
                const _strings = done.filter(d => {
                    if (d.field.startsWith('string') && d.field !== d['table_header']) return d; else return null;
                });
                const bools = done.filter(d => {
                    if (d.field.startsWith('bool') && d.field !== d['table_header']) return d; else return null;
                });
                setFields({
                    fields: fields,
                    enums: enums,
                    _strings: _strings,
                    bools: bools
                });
            }
        });
        !!BotModules?.find(m => m.name === 'workorders') && props.getAllJobs();
        !!BotModules?.find(m => m.name === 'workorders') && props.getAllYards();
    }, []);

    React.useEffect(() => {
        localStorage.setItem('userTab', activeTab);
    }, [activeTab]);

    // React.useEffect(() => {
    //     if (props.user_preference.items?.bot_modules) {
    //         const mods = props.user_preference.items?.bot_modules?.filter(m => m.enabled);
    //         const WM = !!mods?.find(m => m.name === 'workorders');
    //         setWoModule(WM);
    //     }
    //
    // }, [props.user_preference])

    /**
     * @param user.items.nickname
     */
    UserDetail.defaultProps = props;

    const updateWO = wo => {
        props.updateWorkorder(wo);
        props.getUser(props.user?.items?.id);
    };

    const {user, userRoles, unit, workorder} = props;

    return (
        <ReduxBlockUi blocking={user?.loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
            <Card>
                <CardHeader>
                    <Row>
                        <h3>Employee Info</h3>{user?.items && !edit ?
                    <Label className={'link-label-2'} style={{fontSize: '14pt', paddingLeft: '5px', color: 'red'}} onClick={() => setEdit(!edit)}>Edit</Label> : user?.items && <Label className={'link-label-2'} style={{fontSize: '14pt', paddingLeft: '5px', color: 'red'}} onClick={() => setEdit(!edit)}>Cancel Edit</Label>}
                    </Row>
                </CardHeader>
                {!edit ? getUserDetail(user, userRoles, extraFields) : <UserEdit cancelUserEdit={() => setEdit(false)}/>}
            </Card>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({active: activeTab === '1'})} onClick={() => toggle('1')}>
                        Usage Info
                    </NavLink>
                </NavItem>
                {!!BotModules?.find(m => m.name === 'workorders') &&
                <>
                    <NavItem>
                        <NavLink className={classnames({active: activeTab === '2'})} onClick={() => toggle('2')}>
                            Completed Work Orders
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: activeTab === '3'})} onClick={() => toggle('3')}>
                            Current Work Orders
                        </NavLink>
                    </NavItem>
                </>}
            </Nav>
            <TabContent activetab={activeTab}>
                <TabPane tabId={'1'}>
                    {activeTab === '1' &&
                    <Card>
                        <Col sm={'12'}>
                            <Row className={'user-row'}>
                                <label className={'user-label'}>Number of times signed in:</label>
                                <label className={'user-field'}>{user && user.items && user.items['sign_in_count']}</label>
                            </Row>
                            <Row className={'user-row'}>
                                <label className={'user-label'}>Date last signed in:</label>
                                <label
                                    className={'user-field'}>{user && user.items && _dateFormat(user.items['current_sign_in_at'])}</label>
                            </Row>
                            <Row className={'user-row'}>
                                <label className={'user-label'}>IP last signed in from:</label>
                                <label
                                    className={'user-field'}>{user && user.items && user.items['current_sign_in_ip']}</label>
                            </Row>
                        </Col>
                    </Card>}
                </TabPane>
                {!!BotModules?.find(m => m.name === 'workorders') &&
                <>
                    <TabPane tabId={'2'}>
                        {activeTab === '2' &&
                        <BotWorkOrders ref={cwoRef}
                                       yardList={props.yardList}
                                       jobList={props.jobList}
                                       popoutAction
                                       viewAction
                                       actionCount={2}
                                       workorder={workorder}
                                       unit={unit}
                                       remote={false}
                                       updateWorkorder={(req) => updateWO(req)}
                                       tblId={'cwoRef'}
                                       pageSizes={['20', '50', '100', '250', '500', '1000']}
                                       title={'Completed Workorders'}
                                       data={user?.items?.['completed_workorders'] ?? []}
                            // getWorkorders={() => user?.items && props.getUser(user.items.id)}
                                       getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                                       getMyUnit={(id) => props.getMyUnit(id)}
                                       lStorage={'user_comp_work_order_storage'}
                                       csvFile={`${user?.items?.nickname}_complete_work_orders.csv`}
                                       u_preferences={props.user_preference}
                                       includeDateRange={false}
                                       getWorkorders={() => props.getUser(user?.items?.id)}/>}
                    </TabPane>
                    <TabPane tabId={'3'}>
                        {activeTab === '3' &&
                        <BotWorkOrders ref={owoRef}
                                       yardList={props.yardList}
                                       jobList={props.jobList}
                                       popoutAction
                                       viewAction
                                       actionCount={2}
                                       workorder={workorder}
                                       unit={unit}
                                       remote={false}
                                       updateWorkorder={(req) => props.updateWorkorder(req)}
                                       tblId={'owoRef'}
                                       pageSizes={['20', '50', '100', '250', '500', '1000']}
                                       title={'Currently assigned Work Orders'}
                                       data={user && user.items ? user.items['current_workorders'] : []}
                            // getWorkorders={() => user && user.items && props.getUser(user.items.id)}
                                       getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                                       getMyUnit={(id) => props.getMyUnit(id)}
                                       lStorage={'user_curr_work_order_storage'}
                                       csvFile={`${user?.items?.nickname}_current_work_orders.csv`}
                                       u_preferences={props.user_preference}
                                       includeDateRange={false}
                                       getWorkorders={() => props.getUser(user?.items?.id)}/>}
                    </TabPane>
                </>}
            </TabContent>
        </ReduxBlockUi>
    )
};

UserDetail.defaultProps = defaultProps;
export default connect(mapStateToDetailProps, mapDispatchToDetailProps)(UserDetail);
