import {purchaseOrderConstants} from '../constants/';
import {purchaseOrderService} from '../services/';
import {workorderActions} from "./";

export const purchaseOrderActions = {};
purchaseOrderActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_REQUEST});
    const success = (purchase_orders) => ({type: purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_SUCCESS, purchase_orders});
    const failure = (error) => ({type: purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        purchaseOrderService.getAll()
            .then(purchase_orders => dispatch(success(purchase_orders)), error => dispatch(failure(error)));
    };
};
purchaseOrderActions.getPurchaseOrder = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: purchaseOrderConstants.DETAIL_PURCHASE_ORDER_REQUEST});
    const success = (purchase_order) => ({type: purchaseOrderConstants.DETAIL_PURCHASE_ORDER_SUCCESS, purchase_order});
    const failure = (error) => ({type: purchaseOrderConstants.DETAIL_PURCHASE_ORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        purchaseOrderService.getPurchaseOrder(id)
            .then(purchase_order => dispatch(success(purchase_order)), error => dispatch(failure(error)));
    };
};
purchaseOrderActions.createPurchaseOrder = (purchase_order) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_REQUEST});
    const success = (purchase_order) => ({type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_SUCCESS, purchase_order});
    const failure = (error) => ({type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        purchaseOrderService.createPurchaseOrder(purchase_order)
            .then(purchase_order => {
                dispatch(success(purchase_order));
                dispatch(workorderActions.getDetail(purchase_order.workorders[0].id))
            }, error => dispatch(failure(error)));
    };
};
purchaseOrderActions.updatePurchaseOrder = (purchase_order) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_REQUEST});
    const success = (purchase_order) => ({type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_SUCCESS, purchase_order});
    const failure = (error) => ({type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        purchaseOrderService.updatePurchaseOrder(purchase_order)
            .then(purchase_order => {
                dispatch(success(purchase_order));
                dispatch(workorderActions.getDetail(purchase_order.workorders[0].id))
            }, error => dispatch(failure(error)));
    };
};
purchaseOrderActions.load = (purchase_orders) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_REQUEST});
    const success = (purchase_orders) => ({type: purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_SUCCESS, purchase_orders});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dispatch(success(purchase_orders));
    };
};
