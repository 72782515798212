import React, {useState, useEffect, useImperativeHandle, forwardRef} from 'react';
import {Row, Col} from './';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from './';
import PropTypes from 'prop-types';
const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number.isRequired,
    pageNeighbours: PropTypes.number.isRequired,
    onPageChanged: PropTypes.func.isRequired,
    changeSizePerPage: PropTypes.func.isRequired,
    pageSizes: PropTypes.array.isRequired,
    currPage: PropTypes.number
};
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
        range.push(i);
        i += step;
    }
    return range;
};

const Pagination = forwardRef(
    /**
     * @param props
     * @param props.currPage
     * @param props.pageLimit
     * @param props.totalRecords
     * @param props.pageNeighbours
     * @param props.dropup
     * @param props.changeSizePerPage
     * @param props.pageSizes
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        const [state, updateState] = useState({
            currentPage: props.currPage > 0 ? props.currPage : 1,
            sppOpen: false,
        });

        /**
         *
         * @param {Object} newState
         */
        const setState = newState => updateState(prevState => ({
            ...prevState,
            ...newState
        }));

        const { onPageChanged = f => f } = props;

        const gotoPage = page => setState({ currentPage: page });

        const handleClick = page => evt => {
            evt.preventDefault();
            gotoPage(page);
        };

        const handleMoveLeft = evt => {
            evt.preventDefault();
            gotoPage(state.currentPage - (state.pageNeighbours) - 1);
        };

        const handleMoveRight = evt => {
            evt.preventDefault();
            gotoPage(state.currentPage + (state.pageNeighbours) + 1);
        };

        const fetchPageNumbers = () => {
            const totalPages = state.totalPages;
            const currentPage = state.currentPage;
            const pageNeighbours = state.pageNeighbours;
            const totalNumbers = (state.pageNeighbours * 2) + 3;
            const totalBlocks = totalNumbers + 2;

            if (totalPages > totalBlocks) {
                const startPage = Math.max(2, currentPage - pageNeighbours) ?? 1;
                const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours) ?? 1;
                let pages = range(startPage, endPage) ?? 1;
                const hasLeftSpill = startPage > 2;
                const hasRightSpill = (totalPages - endPage) > 1;
                const spillOffset = totalNumbers - (pages.length + 1);
                let extraPages;
                switch (true) {
                    case (hasLeftSpill && !hasRightSpill):
                        extraPages = range(startPage - spillOffset, startPage - 1);
                        pages = [LEFT_PAGE, ...extraPages, ...pages];
                        break;
                    case (!hasLeftSpill && hasRightSpill):
                        extraPages = range(endPage + 1, endPage + spillOffset);
                        pages = [...pages, ...extraPages, RIGHT_PAGE];
                        break;
                    case (hasLeftSpill && hasRightSpill):
                    default:
                        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                        break;
                }
                return [1, ...pages, totalPages];
            }
            return range(1, totalPages);
        };

        const toggleSpp = () => setState({sppOpen: !state.sppOpen});

        useImperativeHandle(ref, () => ({
            gotoPage: (page) => gotoPage(page),
            state: state
        }));

        useEffect(() => {
            setState({
                currentPage: props.currPage > 0 ? props.currPage : 1
            })
        }, []);

        useEffect(() => {
            onPageChanged(state.currentPage);
        }, [state.currentPage]);

        useEffect(() => {
            gotoPage(props.currPage);
        }, [props.currPage]);

        useEffect(() => {
            const tr = typeof props.totalRecords === 'number' ? props.totalRecords : 0;
            const pl = typeof props.pageLimit === 'number' ? props.pageLimit : 20;
            setState({
                currentPage: props.currPage > 0 ? props.currPage : 1,
                pageLimit: pl,
                totalRecords: tr,
                pageNeighbours: typeof props.pageNeighbours === 'number' ? Math.max(0, Math.min(props.pageNeighbours, 2)) : 0,
                totalPages: Math.ceil(tr / pl) ?? 1
            });
        }, [props.totalRecords, props.pageLimit, props.currPage, props.pageNeighbours]);

        const { currentPage, sppOpen, pageLimit, totalRecords } = state;
        const pages = fetchPageNumbers();
        const startPoint = ((currentPage - 1) * pageLimit) + 1;
        const ep = currentPage * pageLimit;
        return (
            <Col className={'bot-pagination-group'}>
                <Row>
                    <Dropdown className={'bot-size-per-page'} direction={props.dropup === true ? 'up' : 'down'} isOpen={sppOpen} toggle={() => toggleSpp()}>
                        <DropdownToggle caret outline color={'texts'}>
                            {pageLimit}
                        </DropdownToggle>
                        <DropdownMenu>
                            {props.pageSizes.map(ps => {
                                if (ps === 'All') {
                                    return (
                                        <DropdownItem className={'bot-pagination-dropdown-item'} color={'texts'} outline key={`db_${ps}`} onClick={() => props.changeSizePerPage(parseInt(totalRecords))}>{ps}</DropdownItem>
                                    )
                                } else {
                                    return(
                                        <DropdownItem className={'bot-pagination-dropdown-item'} color={'texts'} outline key={`db_${ps}`} autoFocus={pageLimit === parseInt(ps)} active={pageLimit === parseInt(ps)} onClick={() => props.changeSizePerPage(parseInt(ps))}>{ps}</DropdownItem>
                                    )
                                }
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    <ul className="bot-pagination">
                        { pages.length > 0 ? pages.map((page, index) => {
                            if (page === LEFT_PAGE) return (
                                <li key={index} className="bot-page-item">
                                    <a className="bot-page-link" href="/#" aria-label="Previous" onClick={handleMoveLeft}>
                                        <span aria-hidden="true">&laquo; Skip</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                            );
                            if (page === RIGHT_PAGE) return (
                                <li key={index} className="bot-page-item">
                                    <a className="bot-page-link" href="/#" aria-label="Next" onClick={handleMoveRight}>
                                        <span aria-hidden="true">Skip &raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            );
                            return (
                                <li key={index} className={`bot-page-item${ currentPage === page ? ' active' : ''}`}>
                                    <a className="bot-page-link" href="/#" onClick={ handleClick(page) }>{ page }</a>
                                </li>
                            );
                        }) : <li key={0} className={`bot-page-item active`}>
                            <a className="bot-page-link" href="/#" onClick={ handleClick(1) }>1</a>
                        </li> }
                    </ul>
                </Row>
                <Row>
                    <label className={'bot-pag-records-label'}>From {totalRecords > 0 ? startPoint : 0} to {Math.min(ep, totalRecords)} of {totalRecords} total records</label>
                </Row>
            </Col>
        );
});

Pagination.propTypes = propTypes;
export default Pagination;
