import React from 'react';
import {BotTable, DropdownItem} from '../../../components/bot-components';
import {defaultFilters, defaultState, myFilters, propTypes, getColumns} from './BotUsers.constants';
import {filterActiveOnly} from './BotUsers.helpers';
import _ from 'lodash';

const BotUsers = React.forwardRef(
    /**
     * @param {object} props
     * @param props.extraColumns
     * @param props.tblId
     * @param props.getMyUser
     * @param props.columns
     * @param props.onUserSelected
     * @param props.showTitle
     * @param props.getUsers
     * @param props.loading
     * @param props.csvUrl
     * @param props.csvFile
     * @param props.remote
     * @param props.lStorage
     * @param props.user_preference
     * @param props.title
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const idRef = React.useRef();
    const nameRef = React.useRef();
    const empNumRef = React.useRef();
    const supRef = React.useRef();
    const fNameRef = React.useRef();
    const lNameRef = React.useRef();
    const userStatusRef = React.useRef();
    const dailyInspectionsRef = React.useRef();
    const phoneRef = React.useRef();
    const emailRef = React.useRef();
    const signCountRef = React.useRef();
    const lastSignRef = React.useRef();
    const jobRef = React.useRef();
    const yardRef = React.useRef();
    const rolesRef = React.useRef();
    const createdAtRef = React.useRef();
    const updatedAtRef = React.useRef();
    const columnRefs = [idRef, nameRef, empNumRef, supRef, fNameRef, lNameRef, userStatusRef, dailyInspectionsRef,
        phoneRef, emailRef, signCountRef, lastSignRef, jobRef, yardRef, rolesRef, createdAtRef, updatedAtRef];
    const extraColumnRefs = {};
    let table_key_iterator = 0;
    props.extraColumns?.map(c => {
        extraColumnRefs[`${c.field}Ref`] = React.createRef();
        defaultFilters[c.field] = "";
        myFilters[c.field] = "";
        defaultState.filters[c.field] = "";
        return c.refProp = extraColumnRefs[`${c.field}Ref`];
    });

    const selects = ['dot', 'user_status_id', 'daily_inspections'];
    const getUsers = async (req) => {
        const qParams = _.cloneDeep(req);
        await Object.keys(qParams.filters).forEach(k => {
            if ((selects.includes(k) || _.startsWith(k, 'bool_field')) && qParams.filters[k] !== '') qParams.filters[k] = JSON.stringify({op: "=", value: qParams.filters[k]});
        });
        props.getUsers(qParams);
    };

    const table_keys = () => `${props.tblId}_${table_key_iterator++}`;

    const FilterActiveBtn = () => (<DropdownItem outline color={'texts'} className={ref?.current?.state?.filters?.user_status_id === 1 ? 'active' : ''} key={table_keys()} onClick={() => {filterActiveOnly(userStatusRef)}}>Active Only</DropdownItem>);

    const FilterActiveIcon = () => {
        let activeFilter = ref?.current?.state?.filters?.user_status_id;
        let onlyActiveDirty = activeFilter === 1 || activeFilter === 2 || activeFilter === '1' || activeFilter === '2';
        return (<span className={'clear-dot-icon'}><span onClick={() => filterActiveOnly(userStatusRef)}>Active</span><span><i className={'clear-icon'} hidden={!onlyActiveDirty} key={table_keys()} onClick={() => {filterActiveOnly(userStatusRef)}}/></span></span>)
    };

    const {title, data, pageSizes} = props;
    const columns = getColumns(props.columns, props.extraColumns, defaultFilters, columnRefs, props.getMyUser, props.onUserSelected);
    return(
        <BotTable ref={ref}
                  columns={columns}
                  title={title}
                  showTitle={props.showTitle}
                  data={data}
                  getData={(req) => getUsers(req)}
                  defaultState={defaultState}
                  loading={props.loading}
                  pageSizes={pageSizes}
                  CsvDownloadBtn={true}
                  CsvDownloadIcon={true}
                  csvFile={props.csvFile}
                  csvUrl={props.csvUrl}
                  pagination={'both'}
                  headers={true}
                  btnGroupDirection={'left'}
                  remote={props.remote}
                  defaultFilters={defaultFilters}
                  onlyFilters={[FilterActiveBtn]}
                  onlyFiltersIcons={[FilterActiveIcon]}
                  onRowDoubleClick={(row) => {
                              localStorage.setItem('edit_user', row.id);
                              props.getMyUser(row.id);
                              props.onUserSelected();
                          }}
                  lStorage={props.lStorage}
                  user_preference={props.user_preference}
                  selects={selects}
        />
    )
})

BotUsers.propTypes = propTypes;
export default BotUsers;
