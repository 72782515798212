import TableActions from '../../../containers/table_action_component';
import React from 'react';

export const editButton = (cell, row, props, toggleTaskModal) => {
    return(
        <TableActions row={row} edit={props.editAction} view={props.viewAction}
                      popout={props.popoutAction} alert={props.alertAction}
                      onEdit={() => {props.getMyTask(row.id); window.location.href = '/#/workorders/tasks/detail/edit';}}
                      onView={() => {props.getMyTask(row.id); window.location.href = '/#/workorders/tasks/detail';}}
                      onPopout={() => {props.getMyTask(row.id); toggleTaskModal()}}
        />
    )
};
