import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from '../../components/bot-components';

const ConfirmationModal = ({isOpen, type, item, finalize, cancel}) => {

    return item ? (
        <Modal isOpen={isOpen}>
            <ModalBody>
                Are you sure you want to {type === 'add' ? 'add' : 'remove'} {item.name}?
            </ModalBody>
            <ModalFooter>
                <Button color={'texts'} outline onClick={() => finalize(item)}>Yes</Button>
                <Button color={'texts'} outline onClick={cancel}>No</Button>
            </ModalFooter>
        </Modal>
    ) : <></>
};

export default ConfirmationModal;
