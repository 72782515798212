import {hasRoles} from '../../../helpers';
import {companyRoleActions, jobActions, roleActions, userActions, yardActions} from '../../../network/actions';
import React from 'react';
import {getEnums, getExtraFields} from '../shared.helpers';
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from '../../../components/bot-components';
import {notEmptyText, validateUniqueEmail, validateUniqueEmpNum} from '../../../helpers';

export const saveUser = (state, props, enums, _strings, bools) => {
    let roles = [];
    state.selectedRoles.map(r => {
        return roles.push(r.value);
    });

    const buff = new Buffer(state.password);
    const pass = buff.toString('base64');
    const conBuff = new Buffer(state.password_confirmation);
    const passCon = conBuff.toString('base64');
    const User = {
        roles: roles,
        first_name: state.first_name,
        last_name: state.last_name,
        phone_number: state.phone_number,
        email: state.user_email,
        employee_number: state.user_number,
        user_status_id: state.user_status_id,
        job_id: state.job_id,
        yard_id: state.yard_id,
        password: pass,
        password_confirmation: passCon,
        daily_inspections: state.daily_inspections
    };
    enums?.map(e => User[e.field] = state[e.field]!== undefined ? state[e.field].value : '');
    _strings?.map(s => User[s.field] = state[s.field]!== undefined ? state[s.field] : null);
    bools?.map(b => User[b.field] = state[b.field] !== undefined ? state[b.field] : false);
    if (User.password_confirmation === User.password) {
        props.createUser(User);
        props.toggle();
    } else {
        alert('Passwords do not match!');
    }
};
export const mapStateToProps = (state) => {
    const {roles, jobs, yards, authentication} = state;
    const user_statuses = [{key: 1, id: 1, status: 'Active'}, {key: 1, id: 2, status: 'Inactive'}];
    const Admin = hasRoles(['Administrator'], authentication.user?.user);
    const Super = hasRoles(['SuperAdmin'], authentication.user?.user);
    const adminRoles = ['Payroll', 'Manager'];
    const superRoles = ['Administrator', 'SuperAdmin'];
    const roleList = roles?.items?.map(r =>
        (adminRoles.includes(r.authority)) ? (Admin || Super) && ({label: r.authority, value: r.id}) :
        (superRoles.includes(r.authority)) ? Super && ({label: r.authority, value: r.id}) :
        ({label: r.authority, value: r.id})
    );
    console.log(jobs.items);
    console.log(yards.items);
    const jobList = jobs?.items?.map(j => !(j.deleted) && ({key: j.id, label: `${j?.name} -- ${j.number}(${j.yard?.name})`, value: j.id})).filter(j => j!==false);
    const yardList = yards?.items?.map(y => !(y.deleted) && ({key: y.id, label: y.name, value: y.id})).filter(y => y !== false);
    const statusList = user_statuses?.map(us => ({label: us.status, value: us.id}));
    console.log(jobList);

    return {roles, jobList, roleList, user_statuses, statusList, yardList, authentication};
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getRoles: () => dispatch(roleActions.getRoles()),
        createUser: (user) => dispatch(userActions.createUser(user)),
        getJobs: () => dispatch(jobActions.getAll()),
        getYards: () => dispatch(yardActions.getAll()),
        getCompRoles: () => dispatch(companyRoleActions.getAll())
    }
};
export const setupData = (props, setState, setEnums, setStrings, setBools) => {
    props.getRoles();
    props.getJobs();
    props.getYards();
    getEnums('enum_field_1').then((done, err) => !err && setState({enum1: done}));
    getEnums('enum_field_2').then((done, err) => !err && setState({enum2: done}));
    getEnums('enum_field_3').then((done, err) => !err && setState({enum3: done}));
    getEnums('enum_field_4').then((done, err) => !err && setState({enum3: done}));
    getExtraFields().then((done, err) => {
        if (!err) {
            setState({fields: done});
            setEnums(done.filter(d => (d.field.startsWith('enum') && d.field !== d['table_header']) ? d : null));
            setStrings(done.filter(d => (d.field.startsWith('string') && d.field !== d['table_header']) ? d : null));
            setBools(done.filter(d => (d.field.startsWith('bool') && d.field !== d['table_header']) ? d : null));
        }
    });
};
export const setupExtraFields = (authentication, bools, _strings, enums, state, setState, changeEnumValues, changeTextField, table_keys) => (<>
    {hasRoles(['Administrator'], authentication.user?.user) &&
    <Row>
        <Col sm={6}>
            {bools?.map((e, index) => {
                if (index % 2 === 0) {
                    return (<InputGroup key={table_keys()}>
                        <InputGroupAddon key={table_keys()} addonType={'prepend'}>
                            <InputGroupText>{e['table_header']}</InputGroupText>
                        </InputGroupAddon>
                        <Input key={table_keys()} style={{marginLeft: '.5rem', marginTop: '.7rem'}} addon type={'checkbox'} name={e.field}
                               checked={state[e.field]} onChange={() => setState({[e.field]: !state[e.field]})}/>
                    </InputGroup>)
                } else return null;
            })}
        </Col>
        <Col sm={6}>
            {bools?.map((e, index) => {
                if (index % 2 !== 0) {
                    return (
                        (<InputGroup key={table_keys()}>
                            <InputGroupAddon key={table_keys()} addonType={'prepend'}>
                                <InputGroupText>{e['table_header']}</InputGroupText>
                            </InputGroupAddon>
                            <Input key={table_keys()} style={{marginLeft: '.5rem', marginTop: '.7rem'}} addon type={'checkbox'} name={e.field}
                                   checked={state[e.field]} onChange={() => setState({[e.field]: !state[e.field]})}/>
                        </InputGroup>)
                    )
                } else return null;
            })}
        </Col>
    </Row>}
    {hasRoles(['Administrator'], authentication.user?.user) &&
    <Row>
        <Col sm={6}>
            {enums?.map((e, index) => (index % 2 === 0) ? (
                    <>
                        <InputGroup key={table_keys()}>
                            <InputGroupAddon key={table_keys()} addonType={'prepend'}><InputGroupText>{e['table_header']}</InputGroupText></InputGroupAddon>
                            <Input key={table_keys()} type={'react-select'}
                                   options={state[`enum${(index + 1)}`] && state[`enum${(index + 1)}`].map(cr => ({label: cr, value: cr}))}
                                   placeholder={`Select ${e['table_header']}.`}
                                   matchProp={'label'}
                                   value={state[e.field]}
                                   style={{borderColor: !state[e.field] && 'var(--red)'}}
                                   onChange={(selectedValue) => changeEnumValues(e.field, selectedValue, setState)}/>
                        </InputGroup>
                        {!state[e.field] &&
                        <small key={table_keys()} style={{color: 'var(--red)'}}>*You must select a {e['table_header']}.</small>}
                    </>
                ) : null
            )}
            {_strings?.map((s, index) => (index % 2 === 0) ? (
                <>
                    <InputGroup key={table_keys()}>
                        <InputGroupAddon key={table_keys()} addonType={'prepend'}><InputGroupText>{s.table_header}</InputGroupText></InputGroupAddon>
                        <Input key={table_keys()} type={'text'} name={s.field} value={state[s.field]}
                               onChange={(e) => changeTextField(e, setState)} invalid={state[`${s.field}_valid`] === false}/>
                    </InputGroup>
                    {state[`${s.field}_valid`] === false &&
                    <small key={table_keys()} style={{color: 'var(--red)'}}>*You must enter a {s['table_header']}.</small>}
                </>
                ) : null
            )}
        </Col>
        <Col sm={6}>
            {enums?.map((e, index) => (index % 2 !== 0) ? (
                <>
                    <InputGroup key={table_keys()}>
                        <InputGroupAddon key={table_keys()} addonType={'prepend'}><InputGroupText>{e['table_header']}</InputGroupText></InputGroupAddon>
                        <Input key={table_keys()} type={'react-select'}
                               options={state[`enum${(index + 1)}`] && state[`enum${(index + 1)}`].map(cr => ({label: cr, value: cr}))}
                               placeholder={`Select ${e['table_header']}.`}
                               matchProp={'label'}
                               value={state[e.field]}
                               style={{borderColor: !state[e.field] && 'var(--red)'}}
                               onChange={(selectedValue) => changeEnumValues(e.field, selectedValue, setState)}/>
                    </InputGroup>
                    {!state[e.field] &&
                    <small key={table_keys()} style={{color: 'var(--red)'}}>*You must select a {e['table_header']}.</small>}
                </>
                ) : null
            )}
            {_strings?.map((s, index) => (index % 2 !== 0) ? (
                    <>
                        <InputGroup key={table_keys()}>
                            <InputGroupAddon key={table_keys()} addonType={'prepend'}><InputGroupText>{s.table_header}</InputGroupText></InputGroupAddon>
                            <Input key={table_keys()} type={'text'} name={s.field} value={state[s.field]}
                                   onChange={(e) => changeTextField(e, setState)} invalid={state[`${s.field}_valid`] === false}/>
                        </InputGroup>
                        {state[`${s.field}_valid`] === false &&
                        <small key={table_keys()} style={{color: 'var(--red)'}}>*You must enter a {s['table_header']}.</small>}
                    </>
                ) : null
            )}
        </Col>
    </Row>}
</>);
export const changeDailyInspections = (setState, state) => {
    setState({
        daily_inspections: !state.daily_inspections
    });
};
export const changeProps = (e, setState) => {
    const {value, name} = e.target;
    setState({
        [name]: value,
    });
};
export const changeTextField = (e, setState) => {
    const {value, name} = e.target;
    setState({
        [name]: value,
        [`${name}_valid`]: notEmptyText(value)
    });
};
export const changeEmail = (e, setState) => {
    const {value, name} = e.target;
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    setState({
        [name]: value,
        [`${name}_valid`]: reg.test(value)
    });
};
export const finalizeEmail = async (e, setState) => {
    const {value} = e.target;
    setState({
        user_email_unique: await validateUniqueEmail(value, null),
        user_email: value
    });
};
export const changeEmpNum = (e, setState) => {
    const {value} = e.target;
    setState({
        user_number: value,
        user_number_exists: notEmptyText(value)
    });
};
export const finalizeEmpNum = async (e, setState) => {
    const {value} = e.target;
    setState({
        user_number_unique: await validateUniqueEmpNum(value, null),
        user_number: value
    });
};
export const changePassword = (e, state, setState) => {
    const {value} = e.target;
    setState({
        password: value,
        password_exists: value !== '' && value !== null && value !== undefined,
        passwords_match: value === state.password_confirmation
    });
};
export const changePasswordConfirmation = (e, state, setState) => {
    const {value} = e.target;
    setState({
        password_confirmation: value,
        password_confirmation_exists: value !== null && value !== '' && value !== undefined,
        passwords_match: value === state.password
    });
};
export const changeEnumValues = (name, value, setState) => {
    setState({
        [name]: value,
        [`${name}_valid`]: value ? notEmptyText(value.value) : false
    });
};
export const cancelUserCreate = (props) => {
    props.toggle();
};
