/*Handles all actions for the part model on the server*/
import {partConstants} from '../constants';
import {partService} from '../services';
import {unitActions, workorderActions} from "./";

export const partActions = {};
/*Get full list of parts request.*/
partActions.getParts = () => {
    return dispatch => {
        dispatch(request());

        partService.getAll()
            .then(
                parts => {
                    dispatch(success(parts))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: partConstants.GET_ALL_PARTS_REQUEST}
    }

    function success(parts) {
        return {type: partConstants.GET_ALL_PARTS_SUCCESS, parts}
    }

    function failure(error) {
        return {type: partConstants.GET_ALL_PARTS_FAILURE, error}
    }

    //</editor-fold>
};
/*Get part detail request.*/
partActions.getPart = (id) => {
    return dispatch => {
        dispatch(request());

        partService.getDetail(id)
            .then(
                part => {
                    dispatch(success(part))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: partConstants.DETAIL_PART_REQUEST}
    }

    function success(part) {
        return {type: partConstants.DETAIL_PART_SUCCESS, part}
    }

    function failure(error) {
        return {type: partConstants.DETAIL_PART_FAILURE, error}
    }

    //</editor-fold>
};
/*Create new part request*/
partActions.createPart = (part) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after part is created.
        partService.createPart(part)
            .then(
                part => {
                    dispatch(success(part));
                    if (part.unit_id) {
                        dispatch(unitActions.getUnit(part.unit_id))
                    }
                    if (part.workorder_id) {
                        dispatch(workorderActions.getDetail(part.workorder_id))
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: partConstants.CREATE_PART_REQUEST}
    }

    function success(part) {
        return {type: partConstants.CREATE_PART_SUCCESS, part}
    }

    function failure(error) {
        return {type: partConstants.CREATE_PART_FAILURE, error}
    }

    //</editor-fold>
};
/*Update part request.*/
partActions.updatePart = (part) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after part is updated.
        partService.updatePart(part)
            .then(
                part => {
                    dispatch(success(part));
                    if (part.unit_id) {
                        dispatch(unitActions.getUnit(part.unit_id))
                    }
                    if (part.workorder_id) {
                        dispatch(workorderActions.getDetail(part.workorder_id))
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: partConstants.UPDATE_PART_REQUEST}
    }

    function success(part) {
        return {type: partConstants.UPDATE_PART_SUCCESS, part}
    }

    function failure(error) {
        return {type: partConstants.UPDATE_PART_FAILURE, error}
    }

    //</editor-fold>
};
