// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const jobService = {};

const apiUrl = baseUrl;

jobService.createJob = (job) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(job)
    };

    return fetch(`${apiUrl}/job`, requestOptions).then(handleResponse)
};

jobService.updateJob = (job) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(job)
    };

    return fetch(`${apiUrl}/job/${job.id}`, requestOptions).then(handleResponse);
};

jobService.deleteJob = (job) => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader('PUT'),
        body: JSON.stringify(job)
    };

    return fetch(`${apiUrl}/job/${job.id}`, requestOptions).then(handleResponse);
};

jobService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/jobs`, requestOptions).then(handleResponse);
};

jobService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/job/` + id, requestOptions).then(handleResponse);
};
