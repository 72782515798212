export const unitPmConstants = {
    CREATE_UNIT_PM_REQUEST: 'UNIT_PM_CREATE_REQUEST',
    CREATE_UNIT_PM_SUCCESS: 'UNIT_PM_CREATE_SUCCESS',
    CREATE_UNIT_PM_FAILURE: 'UNIT_PM_CREATE_FAILURE',

    GET_ALL_UNIT_PMS_REQUEST: 'UNIT_PMS_GET_ALL_REQUEST',
    GET_ALL_UNIT_PMS_SUCCESS: 'UNIT_PMS_GET_ALL_SUCCESS',
    GET_ALL_UNIT_PMS_FAILURE: 'UNIT_PMS_GET_ALL_FAILURE',

    UPDATE_UNIT_PM_REQUEST: 'UNIT_PM_UPDATE_REQUEST',
    UPDATE_UNIT_PM_SUCCESS: 'UNIT_PM_UPDATE_SUCCESS',
    UPDATE_UNIT_PM_FAILURE: 'UNIT_PM_UPDATE_FAILURE',

    DETAIL_UNIT_PM_REQUEST: 'UNIT_PM_DETAIL_REQUEST',
    DETAIL_UNIT_PM_SUCCESS: 'UNIT_PM_DETAIL_SUCCESS',
    DETAIL_UNIT_PM_FAILURE: 'UNIT_PM_DETAIL_FAILURE',
};
