import React from 'react';
import { Button } from './index';

const SaveCancel = ( {
                       onSaveClick,
                       saveDisabled,
                       onCancelClick,
                       cancelDisabled,
                       paddingTop,
                       paddingBottom,
                       otherStyles
                     } ) => {
  const style = {
    paddingTop,
    paddingBottom,
    ...otherStyles
  };
  return (
    <div className={ 'save-cancel' } style={ style }>
      <Button className={ 'save-btn' }
              onClick={ onSaveClick }
              disabled={ saveDisabled }
      >Save</Button>
      <Button className={ 'cancel-btn' }
              onClick={ onCancelClick }
              disabled={ cancelDisabled }
      >Cancel</Button>
    </div>
  );
};

export default SaveCancel;
