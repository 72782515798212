// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants/';
import {handleResponse} from './responseHandler';

export const reportService = {};

const apiUrl = baseUrl;

reportService.getKPIData = (date) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: date ? JSON.stringify({date: date}) : null
  };

  return fetch(`${apiUrl}/kpi_data`, requestOptions).then(handleResponse);
};

reportService.getWorkorderReport = () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader('GET')
  };

  return fetch(`${apiUrl}/workorders/by_yard`, requestOptions).then(handleResponse);
};

reportService.getMaintReportWo = (month) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: month ? JSON.stringify({month: month}) : null
  };

  return fetch(`${apiUrl}/reports/status/wo`, requestOptions).then(handleResponse);
};

reportService.getMaintReportTask = (month) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: month ? JSON.stringify({month: month}) : null
  };

  return fetch(`${apiUrl}/reports/status/task`, requestOptions).then(handleResponse);
};

reportService.getMaintReportWoDetail = (body) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/status/wo_detail`, requestOptions).then(handleResponse);
};

reportService.getMaintReportTaskDetail = (body) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/status/task_detail`, requestOptions).then(handleResponse);
};

reportService.getInsReport = (body) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/inspections3/insRepKey`, requestOptions).then(handleResponse);
};

reportService.getForeReport = (body) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/foreman/`, requestOptions).then(handleResponse);
};

reportService.getWorkOrderByJobReport = (body) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/work_order/`, requestOptions).then(handleResponse);
};

reportService.getDotInspectionExceptions = (body) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(body)
  };

  return fetch(`${apiUrl}/reports/dot_inspections/`, requestOptions).then(handleResponse);
};

reportService.getDotTaskExceptions = (req) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader('PUT'),
    body: JSON.stringify(req)
  };

  return fetch(`${apiUrl}/reports/dot_tasks/`, requestOptions).then(handleResponse);
};
