import { unitTypeConstants } from '../constants/';
import { unitTypeService } from '../services/';
// import {attributeActions} from './attribute.actions';

export const unitTypeActions = {};
unitTypeActions.getAll = () => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitTypeConstants.GET_ALL_UNIT_TYPES_REQUEST });
  const success = ( unit_types ) => ({ type:unitTypeConstants.GET_ALL_UNIT_TYPES_SUCCESS, unit_types });
  const failure = ( error ) => ({ type:unitTypeConstants.GET_ALL_UNIT_TYPES_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitTypeService.getAll()
      .then( unit_types => dispatch( success( unit_types ) ), error => dispatch( failure( error ) ) );
  };
};
unitTypeActions.getType = ( id ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitTypeConstants.DETAIL_UNIT_TYPE_REQUEST });
  const success = ( unit_type ) => ({ type:unitTypeConstants.DETAIL_UNIT_TYPE_SUCCESS, unit_type });
  const failure = ( error ) => ({ type:unitTypeConstants.DETAIL_UNIT_TYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    if (id) {
      dispatch( request() );
      unitTypeService.getType( id )
        .then( unit_type => {
          dispatch( success( unit_type ) );
        }, error => dispatch( failure( error ) ) );
    } else dispatch( success( null ) );
  };
};
unitTypeActions.createType = ( unit_type ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitTypeConstants.CREATE_UNIT_TYPE_REQUEST });
  const success = ( unit_type ) => ({ type:unitTypeConstants.CREATE_UNIT_TYPE_SUCCESS, unit_type });
  const failure = ( error ) => ({ type:unitTypeConstants.CREATE_UNIT_TYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitTypeService.createUnitType( unit_type )
      .then( unit_type => {
        dispatch( success( unit_type ) );
        dispatch( unitTypeActions.getAll() );
        dispatch( unitTypeActions.getType( unit_type.id ) );
      }, error => dispatch( failure( error ) ) );
  };
};
unitTypeActions.updateType = ( unit_type ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitTypeConstants.UPDATE_UNIT_TYPE_REQUEST });
  const success = ( unit_type ) => ({ type:unitTypeConstants.UPDATE_UNIT_TYPE_SUCCESS, unit_type });
  const failure = ( error ) => ({ type:unitTypeConstants.UPDATE_UNIT_TYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitTypeService.updateUnitType( unit_type )
      .then( unit_type => {
        dispatch( success( unit_type ) );
        // dispatch(unitTypeActions.getAll());
        /**TODO need to add process to split attachmentConstants out */
        // dispatch(attributeActions.getByType(unit_type.id));
      }, error => dispatch( failure( error ) ) );
  };
};
