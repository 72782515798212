/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Col,
    CardFooter,
    Row,
    Input, CustomInput, InputGroup, InputGroupAddon, InputGroupText
} from '../../../components/bot-components';
import {unitTypeActions, unitSubTypeActions, unitStatusActions, jobActions, companyActions, assetTypeActions, tireSizeActions} from '../../../network/actions';
import {
    validateNumber,
    validateUnitType,
    validateUnitNum,
    validateUniqueUnitNum,
    validateUnitDescription,
    validateNullableNumber, validateUniqueSerialNum
} from '../../../helpers';
import {_dateOnlyFormat} from '../../../helpers';

const defaultState = {
    unit_type_id: null,
    company_id: 1,
    unit_status_id: 1,
    unit_subtype_id: null,
    job_id: null,
    unit_number: undefined,
    plate: null,
    vin_sn: null,
    registration_date: null,
    description: null,
    location: null,
    mileage: 0.00,
    hours: 0.00,
    cadr: null,
    dot: false,
    asset_type_id: null,
    tire_size_id: null,
    tire_size_2_id: null,
    tire_size_3_id: null,
    tire_size_4_id: null,
    tare_weight: null,
    gross_weight: null,
    height: null,
    wheelbase: null,
    axles: null,
    width: null,
    overall_length: null,
    horsepower: null,
    unitNumValid: false,
    unitSerialValid: false,
    unitNumExists: true,
    unitSerialExists: true,
    descriptionValid: false,
    typeValid: false,
    milesValid: true,
    hoursValid: true,
    tare_weight_valid: true,
    gross_weight_valid: true,
    axles_valid: true
};
const UnitCreate = (props) => {
    const [state, updateState] = React.useState(defaultState);
    const setState = newState => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    React.useEffect(() => {
        props.getUnitTypes();
        props.getUnitStatuses();
        props.getJobs();
        props.getCompanies();
        props.getUnitStatuses();
        !props.asset_types.items && props.getAssetTypes();
        !props.tire_sizes.items && props.getTireSizes();
    }, []);
    React.useEffect(() => {
        props.companyList && setState({selectedCompany: companyList.find(c => c.value === state.company_id)});
    }, [props.companyList]);
    React.useEffect(() => {
        props.unitStatuses && setState({selectedUnitStatus: unitStatuses.find(c => c.value === state.unit_status_id)});
    }, [props.unitStatuses]);
    const changeDot = () => {
        setState({
            dot: !state.dot
        });
    };
    const changeHours = async (e) => {
        const {value} = e.target;
        setState({
            hours: value,
            hoursValid: await validateNumber(value)
        });
    };
    const changeMiles = async (e) => {
        const {value} = e.target;
        setState({
            mileage: value,
            milesValid: await validateNumber(value)
        });
    };
    const changeUnitNumber = (e) => {
        const {value} = e.target;
        const {pass, exists} = validateUnitNum(value, null, false);
        setState({
            unit_number: value,
            unitNumValid: pass,
            unitNumExists: exists
        });
    };
    const changeUnitSerial = (e) => {
        const {value} = e.target;
        const {pass, exists} = validateUnitNum(value, null, false);
        setState({
            vin_sn: value,
            unitSerialValid: pass,
            unitSerialExists: exists
        });
    };
    const checkUniqueUnitNumber = async (e) => {
        const {value} = e.target;
        const {pass, exists} = await validateUniqueUnitNum(value, null, true);
        setState({
            unit_number: value,
            unitNumValid: pass,
            unitNumExists: exists
        });
    };
    const checkUniqueSerialNumber = async (e) => {
        const {value} = e.target;
        const {pass, exists} = await validateUniqueSerialNum(value, null, true);
        setState({
            vin_sn: value,
            unitSerialValid: pass,
            unitSerialExists: value.length > 0 ? exists : true
        });
    };
    const changeDescription = (e) => {
        const {value} = e.target;
        setState({
            description: value,
            descriptionValid: validateUnitDescription(value)
        });
    };
    const changeUnitType = async (e) => {
        const {value} = e;
        await props.getUnitType(value);
        setState({
            selectedUnitType: e,
            unit_type_id: value,
            typeValid: await validateUnitType(value),
            selectedUnitSubType: null
        });
    };
    const changeUnitSubType = async (e) => {
        const {value} = e;
        setState({
            selectedUnitSubType: e,
            unit_subtype_id: value
        });
    };
    const handleDateChange = (date) => {
        setState({
            registration_date: date
        });
    };
    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };
    const changeNumberProps = async (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value,
            [name.concat('_valid')]: await validateNullableNumber(value)
        });
    };

    const {unitSubtypes, unitStatuses, Jobs, companyList, unitTypes, saveUnit, assetTypeList, tireSizeList} = props;
    const {unit_number, plate, vin_sn, registration_date, description, location, mileage, hours, unit_id, unit_type_id, unit_subtype_id, unit_status_id,
        company_id, job_id, dot, tire_size_id, asset_type_id, tire_size_2_id, tire_size_3_id, tire_size_4_id, tare_weight, gross_weight, height,
        wheelbase, axles, width, overall_length, horsepower, cadr, unitNumValid, descriptionValid, typeValid, hoursValid, milesValid, tare_weight_valid,
        gross_weight_valid, axles_valid, unitNumExists, unitSerialExists, unitSerialValid} = state;
    let canSave = unitNumValid && descriptionValid && typeValid && hoursValid && milesValid && tare_weight_valid && gross_weight_valid && axles_valid && unitSerialValid && unitNumExists && unitSerialExists;

    const submitUnit = () => {
            const updatedUnit = {
                id: unit_id,
                unit_type_id: unit_type_id,
                unit_subtype_id: unit_subtype_id,
                unit_status_id: unit_status_id,
                company_id: company_id,
                job_id: job_id,
                number: unit_number,
                plate: plate,
                vin_sn: vin_sn,
                registration_date: registration_date,
                description: description,
                location: location,
                mileage: mileage,
                hours: hours,
                dot: dot,
                tire_size_id: tire_size_id,
                asset_type_id: asset_type_id,
                tire_size_2_id: tire_size_2_id,
                tire_size_3_id: tire_size_3_id,
                tire_size_4_id: tire_size_4_id,
                tare_weight: tare_weight,
                gross_weight: gross_weight,
                height: height,
                wheelbase: wheelbase,
                axles: axles,
                width: width,
                overall_length: overall_length,
                horsepower: horsepower
            };
            saveUnit(updatedUnit);
            props.toggle?.();
            setState(defaultState);
        };
    return (
        <>
            <Row>
                <Col sm={5}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment Status</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedUnitStatus ?? {label: 'Select a Status', value: ""}}
                               onChange={(selectedUnitStatus) => setState({
                                   unit_status_id: selectedUnitStatus?.value,
                                   selectedUnitStatus: selectedUnitStatus
                               })} options={unitStatuses}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Location</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedJob ?? {label: 'Select a Job', value: ""}}
                               onChange={(selectedJob) => setState({
                                   job_id: selectedJob?.value,
                                   selectedJob: selectedJob
                               })} options={Jobs}/>
                    </InputGroup>
                </Col>
                <Col sm={1}>
                    <CustomInput type={'switch'} className={'unit-create-dot-switch'} checked={dot} onChange={changeDot} id="create_dot" name="dot" label="DOT"/>
                    {/*<CustomInput className={'unit-create-dot-switch'} checked={dot} onChange={changeDot} type="switch" id="create_dot" name="dot" label="DOT" />*/}
                </Col>
                <Col sm={2}/>
            </Row>
            <hr className={'page-divider'}/>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Description</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'description'} id={'description'} invalid={!descriptionValid}
                               value={description ? description : ''} onChange={changeDescription}>
                        </Input>
                    </InputGroup>
                    {!descriptionValid && <small style={{color: 'var(--red)'}}>*The description must be filled in to save the piece of equipment.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Miles</InputGroupText></InputGroupAddon>
                        <Input type={'number'} name={'mileage'} id={'mileage'} invalid={!milesValid}
                               onChange={changeMiles} value={mileage}>
                        </Input>
                    </InputGroup>
                    {!milesValid && <small style={{color: 'var(--red)'}}>*The miles must be between 0 and 99999999.99.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Asset Type</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedAssetType ?? {label: 'Select an Asset Type', value: ""}}
                               onChange={(selectedAssetType) => setState({
                                   asset_type_id: selectedAssetType?.value,
                                   selectedAssetType: selectedAssetType
                               })} options={assetTypeList}/>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Unit Number</InputGroupText></InputGroupAddon>
                        <Input type={'text'} invalid={!unitNumValid || !unitNumExists} name={'unit_number'} id={'number'} value={unit_number}
                               onChange={(e) => {changeUnitNumber(e)}} onBlur={(e) => {return checkUniqueUnitNumber(e)}}>
                        </Input>
                    </InputGroup>
                    {!unitNumValid && unitNumExists && <small style={{color: 'var(--red)'}}>*You must enter a unit number for the piece of equipment.</small>}
                    {unitNumValid && !unitNumExists && <small style={{color: 'var(--red)'}}>*The equipment number is already in use for another piece of equipment.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Hours</InputGroupText></InputGroupAddon>
                        <Input type={'number'} name={'hours'} id={'hours'} invalid={!hoursValid}
                               onChange={changeHours} value={hours}>
                        </Input>
                    </InputGroup>
                    {!hoursValid && <small style={{color: 'var(--red)'}}>*The hours must be between 0 and 99999999.99.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Tare Weight</InputGroupText></InputGroupAddon>
                        <Input type={'number'} name={'tare_weight'} id={'tare_weight'} invalid={!tare_weight_valid}
                               onChange={changeNumberProps} value={tare_weight ? tare_weight : ''}>
                        </Input>
                    </InputGroup>
                    {!tare_weight_valid && <small style={{color: 'var(--red)'}}>*The tare weight must be empty or between 0 and 99999999.99.</small>}
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Vin# or SN</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'vin_sn'} id={'vin_sn'} invalid={!unitSerialValid || !unitSerialExists} value={vin_sn ? vin_sn : ''}
                               onChange={(e) => changeUnitSerial(e)} onBlur={(e) => checkUniqueSerialNumber(e)}>
                        </Input>
                    </InputGroup>
                    {unitSerialValid && !unitSerialExists && <small style={{color: 'var(--red)'}}>*The serial number is already in use for another piece of equipment.</small>}
                    {!unitSerialValid && unitSerialExists && <small style={{color: 'var(--red)'}}>*You serial number must not be empty.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Distinct Location</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'location'} id={'location'} value={location ? location : ''}
                               onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Gross Weight</InputGroupText></InputGroupAddon>
                        <Input type={'number'} name={'gross_weight'} id={'gross_weight'} invalid={!gross_weight_valid}
                               onChange={changeNumberProps} value={gross_weight ? gross_weight : ''}>
                        </Input>
                    </InputGroup>
                    {!gross_weight_valid && <small style={{color: 'var(--red)'}}>*The gross weight must be empty between 0 and 99999999.99.</small>}
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment Type</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'} invalid={!typeValid}
                               value={state.selectedUnitType ?? {label: 'Select an equipment type', value: ""}}
                               onChange={changeUnitType} options={unitTypes}/>
                    </InputGroup>
                    {!typeValid && <small style={{color: 'var(--red)'}}>*Equipment type must not be empty.</small>}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>CADR</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'cadr'} id={'cadr'} value={cadr ? cadr : ''}
                               onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Height</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'height'} id={'height'} value={height ? height : ''}
                               onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment Class</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'} value={state.selectedUnitSubType ?? {label: 'Select an equipment class', value: ""}}
                               onChange={changeUnitSubType} options={unitSubtypes}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedTireSize ?? {label: 'Select a size', value: ""}}
                               onChange={(selectedTireSize) => setState({
                                   tire_size_id: selectedTireSize?.value,
                                   selectedTireSize: selectedTireSize
                               })} options={tireSizeList}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Wheelbase</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'wheelbase'} id={'wheelbase'} value={wheelbase ? wheelbase : ''}
                               onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Company</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedCompany ?? {label: 'Select an Owner', value: ""}}
                               onChange={(selectedCompany) => setState({
                                   company_id: selectedCompany?.value,
                                   selectedCompany: selectedCompany
                               })} options={companyList}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size 2</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedTireSize2 ?? {label: 'Select a size', value: ""}}
                               onChange={(selectedTireSize2) => setState({
                                   tire_size_2_id: selectedTireSize2?.value,
                                   selectedTireSize2: selectedTireSize2
                               })} options={tireSizeList}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Axles</InputGroupText></InputGroupAddon>
                        <Input type={'number'} name={'axles'} id={'axles'} invalid={!axles_valid}
                               onChange={changeNumberProps} value={axles ? axles : ''}>
                        </Input>
                        {!axles_valid && <small style={{color: 'var(--red)'}}>*The axles must be empty or between 0 and 99999999.99.</small>}
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Plate</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'plate'} id={'plate'} value={plate ? plate : ''}
                               onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size 3</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedTireSize3 ?? {label: 'Select a size', value: ""}}
                               onChange={(selectedTireSize3) => setState({
                                   tire_size_3_id: selectedTireSize3?.value,
                                   selectedTireSize3: selectedTireSize3
                               })} options={tireSizeList}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Overall Length</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'overall_length'} id={'overall_length'}
                               value={overall_length ? overall_length : ''} onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Registration Date</InputGroupText></InputGroupAddon>
                        <Input type={'date'} onChange={handleDateChange}
                               value={_dateOnlyFormat(registration_date)}
                        />
                    </InputGroup>
                    {/*<Label>Registration Date</Label>*/}
                    {/*<DatePicker onChange={handleDateChange}*/}
                    {/*            dateFormatCalendar={'MM - dd yyyy'}*/}
                    {/*            dateFormat={'MM - dd yyyy'}*/}
                    {/*            selected={registration_date ? new Date(registration_date) : new Date()}*/}
                    {/*            onMonthChange={handleDateChange}*/}
                    {/*            dropdownMode={'select'}*/}
                    {/*/>*/}
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size 4</InputGroupText></InputGroupAddon>
                        <Input type={'react-select'}
                               value={state.selectedTireSize4 ?? {label: 'Select a size', value: ""}}
                               onChange={(selectedTireSize4) => setState({
                                   tire_size_4_id: selectedTireSize4?.value,
                                   selectedTireSize4: selectedTireSize4
                               })} options={tireSizeList}/>
                    </InputGroup>
                </Col>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Horsepower</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'horsepower'} id={'horsepower'}
                               value={horsepower ? horsepower : ''} onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
            </Row>
            <Row style={{paddingBottom: '1rem'}}>
                <Col sm={4}/>
                <Col sm={4}/>
                <Col sm={4}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Width</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'width'} id={'width'}
                               value={width ? width : ''} onChange={changeProps}>
                        </Input>
                    </InputGroup>
                </Col>
            </Row>
            <CardFooter className={'user-edit-footer'}>
                <Button outline color={'texts'} disabled={!canSave} onClick={submitUnit}>Submit</Button>
                <Button outline color={'texts'} onClick={() => {
                    props.toggle && props.toggle();
                    setState(defaultState);
                }}>Cancel</Button>
            </CardFooter>
        </>
    );

};

const mapStateToProps = (state) => {
    const {authentication, unit_types, unit_type, unit_statuses, jobs, companies, asset_types, tire_sizes} = state;
    const {user} = authentication;
    let unitTypes = unit_types?.items?.map(ut => ({label: ut.utype, text: ut.utype, value: ut.id}));
    let companyList = companies?.items?.map(c => ({label: c.name, text: c.name, value: c.id}));
    let unitStatuses = unit_statuses?.items?.map(us => ({label: us.status, text: us.status, value: us.id}));
    let unitSubtypes = unit_type?.items?.unit_subtypes?.map(ust => ({label: ust.subtype, text: ust.subtype, value: ust.id}));
    let assetTypeList = asset_types?.items?.map(at => ({label: at.description, text: at.description, value: at.id}));
    let tireSizeList = tire_sizes?.items?.map(ts => ({label: ts.size, text: ts.size, value: ts.id}));
    let Jobs = jobs?.items?.map(j => ({label: j.name, text: j.name, value: j.id}));

    return {user, unit_types, jobs, asset_types, tire_sizes, companies, unit_statuses, unitSubtypes, unitStatuses, Jobs, companyList, unitTypes, tireSizeList, assetTypeList};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUnitTypes: () => dispatch(unitTypeActions.getAll()),
        getUnitSubTypes: () => dispatch(unitSubTypeActions.getAll()),
        getUnitStatuses: () => dispatch(unitStatusActions.getAll()),
        getUnitJobs: () => dispatch(jobActions.getAll()),
        getCompanies: () => dispatch(companyActions.getAll()),
        getUnitType: (id) => dispatch(unitTypeActions.getType(id)),
        getJobs: () => dispatch(jobActions.getAll()),
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitCreate);
