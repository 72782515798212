import {assetTypeConstants} from '../constants';

export const asset_types = (state = {}, action) => {
    switch (action.type) {
        case assetTypeConstants.GET_ALL_ASSET_TYPES_REQUEST:
            return {
                loading: true
            };
        case assetTypeConstants.GET_ALL_ASSET_TYPES_SUCCESS:
            return {
                items: action.asset_types
            };
        case assetTypeConstants.GET_ALL_ASSET_TYPES_FAILURE:
            return {
                error: action.error
            };
        case assetTypeConstants.ASSET_TYPE_CREATE_REQUEST:
            return {
                loading: true
            };
        case assetTypeConstants.ASSET_TYPE_CREATE_SUCCESS:
            return {
                items: action.asset_types
            };
        case assetTypeConstants.ASSET_TYPE_CREATE_FAILURE:
            return {
                error: action.error
            };
        case assetTypeConstants.ASSET_TYPE_UPDATE_REQUEST:
            return {
                loading: true
            };
        case assetTypeConstants.ASSET_TYPE_UPDATE_SUCCESS:
            return {
                items: action.asset_types
            };
        case assetTypeConstants.ASSET_TYPE_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const asset_type = (state = {}, action) => {
    switch (action.type) {
        case assetTypeConstants.ASSET_TYPE_DETAIL_REQUEST:
            return {
                loading: true
            };
        case assetTypeConstants.ASSET_TYPE_DETAIL_SUCCESS:
            return {
                items: action.asset_type
            };
        case assetTypeConstants.ASSET_TYPE_DETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
