// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const jobStatusService = {};

const apiUrl = baseUrl;

jobStatusService.createJobStatus = (job_status) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({job_status})
    };

    return fetch(`${apiUrl}/job_status`, requestOptions).then(handleResponse);
};

jobStatusService.updateJobStatus = (job_status) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({job_status})
    };

    return fetch(`${apiUrl}/job_status/` + job_status.id, requestOptions).then(handleResponse);
};

jobStatusService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/job_statuses`, requestOptions).then(handleResponse);
};

jobStatusService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/job_status/` + id, requestOptions).then(handleResponse);
};
