import {userConstants} from '../constants';

export const pag_users = (state = {}, action) => {
    switch (action.type) {
        case userConstants.GET_PAG_USERS_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_PAG_USERS_SUCCESS:
            return {
                items: action.pag_users
            };
        case userConstants.GET_PAG_USERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const users = (state = users, action) => {
    switch (action.type) {
        case userConstants.GET_ALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_ALL_SUCCESS:
            return {
                items: action.users
            };
        case userConstants.GET_ALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const user = (state = user, action) => {
    switch (action.type) {
        case userConstants.USER_DETAIL_REQUEST:
            return {
                loading: true
            };
        case userConstants.USER_DETAIL_SUCCESS:
            return {
                items: action.user
            };
        case userConstants.UPDATE_USER_REQUEST:
            return {
                loading: true
            };
        case userConstants.UPDATE_USER_SUCCESS:
            return {
                items: action.user
            };
        case userConstants.UPDATE_USER_FAILURE:
            return {
                error: action.error
            };
        case userConstants.CREATE_USER_REQUEST:
            return {
                loading: true
            };
        case userConstants.CREATE_USER_SUCCESS:
            return {
                items: action.user
            };
        case userConstants.CREATE_USER_FAILURE:
            return {
                error: action.error
            };
        case userConstants.LOGOUT:
            return null;
        default:
            return state;
    }
};

export const login_exceptions = (state = {}, action) => {
    switch (action.type) {
        case userConstants.GET_ALL_LOGIN_EXCEPTIONS_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_ALL_LOGIN_EXCEPTIONS_SUCCESS:
            return {
                items: action.login_exceptions
            };
        case userConstants.GET_ALL_LOGIN_EXCEPTIONS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
