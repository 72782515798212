import React, {useRef, useState, useEffect} from "react";
import {useDrag} from "react-dnd";
import {
    CustomInput,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    SaveCancel
} from '../../components/bot-components';

const AvailableCheck = ({data, updateItem}) => {
    const [{opacity}, drag] = useDrag({
        item: {
            ...data,
            isNew: true
        },
        type: 'div',
        accept: 'div',
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1
        })
    });
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [myData, setData] = useState({
        id: data.id,
        name: data.name,
        dot: data.dot,
        photo: data.photo,
        photo_only: data.photo_only,
        photo_required: data.photo_required,
        photo_count: data.photo_count,
        details: data.details,
        details_required: data.details_required,
        static: data.static
    });

    useEffect(() => {
        setData({
            id: data.id,
            name: data.name,
            dot: data.dot,
            photo: data.photo,
            photo_only: data.photo_only,
            photo_required: data.photo_required,
            photo_count: data.photo_count,
            details: data.details,
            details_required: data.details_required,
            static: data.static
        });

    }, [data]);

    const ref = useRef(null);
    const itemStyle = {
        border: '1px solid gray',
        padding: '0.5rem 1rem',
        backgroundColor: 'white',
        cursor: 'move'
    };

    const save = () => {
        setData(data);
        updateItem(myData);
        toggle();
    }

    const cancel = () => {
        setData(data);
        toggle();
    }

    drag(ref);
    return (
        <div className="availableCheck" ref={ref} style={{...itemStyle, opacity}}>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Edit {data.name}
                </ModalHeader>
                <ModalBody>
                    <Label for={'inspectionCheck'}>Inspection Item: </Label>
                    <Input name={'inspectionCheck'} type={'text'} value={myData.name}
                           onChange={(e) => setData(prevState => ({
                               ...prevState,
                               name: e.target.value
                           }))}/>
                    <Row style={{margin: '0.5rem'}}>
                        <CustomInput id={'dot_checkbox'}
                                     type={'checkbox'} color={'texts'}
                                     label={'DOT'}
                                     defaultChecked={myData.dot}
                                     onChange={() => setData(prevState => ({
                                         ...prevState,
                                         dot: !prevState.dot
                                     }))}/>
                        <CustomInput id={'photo_checkbox'}
                                     type={'checkbox'} color={'texts'}
                                     label={'Photo'}
                                     defaultChecked={myData.photo}
                                     onChange={() => setData(prevState => ({
                                         ...prevState,
                                         photo: !prevState.photo,
                                         photo_count: !prevState.photo ? 1 : 0
                                     }))}/>
                        {myData.photo && <CustomInput id={'photo_only_checkbox'}
                                      type={'checkbox'} color={'texts'}
                                      label={'Photo Only'}
                                      defaultChecked={myData.photo_only}
                                      onChange={() => setData(prevState => ({
                                          ...prevState,
                                          photo: !prevState.photo_only ? true : prevState.photo,
                                          photo_only: !prevState.photo_only
                                      }))}/>}
                        {myData.photo && <CustomInput id={'photo_required_checkbox'}
                                                      type={'checkbox'} color={'texts'}
                                                      label={'Photo Required'}
                                                      defaultChecked={myData.photo_required}
                                                      onChange={() => setData(prevState => ({
                                                          ...prevState,
                                                          photo_required: !prevState.photo_required
                                                      }))}/>}
                        <CustomInput id={'details_checkbox'}
                                     type={'checkbox'} color={'texts'}
                                     label={'Details'}
                                     defaultChecked={myData.details}
                                     onChange={() => setData(prevState => ({
                                         ...prevState,
                                         details: !prevState.details,
                                         details_required: !prevState.details ? prevState.details_required : false
                                     }))}/>
                        {myData.details && <CustomInput id={'details_required_checkbox'}
                                                      type={'checkbox'} color={'texts'}
                                                      label={'Details Required'}
                                                      defaultChecked={myData.details_required}
                                                      onChange={() => setData(prevState => ({
                                                          ...prevState,
                                                          details_required: !prevState.details_required
                                                      }))}/>}
                    </Row>
                    <Row style={{margin: '0.5rem'}}>
                        {myData.photo && (<>Photo count<Input type={'number'} min={1} max={4}
                                                              id={`${data.id}_photo_count`}
                                                              onChange={(e) => {
                                                                  let {value, min, max} = e.target;
                                                                  value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                                                                  setData(prevState => ({
                                                                      ...prevState,
                                                                      photo_count: value
                                                                  }))
                                                              }}
                                                              value={myData.photo_count}/></>)}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <SaveCancel onSaveClick={save} onCancelClick={cancel}/>
                </ModalFooter>
            </Modal>
            <Row style={{justifyContent: 'space-between'}}>
                <span>
                    <span style={{paddingLeft: '1rem'}}>{myData.name}</span>
                    {myData.dot && <span style={{paddingLeft: '1rem'}}>(DOT)</span>}
                    {myData.photo && <span style={{paddingLeft: '1rem'}}>(photo)</span>}
                    {myData.photo_only && <span style={{paddingLeft: '1rem'}}>(photo only)</span>}
                    {myData.photo_required && <span style={{paddingLeft: '1rem'}}>(photo required)</span>}
                    {myData.details && <span style={{paddingLeft: '1rem'}}>(details)</span>}
                    {myData.details_required && <span style={{paddingLeft: '1rem'}}>(details required)</span>}
                    {myData.static && <span style={{paddingLeft: '1rem'}}>(static)</span>}
                </span>
                <i
                    className={'fa fa-pencil'}
                    onClick={() => toggle()}
                    style={{cursor: 'pointer', paddingRight: '1rem'}}/>
            </Row>
        </div>
    );
};
export default AvailableCheck;
