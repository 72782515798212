import React from 'react';
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from '../../../components/bot-components';
import PropTypes from 'prop-types';
import BotButtonGroup from '../../../components/bot-components/BotButtonGroup';
const propTypes = {
    toggle: PropTypes.func,
    toggleTab: PropTypes.func,
    attachments: PropTypes.array,
    isOpen: PropTypes.bool,
    setFiles: PropTypes.func,
    file: PropTypes.any,
    handleAttachFiles: PropTypes.func,
    cancelFiles: PropTypes.func
};
const AttachmentInput = React.forwardRef(({toggle, toggleTab, attachments, isOpen, setFiles, file, handleAttachFiles, cancelFiles, className, tab, children}, ref) => {

    return (<>
        <BotButtonGroup>
            <Button color={'texts'} outline styly={{verticalAlign: 'center'}} className={'attachment-input-btn'} onClick={toggle}>Attach File <i className={'fa fa-folder'}/><Badge color={'texts'} className={'attachment-badge'} pill onClick={(e) => {e.stopPropagation(); toggleTab(tab)}}>{attachments?.length ?? 0}</Badge></Button>
            {children}
        </BotButtonGroup>
        <Modal isOpen={isOpen} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>Attach Files</ModalHeader>
            <ModalBody>
                <input ref={ref} onChange={setFiles} type="file" id={'attachments'}/>
                <label className={'btn-outline-texts'}
                       htmlFor={'attachments'}>Browse <i className={'fa fa-folder'}/></label> {file ?
                <label>{file.name}</label> :
                <label>No files selected.</label>}{}
            </ModalBody>
            <ModalFooter>
                <Button color={'texts'} outline onClick={handleAttachFiles}>Submit</Button>
                <Button color={'texts'} outline onClick={cancelFiles}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </>);
});
AttachmentInput.propTypes = propTypes;
export default AttachmentInput;
