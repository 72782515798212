import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import CheckItem from './CheckItem';

const checkListStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap'
};
const List = ({ data, unit_type, unit_subtype, handleDrop, path, updateDot, updatePhoto, updatePhotoOnly, updatePhotoRequired, updatePhotoCount, updateDetails, updateDetailsRequired, removeCheck }) => {
    const ref = useRef(null);


    const [{}, drop] = useDrop({
        type: 'div',
        accept: 'div',
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    drop(ref);

    const renderComponent = (component, currentPath) => {
        return (
            <CheckItem
                index={component.sort_order}
                data={component}
                unit_type={unit_type}
                path={currentPath}
                updateDot={updateDot}
                updatePhoto={updatePhoto}
                updatePhotoOnly={updatePhotoOnly}
                updatePhotoRequired={updatePhotoRequired}
                updatePhotoCount={updatePhotoCount}
                updateDetails={updateDetails}
                updateDetailsRequired={updateDetailsRequired}
                handleDrop={handleDrop}
                removeCheck={removeCheck}
                placeholder={component.placeholder}
            />
        );
    };

    return (
        <div
            ref={ref}
            style={{ ...checkListStyle }}
        >
            {data.length > 0 ? data.map((component, index) => {
                const currentPath = `${path}-${index}`;

                return (
                    <React.Fragment key={component.id}>
                        {renderComponent(component, currentPath)}
                    </React.Fragment>
                );
            }) : (unit_type || unit_subtype) && <React.Fragment key={'0000.empty'}>
                {renderComponent({name: 'Drag item to add', placeholder: true, sort_order: 0}, '')}
            </React.Fragment>}
        </div>
    );
};
export default List;
