import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    color: PropTypes.string,
    label: PropTypes.bool,
    outline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.oneOf(['', 'alt'])]),
    size: PropTypes.oneOf(['', 'lg', 'sm']),
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    defaultValue: PropTypes.any,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    form: PropTypes.any,
    name: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    variant: PropTypes.oneOf(['', '3d', 'pill']),
    className: PropTypes.string,
    dataOn: PropTypes.string,
    dataOff: PropTypes.string
};

let defaultProps = {
    color: 'secondary',
    label: false,
    outline: false,
    size: '',
    checked: false,
    defaultChecked: false,
    disabled: false,
    required: false,
    type: 'checkbox',
    variant: '',
    dataOn: 'On',
    dataOff: 'Off'
};

let AppSwitch = function (_Component) {
    _inherits(AppSwitch, _Component);

    function AppSwitch(props) {
        _classCallCheck(this, AppSwitch);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.onChange = _this.onChange.bind(_this);
        _this.state = {
            checked: _this.props.defaultChecked || _this.props.checked,
            selected: []
        };
        return _this;
    }

    AppSwitch.prototype.onChange = function onChange(event) {
        let target = event.target;
        this.setState({
            checked: target.checked
        });

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    AppSwitch.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({
                checked: this.props.checked
            });
        }
    };

    AppSwitch.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            disabled = _props.disabled,
            color = _props.color,
            name = _props.name,
            label = _props.label,
            outline = _props.outline,
            size = _props.size,
            required = _props.required,
            type = _props.type,
            value = _props.value,
            dataOn = _props.dataOn,
            dataOff = _props.dataOff,
            variant = _props.variant,
            attributes = _objectWithoutProperties(_props, ['className', 'disabled', 'color', 'name', 'label', 'outline', 'size', 'required', 'type', 'value', 'dataOn', 'dataOff', 'variant']);

        delete attributes.checked;
        delete attributes.defaultChecked;
        delete attributes.onChange;

        let classes = classNames(className, 'switch', label ? 'switch-label' : false, size ? 'switch-' + size : false, variant ? 'switch-' + variant : false, 'switch' + (outline ? '-outline' : '') + '-' + color + (outline === 'alt' ? '-alt' : ''), 'form-check-label');

        let inputClasses = classNames('switch-input', 'form-check-input');

        let sliderClasses = classNames('switch-slider');

        return React.createElement(
            'label',
            {className: classes},
            React.createElement('input', _extends({
                type: type,
                className: inputClasses,
                onChange: this.onChange,
                checked: this.state.checked,
                name: name,
                required: required,
                disabled: disabled,
                value: value
            }, attributes)),
            React.createElement('span', {className: sliderClasses, 'data-checked': dataOn, 'data-unchecked': dataOff})
        );
    };

    return AppSwitch;
}(Component);

AppSwitch.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSwitch.defaultProps = defaultProps;

export default AppSwitch;
