/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {unitActions, workorderActions, plmAlertActions, userActions} from "../../network/actions";
import BotPlmAlerts from './BotPlmAlerts';
import {authHeader, store} from '../../helpers';
import {baseUrl} from '../../network/constants';
const AllAlerts = (props) => {
    const [state, updateState] = React.useState({
        loading: false,
        myAlerts: []
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const aRef = React.useRef();
    React.useEffect(() => {
        localStorage.setItem('lastComp', props.history?.location?.pathname);
    }, []);
    const getAlerts = (req) => {
        setState({
            loading: true
        });
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify(req)
        };

        return fetch(`${baseUrl}/plm_alerts`, requestOptions).then(response => {
            return response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    setState({
                        myAlerts: [],
                        loading: false
                    });
                    return alert('There was an error loading the alerts, please try again...');
                }

                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    // noinspection JSCheckFunctionSignatures
                    await store.dispatch(userActions.updateAuth(Auth, null));
                }
                if (data) {
                    return setState({
                        myAlerts: data,
                        loading: false
                    });
                } else return setState({
                    myAlerts: [],
                    loading: false
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };
    const {unit, plm_alert, workorder} = props;
    const {myAlerts, loading} = state;
    return (
        <BotPlmAlerts ref={aRef}
                      data={myAlerts ? myAlerts : null}
                      plm_alert={plm_alert}
                      workorder={workorder}
                      tblId={'aRef'}
                      getMyAlert={(id) => props.getAlert(id)}
                      showTitle={true}
                      title={'All PLM Alerts'}
                      pageSizes={['20', '50', '100', '500', '1000']}
                      unit={unit}
                      getAlerts={(req) => getAlerts(req)}
                      remote={true}
                      lStorage={'all_alert_storage'}
                      getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                      csvUrl={'plm_alerts'}
                      csvFile={'all_alerts.csv'}
                      loading={loading}
                      u_preferences={props.user_preference}
                      getMyUnit={(id) => {props.getMyUnit(id)}}
        />
    );
};
const mapStateToProps = (state) => {
    const {unit, plm_alert, workorder, user_preference} = state;
    return {unit, plm_alert, workorder, user_preference};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllAlerts);
