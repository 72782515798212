import React from "react";
import PropTypes from 'prop-types';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from '../../components/bot-components';
import {notEmptyText, validateNumber} from '../../helpers';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    workorder: PropTypes.object,
    vendorList: PropTypes.array,
    poStatusList: PropTypes.array,
    onSavePo: PropTypes.func
};
let table_key_iterator = 0;

const CreatePO = (props) => {
    const [state, updateState] = React.useState({
        number: null,
        description: null,
        description_valid: false,
        vendor_id: null,
        vendor_id_valid: false,
        amount: null,
        amount_valid: false,
        po_status_id: null,
        po_status_id_valid: false,
        workorder_id: props.workorder.items.id,
        unit_id: props.workorder.items.unit_id,
        number_valid: true
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    const numberValid = (po_status_id, number) => {
        if (po_status_id !== 2) {
            return true
        } else return number !== null && number.length > 0;
    };

    const table_keys = () => {
        return `woc_${table_key_iterator++}`;
    };

    const changeNumber =  async (e) => {
        const {value} = e.target;
        setState({
            number: value,
            number_valid: await numberValid(state.po_status_id, value)
        });
    };

    const changeDescription = async (e) => {
        const {value} = e.target;
        setState({
            description: value,
            description_valid: await notEmptyText(value)
        });
    };

    const changeVendor = async (e) => {
        const {value} = e.target;
        setState({
            vendor_id: parseInt(value, 0),
            vendor_id_valid: await validateNumber(value)
        });
    };

    const changeAmount = async (e) => {
        const {value} = e.target;
        setState({
            amount: value,
            amount_valid: await validateNumber(value)
        });
    };

    const changeStatus = async (e) => {
        const {value} = e.target;
        setState({
            po_status_id: parseInt(value, 0),
            po_status_id_valid: await validateNumber(value),
            number_valid: await numberValid(parseInt(value, 0), state.number)
        });
    };

    const onSavePo = () => {
        const po = {
            number: this.state.number,
            description: this.state.description,
            vendor_id: this.state.vendor_id,
            amount: this.state.amount,
            po_status_id: this.state.po_status_id,
            workorder_id: this.state.workorder_id,
            unit_id: this.state.unit_id
        };
        props.onSavePo(po);
    };

        const {number, number_valid, description, description_valid, vendor_id, vendor_id_valid, amount, amount_valid, po_status_id, po_status_id_valid, workorder_id, unit_id} = state;
        let canSave = description_valid && vendor_id_valid && po_status_id_valid && amount_valid && number_valid && workorder_id && unit_id;
        return(
            <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
                <ModalHeader toggle={() => props.toggle()}>
                    Create PO for Work Order# {workorder_id}...
                </ModalHeader>
                <ModalBody>
                    <Label>PO Number(Assigned from purchasing)</Label>
                    <Input type={'textarea'} placeholder={'Enter PO number'} value={number ? number : ''} invalid={!number_valid} onChange={(e) => changeNumber(e)}/>
                    <Label>Vendor</Label>
                    <Input type={'select'} value={vendor_id ? vendor_id : ''} invalid={!vendor_id_valid} onChange={(e) => changeVendor(e)}>
                        <option key={table_keys()} value={null}>Select Vendor</option>
                        {props.vendorList.map(v => {return(<option key={table_keys()} value={v.value}>{v.label}</option>)})}
                    </Input>
                    <Label>Amount</Label>
                    <Input type={'textarea'} value={amount ? amount : ''} invalid={!amount_valid} placeholder={'Enter PO amount'} onChange={(e) => changeAmount(e)}/>
                    <Label>Status</Label>
                    <Input type={'select'} value={po_status_id ? po_status_id : ''} invalid={!po_status_id_valid} onChange={(e) => changeStatus(e)}>
                        <option key={table_keys()} value={null}>Select Status</option>
                        {props.poStatusList.map(s => {return(<option key={table_keys()} value={s.value}>{s.label}</option>)})}
                    </Input>
                    <Label>Description</Label>
                    <Input type={'textarea'} value={description ? description : ''} invalid={!description_valid} placeholder={'Enter PO description'} onChange={(e) => changeDescription(e)}/>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={!canSave} color={'texts'} onClick={() => onSavePo()}>Save</Button><Button color={'texts'} onClick={() => props.toggle()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
};

CreatePO.propTypes = propTypes;
export default CreatePO;
