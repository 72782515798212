import {userPreferenceConstants} from '../constants/';

const up = localStorage.getItem('user_preference');
const initialState = up ? JSON.parse(up) : {};

export const user_preferences = (state = {}, action) => {
    switch (action.type) {
        case userPreferenceConstants.GET_ALL_USER_PREFERENCES_REQUEST:
            return {
                loading: true
            };
        case userPreferenceConstants.GET_ALL_USER_PREFERENCES_SUCCESS:
            return {
                items: action.user_preferences
            };
        case userPreferenceConstants.GET_ALL_USER_PREFERENCES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const user_preference = (state = initialState, action) => {
    switch (action.type) {
        case userPreferenceConstants.CREATE_USER_PREFERENCE_SUCCESS:
            return {
                items: action.user_preference
            };
        case userPreferenceConstants.CREATE_USER_PREFERENCE_FAILURE:
            return {
                error: action.error
            };
        case userPreferenceConstants.UPDATE_USER_PREFERENCE_SUCCESS:
            return {
                items: action.user_preference
            };
        case userPreferenceConstants.UPDATE_USER_PREFERENCE_FAILURE:
            return {
                error: action.error
            };
        case userPreferenceConstants.DETAIL_USER_PREFERENCE_SUCCESS:
            return {
                items: action.user_preference
            };
        case userPreferenceConstants.DETAIL_USER_PREFERENCE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
