import React, {useState, useEffect} from 'react';
import {Row, Col, Label, Input, Button, Card, CardBody, CardFooter, ImageViewer} from '../../../components/bot-components';
import {socketUrl} from "../../../network/constants";

/**
 *
 * @param props
 * @param props.allAttachments
 * @returns {JSX.Element}
 * @constructor
 */
const BotAttachment = (props) => {
    const [state, updateState] = useState({
        attachment: {},
        commentText: '',
        comments: []
    });
    const [viewer, setViewer] = useState(false);

    /**
     *
     * @param {Object} newState
     */
    function setState (newState) {
        updateState(prevState => ({
            ...prevState,
            ...newState
        }));
    }

    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value,
        });
    };

    const onSaveComment = () => {
        const Comment = {
            comment: state.commentText,
            image: state.attachment.id,
        };
        props.onSaveComment(Comment);
        setState({
            commentText: '',
        });
    };

    useEffect(() => {
        setState({attachment: props.attachment, comments: props.attachment?.comments ?? []});
    }, [props.attachment]);

    const prepareSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (parseInt(bytes, 0) === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
        return Math.round(bytes / Math.pow(1024, i)).toString() + ' ' + sizes[i];
    };

    /**
     * @param attachment.mimetype
     */
    const {commentText, attachment, comments} = state;
    console.log(viewer);
    return(
        <div className={'bot-attachment-body'}>
            <ImageViewer id={'attachments'} images={props.allAttachments} isOpen={viewer} idx={0} toggle={() => setViewer(false)}/>
                        <Col sm={'4'}>
                            {(attachment?.mimetype === 'image/jpeg' || attachment?.mimetype === 'image/png') && <Row>
                                <img className={'attachment-img'} src={`${socketUrl}/${attachment?.image_path}`} alt={''} onClick={() => setViewer(prevState => !prevState)}/>
                            </Row>}
                            <Row className={'attachment-row'}>
                                <Label className={'attachment-label'}>Filename:</Label>
                                {<a href={`${socketUrl}/${attachment?.image_path}`} rel={'noopener noreferrer'} target={'_blank'}>{attachment?.image_name}</a>}
                            </Row>
                            <Row className={'attachment-row'}>
                                <Label className={'attachment-label'}>Size:</Label>
                                <Label className={'attachment-field'}>{prepareSize(attachment?.size)}</Label>
                            </Row>
                        </Col>
                        <Col sm={'8'}>
                            <Row>
                                <Col sm={12} style={{paddingRight: '2rem'}}>
                                    <Input name={'commentText'} className={'bot-attachment-comment-input'} type={'textarea'}
                                          value={commentText} onChange={changeProps}/>
                                    <Button outline color={'texts'} className={'float-right bot-attachment-comment-save-btn'}
                                            onClick={onSaveComment}>Save</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className={'bot-attachment-comment-list'}>
                                    {comments?.map(c => (
                                        <Card className={'bot-attachment-comment-card'}>
                                            <CardBody className={'bot-attachment-comment-card-body'}>{c.comment}</CardBody>
                                            <CardFooter className={'bot-attachment-comment-card-footer'}>Created By: {c.user}</CardFooter>
                                        </Card>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
        </div>
    );
};

export default BotAttachment;
