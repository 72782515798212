export const assetTypeConstants = {
    GET_ALL_ASSET_TYPES_REQUEST: 'GET_ALL_ASSET_TYPES_REQUEST',
    GET_ALL_ASSET_TYPES_SUCCESS: 'GET_ALL_ASSET_TYPES_SUCCESS',
    GET_ALL_ASSET_TYPES_FAILURE: 'GET_ALL_ASSET_TYPES_FAILURE',

    ASSET_TYPE_DETAIL_REQUEST: 'ASSET_TYPE_DETAIL_REQUEST',
    ASSET_TYPE_DETAIL_SUCCESS: 'ASSET_TYPE_DETAIL_SUCCESS',
    ASSET_TYPE_DETAIL_FAILURE: 'ASSET_TYPE_DETAIL_FAILURE',

    ASSET_TYPE_CREATE_REQUEST: 'ASSET_TYPE_CREATE_REQUEST',
    ASSET_TYPE_CREATE_SUCCESS: 'ASSET_TYPE_CREATE_SUCCESS',
    ASSET_TYPE_CREATE_FAILURE: 'ASSET_TYPE_CREATE_FAILURE',

    ASSET_TYPE_UPDATE_REQUEST: 'ASSET_TYPE_UPDATE_REQUEST',
    ASSET_TYPE_UPDATE_SUCCESS: 'ASSET_TYPE_UPDATE_SUCCESS',
    ASSET_TYPE_UPDATE_FAILURE: 'ASSET_TYPE_UPDATE_FAILURE',

    ASSET_TYPE_DELETE_REQUEST: 'ASSET_TYPE_DELETE_REQUEST',
    ASSET_TYPE_DELETE_SUCCESS: 'ASSET_TYPE_DELETE_SUCCESS',
    ASSET_TYPE_DELETE_FAILURE: 'ASSET_TYPE_DELETE_FAILURE',
};