// noinspection JSValidateTypes

import React, { useRef, useState } from 'react';
import { _dateFormat } from '../../helpers';
import { BotTable } from '../../components/bot-components';
import { GenericTextEditor } from '../Editors';
import _ from 'lodash';
import PropTypes from 'prop-types';

const propTypes = {
  ref:PropTypes.node,
  title:PropTypes.string,
  data:PropTypes.array,
  getUnitTypes:PropTypes.func.isRequired,
  getUnitType:PropTypes.func.isRequired,
  unit_type:PropTypes.object,
  editAction:PropTypes.bool,
  viewAction:PropTypes.bool,
  popoutAction:PropTypes.bool,
  alertAction:PropTypes.bool,
  actionCount:PropTypes.number,
  updateUnitType:PropTypes.func,
  csvFile:PropTypes.string,
  csvUrl:PropTypes.string,
  lStorage:PropTypes.string,
  remote:PropTypes.bool,
  tblId:PropTypes.string,
  pageSizes:PropTypes.array,
  canCreate:PropTypes.bool,
  unitTypeList:PropTypes.any,
  loading:PropTypes.bool,
  u_preferences:PropTypes.any,
  rowSelected:PropTypes.func,
  createUnitType:PropTypes.func
};
let columns = [];
const defaultFilters = {
  id:'',
  utype:'',
  list_count:'',
  unit_subtypes:'',
  unit_count:'',
  updated_at:'',
  updated_by:'',
};
const myFilters = _.cloneDeep( defaultFilters );
const defaultState = {
  sortName:'',
  sortOrder:'',
  currSize:20,
  currPage:1,
  filters:myFilters
};

const UnitTypeTable = React.forwardRef( ( props, ref ) => {
  const {
    tblId,
    title,
    data,
    pageSizes,
    getUnitTypes,
    remote,
    getInspections,
    getUnitType,
    showTitle,
    loading,
    lStorage,
    csvUrl,
    csvFile,
    createNewBtn
  } = props;
  //<editor-fold desc="Column Refs">
  const uTypeRef = useRef();
  const listRef = useRef();
  const subtypeRef = useRef();
  const unitCountRef = useRef();
  const updatedAtRef = useRef();
  const updatedByRef = useRef();
  const idRef = useRef();
  //</editor-fold>

  //<editor-fold desc="State">
  const [selectedRow, setSelectedRow] = useState( null );
  const [selectedColumn, setSelectedColumn] = useState( null );
  const [showEditor, setShowEditor] = useState( false );
  //</editor-fold>

  const toggleEditor = ( row, column ) => {
    setSelectedRow( row );
    setSelectedColumn( column );
    setShowEditor( prevState => !prevState );
    rowSelected( row?.id );
  };

  const refreshInspections = () => {
    const tbl = ref?.current;
    const req = {
      sortName:tbl?.state.sortName,
      sortOrder:tbl?.state.sortOrder,
      currentPage:tbl?.state.currPage,
      sizePerPage:tbl?.state.currSize,
      filters:tbl?.state.filters
    };
    remote ? getInspections( req ) : getInspections();
  };

  const prepareList = ( cell ) => {
    return cell ? 'Yes' : 'No';
  };

  const rowSelected = ( id ) => {
    getUnitType( id );
  };

  const filters = defaultFilters;
  columns = props?.columns ? props.columns : [
    {
      field:'id',
      text:'ID',
      filter:{
        type:'text',
        placeholder:'ID',
        defaultValue:filters.id
      },
      width:100,
      hidden:false,
      sortable:true,
      refProp:idRef
    },
    {
      field:'utype',
      text:'Type',
      filter:{
        type:'text',
        placeholder:'Type',
        defaultValue:filters.utype
      },
      editable:{
        value:true,
        onClick:( row ) => {
          toggleEditor( row, 'utype' );
        }
      },
      width:350,
      hidden:false,
      sortable:true,
      refProp:uTypeRef
    },
    {
      field:'unit_count',
      text:'Equipment Count',
      filter:{
        type:'text',
        placeholder:'Count of Equipment',
        defaultValue:filters.unit_count
      },
      width:250,
      hidden:false,
      sortable:true,
      refProp:unitCountRef
    },
    {
      field:'unit_subtypes',
      text:'Class Count',
      filter:{
        type:'text',
        placeholder:'Count of Classes',
        defaultValue:filters.unit_subtypes
      },
      width:250,
      hidden:false,
      sortable:true,
      format:cell => cell?.length,
      refProp:subtypeRef
    },
    {
      field:'list_count',
      text:'Type Level Inspection',
      filter:{
        type:'select',
        placeholder:'Type Level Inspection',
        options:[{ value:'', text:'Select List' }, { value:true, label:'Yes' }, {
          value:false,
          label:'No'
        }],
        defaultValue:filters.list_count
      },
      width:250,
      hidden:false,
      sortable:true,
      format:cell => prepareList( cell ),
      refProp:listRef
    },
    {
      field:'updated_by',
      text:'Modified By',
      filter:{
        type:'text',
        placeholder:'Last modified person',
        defaultValue:filters.updated_by
      },
      width:250,
      hidden:false,
      sortable:true,
      refProp:updatedByRef
    },
    {
      field:'updated_at',
      text:'Last Modified',
      filter:{
        type:'text',
        placeholder:'Last modified date',
        defaultValue:filters.updated_at
      },
      width:250,
      hidden:false,
      sortable:true,
      format:cell => _dateFormat( cell ),
      refProp:updatedAtRef
    },
  ];

  return (
    <>
      <GenericTextEditor row={ selectedRow } toggle={ () => toggleEditor() } isOpen={ showEditor }
                         cell={ selectedColumn } onUpdate={ ( ops ) => {
        const _unit_type = {
          ...selectedRow,
          [selectedColumn]:ops[selectedColumn]
        };
        props.updateUnitType( _unit_type );
      } }/>
      <BotTable ref={ ref }
                columns={ columns }
                title={ title }
                showTitle={ showTitle }
                header={ true }
                data={ data }
                getData={ ( req ) => getUnitTypes( req ) }
                pageSizes={ pageSizes }
                ClearFiltersBtn={ true }
                ClearFiltersIcon={ true }
                ClearSortBtn={ true }
                ClearSortIcon={ true }
                CsvDownloadBtn={ true }
                CsvDownloadIcon={ true }
                CreateNewBtn={ props.canCreate && createNewBtn }
                pagination={ 'both' }
                headers={ true }
                expandable={ false }
                lStorage={ lStorage }
                loading={ loading }
                tblId={ tblId }
                csvFile={ csvFile }
                csvUrl={ csvUrl }
                doRowUpdate={ () => Promise.reject() }
                refreshData={ () => refreshInspections() }
                btnGroupDirection={ 'left' }
                remote={ remote }
                defaultFilters={ filters }
                defaultState={ defaultState }
                onRowDoubleClick={ ( row ) => rowSelected( row.id ) }
      /></>
  );
} );

UnitTypeTable.propTypes = propTypes;

export default UnitTypeTable;
