import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './styles/css/test.css';
import 'font-awesome/css/font-awesome.min.css';
import {PrivateRoute} from "./js/helpers";
import {Login} from './js/views/Pages';
import DefaultLayout from './js/containers/defaultLayout';
// import io from 'socket.io-client';
// import {socketUrl} from './js/network/constants';

const App = () => {

    // React.useEffect(() => {
    //     const socket = io.connect(`${socketUrl}/`, {transports: ['polling']});
    //     // socket.on('connect', (soc) => soc.join('dm_room_1-790'));
    //     console.log(socket.id);
    //     socket.on('connect', () => {
    //         console.log(socket);
    //         socket.emit('join', 'dm_room_1-790');
    //         // socket.emit('connect_to_room', {room_name: 'dm_room_1-790'});
    //         socket.on('chat', (message) => {
    //             console.log(message);
    //             socket.emit('new_message', {room: 'dm_room_1-790', user_id: 790, message: 'Heller'});
    //         });
    //         socket.on('chat_message', (message) => console.log(message));
    //         socket.on('disconnect', () => socket.connect());
    //     });
    //     return () => socket.close();
    // }, []);


    // socket.on('hello', () => {
    //     console.log('hello');
    // })
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login}/>
          <PrivateRoute path="/" name="Home" component={DefaultLayout}/>
      </Switch>
    </HashRouter>
  );
}

export default App;
