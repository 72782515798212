import {authHeader} from '../../helpers';
import {baseUrl} from '../../network/constants';
import {
    Button,
    Col, CustomInput,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from '../../components/bot-components';
import {defaultState} from './UserCreate/UserCreate.constants';
import {
    changeDailyInspections,
    changeEmail, changeEmpNum, changePassword, changePasswordConfirmation,
    changeProps,
    changeTextField,
    finalizeEmail, finalizeEmpNum
} from './UserCreate/UserCreate.helpers';
import InputMask from 'react-input-mask';
import React from 'react';
import {savePassword} from './UserEdit/UserEdit.helpers';

export const getExtraFields = async () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };
    return fetch(`${baseUrl}/users/list_fields/`, requestOptions).then(response => {
        return response.status !== 404 ? response.json().then(data => {
            if (!response.ok) {
                if (response.status === 401) {
                    alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                }
                return null;
            }
            return data;
        }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
    });
};
export const getEnums = (field) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify({
            field: field
        })
    };
    return fetch(`${baseUrl}/users/list_enum/`, requestOptions).then(response => {
        return response.status !== 404 ? response.json().then(data => {
            if (!response.ok) {
                if (response.status === 401) {
                    alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                }
                return null;
            }
            return data;
        }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
    });
};
export const getUserFields = (statusList, roleList, yardList, jobList, state, setState, canSavePass, savedPassword, props, edit) => {
    const {selectedStatus, selectedRoles, selectedYard, selectedJob, phone_number, user_email, user_email_valid, user_email_unique, first_name,
        first_name_valid, last_name, last_name_valid, daily_inspections, user_number, user_number_exists, user_number_unique, password, passwords_match,
        password_exists, password_confirmation, password_confirmation_exists} = state;
    return (
        <>
            <Row>
                <Col sm={'6'}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Status
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} options={statusList}
                               value={selectedStatus}
                               onChange={(selectedStatus) => setState({selectedStatus: selectedStatus ? selectedStatus : defaultState.selectedStatus, user_status_id: selectedStatus ? selectedStatus.value : 1})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Roles
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} isMulti options={roleList}
                               placeholder={'Select all roles that apply to this user!'}
                               matchProp={'label'} invalid={!selectedRoles.length > 0} valid={selectedRoles.length > 0}
                               value={selectedRoles}
                               onChange={(selectedRoles) => setState({selectedRoles: selectedRoles, validRoles: selectedRoles.length > 0})}/>
                    </InputGroup>
                    {!selectedRoles.length > 0 && <small style={{color: 'var(--red)'}}>*You must select at least 1 role.</small>}
                    <Row>
                        <Col sm={'6'}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText>
                                        First Name
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type={'text'} name={'first_name'} autoComplete={'given-name'} value={first_name}
                                       onChange={(e) => changeTextField(e, setState)} invalid={!first_name_valid}/>
                            </InputGroup>
                            {!first_name_valid && <small style={{color: 'var(--red)'}}>*You must enter a first name.</small>}
                        </Col>
                        <Col sm={'6'}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText>
                                        Last Name
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input name={'last_name'} autoComplete={'family-name'} type={'text'} invalid={!last_name_valid}
                                       value={last_name} onChange={(e) => changeTextField(e, setState)}/>
                            </InputGroup>
                            {!last_name_valid && <small style={{color: 'var(--red)'}}>*You must enter a last name.</small>}
                        </Col>
                    </Row>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Phone
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input name={'phone_number'} autoComplete={'tel-national'} type={'tel'}
                               mask={'+1.999.999.9999'}
                               maskChar={' '}
                               tag={InputMask}
                               value={phone_number}
                               onChange={(e) => changeProps(e, setState)}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Email
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input name={'user_email'} autoComplete={'email'} type={'text'} invalid={!user_email_valid || !user_email_unique}
                               value={user_email} onChange={(e) => changeEmail(e, setState)} onBlur={(e) => finalizeEmail(e, setState)}/>
                    </InputGroup>
                    {!user_email_valid && <small style={{color: 'var(--red)'}}>*You must enter a validly formatted email.</small>}
                    {!user_email_unique && <small style={{color: 'var(--red)'}}>*The email you have entered is already in use.</small>}
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Daily Inspections
                            </InputGroupText>
                        </InputGroupAddon>
                        <CustomInput id={'daily_inspections_chk'} className={'user-custom-checkbox'} type={'checkbox'} name={'daily_inspections'}
                               checked={daily_inspections} onChange={() => changeDailyInspections(setState, state)}/>
                    </InputGroup>
                </Col>
                <Col sm={'6'}>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Job</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} options={jobList}
                               placeholder={'Select the users primary job.'}
                               matchProp={'label'}
                               value={selectedJob}
                               onChange={(selectedJob) => setState({selectedJob: selectedJob, job_id: selectedJob ? selectedJob.value : null})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Yard</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} options={yardList}
                               placeholder={'Select the users primary Yard.'}
                               matchProp={'label'}
                               value={selectedYard}
                               onChange={(selectedYard) => setState({selectedYard: selectedYard, yard_id: selectedYard ? selectedYard.value : null})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>
                                Employee#
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input name={'user_number'} autoComplete={'off'} invalid={!user_number_exists || !user_number_unique}
                               onChange={(e) => changeEmpNum(e, setState)} type={'number'} onBlur={(e) => finalizeEmpNum(e, setState)}
                               value={user_number}/>
                    </InputGroup>
                    {!user_number_exists && <small style={{color: 'var(--red)'}}>*You must enter an employee#.</small>}
                    {!user_number_unique && <small style={{color: 'var(--red)'}}>*The employee# you have entered is already in use in the system.</small>}
                    {edit ?
                        <>
                            {!state.editPassword ? <Button color={'texts'} outline
                                                          onClick={() => setState({editPassword: true})}>Edit
                                    Password</Button> :
                                <div>
                                    <Label>Password:</Label>
                                    <Input type={'password'} autoComplete={'new-password'}
                                           name={'password'} vaue={password} invalid={!password_exists || !passwords_match}
                                           onChange={(e) => changePassword(e, state, setState)}/>
                                    {!password_exists && <small style={{color: 'var(--red)'}}>*You must enter a password.</small>}
                                    {!passwords_match && <small style={{color: 'var(--red)'}}>*The passwords do not match.</small>}
                                    <br/>
                                    <Label>Confirm Password</Label>
                                    <Input type={'password'} autoComplete={'new-password'}
                                           name={'password_confirmation'} invalid={!password_confirmation_exists || !passwords_match}
                                           value={password_confirmation}
                                           onChange={(e) => changePasswordConfirmation(e, state, setState)}/>
                                    {!password_confirmation_exists && <small style={{color: 'var(--red)'}}>*You must enter a password confirmation.</small>}
                                    <Button color={'texts'} outline disabled={!canSavePass}
                                            onClick={() => savePassword(state, props, savedPassword)}>Save</Button><Button
                                    onClick={() => setState({editPassword: false})}
                                    color={'texts'} outline>Cancel</Button>
                                </div>}
                        </>:
                    <>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Password
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'password'} autoComplete={'new-password'} name={'password'} invalid={!passwords_match || !password_exists}
                                   value={password} onChange={(e) => changePassword(e, state, setState)}/>
                        </InputGroup>
                        {!password_exists && <small style={{color: 'var(--red)'}}>*You must enter a password.</small>}
                        {!passwords_match && <small style={{color: 'var(--red)'}}>*The passwords do not match.</small>}
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Confirm Password
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'password'} autoComplete={'new-password'} invalid={!password_confirmation_exists || !passwords_match}
                                   name={'password_confirmation'} value={password_confirmation}
                                   onChange={(e) => changePasswordConfirmation(e, state, setState)}/>
                        </InputGroup>
                        {!password_confirmation_exists && <small style={{color: 'var(--red)'}}>*You must enter a password confirmation.</small>}
                        {!passwords_match && <small style={{color: 'var(--red)'}}>*The passwords do not match.</small>}
                    </>}
                </Col>
            </Row>
        </>
    );
};
