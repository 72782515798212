/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSCheckFunctionSignatures

import React from 'react';
import {connect} from 'react-redux';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from '../../../components/bot-components';
import UserDetail from '../BotUser/BotUser';
import classnames from "classnames";
import BotUsers from './BotUsers';
import {mapDispatchToListProps, mapStateToListProps, setupEnums, setupExtraFields} from './BotUsers.helpers';
import {hasRoles} from '../../../helpers';
// import LoginExceptionSummaries from '../LoginExceptions/LoginExceptions';
import UserCreate from '../UserCreate/UserCreate';

const Users = (props) => {
    const currentTab = localStorage.getItem('usersTab') ? localStorage.getItem('usersTab') : '1';
    const importUsers = React.useRef();
    const [state, updateState] = React.useState({
        modal: true,
        file: importUsers.current?.files[0] ?? null,
        timeHidden: true,
        timeDisabled: true,
        tab: currentTab,
        confirmA: false,
        userDropdown: false,
        loading: false,
        myUsers: [],
        dirty: true,
        createModal: false
    });
    const auRef = React.useRef();
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));

    React.useEffect(() => {
        localStorage.setItem('usersTab', state.tab);
    }, [state.tab]);

    const toggleTab = (tab) => setState({tab: tab});

    const toggleUserDropdown = () => setState({userDropdown: !state.userDropdown});

    const toggleCreate = () => setState({createModal: !state.createModal});

    React.useEffect(() => {
        localStorage.setItem('lastComp', props.history?.location?.pathname);
        setupEnums(state, setState).catch();
        // props.getLoginExceptions();
    }, []);

    React.useEffect(() => {
        setupExtraFields(state, setState).catch();
    }, [state.enum1, state.enum2, state.enum3, state.enum4]);

    return (
        <>
            {state.createModal && <UserCreate toggle={toggleCreate} isOpen={state.createModal}/>}
            {hasRoles(['Administrator', 'Staff', 'Manager'], props.user?.user) &&
            <Dropdown toggle={() => toggleUserDropdown()} isOpen={state.userDropdown} size={'xl'} className={'float-right'}>
                <DropdownToggle color={'texts'} outline onClick={() => toggleUserDropdown()} caret>User Actions</DropdownToggle>
                <DropdownMenu modifiers={{computeStyle: {gpuAcceleration: false}}}>
                    {hasRoles(['Administrator', 'Manager'], props.user?.user) &&
                    <DropdownItem outline color={'texts'} onClick={() => toggleCreate()}>Create New <i className={'fa fa-user-plus'}/></DropdownItem>}
                </DropdownMenu>
            </Dropdown>}
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({active: state.tab === '1'})} onClick={() => toggleTab('1')}>All Users</NavLink>
                </NavItem>
                {hasRoles(['Administrator', 'Staff', 'Manager'], props.user?.user) &&
                <NavItem>
                    <NavLink className={classnames({active: state.tab === '2'})} onClick={() => toggleTab('2')}>User Management</NavLink>
                </NavItem>}
                {hasRoles(['Administrator', 'Manager'], props.user?.user) &&
                <NavItem>
                    <NavLink className={classnames({active: state.tab === '3'})} onClick={() => toggleTab('3')}>Login Exceptions</NavLink>
                </NavItem>}
            </Nav>
            <TabContent activetab={state.tab}>
                {state.tab === '1' &&
                <TabPane tabId={'1'}>
                    {state.extraColumns &&
                    <BotUsers ref={auRef}
                              onUserSelected={() => toggleTab('2')}
                              extraColumns={state.extraColumns}
                              getUsers={(req) => props.getUsers(req)}
                              getMyUser={(id) => props.getUser(id)}
                              pageSizes={['20', '50', '100', '250', '500', '1000']}
                              remote={true}
                              csvUrl={'users'}
                              csvFile={'all_users.csv'}
                              title={'All Users'}
                              showTitle={false}
                              data={props.pag_users?.items ? props.pag_users.items : null}
                              lStorage={'all_users_storage'}
                              loading={props.pag_users?.loading ?? false}
                              user_preference={props.user_preference}
                              tblId={'auRef'}/>}
                </TabPane>}
                {hasRoles(['Administrator', 'Staff', 'Manager'], props.user?.user) && state.tab === '2' &&
                <TabPane tabId={'2'}>
                    <UserDetail/>
                </TabPane>}
                {/*{hasRoles(['Administrator', 'Manager'], props.user?.user) && state.tab === '3' &&*/}
                {/*<TabPane tabId={'3'}>{<LoginExceptionSummaries/>}</TabPane>}*/}
            </TabContent>
        </>
    )
};
export default connect(mapStateToListProps, mapDispatchToListProps)(Users);
