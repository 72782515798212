import React, {Component} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {jobActions, unitActions, userActions, workorderActions, yardActions} from '../../network/actions';
import {Nav, NavItem, NavLink, TabContent, TabPane} from '../../components/bot-components';
import BotWorkOrders from './BotWorkOrders/BotWorkOrders';
import BotWorkOrderExceptions from './BotWorkOrderExceptions/BotWorkOrderExceptions';
import {authHeader, store} from '../../helpers';
import {baseUrl} from '../../network/constants';
import {logOut} from '../../network/services/responseHandler';
import moment from 'moment';

// const selects = ['workorder_status', 'task_priority_id', 'dot'];

class WorkorderTable extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        let tab = 'aRef';
        if (localStorage.getItem('woTab') && isNaN(parseInt(localStorage.getItem('woTab'), 0))) {
            tab = localStorage.getItem('woTab');
        }
        this.state = {
            activeTab: tab,
            loading: false,
            myWorkOrders: []
        };
        this.aRef = React.createRef();
        this.vRef = React.createRef();
        this.blRef = React.createRef();
        this.aoRef = React.createRef();
        this.anRef = React.createRef();
        this.onRef = React.createRef();
        this.woExcRef = React.createRef();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                myWorkOrders: []
            });
            localStorage.setItem('woTab', tab);
        }
    }

    componentDidMount() {
        this.props.getAllYards();
        this.props.getAllJobs();
        localStorage.setItem('lastComp', this.props.history?.location?.pathname);
    }

    updateWorkOrder(wo) {
        delete wo.show;
        this.props.updateWorkorder(wo);
    }

    getWorkOrders(req, uri) {
        this.setState({
            loading: true
        }, () => {
            const requestOptions = {
                method: 'PUT',
                headers: authHeader('PUT'),
                body: JSON.stringify(req)
            };

            return fetch(`${baseUrl}/${uri}`, requestOptions).then(
              /**
               *
               * @param {Response} response
               * @returns {*|Promise<never>}
               */
              response => {
                return response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(async data => {
                    if (!response.ok) {
                        return this.setState({
                            myWorkOrders: [],
                            loading: false
                        }, () => alert('There was an error loading the work orders, please try again...'));
                    }

                    const Auth = JSON.parse(response.headers.get('Authentication'));
                    if (Auth) {
                        // noinspection JSCheckFunctionSignatures
                        await store.dispatch(userActions.updateAuth(Auth, null));
                    }
                    if (data) {
                        return this.setState({
                            myWorkOrders: data,
                            loading: false
                        });
                    } else return this.setState({
                        myWorkOrders: [],
                        loading: false
                    });
                }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
            });
        });
    }

    setBlocked(blocked) {
        this.setState({
            loading: blocked
        });
    }

    render() {
        return (
            <>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'aRef'})}
                                 onClick={() => {
                                     this.toggle('aRef');
                                 }}>
                            All Work Orders
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'vRef'})}
                                 onClick={() => {
                                     this.toggle('vRef');
                                 }}>
                            Service Provider Work Orders
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'blRef'})}
                                 onClick={() => {
                                     this.toggle('blRef');
                                 }}>
                            Created By Inspections
                        </NavLink>
                    </NavItem>
                    {this.props.user_preference.items && this.props.user_preference.items.open_workorders && <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'aoRef'})}
                                 onClick={() => {
                                     this.toggle('aoRef');
                                 }}>
                            Open Work Orders
                        </NavLink>
                    </NavItem>}
                    {this.props.user_preference.items && this.props.user_preference.items.new_workorders && <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'anRef'})}
                                 onClick={() => {
                                     this.toggle('anRef');
                                 }}>
                            New Work Orders
                        </NavLink>
                    </NavItem>}
                    {this.props.user_preference.items && this.props.user_preference.items.old_workorders && <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'onRef'})}
                                 onClick={() => {
                                     this.toggle('onRef');
                                 }}>
                            Past Due Work Orders
                        </NavLink>
                    </NavItem>}
                    {this.props.modules?.find(m => m.name === 'gps') &&
                    <NavItem>
                        <NavLink className={classnames({active: this.state.activeTab === 'woExcRef'})}
                                 onClick={() => {
                                     this.toggle('woExcRef');
                                 }}>
                            Work Order Exceptions
                        </NavLink>
                    </NavItem>}
                </Nav>
                <TabContent activetab={this.state.activeTab}>
                    <TabPane tabId={'aRef'}>
                        {this.state.activeTab === 'aRef' ?
                            <BotWorkOrders ref={this.aRef}
                                           data={this.state.myWorkOrders}
                                           workorder={this.props.workorder}
                                           tblId={'aRef'}
                                           popoutAction={true}
                                           viewAction={true}
                                           actionCount={2}
                                           showTitle={false}
                                           title={'All Work Orders'}
                                           jobList={this.props.jobList}
                                           yardList={this.props.yardList}
                                           pageSizes={['20', '50', '100', '500', '1000']}
                                           getWorkorders={(req) => this.getWorkOrders(req, 'workorders/')}
                                           unit={this.props.unit}
                                           remote={true}
                                           lStorage={'all_work_order_storage'}
                                           getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                           csvUrl={'workorders'}
                                           csvFile={'all_work_orders.csv'}
                                           getMyUnit={(id) => {
                                               this.props.getMyUnit(id);
                                           }}
                                           updateWorkorder={(wo) => this.updateWorkOrder(wo)}
                                           getActiveTable={() => {
                                               return this.state.activeTab;
                                           }}
                                           includeDateRange={true}
                                           setBlocked={(blocked) => this.setBlocked(blocked)}
                                           loading={this.state.loading}
                                           u_preferences={this.props.user_preference}
                            /> : null}
                    </TabPane>
                    <TabPane tabId={'vRef'}>
                        {this.state.activeTab === 'vRef' &&
                        <BotWorkOrders ref={this.vRef}
                                       getWorkorders={(req) => this.getWorkOrders({
                                           ...req,
                                           filters: {
                                               ...req.filters,
                                               vendor_id: JSON.stringify({op: '!=', value: null})
                                           }
                                       }, 'workorders/')}
                                       specialFilters={{
                                           vendor_id: JSON.stringify({op: '!=', value: null})
                                       }}
                                       getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                       title={'Service Provider Work Orders'}
                                       actionCount={2}
                                       data={this.state.myWorkOrders}
                                       viewAction={true} popoutAction={true}
                                       getMyUnit={(id) => {
                                           this.props.getMyUnit(id);
                                       }}
                                       unit={this.props.unit}
                                       refProp={this.vRef}
                                       tblId={this.state.activeTab}
                                       updateWorkorder={(wo) => this.props.updateWorkorder(wo)}
                                       jobList={this.props.jobList}
                                       yardList={this.props.yardList}
                                       csvFile={'vendor_work_orders.csv'}
                                       csvUrl={'workorders/'}
                                       lStorage={'vendor_work_order_storage'}
                                       workorder={this.props.workorder}
                                       remote={true} pageSizes={['20', '50', '100', '500', '1000']}
                                       getActiveTable={() => {
                                           return this.state.activeTab;
                                       }}
                                       includeDateRange={true}
                                       setBlocked={(blocked) => this.setBlocked(blocked)}
                                       loading={this.props.vendor_all_workorders.loading}
                                       u_preferences={this.props.user_preference}
                        />}
                    </TabPane>
                    <TabPane tabId={'blRef'}>
                        {this.state.activeTab === 'blRef' &&
                        <BotWorkOrders ref={this.blRef}
                                       getWorkorders={(req) => this.getWorkOrders({
                                           ...req,
                                           filters: {
                                               ...req.filters,
                                               created_by_log: JSON.stringify({op: '=', value: true})
                                           }
                                       }, 'workorders/')}
                                       specialFilters={{
                                           created_by_log: JSON.stringify({op: '=', value: true})
                                       }}
                                       getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                       title={'Work Orders created by Inspections'}
                                       actionCount={2}
                                       data={this.state.myWorkOrders}
                                       viewAction={true}
                                       popoutAction={true}
                                       getMyUnit={(id) => {
                                           this.props.getMyUnit(id);
                                       }}
                                       unit={this.props.unit}
                                       tblId={'blRef'}
                                       updateWorkorder={(wo) => this.props.updateWorkorder(wo)}
                                       jobList={this.props.jobList}
                                       yardList={this.props.yardList}
                                       csvFile={'work_orders_created_by_inspections.csv'}
                                       csvUrl={'workorders/'}
                                       lStorage={'work_order_by_log_storage'}
                                       workorder={this.props.workorder}
                                       remote={true}
                                       pageSizes={['20', '50', '100', '500', '1000']}
                                       getActiveTable={() => {
                                           return this.state.activeTab;
                                       }}
                                       includeDateRange={true}
                                       setBlocked={(blocked) => this.setBlocked(blocked)}
                                       loading={this.props.log_workorders.loading}
                                       u_preferences={this.props.user_preference}
                        />}
                    </TabPane>
                    <TabPane tabId={'aoRef'}>
                        {this.state.activeTab === 'aoRef' &&
                        <BotWorkOrders ref={this.aoRef}
                                       getWorkorders={(req) => this.getWorkOrders({
                                           ...req,
                                           filters: {
                                               ...req.filters,
                                               workorder_status_id: JSON.stringify({op: '=', value: 1})
                                           }
                                       }, 'workorders/')}
                                       specialFilters={{
                                           workorder_status_id: JSON.stringify({op: '=', value: 1})
                                       }}
                                       getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                       title={'All current open Work Orders'}
                                       actionCount={2}
                                       data={this.state.myWorkOrders}
                                       viewAction={true}
                                       popoutAction={true}
                                       getMyUnit={(id) => {
                                           this.props.getMyUnit(id);
                                       }}
                                       unit={this.props.unit}
                                       tblId={'aoRef'}
                                       updateWorkorder={(wo) => this.props.updateWorkorder(wo)}
                                       jobList={this.props.jobList}
                                       yardList={this.props.yardList}
                                       csvFile={'all_open_work_orders.csv'}
                                       csvUrl={'workorders/'}
                                       lStorage={'open_work_order_storage'}
                                       workorder={this.props.workorder}
                                       remote={true}
                                       pageSizes={['20', '50', '100', '500', '1000']}
                                       getActiveTable={() => {
                                           return this.state.activeTab;
                                       }}
                                       includeDateRange={true}
                                       setBlocked={(blocked) => this.setBlocked(blocked)}
                                       loading={this.props.open_workorders.loading}
                                       u_preferences={this.props.user_preference}
                        />}
                    </TabPane>
                    <TabPane tabId={'anRef'}>
                        {this.state.activeTab === 'anRef' &&
                        <BotWorkOrders ref={this.anRef}
                                       getWorkorders={(req) => this.getWorkOrders({
                                           ...req,
                                           filters: {
                                               ...req.filters,
                                               created_at: JSON.stringify({
                                                   op: '>',
                                                   value: moment().subtract(24, 'hours')
                                               }),
                                               workorder_status_id: JSON.stringify({op: '=', value: 1})
                                           }
                                       }, 'workorders/')}
                                       specialFilters={{
                                           created_at: JSON.stringify({
                                               op: '>',
                                               value: moment().subtract(24, 'hours')
                                           }),
                                           workorder_status_id: JSON.stringify({op: '=', value: 1})
                                       }}
                                       getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                       title={'Work Orders created in the last 24 hours'}
                                       actionCount={2}
                                       data={this.state.myWorkOrders}
                                       viewAction={true} popoutAction={true}
                                       getMyUnit={(id) => {
                                           this.props.getMyUnit(id);
                                       }}
                                       unit={this.props.unit}
                                       tblId={'anRef'}
                                       updateWorkorder={(wo) => this.props.updateWorkorder(wo)}
                                       jobList={this.props.jobList}
                                       yardList={this.props.yardList}
                                       csvFile={'work_orders_from_last_24_hours.csv'}
                                       csvUrl={'workorders/'}
                                       lStorage={'new_work_order_storage'}
                                       workorder={this.props.workorder}
                                       remote={true}
                                       pageSizes={['20', '50', '100', '500', '1000']}
                                       getActiveTable={() => {
                                           return this.state.activeTab;
                                       }}
                                       includeDateRange={false}
                                       setBlocked={(blocked) => this.setBlocked(blocked)}
                                       loading={this.props.new_workorders.loading}
                                       u_preferences={this.props.user_preference}
                        />}
                    </TabPane>
                    <TabPane tabId={'onRef'}>
                        {this.state.activeTab === 'onRef' &&
                        <BotWorkOrders ref={this.onRef}
                                       getWorkorders={(req) => this.getWorkOrders({
                                           ...req,
                                           filters: {
                                               ...req.filters,
                                               created_at: JSON.stringify({
                                                   op: '<',
                                                   value: moment().subtract(45, 'days')
                                               }),
                                               workorder_status_id: JSON.stringify({op: '=', value: 1})
                                           }
                                       }, 'workorders/')}
                                       specialFilters={{
                                           created_at: JSON.stringify({
                                               op: '<',
                                               value: moment().subtract(45, 'days')
                                           }),
                                           workorder_status_id: JSON.stringify({op: '=', value: 1})
                                       }}
                                       getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                       title={'Work Orders over 45 days'}
                                       actionCount={2}
                                       data={this.state.myWorkOrders}
                                       viewAction={true}
                                       popoutAction={true}
                                       getMyUnit={(id) => {
                                           this.props.getMyUnit(id);
                                       }}
                                       unit={this.props.unit}
                                       tblId={'onRef'}
                                       updateWorkorder={(wo) => this.props.updateWorkorder(wo)}
                                       jobList={this.props.jobList}
                                       yardList={this.props.yardList}
                                       csvFile={'work_orders_with_tasks_over_45_days_old.csv'}
                                       csvUrl={'workorders/'}
                                       lStorage={'old_work_order_storage'}
                                       workorder={this.props.workorder}
                                       remote={true}
                                       pageSizes={['20', '50', '100', '500', '1000']}
                                       getActiveTable={() => {
                                           return this.state.activeTab;
                                       }}
                                       includeDateRange={false}
                                       setBlocked={(blocked) => this.setBlocked(blocked)}
                                       loading={this.props.old_workorders.loading}
                                       u_preferences={this.props.user_preference}
                                       history={this.props.history}
                        />}
                    </TabPane>
                    <TabPane tabId={'woExcRef'}>
                        {this.state.activeTab === 'woExcRef' && this.props.modules?.find(m => m.name === 'gps') &&
                        <BotWorkOrderExceptions ref={this.woExcRef}
                                                getWorkorders={(req) => this.getWorkOrders(req, 'reports/work_order_exceptions/')}
                                                getMyWorkOrder={(id) => this.props.getMyWorkorder(id)}
                                                title={'Work Orders over 45 days'}
                                                actionCount={2}
                                                data={this.state.myWorkOrders}
                                                viewAction={true}
                                                popoutAction={true}
                                                tblId={'onRef'}
                                                csvFile={'work_order_exceptions.csv'}
                                                csvUrl={'reports/work_order_exceptions/'}
                                                lStorage={'work_order_exceptions_storage'}
                                                workorder={this.props.workorder}
                                                remote={true}
                                                pageSizes={['20', '50', '100', '500', '1000']}
                                                getActiveTable={() => {
                                                    return this.state.activeTab;
                                                }}
                                                includeDateRange={true}
                                                setBlocked={(blocked) => this.setBlocked(blocked)}
                                                loading={this.state.loading}
                                                u_preferences={this.props.user_preference}
                                                history={this.props.history}
                        />}
                    </TabPane>
                </TabContent>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        workorders,
        workorder,
        unit,
        vendor_all_workorders,
        yards,
        jobs,
        user_preference,
        new_workorders,
        old_workorders,
        open_workorders,
        log_workorders
    } = state;

    const BotModules = user_preference.items?.bot_modules?.filter(m => m.enabled);
    let yardList = [];
    let jobList = [];
    if (yards.items) {
        yards.items.map(y => {
            return yardList.push({value: y.id, label: y.name});
        });
    }
    if (jobs.items) {
        jobs.items.map(j => {
            return jobList.push({value: j.id, label: j.name});
        });
    }
    return {
        workorders,
        workorder,
        unit,
        vendor_all_workorders,
        yardList,
        jobList,
        user_preference,
        new_workorders,
        old_workorders,
        open_workorders,
        log_workorders,
        modules: BotModules
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getWorkorders: (req) => dispatch(workorderActions.getWorkorders(req)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getAllVendor: (req) => dispatch(workorderActions.getAllVendor(req)),
        getAllYards: () => dispatch(yardActions.getAll()),
        getAllJobs: () => dispatch(jobActions.getAll()),
        updateWorkorder: (wo) => dispatch(workorderActions.updateWorkorder(wo)),
        getAllNew: (req) => dispatch(workorderActions.getNewWorkorders(req)),
        getAllOld: (req) => dispatch(workorderActions.getOldWorkorders(req)),
        getAllOpen: (req) => dispatch(workorderActions.getOpenWorkorders(req)),
        getAllLog: (req) => dispatch(workorderActions.getLogWorkorders(req)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderTable);
