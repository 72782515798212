import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from '../../../components/bot-components';
import BotUnit from './BotUnit';
import PropTypes from 'prop-types';

const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    unit: PropTypes.object,
    parentComp: PropTypes.string
};
const BotUnitModal = (props) => {
    const {isOpen, toggle, unit, parentComp} = props;
    return (
        <Modal size={'xl'} isOpen={isOpen} className={props.className} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Unit detail for {unit.items ? unit.items.number : ''}
            </ModalHeader>
            <ModalBody>
                <BotUnit unit={unit} isModal parentComp={parentComp}/>
            </ModalBody>
            <ModalFooter>
                <Button outline color={'texts'} onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
BotUnitModal.propTypes = propTypes;
export default BotUnitModal;
