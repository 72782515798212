import {tireSizeConstants} from '../constants';

export const tire_sizes = (state = {}, action) => {
    switch (action.type) {
        case tireSizeConstants.GET_ALL_TIRE_SIZES_REQUEST:
            return {
                loading: true
            };
        case tireSizeConstants.GET_ALL_TIRE_SIZES_SUCCESS:
            return {
                items: action.tire_sizes
            };
        case tireSizeConstants.GET_ALL_TIRE_SIZES_FAILURE:
            return {
                error: action.error
            };
        case tireSizeConstants.TIRE_SIZE_CREATE_REQUEST:
            return {
                loading: true
            };
        case tireSizeConstants.TIRE_SIZE_CREATE_SUCCESS:
            return {
                items: action.tire_sizes
            };
        case tireSizeConstants.TIRE_SIZE_CREATE_FAILURE:
            return {
                error: action.error
            };
        case tireSizeConstants.TIRE_SIZE_UPDATE_REQUEST:
            return {
                loading: true
            };
        case tireSizeConstants.TIRE_SIZE_UPDATE_SUCCESS:
            return {
                items: action.tire_sizes
            };
        case tireSizeConstants.TIRE_SIZE_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const tire_size = (state = {}, action) => {
    switch (action.type) {
        case tireSizeConstants.TIRE_SIZE_UPDATE_REQUEST:
            return {
                loading: true
            };
        case tireSizeConstants.TIRE_SIZE_UPDATE_SUCCESS:
            return {
                items: action.tire_size
            };
        case tireSizeConstants.TIRE_SIZE_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
