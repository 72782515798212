import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalBody,
    Input,
    InputGroup,
    InputGroupAddon, UncontrolledTooltip
} from '../../components/bot-components';
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    extraProps: PropTypes.any
};
const StatusNoteEditor = React.forwardRef((props, ref) => {
    const {row, toggle, onUpdate, isOpen} = props;
    const inputRef = React.useRef();
    const [state, updateState] = React.useState({
        id: row.id,
        general_status: row.general_status
    });
    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle,
        state: state,
        setState: (newState) => setState(newState)
    }));
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const focusInput = () => {
        inputRef.current?.focus();
    };
    const handleChange = (e) => {
        const {value} = e.target;
        setState({
            general_status: value
        });
    };
    const updateData = () => {
        onUpdate(state);
        toggle();
    };
    const {general_status} = state;
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');
    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} centered={true} onOpened={() => focusInput()}>
            <ModalBody>
                <InputGroup>
                    <Input type={'textarea'}
                           ref={inputRef}
                           value={general_status}
                           onChange={handleChange}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
                </InputGroup>
            </ModalBody>
        </Modal>
    );
});
StatusNoteEditor.propTypes = propTypes;
export default StatusNoteEditor;
