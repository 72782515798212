import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { asideMenuCssClasses } from './Shared/index';
import toggleClasses from './Shared/toggle-classes';

const _extends = Object.assign || function (target) {
    for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];
        for (let key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    let target = {};
    for (let i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i) && keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError("Cannot call a class as a function");
    }
}

function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
        throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    display: PropTypes.any,
    mobile: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    type: PropTypes.string
};

let defaultProps = {
    display: 'lg',
    mobile: false,
    tag: 'button',
    type: 'button'
};

const AppAsideToggler = function (_Component) {
    _inherits(AppAsideToggler, _Component);

    function AppAsideToggler(props) {
        _classCallCheck(this, AppAsideToggler);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.asideToggle = _this.asideToggle.bind(_this);

        _this.state = {};
        return _this;
    }

    AppAsideToggler.prototype.asideToggle = function asideToggle(e) {
        e.preventDefault();

        if (this.props.mobile) {
            document.body.classList.toggle('aside-menu-show');
        } else {
            let display = this.props.display;
            let cssTemplate = 'aside-menu-' + display + '-show';
            let _asideMenuCssClasses$ = asideMenuCssClasses[0],
                cssClass = _asideMenuCssClasses$[0];

            if (display && asideMenuCssClasses.indexOf(cssTemplate) > -1) {
                cssClass = cssTemplate;
            }
            toggleClasses(cssClass, asideMenuCssClasses);
        }
    };

    AppAsideToggler.prototype.render = function render() {
        let _this2 = this;

        let _props = this.props,
            className = _props.className,
            children = _props.children,
            type = _props.type,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'type', 'tag']);

        delete attributes.display;
        delete attributes.mobile;
        delete attributes.display;

        let classes = classNames(className, 'navbar-toggler');

        return React.createElement(
            Tag,
            _extends({
                type: type,
                className: classes
            }, attributes, {
                onClick: function onClick(event) {
                    return _this2.asideToggle(event);
                }
            }),
            children || React.createElement('span', { className: 'navbar-toggler-icon' })
        );
    };

    return AppAsideToggler;
}(Component);

AppAsideToggler.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppAsideToggler.defaultProps = defaultProps;

export default AppAsideToggler;
