// noinspection JSValidateTypes

import React from 'react';
import {BotTable, DropdownItem} from '../../../components/bot-components';
import {StatusNoteEditor, YardEditor, JobEditor} from '../../Editors';
import {propTypes, defaultFilters, defaultState, getColumns} from '../BotWorkOrderDetail/BotWorkOrders.constants';
import {ExpandedWorkOrder} from '../BotWorkOrderDetail/BotWorkOrders.helpers';
import WorkOrderDetailModal from '../WorkOrderDetail/WorkOrderDetailModal';
import WorkOrderCreate from '../WorkOrderCreate/WorkOrderCreate';
import BotUnitModal from '../../Units/BotUnit/BotUnitModal';

let table_key_iterator = 0;

const BotWorkOrders = React.forwardRef(
    /**
     * @param props
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getWorkorders
     * @param props.updateWorkorder
     * @param props.createWorkorder
     * @param props.yardList
     * @param props.jobList
     * @param props.className
     * @param props.includeDateRange
     * @param props.canCreate
     * @param props.getMyWorkOrder
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const idRef = React.useRef();
    const createdAtRef = React.useRef();
    const updatedAtRef = React.useRef();
    const completedAtRef = React.useRef();
    const workorder_statusRef = React.useRef();
    const general_statusRef = React.useRef();
    const unit_numberRef = React.useRef();
    const unit_statusRef = React.useRef();
    const workorder_priorityRef = React.useRef();
    const dotRef = React.useRef();
    const deficient_propertiesRef = React.useRef();
    const unit_typeRef = React.useRef();
    const unit_classRef = React.useRef();
    const unit_descriptionRef = React.useRef();
    const other_descriptionRef = React.useRef();
    const vendorRef = React.useRef();
    const mechanicRef = React.useRef();
    const jobRef = React.useRef();
    const yardRef = React.useRef();
    const mapRef = React.useRef();
    const gsRef = React.useRef();
    const yRef = React.useRef();
    const jRef = React.useRef();
    const refs = [idRef, createdAtRef, updatedAtRef, completedAtRef, workorder_statusRef, general_statusRef, unit_numberRef,
        unit_statusRef, workorder_priorityRef, dotRef, deficient_propertiesRef, unit_typeRef, unit_classRef, unit_descriptionRef,
        other_descriptionRef, vendorRef, mechanicRef, jobRef, yardRef, mapRef];
    const [state, updateState] = React.useState({
        ...defaultState,
        lStorage: props.lStorage,
        remote: props.remote,
        selectedRow: null,
        selectedCell: null,
        jobEditor: false,
        yardEditor: false,
        statusEditor: false,
        pageSizes: props.pageSizes ?? ['20', '40', '80', '160'],
        workorderModal: false,
        createWorkOrderModal: false,
        unitModal: false
    });
    const setState = (newState) => (updateState(
        /**
         *
         * @param {object} prevState
         * @returns {*}
         */
        prevState => ({
        ...prevState,
        ...newState
    })));

    const editGeneralStatus = (row, cell) => setState({
        selectedRow: row,
        selectedCell: cell,
        statusEditor: true
    });

    const toggleStatusEditor = () => setState({
        statusEditor: false,
        selectedRow: null,
        selectedCell: null
    });

    const editJob = (row, cell) => setState({
        selectedRow: row,
        selectedCell: cell,
        jobEditor: true
    });

    const toggleJobEditor = () => setState({
        jobEditor: !state.jobEditor,
        selectedRow: null,
        selectedCell: null
    });

    const editYard = (row, cell) => setState({
        selectedRow: row,
        selectedCell: cell,
        yardEditor: true
    });

    const toggleYardEditor = () => setState({
        yardEditor: !state.yardEditor,
        selectedRow: null,
        selectedCell: null
    });

    const table_keys = () => `${state.tblId}_${table_key_iterator++}`;

    const toggleCreateWorkOrder = () => setState({createWorkOrderModal: !state.createWorkOrderModal});

    const toggleUnitModal = () => setState({unitModal: !state.unitModal});

    const toggleWorkOrderModal = () => setState({workorderModal: !state.workorderModal});

    const refreshWorkorders = () => {
        const req = {
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            currentPage: state.currPage,
            sizePerPage: state.currSize,
            filters: state.filters
        };
        state.remote ? props.getWorkorders(req) : props.getWorkorders?.();
    };

    const createNewBtn = () => (<DropdownItem outline color={'texts'} onClick={() => toggleCreateWorkOrder()}><i className={'fa fa-plus'}/> New</DropdownItem>);

    const FilterDotBtn = () => (<DropdownItem outline color={'texts'} className={(ref?.current?.state?.filters?.dot === true) ? 'active' : ''} key={table_keys()} onClick={() => {filterDotOnly()}}>DOT Only</DropdownItem>);

    const FilterDotIcon = () => (<span className={'clear-dot-icon'}><span onClick={() => filterDotOnly()}>DOT</span><span><i className={'clear-icon'} hidden={!(ref?.current?.state?.filters?.dot === true || ref?.current?.state?.filters?.dot === 'true')} key={table_keys()} onClick={() => {ref?.current?.setFilter('dot', "")}}/></span></span>);

    const filterDotOnly = () => {
            const filter = dotRef.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter("");
        };

    const updateWorkOrder = (wo) => {
        props.updateWorkorder(wo);
        refreshWorkorders();
    };

    const onSaveWorkorder = (wo) => {
        props.createWorkorder(wo);
        toggleCreateWorkOrder();
    };

    let {pageSizes, selectedRow, jobEditor, yardEditor, statusEditor, createWorkOrderModal} = state;
    let {title, data, unit, unitStatusList} = props;

    const columns = getColumns(props, refs, toggleWorkOrderModal, toggleUnitModal, editGeneralStatus, editJob, editYard);
    const {ids} = data?.[0] || [];
    return (
        <>
            {selectedRow && <StatusNoteEditor ref={gsRef} isOpen={statusEditor} toggle={() => toggleStatusEditor()} onUpdate={(wo) => updateWorkOrder(wo)} row={selectedRow}/>}
            {selectedRow && <YardEditor ref={yRef} isOpen={yardEditor} toggle={() => toggleYardEditor()} onUpdate={(wo) => updateWorkOrder(wo)} row={selectedRow} extraProps={props.yardList}/>}
            {selectedRow && <JobEditor ref={jRef} isOpen={jobEditor} toggle={() => toggleJobEditor()} onUpdate={(wo) => updateWorkOrder(wo)} row={selectedRow} extraProps={props.jobList}/>}
            {<WorkOrderCreate isOpen={createWorkOrderModal} toggle={() => toggleCreateWorkOrder()} unitStatusList={unitStatusList} unit={unit} onSaveWorkorder={(wo) => onSaveWorkorder(wo)}/>}
            <WorkOrderDetailModal isOpen={state.workorderModal} toggle={toggleWorkOrderModal} ids={ids}/>
            <BotUnitModal isOpen={state.unitModal} toggle={() => toggleUnitModal()} unit={unit} parentComp={'unit'}/>
            <BotTable refProp={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      getData={(req) => props.getWorkorders && props.getWorkorders(req)}
                      defaultState={defaultState}
                      specialFilters={props.specialFilters}
                      pageSizes={pageSizes}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      pagination={'both'}
                      headers={true}
                      expandable={true}
                      expandableComponent={ExpandedWorkOrder}
                      doRowUpdate={(wo) => props.updateWorkorder(wo)}
                      btnGroupDirection={'left'}
                      remote={props.remote}
                      lStorage={props.lStorage}
                      defaultFilters={defaultFilters}
                      dateRangeBtns={props.includeDateRange ? ["30", "60", "90", "All"] : []}
                      dateRangeIcons={props.includeDateRange ? ["30", "60", "90", "All"] : []}
                      onlyFilters={[FilterDotBtn]}
                      CreateNewBtn={props.canCreate && createNewBtn}
                      onRowDoubleClick={(row) => {props.getMyWorkOrder(row.id); toggleWorkOrderModal()}}
                      onlyFiltersIcons={[FilterDotIcon]}
                      showTitle={props.showTitle}
                      loading={props.loading}
                      csvFile={props.csvFile}
                      csvUrl={props.csvUrl}
            />
        </>
    );
});

BotWorkOrders.propTypes = propTypes;

export default BotWorkOrders;
