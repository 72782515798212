import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const unitTypeService = {};

const apiUrl = baseUrl;

unitTypeService.createUnitType = (unit_type) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(unit_type)
    };

    return fetch(`${apiUrl}/unit_type`, requestOptions).then(handleResponse);
};

unitTypeService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_types`, requestOptions).then(handleResponse)
};

unitTypeService.getType = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_type/` + id, requestOptions).then(handleResponse);
};

unitTypeService.updateUnitType = (unit_type) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(unit_type)
    };

    return fetch(`${apiUrl}/unit_type/${unit_type.id}`, requestOptions).then(handleResponse);
};
