import React from 'react';
import {
  Button,
  Card,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Input, CardBody
} from '../../../components/bot-components';
import 'print-js';
import moment from "moment";
import ReduxBlockUi from 'react-block-ui';
import {_dateOnlyFormat, authHeader} from '../../../helpers';
import {baseUrl} from "../../../network/constants";
import PropTypes from 'prop-types';

const propTypes = {
  unit: PropTypes.object
};
const UnitReport = (props) => {
  const [state, updateState] = React.useState({
    startDate: new moment().subtract(12, 'month').format('YYYY-MM-DD'),
    endDate: new moment().format('YYYY-MM-DD'),
    blocked: false,
    unit_id_array: [props.unit?.items?.id]
  });
  const setState = newState => updateState((prevState) => ({
    ...prevState,
    ...newState
  }));

  const handleStartDateChange = (e) => {
    const {value} = e.target
    setState({
      startDate: value
    });
  };
  const handleEndDateChange = (date) => {
    setState({
      endDate: date
    });
  };
  const downloadReport = () => {
    const {unit} = props;
    setState({blocked: true});
    const requestOptions = {
      method: 'POST',
      headers: authHeader('POST'),
      body: JSON.stringify({
        unitIds: [props.unit?.items?.id],
        startDate: state.startDate,
        endDate: state.endDate})
    };
    return fetch(`${baseUrl}/units/dot`, requestOptions).then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${unit.items.number}_maintenance_record.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).then(() => setState({blocked: false}));
    });
  }

    const {unit, getMyUnit} = props;
  const {unit_id_array} = state;
    if (!unit.items && unit_id_array[0] != null && !unit.loading) {
      getMyUnit(unit_id_array[0]);
    }
    return (
        <ReduxBlockUi tag={'div'} blocking={state.blocked}>
          <Card style={{overflow: 'visible'}}>
            <CardBody>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <InputGroup>
                  <InputGroupAddon addonType={'prepend'}><InputGroupText>Start</InputGroupText></InputGroupAddon>
                  <Input type={'date'} onChange={(e) => handleStartDateChange(e)} value={_dateOnlyFormat(state.startDate)}/>
                </InputGroup>
                <InputGroup>
                  <InputGroupAddon addonType={'prepend'}><InputGroupText>End</InputGroupText></InputGroupAddon>
                  <Input type={'date'} onChange={(e) => handleEndDateChange(e)} value={_dateOnlyFormat(state.endDate)}/>
                </InputGroup>
                <Button style={{maxHeight: '2.1rem', marginTop: '.5rem'}} outline color={'texts'} className={'float-right'} onClick={() => downloadReport()}>Get Report</Button>
              </div>
              {/*<Row>
                <Col sm={3}>
                  Start: <DatePicker onChange={handleStartDateChange}
                                     dateFormatCalendar={'yyyy-MM-dd'}
                                     dateFormat={'yyyy-MM-dd'}
                                     selected={new Date(state.startDate)}
                                     onMonthChange={handleStartDateChange}
                                     dropdownMode={'select'}
                                     style={{paddingLeft: '0', zIndex: 4}}
                />
                </Col>
                <Col sm={3}>
                  End: <DatePicker onChange={handleEndDateChange}
                                   dateFormatCalendar={'yyyy-MM-dd'}
                                   dateFormat={'yyyy-MM-dd'}
                                   selected={new Date(state.endDate)}
                                   onMonthChange={handleEndDateChange}
                                   dropdownMode={'select'}
                                   style={{paddingLeft: '0'}}
                />
                </Col>
                <Col sm={3}/>
                <Col sm={3}>
                  <Button outline color={'texts'} className={'float-right'} onClick={() => downloadReport()}>Get Report</Button>
                </Col>
              </Row>*/}
            </CardBody>
          </Card>
        </ReduxBlockUi>
    );
};
UnitReport.propTypes = propTypes;
export default UnitReport;
