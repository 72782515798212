import {faultConstants} from '../constants';

export const faults = (state = {}, action) => {
    switch (action.type) {
        case faultConstants.GET_ALL_FAULTS_REQUEST:
            return {
                loading: true
            };
        case faultConstants.GET_ALL_FAULTS_SUCCESS:
            return {
                items: action.faults
            };
        case faultConstants.GET_ALL_FAULTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const unassigned_faults = (state = {}, action) => {
    switch (action.type) {
        case faultConstants.GET_UNASSIGNED_FAULTS_REQUEST:
            return {
                loading: true
            };
        case faultConstants.GET_UNASSIGNED_FAULTS_SUCCESS:
            return {
                items: action.unassigned_faults
            };
        case faultConstants.GET_UNASSIGNED_FAULTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const fault = (state = {}, action) => {
    switch (action.type) {
        case faultConstants.DETAIL_FAULT_REQUEST:
            return {
                loading: true
            };
        case faultConstants.DETAIL_FAULT_SUCCESS:
            return {
                items: action.fault
            };
        case faultConstants.DETAIL_FAULT_FAILURE:
            return {
                error: action.error
            };
        case faultConstants.CREATE_FAULT_REQUEST:
            return {
                loading: true
            };
        case faultConstants.CREATE_FAULT_SUCCESS:
            return {
                items: action.fault
            };
        case faultConstants.CREATE_FAULT_FAILURE:
            return {
                error: action.error
            };
        case faultConstants.UPDATE_FAULT_REQUEST:
            return {
                loading: true
            };
        case faultConstants.UPDATE_FAULT_SUCCESS:
            return {
                items: action.fault
            };
        case faultConstants.UPDATE_FAULT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
