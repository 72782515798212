import React from 'react';
import {Row, Col} from './'

export const createColumnMenu = (columns, panes, table_keys, changeHidden) => {
    let col1 = [];
    let col2 = [];
    columns && columns.map((c, index) => {
        if (panes && c && c.field !== 'expand' && c.field !== 'button') if (index % 2 === 0) return col1.push(
            <div key={table_keys()} className={'bot-table-column-menu-control'}>
                <input name={c.field}
                       id={c.field}
                       className={'bot-table-column-menu-control'}
                       type={'checkbox'}
                       onChange={(e) => changeHidden(e)}
                       defaultChecked={panes[c.field].hidden}
                />
                <label htmlFor={c.field}
                       className={'bot-table-column-menu-label'}
                       onClick={
                           /**
                            *
                            * @param e
                            * @param e.target.parentElement.firstChild.checked
                            */
                           (e) => {
                           changeHidden({target: {name: c.field, checked: !e.target.parentElement.firstChild.checked}});
                       }}>{c.text}</label>
            </div>); else return col2.push(
            <div key={table_keys()} className={'bot-table-column-menu-control'}>
                <input name={c.field}
                       id={c.field}
                       className={'bot-table-column-menu-control'}
                       type={'checkbox'}
                       onChange={(e) => changeHidden(e)}
                       defaultChecked={panes[c.field]?.hidden}
                />
                <label htmlFor={c.field}
                       className={'bot-table-column-menu-label'}
                       onClick={
                           /**
                            *
                            * @param e
                            * @param e.target.parentElement.firstChild.checked
                            */
                               (e) => {
                           changeHidden({target: {name: c.field, checked: !e.target.parentElement.firstChild.checked}});
                       }}>{c.text}</label>
            </div>); else return null;
    });
    return (<Row className={'bot-table-column-menu-row'}>
        <Col sm={6}>
            {col1}
        </Col>
        <Col sm={6}>
            {col2}
        </Col>
    </Row>)
};
