/*Handles all actions for the part model on the server*/
import {plmAlertConstants} from '../constants';
import {plmAlertService} from '../services';

export const plmAlertActions = {};
/*Get full list of parts request.*/
plmAlertActions.getAll = (req) => {
    return dispatch => {
        dispatch(request());

        plmAlertService.getAll(req)
            .then(
                plm_alerts => {
                    dispatch(success(plm_alerts))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: plmAlertConstants.GET_ALL_PLM_ALERTS_REQUEST}
    }

    function success(plm_alerts) {
        return {type: plmAlertConstants.GET_ALL_PLM_ALERTS_SUCCESS, plm_alerts}
    }

    function failure(error) {
        return {type: plmAlertConstants.GET_ALL_PLM_ALERTS_FAILURE, error}
    }

    //</editor-fold>
};
/*Get part detail request.*/
plmAlertActions.getDetail = (id) => {
    return dispatch => {
        dispatch(request());

        plmAlertService.getDetail(id)
            .then(
                plm_alert => {
                    dispatch(success(plm_alert))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: plmAlertConstants.DETAIL_PLM_ALERT_REQUEST}
    }

    function success(plm_alert) {
        return {type: plmAlertConstants.DETAIL_PLM_ALERT_SUCCESS, plm_alert}
    }

    function failure(error) {
        return {type: plmAlertConstants.DETAIL_PLM_ALERT_FAILURE, error}
    }

    //</editor-fold>
};
