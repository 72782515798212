export const workorderConstants = {
    GET_ALL_WORKORDERS_REQUEST: 'WORKORDERS_GET_ALL_REQUEST',
    GET_ALL_WORKORDERS_SUCCESS: 'WORKORDERS_GET_ALL_SUCCESS',
    GET_ALL_WORKORDERS_FAILURE: 'WORKORDERS_GET_ALL_FAILURE',

    DETAIL_WORKORDER_REQUEST: 'WORKORDER_DETAIL_REQUEST',
    DETAIL_WORKORDER_SUCCESS: 'WORKORDER_DETAIL_SUCCESS',
    DETAIL_WORKORDER_FAILURE: 'WORKORDER_DETAIL_FAILURE',

    CREATE_WORKORDER_REQUEST: 'WORKORDER_CREATE_REQUEST',
    CREATE_WORKORDER_SUCCESS: 'WORKORDER_CREATE_SUCCESS',
    CREATE_WORKORDER_FAILURE: 'WORKORDER_CREATE_FAILURE',

    UPDATE_WORKORDER_REQUEST: 'WORKORDER_UPDATE_REQUEST',
    UPDATE_WORKORDER_SUCCESS: 'WORKORDER_UPDATE_SUCCESS',
    UPDATE_WORKORDER_FAILURE: 'WORKORDER_UPDATE_FAILURE',

    GET_REPORT_DATA_REQUEST: 'GET_REPORT_DATA_REQUEST',
    GET_REPORT_DATA_SUCCESS: 'GET_REPORT_DATA_SUCCESS',
    GET_REPORT_DATA_FAILURE: 'GET_REPORT_DATA_FAILURE',

    GET_LIGHT_REPORT_DATA_REQUEST: 'GET_LIGHT_REPORT_DATA_REQUEST',
    GET_LIGHT_REPORT_DATA_SUCCESS: 'GET_LIGHT_REPORT_DATA_SUCCESS',
    GET_LIGHT_REPORT_DATA_FAILURE: 'GET_LIGHT_REPORT_DATA_FAILURE',

    OPEN_WORKORDERS_REQUEST: 'WORKORDERS_OPEN_REQUEST',
    OPEN_WORKORDERS_SUCCESS: 'WORKORDERS_OPEN_SUCCESS',
    OPEN_WORKORDERS_FAILURE: 'WORKORDERS_OPEN_FAILURE',

    NEW_WORKORDERS_REQUEST: 'WORKORDERS_NEW_REQUEST',
    NEW_WORKORDERS_SUCCESS: 'WORKORDERS_NEW_SUCCESS',
    NEW_WORKORDERS_FAILURE: 'WORKORDERS_NEW_FAILURE',

    OLD_WORKORDERS_REQUEST: 'WORKORDERS_OLD_REQUEST',
    OLD_WORKORDERS_SUCCESS: 'WORKORDERS_OLD_SUCCESS',
    OLD_WORKORDERS_FAILURE: 'WORKORDERS_OLD_FAILURE',

    LOG_WORKORDERS_REQUEST: 'WORKORDERS_LOG_REQUEST',
    LOG_WORKORDERS_SUCCESS: 'WORKORDERS_LOG_SUCCESS',
    LOG_WORKORDERS_FAILURE: 'WORKORDERS_LOG_FAILURE',

    GET_OPEN_COUNT_REQUEST: 'GET_OPEN_COUNT_REQUEST',
    GET_OPEN_COUNT_SUCCESS: 'GET_OPEN_COUNT_SUCCESS',
    GET_OPEN_COUNT_FAILURE: 'GET_OPEN_COUNT_FAILURE',

    VENDOR_ALL_WORKORDERS_REQUEST: 'WORKORDERS_VENDOR_ALL_REQUEST',
    VENDOR_ALL_WORKORDERS_SUCCESS: 'WORKORDERS_VENDOR_ALL_SUCCESS',
    VENDOR_ALL_WORKORDERS_FAILURE: 'WORKORDERS_VENDOR_ALL_FAILURE',
};
