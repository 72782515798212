/*Handles all actions for accessing report data from server.*/
import {reportConstants} from '../constants/';
import {reportService} from '../services/';

export const reportActions = {};

reportActions.getKPIData = (date) => {
  return dispatch => {
    dispatch(request());

    reportService.getKPIData(date)
      .then(
        kpi_data => {
          dispatch(success(kpi_data))
        },
        error => {

          dispatch(failure(error))
        }
      );
  };

  //<editor-fold desc="Dispatch results to state">
  function request() {
    return {type: reportConstants.KPI_DATA_REQUEST}
  }

  function success(data) {
    return {type: reportConstants.KPI_DATA_SUCCESS, data}
  }

  function failure(error) {
    return {type: reportConstants.KPI_DATA_FAILURE, error}
  }
  //</editor-fold>
};

reportActions.getWorkorderReport = () => {
  return dispatch => {
    dispatch(request());

    reportService.getWorkorderReport()
        .then(
            workorder_report => {
              dispatch(success(workorder_report))
            },
            error => {

              dispatch(failure(error))
            }
        );
  };

  //<editor-fold desc="Dispatch results to state">
  function request() {
    return {type: reportConstants.WORKORDER_REPORT_REQUEST}
  }

  function success(data) {
    return {type: reportConstants.WORKORDER_REPORT_SUCCESS, data}
  }

  function failure(error) {
    return {type: reportConstants.WORKORDER_REPORT_FAILURE, error}
  }
  //</editor-fold>
};

reportActions.getMaintReportWo = (month) => {
  return dispatch => {
    dispatch(request());

    reportService.getMaintReportWo(month)
        .then(
            maint_report_wo => {
              dispatch(success(maint_report_wo))
            },
            error => {

              dispatch(failure(error))
            }
        );
  };

  //<editor-fold desc="Dispatch results to state">
  function request() {
    return {type: reportConstants.MAINT_REPORT_WO_REQUEST}
  }

  function success(data) {
    return {type: reportConstants.MAINT_REPORT_WO_SUCCESS, data}
  }

  function failure(error) {
    return {type: reportConstants.MAINT_REPORT_WO_FAILURE, error}
  }
  //</editor-fold>
};

reportActions.getMaintReportTask = (month) => {
    return dispatch => {
        dispatch(request());

        reportService.getMaintReportTask(month)
            .then(
                maint_report_task => {
                    dispatch(success(maint_report_task))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.MAINT_REPORT_TASK_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.MAINT_REPORT_TASK_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.MAINT_REPORT_TASK_FAILURE, error}
    }
    //</editor-fold>
};

reportActions.getMaintReportWoDetail = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getMaintReportWoDetail(body)
            .then(
                maint_report_wo_detail => {
                    dispatch(success(maint_report_wo_detail))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.MAINT_REPORT_WO_DETAIL_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.MAINT_REPORT_WO_DETAIL_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.MAINT_REPORT_WO_DETAIL_FAILURE, error}
    }
    //</editor-fold>
};

reportActions.getMaintReportTaskDetail = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getMaintReportTaskDetail(body)
            .then(
                maint_report_task_detail => {
                    dispatch(success(maint_report_task_detail))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.MAINT_REPORT_TASK_DETAIL_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.MAINT_REPORT_TASK_DETAIL_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.MAINT_REPORT_TASK_DETAIL_FAILURE, error}
    }
    //</editor-fold>
};

reportActions.clearMaintReportWoDetail = () => {
    return dispatch => {
        dispatch(request());

        dispatch(success([]))
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.MAINT_REPORT_WO_DETAIL_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.MAINT_REPORT_WO_DETAIL_SUCCESS, data}
    }
    //</editor-fold>
};

reportActions.clearMaintReportTaskDetail = () => {
    return dispatch => {
        dispatch(request());

        dispatch(success([]));
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.MAINT_REPORT_TASK_DETAIL_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.MAINT_REPORT_TASK_DETAIL_SUCCESS, data}
    }
    //</editor-fold>
};

reportActions.getInsReport = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getInsReport(body).then(
            ins_report => {
                dispatch(success(ins_report));
            },
            error => {
                dispatch(failure(error));
            }
        )
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.INSPECTION_REPORT_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.INSPECTION_REPORT_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.INSPECTION_REPORT_FAILURE, error}
    }
    //</editor-fold>
};

reportActions.getForeReport = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getForeReport(body).then(
            fore_report => {
                dispatch(success(fore_report));
            },
            error => {
                dispatch(failure(error));
            }
        )
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.FOREMAN_REPORT_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.FOREMAN_REPORT_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.FOREMAN_REPORT_FAILURE, error}
    }
    //</editor-fold>
};

reportActions.getDotInspectionExceptions = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getDotInspectionExceptions(body).then(
            dot_inspection_exceptions => {
                dispatch(success(dot_inspection_exceptions));
            },
            error => {
                dispatch(failure(error));
            }
        )
    };

    function request() {
        return {type: reportConstants.DOT_INSPECTION_REPORT_REQUEST}
    }
    function success(data) {
        return {type: reportConstants.DOT_INSPECTION_REPORT_SUCCESS, data}
    }
    function failure(error) {
        return {type: reportConstants.DOT_INSPECTION_REPORT_FAILURE, error}
    }
};

reportActions.getDotTaskExceptions = (req) => {
    return dispatch => {
        dispatch(request());

        reportService.getDotTaskExceptions(req).then(
            dot_task_exceptions => {
                dispatch(success(dot_task_exceptions));
            },
            error => {
                dispatch(failure(error));
            }
        )
    };

    function request() {
        return {type: reportConstants.DOT_TASK_REPORT_REQUEST};
    }

    function success(data) {
        return {type: reportConstants.DOT_TASK_REPORT_SUCCESS, data};
    }

    function failure(error) {
        return {type: reportConstants.DOT_TASK_REPORT_FAILURE, error};
    }
};

reportActions.getWorkOrderByJobReport = (body) => {
    return dispatch => {
        dispatch(request());

        reportService.getWorkOrderByJobReport(body).then(
            wor_order_report => {
                dispatch(success(wor_order_report));
            },
            error => {
                dispatch(failure(error));
            }
        )
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: reportConstants.WORK_ORDER_BY_JOB_REPORT_REQUEST}
    }

    function success(data) {
        return {type: reportConstants.WORK_ORDER_BY_JOB_REPORT_SUCCESS, data}
    }

    function failure(error) {
        return {type: reportConstants.WORK_ORDER_BY_JOB_REPORT_FAILURE, error}
    }
    //</editor-fold>
};
