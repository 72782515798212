import React from 'react';
import {_dateFormat, _dateOnlyFormat} from '../../../helpers';
import PropTypes from 'prop-types';
import {
    Row,
    Col
} from '../../../components/bot-components';

const propTypes = {
    unit: PropTypes.object
};

const UnitDetail = ({unit}) => {
    return(
        <>
            <Row>
                <Col sm={3}>
                    <label style={{fontWeight: 'bold', fontSize: '1rem'}}>Status:</label>
                    <label className={'unit_status-' + unit.items?.unit_status_id ?? ''} style={{fontSize: 18, paddingLeft: 5, fontWeight: 'bold'}}>{unit.items?.unit_status}</label>
                </Col>
                <Col sm={3}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Location:</label>
                        <label className={'unit_detail-field'}>{unit.items?.yard}</label>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'} htmlFor={'job'}>Job:</label>
                        <label id={'job'} className={'unit_detail-field'}>{unit.items?.job}</label>
                    </Row>
                </Col>
                <Col sm={3}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>LK Operator:</label>
                        <label className={'unit_detail-field'}>{unit.items?.operator}</label>
                    </Row>
                </Col>
            </Row>
            <hr className={'page-divider'}/>
            <Row>
                <Col sm={4}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Description:</label>
                        <label className={'unit_detail-field'}>{unit.items?.description}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Record ID:</label>
                        <label className={'unit_detail-field'}>{unit.items?.id}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>VIN/SN:</label>
                        <label className={'unit_detail-field'}>{unit.items?.vin_sn}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Type:</label>
                        <label className={'unit_detail-field'}>{unit.items?.unit_type}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Class:</label>
                        <label className={'unit_detail-field'}>{unit.items?.unit_subtype}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Owned By:</label>
                        <label className={'unit_detail-field'}>{unit.items?.company}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Plate:</label>
                        <label className={'unit_detail-field'}>{unit.items?.plate}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Reg. Date:</label>
                        <label className={'unit_detail-field'}>{_dateOnlyFormat(unit.items?.registration_date ?? null)}</label>
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Miles:</label>
                        <label className={'unit_detail-field'}>{unit.items?.mileage}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Hours:</label>
                        <label className={'unit_detail-field'}>{unit.items?.hours}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>CADR:</label>
                        <label className={'unit_detail-field'}>{unit.items?.cadr}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Distinct Location:</label>
                        <label className={'unit_detail-field'}>{unit.items?.location}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Tire Size:</label>
                        <label className={'unit_detail-field'}>{unit.items?.tire_size}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Tire Size 2:</label>
                        <label className={'unit_detail-field'}>{unit.items?.tire_size_2}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Tire Size 3:</label>
                        <label className={'unit_detail-field'}>{unit.items?.tire_size_3}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Tire Size 4:</label>
                        <label className={'unit_detail-field'}>{unit.items?.tire_size_4}</label>
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Asset Type:</label>
                        <label className={'unit_detail-field'}>{unit.items?.asset_type}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Tare Weight:</label>
                        <label className={'unit_detail-field'}>{unit.items?.tare_weight}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Gross Weight:</label>
                        <label className={'unit_detail-field'}>{unit.items?.gross_weight}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Height:</label>
                        <label className={'unit_detail-field'}>{unit.items?.height}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Wheelbase:</label>
                        <label className={'unit_detail-field'}>{unit.items?.wheelbase}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Axles:</label>
                        <label className={'unit_detail-field'}>{unit.items?.axles}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Overall Length:</label>
                        <label className={'unit_detail-field'}>{unit.items?.overall_length}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Horsepower:</label>
                        <label className={'unit_detail-field'}>{unit.items?.horsepower}</label>
                    </Row>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Width:</label>
                        <label className={'unit_detail-field'}>{unit.items?.width}</label>
                    </Row>
                </Col>
            </Row>
            <hr className={'page-divider'}/>
            <Row>
                <Col sm={6}>
                    <Row>
                        <label className={'unit_detail-label'}>Last Valid GPS: </label>
                        {unit.items?.last_known_gps ?
                            <label className={'unit_detail-field'}>
                                <a className="gps_location"
                                   href={`https://maps.google.com/?q=${unit.items?.last_known_gps}`}
                                   target={'_blank'} rel={'noopener noreferrer'}><i className={'map-pin-icon'}/> {unit.items?.last_known_gps_date}</a>
                            </label> : <label className={'unit_detail-field'}>None</label>
                        }
                    </Row>
                </Col>
                {/*<Col sm={3}>
                            <Row>
                                <label className={'unit_detail-label'}>Last Valid GPS Date: </label>
                                <label className={'unit_detail-field'}>{last_known_gps_date}</label>
                            </Row>
                        </Col>*/}
                <Col sm={6}>
                    <Row>
                        <label className={'unit_detail-label'}>Last Valid Inspection: </label>
                        {unit.items?.last_inspection_location ?
                            <label className={'unit_detail-field'}>
                                <a className="gps_location"
                                   href={`https://maps.google.com/?q=${unit.items?.last_inspection_location}`}
                                   target={'_blank'} rel={'noopener noreferrer'}><i className={'map-pin-icon'}/> {unit.items?.last_inspection_date}</a>
                            </label> : <label className={'unit_detail-field'}>None</label>
                        }
                    </Row>
                </Col>
                {/*<Col sm={3}>
                            <Row>
                                <label className={'unit_detail-label'}>Last Valid Ins. Date: </label>
                                <label className={'unit_detail-field'}>{last_inspection_date}</label>
                            </Row>
                        </Col>*/}
            </Row>
            <Row>
                <Col sm={6}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Updated By:</label>
                        <label className={'unit_detail-field'}>{unit.items?.updated_by}</label>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row className={'unit_detail-row'}>
                        <label className={'unit_detail-label'}>Modified Date:</label>
                        <label className={'unit_detail-field'}>{_dateFormat(unit.items?.updated_at)}</label>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

UnitDetail.propTypes = propTypes;

export default UnitDetail;
