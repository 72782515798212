export const attributeConstants = {
    CREATE_ATTRIBUTE_REQUEST: 'ATTRIBUTE_CREATE_REQUEST',
    CREATE_ATTRIBUTE_SUCCESS: 'ATTRIBUTE_CREATE_SUCCESS',
    CREATE_ATTRIBUTE_FAILURE: 'ATTRIBUTE_CREATE_FAILURE',

    GET_ALL_ATTRIBUTES_REQUEST: 'ATTRIBUTES_GET_ALL_REQUEST',
    GET_ALL_ATTRIBUTES_SUCCESS: 'ATTRIBUTES_GET_ALL_SUCCESS',
    GET_ALL_ATTRIBUTES_FAILURE: 'ATTRIBUTES_GET_ALL_FAILURE',

    GET_ALL_UNIT_ATTRIBUTES_REQUEST: 'ATTRIBUTES_GET_ALL_UNIT_REQUEST',
    GET_ALL_UNIT_ATTRIBUTES_SUCCESS: 'ATTRIBUTES_GET_ALL_UNIT_SUCCESS',
    GET_ALL_UNIT_ATTRIBUTES_FAILURE: 'ATTRIBUTES_GET_ALL_UNIT_FAILURE',

    GET_ALL_CLASS_ATTRIBUTES_REQUEST: 'ATTRIBUTES_GET_ALL_CLASS_REQUEST',
    GET_ALL_CLASS_ATTRIBUTES_SUCCESS: 'ATTRIBUTES_GET_ALL_CLASS_SUCCESS',
    GET_ALL_CLASS_ATTRIBUTES_FAILURE: 'ATTRIBUTES_GET_ALL_CLASS_FAILURE',

    DETAIL_ATTRIBUTE_REQUEST: 'ATTRIBUTE_DETAIL_REQUEST',
    DETAIL_ATTRIBUTE_SUCCESS: 'ATTRIBUTE_DETAIL_SUCCESS',
    DETAIL_ATTRIBUTE_FAILURE: 'ATTRIBUTE_DETAIL_FAILURE',

    UPDATE_ATTRIBUTE_REQUEST: 'ATTRIBUTE_UPDATE_REQUEST',
    UPDATE_ATTRIBUTE_SUCCESS: 'ATTRIBUTE_UPDATE_SUCCESS',
    UPDATE_ATTRIBUTE_FAILURE: 'ATTRIBUTE_UPDATE_FAILURE',
};
