/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Nav, NavItem, NavLink, TabContent, TabPane} from '../../components/bot-components';
import {reportActions} from '../../network/actions';
import "react-datepicker/dist/react-datepicker.css";
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import classnames from 'classnames';
import WorkOrderStatus from './MaintenanceReport/WorkOrderStatus';
import TaskStatus from './MaintenanceReport/TaskStatus';
import {
  jobActions,
  plmAlertActions,
  taskActions,
  taskPriorityActions,
  unitActions,
  userActions,
  vendorActions,
  workorderActions, yardActions
} from "../../network/actions";
drilldown(Highcharts);
const MaintReport = (props) => {
  const [state, updateState] = React.useState({
    activeTab: localStorage.getItem('mRTab') ?? 'wRef'
  });
  const setState = newState => updateState((prevState) => ({
    ...prevState,
    ...newState
  }));
  const wRef = React.useRef();
  const tRef = React.useRef();

  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
      localStorage.setItem('mRTab', tab);
    }
  };
  React.useEffect(() => {
    !props.yardList && props.getAllYards();
    !props.jobList && props.getAllJobs();
  }, []);

    return (
        <div>
            <Nav tabs>
              <NavItem>
                <NavLink className={classnames({active: state.activeTab === 'wRef'})} onClick={() => {toggle('wRef')}}>
                  Work Orders
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({active: state.activeTab === 'tRef'})} onClick={() => {toggle('tRef')}}>
                  Tasks
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activetab={state.activeTab}>
              <TabPane tabId={'wRef'}>
                <WorkOrderStatus ref={wRef}
                                 getMyUnit={(id) => props.getMyUnit(id)}
                                 getMaintReportWoDetail={(body) => props.getMaintReportWoDetail(body)}
                                 getMyWorkorder={(id) => props.getMyWorkorder(id)}
                                 maint_report_wo={props.maint_report_wo}
                                 maint_report_wo_detail={props.maint_report_wo_detail}
                                 unit={props.unit}
                                 jobList={props.jobList}
                                 yardList={props.yardList}
                                 updateWorkorder={(wo) => props.updateWorkorder(wo)}
                                 workorder={props.workorder} clearMaintReportWoDetail={() => props.clearMaintReportWoDetail()}
                                 user_preference={props.user_preference}
                                 getKPIData={(month) => props.getMaintReportWo(month)}/>
              </TabPane>
              <TabPane tabId={'tRef'}>
                <TaskStatus ref={tRef}
                            getKPIData={(month) => props.getMaintReportTask(month)}
                            getMaintReportTaskDetail={(month) => props.getMaintReportTaskDetail(month)}
                            getMyUnit={(id) => props.getMyUnit(id)}
                            getMyWorkorder={(id) => props.getMyWorkorder(id)}
                            maint_report_task={props.maint_report_task}
                            maint_report_task_detail={props.maint_report_task_detail}
                            clearMaintReportTaskDetail={() => props.clearMaintReportTaskDetail()}
                            updateTask={(task) => props.updateTask(task)}
                            task={props.task}
                            taskStatuses={props.taskStatuses}
                            vendList={props.vendList}
                            vendorList={props.vendorList}
                            mechList={props.mechList}
                            mechanicList={props.mechanicList}
                            PriorityList={props.PriorityList}
                            getMechanics={() => props.getMechanics()}
                            getVendors={() => props.getVendors()}
                            assignToMechanic={(task) => props.assignToMechanic(task)}
                            assignToVendor={(task) => props.assignToVendor(task)}
                            getTaskPriorities={() => props.getTaskPriorities()}
                            getAlert={(id) => props.getAlert(id)}
                            unit={props.unit}
                            workorder={props.workorder}
                            user_preference={props.user_preference}
                            getTask={(id) => props.getTask(id)}/>
              </TabPane>
            </TabContent>
        </div>
    );
};
const mapStateToProps = (state) => {
  const {maint_report_task, maint_report_wo, unit, workorder, maint_report_wo_detail, maint_report_task_detail, vendors, mechanics, task_priorities, task, jobs, yards, user_preference} = state;
  let taskStatuses = [false, true];
  let mechList = mechanics?.items?.map(m => ({label: m.name, text: m.name, value: m.id}));
  let vendorList = vendors?.items?.map(v => ({text: v.name, label: v.name, value: v.id}));
  let mechanicList = mechanics?.items?.map(m => ({label: m.name, value: m.id}));
  let vendList = vendors?.items?.map(v => ({text: v.name, label: v.name, value: v.id}));
  let PriorityList = task_priorities?.items?.map(tp => ({label: tp.priority, value: tp.id}));
  let yardList = yards?.items?.map(y => ({label: y.name, value: y.id}));
  let jobList = jobs?.items?.map(j => ({value: j.id, label: j.name}));

  return {maint_report_wo, maint_report_task, unit, workorder, maint_report_wo_detail, maint_report_task_detail, vendors, mechanics, task_priorities, task, taskStatuses, vendorList, mechList, vendList,
    mechanicList, PriorityList, yardList, jobList, user_preference};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMaintReportWo: (month) => dispatch(reportActions.getMaintReportWo(month)),
    getMaintReportTask: (month) => dispatch(reportActions.getMaintReportTask(month)),
    getMaintReportWoDetail: (body) => dispatch(reportActions.getMaintReportWoDetail(body)),
    clearMaintReportWoDetail: () => dispatch(reportActions.clearMaintReportWoDetail()),
    getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
    getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
    getMaintReportTaskDetail: (body) => dispatch(reportActions.getMaintReportTaskDetail(body)),
    clearMaintReportTaskDetail: () => dispatch(reportActions.clearMaintReportTaskDetail()),
    updateTask: (task) => dispatch(taskActions.updateTask(task)),
    getTask: (id) => dispatch(taskActions.getTask(id)),
    getMechanics: () => dispatch(userActions.getMechanics()),
    getVendors: () => dispatch(vendorActions.getAll()),
    assignToMechanic: (task) => dispatch(taskActions.assignToMechanic(task)),
    assignToVendor: (task) => dispatch(taskActions.assignToVendor(task)),
    getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
    getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
    updateWorkorder: (wo) => dispatch(workorderActions.updateWorkorder(wo)),
    getAllYards: () => dispatch(yardActions.getAll()),
    getAllJobs: () => dispatch(jobActions.getAll()),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(MaintReport);
