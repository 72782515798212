import {yardConstants} from '../constants';

export const yards = (state = {}, action) => {
    switch (action.type) {
        case yardConstants.GET_ALL_YARDS_REQUEST:
            return {
                loading: true
            };
        case yardConstants.GET_ALL_YARDS_SUCCESS:
            return {
                items: action.yards
            };
        case yardConstants.GET_ALL_YARDS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const yard = (state = {}, action) => {
    switch (action.type) {
        case yardConstants.CREATE_YARD_REQUEST:
            return {
                loading: true
            };
        case yardConstants.CREATE_YARD_SUCCESS:
            return {
                items: action.yard
            };
        case yardConstants.CREATE_YARD_FAILURE:
            return {
                error: action.error
            };
        case yardConstants.DETAIL_YARD_REQUEST:
            return {
                loading: true
            };
        case yardConstants.DETAIL_YARD_SUCCESS:
            return {
                items: action.yard
            };
        case yardConstants.DETAIL_YARD_FAILURE:
            return {
                error: action.error
            };
        case yardConstants.DELETE_YARD_REQUEST:
            return {
                loading: true
            };
        case yardConstants.DELETE_YARD_SUCCESS:
            return {
                items: []
            };
        case yardConstants.DELETE_YARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
