import {
  BotTableButtonGroup,
  BotTableHeaders,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  UncontrolledDropdown,
  UncontrolledTooltip
} from './index';
import React from 'react';
import { Pane } from 'react-sortable-pane';
import _ from 'lodash';
import { _dateFormat, authHeader } from '../../helpers';
import { baseUrl } from '../../network/constants';
import moment from 'moment';

const downloadAll = ( props, _columns, state ) => {
  const columns = _.cloneDeep(_columns);
  const tbl = state;
  let filters = _.cloneDeep( tbl.filters );
  const specialFilters = _.cloneDeep( props.specialFilters );

  function convertToCsv( objArray ) {
    let array = typeof objArray != 'object' ? JSON.parse( objArray ) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';
        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }

  filters = {
    ...filters,
    ...specialFilters
  };

  if (props.remote) {
    const req = {
      filters:filters,
      sortName:tbl.sortName,
      sortOrder:tbl.sortOrder,
      csv:true,
      filename:props.csvFile
    };
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( req )
    };
    const url = new URL( `${ baseUrl }/${ props.csvUrl }` );

    // Object.keys(req).forEach(k => {
    //     if (k === 'filters') Object.keys(filters).forEach(k1 => {
    //         if ((selects?.includes(k1) || _.startsWith(k1, 'bool_filed')) && filters[k1] !== '') url.searchParams.append(k1, JSON.stringify({
    //             op: '=',
    //             value: filters[k1]
    //         })); else if (filters[k1] !== '') url.searchParams.append(k1, filters[k1]);
    //     });
    //     else req[k] !== '' && url.searchParams.append(k, req[k]);
    // });
    return fetch( url, requestOptions ).then( response => {
      response.blob().then( blob => {
        const url = window.URL.createObjectURL( new Blob( [blob] ) );
        const link = document.createElement( 'a' );
        link.href = url;
        link.setAttribute( 'download', props.csvFile );
        document.body.appendChild( link );
        link.click();
        link.parentNode.removeChild( link );
      } );
    } );
  } else {

    const headers = {};
    const myCols = columns.filter( c => c.field !== 'button' && c.field !== 'expand' );
    let data = _.cloneDeep( props.data );
    if (data) {
      const Filtered = Object.keys( filters ).map( f => {
        const formatter = columns?.find(c => c.field === f)?.format;
        const myFilter = filters[f];
        if (filters[f] !== '""' && (filters[f].length > 0 || filters[f] === true || filters[f] === false || filters[f] === 1)) {
          if (filters[f] === true || filters[f] === false) {
            data = data.filter( d => d[f] === myFilter );
          } else if (filters[f] === 'true' || filters[f] === 'false') {
            data = data.filter( d => d[f] === (myFilter === 'true') );
          } else if (filters[f] === 1) {
            data = data.filter( d => d[f] === (filters[f]) );
          } else {
            data = data.filter( d => d[f] && (formatter?.(d[f], d) ?? d[f]).toString().toLowerCase().includes( filters[f].toLowerCase() ) );
          }
        }
        return 0;
      } );
      Promise.all( Filtered ).then( () => {
        myCols.map( c => {
          return headers[c.field] = c['text'];
        } );
        let dataObject = [];
        /**
         * @param d.created_at
         * @param d.updated_at
         */
        data.map( d => {
          Object.keys( d ).map( i => {
            if (d[i] !== undefined) {
              let format;
              switch (i) {
                case 'user_status_id':
                  return d[i] = d.user_status;
                case 'created_at':
                  return d[i] = _dateFormat( d.created_at );
                case 'updated_at':
                  return d[i] = _dateFormat( d.updated_at );
                default:
                  format = myCols.find( c => c.field === i )?.format;
                  return d[i] = typeof d[i] === 'string' ? '"'.concat( d[i], '"' ) : d[i] === null ? '' : format?.( d[i], d ) ?? d[i];
              }
            } else return null;
          } );
          const myObj = {};
          Object.keys( headers ).map( h => {
            return myObj[h] = d[h];
          } );
          return dataObject.push( myObj );
        } );
        dataObject.unshift( headers );
        const strObject = JSON.stringify( dataObject );
        const csv = convertToCsv( strObject );

        let blob = new Blob( [csv], { type:'text/csv;charset=utf-8;' } );
        let link = document.createElement( 'a' );
        if (link.download !== undefined) {
          let url = URL.createObjectURL( blob );
          link.setAttribute( 'href', url );
          link.setAttribute( 'download', props.csvFile );
          link.style.visibility = 'hidden';
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );
        }
      } );
    }
  }
};

const buildStyle = ( pane, d, c ) => {
  const baseStyles = { width:pane.width };
  const backgroundStyles = pane.tdStyle && pane.tdStyle( d[c] );
  return backgroundStyles !== undefined ? { ...baseStyles, ...backgroundStyles } : baseStyles;
};

const toggleExp = ( ref, cRef ) => {
  if (ref.current) {
    switch (ref.current.hidden) {
      case true:
        ref.current.hidden = false;
        cRef.current.className = 'bot-table-row-field index0 expanded';
        break;
      case false:
        ref.current.hidden = true;
        cRef.current.className = 'bot-table-row-field index0 minimized';
        break;
      default:
        break;
    }
  }
};

const createEditableRow = ( c, d, panes, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay ) => {
  const EditComp = panes[c] && panes[c].editable;
  return (
    <div key={ table_keys() } hidden={ panes[c].hidden }
         className={ 'bot-table-row-field editor' }
         onDoubleClick={ () => {
           onRowDoubleClick && clearPendingPromises();
           onRowDoubleClick( d );
         } }
         onClick={ () => {
           const waitForClick = cancellablePromise( delay( 300 ) );
           appendPendingPromise( waitForClick );
           return waitForClick.promise.then( () => {
             removePendingPromise( waitForClick );
             EditComp.onClick( d, c );
           } );
         } }
         onContextMenu={ ( e ) => onRowRightClick && onRowRightClick( e, d ) } style={ buildStyle( panes[c], d, c ) }>
      { panes[c].format ? panes[c].format( d[c], d ) : d[c] }
    </div>
  );
};

const createExpandableRow = ( d, expRef, selfRef, ExpandableComponent, panes, order, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick, expandClick, expandDoubleClick ) => {
  return (
    <div key={ table_keys() } id={ 'bot-table-expanded-row' } className={ 'bot-table-expanded-row' }
         onClick={ () => onRowClick ? onRowClick( d ) : expandClick && toggleExp( expRef, selfRef ) }
         onDoubleClick={ () => onRowDoubleClick ? onRowDoubleClick( d ) : expandDoubleClick && toggleExp( expRef, selfRef ) }>
      <div id={ 'bot-table-row' } className={ 'bot-table-row' }>
        { panes && order.map( ( c ) => {
          if (c === 'expand') {
            return (
              <div key={ table_keys() } className={ `bot-table-row-field minimized` }
                   ref={ selfRef }
                   onClick={ () => toggleExp( expRef, selfRef ) }
                   onContextMenu={ ( e ) => onRowRightClick && onRowRightClick( e, d ) }
                   hidden={ panes[c].hidden }
                   style={ buildStyle( panes[c], d, c ) }>

              </div>);
          } else {
            if (panes[c].editable) {
              return createEditableRow( c, d, panes, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick );
            } else {
              return (<div key={ table_keys() }
                           hidden={ panes[c].hidden }
                           className={ 'bot-table-row-field' }
                           onContextMenu={ ( e ) => onRowRightClick && onRowRightClick( e, d ) }
                           style={ buildStyle( panes[c], d, c ) }>
                { panes[c].format ? panes[c].format( d[c], d ) : d[c] }
              </div>);
            }
          }
        } ) }
      </div>
      <div ref={ expRef } hidden={ true }>
        <ExpandableComponent data={ d }/>
      </div>
    </div>);
};

const createStandardRow = ( d, panes, order, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick ) => {
  return (<div key={ table_keys() } className={ 'bot-table-row' }
               onClick={ () => onRowClick && onRowClick( d ) }
               onDoubleClick={ () => onRowDoubleClick && onRowDoubleClick( d ) }>
    { panes && order?.map?.( ( c ) => {
      if (panes[c].editable) {
        return createEditableRow( c, d, panes, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay );
      } else {
        return (<div key={ table_keys() }
                     hidden={ panes[c].hidden }
                     className={ `bot-table-row-field` }
                     onContextMenu={ ( e ) => onRowRightClick && onRowRightClick( e, d ) }
                     style={ buildStyle( panes[c], d, c ) }>
          { panes[c].format ? panes[c].format( d[c], d ) : d[c] }
        </div>);
      }
    } ) }</div>);
};

export const createRows = ( Data, filters, currentPage, currentSize, sortName, sortOrder, remote, panes, order, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick, expandable, expandableComponent, expandClick, expandDoubleClick, useLength, columns ) => {
  const compare = ( a, b ) => {
    if (a && b) {
      if (sortName !== '' && sortOrder !== '') {
        if (sortOrder === 'asc') {
          if (a[sortName] > b[sortName]) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (a[sortName] < b[sortName]) {
            return 1;
          } else {
            return -1;
          }
        }
      } else {
        return 1;
      }
    }
  };
  const send = ( data ) => {
    return (
      data && data.length > 0 ? data.map( d => {
        if (expandable && expandableComponent) {
          const ExpandableComponent = expandableComponent;
          const expRef = React.createRef();
          const selfRef = React.createRef();
          return createExpandableRow( d, expRef, selfRef, ExpandableComponent, panes, order, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick, expandClick, expandDoubleClick );
        } else {
          return createStandardRow( d, panes, order, table_keys, onRowDoubleClick, onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, onRowClick );
        }
      } ) : <div style={ { textAlign:'center', paddingBottom:'10rem' } }>No Data</div>
    );
  };
  const manageLocal = () => {
    let data = _.cloneDeep( Data );
    const myFills = _.cloneDeep( filters );
    useLength && delete myFills.date_range;
    data = filterLocalData( data, myFills, columns );
    data = sortName ? data?.sort( compare ) : data;
    data = !isNaN( currSize ) && !isNaN( currPage ) ? data.slice( startPoint, ep ) : data;
    return send( data );
  };
  let data = Data;
  const currPage = parseInt( currentPage, 0 );
  const currSize = parseInt( currentSize, 0 );
  const startPoint = (currPage - 1) * currSize;
  const ep = (currPage * currSize);

  return (remote && useLength) ? data : (!remote && data) ? manageLocal() : send( data );
};

export const createPagination = ( data, direction, remote, currPage, pageSizes, currSize, onPageChanged, onSizeChange, pagRef, filters, useLength ) => {
  const myFills = _.cloneDeep( filters );
  remote && useLength && delete myFills.date_range;
  const totalRecords = remote && data ? useLength ? filterLocalData( data, myFills ).length : parseInt( data?.[0]?.['totalcount'] ) || 0 : data ? filterLocalData( data, filters ).length : 0;
  return (
    <Pagination ref={ pagRef }
                dropup={ direction === 'up' }
                direction={ direction }
                totalRecords={ totalRecords }
                pageLimit={ currSize }
                currPage={ currPage }
                pageNeighbours={ 2 }
                onPageChanged={ ( pag ) => onPageChanged( pag ) }
                changeSizePerPage={ ( req ) => onSizeChange( req ) }
                pageSizes={ pageSizes }
                useLength={ useLength }
    />);
};

export const createHeaders = ( panes, filters, sortName, sortOrder, filterCleared, sortChanged, filterChanged, selectedRows, deleteRows ) => {
  return panes && Object.keys( panes ).map( p => {
    const P = panes[p];
    if (P.filter) P.filter.defaultValue = filters[p];
    return (<Pane key={ p }
                  className={ 'bot-table-header-col' }
                  size={ { width:P.width, height:'max-content' } }
                  hidden={ P.hidden }>
      <BotTableHeaders field={ p }
                       text={ P.text }
                       stored={ P.text }
                       width={ '100%' }
                       filter={ P.filter }
                       ref={ P.refProp }
                       onFilterCleared={ ( fil ) => filterCleared( fil ) }
                       onSortChanged={ ( sort ) => sortChanged( sort ) }
                       onFilterChanged={ ( e ) => filterChanged( e ) }
                       sortable={ P.sortable }
                       defaultSort={ !sortName ? null : sortName === p ? sortOrder : null }
                       selectedRows={ selectedRows }
                       deleteRows={ deleteRows }
      />
    </Pane>);
  } );
};

/**
 *
 * @param filters
 * @param filters.date_range
 * @param defaultFilters
 * @param CsvDownloadBtn
 * @param CreateNewBtn
 * @param CustomBtns
 * @param table_keys
 * @param onlyFilters
 * @param remote
 * @param csvFile
 * @param csvUrl
 * @param data
 * @param columns
 * @param tblRef
 * @param saveSettings
 * @param dateRangeBtns
 * @param setFilter,
 * @param canSelect
 * @param canDelete
 * @param selectAll
 * @param selectNone
 * @param showSelected
 * @param deleteRows
 * @param selects
 * @param specialFilters
 * @returns {JSX.Element}
 */
export const createButtonGroup = ( filters, defaultFilters, CsvDownloadBtn, CreateNewBtn, CustomBtns, table_keys, onlyFilters, remote, csvFile, csvUrl, data, columns, tblRef, saveSettings, dateRangeBtns, setFilter, canSelect, canDelete, selectAll, selectNone, showSelected, deleteRows, selects, specialFilters ) => {
  const funcGroup = { children:[] };
  const filterGroup = { children:[] };
  const dateRangeGroup = { children:[] };
  const customGroup = { children:[] };
  const deleteGroup = { children:[] };
  if (CreateNewBtn) funcGroup.children.push( <CreateNewBtn key={ table_keys() }/> );
  if (CsvDownloadBtn) funcGroup.children.push( csvDownloadBtn( {
    remote:remote,
    csvFile:csvFile,
    csvUrl:csvUrl,
    data:data,
    specialFilters:specialFilters
  }, columns, tblRef, table_keys, selects ) );
  if (onlyFilters?.length > 0) onlyFilters.map( B => filterGroup.children.push( <B key={ table_keys() }/> ) );
  if (dateRangeBtns) dateRangeBtns.map( dr => {
    return dateRangeGroup.children.push( <DropdownItem outline color={ 'texts' } key={ table_keys() }
                                                       className={ filters.date_range === dr ? 'active' : (dr === 'All' && (!filters.date_range || filters.date_range === '')) ? 'active' : '' }
                                                       onClick={ () => setFilter( {
                                                         target:{
                                                           name:'date_range',
                                                           value:dr === 'All' ? '' : dr
                                                         }
                                                       } ) }>{ dr } { dr !== 'All' && 'Days' }</DropdownItem> );
  } );
  if (CustomBtns && CustomBtns.length > 0) CustomBtns.map( B => customGroup.children.push( <B
    key={ table_keys() }/> ) );
  canSelect && deleteGroup.children.push( <DropdownItem outline color={ 'texts' } key={ table_keys() }
                                                        onClick={ selectAll }>Select
    All</DropdownItem> );
  canSelect && deleteGroup.children.push( <DropdownItem outline color={ 'texts' } key={ table_keys() }
                                                        onClick={ selectNone }>Select None</DropdownItem> );
  canSelect && deleteGroup.children.push( <DropdownItem outline color={ 'texts' } key={ table_keys() }
                                                        onClick={ showSelected }>Show Selected</DropdownItem> );
  canSelect && deleteGroup.children.push( <DropdownItem outline color={ 'texts' } key={ table_keys() }
                                                        onClick={ deleteRows }>Delete Selected</DropdownItem> );
  const BtnGroup = (
    <>
      { funcGroup?.children?.length > 0 ? <UncontrolledDropdown>
        <DropdownToggle caret className={ 'bot-table-function-menu-button' }>Actions</DropdownToggle>
        <DropdownMenu>
          { funcGroup.children?.length > 0 ? funcGroup.children : null }
        </DropdownMenu>
      </UncontrolledDropdown> : null }
      { filterGroup?.children?.length > 0 ? (
        <UncontrolledDropdown>
          <DropdownToggle caret className={ 'bot-table-function-menu-button' }>Filter Only</DropdownToggle>
          <DropdownMenu>
            { filterGroup.children }
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null }
      { dateRangeGroup?.children?.length > 0 ? (
        <UncontrolledDropdown>
          <DropdownToggle caret className={ 'bot-table-function-menu-button' }>Date Range</DropdownToggle>
          <DropdownMenu>
            { dateRangeGroup.children }
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null }
      { deleteGroup?.children?.length > 0 ? (
        <UncontrolledDropdown>
          <DropdownToggle caret className={ 'bot-table-function-menu-button' }>Selection</DropdownToggle>
          <DropdownMenu>
            { deleteGroup.children }
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null }
      { customGroup?.children?.length > 0 ? (
        <UncontrolledDropdown>
          <DropdownToggle caret className={ 'bot-table-function-menu-button' }>Special
            Functions</DropdownToggle>
          <DropdownMenu>
            { customGroup.children }
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null }
    </>);
  return (BtnGroup);
};

export const createIconGroup = ( clearFiltersIcon, clearSortIcon, csvDownloadIcon, onlyFiltersIcons, customIcons, btnGroupDirection, remote, csvFile, csvUrl, data, specialFilters, selects, columns, tblRef, table_keys, cleanFiltered, clearSort, hPosition ) => {
  const iconGroup = {
    [btnGroupDirection]:true,
    children:[]
  };
  if (csvDownloadIcon) iconGroup.children.push( CsvDownloadIcon( {
    remote:remote,
    csvFile:csvFile,
    csvUrl:csvUrl,
    data:data,
    specialFilters:specialFilters
  }, columns, tblRef, table_keys, selects ) );
  if (clearFiltersIcon) iconGroup.children.push( ClearFiltersIcon( cleanFiltered ) );
  if (clearSortIcon) iconGroup.children.push( ClearSortIcon( clearSort ) );
  if (onlyFiltersIcons && onlyFiltersIcons.length > 0) onlyFiltersIcons.map( B => {
    return iconGroup.children.push( <B key={ `${ table_keys() }` }/> );
  } );
  if (customIcons) customIcons.map( I => {
    return iconGroup.children.push( <I key={ `${ table_keys() }` }/> );
  } );
  iconGroup.children.push( <div className={ 'bot-table-scroll-row' }>
    <UncontrolledTooltip target={ 'scroll-left-icon' } placement={ 'top' } style={ { fontSize:'0.75rem' } }>
      Click to scroll left
    </UncontrolledTooltip>
    <UncontrolledTooltip target={ 'scroll-right-icon' } placement={ 'top' } style={ { fontSize:'0.75rem' } }>
      Click to scroll right
    </UncontrolledTooltip>
    <i
      id={ 'scroll-left-icon' }
      className={ 'fa fa-chevron-left bot-table-scroller' }
      onClick={ () => hPosition.current?.scrollBy( -200, 0 ) }/><i
    id={ 'scroll-right-icon' }
    className={ 'fa fa-chevron-right bot-table-scroller' }
    onClick={ () => hPosition.current?.scrollBy( 200, 0 ) }/></div> );
  return (<BotTableButtonGroup style={ { paddingLeft:'0.5rem' } } { ...iconGroup }/>);
};

export const createActiveFilters = ( { sortName, clearSort, filters, columns, cleanFiltered } ) => (<>
  <div className={ 'active-filters-label' }>Active Filters & Sort</div>
  <div className={ 'row active-filters' }>
    { sortName && sortName !== '' && <div className={ 'active-filter clear' } onClick={ ( e ) => {
      e.stopPropagation();
      clearSort();
    } }><i
      className={ `bot-table-sort sort-null` }/><span>Clear Sort</span></div> }
    { filters && Object.keys( filters ).length > 1 && <div className={ 'active-filter clear' } onClick={ ( e ) => {
      e.stopPropagation();
      cleanFiltered();
    } }><i
      className={ 'bot-table-header-clear-icon' }/><span>Clear Filters</span></div> }
    { Object.keys( filters ).map( f => {
      const col = columns.find( c => c.field === f );
      return (<div className={ 'active-filter' } onClick={ ( e ) => {
        e.stopPropagation();
        col.refProp?.current?.setFilter( '' );
      } }><i
        className={ 'bot-table-header-clear-icon' }/><span>{ col.text }</span></div>);
    } ) }
  </div>
</>);

const CsvDownloadIcon = ( props, columns, tblRef, table_keys, selects ) => {
  return (
    <>
      <UncontrolledTooltip target={ 'download-csv-icon' } placement={ 'top' } style={ { fontSize:'0.75rem' } }>
        Click to download csv file
      </UncontrolledTooltip>
      <i id={ 'download-csv-icon' } className={ 'fa fa-download' } onClick={ () => {
        return downloadAll( props, columns, tblRef, selects );
      } }/>
    </>
  );
};

const ClearFiltersIcon = ( cleanFiltered ) => {
  return (
    <>
      <UncontrolledTooltip target={ 'clear-filters-icon' } placement={ 'top' } style={ { fontSize:'0.75rem' } }>
        Click to clear all filters
      </UncontrolledTooltip>
      <i id={ 'clear-filters-icon' } className={ 'bot-table-header-clear-icon' } onClick={ () => {
        return cleanFiltered();
      } }/>
    </>
  );
};

const ClearSortIcon = ( clearSort ) => {
  return (
    <>
      <UncontrolledTooltip target={ 'clear-sort-icon' } placement={ 'top' } style={ { fontSize:'0.75rem' } }>
        Click to clear sort
      </UncontrolledTooltip>
      <i id={ 'clear-sort-icon' } className={ `bot-table-sort sort-null` } onClick={ () => {
        return clearSort();
      } }/>
    </>
  );
};

const csvDownloadBtn = ( props, columns, tblRef, table_keys, selects ) => {
  return (
    <DropdownItem key={ table_keys() } outline color={ 'texts' } onClick={ () => {
      return downloadAll( props, columns, tblRef, selects );
    } }><i key={ table_keys() } className={ 'fa fa-download' }/> Download CSV</DropdownItem>
  );
};

export const createColumns = ( props, sp, o ) => {
  const panes = {};
  let order = [];
  let columnRefs = {};
  props.columns.map( c => {
    const p = sp !== undefined && c !== undefined ? sp[c.field] : null;
    const makeCol = ( c ) => {
      !order.includes( c.field ) && order.push( c.field );
      columnRefs[c.field] = c.refProp;
      return panes[c.field] = {
        width:p ? p.width : c.width,
        text:c.text,
        filter:c.filter,
        format:c.format,
        hidden:p ? p.hidden : c.hidden,
        tdStyle:c.tdStyle,
        refProp:c.refProp,
        editable:c.editable,
        sortable:c.sortable
      };
    };
    if (c && (props.expandable || c.field !== 'expand')) return makeCol( c ); else return null;
  } );
  return { panes:panes, defaultOrder:order, order:o ? o : order, columnRefs:columnRefs };
};

export const filterLocalData = ( data, filters, _columns ) => {
  let Data = _.cloneDeep( data );
  const columns = _.cloneDeep(_columns);
  if (Data) {
    if (filters) {
      Object.keys( filters ).map( f => {
        const formatter = columns?.find(c => c.field === f)?.format;
        if (filters[f] !== '' && (filters[f].length > 0 || filters[f] === true || filters[f] === false || filters[f] === 1 || typeof filters[f] === 'number')) {
          if (Data[0] && Object.hasOwn(Data[0], f)) {
            if (f === 'date_range') {
              let startDate = moment().subtract( +filters[f], 'days' ).toISOString();
              return Data = Data.filter( d => {
                if (d['created_at'] > startDate) return d; else return null;
              } );
            } else if (filters[f] === true || filters[f] === false) {
              return Data = Data.filter( d => d[f] === filters[f] );
            } else if (filters[f] === 'true' || filters[f] === 'false') {
              return Data = Data.filter( d => d[f] === (filters[f] === 'true') );
            } else if (filters[f] === 1) {
              return Data = Data.filter( d => d[f] === (filters[f]) );
            } else if (typeof filters[f] === 'number') {
              return Data = Data.filter( d => d[f] === (filters[f]) );
            } else {
              return Data = Data.filter( d => d[f] && (formatter?.(d[f], d) ?? d[f]).toString().toLowerCase().includes( filters[f].toLowerCase() ) );
            }
          }
        }
        return Data;
      } );
      return Data;
    } else return Data;
  }
};
