/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardFooter,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Modal, ModalHeader, ModalBody, ModalFooter, Label, CardBody
} from '../../../components/bot-components';
import {
    unitStatusActions,
    unitActions,
    vendorActions,
    jobActions,
    yardActions,
    unitTypeActions, companyActions, tireSizeActions, assetTypeActions, logActions, partActions, plmAlertActions
} from '../../../network/actions';
import DataPlates from './DataPlates';
import classnames from 'classnames';
import {
    attachmentActions,
    commentActions, dataPlateActions,
    // logActions, partActions,
    // plmAlertActions,
    unitPmActions,
    workorderActions
} from '../../../network/actions';
import BotAttachments from '../../Attachments/BotAttachments/BotAttachments';
import BotWorkOrders from "../../WorkOrders/BotWorkOrders/BotWorkOrders";
import BotPms from '../../UnitPms/BotPms/BotPms';
import ReduxBlockUi from 'react-block-ui';
import WorkorderDetail from '../../WorkOrders/WorkOrderDetail/WorkOrderDetail';
import UnitDetail from '../UnitDetail/UnitDetail';
import UnitEdit from '../UnitEdit/UnitEdit';
import {Link} from 'react-router-dom';
import AttachmentInput from '../../Attachments/AttachmentInput/AttachmentInput';
import UnitReport from '../UnitReport/UnitReport';
import BotGpsDataTable from '../../GpsData/BotGpsDataTable';
import BotInspections from '../../Inspections/BotInspections';
import BotParts from '../../Parts/BotParts';
import BotPlmAlerts from '../../PlmAlerts/BotPlmAlerts';
import {useHistory} from 'react-router-dom';

const propTypes = {
    email_alerts: PropTypes.any,
    component_infos: PropTypes.any,
    isModal: PropTypes.bool,
    parentComp: PropTypes.string.isRequired
};

const defaultState = {
    activeTab: 'detail',
    selectedUnit: null,
    attachment: null,
    attachmentModal: false,
    file: null,
    blocked: false,
    unit_status_id: '',
    unit_status: '',
    job: '',
    operator: '',
    description: '',
    id: '',
    vin_sn: '',
    unit_type: '',
    unit_subtype: '',
    company: '',
    plate: '',
    registration_date: '',
    mileage: '',
    hours: '',
    cadr: '',
    gps_location: '',
    location: '',
    tire_size: '',
    tire_size_2: '',
    tire_size_3: '',
    tire_size_4: '',
    asset_type: '',
    tare_weight: '',
    gross_weight: '',
    height: '',
    wheelbase: '',
    axles: '',
    overall_length: '',
    horsepower: '',
    width: '',
    workorders: [],
    unit_pms: [],
    logs: [],
    parts: [],
    filters: [],
    email_alerts: [],
    images: [],
    component_infos: [],
    editUnit: false,
    openWorkorder: null,
    openPm: null
};

const BotUnit = props => {
    const {BotModules} = props;
    const attachFiles = React.useRef();
    const [state, updateState] = React.useState({
        ...defaultState,
        file: attachFiles.current?.files?.[0] ?? null,
        workorderModal: false
    });
    const setState = (newState) => (updateState((prevState) => ({
        ...prevState,
        ...newState
    })));
    const history = useHistory();
    const {openWorkorder, openPm} = state;

    const uaRef = React.useRef();
    const uwRef = React.useRef();
    const uiRef = React.useRef();
    const upmRef = React.useRef();
    const upaRef = React.useRef();
    const unaRef = React.useRef();
    const udpRef = React.useRef();

    const toggleAttachmentModal = () => {
        setState({
            attachmentModal: !state.attachmentModal,
        });
    };

    const toggleWorkOrderModal = () => {
        setState({
            workorderModal: !state.workorderModal
        });
    };

    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab,
            });
            localStorage.setItem('unitTab', tab);
        }
    };

    const getNext = () => {
        setState({
            usage_history: null,
            openWorkorder: null,
            openPm: null
        });
        props.getNext(props.unit.items.id);
    };

    const getPrev = () => {
        setState({
            usage_history: null,
            openWorkorder: null,
            openPm: null
        });
        props.getPrevious(props.unit.items.id);
    };

    const handleAttachFiles = () => {
        const data = new FormData();
        data.append('unit_id', props.unit.items.id);
        data.append('file', state.file);
        props.attachFiles(data, props.unit.items.id);
        toggleAttachmentModal();
    };

    const setFiles = (e) => {
        const {files} = e.target;
        setState({
            file: files[0],
        });
    };

    const cancelFiles = () => {
        setState({
            file: null,
        });
        toggleAttachmentModal();
    };

    React.useEffect(() => {
        !props.unit_types?.items && props.getUnitTypes();
        !props.unit_statuses?.items && props.getUnitStatuses();
        !props.jobs?.items && props.getJobs();
        !props.companies?.items && props.getCompanies();
        !props.tire_sizes?.items && props.getTireSizes();
        !props.asset_types?.items && props.getAssetTypes();
        setState({
            activeTab: localStorage.getItem('unitTab') ?? state.activeTab,
            editUnit: history?.location?.state?.edit ?? false
        });
    }, []);

    React.useEffect(() => {
        let oWo;
        let oPm;
        oWo = props.parentComp !== 'workorder' && props.unit?.items?.workorders?.find(w => w.workorder_status_id === 1);
        oWo && localStorage.setItem('workorder_id', oWo.id);
        oPm = props.parentComp !== 'unit_pm' && props.unit?.items?.unit_pms?.find(pm => pm.workorder_status_id === 1);
        oPm && localStorage.setItem('pm_id', oPm.id);
        props.unit.items && setState({
            ...props.unit.items,
            logs: props.unit.items?.logs,
            openPm: oPm,
            openWorkorder: oWo,

        });
    }, [props.unit]);

    const updateWorkOrder = (wo) => {
        delete wo.show;
        props.updateWorkorder(wo);
    };

    const onSaveComment = (Comment) => {
        const unit_id = props.unit.items.id;
        Promise.resolve(unit_id).then(() => {
            Comment.unit_id = unit_id;
            props.saveUnitComment(Comment);
            setTimeout(() => {
                props.getAttachment(Comment.image_id);
            }, 1000);
        });
    };

    const onCreatePart = (row) => {
        const part = {
            number: row.number,
            description: row.description,
            unit_cost: row.unit_cost,
            qty: row.qty,
            filter: row.filter,
            requested: row.requested,
            ordered: row.ordered,
            received: row.received,
            workorder_id: null,
            unit_id: row.unit_id ? row.unit_id : props.unit.items.id,
            vendor_id: row.vendor_id,
        };
        console.log(part);
        // props.createPart(part);
    };

    const onUpdatePart = (row) => {
        const part = {
            id: row.id,
            number: row.number,
            description: row.description,
            unit_cost: row.unit_cost,
            qty: row.qty,
            filter: row.filter,
            requested: row.requested,
            ordered: row.ordered,
            received: row.received,
            workorder_id: null,
            unit_id: row.unit_id ? row.unit_id : props.unit.items.id,
            vendor_id: row.vendor_id,
        };
        console.log(part);
        // props.updatePart(part);
    };

    const {unit, workorder, unit_pm, isModal, attachment, log, vendorList, plm_alert} = props;
    const {attachmentModal, file, workorders,
        images, component_infos, unit_pms, logs, usage_history, parts, email_alerts} = state;

    console.log(BotModules);

    const block = (!!props.unit?.loading || !!props.unit_types?.loading || !!props.unit_subtypes?.loading || !!props.unit_statuses?.loading || !!props.jobs?.loading || !!props.companies?.loading);
    return(
        <ReduxBlockUi blocking={block} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
            <Card>
                <CardHeader style={{marginBottom: '.5rem'}}>
                    <Modal size={'xl'} isOpen={state.workorderModal} className={props.className} toggle={() => toggleWorkOrderModal()}>
                        <ModalHeader toggle={() => toggleWorkOrderModal()}/>
                        <ModalBody>
                            <WorkorderDetail modal/>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => toggleWorkOrderModal()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Row>
                        <Col sm={2}>
                            <Button hidden={unit?.items?.first_record} className={'back-btn'} onClick={() => {getPrev()}}><i className={'fa fa-step-backward'}/></Button>
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <h3 style={{textAlign: 'center'}}>Equipment# {unit.items && unit.items.number} Details</h3>
                                {isModal && <Link className={'link-label-1'} to={'/equipment/detail/'}>Go To</Link>}
                                {!state.editUnit ?
                                    <Label className={'link-label-1'} onClick={() => setState({editUnit: true, activeTab: 'detail'})}>Edit</Label> :
                                    <Label className={'link-label-1'} onClick={() => setState({editUnit: false, activeTab: 'detail'})}>Cancel Edit</Label>}
                            </Row>
                        </Col>
                        <AttachmentInput toggleTab={toggle} toggle={toggleAttachmentModal} attachments={images} ref={attachFiles} file={file} setFiles={setFiles} handleAttachFiles={handleAttachFiles} cancelFiles={cancelFiles} isOpen={attachmentModal} tab={'7'}/>
                        <Col sm={1}>
                            {unit.items && unit.items.dot && <img className={'dot-logo'} src={''} alt={''}/>}
                        </Col>
                        <Col sm={1}>
                            <Button hidden={unit?.items?.last_record} className={'skip-btn'} onClick={() => {getNext()}}><i className={'fa fa-step-forward'}/></Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: state.activeTab === 'detail'})} onClick={() => toggle('detail')}>
                                Detail
                            </NavLink>
                        </NavItem>
                        {!!BotModules?.find(m => m.name === 'workorders') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '1'})}
                                     onClick={() => {
                                         toggle('1');
                                     }}>
                                Work Orders
                            </NavLink>
                        </NavItem>}
                        {!!BotModules?.find(m => m.name === 'preventative_maintenance') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '2'})}
                                     onClick={() => {
                                         toggle('2');
                                     }}>
                                PM's
                            </NavLink>
                        </NavItem>}
                        {!!BotModules?.find(m => m.name === 'inspections') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '3'})}
                                     onClick={() => {
                                         toggle('3');
                                     }}>
                                Inspections
                            </NavLink>
                        </NavItem>}
                        {!!BotModules?.find(m => m.name === 'parts') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '4'})}
                                     onClick={() => {
                                         toggle('4');
                                     }}>
                                Parts
                            </NavLink>
                        </NavItem>}
                        {/*<NavItem>*/}
                        {/*    <NavLink className={classnames({active: state.activeTab === '5'})}*/}
                        {/*             onClick={() => {*/}
                        {/*                 toggle('5')*/}
                        {/*             }}>*/}
                        {/*        Filters*/}
                        {/*    </NavLink>*/}
                        {/*</NavItem>*/}
                        {!!BotModules?.find(m => m.name === 'plm') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '6'})}
                                     onClick={() => {
                                         toggle('6');
                                     }}>
                                PLM Alerts
                            </NavLink>
                        </NavItem>}
                        <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '7'})}
                                     onClick={() => {
                                         toggle('7')
                                     }}>
                                Attachments
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '8'})}
                                     onClick={() => {
                                         toggle('8')
                                     }}>
                                Data Plates
                            </NavLink>
                        </NavItem>
                        {!!BotModules?.find(m => m.name === 'gps') && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '9'})}
                                     onClick={() => {
                                         toggle('9');
                                     }}>
                                GPS History
                            </NavLink>
                        </NavItem>}
                        {!!BotModules?.find(m => ['inspections', 'workorders'].includes(m.name)) && <NavItem>
                            <NavLink className={classnames({active: state.activeTab === '10'})}
                                     onClick={() => toggle('10')}>
                                Report
                            </NavLink>
                        </NavItem>}
                    </Nav>
                    <TabContent activetab={state.activeTab}>
                        <TabPane tabId={'detail'}>
                            <Card>
                                <CardBody>
                                    {!state.editUnit ?
                                    <UnitDetail unit={unit}/> : <UnitEdit cancelUpdate={() => setState({editUnit: false})}/>}
                                </CardBody>
                                {!!BotModules?.find(m => ['workorders', 'preventative_maintenance'].includes(m.name)) && (props.parentComp !== 'workorder' || props.parentComp !== 'unit_pm') &&
                                <CardFooter>
                                    <Row>
                                        {!!BotModules?.find(m => m.name === 'workorders') && props.parentComp !== 'workorder' &&
                                        <Col sm={6}>
                                            <label style={{fontWeight: 'bold', fontSize: 18}}>Open Work
                                                Order: </label>{openWorkorder &&
                                        <label style={{fontSize: 18, paddingLeft: 10, color: 'red', cursor: 'pointer'}} onClick={() => {
                                            props.getMyWorkorder(openWorkorder.id);
                                            toggleWorkOrderModal();
                                        }}>{openWorkorder.id}</label>}
                                        </Col>}
                                        {!!BotModules?.find(m => m.name === 'preventative_maintenance') && props.parentComp !== 'unit_pm' &&
                                        <Col sm={6}>
                                            <Label style={{fontWeight: 'bold', fontSize: 18}}>Open PM: </Label>{openPm &&
                                        <Label style={{fontSize: 18, paddingLeft: 10, color: 'red', cursor: 'pointer'}}
                                               onClick={() => {
                                                   props.getMyPm(openPm.id);
                                                   // togglePmModal();
                                               }}>{openPm.id}</Label>}
                                        </Col>}
                                    </Row>
                                </CardFooter>}
                            </Card>
                        </TabPane>
                        {!!BotModules?.find(m => m.name === 'workorders') && <TabPane tabId={'1'}>
                            <BotWorkOrders ref={uwRef}
                                           data={workorders}
                                           tblId={'uwRef'}
                                           remote={false}
                                           unit={unit}
                                           actionCount={2}
                                           viewAction={true}
                                           popoutAction={true}
                                           workorder={workorder}
                                           title={'Unit Work Orders'}
                                           jobList={props.jobList}
                                           yardList={props.yardList}
                                           pageSizes={['20', '50', '100', '500', '1000']}
                                           updateWorkorder={(wo) => updateWorkOrder(wo)}
                                           getWorkorders={(id) => props.getMyUnit(id)}
                                           getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                                           canCreate={!openWorkorder}
                                           unitStatusList={props.unitStatusList}
                                           createWorkorder={(wo) => props.createWorkorder(wo)}
                                           lStorage={'unit_detail_work_order_storage'}
                                           u_preferences={props.user_preference}
                                           parentComp={'unit'}
                                           csvFile={unit.items && `equipment_${unit.items.number}_work_orders.csv`}
                                           getMyUnit={(id) => props.getMyUnit(id)} includeDateRange={false}
                            />
                        </TabPane>}
                        {!!BotModules?.find(m => m.name === 'pms') && <TabPane tabId={'2'}>
                            <BotPms
                                ref={upmRef}
                                data={unit_pms}
                                unit={unit}
                                actionCount={2}
                                viewAction={true}
                                popoutAction={true}
                                unit_pm={unit_pm}
                                getPms={(id) => props.getMyUnit(id)}
                                getMyPm={(id) => props.getPm(id)}
                                getMyUnit={(id) => props.getMyUnit(id)}
                                remote={false}
                                title={'Equipment PMs'}
                                canCreate={true}
                                detail={true}
                                lStorage={'unit_detail_pm_storage'}
                                csvFile={unit.items && `equipment_${unit.items.number}_pms.csv`}
                                u_preferences={props.user_preference}
                                parentComp={'unit'}
                                tblId={'upmRef'}
                            />
                        </TabPane>}
                        {!!BotModules?.find(m => m.name === 'inspections') && <TabPane tabId={'3'}>
                            <BotInspections
                                ref={uiRef}
                                getInspections={() => null}
                                getMyInspection={(id) => props.getLog(id)}
                                getMyUnit={(id) => props.getMyUnit(id)}
                                getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                                title={'Equipment Inspections'}
                                actionCount={2}
                                data={logs}
                                onRowSelected={(id) => props.getMyLog(id)}
                                viewAction={true}
                                popoutAction={true}
                                unit={unit}
                                tblId={'uiRef'}
                                remote={false}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                workorder={workorder}
                                lStorage={'unit_detail_inspections_storage'}
                                csvFile={unit.items && `equipment_${unit.items.number}_inspections.csv`}
                                u_preferences={props.user_preference}
                                inspection={log}
                            />
                        </TabPane>}
                        {!!BotModules?.find(m => m.name === 'parts') && <TabPane tabId={'4'}>
                            <BotParts
                                ref={upaRef}
                                data={parts}
                                unit={unit}
                                tblId={'wpaRef'}
                                title={`Unit Part's`}
                                vendorList={vendorList}
                                pageSizes={['20', '50', '100', '250', '500', '1000']}
                                getParts={() => null}
                                canCreate={true}
                                remote={false} updatePart={(pa) => onUpdatePart(pa)}
                                lStorage={'unit_detail_part_storage'}
                                csvFile={unit.items && `equipment_${unit.items.number}_parts.csv`}
                                u_preferences={props.user_preference}
                                createPart={(pa) => onCreatePart(pa)}
                            />
                        </TabPane>}
                        {!!BotModules?.find(m => m.name === 'plm') && <TabPane tabId={'6'}>
                            <BotPlmAlerts
                                ref={uaRef}
                                data={email_alerts}
                                plm_alert={plm_alert}
                                workorder={workorder}
                                tblId={'aRef'}
                                getMyAlert={(id) => props.getAlert(id)}
                                title={'Equipment Alerts'}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                unit={unit}
                                getAlerts={() => unit?.items && props.getMyUnit(unit?.items?.id)}
                                remote={false}
                                getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                                getMyUnit={(id) => props.getMyUnit(id)}
                                lStorage={'unit_detail_plm_storage'}
                                csvFile={unit?.items && `equipment_${unit?.items?.number}_plm_alerts.csv`}
                                u_preferences={props.user_preference}
                                parentComp={'unit'}
                            />
                        </TabPane>}
                        <TabPane tabId={'7'}>
                            <BotAttachments
                                data={images}
                                attachment={attachment}
                                title={'Unit Attachments'}
                                ref={unaRef}
                                getAttachment={props.getAttachment}
                                tblId={'waRef'}
                                remote={false}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                lStorage={'unit_detail_attachment_storage'}
                                u_preferences={props.user_preference}
                                onSaveComment={onSaveComment}
                                parentComp={'unit'}
                            />
                        </TabPane>
                        <TabPane tabId={'8'}>
                            <DataPlates
                                ref={udpRef}
                                data={component_infos || []}
                                tblId={'udpRef'} canCreate={true}
                                createDataPlate={(dp) => props.createDataPlate(dp)}
                                updateDataPlate={(dp) => props.updateDataPlate(dp)}
                                title={'Equipment Plates'}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                unit={unit}
                                lStorage={'unit_detail_plate_storage'}
                                csvFile={unit.items && `equipment_${unit.items.number}_plates.csv`}
                                u_preferences={props.user_preference}
                                remote={false}
                            />
                        </TabPane>
                        {!!BotModules?.find(m => m.name === 'gps') && <TabPane tabId={'9'}>
                            {state.activeTab === '9' &&
                            <BotGpsDataTable
                                data={usage_history ?? []}
                                lStorage={'work_order_gps_table'}
                                unit={props.unit}
                                getExceptions={() => null}
                                getUnit={(id) => props.getMyUnit(id)}
                                remote={false}
                                csvFile={`equipment#${unit.items?.number}-gps_history`}
                                includeDateRange={true}/>}
                        </TabPane>}
                        {!!BotModules?.find(m => ['inspections', 'workorders'].includes(m.name)) && <TabPane tabId={'10'}>
                            <UnitReport unit={unit}/>
                        </TabPane>}
                    </TabContent>
                </CardBody>
            </Card>
        </ReduxBlockUi>
    );
};

BotUnit.propTypes = propTypes;

function mapStateToProps(state) {
    const {unit, units, attachment, workorder, vendors, log, unit_statuses, unit_pm, plm_alert, jobs, yards, user_preference} = state;
    let unitList = [];
    let vendorList = [];
    let unitStatusList = [];

    const jobList = jobs.items?.map(j => ({label: j.name, value: j.id}));

    const yardList = yards.items?.map(y => ({label: y.name, value: y.id}));

    if (vendors.items) {
        for (let i = 0; i < vendors.items.length; i++) {
            const vendor_id = vendors.items[i].id;
            const vendor_name = vendors.items[i].name;
            vendorList.push({label: vendor_name, value: vendor_id});
        }
    }

    if (units.items) {
        for (let i = 0; i < units.items.length; i++) {
            const unit_id = units.items[i].id;
            const unit_number = units.items[i].number;
            unitList.push({label: unit_number, value: unit_id});
        }
    }

    if (unit_statuses.items) {
        for (let i = 0; i < unit_statuses.items.length; i++) {
            unitStatusList.push(<option key={unit_statuses.items[i].id} value={unit_statuses.items[i].id}>{unit_statuses.items[i].status}</option>)
        }
    }

    console.log(user_preference.items);
    const BotModules = user_preference.items?.bot_modules;

    return {unit, units, unitList, attachment, workorder, vendors, vendorList, unit_statuses, log, unitStatusList, unit_pm, plm_alert, yardList, jobList, BotModules};
}

function mapDispatchToProps(dispatch) {
    return {
        getMyUnit: (id) => id && dispatch(unitActions.getUnit(id)),
        getUnits: () => dispatch(unitActions.getUnits()),
        getUnitStatuses: () => dispatch(unitStatusActions.getAll()),
        getVendors: () => dispatch(vendorActions.getAll()),
        getPm: (id) => dispatch(unitPmActions.getPm(id)),
        getMyWorkorder: (id, wo_only) => dispatch(workorderActions.getDetail(id, wo_only)),
        getAttachment: (id) => dispatch(attachmentActions.getAttachment(id)),
        saveUnitComment: (comment) => dispatch(commentActions.createComment(comment)),
        attachFiles: (data, unit_id) => dispatch(unitActions.attachFiles(data, unit_id)),
        getNext: (id) => dispatch(unitActions.getNext(id)),
        getPrevious: (id) => dispatch(unitActions.getPrevious(id)),
        getUnitTypes: () => dispatch(unitTypeActions.getAll()),
        getJobs: () => dispatch(jobActions.getAll()),
        getCompanies: () => dispatch(companyActions.getAll()),
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes()),
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
        getLog: (id) => dispatch(logActions.getDetail(id)),
        getAlert: (id) => dispatch(plmAlertActions.getDetail(id)),
        createPart: (part) => dispatch(partActions.createPart(part)),
        updatePart: (part) => dispatch(partActions.updatePart(part)),
        createDataPlate: (data_plate) => dispatch(dataPlateActions.createDataPlate(data_plate)),
        createWorkorder: (workorder) => dispatch(workorderActions.createWorkorder(workorder)),
        clearDotReport: () => dispatch(unitActions.clearDotReport()),
        getAllJobs: () => dispatch(jobActions.getAll()),
        getAllYards: () => dispatch(yardActions.getAll()),
        getMyPm: (id) => dispatch(unitPmActions.getPm(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BotUnit);

/** @namespace unit.items.last_known_gps*/
/** @namespace unit.items.last_known_gps_date*/
/** @namespace unit.items.last_inspection_location*/
/** @namespace unit.items.last_inspection_date*/
