/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Row, Col, Card, CardHeader, CardBody, Label, Button} from '../../../components/bot-components';
import * as moment from 'moment';
import DatePicker from 'react-datepicker'
import BotWorkOrders from '../BotWorkOrders/BotWorkOrders';
import "react-datepicker/dist/react-datepicker.css";
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import PropTypes from 'prop-types';
drilldown(Highcharts);
const propTypes = {
    getKPIData: PropTypes.func.isRequired,
    getMaintReportWoDetail: PropTypes.func.isRequired,
    clearMaintReportWoDetail: PropTypes.func.isRequired,
    getMyWorkorder: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    maint_report_wo: PropTypes.any,
    unit: PropTypes.object,
    workorder: PropTypes.object,
    maint_report_wo_detail: PropTypes.any
};
const WorkOrderStatus = (props) => {
    const [state, updateState] = React.useState({
        maint_report_wo: {},
        now: new Date(),
        yards: [],
        drilldown: [],
        selectedYard: '',
        selectedStatus: '',
        month: null,
        active: false,
        myChart: undefined
    });
    const setState = newState => updateState(
        /**
         *
         * @param {object} prevState
         * @returns {*}
         */
        prevState => ({
        ...prevState,
        ...newState
    }));
    Highcharts.setOptions({
        lang: {
            drillUpText: 'Back to Yards',
        }
    });
    const tblRef = React.useRef();
    const {myChart} = state;
    const handleDateChange = (date) => {
        const newDate = moment(date).format('YYYY-MM');
        setState({
            now: date,
            month: newDate,
        });
        props.getKPIData(newDate);
    };
    const showAllClick = () => {
        setState({
            now: null,
            month: null,
        });
        props.getKPIData();
        props.clearMaintReportWoDetail();
    };
    const getWorkOrderStatusDetail = () => {
        const body = {
            date: state.month,
            yard: state.selectedYard,
            status: state.selectedStatus
        };
        props.getMaintReportWoDetail(body);
        const tbl = document.querySelector('#woStatTbl');
        state.selectedStatus && setTimeout(() => window.scrollTo(0, tbl.offsetTop - 60), 500);
    };
    React.useEffect(() => {
        maintenanceWorkorderChart().catch();
        const newDate = moment().format('YYYY-MM');
        setState({month: newDate});
        return () => props.clearMaintReportWoDetail();
    }, []);
    const maintenanceWorkorderChart = () => {
        let yData = [];
        let drillData = [];
        let total = 0;
        function mapYard (Yard) {
            return new Promise(function (resolve) {
                yData.push({name: Yard.yard, data: [{name: Yard.yard, id: Yard.yard, drilldown: Yard.yard, y: parseInt(Yard.count, 0)}]});
                total = parseInt(total) + parseInt(Yard.count);
                mapWorkorders(Yard.details, Yard).then(function () {
                    resolve();
                });
            });
        }
        function mapWorkorders (WorkOrders, yard) {
            return new Promise(function (resolve) {
                let wos = [];
                const set = WorkOrders?.map?.(function (workorder) {
                    return wos.push({name: workorder.unit_status, id: yard.yard, y: parseInt(workorder.count, 0)});
                });
                Promise.all(set || []).then(function () {
                    drillData.push({name: yard.yard, id: yard.yard, data: wos});
                    resolve();
                });
            });
        }
        const Yards = props.maint_report_wo?.maint_report?.map(function (yard) {
            return mapYard(yard);
        }) ?? [];
        return Promise.all(Yards).then(function () {
            let thisMonth = state.now ? moment(state.now).format("MMMM YYYY") : 'All Work Orders';
            return setState({active: true, myChart: Highcharts.chart("maint_report_wo", {
                    chart: {
                        type: 'column',
                        events: {
                            drilldown: function (e) {
                                setState({
                                    selectedYard: e.seriesOptions.name,
                                });
                            },
                            drillup: function() {
                                props.clearMaintReportWoDetail();
                            }
                        }
                    },
                    title: {
                        text: `Work Order Status Report (${total}) Work Orders`
                    },
                    subtitle: {
                        text: thisMonth
                    },
                    xAxis: {
                        type: 'category'
                    },
                    yAxis: {
                        title: {
                            text: 'Total Work Orders'
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    plotOptions: {
                        series: {
                            grouping: false,
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true
                            },
                            point: {
                                events: {
                                    click: function (e) {
                                        setState({
                                            selectedStatus: e.point.name,
                                        });
                                    }
                                }
                            }
                        },
                    },
                    series: yData,
                    drilldown: {
                        series: drillData
                    }
                })
            });
        });
    };
    const addLoadingLabelToChart = chart => chart.renderer.label('Chart loading', 100, 120).attr({fill: Highcharts.getOptions().colors[0], padding: 10, r: 5, zIndex: 8}).css({color: '#FFFFFF'}).add();
    myChart && !props.maint_report_wo.maint_report && addLoadingLabelToChart(myChart);
    React.useEffect(() => {
        setState({
            maint_report_wo: props.maint_report_wo.maint_report
        });
    }, [props.maint_report_wo]);
    React.useEffect(() => {
        props.maint_report_wo_detail?.items && setState({active: true});
    }, [props.maint_report_wo_detail]);
    React.useEffect(() => {
        maintenanceWorkorderChart().catch();
    }, [state.maint_report_wo]);
    React.useEffect(() => {
        if (state.active) {
            const newDate = moment().format('YYYY-MM');
            setState({month: newDate});
            props.getKPIData(newDate);
        }
    }, [state.active]);
    React.useEffect(() => {
        state.selectedStatus && getWorkOrderStatusDetail();
    }, [state.selectedStatus]);
    const updateWorkOrder = (wo) => {
        delete wo.show;
        props.updateWorkorder(wo);
    }
    const {unit, workorder, maint_report_wo_detail} = props;

    return (
        <div>
            <Card>
                <CardHeader>
                    <Col sm={'12'}>
                        <Row>
                            <Col sm={'4'}/>
                            <Col sm={'4'}>
                                <Row>
                                    <Col sm={6}>
                                        <Label className={'select-date-label'} style={{fontSize: '18', fontWeight: 'bold', paddingTop: '2px'}}>Select Date</Label>
                                    </Col>
                                    <Col sm={6}>
                                        <DatePicker onChange={handleDateChange}
                                                    dateFormatCalendar={'MMMM yyyy'}
                                                    dateFormat={'MMMM yyyy'}
                                                    selected={state.now}
                                                    onMonthChange={handleDateChange}
                                                    dropdownMode={'select'}
                                                    style={{paddingLeft: '0'}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={'4'}>
                                <Button outline color={'texts'} className={'show-all-btn'} onClick={() => {showAllClick()}}>Show All</Button>
                            </Col>
                        </Row>
                    </Col>
                </CardHeader>
                <CardBody>
                    <Col className={'wo-status-report-container'} sm={'12'}>
                        <div className={'wo-status-report'} style={{width: '100%', margin: '0 auto'}}  id="maint_report_wo">loading</div>
                    </Col>
                </CardBody>
            </Card>
            <div id={'woStatTbl'}>
                <BotWorkOrders ref={tblRef}
                               remote={false}
                               unit={unit}
                               actionCount={2}
                               viewAction={true}
                               popoutAction={true}
                               workorder={workorder}
                               getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                               title={'Work Order Status List'}
                               tblId={'tblRef'}
                               jobList={props.jobList}
                               yardList={props.yardList}
                               getMyUnit={(id) => props.getMyUnit(id)}
                               updateWorkorder={(wo) => updateWorkOrder(wo)}
                               data={maint_report_wo_detail?.items}
                               pageSizes={['20', '50', '100', '500', '1000']}
                               getWorkorders={() => null}
                               lStorage={'work_order_status_storage'}
                               csvFile={`${state.selectedYard && state.selectedYard}_${state.selectedStatus && state.selectedStatus}_work_orders.csv`}
                               u_preferences={props.user_preference}
                               includeDateRange={false}/>
            </div>
        </div>
    );
};

WorkOrderStatus.propTypes = propTypes;
export default WorkOrderStatus;
