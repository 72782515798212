/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSCheckFunctionSignatures

import React from 'react';
import {connect} from 'react-redux';
import {logActions/*, userActions*/} from '../../network/actions/';
import BotInspections from './BotInspections';
import {unitActions, workorderActions} from "../../network/actions";
import ReduxBlockUi from 'react-block-ui';
import InspectionExceptions from '../Exceptions/inspection_exceptions';
import {Label, Nav, NavItem, NavLink, TabContent, TabPane} from "../../components/bot-components";
import classnames from 'classnames';
// import {authHeader, store} from '../../helpers';
// import {baseUrl} from '../../network/constants';
// import {logOut} from '../../network/services/responseHandler';
import BotGpsDataTable from '../GpsData/BotGpsDataTable';

const AllLogs = (props) => {
    const [state, updateState] = React.useState({
        activeTab: localStorage.getItem('inspectionTab') ?? '1',
        exceptionsLoading: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const allRef = React.useRef();
    const gpsRef = React.useRef();

    const toggle = (tab) => {
        setState({
            activeTab: tab,
        });
        localStorage.setItem('inspectionTab', tab);
    };

    React.useEffect(() => {
        props.clearUnit();
        localStorage.setItem('lastComp', props.history?.location?.pathname);
    }, []);

    const getInspectionExceptions = () => {
        // const tbl = gpsRef?.current?.state;
        // const days = tbl?.filters?.date_range;
        // setState({
        //     loading: true
        // });
        // const requestOptions = {
        //     method: 'GET',
        //     headers: authHeader('GET'),
        //     body: JSON.stringify({days: days})
        // };
        //
        // return fetch(`${baseUrl}/reports/inspection_exceptions`, requestOptions).then(response => {
        //     return response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(async data => {
        //         if (!response.ok) {
        //             setState({
        //                 exceptions: [],
        //                 exceptionsLoading: false
        //             });
        //             return alert('There was an error loading the work orders, please try again...');
        //         }
        //         const Auth = JSON.parse(response.headers.get('Authentication'));
        //         if (Auth) {
        //             // noinspection JSCheckFunctionSignatures
        //             await store.dispatch(userActions.updateAuth(Auth, null));
        //         }
        //         if (data) {
        //             return setState({
        //                 exceptions: data,
        //                 exceptionsLoading: false
        //             });
        //         } else return setState({
        //             exceptions: [],
        //             exceptionsLoading: false
        //         });
        //     }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        // });
    };

    const {logs, workorder, unit, log, BotModules} = props;
    return (
        <div className="animated fadeIn">
            <Nav tabs>
                <NavItem>
                    <NavLink style={{textDecoration: 'underline'}} className={classnames({active: state.activeTab === '1'})}
                             onClick={() => {
                                 toggle('1');
                             }}>
                        All Inspections
                    </NavLink>
                </NavItem>
                {!!BotModules?.find(m => m.name === 'gps') && <NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '3'})}
                             onClick={() => {
                                 toggle('3');
                             }}>
                        Missing Inspections
                    </NavLink>
                </NavItem>}
                <NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '4'})}
                             onClick={() => toggle('4')}>
                        DOT – No Post-trip
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activetab={state.activeTab}>
                <TabPane tabId={'1'}>
                    <ReduxBlockUi blocking={logs.loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                        <BotInspections
                            ref={allRef}
                            getInspections={(req) => props.getLogs(req)}
                            getMyInspection={(id) => props.getMyLog(id)}
                            getMyUnit={(id) => props.getUnit(id)}
                            getMyWorkOrder={(id) => props.getWorkorder(id)}
                            title={'All Inspections'}
                            actionCount={2}
                            data={logs.items ? logs.items : undefined}
                            viewAction={true}
                            popoutAction={true}
                            onRowSelected={(id) => {props.getMyLog(id)}}
                            unit={unit}
                            tblId={'allRef'}
                            csvFile={'all_inspections.csv'}
                            csvUrl={'logs'}
                            remote={true}
                            pageSizes={['20', '50', '100', '500', '1000']}
                            lStorage={'all_inspections_storage'}
                            workorder={workorder}
                            inspection={log}
                            loading={logs.loading}
                            u_preferences={props.user_preference}
                            BotModules={BotModules}
                        />
                    </ReduxBlockUi>
                </TabPane>
                {!!BotModules?.find(m => m.name === 'gps') && <TabPane tabId={'3'}>
                    <BotGpsDataTable
                        ref={gpsRef}
                        data={state.exceptions}
                        includeDateRange={true}
                        useLength={true}
                        getExceptions={(days) => days && getInspectionExceptions(days)}
                        csvFile={'inspection_exceptions.csv'}
                        lStorage={'inspection_exceptions_storage'}
                        title={'Inspection Exceptions'}
                        loading={false}
                        pageSizes={['20', '50', '100', '500', '1000']}
                        tblId={'insTblRef'}
                        remote={true}
                        u_preferences={props.user_preference}
                        getUnit={(id) => props.getUnit(id)}
                        unit={unit}
                    />
                </TabPane>}
                <TabPane tabId={'4'}>
                    <InspectionExceptions/>
                </TabPane>
            </TabContent>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {logs, workorder, unit, log, user_preference} = state;
    const BotModules = user_preference.items?.bot_modules;

    return {logs, workorder, unit, log, user_preference, BotModules};
}
const mapDispatchToProps = (dispatch) => {
    return {
        getMyLog: (id) => dispatch(logActions.getDetail(id)),
        getLogs: (req) => dispatch(logActions.getAll(req)),
        clearUnit: () => dispatch(unitActions.clearUnit()),
        getWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getUnit: (id) => dispatch(unitActions.getUnit(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLogs);

