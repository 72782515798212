import {companyRoleConstants} from '../constants/';
import {companyRoleService} from '../services/';

export const companyRoleActions = {};
companyRoleActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyRoleConstants.GET_ALL_COMPANY_ROLES_REQUEST});
    const success = (company_roles) => ({type: companyRoleConstants.GET_ALL_COMPANY_ROLES_SUCCESS, company_roles});
    const failure = (error) => ({type: companyRoleConstants.GET_ALL_COMPANY_ROLES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyRoleService.getAll()
            .then(company_roles => dispatch(success(company_roles)), error => dispatch(failure(error)));
    };
};
companyRoleActions.getCompany = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyRoleConstants.DETAIL_COMPANY_ROLE_REQUEST});
    const success = (company_role) => ({type: companyRoleConstants.DETAIL_COMPANY_ROLE_SUCCESS, company_role});
    const failure = (error) => ({type: companyRoleConstants.DETAIL_COMPANY_ROLE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyRoleService.getCompany(id)
            .then(company_role => dispatch(success(company_role)), error => dispatch(failure(error)));
    };
};
companyRoleActions.createCompany = (company_role) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyRoleConstants.CREATE_COMPANY_ROLE_REQUEST});
    const success = (company_role) => ({type: companyRoleConstants.CREATE_COMPANY_ROLE_SUCCESS, company_role});
    const failure = (error) => ({type: companyRoleConstants.CREATE_COMPANY_ROLE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyRoleService.createCompany(company_role)
            .then(company_role => dispatch(success(company_role)), error => dispatch(failure(error)));
    };
};
