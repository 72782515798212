import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from '../../../components/bot-components';
import Select from 'react-select';
import { roleActions, unitPmActions, workorderActions } from '../../../network/actions';
import WorkOrderDetailModal from '../../WorkOrders/WorkOrderDetail/WorkOrderDetailModal';

const BotPm = ( props ) => {
  const [state, updateState] = React.useState( {
    selectedMechanic:'',
    hours:'',
    mileage:'',
    unit_id:'',
    job_id:'',
    mechanic_id:'',
    mechanic_assigned:false,
    mechanic_name:'',
    workorder_status_id:'',
    notes:'',
    items_checked:{},
    openWorkorder:null,
    attributeList:[],
    WOModal:false
  } );
  /**
   *
   * @param {Object} newState
   */
  const setState = newState => updateState( ( prevState ) => ({
    ...prevState,
    ...newState
  }) );
  const createForm = () => {
    let col1 = [];
    let col2 = [];
    Object.keys( props.unit_pm.items.items_checked ).map( ( item, i ) => {
      if (i < (Object.keys( props.unit_pm.items.items_checked ).length / 2)) {
        col1.push(
          <div>
            <FormGroup check inline>
              <Input type="checkbox" id={ [item] }
                     name={ [item] } onChange={ toggleItemsChecked }
                     defaultChecked={ props.unit_pm.items.items_checked[item].value }
              />
              <Label className="form-check-label" check
                     htmlFor={ [item] }>{ props.unit_pm.items.items_checked[item].text }</Label>
            </FormGroup>
          </div>
        );
      } else {
        col2.push(
          <div>
            <FormGroup check inline>
              <Input type="checkbox" id={ [item] }
                     name={ [item] } onChange={ toggleItemsChecked }
                     defaultChecked={ props.unit_pm.items.items_checked[item].value }
              />
              <Label className="form-check-label" check
                     htmlFor={ [item] }>{ props.unit_pm.items.items_checked[item].text }</Label>
            </FormGroup>
          </div>
        );
      }
      return true;
    } );
    setState( {
      attributeList:[
        <Col sm={ '6' }>
          { col1 }
        </Col>,
        <Col sm={ '6' }>
          { col2 }
        </Col>
      ],
      items_checked:props.unit_pm.items.items_checked,
      mileage:props.unit_pm.items.mileage,
      hours:props.unit_pm.items.hours,
      notes:props.unit_pm.items.notes,
      unit_id:props.unit_pm.items.unit_id,
      workorder_status_id:props.unit_pm.items.workorder_status_id,
      job_id:props.unit_pm.items.job_id,
    } );
  };
  React.useEffect( () => {
    props.getMechanics();
  }, [] );
  React.useEffect( () => {
    const { unit_pm } = props;
    if (unit_pm.items) {
      if (unit_pm.items) {
        createForm();
      }
      if (unit_pm.items.mechanic_id) {
        if (!mechanic_assigned) {
          setMechanicAssigned( {
            assigned:true,
            id:unit_pm.items.mechanic_id,
            name:unit_pm.items.mechanic,
          } );
        }
      }
    }
    if (!state.openWorkorder && unit.items) {
      unit.items.workorders && unit.items.workorders.forEach( workorder => {
        if (workorder.workorder_status === 'Open') {
          setState( { openWorkorder:workorder } );
          localStorage.setItem( 'workorder_id', workorder.id );
        }
      } );
    }
  }, [props.unit_pm, props.unit] );
  const toggleItemsChecked = ( e ) => {
    const { name, checked } = e.target;
    setState( {
      items_checked:{
        ...state.items_checked,
        [name]:{
          ...state.items_checked[name],
          value:checked
        }
      }
    } );
  };
  const changeProps = ( e ) => {
    const { name, value } = e.target;
    setState( {
      [name]:value,
    } );
  };
  const updatePm = () => {
    const PM = {
      id:props.unit_pm.items.id,
      hours:parseFloat( state.hours ),
      mileage:parseFloat( state.mileage ),
      items_checked:state.items_checked,
      notes:state.notes,
      job_id:props.unit_pm.items.job_id,
      unit_id:props.unit_pm.items.unit_id,
      mechanic_id:state.mechanic_id,
      workorder_status_id:state.workorder_status_id
    };
    if (state.selectedMechanic) {
      PM.mechanic_id = state.selectedMechanic.value;
    } else {
      delete PM.mechanic_id;
    }
    props.updateUnitPm( PM );
    const lastComp = localStorage.getItem( 'lastComp' );
    window.location.href = `/#/${ lastComp }`;
  };
  const closePm = () => {
    const PM = {
      id:props.unit_pm.items.id,
      hours:parseFloat( state.hours ),
      mileage:parseFloat( state.mileage ),
      items_checked:state.items_checked,
      notes:state.notes,
      job_id:props.unit_pm.items.job_id,
      unit_id:props.unit_pm.items.unit_id,
      mechanic_id:state.mechanic_id,
      workorder_status_id:2
    };
    if (state.selectedMechanic) {
      PM.mechanic_id = state.selectedMechanic.value;
    } else {
      delete PM.mechanic_id;
    }
    props.updateUnitPm( PM );
    const lastComp = localStorage.getItem( 'lastComp' );
    window.location.href = `/#/${ lastComp }`;
  };
  const openPm = () => {
    const PM = {
      id:props.unit_pm.items.id,
      hours:parseFloat( state.hours ),
      mileage:parseFloat( state.mileage ),
      items_checked:state.items_checked,
      notes:state.notes,
      job_id:props.unit_pm.items.job_id,
      unit_id:props.unit_pm.items.unit_id,
      mechanic_id:state.mechanic_id,
      workorder_status_id:1
    };
    if (state.selectedMechanic) {
      PM.mechanic_id = state.selectedMechanic.value;
    } else {
      delete PM.mechanic_id;
    }
    props.updateUnitPm( PM );
    const lastComp = localStorage.getItem( 'lastComp' );
    window.location.href = `/#/${ lastComp }`;
  };
  const setMechanicAssigned = ( { assigned, id, name } ) => {
    setState( {
      selectedMechanic:{ label:name, value:id },
      mechanic_assigned:assigned,
      mechanic_id:id,
      mechanic_name:name,
    } );
  };
  const toggleWoModal = () => setState( {
    WOModal:!state.WOModal
  } );

  const {
    selectedMechanic, hours, mileage, notes, mechanic_assigned, workorder_status_id, attributeList, openWorkorder
  } = state;
  const { mechanicList, unit } = props;

  return (
    <>
      <WorkOrderDetailModal isOpen={ state.WOModal } toggle={ toggleWoModal } ids={ [openWorkorder?.id] }/>
      <Row>
        <Col sm={ '4' }>
          <Label style={ { fontWeight:'bold' } }>Miles:</Label>
          <Input type={ 'textField' } name={ 'mileage' } value={ mileage }
                 onChange={ changeProps }/>
        </Col>
        <Col sm={ '4' }>
          <Label style={ { fontWeight:'bold' } }>Hours:</Label>
          <Input type={ 'textField' } name={ 'hours' } value={ hours } onChange={ changeProps }/>
        </Col>
        <Col sm={ '4' }>
          <Label style={ { fontWeight:'bold' } }>Mechanic:</Label>
          <Select options={ mechanicList } placeholder={ 'Please select mechanic from list' }
                  matchProp={ 'label' }
                  onChange={ ( selectedMechanic ) => setState( { selectedMechanic:selectedMechanic } ) }
                  value={ selectedMechanic }/>
        </Col>
      </Row>
      <Row>
        { attributeList }
      </Row>
      <Row>
        <Col sm={ '6' }>
          <Label style={ { fontWeight:'bold' } }>Notes:</Label>
          <Input type={ 'textArea' } id={ 'notes' } name={ 'notes' }
                 onChange={ changeProps } value={ notes }/>
        </Col>
      </Row>
      <Row>
        <Col sm={ '3' }>
          <Button outline color={ 'texts' } onClick={ () => {
            updatePm();
          } }>Update</Button>
        </Col>
        <Col sm={ '3' }>
          <Button outline color={ 'texts' } className={ 'float-right' } onClick={ () => {
            workorder_status_id === 1 ? closePm() : openPm();
          } }>{ workorder_status_id === 1 ? 'Close' : 'ReOpen' }</Button>
        </Col>
      </Row>
      <label style={ { fontWeight:'bold', fontSize:18 } }>Open Work Order: </label>{ openWorkorder &&
      <label style={ { fontSize:18, paddingLeft:10, color:'red', cursor:'pointer' } } onClick={ () => {
        props.getMyWorkOrder( openWorkorder.id );
        toggleWoModal();
      } }>{ openWorkorder.id }</label> }
    </>
  );
};
const mapStateToProps = ( state ) => {
  const { units, unit, mechanics, unit_pm } = state;
  let unitList = [];
  let mechanicList = [];

  if (units.items) {
    for (let i = 0; i < units.items.length; i++) {
      const unit_id = units.items[i].id;
      const unit_number = units.items[i].number;
      const unit = { label:unit_number, value:unit_id };
      unitList.push( unit );
    }
  }

  if (mechanics.items) {
    mechanics.items.map( mechanic => {
      return mechanicList.push( { label:mechanic.name, value:mechanic.id } );
    } );
  }

  return { units, unitList, unit, mechanicList, unit_pm };
};
const mapDispatchToProps = ( dispatch ) => {
  return {
    updateUnitPm:( unit_pm ) => dispatch( unitPmActions.updatePm( unit_pm ) ),
    getMechanics:() => dispatch( roleActions.getMechanics() ),
    getPm:( id ) => dispatch( unitPmActions.getPm( id ) ),
    getMyWorkOrder:( id ) => dispatch( workorderActions.getDetail( id ) )
  };
};
export default connect( mapStateToProps, mapDispatchToProps )( BotPm );
