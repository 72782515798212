import {authHeader} from '../../helpers';
import {baseUrl} from "../constants";
import {handleResponse} from './responseHandler';

export const callLogService = {};

const apiUrl = baseUrl;
callLogService.createCallLog = (call_log) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(call_log)
    };

    return fetch(`${apiUrl}/call_log`, requestOptions).then(handleResponse);
};
