import {attachmentConstants} from '../constants';

export const attachments = (state = {}, action) => {
    switch (action.type) {
        case attachmentConstants.GET_ALL_ATTACHMENTS_REQUEST:
            return {
                loading: true
            };
        case attachmentConstants.GET_ALL_ATTACHMENTS_SUCCESS:
            return {
                items: action.attachments
            };
        case attachmentConstants.GET_ALL_ATTACHMENTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const attachment = (state = {}, action) => {
    switch (action.type) {
        case attachmentConstants.DETAIL_ATTACHMENT_REQUEST:
            return {
                loading: true
            };
        case attachmentConstants.DETAIL_ATTACHMENT_SUCCESS:
            return {
                items: action.attachment
            };
        case attachmentConstants.DETAIL_ATTACHMENT_FAILURE:
            return {
                error: action.error
            };
        case attachmentConstants.CREATE_ATTACHMENT_REQUEST:
            return {
                loading: true
            };
        case attachmentConstants.CREATE_ATTACHMENT_SUCCESS:
            return {
                items: action.attachments
            };
        case attachmentConstants.CREATE_ATTACHMENT_FAILURE:
            return {
                error: action.error
            };
        case attachmentConstants.UPDATE_ATTACHMENT_REQUEST:
            return {
                loading: true
            };
        case attachmentConstants.UPDATE_ATTACHMENT_SUCCESS:
            return {
                items: action.attachments
            };
        case attachmentConstants.UPDATE_ATTACHMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
