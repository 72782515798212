import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavLink,
    TabContent,
    TabPane,
    Alert,
    Label
} from '../../components/bot-components';
import {userActions} from '../../network/actions';
import {baseUrl} from '../../network/constants';
import classnames from "classnames";
import UserIntegrationTable from './UserIntegrationTable';
import {NewUpload, PendingUpload} from './';
import ReduxBlockUi from 'react-block-ui';
import {authHeader} from '../../helpers';

class UserIntegration extends Component {
    constructor(props) {
        super(props);
        const currentTab = '1';
        this.state = {
            modal: false,
            file: null,
            tab: currentTab,
            confirmA: false,
            uploadPending: false,
            blocked: false,
            active_fm_users: '',
            active_fm_users_not_in_vista: '',
            active_vista_users: '',
            approved_driver_changes: '',
            created_at: '',
            firstname_changes: '',
            fm_approved_driver_users_not_in_vista: '',
            inactive_users: '',
            job_blank: '',
            job_changes: '',
            lastname_changes: '',
            new_users: '',
            phone_blank: '',
            phone_changes: '',
            role_blank: '',
            role_changes: '',
            total_affected_fm_users: '',
            updated_at: '',
            upload_date: '',
            vista_date: '',
            table_data: [],
            user_table_open: false,
            is_committed: null
        };
        this.auRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);

        this.vistaColumnsFilters = {
            id: "",
            employee_number: "",
            phase_description: "",
            first_name: "",
            last_name: "",
            approved_driver_flag: "",
            cell_phone_number: "",
            position_code: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.fnColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            vista: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.pnColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            vista: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.jnColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            vista: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.crColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            vista: "",
            first_name: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.adColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            vista: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.raColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            user_status: "",
            vista: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.fmColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.lnColumnsFilters = {
            id: "",
            name: "",
            employee_number: "",
            company_role: "",
            first_name: "",
            last_name: "",
            vista: "",
            user_status: "",
            approved_driver: "",
            daily_inspections: "",
            phone_number: "",
            email: "",
            job_number: "",
            yard_name: "",
            role_string: "",
            phase_description: "",
            approved_driver_flag: "",
            last_phase_code: "",
            last_job_worked: "",
        };
        this.hisColumnsFilters = {};

        this.vistaColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.vistaColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.vistaColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.vistaColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.vistaColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'position_code',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.vistaColumnsFilters.position_code
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_job_worked',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.vistaColumnsFilters.last_job_worked
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'cell_phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.vistaColumnsFilters.cell_phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'approved_driver_flag',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.vistaColumnsFilters.approved_driver_flag,
                    options: [{value: "", text: 'Select Y/N'}, {value: 'Y', text: 'Y'}, {value: 'N', text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true
            },
            {
                field: 'last_phase_code',
                text: 'Phase Code',
                filter: {
                    type: 'text',
                    placeholder: 'Phase Code',
                    defaultValue: this.vistaColumnsFilters.last_phase_code
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'phase_description',
                text: 'Phase Code Description',
                filter: {
                    type: 'text',
                    placeholder: 'Phase Code',
                    defaultValue: this.vistaColumnsFilters.phase_description
                },
                width: 200,
                hidden: false,
                sortable: true,
            }
        ];
        this.fnColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.fnColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.fnColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.fnColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.fnColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.fnColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'vista',
                text: 'Vista First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.fnColumnsFilters.vista
                },
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell}</div>
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.fnColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.fnColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.fnColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.fnColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.fnColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.fnColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.fnColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.fnColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.fnColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.pnColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.pnColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.pnColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.pnColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.pnColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.pnColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.pnColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.pnColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.pnColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.pnColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.pnColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'vista',
                text: 'Vista Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.pnColumnsFilters.vista
                },
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell}</div>
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.pnColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.pnColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.pnColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.pnColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.jnColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.jnColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.jnColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.jnColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.jnColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.jnColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.jnColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.jnColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.jnColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.jnColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.jnColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.jnColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.jnColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'vista',
                text: 'Vista Job#',
                filter: {
                    type: 'text',
                    placeholder: 'Job#',
                    defaultValue: this.jnColumnsFilters.vista
                },
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell}</div>
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.jnColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.jnColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.crColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.crColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.crColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.crColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.crColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'vista',
                text: 'Vista Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.crColumnsFilters.vista
                },
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell}</div>
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.crColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.crColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.crColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.crColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.crColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.crColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.crColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.crColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.crColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.crColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.adColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.adColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.adColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.adColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.adColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.adColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.adColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.adColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.adColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'vista',
                text: 'Vista: Approved Driver/Daily',
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell === 'Y' ? 'Yes  /  Yes' : 'No  /  No'}</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.adColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.adColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.adColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.adColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.adColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.adColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.raColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.raColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.raColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.raColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.raColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.raColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.raColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.raColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.raColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'vista',
                text: 'Vista: Status',
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell === 'Y' ? 'Active' : 'Inactive'}</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.raColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.raColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.raColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.raColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.raColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.raColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.fmColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.fmColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.fmColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.fmColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.fmColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.fmColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.fmColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.fmColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.fmColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.fmColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.fmColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.fmColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.fmColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.fmColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.fmColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.lnColumns = [
            {
                field: 'id',
                text: 'ID',
                filter: {
                    type: 'text',
                    placeholder: 'ID',
                    defaultValue: this.lnColumnsFilters.id,
                },
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: this.lnColumnsFilters.name
                },
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'employee_number',
                text: 'Employee#',
                filter: {
                    type: 'text',
                    placeholder: 'Employee#',
                    defaultValue: this.lnColumnsFilters.employee_number
                },
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'company_role',
                text: 'Company Role',
                filter: {
                    type: 'text',
                    placeholder: 'Company Role',
                    defaultValue: this.lnColumnsFilters.company_role
                },
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'first_name',
                text: 'First Name',
                filter: {
                    type: 'text',
                    placeholder: 'First Name',
                    defaultValue: this.lnColumnsFilters.first_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'last_name',
                text: 'Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.lnColumnsFilters.last_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'vista',
                text: 'Vista Last Name',
                filter: {
                    type: 'text',
                    placeholder: 'Last Name',
                    defaultValue: this.lnColumnsFilters.vista
                },
                width: 200,
                hidden: false,
                sortable: true,
                format: cell => <div style={{backgroundColor: 'var(--thrintary)'}}>{cell}</div>
            },
            {
                field: 'user_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: this.lnColumnsFilters.user_status,
                    options: [{value: "", text: 'Select Status'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell, row) => cell ? <div>{row.user_status}</div> : ''
            },
            {
                field: 'approved_driver',
                text: 'Approved Driver',
                filter: {
                    type: 'select',
                    placeholder: 'Approved Driver',
                    defaultValue: this.lnColumnsFilters.approved_driver,
                    options: [{value: "", text: 'Select Approved Driver'}, {value: true, text: 'Yes'}, {value: false, text: 'No'}]
                },
                width: 120,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Yes</div> : <div>No</div>
            },
            {
                field: 'daily_inspections',
                text: 'Daily',
                filter: {
                    type: 'select',
                    placeholder: 'Select Y/N',
                    defaultValue: this.lnColumnsFilters.daily_inspections,
                    options: [{value: "", text: 'Select Y/N'}, {value: true, text: 'Y'}, {value: false, text: 'N'}]
                },
                width: 100,
                hidden: false,
                sortable: true,
                format: (cell) => cell ? <div>Y</div> : <div>N</div>,
            },
            {
                field: 'phone_number',
                text: 'Phone#',
                filter: {
                    type: 'text',
                    placeholder: 'Phone#',
                    defaultValue: this.lnColumnsFilters.phone_number
                },
                width: 150,
                hidden: false,
                sortable: true,
                format: cell => cell !== 'false' ? cell : null,
            },
            {
                field: 'email',
                text: 'Username',
                filter: {
                    type: 'text',
                    placeholder: 'Username',
                    defaultValue: this.lnColumnsFilters.email
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_number',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: this.lnColumnsFilters.job_number
                },
                width: 225,
                hidden: false,
                sortable: true,
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: this.lnColumnsFilters.yard_name
                },
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_string',
                text: 'Roles',
                filter: {
                    type: 'text',
                    placeholder: 'Roles',
                    defaultValue: this.lnColumnsFilters.role_string
                },
                width: 300,
                hidden: false,
                sortable: true,
            }
        ];
        this.hisColumns = [
            {
                field: 'vista_date',
                text: 'Last Vista File Date:',
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'active_vista_users',
                text: 'Active Vista Users:',
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'new_users',
                text: 'New Users Added:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'inactive_users',
                text: 'Inactive Users Re-activated:',
                width: 150,
                hidden: false,
                sortable: true,
            },
            {
                field: 'firstname_changes',
                text: 'First Names Changed:',
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'lastname_changes',
                text: 'Last Names Changed:',
                width: 200,
                hidden: false,
                sortable: true,
            },
            {
                field: 'job_changes',
                text: 'Job Numbers Changed:',
                width: 200,
                hidden: false,
                sortable: true
            },
            {
                field: 'job_missing',
                text: 'Vista Jobs not in FM:',
                width: 120,
                hidden: false,
                sortable: true
            },
            {
                field: 'upload_date',
                text: 'Last Upload Date:',
                width: 180,
                hidden: false,
                sortable: true
            },
            {
                field: 'active_fm_users',
                text: 'Active FleetMatrix Users:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'phone_blank',
                text: 'Phone Number Added',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'phone_diffs',
                text: 'Phone #s not matching Vista:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_blank',
                text: 'Company Roles Changed:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'role_diffs',
                text: 'Company Roles not Matching Vista:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'approved_driver_changes',
                text: 'Approved Driver Changed:',
                width: 120,
                hidden: false,
                sortable: true
            },
            {
                field: 'fm_approved_driver_users_not_in_vista',
                text: 'Approved Drivers not Matching Vista:',
                width: 120,
                hidden: false,
                sortable: true
            },
            {
                field: 'active_fm_users_not_in_vista',
                text: 'FM Users not in Vista:',
                width: 120,
                hidden: false,
                sortable: true,
            },
            {
                field: 'created_by',
                text: 'User:',
                width: 120,
                hidden: false,
                sortable: true,
            }
        ];
    }

    componentDidMount() {
        localStorage.setItem('lastComp', this.props.history?.location?.pathname);
        return this.getLastImport();
    }

    confirmOldFile() {
        this.setState({
            confirmA: false
        })
    }

    setFiles(e) {
        const {files} = e.target;
        const today = new Date();
        const oldDate = today.setDate(today.getDate() - 3);
        const tooOld = files[0].lastModified < oldDate;
        if (tooOld === true) {
            this.setState({
                confirmA: true,
                files: files[0]
            });
        } else {
            this.setState({
                files: files[0]
            });
        }
    }

    cancelFiles() {
        this.setState({
            files: null,
            confirmA: false,
            uploadPending: false
        });
        this.toggle();
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    toggleTab(tab) {
        this.setState({
            tab: tab,
        });
        localStorage.setItem('userTab', tab);
    }

    handleUserImport = async () => {
        const data = new FormData();
        data.append('files', this.importUsers.files[0]);
        this.toggle();
        this.setState({
            blocked: true
        });
        this.importUserData(data).then((data, err) => {
            if (err) {
                this.setState({
                    files: null,
                    blocked: false
                });
                alert('There was an error uploading data, please try again. If the error persists, please contact support.');
            } else {
                this.setState({
                    summary: data.summary,
                    active_vista: data.active_vista,
                    new_fm: data.new_fm,
                    f_name_changes: data.f_name_changes,
                    phone_add: data.phone_add,
                    job_changes: data.job_changes,
                    comp_role_changes: data.comp_role_changes,
                    ad_changes: data.ad_changes,
                    fm_active_not_in_vista: data.fm_active_not_in_vista,
                    fm_active: data.fm_active,
                    reactivated: data.reactivated,
                    l_name_changes: data.l_name_changes,
                    phone_mismatch: data.phone_mismatch,
                    job_missing: data.job_missing,
                    job_added: data.job_added,
                    role_blank: data.role_blank,
                    fm_ad_not_in_vista: data.fm_ad_not_in_vista,
                    total: data.total,
                    files: null,
                    blocked: false,
                    uploadPending: true
                });
            }

        });
    };

    getLastImport() {
        return this.setState({
            blocked: true
        }, () => {
            const requestOptions = {
                method: 'PUT',
                headers: authHeader('PUT')
            };
            return fetch(`${baseUrl}/users/import_summary/`, requestOptions).then(
              /**
               *
               * @param {Response} response
               * @returns {*|Promise<never>}
               */
              response => {
                return response.status !== 404 ? response.json().then(data => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                        }return this.setState({
                            is_committed: null,
                            oldSummary: null,
                            old_active_vista: null,
                            old_new_fm: null,
                            old_f_name_changes: null,
                            old_phone_add: null,
                            old_job_changes: null,
                            old_comp_role_changes: null,
                            old_ad_changes: null,
                            old_fm_active_not_in_vista: null,
                            old_fm_active: null,
                            old_reactivated: null,
                            old_l_name_changes: null,
                            old_phone_mismatch: null,
                            old_job_missing: null,
                            old_job_added: null,
                            old_role_blank: null,
                            old_fm_ad_not_in_vista: null,
                            old_total: null,
                            loading: false
                        }, () => alert('There was an error loading the data, please try again...'));
                    }
                    if (!data.summary) {
                        return this.setState({
                            is_committed: null,
                            oldSummary: null,
                            old_active_vista: null,
                            old_new_fm: null,
                            old_f_name_changes: null,
                            old_phone_add: null,
                            old_job_changes: null,
                            old_comp_role_changes: null,
                            old_ad_changes: null,
                            old_fm_active_not_in_vista: null,
                            old_fm_active: null,
                            old_reactivated: null,
                            old_l_name_changes: null,
                            old_phone_mismatch: null,
                            old_job_missing: null,
                            old_job_added: null,
                            old_role_blank: null,
                            old_fm_ad_not_in_vista: null,
                            old_total: null,
                            blocked: false
                        });
                    } else if (data.summary.is_committed === 'Y') {
                        return this.setState({
                            is_committed: 'Y',
                            oldSummary: data.summary,
                            old_active_vista: data.active_vista,
                            old_new_fm: data.new_fm,
                            old_f_name_changes: data.f_name_changes,
                            old_phone_add: data.phone_add,
                            old_job_changes: data.job_changes,
                            old_comp_role_changes: data.comp_role_changes,
                            old_ad_changes: data.ad_changes,
                            old_fm_active_not_in_vista: data.fm_active_not_in_vista,
                            old_fm_active: data.fm_active,
                            old_reactivated: data.reactivated,
                            old_l_name_changes: data.l_name_changes,
                            old_phone_mismatch: data.phone_mismatch,
                            old_job_missing: data.job_missing,
                            old_job_added: data.job_added,
                            old_role_blank: data.role_blank,
                            old_fm_ad_not_in_vista: data.fm_ad_not_in_vista,
                            old_total: data.total,
                            blocked: false
                        });
                    } else if (data.summary.is_committed === 'N') {
                        return this.setState({
                            is_committed: 'N',
                            oldSummary: null,
                            old_active_vista: null,
                            old_new_fm: null,
                            old_f_name_changes: null,
                            old_phone_add: null,
                            old_job_changes: null,
                            old_comp_role_changes: null,
                            old_ad_changes: null,
                            old_fm_active_not_in_vista: null,
                            old_fm_active: null,
                            old_reactivated: null,
                            old_l_name_changes: null,
                            old_phone_mismatch: null,
                            old_job_missing: null,
                            old_job_added: null,
                            old_role_blank: null,
                            old_fm_ad_not_in_vista: null,
                            old_total: null,
                            blocked: false
                        });
                    }
                    return null;
                }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
            });
        });

    }

    importUserData(data) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader('GET'),
            contentLength: data.size,
            body: data,
        };
        return fetch(`${baseUrl}/users/`, requestOptions).then(
          /**
           *
           * @param {Response} response
           * @returns {*|Promise<never>}
           */
          response => {
            return response.status !== 404 ? response.json().then(data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    return null;
                }
                return data;
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    }

    saveUpload() {
        this.setState({
            blocked: true
        }, () => {
            const requestOptions = {
                method: 'PUT',
                headers: authHeader('PUT')
            };
            return fetch(`${baseUrl}/users/import/commit/`, requestOptions).then(
              /**
               *
               * @param {Response} response
               * @returns {*|Promise<never>}
               */
              response => {
                return response.status !== 404 ? response.json().then(data => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                        }
                        return null;
                    }
                    return this.setState({
                        is_committed: 'Y',
                        oldSummary: data.summary,
                        old_active_vista: data.active_vista,
                        old_new_fm: data.new_fm,
                        old_f_name_changes: data.f_name_changes,
                        old_phone_add: data.phone_add,
                        old_job_changes: data.job_changes,
                        old_comp_role_changes: data.comp_role_changes,
                        old_ad_changes: data.ad_changes,
                        old_fm_active_not_in_vista: data.fm_active_not_in_vista,
                        old_fm_active: data.fm_active,
                        old_reactivated: data.reactivated,
                        old_l_name_changes: data.l_name_changes,
                        old_phone_mismatch: data.phone_mismatch,
                        old_job_missing: data.job_missing,
                        old_job_added: data.job_added,
                        old_role_blank: data.role_blank,
                        old_fm_ad_not_in_vista: data.fm_ad_not_in_vista,
                        old_total: data.total,
                        blocked: false,
                        uploadPending: false
                    });
                }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
            });
        })

    }

    cancelUpload() {
        this.setState({
            is_committed: 'N',
            oldSummary: null,
            old_active_vista: null,
            old_new_fm: null,
            old_f_name_changes: null,
            old_phone_add: null,
            old_job_changes: null,
            old_comp_role_changes: null,
            old_ad_changes: null,
            old_fm_active_not_in_vista: null,
            old_fm_active: null,
            old_reactivated: null,
            old_l_name_changes: null,
            old_phone_mismatch: null,
            old_job_missing: null,
            old_job_added: null,
            old_role_blank: null,
            old_fm_ad_not_in_vista: null,
            old_total: null,
            blocked: false,
            uploadPending: false
        });
    }

    setTableData(set, columns) {
        this.setState({
            table_data: this.state[set],
            vista_cols: this[columns],
            vista_filters: this[`${columns}Filters`]
        }, () => {
            this.toggleUserTable();
        });
    }

    setHistory() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };
        return fetch(`${baseUrl}/users/import_history/`, requestOptions).then(
          /**
           *
           * @param {Response} response
           * @returns {*|Promise<never>}
           */
          response => {
            return response.status !== 404 ? response.json().then(data => {
                if (!response.ok) {
                    if (response.status === 401) {
                        alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                    }
                    return null;
                }
                return this.setState({
                    table_data: data,
                    vista_cols: this.hisColumns,
                    vista_filters: this.hisColumnsFilters
                }, () => {
                    this.toggleUserTable();
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    }

    toggleUserTable() {
        this.setState({
            user_table_open: !this.state.user_table_open
        });
    }

    render() {
        const {files, confirmA, is_committed, blocked} = this.state;
        return (
            <ReduxBlockUi blocking={blocked} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <Alert color={'danger'} isOpen={confirmA} toggle={() => this.cancelFiles()}>
                        <label>This file is more than 3 days old...</label>
                        <label>Are you sure you want to use this file?</label>
                        <hr/>
                        <div><Button color={'success'} onClick={() => this.confirmOldFile(files)}>Yes</Button><Button color={'warning'} onClick={() => this.cancelFiles()}>No</Button> </div>
                    </Alert>
                    <ModalHeader toggle={this.toggle}>Import Users</ModalHeader>
                    <ModalBody>
                        <input ref={(ref) => {
                            this.importUsers = ref
                        }} onChange={(files) => {
                            this.setFiles(files)
                        }} type="file" id={'user_import'} accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}/><label style={{textAlign: 'center'}}
                                                                                      htmlFor={'user_import'}>Import
                        Users <i className={'fa fa-users'}/></label> {files ?
                        <label>{files.name}</label> : <label>No files selected.</label>}{}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'success'} onClick={() => {
                            return this.handleUserImport()
                        }}>Submit</Button>
                        <Button color={'warning'} onClick={() => {
                            this.cancelFiles()
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal size={'xl'} isOpen={this.state.user_table_open} toggle={this.toggleUserTable.bind(this)} className={this.props.className}>
                    <ModalHeader toggle={this.toggleUserTable.bind(this)}/>
                    <ModalBody>
                        <UserIntegrationTable
                            data={this.state.table_data ? this.state.table_data : null}
                            remote={false}
                            tblId={'uitRef'}
                            getUsers={() => console.log('Nope')}
                            onUserSelected={() => console.log('Nope')}
                            getMyUser={(id) => this.props.getUser(id)}
                            expandable={!this.state.columns}
                            pageSizes={['20', '50', '100', '250', '500', '1000']}
                            title={'User Integration'}
                            columns={this.state.vista_cols ? this.state.vista_cols : null}
                            filters={this.state.vista_filters ? this.state.vista_filters : null}
                            u_preferences={this.props.user_preference}/>
                    </ModalBody>
                </Modal>
                <Button className={'bot-3d-user-history-button'} onClick={() => this.setHistory()}><i className={'fa fa-history'}/> History</Button>
                <Nav tabs>
                    <NavLink className={classnames({active: this.state.tab === '1'})}
                             onClick={() => {
                                 this.toggleTab('1');
                             }}>
                        User Integration
                    </NavLink>
                </Nav>
                <TabContent activetab={this.state.tab}>
                    <TabPane tabId={'1'}>
                        {this.state.uploadPending ?
                            <PendingUpload setTableData={(set, vista) => this.setTableData(set, vista)} cancelUpload={() => this.cancelUpload()} saveUpload={() => this.saveUpload()} viewHistory={() => this.setHistory()} summaryData={this.state}/> :
                            <NewUpload setTableData={(set, vista) => this.setTableData(set, vista)} is_committed={is_committed} viewHistory={() => this.setHistory()} setUpload={() => this.toggle()} summaryData={this.state.oldSummary}/>}
                    </TabPane>
                </TabContent>
            </ReduxBlockUi>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, user_preference} = state;
    const user = authentication.user;
    return {user, user_preference};
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => dispatch(userActions.getDetail(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIntegration);
