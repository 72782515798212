import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    wrapTag: tagPropType,
    toggle: PropTypes.func,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    children: PropTypes.node,
    closeAriaLabel: PropTypes.string,
    charCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    close: PropTypes.object,
};

const defaultProps = {
    tag: 'h5',
    wrapTag: 'div',
    closeAriaLabel: 'Close',
    charCode: 215,
};

const ModalHeader = ({ className, cssModule, children, toggle, tag: Tag, wrapTag: WrapTag, closeAriaLabel, charCode, close, ...attributes }) => {
    let closeButton;
    const classes = mapToCssModules(classNames(
        className,
        'modal-header'
    ), cssModule);

    if (!close && toggle) {
        const closeIcon = typeof charCode === 'number' ? String.fromCharCode(charCode) : charCode;
        closeButton = (
            <bot-button type="bot-button" onClick={toggle} className={mapToCssModules('close', cssModule)} aria-label={closeAriaLabel}>
                <span aria-hidden="true">{closeIcon}</span>
            </bot-button>
        );
    }

    return (
        <WrapTag {...attributes} className={classes}>
            <Tag className={mapToCssModules('modal-title', cssModule)}>
                {children}
            </Tag>
            {close || closeButton}
        </WrapTag>
    );
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
