import {unitTypeConstants} from '../constants';

export const unit_types = (state = {}, action) => {
    switch (action.type) {
        case unitTypeConstants.GET_ALL_UNIT_TYPES_REQUEST:
            return {
                loading: true
            };
        case unitTypeConstants.GET_ALL_UNIT_TYPES_SUCCESS:
            return {
                items: action.unit_types
            };
        case unitTypeConstants.GET_ALL_UNIT_TYPES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const unit_type = (state = {}, action) => {
    switch (action.type) {
        case unitTypeConstants.CREATE_UNIT_TYPE_REQUEST:
            return {
                loading: true
            };
        case unitTypeConstants.CREATE_UNIT_TYPE_SUCCESS:
            return {
                items: action.unit_type
            };
        case unitTypeConstants.CREATE_UNIT_TYPE_FAILURE:
            return {
                error: action.error
            };
        case unitTypeConstants.DETAIL_UNIT_TYPE_REQUEST:
            return {
                loading: true
            };
        case unitTypeConstants.DETAIL_UNIT_TYPE_SUCCESS:
            return {
                items: action.unit_type
            };
        case unitTypeConstants.DETAIL_UNIT_TYPE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
