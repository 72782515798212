// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const commentService = {};

const apiUrl = baseUrl;

commentService.createComment = (comment) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(comment)
    };

    return fetch(`${apiUrl}/comment`, requestOptions).then(handleResponse);
};

commentService.updateComment = (comment) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(comment)
    };


    return fetch(`${apiUrl}/comment/` + comment.id, requestOptions).then(handleResponse);
};

commentService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/comments`, requestOptions).then(handleResponse);
};

commentService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/comment/` + id, requestOptions).then(handleResponse);
};
