import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
};

const defaultProps = {
    tag: 'div'
};

const CardHeader = ({ className, cssModule, tag: Tag, ...attributes }) => {
    const classes = mapToCssModules(classNames(
        className,
        'bot-card-header'
    ), cssModule);

    return (
        <Tag {...attributes} className={classes} />
    );
};

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

export default CardHeader;
