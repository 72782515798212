import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'babel-polyfill';
import App from './App';
import {store} from "./js/helpers";
import {Provider} from 'react-redux';
import Bugsnag from './js/helpers/Bugsnag';
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App/>
        </Provider>
    </ErrorBoundary>,
  document.getElementById('root')
);
