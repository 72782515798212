import {partConstants} from '../constants';

export const parts = (state = {}, action) => {
    switch (action.type) {
        case partConstants.GET_ALL_PARTS_REQUEST:
            return {
                loading: true
            };
        case partConstants.GET_ALL_PARTS_SUCCESS:
            return {
                items: action.parts
            };
        case partConstants.GET_ALL_PARTS_FAILURE:
            return {
                error: action.error
            };
        case partConstants.CREATE_PART_REQUEST:
            return {
                loading: true
            };
        case partConstants.CREATE_PART_SUCCESS:
            return {
                items: action.parts
            };
        case partConstants.CREATE_PART_FAILURE:
            return {
                error: action.error
            };
        case partConstants.UPDATE_PART_REQUEST:
            return {
                loading: true
            };
        case partConstants.UPDATE_PART_SUCCESS:
            return {
                items: action.parts
            };
        case partConstants.UPDATE_PART_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const part = (state = {}, action) => {
    switch (action.type) {
        case partConstants.DETAIL_PART_REQUEST:
            return {
                loading: true
            };
        case partConstants.DETAIL_PART_SUCCESS:
            return {
                items: action.part
            };
        case partConstants.DETAIL_PART_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
