import PropTypes from 'prop-types';
import _ from 'lodash';

export const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    data: PropTypes.any,
    getUnits: PropTypes.func,
    getMyUnit: PropTypes.func,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    updateUnit: PropTypes.func,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    canCreate: PropTypes.bool,
    unit: PropTypes.object,
    asset_types: PropTypes.object.isRequired,
    tire_sizes: PropTypes.object.isRequired,
    alertUnit: PropTypes.func,
    customBtns: PropTypes.any,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    saveUnit: PropTypes.func
};
export const defaultFilters = {
    number: "",
    hours: "",
    mileage: "",
    unit_type: "",
    unit_subtype: "",
    unit_status: "",
    yard: "",
    vin_sn: "",
    description: "",
    tire_size: "",
    asset_type: "",
    active: "",
    dot: "",
    tire_size_2: "",
    tire_size_3: "",
    tire_size_4: "",
    plate: "",
    registration_date: "",
    location: "",
    tare_weight: "",
    gross_weight: "",
    height: "",
    wheelbase: "",
    axles: "",
    width: "",
    overall_length: "",
    horsepower: "",
    cadr: "",
    company: "",
    job: "",
    operator: ""
};
const defaultSort = {
    sortName: '',
    sortOrder: '',
};
const defaultPaging = {
    currSize: 20,
    currPage: 1
};
const myFilters = _.cloneDeep(defaultFilters);
export const defaultState = {
    ...defaultSort,
    ...defaultPaging,
    filters: myFilters,
    selectedUnitType: null,
    selectedUnitSubType: null,
    selectAll: false,
    selected: [],
    unitModal: false
};
