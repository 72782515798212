// noinspection JSCheckFunctionSignatures

import React from 'react';
import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../components/bot-components';
import PropTypes from 'prop-types';
import {validateUniqueJobNum} from '../../helpers';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    yard_id: PropTypes.any,
    createJob: PropTypes.func,
    managerList: PropTypes.array,
    jobStatusList: PropTypes.array,
    yardList: PropTypes.array
};

const CreateNewJob = (props) => {
    const [state, updateState] = React.useState({
        jobNumberIsValid: true,
        name: '',
        number: '',
        job_status_id: 2,
        supervisor_id: null,
        yard_id: props.yard_id
    });
    const [selectedStatus, setStatus] = React.useState(props.jobStatusList ? props.jobStatusList.find(j => j.value === 2) : null);
    const [selectedManage, setManager] = React.useState(null);
    const [selectedYard, setYard] = React.useState(props.yard_id && props.yardList ? props.yardList.find(y => y.value === props.yard_id) : null);
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    React.useEffect(() => {
        setState({yard_id: props.yard_id});
    }, [props.yard_id]);

    const validateJobNumber = async (value) => {
        setState({
            jobNumberIsValid: await validateUniqueJobNum(value, null)
        });
    };

    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value === '' ? null : value
        });
    };

        const {name, number, job_status_id, yard_id, jobNumberIsValid} = state;
        const {isOpen, toggle, createJob} = props;

        return (
            <Modal toggle={toggle} isOpen={isOpen}>
                <ModalHeader toggle={toggle} isOpen={isOpen}>
                    Create New Job
                </ModalHeader>
                <ModalBody toggle={toggle} isOpen={isOpen}>
                    <FormGroup>
                        <Label for={'name'}>{'Name'}</Label>
                        <Input name={'name'} autoComplete={'off'} invalid={(!name || (name && name.length < 4))}
                               onChange={(e) => changeProps(e)} type={'text'}/>
                        {(!name || (name && name.length < 4)) &&
                        <small style={{color: 'var(--red)'}}>*You must enter an name at least 4 characters
                            long.</small>}
                    </FormGroup>
                    <FormGroup>
                        <Label for={'number'}>{'Number'}</Label>
                        <Input name={'number'} autoComplete={'off'}
                               invalid={(!number || (number && number.length < 4)) || !jobNumberIsValid}
                               onChange={(e) => changeProps(e)} type={'text'} onBlur={(e) => {
                            return validateJobNumber(e.target.value);
                        }}/>
                        {(!number || (number && number.length < 4)) &&
                        <small style={{color: 'var(--red)'}}>*You must enter an job# at least 4 characters
                            long.</small>}
                        {!jobNumberIsValid &&
                        <small style={{color: 'var(--red)'}}>*The job# you have entered is already in use in the
                            system.</small>}
                    </FormGroup>
                    <FormGroup>
                        <Label for={'job_status_id'}>{'Status'}</Label>
                        <Input name={'job_status_id'} type={'react-select'} invalid={!job_status_id}
                               value={selectedStatus}
                               onChange={(e) => {
                                   setState({
                                       user_status_id: e.value
                                   });
                                   setStatus(e);
                               }} options={props.jobStatusList}/>
                        {(!job_status_id) && <small style={{color: 'var(--red)'}}>*You must select a status.</small>}
                    </FormGroup>
                    <FormGroup>
                        <Label for={'supervisor_id'}>{'Supervisor'}</Label>
                        <Input name={'supervisor_id'} type={'react-select'}
                               value={selectedManage} options={props.managerList}
                               onChange={(e) => {
                            setState({
                                supervisor_id: e.value
                            });
                            setManager(e);
                        }}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for={'yard_id'}>{'Location'}</Label>
                        <Input name={'yard_id'} type={'react-select'}
                               value={selectedYard}
                               options={props.yardList}
                               invalid={!yard_id}
                               onChange={(e) => {
                                   setState({
                                       yard_id: e.value
                                   });
                                   setYard(e);
                               }}/>
                        {(!yard_id) && <small style={{color: 'var(--red)'}}>*You must select a location.</small>}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color={'texts'} outline={true} onClick={toggle}>Cancel</Button><Button color={'texts'} outline={true}
                                                                                    disabled={(!number || (number && number.length < 4)) || (!name || (name && name.length < 4)) || !job_status_id || !yard_id || !jobNumberIsValid}
                                                                                    onClick={() => {
                                                                                        createJob(state);
                                                                                        toggle();
                                                                                    }}>Save</Button>
                </ModalFooter>
            </Modal>
        );
};

CreateNewJob.propTypes = propTypes;
export default CreateNewJob;
