/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {authHeader} from '../../../helpers';
import {Button, Nav, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, Card, CardBody, CardHeader, Col, Input,
    Label, Row} from '../../../components/bot-components'
import ReduxBlockUi from 'react-block-ui';
import {baseUrl} from "../../../network/constants";
import {defaultState, defaultProps} from './WorkOrderDetail.constants';
import {getPanes, getTabs, mapDispatchToProps, mapStateToProps} from './WorkOrderDetail.helpers';
import AttachmentInput from '../../Attachments/AttachmentInput/AttachmentInput';

let openPm;

const WorkorderDetail = (props) => {
    const attachFiles = React.useRef();
    const wtRef = React.useRef();
    const wiRef = React.useRef();
    const liRef = React.useRef();
    const wpRef = React.useRef();
    const wpaRef = React.useRef();
    // const waRef = React.useRef();
    const [mods, setMods] = React.useState(props.user_preference.items?.bot_modules?.filter(m => m.enabled));
    const [state, updateState] = React.useState(defaultState);
    /**
     *
     * @param {object} newState
     */
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const {ids} = props;

    React.useEffect(() => {
        const nm = props.user_preference.items?.bot_modules?.filter(m => m.enabled);
        !_.isEqual(nm, mods) && setMods(nm);
    }, [props.user_preference, mods]);

    const toggleClosingModal = () => {
        setState({
            closingModal: !state.closingModal,
        });
    };

    const toggleAttachmentModal = () => {
        setState({
            attachmentModal: !state.attachmentModal,
        });
    };

    const confirmWorkorder = () => {
        if (props.workorder.items['created_by_log']) {
            props.confirmWorkorder();
        }
    };

    const updateWo = (wo) => {
        const u = {
            id: wo.unit_id,
            unit_status_id: wo.unit_status_id
        };
        props.updateUnitStatus(u);
        delete wo.unit_id;
        delete wo.unit_status_id
        props.updateWorkorder(wo);
    };

    // const _handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         setState({
    //             [e.target.name]: false,
    //         });
    //     }
    // };
    //
    // const toggle = (name, val) => {
    //     setState({
    //         [name]: val,
    //     });
    // };

    const changeMessage = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value,
        })
    };

    const getNext = () => {
        openPm = null;
        const index = ids?.indexOf(props.workorder.items?.id) + 1;
        const id = ids?.[index];
        id && props.getMyWorkorder(id);
        // if (props.workorder.items) {
        //     let workorder_id = props.workorder.items.id;
        //     props.getNextWorkorder(workorder_id);
        // } else {
        //     let workorder_id = localStorage.getItem('workorder_id');
        //     props.getNextWorkorder(workorder_id);
        // }
    };

    const getPrev = () => {
        openPm = null;
        const index = ids?.indexOf(props.workorder.items?.id) - 1;
        const id = ids?.[index];
        id && props.getMyWorkorder(id);
        // if (props.workorder.items) {
        //     let workorder_id = props.workorder.items.id;
        //     props.getPreviousWorkorder(workorder_id);
        // } else {
        //     let workorder_id = localStorage.getItem('workorder_id');
        //     props.getPreviousWorkorder(workorder_id);
        // }
    };

    const closeWorkorder = () => {
        openPm = null;
        const workorder = {
            id: props.workorder.items.id,
            workorder_status_id: 2,
            workorder_status: 'Closed',
            closing_message: state.closing_message,
        };
        toggleClosingModal();
        props.closeWorkorder(workorder);
    };

    React.useEffect(() => {
        setState({openPm: null, activeTab: localStorage.getItem('wodTab') ?? '1'});
        !props.mechanicList && props.getMechanics();
        !props.vendList && props.getVendors();
        !props.priorityList && props.getTaskPriorities();
    }, []);

    const toggleTab = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab,
            });
            localStorage.setItem('wodTab', tab);
        }
    };

    React.useEffect(() => {
        const {workorder, unit, attachment} = props;
        if (!workorder.loading) {
            setState({
                myWo: {...workorder.items},
                blocked: false
            });
            if (attachment?.items) {
                setState({
                    attachment: attachment.items,
                });
            }
            if (unit?.loading) {
                setState({
                    blocked: true,
                });
            } else {
                setState({
                    blocked: false,
                });
            }
            if (!openPm && unit?.items && unit?.items.unit_pms) {
                unit.items.unit_pms.forEach(pm => {
                    if (pm.workorder_status === "Open") {
                        openPm = pm;
                        localStorage.setItem('pm_id', pm.id);
                    }
                });
            }
            setState({
                usage_history: workorder?.items?.usage_history ? [...workorder.items.usage_history] : null
            });
        } else {
            setState({
                blocked: true
            });
        }
    }, [props.workorder, props.attachment, props.unit]);

    const onUpdatePart = (row) => {
        const part_id = row.id;
        const part = {
            id: part_id,
            number: row.number,
            description: row.description,
            unit_cost: row.unit_cost,
            qty: row.qty,
            ordered: row.ordered,
            received: row.received,
            vendor_id: row.vendor_id,
        };
        props.updatePart(part);
        return true;
    };

    const onCreatePart = (row) => {
        const part = {
            number: row.number,
            description: row.description,
            unit_cost: row.unit_cost,
            qty: row.qty,
            filter: row.filter,
            requested: row.requested,
            ordered: row.ordered,
            received: row.received,
            unit_id: props.workorder.items.unit_id,
            workorder_id: props.workorder.items.id,
            vendor_id: row.vendor_id,
        };
        props.createPart(part);
    };

    const onUpdatePo = (row) => {
        const po_id = row.id;
        const po = {
            id: po_id,
            description: row.description,
            number: row.number,
            po_status_id: row.po_status_id,
            vendor_id: row.vendor_id,
            amount: row.amount
        };
        props.updatePurchaseOrder(po);
        return true;
    };

    const onCreatePo = (row) => {
        const po = {
            number: row.number,
            description: row.description,
            amount: row.amount,
            vendor_id: row.vendor_id,
            po_status_id: row.po_status_id,
            workorder_id: props.workorder.items.id,
        };
        props.createPurchaseOrder(po);
    };

    const handleAttachFiles = () => {
        const data = new FormData();
        data.append('workorder_id', props.workorder.items.id);
        data.append('file', state.file);
        props.attachFiles(data, props.workorder.items.id);
        toggleAttachmentModal();
    }

    const setFiles = (e) => {
        const {files} = e.target;
        setState({
            file: files[0],
        });
    }

    const cancelFiles = () => {
        setState({
            file: null,
        });
        toggleAttachmentModal();
    }

    const onSaveComment = (Comment) => {
        const workorder_id = props.workorder.items.id;
        Promise.resolve(workorder_id).then(() => {
            Comment.workorder_id = workorder_id;
            props.saveComment(Comment);
        });
    }

    const downloadWorkOrder = () => {
        const {workorder} = props;
        if (workorder.items) {
            setState({blocked: true});
            const requestOptions = {
                method: 'GET',
                headers: authHeader('GET')
            };
            return fetch(`${baseUrl}/workorder/download/${workorder.items.id}`, requestOptions).then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `work_order_${workorder.items.id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }).then(() => setState({blocked: false}));
            });
        }
    }

    const updateTask = (row) => {
        const task = {
            id: row.id,
            description: row.description,
            task_priority_id: row.task_priority_id,
            vendor_id: row.vendor_id,
            mechanic_id: row.mechanic_id
        };
        props.updateTask(task);
    };

    const createTask = (row) => {
        const task = {
            description: row.description,
            complete: row.complete,
            vendor: row.vendor,
            workorder_id: props.workorder.items.id,
            vendor_id: row.vendor_id,
            mechanic_id: row.mechanic_id,
            est_complete_date: null,
        };
        if (task.vendor_id === 'Select Service Provider' || !task.vendor_id) {
            delete task.vendor_id;
            delete task.vendor;
        } else {
            delete task.mechanic_id;
        }
        if (task.mechanic_id === 'Select Mechanic' || !task.mechanic_id) {
            delete task.mechanic_id;
        } else {
            delete task.vendor_id;
        }
        if (row.est_complete_date) {
            task.est_complete_date = row.est_complete_date;
        } else {
            delete task.est_complete_date;
        }
        props.createTask(task);
    };

    const cancelUpdate = () => {
        setState({
            edit: false
        })
    };

    WorkorderDetail.defaultProps = props;

    /**
     * @param myWo.first_record
     * @param myWo.last_record
     */
    // const {workorder, workorder_statusList, unit_statusList, unit, mechanicList, vendList, yardList,
    //     PriorityList, calls, task, inspections, attachments, log} = props;
    const {file, blocked, myWo} = state;
    console.log(myWo);
    return (
        <ReduxBlockUi blocking={blocked} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
            <Card>
                <Modal isOpen={state.closingModal} toggle={toggleClosingModal} className={props.className}>
                    <ModalHeader toggle={toggleClosingModal}>Close Work Order</ModalHeader>
                    <ModalBody>
                        <label style={{textAlign: 'center'}} htmlFor={'closing_message'}>Notes</label>
                        <Input name={'closing_message'} onChange={changeMessage} type="textarea" id={'closing_message'}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'texts'} outline onClick={closeWorkorder}>Submit</Button>
                        <Button color={'texts'} outline onClick={toggleClosingModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <CardHeader>
                    <Row>
                        <Col sm={1}>
                            <Button disabled={ids && myWo ? myWo.id === ids?.[0] : true} className={'back-btn'}
                                    onClick={() => getPrev()}><i
                                    className={'fa fa-step-backward'}/></Button>
                        </Col>
                        {myWo &&
                        <Col sm={10}>
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <h3 style={{textAlign: 'center'}}>Work Order Info for
                                            Equipment# {myWo && myWo.unit_number}</h3>
                                        {!state.edit ?
                                            <Label style={{
                                                textDecoration: 'underline',
                                                color: 'red',
                                                paddingLeft: '5px',
                                                fontSize: '14pt',
                                                cursor: 'pointer'
                                            }} onClick={() => setState({edit: true, activeTab: '1'})}>Edit</Label> :
                                            <Label style={{
                                                textDecoration: 'underline',
                                                color: 'red',
                                                paddingLeft: '5px',
                                                fontSize: '14pt',
                                                cursor: 'pointer'
                                            }} onClick={() => setState({edit: false, activeTab: '1'})}>Cancel Edit</Label>}
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <AttachmentInput toggleTab={toggleTab} toggle={toggleAttachmentModal} attachments={myWo.images} ref={attachFiles} file={file}
                                                     setFiles={setFiles} handleAttachFiles={handleAttachFiles} cancelFiles={cancelFiles} isOpen={state.attachmentModal}
                                                     tab={'8'}>
                                        <Button color={'texts'} outline id="buttonID" onClick={downloadWorkOrder}>Print <i className={'fa fa-print fa-x2'}/></Button>
                                        {myWo['created_by_log'] && <Button color={'texts'} outline onClick={confirmWorkorder}>Confirm Work Order</Button>}
                                        {myWo.workorder_status_id === 1 && <Button color={'texts'} outline onClick={toggleClosingModal}>Close</Button>}
                                    </AttachmentInput>
                                </Col>
                            </Row>
                        </Col>}
                        <Col sm={1}>
                            <Button disabled={ids && myWo ? myWo.id === ids?.[ids?.length - 1] : true} className={'skip-btn'} onClick={getNext}><i className={'fa fa-step-forward'}/></Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        {getTabs(mods, state, toggleTab)}
                    </Nav>
                    <TabContent activetab={state.activeTab}>
                        {getPanes(mods, state, toggleTab, cancelUpdate, updateWo, props, wiRef, liRef, wtRef, onSaveComment, updateTask, createTask, myWo?.logs, wpRef, onCreatePo, onUpdatePo, wpaRef, onCreatePart, onUpdatePart)}
                    </TabContent>
                </CardBody>
            </Card>
        </ReduxBlockUi>
    );
};

WorkorderDetail.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderDetail);
