import {purchaseOrderConstants} from '../constants';

export const purchase_orders = (state = {}, action) => {
    switch (action.type) {
        case purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_REQUEST:
            return {
                loading: true
            };
        case purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_SUCCESS:
            return {
                items: action.purchase_orders
            };
        case purchaseOrderConstants.GET_ALL_PURCHASE_ORDERS_FAILURE:
            return {
                error: action.error
            };
        case purchaseOrderConstants.CREATE_PURCHASE_ORDER_REQUEST:
            return {
                loading: true
            };
        case purchaseOrderConstants.CREATE_PURCHASE_ORDER_SUCCESS:
            return {
                items: action.purchase_orders
            };
        case purchaseOrderConstants.CREATE_PURCHASE_ORDER_FAILURE:
            return {
                error: action.error
            };
        case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_REQUEST:
            return {
                loading: true
            };
        case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_SUCCESS:
            return {
                items: action.purchase_orders
            };
        case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const purchase_order = (state = {}, action) => {
    switch (action.type) {
        case purchaseOrderConstants.DETAIL_PURCHASE_ORDER_REQUEST:
            return {
                loading: true
            };
        case purchaseOrderConstants.DETAIL_PURCHASE_ORDER_SUCCESS:
            return {
                items: action.purchase_order
            };
        case purchaseOrderConstants.DETAIL_PURCHASE_ORDER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
