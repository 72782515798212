import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const attributeService = {};

const apiUrl = baseUrl;

attributeService.createAttribute = (attribute) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(attribute)
    };

    return fetch(`${apiUrl}/inspection_check`, requestOptions).then(handleResponse);
};

attributeService.updateAttribute = (attribute) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(attribute)
    };

    return fetch(`${apiUrl}/inspection_check/` + attribute.id, requestOptions).then(handleResponse);
};

attributeService.getByType = (unit_type) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(unit_type)
    };

    return fetch(`${apiUrl}/unit_inspection_checks`, requestOptions).then(handleResponse)
};

attributeService.getByClass = (unit_subtype) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(unit_subtype)
    };

    return fetch(`${apiUrl}/unit_inspection_checks/class`, requestOptions).then(handleResponse)
};

attributeService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET'),
    };

    return fetch(`${apiUrl}/inspection_checks`, requestOptions).then(handleResponse)
};

attributeService.getAttribute = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/inspection_check/` + id, requestOptions).then(handleResponse);
};
