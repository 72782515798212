import {userService} from '../services/';
import {history} from '../../helpers'
import * as con from './user.actions.constants';

export const userActions = {};
userActions.login = (email, password) => dispatch => {
    dispatch(con.loginRequest());
    userService.login(email, password)
        .then(user => {
                dispatch(con.loginSuccess(user));
                localStorage.setItem('user', JSON.stringify(user));
                history.push('/');
            }, err => dispatch(con.loginFailure(err)))
        .catch(error => dispatch(con.loginFailure(error)));
};
userActions.updateAuth = (user, error) => dispatch => {
    if (user?.token) {
        if (user.user.role_string) {
            const _roles = user.user.role_string.split(',');
            if (_roles.includes('Administrator') || _roles.includes('Manager') || _roles.includes('Staff') || _roles.includes('Payroll')) {
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(con.loginSuccess(user));
            } else {
                alert('You are not authorized to access this resource. This incident has been logged.');
            }
        } else {
            if (user.user.roles.includes('Administrator') || user.user.roles.includes('Manager') || user.user.roles.includes('Staff') || user.user.roles.includes('Payroll')) {
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(con.loginSuccess(user))
            } else {
                alert('You are not authorized to access this resource. This incident has been logged.');
            }
        }
    } else {
        dispatch(con.loginFailure(error));
    }
};
userActions.createUser = (user) => dispatch => {
    dispatch(con.createUserRequest());
    userService.createUser(user)
        .then((user, err) => err ? dispatch(con.createUserFailure(err)) : [dispatch(con.createUserSuccess(user)), dispatch(userActions.getAll()), user && localStorage.setItem('edit_user', user.id)])
        .catch(error => dispatch(con.createUserFailure(error)));
};
userActions.updateUser = (user1) => dispatch => {
    dispatch(con.updateUserRequest());
    userService.updateInfo(user1)
        .then((user, err) => err ? dispatch(con.updateUserFailure(err)) : [dispatch(con.updateUserSuccess(user)), dispatch(userActions.getAll())])
        // .then((user, err) => err ? dispatch(con.updateUserFailure(err)) : [dispatch(con.updateUserSuccess(user)), dispatch(userActions.getDetail(user1.id)), dispatch(userActions.getAll())])
        .catch(error => dispatch(con.updateUserFailure(error)));
};
userActions.updatePassword = (user1) => dispatch => {
    dispatch(con.updateUserRequest());
        userService.updatePassword(user1)
            .then((user, err) => err ? dispatch(con.updateUserFailure(err)) : [dispatch(con.updateUserSuccess(user)), dispatch(userActions.getDetail(user1.id)), dispatch(userActions.getAll())])
            .catch(error => dispatch(con.updateUserFailure(error)));
};
userActions.logout = () => {
    userService.logout();
    return con.logoutRequest();
};
userActions.getAll = () => dispatch => {
        dispatch(con.getAllUsersRequest());
        userService.getAll()
            .then((users, err) => err ? dispatch(con.getAllUsersFailure(err)) : dispatch(con.getAllUsersSuccess(users.users)))
            .catch(error => dispatch(con.getAllUsersFailure(error)));
};
userActions.getPag = (req) => dispatch => {
    dispatch(con.getPagUsersRequest());
    userService.getPag(req)
        .then((pag_users, err) => err ? dispatch(con.getAllUsersFailure(err)) : dispatch(con.getPagUsersSuccess(pag_users)))
        .catch(error => dispatch(con.getPagUsersFailure(error)));
};
userActions.getLoginExceptions = () => dispatch => {
    dispatch(con.getLoginExceptionsRequest());
    userService.getLoginExceptions()
        .then((login_exceptions, err) => err ? dispatch(con.getLoginExceptionsFailure(err)) : dispatch(con.getLoginExceptionsSuccess(login_exceptions)))
        .catch(error => dispatch(con.getLoginExceptionsFailure(error)));
};
userActions.getDetail = (id) => dispatch => {
    dispatch(con.getUserRequest());
    userService.getDetail(id)
        .then((user, err) => err ? dispatch(con.getUserFailure(err)) : dispatch(con.getUserSuccess(user)))
        .catch(error => dispatch(con.getUserFailure(error)));
};
userActions.importUsers = (file) => dispatch => {
    dispatch(con.createUserRequest());
    userService.importUsers(file)
        .then((user, err) => err ? dispatch(con.createUserFailure(err)) : [dispatch(con.createUserSuccess(user)), dispatch(userActions.getAll()), user && localStorage.setItem('edit_user', user.id)])
        .catch(error => dispatch(con.createUserFailure(error)));
};
