import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    InputGroup, InputGroupAddon, InputGroupText
} from '../../../components/bot-components';
const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    workorder: PropTypes.object,
    priorityList: PropTypes.array,
    onSaveTask: PropTypes.func,
    vendorList: PropTypes.array,
    mechanicList: PropTypes.array
};

const CreateTask = (props) => {
    const [state, updateState] = React.useState({
        description: null,
        yard_id: props.workorder.items.yard_id,
        complete: false,
        vendor: false,
        workorder_id: props.workorder.items.id,
        vendor_id: null,
        mechanic_id: null,
        est_complete_date: null,
        task_priority_id: 3
    });
    function setState(newState) {
        updateState(prevState => ({
            ...prevState,
            ...newState
        }));
    }

    const changeDescription = (e) => {
        const {value} = e.target;
        setState({
            description: value ? value : null
        });
    };

    const changeDate = (e) => {
        const {value} = e.target;
        setState({
            est_complete_date: value
        });
    };

    const onSaveTask = () => {
        props.onSaveTask(state);
    };

        return(
            <Modal size={'lg'} toggle={() => props.toggle()} isOpen={props.isOpen}>
                <ModalHeader toggle={() => props.toggle()}>
                    Create new task for Work Order {props.workorder.items.id}
                </ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Description</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'textarea'} value={state.description ?? ''} onChange={(e) => changeDescription(e)} placeholer={'Enter a description for the task...'}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Priority</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} value={state.selectedPriority} placeholer={'Enter a description for the task...'} options={props.priorityList}
                               onChange={(selectedPriority) => setState({selectedPriority: selectedPriority, task_priority_id: selectedPriority ? selectedPriority.value : null})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Service Provider</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} value={state.selectedVendor} placeholer={'Enter a description for the task...'} options={props.vendorList}
                               onChange={(selectedVendor) => setState({selectedVendor: selectedVendor, vendor_id: selectedVendor ? selectedVendor.value : null})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Mechanic</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'react-select'} value={state.selectedMechanic} placeholer={'Enter a description for the task...'} options={props.mechanicList}
                               onChange={(selectedMechanic) => setState({selectedMechanic: selectedMechanic, mechanic_id: selectedMechanic ? selectedMechanic.value : null})}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}>
                            <InputGroupText>Estimated Completion Date</InputGroupText>
                        </InputGroupAddon>
                        <Input type={'date'} onChange={(e) => changeDate(e)} value={state.est_complete_date ?? ''}/>
                    </InputGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color={'texts'} outline onClick={() => onSaveTask()}>Save</Button><Button outline color={'texts'} onClick={() => props.toggle()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
};

CreateTask.propTypes = propTypes;
export default CreateTask;
