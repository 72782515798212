// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const unitService = {};

const apiUrl = baseUrl;

unitService.createUnit = (unit) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(unit)
    };

    return fetch(`${apiUrl}/unit`, requestOptions).then(handleResponse);
};

unitService.updateUnit = (unit) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(unit)
    };


    return fetch(`${apiUrl}/unit/` + unit.id, requestOptions).then(handleResponse);
};

unitService.getAll = (req) => {
    // const requestOptions = {
    //     method: 'PUT',
    //     headers: authHeader('PUT'),
    //     body: JSON.stringify(req)
    // };
    //
    // return fetch(`${apiUrl}/units`, requestOptions).then(handleResponse);
    console.log(req);
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${baseUrl}/units`, requestOptions).then(handleResponse);
};

unitService.getNoLog = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/units/no_log`, requestOptions).then(handleResponse);
};

unitService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit/` + id, requestOptions).then(handleResponse);
};

unitService.getNext = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit/next/` + id, requestOptions).then(handleResponse);
};

unitService.getPrevious = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit/previous/` + id, requestOptions).then(handleResponse);
};

unitService.dotReport = (values) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(values)
    };

    return fetch(`${apiUrl}/units/dot`, requestOptions).then(handleResponse);
};

unitService.attachFiles = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('GET'),
        contentLength: data.size,
        body: data,
    };

    return fetch(`${apiUrl}/attachment/unit`, requestOptions).then(handleResponse);
};
