import {unitPmConstants} from '../constants/';
import {unitPmService} from '../services/';

export const unitPmActions = {};
unitPmActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitPmConstants.GET_ALL_UNIT_PMS_REQUEST});
    const success = (unit_pms) => ({type: unitPmConstants.GET_ALL_UNIT_PMS_SUCCESS, unit_pms});
    const failure = (error) => ({type: unitPmConstants.GET_ALL_UNIT_PMS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitPmService.getAll()
            .then(unit_pms => dispatch(success(unit_pms)), error => dispatch(failure(error)));
    };
};
unitPmActions.getPm = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitPmConstants.DETAIL_UNIT_PM_REQUEST});
    const success = (unit_pm) => ({type: unitPmConstants.DETAIL_UNIT_PM_SUCCESS, unit_pm});
    const failure = (error) => ({type: unitPmConstants.DETAIL_UNIT_PM_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitPmService.getPm(id)
            .then(unit_pm => dispatch(success(unit_pm)), error => dispatch(failure(error)));
    };
};
unitPmActions.createPm = (unit_pm) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitPmConstants.CREATE_UNIT_PM_REQUEST});
    const success = (unit_pm) => ({type: unitPmConstants.CREATE_UNIT_PM_SUCCESS, unit_pm});
    const failure = (error) => ({type: unitPmConstants.CREATE_UNIT_PM_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitPmService.createUnitPm(unit_pm)
            .then(unit_pm => {
                dispatch(success(unit_pm));
                dispatch(unitPmActions.getAll())
            }, error => dispatch(failure(error)));
    };
};
unitPmActions.updatePm = (unit_pm) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: unitPmConstants.UPDATE_UNIT_PM_REQUEST});
    const success = (unit_pm) => ({type: unitPmConstants.UPDATE_UNIT_PM_SUCCESS, unit_pm});
    const failure = (error) => ({type: unitPmConstants.UPDATE_UNIT_PM_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        unitPmService.updateUnitPm(unit_pm)
            .then(unit_pm => {
                dispatch(success(unit_pm));
                dispatch(unitPmActions.getAll())
            }, error => dispatch(failure(error)));
    };
};
