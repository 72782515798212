import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';
const propTypes = {
    tag: tagPropType,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    onClick: PropTypes.func,
    href: PropTypes.any,
};
const defaultProps = {
    tag: 'a',
};
const NavLink = ({ className, cssModule, active, tag: Tag, innerRef, ...attributes }) => {
    const onClick = (e) => {
        if (attributes.disabled) {
            e.preventDefault();
            return;
        }
        if (attributes.href === '#') {
            e.preventDefault();
        }
        if (attributes.onClick) {
            attributes.onClick(e);
        }
    }

    const classes = mapToCssModules(classNames(
        className,
        'bot-nav-link',
        {
            disabled: attributes.disabled,
            active: active
        }), cssModule);
    return (
        <Tag {...attributes} ref={innerRef} onClick={onClick} className={classes} />);
}
NavLink.propTypes = propTypes;
NavLink.defaultProps = defaultProps;
export default NavLink;
