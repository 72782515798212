import React from 'react';
import {BotTable} from '../../components/bot-components';
import _ from 'lodash';
import PropTypes, {oneOf} from 'prop-types';
const propTypes = {
    ref: PropTypes.node,
    data: PropTypes.array,
    getFault: PropTypes.func,
    pageSizes: PropTypes.array,
    getFaults: PropTypes.func,
    title: oneOf([PropTypes.string, PropTypes.bool]),
    showTitle: PropTypes.bool,
    remote: PropTypes.bool,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string
};
const defaultFilters = {
    code: '',
    description: '',
    search: '',
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};
const BotFaults = React.forwardRef(({getFault, loading, pageSizes, getFaults, title, showTitle, remote, csvFile, csvUrl, data, ...props}, ref) => {
    const codeRef = React.useRef();
    const descriptionRef = React.useRef();
    const onRowSelect = (row) => {
        getFault(row.id);
        localStorage.setItem('fault_id', row.id);
        window.location.href = '/#/blueocean_xpi_integrations/plm/faults/detail';
    };
    const columns = props?.columns ?? [
        {
            field: 'code',
            text: 'Code#',
            filter: {
                type: 'text',
                placeholder: 'Code#',
                defaultValue: defaultFilters.code
            },
            width: 180,
            hidden: false,
            refProp: codeRef,
            sortable: true
        },
        {
            field: 'description',
            text: 'Description',
            refProp: descriptionRef,
            filter: {
                type: 'text',
                placeholder: 'Description',
                defaultValue: defaultFilters.description
            },
            width: 600,
            hidden: false,
            sortable: true
        }
    ];
    return (
        <BotTable ref={ref}
                  columns={columns}
                  title={title}
                  data={data}
                  getData={(req) => getFaults(req)}
                  pageSizes={pageSizes}
                  ClearFiltersBtn={true}
                  ClearFiltersIcon={true}
                  ClearSortBtn={true}
                  ClearSortIcon={true}
                  CsvDownloadBtn={true}
                  CsvDownloadIcon={true}
                  pagination={'both'}
                  headers={true}
                  doRowUpdate={(t) => console.log(t)}
                  refreshData={() => getFaults()}
                  btnGroupDirection={'left'}
                  remote={remote}
                  defaultFilters={defaultFilters}
                  onRowDoubleClick={(row) => onRowSelect(row)}
                  defaultState={defaultState}
                  showTitle={showTitle}
                  loading={loading}
                  csvFile={csvFile}
                  csvUrl={csvUrl}/>
    );
});
BotFaults.propTypes = propTypes;
export default BotFaults;
