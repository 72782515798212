import {store} from "../../helpers";
import {userActions} from '../actions';

export function logOut() {
    store.dispatch(userActions.logout());
    alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
    window.location.reload();
}
export function handleResponse(response) {
    return new Promise((resolve, reject) => {
        response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(data => {
            if (!response.ok) {
                const error = (data && data.error) || response.statusText;
                reject(error);
            }
            const Auth = JSON.parse(response.headers.get('Authentication'));
            if (Auth) {
                store.dispatch(userActions.updateAuth(Auth, null));
            }
            resolve(data);
        }).catch(error => reject(error)) : reject({message: response.statusText, status: response.status});
    })
}
