/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import * as funcs from './OpenTasks.helpers';
import BotTasks from '../BotTasks/BotTasks';

const defaultProps = {};
const OpenTasks = (props) => {
    const otRef = React.useRef();
    React.useEffect(() => {
        !props.task_priorities?.items && props.getTaskPriorities();
        !props.mechanics?.items && props.getMechanics();
        !props.vendors?.items && props.getVendors();
    }, []);

    const {tasks, PriorityList, workorder, unit, task} = props;
    OpenTasks.defaultProps = props;

    return (
            <BotTasks ref={otRef} data={tasks ? tasks : null}
                      workorder={workorder}
                      tblId={'otRef'} priorityList={PriorityList}
                      popoutAction viewAction actionCount={2}
                      title={'Open Tasks'}
                      pageSizes={['20', '50', '100', '500', '1000']}
                      getTasks={(req) => props.getTasks(req, 'tasks/open')}
                      unit={unit}
                      remote={true}
                      vendorList={props.vendorList}
                      mechanicList={props.mechanicList}
                      lStorage={'all_open_task_storage'}
                      getMyWorkOrder={(id) => props.getMyWorkorder(id)}
                      csvUrl={'tasks/open'}
                      csvFile={'open_task.csv'}
                      getMyUnit={(id) => props.getMyUnit(id)}
                      updateTask={(t) => props.updateTask(t)}
                      task={task}
                      loading={props.loading}
                      plm_alert={props.plm_alert}
                      // getMyAlert={(id) => props.getAlert(id)}
                      u_preferences={props.user_preference}
                      getMyTask={(id) => props.getTask(id)}/>
    );
};

OpenTasks.defaultProps = defaultProps;
export default connect(funcs.mapStateToProps, funcs.mapDispatchToProps)(OpenTasks);
