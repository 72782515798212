import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './Toast.css';

const propTypes = {
    ref: PropTypes.any,
    position: PropTypes.string,
    autoDelete: PropTypes.bool,
    dismissTime: PropTypes.number
};

const Toast = forwardRef((props, ref) => {
    const { position, autoDelete, dismissTime } = props;
    const [list, setList] = useState([]);
    let toastProperties = null;

    useImperativeHandle(ref, () => ({
        addToast: (type, message) => {
            showToast(type, message);
        }
    }));

    const showToast = (type, message) => {
        const id = Math.floor((Math.random() * 101) + 1);

        switch(type) {
            case 'success':
                toastProperties = {
                    id,
                    title: message,
                    description: 'This is a success toast component',
                    backgroundColor: '#5cb85c',
                    // icon: checkIcon
                }
                break;
            case 'danger':
                toastProperties = {
                    id,
                    title: message,
                    description: 'This is a error toast component',
                    backgroundColor: '#d9534f',
                    // icon: errorIcon
                }
                break;
            case 'info':
                toastProperties = {
                    id,
                    title: message,
                    description: 'This is an info toast component',
                    backgroundColor: '#5bc0de',
                    // icon: infoIcon
                }
                break;
            case 'warning':
                toastProperties = {
                    id,
                    title: message,
                    description: 'This is a warning toast component',
                    backgroundColor: '#f0ad4e',
                    // icon: warningIcon
                }
                break;

            default:
                setList([]);
        }

        setList([...list, toastProperties]);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && list.length) {
                deleteToast(list[0].id);
            }
        }, dismissTime);

        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [autoDelete, dismissTime, list]);

    const deleteToast = id => {
        setList([...list.filter(l => l.id !== id)]);
    }

    return (
        <>
            <div className={`notification-container ${position}`}>
                {
                    list.map((toast, i) =>
                        <div
                            key={i}
                            className={`notification toast ${position}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            {/*<div className="notification-image">*/}
                            {/*    <img src={toast.icon} alt="" />*/}
                            {/*</div>*/}
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
});

Toast.propTypes = propTypes;
export default Toast;