// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const faultService = {};

const apiUrl = baseUrl;

faultService.createFault = (fault) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(fault)
    };

    return fetch(`${apiUrl}/fault`, requestOptions).then(handleResponse);
};

faultService.updateFault = (fault) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(fault)
    };


    return fetch(`${apiUrl}/fault/` + fault.id, requestOptions).then(handleResponse);
};

faultService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/faults`, requestOptions).then(handleResponse);
};

faultService.getUnassigned = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/faults/unassigned`, requestOptions).then(handleResponse);
};

faultService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/fault/` + id, requestOptions).then(handleResponse);
};
