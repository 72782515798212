import React from 'react';
import PropTypes from 'prop-types';
import {BotTable, Nav, NavLink, TabContent, TabPane} from '../../components/bot-components';
import BotUnitModal from '../Units/BotUnit/BotUnitModal';
import HeatMap from './HeatMap';
import _ from 'lodash';
import {_dateOnlyFormat} from '../../helpers';
import classnames from 'classnames';

const propTypes = {
    lStorage: PropTypes.string,
    csvFile: PropTypes.string,
    includeDateRange: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    title: PropTypes.string,
    u_preferences: PropTypes.any,
    getExceptions: PropTypes.func.isRequired,
    getUnit: PropTypes.func.isRequired,
    unit: PropTypes.any,
    data: PropTypes.object,
    useLength: PropTypes.bool
};

const defaultFilters = {
    source: "",
    date: "",
    inspections: "",
    number: "",
    description: "",
    unit_type: "",
    unit_subtype: "",
    dot: "",
    miles: "",
    hours: "",
    gps_operator: "",
    last_known_operator: "",
    yard: "",
    job: "",
    gps_location: ""
}

const validCoords = /-?[0-9]{1,3}[.][0-9]+/;

const myFilters = _.cloneDeep(defaultFilters);

const defaultState = {
    sortName: "",
    sortOrder: "",
    currSize: 20,
    currPage: 1,
    filters: myFilters,
    date_range: '7',
    activeTab: '1',
    mapData: null
};

const BotGpsDataTable = React.forwardRef(
    /**
     *
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getUnit
     * @param props.includeDateRange
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param props.showTitle,
     * @param props.getExceptions
     * @param props.useLength
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const [state, updateState] = React.useState({
        ...defaultState,
        tblId: props.tblId,
        lStorage: props.lStorage,
        remote: props.remote,
        selectedRow: null,
        selectedCell: null,
        pageSizes: props.pageSizes,
        unitModal: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    let {pageSizes, data, title, unit} = props;

    const columnRefs = {
        sourceRef: React.useRef(),
        dateRef: React.useRef(),
        insRef: React.useRef(),
        numberRef: React.useRef(),
        descriptionRef: React.useRef(),
        utypeRef: React.useRef(),
        subtypeRef: React.useRef(),
        dotRef: React.useRef(),
        milesRef: React.useRef(),
        hoursRef: React.useRef(),
        gpsOpRef: React.useRef(),
        lkOpRef: React.useRef(),
        yardRef: React.useRef(),
        jobRef: React.useRef(),
        gpsLocRef: React.useRef()
    };

    const prepareDOT = (cell) => {
        return cell ? (<img className="dot-logo" src="" alt=""/>) : 'Non-DOT';
    };

    const prepareLink = (cell) => {
        return (
            validCoords.test(cell) ? <a href={`https://maps.google.com/?q=${cell}`} target={'_blank'} rel={'noopener noreferrer'}>Map Link</a> : 'Invalid Coordinates'
        )
    };

    const prepareInspections = (cell) => {
        const valid = parseInt(cell) < 1;
        return (
            valid ? {backgroundColor: 'var(--light-red)', color: 'var(--white)'} : {}
        )
    };

    const toggleUnitModal = () => {
        setState({
            unitModal: !state.unitModal
        });
    };

    const prepareUnit = (cell, row) => {
        function modalUnitClick() {
            props.getUnit(row.unit_id);
            toggleUnitModal();
        }
        return (
            <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {modalUnitClick()}}>{cell}</div>
        )
    };

    const makeMapData = (Data) => {
        const D = [];
        return Promise.all(Data.map(g => {
            const lat_long = g.gps_location?.split(',');
            const lat = lat_long && parseFloat(lat_long[0]);
            const long = lat_long && parseFloat(lat_long[1]);
            return lat_long && lat && !isNaN(lat) && D.push({position: {lat: lat, lng: long}, date: _dateOnlyFormat(g.date)});
        })).then(() => D);
    };

    React.useEffect( () => {
        const setupMap = async () => {
            const mData = await makeMapData(data);
            setState({
                mapData: mData?.length > 0 ? mData : null
            });
        };
        data && setupMap().catch();
    }, [data]);

    const toggle = (tab) => {
        setState({
            activeTab: tab
        });
    };

    const filters = defaultFilters;
    let columns = [
        {
            field: 'source',
            text: 'Source',
            filter: {
                type: 'text',
                placeholder: 'Source',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.sourceRef,
            sortable: true
        },
        {
            field: 'date',
            text: 'Date',
            filter: {
                type: 'text',
                placeholder: 'date',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.dateRef,
            sortable: true
        },
        {
            field: 'inspections',
            text: 'Inspections',
            filter: {
                type: 'text',
                placeholder: 'Inspections',
                defaultValue: ""
            },
            width: 180,
            tdStyle: (cell) => prepareInspections(cell),
            hidden: false,
            refProp: columnRefs.insRef,
            sortable: true
        },
        {
            field: 'number',
            text: 'Equipment#',
            filter: {
                type: 'text',
                placeholder: 'Equipment#',
                defaultValue: ""
            },
            format: (cell, row) => prepareUnit(cell, row),
            width: 180,
            hidden: false,
            refProp: columnRefs.numberRef,
            sortable: true
        },
        {
            field: 'description',
            text: 'Equip. Description',
            filter: {
                type: 'text',
                placeholder: 'Source',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.descriptionRef,
            sortable: true
        },
        {
            field: 'unit_type',
            text: 'Equip. Type',
            filter: {
                type: 'text',
                placeholder: 'Equip. Type',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.utypeRef,
            sortable: true
        },
        {
            field: 'unit_subtype',
            text: 'Equip. Class',
            filter: {
                type: 'text',
                placeholder: 'Equip. Class',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.subtypeRef,
            sortable: true
        },
        {
            field: 'dot',
            text: 'DOT',
            filter: {
                type: 'select',
                placeholder: 'DOT',
                options: [{value: "", label: 'Select DOT'}, {value: true, label: 'DOT'}, {value: false, label: 'Not-DOT'}],
                defaultValue: ""
            },
            width: 150,
            hidden: false,
            format: cell => prepareDOT(cell),
            refProp: columnRefs.dotRef,
            sortable: true
        },
        {
            field: 'miles',
            text: 'Miles',
            filter: {
                type: 'text',
                placeholder: 'Miles',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.milesRef,
            sortable: true
        },
        {
            field: 'hours',
            text: 'Hours',
            filter: {
                type: 'text',
                placeholder: 'Hours',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.hoursRef,
            sortable: true
        },
        {
            field: 'gps_operator',
            text: 'GPS Operator',
            filter: {
                type: 'text',
                placeholder: 'GPS Operator',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.gpsOpRef,
            sortable: true
        },
        {
            field: 'last_known_operator',
            text: 'LK Operator',
            filter: {
                type: 'text',
                placeholder: 'LK Operator',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.lkOpRef,
            sortable: true
        },
        {
            field: 'yard',
            text: 'Location',
            filter: {
                type: 'text',
                placeholder: 'Location',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.yardRef,
            sortable: true
        },
        {
            field: 'job',
            text: 'Job',
            filter: {
                type: 'text',
                placeholder: 'Job',
                defaultValue: ""
            },
            width: 180,
            hidden: false,
            refProp: columnRefs.jobRef,
            sortable: true
        },
        {
            field: 'gps_location',
            text: 'GPS Location',
            filter: {
                type: 'text',
                placeholder: 'GPS Location',
                defaultValue: ""
            },
            width: 180,
            format: (cell) => prepareLink(cell),
            hidden: false,
            refProp: columnRefs.gpsLocRef,
            sortable: true
        },
    ];
    return (
        <>
            <BotUnitModal isOpen={state.unitModal} toggle={() => toggleUnitModal()} unit={unit} parentComp={'gps'}/>
            <Nav tabs>
                <NavLink className={classnames({active: state.activeTab === '1'})}
                         onClick={() => {
                             toggle('1');
                         }}>
                    List
                </NavLink>
                <NavLink className={classnames({active: state.activeTab === '2'})}
                         onClick={() => {
                             toggle('2');
                         }}>
                    Map
                </NavLink>
            </Nav>
            <TabContent activetab={state.activeTab}>
                <TabPane tabId={'1'}>
                    <BotTable ref={ref}
                              columns={columns}
                              title={title}
                              data={data} getData={(days) => props.getExceptions(days)}
                              pageSizes={pageSizes}
                              ClearFiltersBtn={true}
                              ClearFiltersIcon={true}
                              ClearSortBtn={true}
                              ClearSortIcon={true}
                              CsvDownloadBtn={true}
                              CsvDownloadIcon={true}
                              pagination={'both'}
                              headers={true}
                              btnGroupDirection={'left'}
                              remote={props.remote}
                              useLength={props.useLength}
                              defaultFilters={filters}
                              lStorage={props.lStorage}
                              defaultState={defaultState}
                              onlyFilters={[]}
                              dateRangeBtns={props.includeDateRange ? ["7", "30", "60"] : []}
                              dateRangeIcons={props.includeDateRange ? [ "7","30", "60"] : []}
                              showTitle={props.showTitle}
                              loading={props.loading}
                              csvFile={props.csvFile}
                              csvUrl={props.csvUrl}/>
                </TabPane>
                <TabPane tabId={'2'}>
                    {state.mapData ? <HeatMap data={state.mapData}/> : <HeatMap data={state.mapData}/>}
                </TabPane>
            </TabContent>
        </>
    );
});

BotGpsDataTable.propTypes = propTypes;
export default BotGpsDataTable;
