import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, tagPropType } from './utils';

const propTypes = {
    tag: tagPropType,
    className: PropTypes.string,
    cssModule: PropTypes.object,
    innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.func,
    ]),
};

const defaultProps = {
    tag: 'div'
};

const CardBody = ({ className, cssModule, innerRef, style, tag: Tag, ...attributes }) => {
    const classes = mapToCssModules(classNames(
        className,
        'bot-card-body'
    ), cssModule);

    return (
        <Tag {...attributes} className={classes} style={style} ref={innerRef} />
    );
};

CardBody.propTypes = propTypes;
CardBody.defaultProps = defaultProps;

export default CardBody;
