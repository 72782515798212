import {BotTable} from '../../../components/bot-components';
import React from 'react';
import {getColumn} from '../../../helpers';
import TableActions from '../../../containers/table_action_component';

export const prepareDescription = (cell, row) => {
    if (row.dot) {
        return (<div id={'bot-table-expanded-row'} className={'bot-table-expanded-row'}><div hidden={true} className={'bot-table-row-field'}/><div style={{width: '100%', textAlign: 'left'}} className={'bot-table-row-field'}><img className="dot-logo" src="" alt=""/> {cell}</div></div>)
    } else {
        return (<div id={'bot-table-expanded-row'} className={'bot-table-expanded-row'}><div hidden={true} className={'bot-table-row-field'}/><div style={{width: '100%', textAlign: 'left'}} className={'bot-table-row-field'}>{cell}</div></div> );
    }
};
export const prepareLink = (cell) => (cell ? <a href={`https://maps.google.com/?q=${cell}`} target={'_blank'} rel={'noopener noreferrer'}>Map Link</a> : '');

export const ExpandedWorkOrder = (props) => {
    let {tasks} = props.data;
    return(
        <BotTable id={'bot-expanded-table'}
                  data={tasks}
                  columns={[
                      getColumn('id', 'ID', 0, null, true, null, false, null),
                      getColumn('description', 'Description', '100%', (cell, row) => prepareDescription(cell, row), false, null, false, null),
                      ]}
                  remote={false}
                  currSize={tasks ? tasks.length : 0}
                  currPage={1}
                  pagination={''}
                  headers={false}
                  expandable={false}
        />
    );
};
export const editButton = (cell, row, props, toggleWorkOrderModal) => {
    return(
        <TableActions row={row} edit={props.editAction} view={props.viewAction}
                      popout={props.popoutAction} alert={props.alertAction}
                      onView={() => {props.getMyWorkOrder(row.id); window.location.href = '/#/workorders/lists/detail';}}
                      onPopout={() => {props.getMyWorkOrder(row.id); toggleWorkOrderModal()}}
        />
    )
};

export const prepareUnit = (cell, row, props, toggleUnitModal) => {
    function modalUnitClick() {
        props.getMyUnit(row.unit_id);
        toggleUnitModal();
    }
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {modalUnitClick()}}>{cell}</div>
    )
};

export const prepareWorkorder = (cell, props, toggleWorkOrderModal) => {
    function handleWorkorderClick() {
        props.getMyWorkOrder(cell);
        localStorage.setItem('workorder_id', cell);
        toggleWorkOrderModal();
    }
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {handleWorkorderClick()}}>{cell}</div>
    )
};

export const prepareDOT = (cell) => {
    return cell ? (<img className="dot-logo" src="" alt=""/>) : 'Non-DOT';
};

export const styleUnitStatus = (cell) => {
    switch (cell) {
        case 'Down':
            return {color: 'black', backgroundColor: 'var(--light-red)', fontWeight: 'bold', textAlign: 'center'};
        case 'Needs Attention':
            return {color: 'black', backgroundColor: 'var(--light-yellow)', fontWeight: 'bold', textAlign: 'center'};
        case 3:
            return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
        default:
            return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
    }
};
