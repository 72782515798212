import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  SaveCancel
} from '../../components/bot-components';
import List from '../InspectionChecks/List';
import AvailableCheck from '../InspectionChecks/AvailableCheck';
import ConfirmationModal from '../InspectionChecks/ConfirmationModal';
import _ from 'lodash';
import InspectionSheet from '../InspectionSheets/InspectionSheet';
import {
  createClassInspectionCheck,
  getUnitSubtypes,
  getUnitTypes,
  mapDispatchToProps,
  mapStateToProps,
  updateUnitSubType
} from './InspectionCheckList.helper';
import ReduxBlockUi from 'react-block-ui';
import Select from 'react-select';
import { authHeader } from '../../helpers';
import { baseUrl } from '../../network/constants';

const InspectionCheckList = ( { unit_type, unit_subtype, createAttribute, updateType, updateAttribute, ...props } ) => {
  const [attributeList, setAttributeList] = useState( [] );
  const [unfilteredAttributes, setUnfilteredAttributes] = useState( null );
  const [pvModal, setPvModal] = useState( false );
  const [availableSearch, setAvailableSearch] = useState( '' );
  const [inspectionCheck, setInspectionCheck] = useState( undefined );
  const [selectedChecks, setSelectedChecks] = useState( [] );
  const [confModal, setModal] = useState( { isOpen:false, type:'add', item:null } );
  const [ckModal, setCkModal] = useState( false );
  const [sortedList, setSortedList] = useState( [] );
  const [unitTypes, setUnitTypes] = useState( [] );
  const [unitSubtypes, setUnitSubtypes] = useState( [] );
  const [unitSubtype, setUnitSubtype] = useState( null );
  const { attributeList:checks } = props;
  const { checks:attributes } = unitSubtype ? unitSubtype : unit_type ?? [];
  const [loading, setLoading] = useState( true );
  const [copyModal, setCopyModal] = useState( false );
  const [cancelCopy, setCancelCopy] = useState( false );
  const [unitType, setUnitType] = useState( null );

  useEffect( () => {
    props.getAttributes();
    getUnitTypes().then( ut => {
      setUnitTypes( ut );
    } );
    getUnitSubtypes( { unit_type_id:null } ).then( u => setUnitSubtypes( u ) );
  }, [] );

  useEffect(() => {
    setUnitType(unit_type);
  }, [unit_type]);

  useEffect( () => {
    unit_subtype && setUnitSubtype( unit_subtype );
  }, [unit_subtype] );

  useEffect( () => {
    setLoading( props.loading );
  }, [props.loading] );

  const _setUnitType = ( ut ) => {
    props.getUnitType( ut );
    setLoading( true );
    getUnitSubtypes( { unit_type_id:ut } ).then( u => {
      setLoading( false );
      setUnitSubtypes( u );
    } );
    setUnitSubtype( null );
  };

  const _setUnitSubtype = ust => {
    setUnitSubtype( ust );
    props.getUnitSubtype( ust );
  };

  const onFilterAvailable = ( event ) => {
    const { value } = event?.target ?? {};

    function setFilteredList() {
      const sc = [...selectedChecks.map( c => c.name )];
      unfilteredAttributes && setAttributeList( unfilteredAttributes.filter( att => !sc.includes( att.name ) )?.filter( attribute => value ? attribute.name?.toLowerCase().includes( value.toLowerCase() ) : attribute ) );
      setAvailableSearch( value );
    }

    if (!unfilteredAttributes) {
      setUnfilteredAttributes( [...checks] );
      setAvailableSearch( value );
      setFilteredList();
    } else {
      setFilteredList();
    }
  };

  useEffect( () => {
    onFilterAvailable( { target:{ value:availableSearch } } );
  }, [selectedChecks] );

  const finalizeRemoveCheck = async ( item ) => {
    let sc = _.cloneDeep( selectedChecks );
    sc.splice( item.sort_order, 1 );
    await sc.forEach( ( c, i ) => sc[i] = ({ ...c, sort_order:i }) );
    setSelectedChecks( sc );
    setModal( { isOpen:false, type:'remove', item:item } );
  };

  const removeCheck = async ( item ) => {
    setModal( { isOpen:true, type:'remove', item:item } );
  };

  const finalizeAddCheck = async ( item ) => {
    let sc = _.cloneDeep( selectedChecks );
    const check = {
      id:item.id,
      name:item.name,
      dot:false,
      photo:item.photo,
      photo_required:item.photo_required ?? false,
      photo_count:item.photo ? (item.photo_count > 0 ? item.photo_count : 1) : 0,
      photo_only:item.photo_only,
      details:item.details,
      details_required:item.details_required ?? false,
      static:item.static,
      sort_order:item.photo_only ? sc.length : item.sort_order
    };
    !item.photo_only ? sc.splice( item.sort_order, 0, check ) : sc.push( check );
    await sc.forEach( ( c, i ) => sc[i] = ({ ...c, sort_order:i }) );
    setSelectedChecks( sc );
    setModal( { isOpen:false, type:'add', item:null } );
  };

  const addCheck = ( item ) => {
    setModal( { isOpen:true, type:'add', item:item } );
  };

  const handleDrop = useCallback( ( item, dragIndex, hoverIndex ) => {
    if (item?.isNew) {
      return addCheck( item );
    } else if (dragIndex !== hoverIndex) {
      item.sort_order = hoverIndex;
      let sc = [...selectedChecks];
      sc = update( sc, {
        $splice:[
          [dragIndex, 1],
          [hoverIndex, 0, sc[dragIndex]]
        ]
      } ).map( ( s, i ) => ({ ...s, sort_order:i }) );
      setSelectedChecks( sc );
    }
  }, [selectedChecks] );

  const toggleCk = () => {
    setCkModal( !ckModal );
    setInspectionCheck( '' );
  };

  const createInspectionCheck = async () => {
    const InspectionCheck = {
      ...inspectionCheck,
      sort_order:selectedChecks.length,
      unit_subtypes:unitSubtype ? [unitSubtype.id] : undefined,
      unit_types:unitSubtype ? undefined : unitType ? [unitType.id] : undefined
    };
    await createClassInspectionCheck( InspectionCheck );
    setSelectedChecks( [] );
    toggleCk();
  };

  const configureState = useCallback( async ( _checks, _unit_subtype, _unit_type, _attributes ) => {
    let attList = [];
    let _selectedChecks = [];

    await _checks?.map?.( async function ( e ) {
      let exists = false;
      _attributes && await _attributes.map( function ( att ) {
        if (att.id === e.id) {
          exists = true;
        }
        return true;
      } );
      if (!exists) {
        return attList.push( e );
      }
      return true;
    } );
    _attributes && await _attributes.map( attribute => {
      attribute.sort_order = Math.floor( attribute.sort_order );
      return _selectedChecks.push( attribute );
    } );
    setAttributeList( attList );
    setSelectedChecks( _selectedChecks.map( c => ({
      ...c,
      sort_order:Math.floor( c.sort_order )
    }) ).sort( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) );
    setSortedList( _.cloneDeep( _attributes?.map( a => ({
      ...a,
      sort_order:Math.floor( a.sort_order )
    }) ).sort( ( a, b ) => (a.sort_order > b.sort_order) ? 1 : -1 ) ) ?? [] );
    const ca = (unitSubtype ? unitSubtype.class_attributes?.map( sc => sc.name ) : unitType && unitType.checks?.map( sc => sc.name )) ?? [];
    const nl = ca && _checks ? [..._checks.filter?.( att => !ca.includes( att.name ) )] : _checks;
    setUnfilteredAttributes( nl );
  }, [unitSubtype, unitType] );

  React.useEffect( () => {
    configureState( checks, unitSubtype, unitType, attributes ).catch();
  }, [checks, unitSubtype, unitType, attributes, configureState] );

  const updateDot = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.dot = !c.dot;
      _list[index] = c;
      return _list;
    } );
  };

  const updateDetails = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.details = !c.details;
      c.details_required = c.details ? c.details_required : false;
      _list[index] = c;
      return _list;
    } );
  };

  const updateDetailsRequired = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.details_required = !c.details_required;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhoto = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      if (c.photo) {
        c.photo = false;
        c.photo_only = false;
        c.photo_count = 1;
        c.photo_required = false;
      } else c.photo = !c.photo;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoOnly = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.photo_only = !c.photo_only;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoRequired = async ( item ) => {
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.photo_required = !c.photo_required;
      _list[index] = c;
      return _list;
    } );
  };

  const updatePhotoCount = async ( e, item ) => {
    let { value, min, max } = e.target;
    value = Math.max( Number( min ), Math.min( Number( max ), Number( value ) ) );
    setSelectedChecks( prevState => {
      const _list = [...prevState];
      const c = prevState.find( s => s.id === item.id );
      const index = c && prevState.indexOf( c );
      c.photo_count = value;
      _list[index] = c;
      return _list;
    } );
  };

  const togglePreview = () => {
    setPvModal( prevState => !prevState );
  };

  const disableButtons = selectedChecks.length === 0
    ||
    _.isEqual( sortedList, selectedChecks );

  const onUpdate = () => {
    updateUnitSubType( { checks: selectedChecks, unit_type: unitType, unit_subtype: unitSubtype }).then(
      /**
       *
       * @param {Object} final
       */
      final => {
      Object.hasOwn(final, 'utype') ? props.getUnitType(final.id) : Object.hasOwn(final, 'subtype') && props.getUnitSubtype(final.id);
    });
  };

  const toggleCopy = () => {
    setCopyModal( prevState => !prevState );
  };

  const toggleCancelCopy = () => {
    setCancelCopy( prevState => !prevState );
  };

  const [copyClass, setCopyClass] = useState( null );
  const [copyType, setCopyType] = useState( null );
  const [destType, setDestType] = useState( null );
  const [destClass, setDestClass] = useState( null );
  const [toOverwrite, setToOverwrite] = useState( null );
  const [toCreate, setToCreate] = useState( null );
  const [confirmSaveModal, setConfirmSaveModal] = useState( false );

  const confirmCancelCopy = () => {
    setCancelCopy( false );
    setCopyModal( false );
    setCopyType( null );
    setCopyClass( null );
    setDestType( null );
    setDestClass( null );
    setToOverwrite( null );
    setToCreate( null );
    setConfirmSaveModal( false );
  };

  const confirmSave = () => {
    destType ? props.getUnitType( destType.id ) : destClass && props.getUnitSubtype( destClass.id );
    confirmCancelCopy();
  };

  const selectCopyType = ( e ) => {
    const unit_type = unitTypes?.find( u => u.id === e.value );
    setCopyType( unit_type );
    setCopyClass( unit_type ? null : copyClass );
    setToOverwrite( null );
    setToCreate( null );
  };

  const selectCopyClass = ( type ) => {
    const unit_subtype = unitSubtypes?.find( u => u.id = type.value );
    setCopyClass( unit_subtype );
    setCopyType( unit_subtype ? null : copyType );
    setToOverwrite( null );
    setToCreate( null );
  };

  const selectDestType = ( e ) => {
    const unit_type = unitTypes?.find( u => u.id === e.value );
    setDestType( unit_type );
    setDestClass( unit_type ? null : copyClass );
    setToCreate( null );
    setToOverwrite( null );
  };

  const selectDestClass = ( e ) => {
    const unit_subtype = unitSubtypes?.find( u => u.id === e.value );
    setDestType( unit_subtype ? null : destType );
    setDestClass( unit_subtype );
    setToCreate( unit_subtype.list_count === false ? e.label : null );
    setToOverwrite( unit_subtype.list_count === true ? e.label : null );
  };

  const confirmCopy = () => {
    if (destType) {
      if (destType?.list_count === true) {
        setToOverwrite( destType.utype );
        setToCreate( null );
      } else {
        setToOverwrite( null );
        setToCreate( destType.utype );
      }
    } else if (destClass) {
      if (destClass?.list_count === true) {
        setToOverwrite( destClass.subtype );
        setToCreate( null );
      } else {
        setToOverwrite( null );
        setToCreate( destClass.subtype );
      }
    }
  };

  const copyUnitTypeOrClass = () => {
    const checkList = {
      copyClass:copyClass?.id,
      copyType:copyType?.id
    };

    if (destClass) checkList.destClass = destClass.id;
    if (destType) checkList.destType = destType.id;

    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( checkList )
    };
    let url = destClass ? new URL( `${ baseUrl }/unit_inspection_checks/copy` ) : destType ? new URL( `${ baseUrl }/unit_type_inspection_checks/copy` ) : null;

    return fetch( url, requestOptions ).then( response => {
      if (response.ok) setConfirmSaveModal( true ); else {
        alert( 'There was an error saving your data. Please try again or contact a system Administrator' );
        confirmSave();
      }
    } );
  };

  return (
    <ReduxBlockUi blocking={ loading } tag={ 'div' }
                  message={ <Label className={ 'blocking-loading-lbl' }>Please Wait</Label> }>
      <Card>
        <Modal size={ 'md' } isOpen={ pvModal } toggle={ () => togglePreview() }>
          <ModalHeader toggle={ () => togglePreview() }/>
          <ModalBody>
            <InspectionSheet unit_attributes={ selectedChecks }/>
          </ModalBody>
        </Modal>
        <Modal isOpen={ ckModal } toggle={ () => {
          toggleCk();
        } }>
          <ModalHeader toggle={ () => {
            toggleCk();
          } }>Create Inspection Check Item</ModalHeader>
          <ModalBody>
            <Label for={ 'inspectionCheck' }>Inspection Item: </Label>
            <Input name={ 'inspectionCheck' } type={ 'text' } value={ inspectionCheck?.name }
                   onChange={ ( e ) => setInspectionCheck( prevState => ({
                     ...prevState,
                     name:e.target.value
                   }) ) }/>
            <Row style={ { margin:'0.5rem' } }>
              <CustomInput id={ 'dot_checkbox' } type={ 'checkbox' } label={ 'DOT' }
                           defaultChecked={ inspectionCheck?.dot } onChange={ () => setInspectionCheck( prevState => ({
                ...prevState,
                dot:!prevState.dot
              }) ) }/>
              <CustomInput id={ 'details_checkbox' } type={ 'checkbox' } label={ 'Details' }
                           defaultChecked={ inspectionCheck?.details }
                           onChange={ () => setInspectionCheck( prevState => ({
                             ...prevState,
                             details:!prevState.details,
                             details_required:false
                           }) ) }/>
              { inspectionCheck?.details &&
                <CustomInput id={ 'details_required_checkbox' } type={ 'checkbox' }
                             label={ 'Details Required' }
                             defaultChecked={ inspectionCheck?.details_required }
                             onChange={ () => setInspectionCheck( prevState => ({
                               ...prevState,
                               details_required:!prevState.details_required
                             }) ) }/> }
              <CustomInput id={ 'photo_checkbox' } type={ 'checkbox' } label={ 'Photo' }
                           defaultChecked={ inspectionCheck?.photo }
                           onChange={ () => setInspectionCheck( prevState => ({
                             ...prevState,
                             photo:!prevState.photo,
                             photo_count:!prevState.photo ? 1 : 0
                           }) ) }/>
              { inspectionCheck?.photo &&
                <CustomInput id={ 'photo_required_checkbox' } type={ 'checkbox' }
                             label={ 'Photo Required' }
                             defaultChecked={ inspectionCheck?.photo_required }
                             onChange={ () => setInspectionCheck( prevState => ({
                               ...prevState,
                               photo_required:!prevState.photo_required
                             }) ) }/> }
              { inspectionCheck?.photo &&
                <CustomInput id={ 'photo_only_checkbox' } type={ 'checkbox' } label={ 'Photo Only' }
                             defaultChecked={ inspectionCheck?.photo_only }
                             onChange={ () => setInspectionCheck( prevState => ({
                               ...prevState,
                               photo:!prevState.photo_only ? true : prevState.photo,
                               photo_only:!prevState.photo_only
                             }) ) }/> }
            </Row>
            { inspectionCheck?.photo &&
              <>
                Photo count<Input type={ 'number' } min={ 1 } max={ 4 }
                                  id={ `${ inspectionCheck.id }_photo_count` }
                                  onChange={ ( e ) => setInspectionCheck( prevState => ({
                                    ...prevState,
                                    photo_count:e.target.value
                                  }) ) }
                                  value={ inspectionCheck.photo_count }/>
              </> }
          </ModalBody>
          <ModalFooter><Button outline color={ 'texts' } onClick={ () => createInspectionCheck() }>Save</Button><Button
            outline color={ 'texts' } className={ 'float-right' } onClick={ () => {
            toggleCk();
          } }>Cancel</Button></ModalFooter>
        </Modal>
        <ConfirmationModal isOpen={ confModal.isOpen } type={ confModal.type } item={ confModal.item }
                           finalize={ confModal.type === 'add' ? finalizeAddCheck : finalizeRemoveCheck }
                           cancel={ () => setModal( { isOpen:false, type:'add', item:null } ) }/>
        <ConfirmationModal isOpen={ confModal.isOpen } type={ confModal.type } item={ confModal.item }
                           finalize={ confModal.type === 'add' ? finalizeAddCheck : finalizeRemoveCheck }
                           cancel={ () => setModal( { isOpen:false, type:'add', item:null } ) }/>
        <Modal isOpen={ copyModal } size={ 'lg' } centered toggle={ () => toggleCancelCopy() }>
          <Modal isOpen={ cancelCopy } centered>
            <ModalBody>
              Are you sure you want to cancel the copy process.
              <hr/>
              <Row>
                <Col sm={ 6 }>
                  <Button color={ 'texts' } block outline
                          onClick={ () => confirmCancelCopy() }>Ok</Button>
                </Col>
                <Col sm={ 6 }>
                  <Button color={ 'texts' } block outline
                          onClick={ () => toggleCancelCopy() }>Cancel</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal centered isOpen={ confirmSaveModal } toggle={ () => confirmSave() }>
            <ModalBody>
              <Row>
                <Col sm={ 12 } style={ { alignItems:'center' } }>
                  <span
                    style={ { textAlign:'center' } }>A new Equipment Type Checklist has been created for <b>{ toCreate ? toCreate : toOverwrite }</b></span>
                </Col>
              </Row>
              <Row style={ { paddingTop:'2rem' } }>
                <Col sm={ 4 }/>
                <Col sm={ 4 } style={ { alignItems:'center' } }>
                  <Button block outline color={ 'texts' } onClick={ () => confirmSave() }>Ok</Button>
                </Col>
                <Col sm={ 4 }/>
              </Row>
            </ModalBody>
          </Modal>
          <ModalHeader toggle={ () => toggleCancelCopy() }>
            Copy Inspection Check List
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={ 6 } style={ { textAlign:'center' } }>
                Select an existing Inspection Type Checklist or an Inspection Class Checklist to Copy
                from
              </Col>
              <Col sm={ 6 } style={ { textAlign:'center' } }>
                Select an Inspection Type to Copy to
              </Col>
            </Row>
            <Row>
              <Col sm={ 6 } style={ { alignItems:'center', marginTop:'0.5rem' } }>
                <Select placeholder={ 'Type Inspection' }
                        value={ copyType && { value:copyType.id, label:copyType.utype } }
                        onChange={ ( e ) => selectCopyType( e ) }
                        options={ unitTypes ? unitTypes.map( u => ({ label:u.utype, value:u.id }) ) : [] }/>
              </Col>
              <Col sm={ 6 } style={ { alignItems:'center', marginTop:'0.5rem' } }>
                <Select placeholder={ 'Type Inspection' }
                        value={ destType && { value:destType.id, label:destType.utype } }
                        onChange={ ( e ) => selectDestType( e ) } options={ unitTypes ? [{
                  label:'Type Inspection',
                  value:null
                }, ...unitTypes.map( u => ({ label:u.utype, value:u.id }) )] : [] }/>
              </Col>
            </Row>
            <Row>
              <Col sm={ 6 } style={ { textAlign:'center', marginTop:'0.5rem' } }>
                Or
              </Col>
              <Col sm={ 6 } style={ { textAlign:'center', marginTop:'0.5rem' } }/>
            </Row>
            <Row>
              <Col sm={ 6 } style={ { alignItems:'center', marginTop:'0.5rem' } }>
                <Select placeholder={ 'Class Inspection' }
                        value={ copyClass && { label:copyClass.subtype, value:copyClass.id } }
                        onChange={ ( e ) => selectCopyClass( e ) }
                        options={ unitSubtypes ? [{
                          label:'Class Inspection',
                          value:null
                        }, ...unitSubtypes.map( u => ({ label:u.subtype, value:u.id }) )] : [] }/>
              </Col>
              <Col sm={ 6 } style={ { alignItems:'center', marginTop:'0.5rem' } }>
                <Select placeholder={ 'Class Inspection' }
                        value={ destClass && { label:destClass.subtype, value:destClass.id } }
                        onChange={ ( e ) => selectDestClass( e ) } options={ unitSubtypes ? [{
                  label:'Class Inspection',
                  value:null
                }, ...unitSubtypes.map( u => ({ label:u.subtype, value:u.id }) )] : [] }/>
              </Col>
            </Row>
            <Row>
              <Col sm={ { size:2, offset:8 } }>
                { (toCreate === null && toOverwrite === null) &&
                  <Button disabled={ (!copyClass && !copyType) || (!destType && !destClass) } block
                          outline color={ 'texts' } onClick={ () => confirmCopy() }>Copy</Button> }
              </Col>
            </Row>
            { (copyClass || copyType) && (destType || destClass) && toOverwrite !== null ?
              <Row style={ { textAlign:'center', marginTop:'1rem' } }>
                <Col sm={ 6 }/>
                <Col sm={ 6 }>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm={ 12 } style={ { textAlign:'center' } }>
                                                <span>This will <span style={ { color:'red' } }>OVERWRITE</span> an existing Inspection { destType ? 'Type' : 'Class' } Checklist for
                                                    ({ toOverwrite }).</span>
                        </Col>
                      </Row>
                      <Row style={ { marginTop:'1rem' } }>
                        <Col sm={ 6 } style={ { alignItems:'center' } }>
                          { (copyClass || copyType) && (destType || destClass) &&
                            <Button block outline color={ 'texts' }
                                    onClick={ () => copyUnitTypeOrClass() }>Continue</Button> }
                        </Col>
                        <Col sm={ 6 } style={ { alignItems:'center' } }>
                          <Button block outline color={ 'texts' }
                                  onClick={ () => toggleCancelCopy() }>Cancel</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> :
              toCreate !== null ?
                <Row style={ { textAlign:'center', marginTop:'1rem' } }>
                  <Col sm={ 6 }/>
                  <Col sm={ 6 }>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm={ 12 } style={ { textAlign:'center' } }>
                                                <span>This will <span style={ { color:'red' } }>CREATE</span> a new Inspection Type Checklist for
                                                    ({ toCreate }).</span>
                          </Col>
                        </Row>
                        <Row style={ { marginTop:'1rem' } }>
                          <Col sm={ 6 } style={ { alignItems:'center' } }>
                            <Button block outline color={ 'texts' }
                                    onClick={ () => copyUnitTypeOrClass() }>Continue</Button>
                          </Col>
                          <Col sm={ 6 } style={ { alignItems:'center' } }>
                            <Button block outline color={ 'texts' }
                                    onClick={ () => toggleCancelCopy() }>Cancel</Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> : null }
          </ModalBody>
        </Modal>
        <CardHeader>
          <Row>
            <Col><span style={ { fontSize:24, fontWeight:'bold' } }>Manage Inspection Checklist</span></Col>
            <Col><InputGroup>
              <InputGroupAddon addonType={ 'prepend' }>
                <InputGroupText>Unit Type</InputGroupText>
              </InputGroupAddon>
              <Input type={ 'react-select' } isClearable
                     value={ unitType ? { label:unitType.utype, value:unitType.id } : null }
                     options={ unitTypes?.map( ut => ({
                       label:`${ ut.utype } - Has List:(${ ut.list_count ? 'YES' : 'NO' })`,
                       value:ut.id
                     }) ) } onChange={ e => _setUnitType( e?.value ) }/>
            </InputGroup></Col>
            <Col><InputGroup>
              <InputGroupAddon addonType={ 'prepend' }>
                <InputGroupText>Unit Class</InputGroupText>
              </InputGroupAddon>
              <Input type={ 'react-select' } isClearable
                     value={ unitSubtype ? { label:unitSubtype.subtype, value:unitSubtype.id } : null }
                     options={ unitSubtypes?.map( ust => ({
                       label:`${ ust.subtype } - Has List: (${ ust.list_count ? 'YES' : 'NO' })`,
                       value:ust.id
                     }) ) } onChange={ e => _setUnitSubtype( e?.value ) }/>
            </InputGroup></Col>
            <Col><Button
              disabled={ !unitSubtype && !unitType } outline color={ 'texts' } className={ 'float-right' }
              onClick={ () => togglePreview() }>Preview</Button>
              <Button onClick={ () => toggleCopy() } className={ 'float-right' } outline color={ 'texts' }>Copy
                Inspection</Button>
              <Button outline color={ 'texts' }
                      className={ 'float-right' } onClick={ () => {
                toggleCk();
              } }><i className={ 'fa fa-plus' }/> New Item</Button></Col></Row>
        </CardHeader>
        <CardBody>
          <DndProvider backend={ HTML5Backend }>
            <Row>
              <Col sm={ 6 }>
                <InputGroup>
                  { availableSearch !== '' &&
                    <InputGroupAddon addonType={ 'prepend' }>
                      <InputGroupText style={ { height:'25px' } }
                                      onClick={ () => onFilterAvailable( { target:{ value:'' } } ) }>X</InputGroupText>
                    </InputGroupAddon> }
                  <Input style={ { height:'25px' } } placeholder={ 'Search...' } value={ availableSearch }
                         onChange={ onFilterAvailable }/>
                </InputGroup>
                <div style={ {
                  maxHeight:'20rem',
                  overflow:'scroll',
                  border:'1px solid grey',
                  marginTop:'0.5rem',
                  minHeight:'5em',
                  width:'100%'
                } }>
                  { attributeList.map( c => (
                    <AvailableCheck key={ c.id } data={ c } updateItem={ updateAttribute }/>) ) }
                </div>
              </Col>
              <Col sm={ 6 }>
                <SaveCancel onSaveClick={ onUpdate } saveDisabled={ disableButtons }
                            onCancelClick={ () => setSelectedChecks( sortedList ) }
                            cancelDisabled={ disableButtons } paddingBottom={ '0.5rem' }/>
                <div style={ {
                  maxHeight:'20rem', overflow:'scroll', border:'1px solid grey'
                } }>
                  <List
                    data={ selectedChecks }
                    unit_type={ unitType }
                    unitSubtype={ unit_subtype }
                    path={ 'selected_checks' }
                    handleDrop={ handleDrop }
                    updateDot={ updateDot }
                    updatePhoto={ updatePhoto }
                    updatePhotoOnly={ updatePhotoOnly }
                    updatePhotoRequired={ updatePhotoRequired }
                    updatePhotoCount={ updatePhotoCount }
                    updateDetails={ updateDetails }
                    updateDetailsRequired={ updateDetailsRequired }
                    removeCheck={ removeCheck }/>
                </div>
              </Col>
            </Row>
          </DndProvider>
        </CardBody>
      </Card>
    </ReduxBlockUi>
  );
};

export default connect( mapStateToProps, mapDispatchToProps )( InspectionCheckList );