import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from './bot-dropdown';
import { omit } from './utils';

const omitKeys = ['defaultOpen'];

const UncontrolledDropdown = (props) => {
  const [state, updateState] = useState({isOpen: props.defaultOpen || false});

  const setState = newState => updateState(prevState => ({
    ...prevState,
    ...newState
  }));

  const toggle = () => {
    setState({ isOpen: !state.isOpen });
  };

  const ch = props.children?.map(c => <div key={Math.random()} onClick={toggle}>{c}</div> );

  return <Dropdown isOpen={state.isOpen} toggle={toggle} {...omit(props, omitKeys)}>{ch}</Dropdown>;
};

UncontrolledDropdown.propTypes = {
  defaultOpen: PropTypes.bool,
  ...Dropdown.propTypes
};
export default UncontrolledDropdown;
