import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    fixed: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'header',
    fixed: false
};

let AppHeader = function (_Component) {
    _inherits(AppHeader, _Component);

    function AppHeader() {
        _classCallCheck(this, AppHeader);

        return _possibleConstructorReturn(this, _Component.apply(this, arguments));
    }

    AppHeader.prototype.componentDidMount = function componentDidMount() {
        this.isFixed(this.props.fixed);
    };

    AppHeader.prototype.isFixed = function isFixed(fixed) {
        if (fixed) {
            document.body.classList.add('header-fixed');
        }
    };

    // breakpoint(breakpoint) {
    //   return breakpoint || '';
    // }

    AppHeader.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        delete attributes.fixed;

        let classes = classNames(className, 'app-header', 'navbar');

        return React.createElement(
            Tag,
            _extends({ className: classes }, attributes),
            children
        );
    };

    return AppHeader;
}(Component);

AppHeader.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppHeader.defaultProps = defaultProps;

export default AppHeader;
