import {attributeConstants} from '../constants';
import {attributeService} from '../services/';
import {unitTypeActions} from './unit_type.actions';

export const attributeActions = {};

attributeActions.getByType = (unit_type) => {
    return dispatch => {
        dispatch(request());

        attributeService.getByType({unit_type_id: unit_type})
            .then(
                unit_attributes => {
                    dispatch(success(unit_attributes))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.GET_ALL_UNIT_ATTRIBUTES_REQUEST}
    }

    function success(unit_attributes) {
        return {type: attributeConstants.GET_ALL_UNIT_ATTRIBUTES_SUCCESS, unit_attributes}
    }

    function failure(error) {
        return {type: attributeConstants.GET_ALL_UNIT_ATTRIBUTES_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.getByClass = (unit_subtype) => {
    return dispatch => {
        dispatch(request());

        attributeService.getByClass({unit_subtype_id: unit_subtype})
            .then(
                class_attributes => {
                    dispatch(success(class_attributes))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.GET_ALL_CLASS_ATTRIBUTES_REQUEST}
    }

    function success(class_attributes) {
        return {type: attributeConstants.GET_ALL_CLASS_ATTRIBUTES_SUCCESS, class_attributes}
    }

    function failure(error) {
        return {type: attributeConstants.GET_ALL_CLASS_ATTRIBUTES_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.getAll = () => {
    return dispatch => {
        dispatch(request());

        attributeService.getAll()
            .then(
                attributes => {
                    dispatch(success(attributes))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.GET_ALL_ATTRIBUTES_REQUEST}
    }

    function success(attributes) {
        return {type: attributeConstants.GET_ALL_ATTRIBUTES_SUCCESS, attributes}
    }

    function failure(error) {
        return {type: attributeConstants.GET_ALL_ATTRIBUTES_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.getAttribute = (id) => {
    return dispatch => {
        dispatch(request());

        attributeService.getAttribute(id)
            .then(
                attribute => {
                    dispatch(success(attribute));
                    if (attribute) {
                        localStorage.setItem('edit_attribute', attribute.id);
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.DETAIL_ATTRIBUTE_REQUEST}
    }

    function success(attribute) {
        return {type: attributeConstants.DETAIL_ATTRIBUTE_SUCCESS, attribute}
    }

    function failure(error) {
        return {type: attributeConstants.DETAIL_ATTRIBUTE_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.createAttribute = (attribute) => {
    return dispatch => {
        dispatch(request());

        attributeService.createAttribute(attribute)
            .then(
                attribute => {
                    dispatch(attributeActions.getAll());
                    dispatch(success(attribute));
                    alert('Inspection item was created successfully.');
                    /** @namespace attribute.UnitTypes */
                    if (attribute.UnitTypes?.[0]) {
                        dispatch(unitTypeActions.getType(attribute.UnitTypes[0].id));
                        dispatch(attributeActions.getByType(attribute.UnitTypes[0].id));
                    }
                },
                error => dispatch(failure(error))
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.CREATE_ATTRIBUTE_REQUEST}
    }

    function success(attribute) {
        return {type: attributeConstants.CREATE_ATTRIBUTE_SUCCESS, attribute}
    }

    function failure(error) {
        return {type: attributeConstants.CREATE_ATTRIBUTE_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.updateAttribute = (attribute) => {
    return dispatch => {
        dispatch(request());

        attributeService.updateAttribute(attribute)
            .then(
                attribute => {
                    dispatch(success(attribute));
                    dispatch(attributeActions.getAttribute(attribute.id));
                    dispatch(attributeActions.getAll());
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attributeConstants.UPDATE_ATTRIBUTE_REQUEST}
    }

    function success(attribute) {
        return {type: attributeConstants.UPDATE_ATTRIBUTE_SUCCESS, attribute}
    }

    function failure(error) {
        return {type: attributeConstants.UPDATE_ATTRIBUTE_FAILURE, error}
    }

    //</editor-fold>
};

attributeActions.load = (attributes) => {
    return dispatch => {
        dispatch(success(attributes));
    };

    function success(attributes) {
        return {type: attributeConstants.GET_ALL_ATTRIBUTES_SUCCESS, attributes};
    }
};
