import React, {Component} from "react";
import PropTypes from 'prop-types';
import Select from "react-select";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Col,
    Row,
    Input
} from "../../components/bot-components";
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    poStatusList: PropTypes.any
};
const defaultState = {
    id: null,
    po_status_id: null,
    number: null,
    number_valid: true
};

class PoStatusEditor extends Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.state = defaultState;
        this.statusInputRef = React.createRef();
        this.numberInputRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            id: this.props.row.id,
            po_status_id: this.props.row.po_status_id,
            number: this.props.row.number,
            number_valid: this.numberValid(this.props.row.po_status_id, this.props.row.number)
        });
    }

    numberValid(po_status_id, number) {
        if (po_status_id !== 2) {
            return true
        } else return !!(po_status_id === 2 && number && number.length > 0);
    }

    async changeStatus(e) {
        const {value} = e;
        this.setState({
            po_status_id: value ? value : null,
            number_valid: await this.numberValid(value, this.state.number)
        });
    }

    async changeNumber(e) {
        const {value} = e.target;
        this.setState({
            number: value,
            number_valid: await this.numberValid(this.state.po_status_id, value)
        });
    }

    updateData() {
        this.props.toggle();
        this.props.onUpdate(this.state);
    }

    cancel() {
        this.setState({
            id: this.props.row.id,
            po_status_id: this.props.row.po_status_id,
            number: this.props.row.number,
            number_valid: this.numberValid(this.props.row.po_status_id, this.props.row.number)
        });
        this.props.toggle();
    }

    render() {
        let {poStatusList, isOpen} = this.props;
        let {po_status_id, number} = this.state;
        return (
            <Modal toggle={() => this.props.toggle()} centered={true} isOpen={isOpen}>
                <ModalHeader>
                    Edit PO Number and Status
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={3}>
                                    <Label>Status</Label>
                                </Col>
                                <Col sm={9}>
                                    <Select options={poStatusList}
                                            ref={this.statusInputRef}
                                            placeholder={'Select the Status.'}
                                            matchProp={'label'}
                                            value={po_status_id ? po_status_id : null}
                                            onChange={(e) => this.changeStatus(e)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <Label>Number</Label>
                                </Col>
                                <Col sm={9}>
                                    <Input ref={this.numberInputRef} invalid={!this.state.number_valid} type={'text'} placeholder={'Enter a number'} onChange={(e) => this.changeNumber(e)} value={number ? number : ''}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={!this.state.number_valid} outline={false} color={'primary'} onClick={ this.updateData }>Save</Button>
                    <Button outline={false} color={'warning'} onClick={() => this.cancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

PoStatusEditor.propTypes = propTypes;
export default PoStatusEditor;
