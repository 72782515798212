import { unitSubTypeConstants } from '../constants/';
import { unitSubTypeService } from '../services/';
import { unitTypeActions } from './unit_type.actions';
// import {attributeActions} from './attribute.actions';

export const unitSubTypeActions = {};
unitSubTypeActions.getAll = () => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_REQUEST });
  const success = ( unit_subtypes ) => ({ type:unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_SUCCESS, unit_subtypes });
  const failure = ( error ) => ({ type:unitSubTypeConstants.GET_ALL_UNIT_SUBTYPES_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitSubTypeService.getAll()
      .then( unit_subtypes => dispatch( success( unit_subtypes ) ), error => dispatch( failure( error ) ) );
  };
};
unitSubTypeActions.getSubType = ( id ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_REQUEST });
  const success = ( unit_subtype ) => ({ type:unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_SUCCESS, unit_subtype });
  const failure = ( error ) => ({ type:unitSubTypeConstants.DETAIL_UNIT_SUBTYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    if (id) {
      dispatch( request() );
      unitSubTypeService.getSubType( id )
        .then( unit_subtype => dispatch( success( unit_subtype ) ), error => dispatch( failure( error ) ) );
    } else dispatch( success( null ) );
  };
};
unitSubTypeActions.createSubType = ( unit_subtype ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitSubTypeConstants.CREATE_UNIT_SUBTYPE_REQUEST });
  const success = ( unit_subtype ) => ({ type:unitSubTypeConstants.CREATE_UNIT_SUBTYPE_SUCCESS, unit_subtype });
  const failure = ( error ) => ({ type:unitSubTypeConstants.CREATE_UNIT_SUBTYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitSubTypeService.createUnitSubType( unit_subtype )
      .then( unit_subtype => {
        dispatch( success( unit_subtype ) );
        dispatch( unitSubTypeActions.getAll() );
        dispatch( unitTypeActions.getType( unit_subtype.unit_type_id ) );
      }, error => dispatch( failure( error ) ) );
  };
};
unitSubTypeActions.updateSubType = ( unit_subtype ) => {
  //<editor-fold desc="Dispatch results to state">
  const request = () => ({ type:unitSubTypeConstants.UPDATE_UNIT_SUBTYPE_REQUEST });
  const success = ( unit_subtype ) => ({ type:unitSubTypeConstants.UPDATE_UNIT_SUBTYPE_SUCCESS, unit_subtype });
  const failure = ( error ) => ({ type:unitSubTypeConstants.UPDATE_UNIT_SUBTYPE_FAILURE, error });
  //</editor-fold>
  return dispatch => {
    dispatch( request() );
    unitSubTypeService.updateSubType( unit_subtype )
      .then( unit_subtype => {
        dispatch( success( unit_subtype ) );
        dispatch( unitSubTypeActions.getAll() );
        // dispatch(attributeActions.getByClass(unit_subtype.id));
        dispatch( unitTypeActions.getType( unit_subtype.unit_type_id ) );
      }, error => dispatch( failure( error ) ) );
  };
};
