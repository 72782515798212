import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    brand: PropTypes.any,
    full: PropTypes.any,
    minimized: PropTypes.any
};

let defaultProps = {
    tag: 'a'
};

let AppNavbarBrand = function (_Component) {
    _inherits(AppNavbarBrand, _Component);

    function AppNavbarBrand() {
        _classCallCheck(this, AppNavbarBrand);

        return _possibleConstructorReturn(this, _Component.apply(this, arguments));
    }

    AppNavbarBrand.prototype.imgSrc = function imgSrc(brand) {
        return brand.src ? brand.src : '';
    };

    AppNavbarBrand.prototype.imgWidth = function imgWidth(brand) {
        return brand.width ? brand.width : 'auto';
    };

    AppNavbarBrand.prototype.imgHeight = function imgHeight(brand) {
        return brand.height ? brand.height : 'auto';
    };

    AppNavbarBrand.prototype.imgAlt = function imgAlt(brand) {
        return brand.alt ? brand.alt : '';
    };

    AppNavbarBrand.prototype.navbarBrandImg = function navbarBrandImg(props, classBrand, key) {
        return React.createElement('img', {
            src: this.imgSrc(props),
            width: this.imgWidth(props),
            height: this.imgHeight(props),
            alt: this.imgAlt(props),
            className: classBrand,
            key: key.toString()
        });
    };

    AppNavbarBrand.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        let classes = classNames(className, 'navbar-brand');

        let img = [];
        if (this.props.brand) {
            let props = this.props.brand;
            let classBrand = 'navbar-brand';
            img.push(this.navbarBrandImg(props, classBrand, img.length + 1));
        }
        if (this.props.full) {
            let _props2 = this.props.full;
            let _classBrand = 'navbar-brand-full';
            img.push(this.navbarBrandImg(_props2, _classBrand, img.length + 1));
        }
        if (this.props.minimized) {
            let _props3 = this.props.minimized;
            let _classBrand2 = 'navbar-brand-minimized';
            img.push(this.navbarBrandImg(_props3, _classBrand2, img.length + 1));
        }

        return React.createElement(
            Tag,
            _extends({}, attributes, {className: classes}),
            children || img
        );
    };

    return AppNavbarBrand;
}(Component);

AppNavbarBrand.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppNavbarBrand.defaultProps = defaultProps;

export default AppNavbarBrand;
