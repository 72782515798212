import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button, Card, CardBody, Col, Row, Label} from '../../components/bot-components';
import {userActions} from "../../network/actions";
import Proptypes from 'prop-types';

const propTypes = {
    cancelUpload: Proptypes.func,
    saveUpload: Proptypes.func,
    viewHistory: Proptypes.func,
    summaryData: Proptypes.object.isRequired,
    setTableData: Proptypes.func
}

class PendingUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.summaryData !== prevProps.summaryData) {
            this.setState(this.state);
        }
    }

    render() {
        const {summaryData} = this.props;
        const {summary} = summaryData;
        return (
            <Card>
                <CardBody>
                    <Col sm={12}>
                        <Row>
                            <Col sm={3}/>
                            <Col sm={3}>
                                <Button className={'bot-3d-user-cancel-button'} onClick={() => this.props.cancelUpload()} block={true}>Cancel Update</Button>
                            </Col>
                            <Col sm={3}>
                                <Button className={'bot-3d-user-complete-button'} onClick={() => this.props.saveUpload()} block={true}>Complete Update</Button>
                            </Col>
                            <Col sm={3}/>
                        </Row>
                        <br/>
                        <br/>
                        <br/>
                        <Row>
                            <Col sm={1}/>
                            <Col sm={5}>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-dark-left-lbl'}>
                                            Pending Vista File Date:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-dark-right-date-lbl'}>
                                            {summary && summary.vista_date ? moment(summary.vista_date).format('MM/DD/YYYY') : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Active Vista Users:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('active_vista', 'vistaColumns')}>
                                            {summary && summary.active_vista_users ? summary.active_vista_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            New Users to Add:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('new_fm', 'vistaColumns')}>
                                            {summary && summary.new_users ? summary.new_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Inactive Users to Re-activate:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('reactivated', 'raColumns')}>
                                            {summary && summary.inactive_users ? summary.inactive_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            First Names to Change:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('f_name_changes', 'fnColumns')}>
                                            {summary && summary.firstname_changes ? summary.firstname_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Last Names to Change:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('l_name_changes', 'lnColumns')}>
                                            {summary && summary.lastname_changes ? summary.lastname_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Job Numbers to Change:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('job_changes', 'jnColumns')}>
                                            {summary && summary.job_changes ? summary.job_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Vista Jobs not in FM:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('job_missing', 'jnColumns')}>
                                            {summary && summary.job_missing ? summary.job_missing : ''}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={5}>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-dark-left-lbl'}>
                                            Last Upload Date:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-dark-right-date-lbl'}>
                                            {summary && summary.upload_date ? moment(summary.upload_date).format('MM/DD/YYYY') : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Active FleetMatrix Users:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('fm_active', 'fmColumns')}>
                                            {summary && summary.active_fm_users ? summary.active_fm_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Phone Number to Add:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('phone_add', 'pnColumns')}>
                                            {summary && summary.phone_blank ? summary.phone_blank : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Company Roles to add:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('role_blank', 'crColumns')}>
                                            {summary && summary.role_blank ? summary.role_blank : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Approved Driver to Change:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('ad_changes', 'adColumns')}>
                                            {summary && summary.approved_driver_changes ? summary.approved_driver_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={1}/>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, user_preference} = state;
    const user = authentication.user;
    return {user, user_preference};
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => dispatch(userActions.getDetail(id)),
        importUsers: (file) => dispatch(userActions.importUsers(file)),
    }
}

PendingUpload.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(PendingUpload);
