// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const taskService = {};

const apiUrl = baseUrl;

taskService.createTask = (task) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(task)
    };

    return fetch(`${apiUrl}/task/`, requestOptions).then(handleResponse);
};

taskService.assignToVendor = (task) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task),
    };

    return fetch(`${apiUrl}/task/vendor/${task.id}`, requestOptions).then(handleResponse);
};

taskService.assignToMechanic = (task) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task),
    };

    return fetch(`${apiUrl}/task/mechanic/${task.id}`, requestOptions).then(handleResponse);
};

taskService.arrivedAtVendor = (task) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task),
    };

    return fetch(`${apiUrl}/task/arrived/${task.id}`, requestOptions).then(handleResponse);
};

taskService.pickup = (task) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task),
    };

    return fetch(`${apiUrl}/task/pickup/${task.id}`, requestOptions).then(handleResponse);
};

taskService.updateTask = (task) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task)
    };

    return fetch(`${apiUrl}/task/` + task.id, requestOptions).then(handleResponse);
};

taskService.getAll = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/tasks`, requestOptions).then(handleResponse);
};

// taskService.getAll = () => {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader('GET')
//     };
//
//     return fetch(`${apiUrl}/tasks`, requestOptions).then(handleResponse);
// };

taskService.getOpen = (req) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${apiUrl}/tasks/open`, requestOptions).then(handleResponse);
    // const requestOptions = {
    //     method: 'GET',
    //     headers: authHeader('GET')
    // };
    //
    // return fetch(`${apiUrl}/tasks/open`, requestOptions).then(handleResponse);
};

taskService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/task/` + id, requestOptions).then(handleResponse);
};
