import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    InputGroup, Input, InputGroupAddon, UncontrolledTooltip
} from '../../components/bot-components';
import PropTypes from 'prop-types';
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    extraProps: PropTypes.any,
    label_field: PropTypes.string
};
const VendorEditor = React.forwardRef((props, ref) => {
    const {row, label_field, toggle, isOpen, extraProps, onUpdate} = props;
    const [state, updateState] = React.useState({
        id: null,
        vendor_id: null,
        selectedVendor: {value: row.vendor_id, label: row[label_field]}
    });

    const setState = (newState) => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    React.useEffect(() => {
        const label = Object.keys(row).includes('vendor_name') ? row.vendor_name : Object.keys(row).includes('vendor') && row.vendor
        setState({
            vendor_id: row.vendor_id,
            selectedVendor: {value: row.vendor_id, label: label}
        });
    }, [row]);
    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        state: state,
        setState: (newState) => setState(newState)
    }));

    const vendInputRef = React.useRef();

    const focusInput = () => vendInputRef.current?.focus();

    const updateData = () => {
        onUpdate({
            id: state.id,
            vendor_id: state.vendor_id
        });
        toggle();
    };
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');

    return (
        <Modal toggle={toggle} centered={true} isOpen={isOpen} onOpened={() => focusInput()}>
            <ModalBody>
                <InputGroup>
                    <Input type={'react-select'}
                           options={extraProps}
                           ref={vendInputRef}
                           placeholder={'Select the Service Provider.'}
                           matchProp={'label'}
                           value={state.selectedVendor}
                           onChange={(selectedVendor) => {setState({selectedVendor: selectedVendor, vendor_id: selectedVendor ? selectedVendor.value : null, vendor: !!selectedVendor, mechanic_id: selectedVendor ? null : state.mechanic_id})}}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
                </InputGroup>
                {/*<Row>
                    <Col sm={12}>
                        <Row>
                            <Col sm={3}>
                                <Label>Service Provider</Label>
                            </Col>
                            <Col sm={9}>
                                <Select options={extraProps}
                                        ref={vendInputRef}
                                        placeholder={'Select the Service Provider.'}
                                        matchProp={'label'}
                                        value={state.selectedVendor}
                                        onChange={(selectedVendor) => {setState({selectedVendor: selectedVendor, vendor_id: selectedVendor ? selectedVendor.value : null, vendor: !!selectedVendor, mechanic_id: selectedVendor ? null : state.mechanic_id})}}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>*/}
            </ModalBody>
            {/*<ModalFooter>
                <Button color={'texts'} outline onClick={updateData}>Save</Button>
                <Button color={'texts'} outline onClick={toggle}>Close</Button>
            </ModalFooter>*/}
        </Modal>
    );
});

VendorEditor.propTypes = propTypes;
export default VendorEditor;
