import {companyConstants} from '../constants';

export const companies = (state = {}, action) => {
    switch (action.type) {
        case companyConstants.GET_ALL_COMPANIES_REQUEST:
            return {
                loading: true
            };
        case companyConstants.GET_ALL_COMPANIES_SUCCESS:
            return {
                items: action.companies
            };
        case companyConstants.GET_ALL_COMPANIES_FAILURE:
            return {
                error: action.error
            };
        case companyConstants.CREATE_COMPANY_REQUEST:
            return {
                loading: true
            };
        case companyConstants.CREATE_COMPANY_SUCCESS:
            return {
                items: action.companies
            };
        case companyConstants.CREATE_COMPANY_FAILURE:
            return {
                error: action.error
            };
        case companyConstants.UPDATE_COMPANY_REQUEST:
            return {
                loading: true
            };
        case companyConstants.UPDATE_COMPANY_SUCCESS:
            return {
                items: action.companies
            };
        case companyConstants.UPDATE_COMPANY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const company = (state = {}, action) => {
    switch (action.type) {
        case companyConstants.DETAIL_COMPANY_REQUEST:
            return {
                loading: true
            };
        case companyConstants.DETAIL_COMPANY_SUCCESS:
            return {
                items: action.company
            };
        case companyConstants.DETAIL_COMPANY_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
