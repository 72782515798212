import {dashboardConstants} from '../constants/';
import {_dateFormat} from '../../helpers';
import moment from 'moment';

export const insDashboard = (state = insDashboard, action) => {
    switch (action.type) {
        case dashboardConstants.INS_DASHBOARD_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.INS_DASHBOARD_SUCCESS:
            return {
                ...action.insDashboard, last_loaded: _dateFormat(moment.now())
            };
        case dashboardConstants.INS_DASHBOARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const woDashboard = (state = woDashboard, action) => {
    switch (action.type) {
        case dashboardConstants.WO_DASHBOARD_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.WO_DASHBOARD_SUCCESS:
            return {
                ...action.woDashboard, last_loaded: _dateFormat(moment.now())
            };
        case dashboardConstants.WO_DASHBOARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const dotDashboard = (state = dotDashboard, action) => {
    switch (action.type) {
        case dashboardConstants.DOT_DASHBOARD_REQUEST:
            return {
                loading: true
            };
        case dashboardConstants.DOT_DASHBOARD_SUCCESS:
            return {
                ...action.dotDashboard, last_loaded: _dateFormat(moment.now())
            };
        case dashboardConstants.DOT_DASHBOARD_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
