/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Card, CardBody} from '../../components/bot-components';
import {Map, Marker, InfoWindow} from 'google-maps-react';
import HeatMap from 'google-maps-react/dist/components/HeatMap'
import PropTypes from 'prop-types';

const propTypes = {
    data: PropTypes.array
};

const defaultProps = {};

const gradient = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)'
];

const BotHeatMap = (props) => {
    const getHeatMap = (data) => {
        return (<HeatMap
            gradient={gradient}
            opacity={0.3}
            positions={[...data.map(p => p.position)]}
            radius={20}
        />)
    };

    const onMarkerClick = (props, marker) => {
        setState({
            showingInfoWindow: false
        });
        setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    const onMapClicked = () => {
        if (state.showingInfoWindow) {
            setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    const [state, updateState] = React.useState({
        radio: 1,
        positionList: props.data ? [...props.data.map(p => p.position)] : [],
        markerList: props.data ? [...props.data.map((p, index) => <Marker id={index}
                                                            name={p.date.toString()}
                                                            onClick={onMarkerClick}
                                                            position={p.position}/>)] : [],
        heatMap: props.data ? getHeatMap([...props.data]) : null,
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        positions: props.data ? [...props.data] : []
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    React.useEffect(() => {
        setState({
            positionList: props.data ? [...props.data.map(p => p.position)] : [],
            markerList: props.data ? [...props.data.map((p, index) => <Marker id={index}
                                                                              name={p.date.toString()}
                                                                              onClick={onMarkerClick}
                                                                              position={p.position}/>)] : [],
            heatMap: props.data ? getHeatMap([...props.data]) : null,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            positions: props.data ? [...props.data] : []
        })
    }, [props.data]);

    BotHeatMap.defaultProps = props;
    const {positionList, markerList, showingInfoWindow, activeMarker, heatMap} = state;

    // noinspection RequiredAttributes
    return (
        <Card style={{width: '100% !important', height: '50rem'}}>
            <CardBody>
                {/*<ButtonGroup style={{marginBottom: '20px'}}>*/}
                {/*    <Button onClick={() => this.radio(1)} active={this.state.radio === 1}>Today</Button>*/}
                {/*    <Button onClick={() => this.radio(2)} active={this.state.radio === 2}>MTD</Button>*/}
                {/*    <Button onClick={() => this.radio(3)} active={this.state.radio === 3}>YTD</Button>*/}
                {/*</ButtonGroup>*/}
                <Map
                    draggable={true}
                    zoomControl={true}
                    disableDefaultUI={false}
                    google={window.google}
                    onClick={onMapClicked}
                    containerStyle={{
                        position: 'absolute',
                        width: '90%',
                        height: '42rem'
                    }}
                    zoom={9}
                    initialCenter={
                        positionList[0] ?? {lat: 30.1750711, lng: -96.9356813}
                    }
                    center={
                        positionList[0] ?? {lat: 30.1750711, lng: -96.9356813}
                    }
                >
                    {heatMap}
                    {markerList}
                    <InfoWindow visible={showingInfoWindow}
                                marker={activeMarker}>
                        <div>
                            <h1>{activeMarker?.name}</h1>
                        </div>
                    </InfoWindow>
                </Map>
            </CardBody>
        </Card>
    );
};

BotHeatMap.defaultProps = defaultProps;
BotHeatMap.propTypes = propTypes;

export default BotHeatMap;
