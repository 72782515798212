/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../components/bot-components';
import {saveUser, mapStateToProps, mapDispatchToProps, setupData, setupExtraFields,
    changeTextField, cancelUserCreate, changeEnumValues} from './UserCreate.helpers';
import {defaultState} from './UserCreate.constants';
import {getUserFields} from '../shared.helpers';

let table_key_iterator = 0;
const UserCreate = (props) => {
    const [state, updateState] = React.useState(defaultState);
    const [enums, setEnums] = React.useState(null);
    const [_strings, setStrings] = React.useState(null);
    const [bools, setBools] = React.useState(null);
    const setState = newState => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    const table_keys = () => `user_create_${table_key_iterator++}`;
    React.useEffect(() => {
        setupData(props, setState, setEnums, setStrings, setBools);
    }, []);

    const {first_name_valid, last_name_valid, user_email_valid, password_exists, password_confirmation_exists, passwords_match, user_number_exists, user_number_unique,
        selectedRoles} = state;
    const {statusList, roleList, jobList, authentication} = props;
    const canSave = first_name_valid && last_name_valid && user_email_valid && password_exists && password_confirmation_exists && passwords_match && user_number_exists && user_number_unique && selectedRoles?.length > 0;

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size={'xl'}>
            <ModalHeader toggle={props.toggle}><h3 style={{textAlign: 'center'}}>New User</h3></ModalHeader>
            <ModalBody>
                <Form>
                    {getUserFields(statusList, roleList, props.yardList, jobList, state, setState, table_keys)}
                    {setupExtraFields(authentication, bools, _strings, enums, state, setState, changeEnumValues, changeTextField, table_keys)}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button disabled={!canSave} color={'texts'} outline onClick={() => saveUser(state, props, enums, _strings, bools)}>Save</Button>
                <Button color={'texts'} outline onClick={() => {
                    cancelUserCreate(props);
                    setState(defaultState);
                }}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
