import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardFooter, Col, Label, Row, UncontrolledTooltip
} from '../../../components/bot-components';
import {_dateFormat} from '../../../helpers';
import PropTypes from 'prop-types';

const propTypes = {
    myWo: PropTypes.object,
    openPm: PropTypes.any
};

const WorkOrderDetail = (props) => {

    /**
     * @param myWo.vendor.contact_name
     * @param myWo.gps_location
     * @param myWo.arrived_at_vendor
     * @param myWo.picked_up_at
     */
    const {myWo, openPm, modules} = props;
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col sm={'4'}>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Work Order#:</label>
                            <label className={'workorder_detail-field'}>{myWo && myWo.id}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Date Created:</label>
                            <label
                                className={'workorder_detail-field'}>{myWo && _dateFormat(myWo['created_at'])}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>WO Status:</label>
                            <label className={'workorder_detail-field'}>{myWo && myWo.workorder_status}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Priority:</label>
                            <label className={'workorder_detail-field'}>{myWo && myWo.workorder_priority}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Last Valid Inspection: </label>
                            {myWo?.last_inspection_location ?
                                <label className={'workorder_detail-field'}>
                                    <a className="gps_location"
                                       href={`https://maps.google.com/?q=${myWo['last_inspection_location']}`}
                                       target={'_blank'} rel={'noopener noreferrer'}><i className={'map-pin-icon'}/> {myWo['last_inspection_location'] && myWo['last_inspection_date']}</a>
                                </label> : <label className={'workorder_detail-field'}>None</label>
                            }
                        </Row>
                        {myWo && myWo.vendor &&
                        <div>
                            <Row className={'workorder_detail-row'}>
                                <label className={'workorder_detail-label'}>Service Provider:</label>
                                <label className={'workorder_detail-field'}>{myWo.vendor.name}</label>
                            </Row>
                            <Row className={'workorder_detail-row'}>
                                <label className={'workorder_detail-label'}>Contact:</label>
                                <label className={'workorder_detail-field'}>{myWo.vendor.contact_name}</label>
                            </Row>
                            <Row className={'workorder_detail-row'}>
                                <label className={'workorder_detail-label'}>Phone#:</label>
                                <label className={'workorder_detail-field'}>{myWo.vendor.phone_number}</label>
                            </Row>
                        </div>
                        }
                        {myWo && myWo.mechanic &&
                        <div>
                            <Row className={'workorder_detail-row'}>
                                <label className={'workorder_detail-label'}>Mechanic:</label>
                                <label className={'workorder_detail-field'}>{myWo.mechanic.name}</label>
                            </Row>
                        </div>}
                    </Col>
                    <Col sm={'4'}>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Equipment#:</label>
                            {myWo &&
                            <Link to={'/equipment/detail'}
                                  className={'workorder_detail-field'}>{myWo.unit_number}
                                <i style={{paddingLeft: 4}} className={'fa fa-question-circle'}
                                   id={'unit-tooltip'}/>
                                <UncontrolledTooltip placement={'right'} target={'unit-tooltip'}>Click
                                    here to go to this unit!</UncontrolledTooltip>
                            </Link>
                            }
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Description:</label>
                            <label
                                className={'workorder_detail-field'}>{myWo && myWo.unit?.description}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Brief Desc.:</label>
                            {myWo && <label className={'workorder_detail-field'}>{myWo.other_description}</label>}
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Location:</label>
                            {myWo && <label className={'workorder_detail-field'}>{myWo.yard && myWo.yard.name}</label>}
                        </Row>
                        {modules?.find(m => m.name === 'gps') &&
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Last Valid GPS: </label>
                            {myWo?.last_known_gps ?
                                <label className={'workorder_detail-field'}>
                                    <a className="gps_location"
                                       href={`https://maps.google.com/?q=${myWo['last_known_gps']}`}
                                       target={'_blank'} rel={'noopener noreferrer'}><i
                                        className={'map-pin-icon'}/> {myWo['last_known_gps_date'] && myWo['last_known_gps_date']}
                                    </a>
                                </label> : <label className={'unit_detail-field'}>None</label>
                            }
                        </Row>}
                    </Col>
                    {myWo && <Col sm={'4'}>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Equipment Status: </label>
                            {myWo && myWo.unit && <label className={'unit_status-' + myWo.unit?.unit_status?.id}>{myWo.unit?.unit_status?.status}</label>}
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <label className={'workorder_detail-label'}>Est. Completion Date: </label>
                            <label className={'workorder_detail-field'}>{_dateFormat(myWo.est_complete_date)}</label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <Label className={'workorder_detail-label'}>Arrived At Vendor:</Label>
                            <Label
                                className={'workorder_detail-field'}>{_dateFormat(myWo.arrived_at_vendor)}</Label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <Label className={'workorder_detail-label'}>Picked Up On:</Label>
                            <Label
                                className={'workorder_detail-field'}>{_dateFormat(myWo.picked_up_at)}</Label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <Label className={'workorder_detail-label'}>Completed On:</Label>
                            <Label
                                className={'workorder_detail-field'}>{_dateFormat(myWo['completed_at'])}</Label>
                        </Row>
                        <Row className={'workorder_detail-row'}>
                            <Label className={'workorder_detail-label'}>Status Notes:</Label>
                            <label className={'workorder_detail-field'}>{myWo.general_status}</label>
                        </Row>
                    </Col>}
                </Row>
                <hr/>
                <Label sm={12} style={{fontWeight: 'bold', fontSize: '1rem', marginLeft: '-1.5rem'}}>
                    Work Order Details from Inspections
                </Label>
                <Row>
                    {myWo?.comments?.map(c => (
                        <Col sm={6} style={{paddingTop: '0.25rem', fontWeight: 'bold', fontSize: '0.9rem'}}>
                            {c.comment}
                        </Col>
                    ))}
                </Row>
            </CardBody>
            <CardFooter>
                <label style={{fontWeight: 'bold', fontSize: 18}}>Open PM: </label>{openPm &&
            <Link to={'/pms/edit'}
                  style={{fontSize: 18, paddingLeft: 10, color: 'red'}}>{openPm.id}</Link>}
            </CardFooter>
        </Card>
    );
};

WorkOrderDetail.propTypes = propTypes;
export default WorkOrderDetail;

