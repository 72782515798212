import {roleConstants} from '../constants';

export const roles = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_ALL_ROLES_REQUEST:
            return {
                loading: true
            };
        case roleConstants.GET_ALL_ROLES_SUCCESS:
            return {
                items: action.roles
            };
        case roleConstants.GET_ALL_ROLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const mechanics = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_MECHANICS_REQUEST:
            return {
                loading: true
            };
        case roleConstants.GET_MECHANICS_SUCCESS:
            return {
                items: action.mechanics
            };
        case roleConstants.GET_MECHANICS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};

export const managers = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_MANAGERS_REQUEST:
            return {
                loading: true
            };
        case roleConstants.GET_MANAGERS_SUCCESS:
            return {
                items: action.managers
            };
        case roleConstants.GET_MANAGERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};

export const operators = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_OPERATORS_REQUEST:
            return {
                loading: true
            };
        case roleConstants.GET_OPERATORS_SUCCESS:
            return {
                items: action.operators
            };
        case roleConstants.GET_OPERATORS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
