import React from 'react';
import {Card, CardBody, Nav, NavLink, TabContent, TabPane} from '../../components/bot-components';
import classnames from "classnames";
import UserFields from './UserFields';
import UserEnums from './UserEnums';
const BotSettings = () => {
    const [activeTab, toggleTab] = React.useState('1');
    React.useEffect(() => {
        localStorage.setItem('lastComp', '/configurations');
    }, []);
    return(
        <Card>
            <CardBody>
                <Nav tabs>
                    <NavLink className={classnames({active: activeTab === '1'})}
                             onClick={() => {
                                 toggleTab('1');
                             }}>
                        Custom User Fields
                    </NavLink>
                    <NavLink className={classnames({active: activeTab === '2'})}
                             onClick={() => {
                                 toggleTab('2');
                             }}>
                        Custom User Lists
                    </NavLink>
                </Nav>
                <TabContent activetab={activeTab}>
                    {activeTab === '1' &&
                    <TabPane tabId={'1'}>
                        <UserFields/>
                    </TabPane>}
                    {activeTab === '2' &&
                    <TabPane tabId={'2'}>
                        <UserEnums/>
                    </TabPane>}
                </TabContent>
            </CardBody>
        </Card>
    );
};
export default BotSettings;
