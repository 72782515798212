import {workorderConstants} from '../constants';

export const workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.GET_ALL_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.GET_ALL_WORKORDERS_SUCCESS:
            return {
                items: action.workorders
            };
        case workorderConstants.GET_ALL_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const vendor_all_workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.VENDOR_ALL_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.VENDOR_ALL_WORKORDERS_SUCCESS:
            return {
                items: action.vendor_all_workorders
            };
        case workorderConstants.VENDOR_ALL_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const open_workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.OPEN_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.OPEN_WORKORDERS_SUCCESS:
            return {
                items: action.open_workorders
            };
        case workorderConstants.OLD_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const log_workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.LOG_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.LOG_WORKORDERS_SUCCESS:
            return {
                items: action.log_workorders
            };
        case workorderConstants.LOG_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const open_count = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.GET_OPEN_COUNT_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.GET_OPEN_COUNT_SUCCESS:
            return {
                items: action.open_count
            };
        case workorderConstants.GET_OPEN_COUNT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const new_workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.NEW_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.NEW_WORKORDERS_SUCCESS:
            return {
                items: action.new_workorders
            };
        case workorderConstants.NEW_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const old_workorders = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.OLD_WORKORDERS_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.OLD_WORKORDERS_SUCCESS:
            return {
                items: action.old_workorders
            };
        case workorderConstants.OLD_WORKORDERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const report = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.GET_REPORT_DATA_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.GET_REPORT_DATA_SUCCESS:
            return {
                vendor_workorders: action.report['vendor'],
                mechanic_workorders: action.report['ours'],
                auction_units: action.report['auction'],
            };
        case workorderConstants.GET_REPORT_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const light_report = (state = {}, action) => {
    switch (action.type) {
        case workorderConstants.GET_LIGHT_REPORT_DATA_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.GET_LIGHT_REPORT_DATA_SUCCESS:
            return {
                vendor_workorders: action.light_report['vendor'],
                mechanic_workorders: action.light_report['ours'],
                auction_units: action.light_report['auction'],
            };
        case workorderConstants.GET_LIGHT_REPORT_DATA_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const workorder = (state = {}, action) => {
    let Workorder;
    switch (action.type) {
        case workorderConstants.DETAIL_WORKORDER_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.DETAIL_WORKORDER_SUCCESS:
            Workorder = {};
            if (action.workorder) {
                Workorder = {
                    items: action.workorder,
                    // tasks: action.workorder.tasks,
                    // pos: action.workorder['purchase_orders']
                }
            } else {
                Workorder = {
                    items: {},
                    // tasks: {},
                    // pos: {}
                }
            }
            return Workorder;
        case workorderConstants.DETAIL_WORKORDER_FAILURE:
            return {
                error: action.error
            };
        case workorderConstants.CREATE_WORKORDER_REQUEST:
            return {
                loading: true
            };
        case workorderConstants.CREATE_WORKORDER_SUCCESS:
            return {
                items: action.workorder
            };
        case workorderConstants.CREATE_WORKORDER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
