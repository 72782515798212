import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const messageService = {};

const apiUrl = baseUrl;

messageService.createMessage = (message) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(message)
    };

    return fetch(`${apiUrl}/message`, requestOptions).then(handleResponse);
};
