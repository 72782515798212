import {dataPlateConstants} from "../constants/";
import {dataPlateService} from "../services/";
import {unitActions} from "./";

export const dataPlateActions = {};
dataPlateActions.getDataPlates = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: dataPlateConstants.GET_ALL_DATA_PLATES_REQUEST});
    const success = (data_plates) => ({type: dataPlateConstants.GET_ALL_DATA_PLATES_SUCCESS, data_plates});
    const failure = (error) => ({type: dataPlateConstants.GET_ALL_DATA_PLATES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dataPlateService.getAll()
            .then(data_plates => dispatch(success(data_plates)), error => dispatch(failure(error)));
    };
};
dataPlateActions.getDataPlate = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: dataPlateConstants.DETAIL_DATA_PLATE_REQUEST});
    const success = (data_plate) => ({type: dataPlateConstants.DETAIL_DATA_PLATE_SUCCESS, data_plate});
    const failure = (error) => ({type: dataPlateConstants.DETAIL_DATA_PLATE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request({id}));
        dataPlateService.getDetail(id)
            .then(data_plate => dispatch(success(data_plate)), error => dispatch(failure(error)));
    };
};
dataPlateActions.createDataPlate = (data_plate) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: dataPlateConstants.CREATE_DATA_PLATE_REQUEST});
    const success = (data_plate) => ({type: dataPlateConstants.CREATE_DATA_PLATE_SUCCESS, data_plate});
    const failure = (error) => ({type: dataPlateConstants.CREATE_DATA_PLATE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request({data_plate}));
        dataPlateService.createDataPlate(data_plate)
            .then(data_plate => {
                dispatch(success(data_plate));
                dispatch(unitActions.getUnit(data_plate.unit_id))
            }, error => dispatch(failure(error)));
    };
};
dataPlateActions.updateDataPlate = (data_plate) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: dataPlateConstants.UPDATE_DATA_PLATE_REQUEST});
    const success = (data_plate) => ({type: dataPlateConstants.UPDATE_DATA_PLATE_SUCCESS, data_plate});
    const failure = (error) => ({type: dataPlateConstants.UPDATE_DATA_PLATE_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request({data_plate}));
        dataPlateService.updateDataPlate(data_plate)
            .then(data_plate => dispatch(success(data_plate)), error => dispatch(failure(error)));
    };
};
