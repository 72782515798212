export const jobStatusConstants = {
    CREATE_JOB_STATUS_REQUEST: 'JOB_STATUS_CREATE_REQUEST',
    CREATE_JOB_STATUS_SUCCESS: 'JOB_STATUS_CREATE_SUCCESS',
    CREATE_JOB_STATUS_FAILURE: 'JOB_STATUS_CREATE_FAILURE',

    UPDATE_JOB_STATUS_REQUEST: 'JOB_STATUS_UPDATE_REQUEST',
    UPDATE_JOB_STATUS_SUCCESS: 'JOB_STATUS_UPDATE_SUCCESS',
    UPDATE_JOB_STATUS_FAILURE: 'JOB_STATUS_UPDATE_FAILURE',

    GET_ALL_JOB_STATUSES_REQUEST: 'JOB_STATUSES_GET_ALL_REQUEST',
    GET_ALL_JOB_STATUSES_SUCCESS: 'JOB_STATUSES_GET_ALL_SUCCESS',
    GET_ALL_JOB_STATUSES_FAILURE: 'JOB_STATUSES_GET_ALL_FAILURE',

    DETAIL_JOB_STATUS_REQUEST: 'JOB_STATUS_DETAIL_REQUEST',
    DETAIL_JOB_STATUS_SUCCESS: 'JOB_STATUS_DETAIL_SUCCESS',
    DETAIL_JOB_STATUS_FAILURE: 'JOB_STATUS_DETAIL_FAILURE',
};
