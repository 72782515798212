import {messageConstants} from '../constants';

export const message = (state = {}, action) => {
    switch (action.type) {
        case messageConstants.CREATE_MESSAGE_REQUEST:
            return {
                loading: true
            };
        case messageConstants.CREATE_MESSAGE_SUCCESS:
            return {
                items: action.message
            };
        case messageConstants.CREATE_MESSAGE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
