const navigation = {
  items:[
    {
      name:'Dashboard',
      url:'/dashboard',
      icon:'home-icon',
      roles:['Administrator', 'Manager', 'Staff']
    },
    {
      name:'Inspections',
      url:'/inspections',
      icon:'inspection-icon',
      roles:['Administrator', 'Manager', 'Staff'],
      modules:['inspections'],
    },
    {
      name:'Work Order Menu',
      icon:'workorder-icon',
      url:'/workorders',
      roles:['Administrator', 'Manager', 'Staff'],
      modules:['workorders'],
      children:[
        {
          name:'Work Order Lists',
          url:'/workorders/lists',
          icon:'workorder-list-icon',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['workorders'],
        },
        {
          name:'Tasks',
          url:'/workorders/tasks',
          icon:'tasks-icon',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['workorders'],
        },
        {
          name:'Maintenance Status',
          url:'/workorders/status',
          icon:'maintenance-status-icon',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['workorders'],
        },
      ]
    },
    {
      name:'Setup',
      icon:'configuration-icon',
      url:'/setup',
      roles:['Administrator', 'Manager', 'Staff', 'Payroll'],
      children:[

        {
          name:'Users',
          url:'/setup/users',
          icon:'users-icon',
          roles:['Administrator', 'Manager', 'Staff', 'Payroll']
        },
        {
          name:'Providers',
          url:'/setup/service_providers',
          icon:'vendors-icon',
          roles:['Administrator', 'Manager', 'Staff']
        },
        {
          name:'Locations',
          url:'/setup/job_sites',
          icon:'yards-icon',
          roles:['Administrator', 'Manager', 'Staff']
        }
      ]

    },
    {
      name:'Equipment Menu',
      url:'equipment',
      icon:'equipment-menu-icon',
      roles:['Administrator', 'Manager', 'Staff'],
      children:[
        {
          name:'Master',
          url:'/equipment',
          icon:'units-icon',
          roles:['Administrator', 'Manager', 'Staff']
        },
        {
          name:'Equip. Types and Classes',
          url:'/equipment/equipment_types',
          icon:'equipment-type-icon',
          roles:['Administrator', 'Manager', 'Staff']
        },
        {
          name:'Tire Sizes',
          url:'/equipment/tire_sizes',
          icon:'tire-size-icon',
          roles:['Administrator', 'Manager', 'Staff']
        },
        {
          name:'Asset Types',
          url:'/equipment/asset_types',
          icon:'asset-type-icon',
          roles:['Administrator', 'Manager', 'Staff']
        },
        {
          name:'Inspections',
          url:'/equipment/inspections',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['inspections']
        },
        {
          name:`Preventative Maintenance`,
          url:'/equipment/preventative_maintenance',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['preventative_maintenance']
        }
      ]
    },
    {
      name:'BlueOceanXPI',
      icon:'blueocean-xpi-icon',
      url:'/blueocean_xpi_integrations',
      roles:['Administrator', 'Manager', 'Staff', 'Payroll'],
      modules:['blueocean_xpi_integrations'],
      children:[
        {
          name:'User Integration',
          url:'/blueocean_xpi_integrations/user_integration',
          icon:'user-integration-icon',
          roles:['Payroll'],
          modules:['vista-users']
        },
        {
          name:'Alerts',
          url:'/blueocean_xpi_integrations/plm/plm_alerts',
          icon:'plm-icon',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['plm']
        },
        {
          name:'Fault Codes',
          icon:'fault-code-icon',
          url:'/blueocean_xpi_integrations/plm/faults',
          roles:['Administrator', 'Manager', 'Staff'],
          modules:['plm']
        },
        {
          name:'XPI History',
          icon:'fault-code-icon',
          url:'/blueocean_xpi_integrations/history',
          roles:['Administrator']
        },
      ]
    },
    {
      name:'Reports',
      url:'/reports',
      icon:'reports-icon',
      roles:['Administrator', 'Manager', 'Staff', 'Payroll'],
      modules:['workorders', 'inspections']
    },
    {
      name:'Configuration',
      url:'/configurations',
      icon:'bot-configuration-icon',
      roles:['SuperAdmin']
    }
  ],
};

export default navigation;
