import React, { forwardRef, useImperativeHandle } from 'react';
import { Input } from '../components/bot-components';

const SelectButton = forwardRef(
  /**
   *
   * @param props
   * @param props.row
   * @param props.onSelect
   * @param ref
   * @returns {JSX.Element}
   */
  ( props, ref ) => {

    useImperativeHandle( ref, () => ({
      row:props.row
    }) );

    const onClick = ( e ) => {
      console.log( e );
      props.onSelect?.( props.row, e );
    };

    return (
      <Input ref={ ref } type="checkbox" readOnly className={ 'table-select-btn' } checked={ props.row.selected }
             name="check" onClick={ onClick }/>
    );
  } );

const TableActions = forwardRef(
  /**
   *
   * @param props
   * @param props.onEdit
   * @param props.edit
   * @param props.onView
   * @param props.view
   * @param props.onPopout
   * @param props.popout
   * @param props.onAlert
   * @param props.alert
   * @param props.select
   * @param props.delete
   * @param props.onDelete
   * @param ref
   * @returns {JSX.Element}
   */
  ( props, ref ) => {

    const editButton = () => (<i className={ 'fa fa-pencil table-edit-btn' } onClick={ () => {
      props.onEdit?.();
    } }/>);

    const viewButton = () => (<i className={ 'fa fa-search-plus table-view-btn' } onClick={ () => {
      props.onView?.();
    } }/>);

    const popoutButton = () => (<i className={ 'fa fa-expand table-popout-btn' } onClick={ () => {
      props.onPopout?.();
    } }/>);

    const alertButton = () => (<i className={ 'fa fa-bullhorn table-alert-btn' } onClick={ () => {
      props.onAlert?.();
    } }/>);

    const deleteButton = () => (<i className={ 'fa fa-trash table-alert-btn' } onClick={ () => {
      props.onDelete?.();
    } }/>);

    return (
      <>
        { props.select && (<SelectButton { ...props } ref={ ref }/>) }
        { props.edit && editButton() }
        { props.view && viewButton() }
        { props.popout && popoutButton() }
        { props.alert && alertButton() }
        { props.delete && deleteButton() }
      </>
    );
  } );

export default TableActions;
