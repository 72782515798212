import {taskConstants} from '../constants/';

export const open_tasks = (state = {}, action) => {
    switch (action.type) {
        case taskConstants.GET_OPEN_TASKS_REQUEST:
            return  {
                loading: true
            };
        case taskConstants.GET_OPEN_TASKS_SUCCESS:
            return {
                items: action.open_tasks
            };
        case taskConstants.GET_OPEN_TASKS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};

export const tasks = (state = {}, action) => {
    switch (action.type) {
        case taskConstants.GET_ALL_TASKS_REQUEST:
            return {
                loading: true
            };
        case taskConstants.GET_ALL_TASKS_SUCCESS:
            return {
                items: action.tasks
            };
        case taskConstants.GET_ALL_TASKS_FAILURE:
            return {
                error: action.error
            };
        case taskConstants.CREATE_TASK_REQUEST:
            return {
                loading: true
            };
        case taskConstants.CREATE_TASK_SUCCESS:
            return {
                items: action.tasks
            };
        case taskConstants.CREATE_TASK_FAILURE:
            return {
                error: action.error
            };
        case taskConstants.UPDATE_TASK_REQUEST:
            return {
                loading: true
            };
        case taskConstants.UPDATE_TASK_SUCCESS:
            return {
                items: action.tasks
            };
        case taskConstants.UPDATE_TASK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const task = (state = {}, action) => {
    switch (action.type) {
        case taskConstants.DETAIL_TASK_REQUEST:
            return {
                loading: true
            };
        case taskConstants.DETAIL_TASK_SUCCESS:
            return {
                items: action.task
            };
        case taskConstants.DETAIL_TASK_FAILURE:
            return {
                error: action.error
            };
        case taskConstants.UPDATE_TASK_REQUEST:
            return {
                loading: true
            };
        default:
            return state;
    }
};
