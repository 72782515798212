import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from './bot-tooltip';
import { omit } from './utils';

const omitKeys = ['defaultOpen'];

const UncontrolledTooltip = (props) => {

    const [isOpen, setOpen] = useState(props.defaultOpen || false);

    const toggle = () => {
        setOpen(!isOpen);
    }

    return <Tooltip isOpen={isOpen} toggle={toggle} {...omit(props, omitKeys)} />;
}

UncontrolledTooltip.propTypes = {
    defaultOpen: PropTypes.bool,
    ...Tooltip.propTypes
};

export default UncontrolledTooltip;
