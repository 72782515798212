import React from 'react';
import {FormGroup, Col, Card, CardHeader, CardBody, Row, Label, CustomInput} from '../../components/bot-components';

const InspectionSheet = (props) => {
    let sortedList = props.unit_attributes.map(attribute => {
        attribute.sort_order = Math.floor(attribute.sort_order);
        return attribute;
    });

    const createForm = () => {
        let col1 = [];
        let col2 = [];
        function compare(a, b) {
            const numA = +a.sort_order;
            const numB = +b.sort_order;

            return numA < numB ? -1 : numA > numB ? 1 : 0;
        }
        sortedList = sortedList.sort(compare);
        sortedList.map((attribute, index) => {
            if (index % 2 === 0) {
                col1.push(
                    <div key={`log-check-${attribute.id}`}>
                        <br/>
                        <Label for={attribute.name.replace(/ /g,"_")}>{attribute.name + ':'}</Label>
                        <br/>
                        <FormGroup check inline>
                            <CustomInput type={'radio'} id={'ok_' + attribute.name.replace(/ /g,"_")} status={'OK'} label={'OK'} name={attribute.id} defaultChecked/>
                            <CustomInput type={'radio'} id={'fail_' + attribute.name.replace(/ /g,"_")} status={'Fail'} label={'Fail'} name={attribute.id}/>
                        </FormGroup>
                    </div>
                )
            } else {
                col2.push(
                    <div key={`log-check-${attribute.id}`}>
                        <br/>
                        <Label for={attribute.name.replace(/ /g,"_")}>{attribute.name + ':'}</Label>
                        <br/>
                        <FormGroup check inline>
                            <CustomInput type={'radio'} id={'ok_' + attribute.name.replace(/ /g,"_")} status={'OK'} label={'OK'} name={attribute.id} defaultChecked/>
                            <CustomInput type={'radio'} id={'fail_' + attribute.name.replace(/ /g,"_")} status={'Fail'} label={'Fail'} name={attribute.id}/>
                        </FormGroup>
                    </div>
                )
            }
            return true;
        });
        return (
            [
                <Col key={'log-col-1'} sm={'6'}>
                    {col1}
                </Col>,
                <Col key={'log-col-2'} sm={'6'}>
                    {col2}
                </Col>
            ]
        )
    };

    return (
        <Card>
            <CardHeader>
                <Row>
                    <Col sm={3}/>
                    <Col sm={6}><h3 style={{textAlign: 'center'}}>Inspection Preview</h3></Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row style={{maxHeight: '20rem', overflow: 'scroll'}}>
                    {createForm()}
                </Row>
            </CardBody>
        </Card>
    );
};

export default InspectionSheet;
