import React from 'react';
import PropTypes from 'prop-types';
import {BotTable} from '../../components/bot-components';

const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    data: PropTypes.any,
    filters: PropTypes.any,
    remote: PropTypes.bool.isRequired,
    tblId: PropTypes.string.isRequired,
    pageSizes: PropTypes.array,
    columns: PropTypes.array,
    loading: PropTypes.bool,
    expandable: PropTypes.bool
};
let columns = [];
const defaultFilters = {};
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: defaultFilters
};

const UserIntegrationTable = React.forwardRef((props, ref) => {
    const {filters} = props;
    defaultState.filters = filters;
    //<editor-fold desc="Column Refs">
        const refs = {
            'col0Ref': React.createRef(),
            'col1Ref': React.createRef(),
            'col2Ref': React.createRef(),
            'col3Ref': React.createRef(),
            'col4Ref': React.createRef(),
            'col5Ref': React.createRef(),
            'col6Ref': React.createRef(),
            'col7Ref': React.createRef(),
            'col8Ref': React.createRef(),
            'col9Ref': React.createRef(),
            'col10Ref': React.createRef(),
            'col11Ref': React.createRef(),
            'col12Ref': React.createRef(),
            'col13Ref': React.createRef(),
            'col14Ref': React.createRef(),
            'col15Ref': React.createRef(),
            'col16Ref': React.createRef()
        }
        //</editor-fold>
    columns = props?.columns;
    columns.map((c, index) => {
        return c.refProp = refs[`col${index}Ref`]
    });

    const {pageSizes, title, data, remote, getMyUser, onUserSelected} = props;
    return(
        <div>
            <BotTable ref={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      pageSizes={pageSizes}
                      ClearFiltersBtn={true}
                      ClearFiltersIcon={true}
                      ClearSortBtn={true}
                      ClearSortIcon={true}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      pagination={'both'}
                      headers={true}
                      btnGroupDirection={'left'}
                      remote={remote}
                      defaultFilters={filters}
                      defaultState={defaultState}
                      onRowDoubleClick={(row) => {
                          localStorage.setItem('edit_user', row.id);
                          getMyUser(row.id);
                          onUserSelected();
                      }}/>
        </div>
    );
})

UserIntegrationTable.propTypes = propTypes;
export default UserIntegrationTable;
