import React from 'react';
import {connect} from 'react-redux';
import {_dateFormat} from '../../helpers/';
import {Card, CardHeader, CardBody, Row, Col, Label} from '../../components/bot-components';
import {logActions} from '../../network/actions/';
import {socketUrl} from '../../network/constants';

let table_key_iterator = 0;

const InspectionDetail = (props) => {
    const [images, setImages] = React.useState([]);


    const table_keys = () => {
        return `ldi_${table_key_iterator++}`;
    };

    const getCreatedBy = () => {
        localStorage.setItem('edit_user', props.log.items.posted_by_id);
        localStorage.setItem('userTab', '2');
    };

    const getOperator = () => {
        localStorage.setItem('edit_user', props.log.items.operator_id);
        localStorage.setItem('userTab', '2');
    };

    React.useEffect(() => {
        setImages(props?.log?.items?.images?.map(i => (
            <Col>
                <Row><img key={table_keys()} className={'inspection-image'} src={`${socketUrl}/${i.image_path}`}
                        alt={''}/></Row>
                {i.name && <Row>
                    <text>{i.name} Date: {i.metadata?.lastModified ?? 'None supplied'}</text>
                </Row>}
            </Col>
        )));
    }, [props.log]);

    const {log, deficient_properties} = props;
    return (
        <div>
            <Row>
                <Col xs={12} sm={12}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm={12}>
                                    <h2 className={'table-title'}>Equipment# {log.items && log.items['unit_number']}</h2>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Date Created:</label>
                                        <label
                                            className={'log_detail-field'}>{log.items && _dateFormat(log.items['created_at'])}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Date Performed:</label>
                                        <label
                                            className={'log_detail-field'}>{log.items && _dateFormat(log.items.date)}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Created By:</label>
                                        <a className={'log_detail-field'} href={'/#/setup/users/'} onClick={() => {
                                            getCreatedBy();
                                        }}>{log.items && log.items['created_by']}</a>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Equipment#:</label>
                                        <a className={'log_detail-field'}
                                           href={'/#/equipment/detail'}>{log.items && log.items['unit_number']}</a>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Location:</label>
                                        <label className={'log_detail-field'}>{log.items && log.items.job}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Operator:</label>
                                        <a className={'log_detail-field'} href={'/#/setup/users/'} onClick={() => {
                                            getOperator();
                                        }}>{log.items && log.items.operator}</a>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Brief Description:</label>
                                        <label
                                            className={'log_detail-field'}>{log.items && log.items['other_description']}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Work Order:</label>
                                        {log.items && log.items.workorder_id ? <a className={'log_detail-field'}
                                                                                  href={'/#/workorders/detail'}>{log.items['workorder_id']}</a> : null}
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Hours:</label>
                                        <label className={'log_detail-field'}>{log.items && log.items.hours}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Mileage:</label>
                                        <label className={'log_detail-field'}>{log.items && log.items.mileage}</label>
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>GPS Location:</label>
                                        {log.items && log.items.gps_location ?
                                            <a className={'log_detail-field'} name="gps_location"
                                               href={`https://maps.google.com/?q=${log.items.gps_location}`}
                                               target={'_blank'} rel={'noopener noreferrer'}>Map Link</a>
                                            : null
                                        }
                                    </Row>
                                    <Row className={'log_detail-row'}>
                                        <label className={'log_detail-label'}>Deficient Properties:</label>
                                        <ul>
                                            {deficient_properties}
                                        </ul>
                                    </Row>
                                </Col>
                            </Row>
                            <br/><hr/>
                            <Label sm={12} style={{fontWeight: 'bold', fontSize: '1rem', marginLeft: '-1.5rem'}}>
                                Detail Items
                            </Label>
                            <Row>
                                {log.items?.comments?.map(c => (
                                    <Col sm={6} style={{paddingTop: '0.25rem', fontWeight: 'bold', fontSize: '0.9rem'}}>
                                        {c.comment}
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                {images}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {authentication, log} = state;
    const {user} = authentication;
    const deficient_properties = [];

    if (log.items) {
        localStorage.setItem('log_id', log.items.id);
        localStorage.setItem('unit_id', log.items['unit_id']);
        if (log.items.Tasks) {
            if (log.items.Tasks.length > 0) {
                log.items.Tasks.map(function (check) {
                    return deficient_properties.push(<li key={`ldi_${table_key_iterator++}`}
                                                         style={{fontSize: 18}}>{check.name === 'Other' ? log.items.other_description : check.name}</li>);
                });
            }
        }
    }
    return {user, log, deficient_properties};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyLog: (id) => dispatch(logActions.getDetail(id)),
        getPrevious: (id) => dispatch(logActions.getPrevious(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetail);

/**@namespace items.operator_id*/
/**@namespace items.posted_by_id*/
/**@namespace items.Tasks*/
/**@namespace i.image_path*/
