/*Handles all actions for tire_size model on server.*/
import {tireSizeConstants} from '../constants/';
import {tireSizeService} from '../services/';
export const tireSizeActions = {};

/*Get full list of tire_sizes request.*/
tireSizeActions.getTireSizes = () => {
    return dispatch => {
        dispatch(request());

        tireSizeService.getAll()
            .then(
                tire_sizes => {
                    dispatch(success(tire_sizes))
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: tireSizeConstants.GET_ALL_TIRE_SIZES_REQUEST}
    }

    function success(tire_sizes) {
        return {type: tireSizeConstants.GET_ALL_TIRE_SIZES_SUCCESS, tire_sizes}
    }

    function failure(error) {
        return {type: tireSizeConstants.GET_ALL_TIRE_SIZES_FAILURE, error}
    }

    //</editor-fold>
};

/*Get tire_size detail request.*/
tireSizeActions.getTireSize = (id) => {
    return dispatch => {
        dispatch(request());

        tireSizeService.getDetail(id)
            .then(
                tire_size => {
                    dispatch(success(tire_size));
                    if (tire_size) {
                        localStorage.setItem('tire_size_id', tire_size.id)
                    }
                },
                error => {

                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: tireSizeConstants.TIRE_SIZE_DETAIL_REQUEST}
    }

    function success(tire_size) {
        return {type: tireSizeConstants.TIRE_SIZE_DETAIL_SUCCESS, tire_size}
    }

    function failure(error) {
        return {type: tireSizeConstants.TIRE_SIZE_DETAIL_FAILURE, error}
    }

    //</editor-fold>
};

/*Clear tire_size detail request.*/
tireSizeActions.clearTireSize = () => {
    return dispatch => {
        dispatch(success(null));
    };

    //<editor-fold desc="Dispatch results to state">

    function success(tire_size) {
        return {type: tireSizeConstants.TIRE_SIZE_DETAIL_SUCCESS, tire_size}
    }

    //</editor-fold>
};

/*Update tire_size request.*/
tireSizeActions.updateTireSize = (tire_size) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after tire_size updated.
        tireSizeService.updateTireSize(tire_size)
            .then(
                tire_size => {
                    dispatch(success(tire_size));
                    if (tire_size) {
                        dispatch(tireSizeActions.getTireSize(tire_size.id));
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: tireSizeConstants.TIRE_SIZE_UPDATE_REQUEST}
    }

    function success(tire_size) {
        return {type: tireSizeConstants.TIRE_SIZE_UPDATE_SUCCESS, tire_size}
    }

    function failure(error) {
        return {type: tireSizeConstants.TIRE_SIZE_UPDATE_FAILURE, error}
    }

    //</editor-fold>
};

/*Create new tire_size request.*/
tireSizeActions.createTireSize = (tire_size) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after tire_size creation.
        tireSizeService.createTireSize(tire_size)
            .then(
                tire_size => {
                    dispatch(success(tire_size));
                    if (tire_size) {
                        dispatch(tireSizeActions.getTireSize(tire_size.id))
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: tireSizeConstants.TIRE_SIZE_CREATE_REQUEST}
    }

    function success(tire_size) {
        return {type: tireSizeConstants.TIRE_SIZE_CREATE_SUCCESS, tire_size}
    }

    function failure(error) {
        return {type: tireSizeConstants.TIRE_SIZE_CREATE_FAILURE, error}
    }

    //</editor-fold>
};