export const companyRoleConstants = {
    CREATE_COMPANY_ROLE_REQUEST: 'COMPANY_ROLE_CREATE_REQUEST',
    CREATE_COMPANY_ROLE_SUCCESS: 'COMPANY_ROLE_CREATE_SUCCESS',
    CREATE_COMPANY_ROLE_FAILURE: 'COMPANY_ROLE_CREATE_FAILURE',

    UPDATE_COMPANY_ROLE_REQUEST: 'COMPANY_ROLE_UPDATE_REQUEST',
    UPDATE_COMPANY_ROLE_SUCCESS: 'COMPANY_ROLE_UPDATE_SUCCESS',
    UPDATE_COMPANY_ROLE_FAILURE: 'COMPANY_ROLE_UPDATE_FAILURE',

    GET_ALL_COMPANY_ROLES_REQUEST: 'COMPANY_ROLES_GET_ALL_REQUEST',
    GET_ALL_COMPANY_ROLES_SUCCESS: 'COMPANY_ROLES_GET_ALL_SUCCESS',
    GET_ALL_COMPANY_ROLES_FAILURE: 'COMPANY_ROLES_GET_ALL_FAILURE',

    DETAIL_COMPANY_ROLE_REQUEST: 'COMPANY_ROLE_DETAIL_REQUEST',
    DETAIL_COMPANY_ROLE_SUCCESS: 'COMPANY_ROLE_DETAIL_SUCCESS',
    DETAIL_COMPANY_ROLE_FAILURE: 'COMPANY_ROLE_DETAIL_FAILURE',
};
