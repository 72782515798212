const ZOOM_FACTOR = 0.1;
const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;
export const types = {
    PAN: 'PAN',
    PAN_START: 'PAN_START',
    END_PAN: 'END_PAN',
    ZOOM: 'ZOOM',
    CLICK_ZOOM: 'CLICK_ZOOM',
    RESET: 'RESET'
};
export const _reset = () => ({
    type: types.RESET
});
export const startPan = (event) => ({
    type: types.PAN_START,
    clientX: event.clientX,
    clientY: event.clientY,
});
export const pan = (event) => ({
    type: types.PAN,
    clientX: event.clientX,
    clientY: event.clientY,
});
export const end_pan = (event) => ({
    type: types.END_PAN,
    clientX: event.clientX,
    clientY: event.clientY
});
export const zoom = (event, containerRect) => {
    return ({
        type: types.ZOOM,
        zoomFactor: event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
        clientX: event.clientX,
        clientY: event.clientY,
        containerRect: containerRect,
    });
}
export const click_zoom = (event, containerRect) => {
    return ({
        type: types.CLICK_ZOOM,
        zoomFactor: event.deltaY,
        clientX: event.clientX,
        clientY: event.clientY,
        containerRect: containerRect,
    })
}
