import {authHeader, store} from '../../../helpers';
import {baseUrl} from '../../../network/constants';
import {userActions} from '../../../network/actions';
export const toggle = (tab, state, setState) => {
    if (state.activeTab !== tab) {
        setState({
            activeTab: tab,
        });
        localStorage.setItem('dbTasksTab', tab);
    }
};
export const getTasks = (req, uri, setState) => {
    setState({
        loading: true
    });
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(req)
    };

    return fetch(`${baseUrl}/${uri}`, requestOptions).then(response => {
        return response.status !== 404 ? response.json().then(async data => {
            if (!response.ok) {
                if (response.status === 401) {
                    alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                }
                setState({
                    myTasks: [],
                    loading: false
                });
                return alert('There was an error loading the tasks, please try again...');
            }

            const Auth = JSON.parse(response.headers.get('Authentication'));
            if (Auth) {
                // noinspection JSCheckFunctionSignatures
                await store.dispatch(userActions.updateAuth(Auth, null));
            }
            if (data) {
                return setState({
                    myTasks: data,
                    loading: false
                });
            } else return setState({
                myTasks: [],
                loading: false
            });
        }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
    });
};
