import React, { Component } from 'react';
import { Dropdown } from './bot-components';
import PropTypes from 'prop-types';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    direction: PropTypes.string
};

let defaultProps = {
    direction: 'down'
};

let AppHeaderDropdown = function (_Component) {
    _inherits(AppHeaderDropdown, _Component);

    function AppHeaderDropdown(props) {
        _classCallCheck(this, AppHeaderDropdown);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.toggle = _this.toggle.bind(_this);
        _this.state = {
            dropdownOpen: false
        };
        return _this;
    }

    AppHeaderDropdown.prototype.toggle = function toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    AppHeaderDropdown.prototype.render = function render() {
        let _props = this.props,
            children = _props.children,
            attributes = _objectWithoutProperties(_props, ['children']);

        return React.createElement(
            Dropdown,
            _extends({ nav: true, isOpen: this.state.dropdownOpen, toggle: this.toggle }, attributes),
            children
        );
    };

    return AppHeaderDropdown;
}(Component);

AppHeaderDropdown.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppHeaderDropdown.defaultProps = defaultProps;

export default AppHeaderDropdown;
