import React, {Component} from "react";
import PropTypes from 'prop-types';
import {mapToCssModules} from "./utils";
import classNames from "classnames";
const propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
    left: PropTypes.bool,
    right: PropTypes.bool
};
class BotTableButtonGroup extends Component {
    render() {
        const {className, left, right, innerRef, children, ...attributes} = this.props;
        const classes = mapToCssModules(classNames(
            className,
            left ? 'left' : false,
            right ? 'right' : false
        ));
        return (
            <bot-table-button-group class={classes} {...attributes}>
                {children}
            </bot-table-button-group>
        );
    }
}
BotTableButtonGroup.propTypes = propTypes;
export default BotTableButtonGroup;
customElements.define('bot-table-button-group', BotTableButtonGroup, {extends: 'div'});
