export const defaultState = {
    user_status_id: 1,
    user_roles: [],
    first_name: '',
    last_name: '',
    phone_number: '',
    user_email: '',
    user_number: '',
    selectedJob: null,
    selectedYard: null,
    crew: null,
    selectedRoles: [],
    password: 'Pumpco123',
    password_confirmation: 'Pumpco123',
    daily_inspections: false,
    first_name_valid: false,
    last_name_valid: false,
    user_email_valid: false,
    user_email_unique: true,
    password_exists: true,
    password_confirmation_exists: true,
    passwords_match: true,
    user_number_exists: false,
    user_number_unique: true,
    validRoles: false,
    validCompRole: false,
    enum1: null,
    enum2: null,
    enum3: null,
    enum4: null,
    fields: null,
    selectedStatus: {label: 'Active', value: 1}
};
