import React from "react";
import PropTypes from 'prop-types';
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from '../../../components/bot-components';
import {notEmptyText, validateNumber} from '../../../helpers';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    unit: PropTypes.object,
    unitStatusList: PropTypes.array,
    onSaveWorkorder: PropTypes.func
};

const WorkOrderCreate = (props) => {
    const [state, updateState] = React.useState({
        wo_unit_status_id: props.unit?.unit_status_id,
        wo_hours_valid: false,
        wo_mileage_valid: false,
        wo_brief_description_valid: false,
        wo_deficient_properties_valid: false,
        wo_general_status_valid:false
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));

    const changeUnitStatus = (e) => {
        const {value} = e.target;
        setState({
            wo_unit_status_id: value
        });
    }

    const changeHours = async (e) => {
        const {value} = e.target;
        setState({
            wo_hours: value,
            wo_hours_valid: await validateNumber(value)
        });
    }

    const changeMiles = async (e) => {
        const {value} = e.target;
        setState({
            wo_mileage: value,
            wo_mileage_valid: await validateNumber(value)
        });
    }

    const changeDeficiencies = (e) => {
        const {value} = e.target;
        setState({
            wo_deficient_properties: value,
            wo_deficient_properties_valid: notEmptyText(value)
        });
    }

    const changeBriefDescription = (e) => {
        const {value} = e.target;
        setState({
            wo_brief_description: value,
            wo_brief_description_valid: notEmptyText(value)
        });
    }

    const changeGeneralStatus = (e) => {
        const {value} = e.target;
        setState({
            wo_general_status: value,
            wo_general_status_valid: value.length < 50
        })
    }

    const onSaveWorkOrder = () => {
        const workorder = {
            unit_id: props.unit?.items.id ?? 1,
            mileage: parseFloat(Math.round(state.wo_mileage).toFixed(2)),
            hours: parseFloat(Math.round(state.wo_hours).toFixed(2)),
            other_description: state.wo_brief_description,
            deficient_properties: state.wo_deficient_properties,
            unit_status_id: state.wo_unit_status_id ?? 1,
            general_status: state.wo_general_status
        };
        props.onSaveWorkorder(workorder);
    }

    let {unit, unitStatusList} = props;
    const {wo_hours_valid, wo_mileage_valid, wo_brief_description_valid, wo_deficient_properties_valid, wo_general_status_valid, wo_unit_status_id} = state;
    const canSave = wo_deficient_properties_valid && wo_brief_description_valid && wo_hours_valid && wo_mileage_valid && wo_general_status_valid;
    return(
        <Modal size={'md'} isOpen={props.isOpen} className={props.className} toggle={() => {props.toggle()}}>
            <ModalHeader toggle={() => {props.toggle()}}>
                Create new Work Order for {unit?.items ? unit.items.number : ''}
            </ModalHeader>
            <ModalBody>
                <Label>Equip. Status:</Label>
                <Input type={'select'} value={wo_unit_status_id} onChange={(e) => {changeUnitStatus(e)}}>
                    {unitStatusList}
                </Input>
                <Label>Status Notes:</Label>
                <Input type={'textarea'}
                       placeholder={'Enter a status that is less than 50 characters long.'}
                       invalid={!wo_general_status_valid}
                       onChange={(e) => {changeGeneralStatus(e)}}/>
                <Label>Deficiencies:</Label>
                <Input type={'textarea'}
                       placeholder={'Use ( , ) to separate issues and create tasks on the new work order'}
                       invalid={!wo_deficient_properties_valid}
                       onChange={(e) => {changeDeficiencies(e)}}/>
                <Label>Brief Description:</Label>
                <Input type={'text'}
                       placeholder={'Enter a brief description for the work order.'}
                       onChange={(e) => {changeBriefDescription(e)}}
                       invalid={!wo_brief_description_valid}/>
                <Row>
                    <Col sm={6}>
                        <Label>Hours:</Label>
                        <Input type={'number'}
                               invalid={!wo_hours_valid}
                               onChange={(e) => {return changeHours(e)}}/>
                    </Col>
                    <Col sm={6}>
                        <Label>Miles:</Label>
                        <Input type={'number'}
                               onChange={(e) => {return changeMiles(e)}}
                               invalid={!wo_mileage_valid}/>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color={'texts'} outline disabled={!canSave} onClick={() => {onSaveWorkOrder()}}>Submit</Button>
                <Button color={'texts'} outline onClick={() => {props.toggle()}}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

WorkOrderCreate.propTypes = propTypes;
export default WorkOrderCreate;
