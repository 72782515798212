// noinspection JSValidateTypes,JSCheckFunctionSignatures

import React from 'react';
import {
    BotTable,
    Button,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../components/bot-components';
import PropTypes from 'prop-types';
import TableActions from '../../containers/table_action_component';
import WorkorderDetail from '../WorkOrders/WorkOrderDetail/WorkOrderDetail';
import InspectionDetail from './InspectionDetail';
import _ from 'lodash';
import BotUnitModal from '../Units/BotUnit/BotUnitModal';
import {_dateFormat} from '../../helpers';

const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    data: PropTypes.array,
    getInspections: PropTypes.func,
    getMyInspection: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    getMyWorkOrder: PropTypes.func.isRequired,
    unit: PropTypes.object,
    inspection: PropTypes.object,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    refProp: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({current: PropTypes.instanceOf(Element)})
    ]),
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    workorder: PropTypes.object,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    nested: PropTypes.bool,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    BotModules: PropTypes.object
};
let columns = [];
const defaultFilters = {
    created_at: '',
    posted_by: '',
    deficiency: '',
    deficient_properties: '',
    dot: '',
    hours: '',
    job: '',
    mileage: '',
    operator: '',
    other_description: '',
    pre_post: '',
    unit_active: '',
    unit_number: '',
    unit_type: '',
    workorder_id: ''
};
const myFilters = _.cloneDeep(defaultFilters);
let table_key_iterator = 0;
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const BotInspections = React.forwardRef(
    /**
     *
     * @param props
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.tblId
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param props.editAction
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.alertAction
     * @param props.getMyInspection
     * @param props.getMyUnit
     * @param props.getMyWorkOrder
     * @param props.getInspections
     * @param props.columns
     * @param props.actionCount
     * @param props.className
     * @param props.includeDateRange
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        const [state, updateState] = React.useState({
            ...defaultState,
            tblId: props.tblId,
            lStorage: props.lStorage,
            remote: props.remote,
            pageSizes: props.pageSizes,
            active: false,
            unitModal: false,
            workOrderModal: false,
            inspectionModal: false
        });
        const setState = newState => updateState((prevState) => ({
            ...prevState,
            ...newState
        }));
        //<editor-fold desc="Column Refs">
        const numberRef = React.useRef();
        const dotRef = React.useRef();
        const prePostRef = React.useRef();
        const deficienciesRef = React.useRef();
        const otherDescRef = React.useRef();
        const hourRef = React.useRef();
        const milesRef = React.useRef();
        const jobRef = React.useRef();
        const operatorRef = React.useRef();
        const deficiencyRef = React.useRef();
        const createdAtRef = React.useRef();
        const createdByRef = React.useRef();
        const uTypeRef = React.useRef();
        const woRef = React.useRef();
        const activeRef = React.useRef();
        //</editor-fold>

        const editButton = (cell, row) => {
            return (
                <TableActions row={row} edit={props.editAction} view={props.viewAction}
                              popout={props.popoutAction} alert={props.alertAction}
                              onView={() => {
                                  props.getMyInspection(row.id);
                                  window.location.href = '/#/inspections/detail';
                              }}
                              onPopout={() => {
                                  props.getMyInspection(row.id);
                                  toggleInspectionModal();
                              }}
                />
            );
        };

        const toggleWorkorderModal = () => {
            setState({
                workOrderModal: !state.workOrderModal
            });
        };

        const toggleUnitModal = () => {
            setState({
                unitModal: !state.unitModal
            });
        };

        const toggleInspectionModal = () => {
            setState({
                inspectionModal: !state.inspectionModal
            });
        };

        const table_keys = () => `${state.tblId}_${table_key_iterator++}`;

        const prepareUnit = (cell, row) => {
            function modalUnitClick() {
                props.getMyUnit(row.unit_id);
                toggleUnitModal();
            }

            return (
                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                    modalUnitClick();
                }}>{cell}</div>
            );
        };

        const prepareUnitActive = (cell) => {
            return (
                <div>{cell ? 'Active' : 'Inactive'}</div>
            );
        };

        const prepareWorkorder = (cell) => {
            function handleWorkorderClick() {
                props.getMyWorkOrder(cell);
                localStorage.setItem('workorder_id', cell);
                toggleWorkorderModal();
            }

            return (
                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                    handleWorkorderClick();
                }}>{cell}</div>
            );
        };

        const prepareDOT = (cell) => {
            return cell ? (<img className="dot-logo" src="" alt=""/>) : 'Non-DOT';
        };

        const refreshInspections = () => {
            const tbl = ref?.current?.state;
            const req = {
                sortName: tbl.sortName,
                sortOrder: tbl.sortOrder,
                currentPage: tbl.currPage,
                sizePerPage: tbl.currSize,
                filters: tbl.filters
            };
            props.remote ? props.getInspections(req) : props.getInspections();
        };

        const FilterDotBtn = () => (<DropdownItem outline color={'texts'}
                                                  className={(ref?.current?.state?.filters?.dot === true) ? 'active' : ''}
                                                  key={table_keys()} onClick={() => {
            filterDotOnly();
        }}>DOT Only</DropdownItem>);

        const filterDotOnly = () => {
            const filter = dotRef.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter('');
        };

        const FilterActiveBtn = () => (<DropdownItem outline color={'texts'}
                                                     className={ref?.current?.state?.filters?.unit_active === true ? 'active' : ''}
                                                     key={table_keys()} onClick={() => filterActiveOnly()}>Active
            Equipment Only</DropdownItem>);

        const filterActiveOnly = () => {
            const filter = activeRef?.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter('');
        };

        const FilterDefBtn = () => (<DropdownItem outline color={'texts'}
                                                  className={ref?.current?.state?.filters?.deficiency === true ? 'active' : ''}
                                                  key={table_keys()} onClick={() => {
            filterDefOnly();
        }}>Deficient Only</DropdownItem>);

        const filterDefOnly = () => {
            const filter = deficiencyRef?.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter('');
        };

        const selects = ['unit_active', 'dot', 'pre_post', 'deficiency'];

        const getInspections = async (req) => {
            const qParams = _.cloneDeep(req);
            await Object.keys(qParams?.filters || {}).forEach(k => {
                if ((selects.includes(k) || _.startsWith(k, 'bool_field')) && qParams.filters[k] !== '') qParams.filters[k] = JSON.stringify({
                    op: '=',
                    value: qParams.filters[k]
                });
            });
            props.getInspections(qParams);
        };

        const {pageSizes, title, data, unit, BotModules} = props;
        const filters = defaultFilters;
        columns = BotModules && props.columns ? props.columns : [
            {
                field: 'button',
                text: 'Views',
                width: props.actionCount * 40,
                hidden: false,
                format: (cell, row) => editButton(cell, row),
                sortable: false
            },
            {
                field: 'unit_number',
                text: 'Equipment#',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment#',
                    defaultValue: filters.unit_number
                },
                width: 150,
                hidden: false,
                format: (cell, row) => prepareUnit(cell, row),
                refProp: numberRef,
                sortable: true
            },
            {
                field: 'unit_active',
                text: 'Equipment Active',
                filter: {
                    type: 'select',
                    placeholder: 'Equipment Active',
                    options: [{value: '', label: 'Select Active'}, {value: true, label: 'Active'}, {
                        value: false,
                        label: 'Inactive'
                    }],
                    defaultValue: filters.dot
                },
                width: 150,
                hidden: false,
                format: (cell, row) => prepareUnitActive(cell, row),
                refProp: activeRef,
                sortable: true
            },
            {
                field: 'unit_type',
                text: 'Type',
                filter: {
                    type: 'text',
                    placeholder: 'Type',
                    defaultValue: filters.unit_type
                },
                width: 150,
                hidden: false,
                sortable: true,
                refProp: uTypeRef
            },
            {
                field: 'dot',
                text: 'DOT',
                filter: {
                    type: 'select',
                    placeholder: 'DOT',
                    options: [{value: '', label: 'Select DOT'}, {value: true, label: 'DOT'}, {
                        value: false,
                        label: 'Not-DOT'
                    }],
                    defaultValue: filters.dot
                },
                width: 150,
                hidden: false,
                format: cell => prepareDOT(cell),
                refProp: dotRef,
                sortable: true
            },
            {
                field: 'pre_post',
                text: 'Pre/Post-Trip',
                filter: {
                    type: 'select',
                    placeholder: 'Pre-Post',
                    options: [{value: '', label: 'Select Pre/Post-Trip'}, {
                        value: 'Pre-Trip',
                        label: 'Pre-Trip'
                    }, {value: 'Post-Trip', label: 'Post-Trip'}],
                    defaultValue: filters.pre_post
                },
                width: 150,
                hidden: false,
                refProp: prePostRef,
                sortable: true
            },
            {
                field: 'hours',
                text: 'Hours',
                filter: {
                    type: 'text',
                    placeholder: 'Hours',
                    defaultValue: filters.hours
                },
                width: 150,
                hidden: false,
                refProp: hourRef,
                sortable: true
            },
            {
                field: 'mileage',
                text: 'Mileage',
                filter: {
                    type: 'text',
                    placeholder: 'Mileage',
                    defaultValue: filters.mileage
                },
                width: 150,
                hidden: false,
                sortable: true,
                refProp: milesRef
            },
            {
                field: 'workorder_id',
                text: 'Work Order#',
                filter: {
                    type: 'text',
                    placeholder: 'Work Order#',
                    defaultValue: filters.workorder_id
                },
                width: 150,
                hidden: !BotModules?.find(m => m.name === 'workorders'),
                refProp: woRef,
                sortable: true,
                format: (cell) => prepareWorkorder(cell)
            },
            {
                field: 'created_at',
                text: 'Created',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: createdAtRef
            },
            {
                field: 'deficient_properties',
                text: 'Deficiencies',
                filter: {
                    type: 'text',
                    placeholder: 'Deficiencies',
                    defaultValue: filters.deficient_properties
                },
                width: 150,
                hidden: false,
                refProp: deficienciesRef,
                sortable: true
            },
            {
                field: 'other_description',
                text: 'Other Description',
                filter: {
                    type: 'text',
                    placeholder: 'Other Description',
                    defaultValue: filters.other_description
                },
                width: 150,
                hidden: false,
                refProp: otherDescRef,
                sortable: true
            },
            {
                field: 'job',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: filters.job
                },
                width: 150,
                hidden: false,
                refProp: jobRef,
                sortable: true
            },
            {
                field: 'operator',
                text: 'Operator',
                filter: {
                    type: 'text',
                    placeholder: 'Operator',
                    defaultValue: filters.operator
                },
                width: 200,
                hidden: false,
                refProp: operatorRef,
                sortable: true
            },
            {
                field: 'posted_by',
                text: 'Created By',
                filter: {
                    type: 'text',
                    placeholder: 'Created By',
                    defaultValue: filters.posted_by
                },
                width: 200,
                hidden: false,
                refProp: createdByRef,
                sortable: true
            },
            {
                field: 'deficiency',
                text: 'Deficiency',
                filter: {
                    type: 'select',
                    placeholder: 'Deficiency',
                    defaultValue: filters.deficiency,
                    options: [{value: '', label: 'Select Deficiency'}, {value: true, label: 'Yes'}, {
                        value: false,
                        label: 'No'
                    }]
                },
                width: 150,
                hidden: false,
                refProp: deficiencyRef,
                sortable: true,
                format: (cell) => {
                    if (cell === true || cell === 'true') {
                        return 'Yes';
                    } else {
                        return 'No';
                    }
                }
            }
        ];
        return (
            <div>
                <Modal size={'xl'} isOpen={state.workOrderModal} className={props.className}>
                    <ModalHeader toggle={() => toggleWorkorderModal()}/>
                    <ModalBody>
                        <WorkorderDetail modal/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'texts'} outline onClick={() => toggleWorkorderModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <BotUnitModal isOpen={state.unitModal} toggle={() => toggleUnitModal()} unit={unit}
                              parentComp={'workorder'}/>
                <Modal size={'xl'} isOpen={state.inspectionModal} className={props.className} toggle={() => toggleInspectionModal()}>
                    <ModalHeader toggle={() => {
                        toggleInspectionModal();
                    }}/>
                    <ModalBody>
                        <InspectionDetail/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'texts'} outline onClick={() => {
                            toggleInspectionModal();
                        }}>Close</Button>
                    </ModalFooter>
                </Modal>
                <BotTable refProp={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => getInspections(req)}
                          defaultState={defaultState}
                          pageSizes={pageSizes}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          pagination={'both'}
                          headers={true}
                          expandable={false}
                          doRowUpdate={() => null}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          lStorage={props.lStorage}
                          defaultFilters={filters}
                          dateRangeBtns={props.includeDateRange && ['30', '60', '90', 'All']}
                          dateRangeIcons={props.includeDateRange && ['30', '60', '90', 'All']}
                          onlyFilters={state.tblId === 'leRef' ? [FilterActiveBtn, FilterDefBtn] : [FilterActiveBtn, FilterDotBtn, FilterDefBtn]}
                          onRowDoubleClick={(row) => {
                              props.getMyInspection(row.id);
                              toggleInspectionModal();
                          }}
                          showTitle={false}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                          ClearFiltersBtn={true}
                          ClearFiltersIcon={true}
                          ClearSortBtn={true}
                          ClearSortIcon={true}
                          refreshData={() => refreshInspections()}
                          selects={selects}
                />
            </div>
        );
    });

BotInspections.propTypes = propTypes;
export default BotInspections;
