export const vendorConstants = {
    CREATE_VENDOR_REQUEST: 'VENDOR_CREATE_REQUEST',
    CREATE_VENDOR_SUCCESS: 'VENDOR_CREATE_SUCCESS',
    CREATE_VENDOR_FAILURE: 'VENDOR_CREATE_FAILURE',

    GET_ALL_VENDORS_REQUEST: 'VENDORS_GET_ALL_REQUEST',
    GET_ALL_VENDORS_SUCCESS: 'VENDORS_GET_ALL_SUCCESS',
    GET_ALL_VENDORS_FAILURE: 'VENDORS_GET_ALL_FAILURE',

    DETAIL_VENDOR_REQUEST: 'VENDOR_DETAIL_REQUEST',
    DETAIL_VENDOR_SUCCESS: 'VENDOR_DETAIL_SUCCESS',
    DETAIL_VENDOR_FAILURE: 'VENDOR_DETAIL_FAILURE',

    UPDATE_VENDOR_REQUEST: 'VENDOR_UPDATE_REQUEST',
    UPDATE_VENDOR_SUCCESS: 'VENDOR_UPDATE_SUCCESS',
    UPDATE_VENDOR_FAILURE: 'VENDOR_UPDATE_FAILURE',
};
