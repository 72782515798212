import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {BotTableFilters, Label, Tooltip} from './index';
import moment from 'moment';
const BotTableHeader = forwardRef((props, ref) => {
    const propTypes = {
        field: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        filter: PropTypes.object,
        width: PropTypes.string,
        hidden: PropTypes.bool,
        onFilterCleared: PropTypes.func,
        onSortChanged: PropTypes.func,
        onFilterChanged: PropTypes.func,
        sortable: PropTypes.bool,
        defaultSort: PropTypes.any
    };
    const {
        field,
        text,
        filter,
        width,
        hidden,
        onFilterChanged,
        onSortChanged,
        sortable,
        selectedRows,
        deleteRows,
        refProp
    } = props;

    const [state, setState] = useState({
        value: filter ? filter.type === 'date' ? (filter.defaultValue === '' || filter.defaultValue === '') ? {
            op: '<',
            date: ''
        } : filter.defaultValue : filter.defaultValue : ''
    });

    useEffect(() => {
        const dateFilter = filter ? filter.type === 'date' ? (filter.defaultValue === '' || filter.defaultValue === '') ? {
            op: '<',
            date: ''
        } : filter.defaultValue : null : null;
        setState(prevState =>({
            ...prevState,
            value: dateFilter ? dateFilter : filter ? filter.defaultValue : null
        }));
    }, [filter, filter?.defaultValue]);

    useImperativeHandle(ref, () => ({
        cleanFiltered() {
            return ({[field]: ''});
        },

        setFilter(value) {
            return sendFilter({value: value});
        },
        state: state,
        propTypes
    }));

    const changeProps = (e) => {
        const {value} = e.target;
        setState(prevState => ({
            ...prevState,
            value: value
        }));
    };

    const changeSelectProps = (e) => {
        const {value} = e;
        setState(prevState => ({
            ...prevState,
            value: value
        }));
        sendFilter({
            value: value
        });
    };

    const changeDateOpProp = (e) => {
        setState({
            ...state,
            value: {
                op: e.target.value,
                date: state.value && state.value.date
            }
        });
        state.value && state.value.date && state.value.date !== '' && sendFilter({
            value: {
                op: e.target.value,
                date: state.value.date
            }
        });
    };

    const changeDateProp = (e) => {
        const date = moment(e.target.value).format('YYYY-MM-DD');
        setState({
            ...state,
            value: {
                date: date,
                op: state.value ? state.value.op : '<'
            }
        });
        e.target.value !== '' && sendFilter({
            value: {
                op: state.value.op, date: date
            },
            sent: true
        });
    };

    const sendFilter = (v) => {
        onFilterChanged({target: {name: field, value: v.value}});
    };

    const clearMyFilter = () => {
        sendFilter({value: ''});
    };

    const handleSort = () => {
        switch (props.defaultSort) {
            case null:
                onSortChanged({
                    sortName: field,
                    sortOrder: 'asc'
                });
                break;
            case 'asc':
                onSortChanged({
                    sortName: field,
                    sortOrder: 'desc'
                });
                break;
            case 'desc':
                onSortChanged({
                    sortName: '',
                    sortOrder: ''
                });
                break;
            default:
                break;
        }
    };

    const {value} = state;
    const dirty = value !== filter?.defaultValue;
    const filter_dirty = ![null, '', '""', {op: '<', date: ''}].includes(value) && !dirty;

    return (
        <div
            ref={refProp}
            className={'bot-table-header'}
            style={{width: width, height: '100%'}}
            hidden={hidden}>
            <div
                className={'bot-table-header-row'}>
                <Label
                    className={'bot-table-header-label'}>
                    <i
                        className={`bot-table-sort sort-${props.defaultSort}`}
                        hidden={!sortable}
                        onClick={() => sortable ? handleSort() : null}
                    />
                    <i
                        className={'bot-table-header-clear-icon'}
                        onClick={(e) => {
                            e.stopPropagation();
                            clearMyFilter();
                        }}
                        hidden={!filter_dirty}
                    />
                    {text}
                </Label>
            </div>
            {field === 'button' ? <div>
                {selectedRows?.length > 0 ? <i className={'fa fa-2x fa-trash pointer'} onClick={deleteRows}/> : ''}
            </div> : filter &&
            <div
                className={'bot-table-header-row'}>
                <BotTableFilters
                    field={field}
                    type={filter.type}
                    placeholder={filter.placeholder}
                    options={filter.options}
                    sendFilter={(v) => sendFilter(v)}
                    filter_value={value}
                    changeDateOpProp={(e) => changeDateOpProp(e)}
                    changeDateProp={(e) => changeDateProp(e)}
                    changeProps={(e) => changeProps(e)}
                    changeSelectProps={(e) => changeSelectProps(e)}
                />
                {filter.type !== 'select' && filter.type !== 'date' &&
                <Tooltip
                    placement={'right'}
                    target={field + '-tooltip'}
                    isOpen={dirty}>
                    Press enter to filter
                </Tooltip>}
            </div>}
        </div>
    );
});
export default BotTableHeader;
