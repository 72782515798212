// noinspection JSValidateTypes,JSCheckFunctionSignatures

import React from 'react';
import {BotTable, DropdownItem} from '../../../components/bot-components';
import {ExpandedWorkOrder} from '../BotWorkOrderDetail/BotWorkOrders.helpers';
import WorkOrderDetailModal from '../WorkOrderDetail/WorkOrderDetailModal';
import {_dateFormat} from '../../../helpers';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    getMyWorkOrder: PropTypes.func.isRequired,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    workorder: PropTypes.object,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    toggleWorkorderModal: PropTypes.func,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any
};
const defaultFilters = {
    original_wo_id: '',
    original_created_at: '',
    original_completed_at: '',
    original_wo_status: '',
    original_completed_by: '',
    new_wo_id: '',
    new_created_at: '',
    new_completed_at: '',
    new_workorder_status: '',
    new_created_by: '',
    unit_number: '',
    unit_status: '',
    workorder_priority: '',
    dot: '',
    old_wo_tasks: '',
    new_wo_tasks: '',
    unit_type: '',
    unit_description: '',
    other_description: '',
    vendor: '',
    job: '',
    yard: '',
    unit_class: '',
    last_known_gps: ''
};
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: _.cloneDeep(defaultFilters)
};
let table_key_iterator = 0;

const BotWorkOrderExceptions = React.forwardRef(
    /**
     * @param props
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getWorkorders
     * @param props.updateWorkorder
     * @param props.createWorkorder
     * @param props.yardList
     * @param props.jobList
     * @param props.className
     * @param props.includeDateRange
     * @param props.canCreate
     * @param props.getMyWorkOrder
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        //<editor-fold desc="Column Refs">
        const orgIdRef = React.useRef();
        const orgCreatedAtRef = React.useRef();
        const orgCompletedAtRef = React.useRef();
        const orgWoStatusRef = React.useRef();
        const orgCompletedByRef = React.useRef();
        const nIdRef = React.useRef();
        const nCreatedAtRef = React.useRef();
        const nCompletedAtRef = React.useRef();
        const nWoStatusRef = React.useRef();
        const nCreatedByRef = React.useRef();
        const unitNumberRef = React.useRef();
        const unitStatusRef = React.useRef();
        const workorderPriorityRef = React.useRef();
        const dotRef = React.useRef();
        const oldWoTasksRef = React.useRef();
        const nWoTasksRef = React.useRef();
        const unitTypeRef = React.useRef();
        const unitClassRef = React.useRef();
        const unitDescriptionRef = React.useRef();
        const otherDescriptionRef = React.useRef();
        const vendorRef = React.useRef();
        const jobRef = React.useRef();
        const yardRef = React.useRef();
        const lastKnownGpsRef = React.useRef();
        //</editor-fold>
        const [state, updateState] = React.useState({
            ...defaultState,
            lStorage: props.lStorage,
            remote: props.remote,
            selectedRow: null,
            selectedCell: null,
            pageSizes: props.pageSizes ?? ['20', '40', '80', '160'],
            workorderModal: false,
            unitModal: false
        });
        const setState = (newState) => (updateState(prevState => ({
            ...prevState,
            ...newState
        })));

        const prepareLink = (cell) => {
            return (
                cell ? <a href={`https://maps.google.com/?q=${cell}`} target={'_blank'} rel={'noopener noreferrer'}>Map
                    Link</a> : ''
            );
        };

        const prepareWorkorder = (cell) => {
            return (
                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                    localStorage.setItem('workorder_id', cell);
                    props.getMyWorkOrder(cell);
                    toggleWorkOrderModal();
                }}>{cell}</div>
            );
        };

        const styleUnitStatus = (cell) => {
            switch (cell) {
                case 'Down':
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-red)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                case 'Needs Attention':
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-yellow)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                case 3:
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-green)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                default:
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-green)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
            }
        };

        const table_keys = () => `${state.tblId}_${table_key_iterator++}`;

        const toggleWorkOrderModal = () => setState({workorderModal: !state.workorderModal});

        const FilterDotBtn = () => (<DropdownItem outline color={'texts'}
                                                  className={(ref?.current?.state?.filters?.dot === true) ? 'active' : ''}
                                                  key={table_keys()} onClick={() => {
            filterDotOnly();
        }}>DOT Only</DropdownItem>);

        const filterDotOnly = () => {
            const filter = dotRef.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter('');
        };

        let {pageSizes} = state;
        let {title, data} = props;
        const columns = [
            {
                field: 'original_wo_id',
                text: 'Original Work Order#',
                filter: {
                    type: 'text',
                    placeholder: 'Original Work Order#'
                },
                width: 180,
                format: (cell) => prepareWorkorder(cell),
                hidden: false,
                refProp: orgIdRef,
                sortable: true
            },
            {
                field: 'original_created_at',
                text: 'Original Created',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: orgCreatedAtRef
            },
            {
                field: 'original_completed_at',
                text: 'Original Completed At',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: orgCompletedAtRef
            },
            {
                field: 'original_wo_status',
                text: 'Original WO Status',
                width: 150,
                hidden: false,
                filter: {
                    type: 'select',
                    placeholder: 'Original WO Status',
                    options: [{value: '', label: 'Select Status'}, {value: 'Open', label: 'Open'}, {
                        value: 'Closed',
                        label: 'Closed'
                    }]
                },
                sortable: true,
                refProp: orgWoStatusRef
            },
            {
                field: 'original_completed_by',
                text: 'Original WO Completed By',
                filter: {
                    type: 'text',
                    placeholder: 'Original WO Completed By',
                },
                width: 180,
                hidden: false,
                refProp: orgCompletedByRef,
                sortable: true
            },
            {
                field: 'new_wo_id',
                text: 'New Work Order#',
                filter: {
                    type: 'text',
                    placeholder: 'New Work Order#',
                },
                width: 180,
                format: (cell) => prepareWorkorder(cell),
                hidden: false,
                refProp: nIdRef,
                sortable: true
            },
            {
                field: 'new_created_at',
                text: 'New Created',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: nCreatedAtRef
            },
            {
                field: 'new_completed_at',
                text: 'New Completed At',
                width: 150,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                sortable: true,
                refProp: nCompletedAtRef
            },
            {
                field: 'new_workorder_status',
                text: 'New WO Status',
                width: 150,
                hidden: false,
                filter: {
                    type: 'select',
                    placeholder: 'New WO Status',
                    options: [{value: '', label: 'Select Status'}, {value: 'Open', label: 'Open'}, {
                        value: 'Closed',
                        label: 'Closed'
                    }]
                },
                sortable: true,
                refProp: nWoStatusRef
            },
            {
                field: 'new_created_by',
                text: 'New WO Created By',
                filter: {
                    type: 'text',
                    placeholder: 'New WO Created By',
                },
                width: 180,
                hidden: false,
                refProp: nCreatedByRef,
                sortable: true
            },
            {
                field: 'unit_number',
                text: 'Equipment#',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment#',
                },
                width: 150,
                hidden: false,
                refProp: unitNumberRef,
                sortable: true
            },
            {
                field: 'unit_status',
                text: 'Equip. Status',
                filter: {
                    type: 'text',
                    placeholder: 'Equip. Status'
                },
                width: 150,
                hidden: false,
                tdStyle: (cell) => styleUnitStatus(cell),
                refProp: unitStatusRef,
                sortable: true
            },
            {
                field: 'workorder_priority',
                text: 'Priority',
                filter: {
                    type: 'select',
                    placeholder: 'Priority',
                    options: [{value: '""', label: 'Select Priority'}, {value: 'High', label: 'High'}, {
                        value: 'Medium',
                        label: 'Medium'
                    }, {value: 'Low', label: 'Low'}]
                },
                width: 150,
                hidden: false,
                refProp: workorderPriorityRef,
                sortable: true
            },
            {
                field: 'dot',
                text: 'DOT',
                filter: {
                    type: 'select',
                    placeholder: 'DOT',
                    options: [{value: '', label: 'Select DOT'}, {value: 'D.O.T.', label: 'DOT'}, {
                        value: 'Non-D.O.T.',
                        label: 'Not-DOT'
                    }],
                },
                width: 150,
                hidden: false,
                refProp: dotRef,
                sortable: true
            },
            {
                field: 'old_wo_tasks',
                text: 'Old WO Tasks',
                filter: {
                    type: 'text',
                    placeholder: 'Old WO Tasks',
                },
                width: 150,
                hidden: false,
                refProp: oldWoTasksRef,
                sortable: true
            },
            {
                field: 'new_wo_tasks',
                text: 'New WO Tasks',
                filter: {
                    type: 'text',
                    placeholder: 'New WO Tasks',
                },
                width: 150,
                hidden: false,
                refProp: nWoTasksRef,
                sortable: true
            },
            {
                field: 'unit_type',
                text: 'Type',
                filter: {
                    type: 'text',
                    placeholder: 'Type',
                },
                width: 150,
                hidden: false,
                refProp: unitTypeRef,
                sortable: true
            },
            {
                field: 'unit_description',
                text: 'Equipment Description',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment Description',
                },
                width: 150,
                hidden: false,
                refProp: unitDescriptionRef,
                sortable: true
            },
            {
                field: 'other_description',
                text: 'Repair Description',
                filter: {
                    type: 'text',
                    placeholder: 'Repair Description',
                },
                width: 150,
                hidden: false,
                refProp: otherDescriptionRef,
                sortable: true
            },
            {
                field: 'vendor',
                text: 'Service Provider Name',
                filter: {
                    type: 'text',
                    placeholder: 'Service Provider Name',
                },
                width: 150,
                hidden: false,
                refProp: vendorRef,
                sortable: true
            },
            {
                field: 'job',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                },
                width: 150,
                hidden: false,
                refProp: jobRef,
                sortable: true
            },
            {
                field: 'yard',
                text: 'Location',
                filter: {
                    type: 'text',
                    placeholder: 'Location',
                },
                width: 150,
                hidden: false,
                refProp: yardRef,
                sortable: true
            },
            {
                field: 'unit_class',
                text: 'Class',
                filter: {
                    type: 'text',
                    placeholder: 'Class',
                },
                width: 150,
                hidden: false,
                refProp: unitClassRef,
                sortable: true
            },
            {
                field: 'last_known_gps',
                text: 'Map Link',
                width: 150,
                hidden: false,
                refProp: lastKnownGpsRef,
                sortable: false,
                format: (cell) => prepareLink(cell)
            }
        ];
        return (
            <>
                <WorkOrderDetailModal isOpen={state.workorderModal} toggle={toggleWorkOrderModal}/>
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => props.getWorkorders && props.getWorkorders(req)}
                          defaultState={defaultState}
                          pageSizes={pageSizes}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          pagination={'both'}
                          headers={true}
                          expandable={true}
                          expandableComponent={ExpandedWorkOrder}
                          doRowUpdate={(wo) => props.updateWorkorder(wo)}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          lStorage={props.lStorage}
                          defaultFilters={defaultFilters}
                          dateRangeBtns={props.includeDateRange ? ['30', '60', '90', 'All'] : []}
                          onlyFilters={[FilterDotBtn]}
                          showTitle={props.showTitle}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                />
            </>
        );
    });

BotWorkOrderExceptions.propTypes = propTypes;

export default BotWorkOrderExceptions;
