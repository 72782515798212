// noinspection DuplicatedCode

import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Col, CustomInput, Input, Row} from "../../components/bot-components";

const style = {
    padding: "0.5rem 1rem",
    backgroundColor: "white",
};

const CheckItem = ({data, handleDrop, updateDot, updatePhoto, updatePhotoOnly, updatePhotoRequired, updatePhotoCount, updateDetails, updateDetailsRequired, removeCheck, placeholder}) => {
    const ref = useRef(null);

    const [{opacity, cursor}, drag] = useDrag({
        item: data,
        type: 'div',
        accept: 'div',
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            cursor: monitor.canDrag() ? 'move' : 'default',
            item: monitor.getItem()
        }),
        canDrag: () => {
            if (data.photo_only) {
                return data.isNew;
            } else return true;
        }
    });

    const typeWatch = function () {
        let timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    }();

    const [{}, drop] = useDrop({
        accept: 'div',
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
            canDrop: monitor.canDrop()
        }),
        hover: (item) => {
            const dragIndex = item.sort_order;
            const hoverIndex = data.sort_order;

            if (item.isNew) {
                item.sort_order = hoverIndex;
            }
            dragIndex !== hoverIndex && typeWatch(() => {
                handleDrop(item, dragIndex, hoverIndex);
            }, 300);
        },
        canDrop: item => !item.photo_only
    });

    // noinspection JSValidateTypes
    drag(drop(ref));

    return (<div ref={ref} style={{...style, width: placeholder ? '100%' : '50%', cursor, opacity}}>
        <Row>
            {!placeholder && <i className={'fa fa-close remove-check'} onClick={() => removeCheck(data)}/>} {data.name} <div className={'photo-only-indicator'}>{data.photo_only && '(Photo Only)'}</div>
        </Row>
        {!placeholder && <Row>
            <CustomInput type="checkbox" color={'texts'} id={`${data.id}_dot`} checked={data.dot} disabled={data.static}
                         onChange={() => {
                             updateDot(data);
                         }} label="Dot" inline/>
            <CustomInput type="checkbox" color={'texts'} id={`${data.id}_photo`} checked={data.photo} disabled={data.static}
                         onChange={() => {
                             updatePhoto(data);
                         }} label="Photo" inline/>
            {data.photo &&
                <CustomInput type="checkbox" color={'texts'} id={`${data.id}_photo_only`} checked={data.photo_only} disabled={data.static}
                             onChange={() => {
                                 updatePhotoOnly(data);
                             }} label="Photo Only" inline/>}
            {data.photo &&
              <CustomInput type="checkbox" color={'texts'} id={`${data.id}_photo_required`} checked={data.photo_required} disabled={data.static}
                           onChange={() => {
                               updatePhotoRequired(data);
                           }} label="Photo Required" inline/>}
            {data.photo && (<Col sm={12}>Photo count<Input type={'number'} min={1} max={4} id={`${data.id}_photo_count`} disabled={data.static}
                                                           onChange={(e) => updatePhotoCount(e, data)}
                                                           value={data.photo_count}/></Col>)}
            <CustomInput type="checkbox" color={'texts'} id={`${data.id}_details`} checked={data.details} disabled={data.static}
                         onChange={() => {
                             updateDetails(data);
                         }} label="Details" inline/>
            {data.details &&
                <CustomInput type="checkbox" color={'texts'} id={`${data.id}_details_required`} checked={data.details_required} disabled={data.static}
                             onChange={() => {
                                 updateDetailsRequired(data);
                             }} label="Details Required" inline/>}

        </Row>}
    </div>);
};
export default CheckItem;

/**@namespace item.photo*/
/**@namespace item.photo_required*/
/**@namespace item.photo_count*/
/**@namespace item.photo_only*/
