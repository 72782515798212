import {callLogConstants} from '../constants';

export const call_log = (state = {}, action) => {
    switch (action.type) {
        case callLogConstants.CREATE_CALL_LOG_REQUEST:
            return {
                loading: true
            };
        case callLogConstants.CREATE_CALL_LOG_SUCCESS:
            return {
                items: action.call_log
            };
        case callLogConstants.CREATE_CALL_LOG_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
