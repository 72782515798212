import React from "react";
import PropTypes from 'prop-types';
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "../../../components/bot-components";
import {notEmptyText} from '../../../helpers';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    unit: PropTypes.object,
    onSaveDataPlate: PropTypes.func
};

const CreateDataPlate = (props) => {
    const [state, updateState] = React.useState({
        description: null,
        description_valid: false,
        number: null,
        number_valid: false,
        unit_id: props.unit.items.id
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const changeNumber = async (e) => {
        const {value} = e.target;
        setState({
            number: value,
            number_valid: await notEmptyText(value)
        });
    };
    const changeDescription = async (e) => {
        const {value} = e.target;
        setState({
            description: value,
            description_valid: await notEmptyText(value)
        });
    };
    const saveDataPlate = () => {
        const DP = {
            description: state.description,
            number: state.description,
            unit_id: props.unit.items.id
        };
        props.onSaveDataPlate(DP);
    };

    const {number, number_valid, description, description_valid, unit_id} = state;
    let canSave = description_valid && number_valid && unit_id;
    return(
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                Create Data Plate for Equipment# {props.unit.items.number}...
            </ModalHeader>
            <ModalBody>
                <Label>Name</Label>
                <Input type={'textarea'} value={description ? description : ''} invalid={!description_valid} placeholder={'Enter Name'} onChange={changeDescription}/>
                <Label>Serial Number</Label>
                <Input type={'textarea'} placeholder={'Enter serial number'} value={number ? number : ''} invalid={!number_valid} onChange={changeNumber}/>
            </ModalBody>
            <ModalFooter>
                <Button disabled={!canSave} outline color={'texts'} onClick={saveDataPlate}>Save</Button><Button outline color={'texts'} onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

CreateDataPlate.propTypes = propTypes;
export default CreateDataPlate;
