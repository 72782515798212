export const roleConstants = {

    GET_MECHANICS_REQUEST: 'USERS_GET_MECHANICS_REQUEST',
    GET_MECHANICS_SUCCESS: 'USERS_GET_MECHANICS_SUCCESS',
    GET_MECHANICS_FAILURE: 'USERS_GET_MECHANICS_FAILURE',

    GET_MANAGERS_REQUEST: 'USERS_GET_MANAGERS_REQUEST',
    GET_MANAGERS_SUCCESS: 'USERS_GET_MANAGERS_SUCCESS',
    GET_MANAGERS_FAILURE: 'USERS_GET_MANAGERS_FAILURE',

    GET_OPERATORS_REQUEST: 'USERS_GET_OPERATORS_REQUEST',
    GET_OPERATORS_SUCCESS: 'USERS_GET_OPERATORS_SUCCESS',
    GET_OPERATORS_FAILURE: 'USERS_GET_OPERATORS_FAILURE',

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',
};
