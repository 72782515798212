import {CardBody, Col, Label, Row} from '../../../components/bot-components';
import React from 'react';
import {jobActions, unitActions, userActions, workorderActions, yardActions} from '../../../network/actions';

export const getUserDetail = (user, userRoles, extraFields) => {
    return(
        <CardBody>
            <Row>
                <Col sm={4}>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Status:</label>
                        <label
                            className={'user-field'}>{user && user.items && user.items['user_status']}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <Label className={'user-label'}>Daily Inspections:</Label>
                        <Label className={'user-field'}>{user && user.items ? user.items.daily_inspections ? 'Yes' : 'No' : ''}</Label>
                    </Row>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Roles:</label>
                        <label className={'user-field'}>{userRoles && userRoles}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Job:</label>
                        <label
                            className={'user-field'}>{user && user.items && user.items.job_number}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Yard:</label>
                        <label
                            className={'user-field'}>{user && user.items && user.items.yard_name}</label>
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Name:</label>
                        <label className={'user-field'}>{user && user.items && user.items.name}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Employee#:</label>
                        <label
                            className={'user-field'}>{user && user.items && user.items['employee_number']}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <label className={'user-label'}>Phone#:</label>
                        <label
                            className={'user-field'}>{user && user.items && user.items['phone_number']}</label>
                    </Row>
                    <Row className={'user-row'}>
                        <Label className={'user-label'}>Email:</Label>
                        <Label className={'user-field'}>{user && user.items && user.items.email}</Label>
                    </Row>
                    {/*<Row className={'user-row'}>
                                    <label className={'user-label'}>Crew:</label>
                                    <label
                                        className={'user-field'}>{user && user.items && user.items.crew ? user.items.crew.name : ''}</label>
                                </Row>*/}
                </Col>
                <Col sm={4}>
                    {extraFields.bools && extraFields.bools.map(b => {
                        return(
                            <Row className={'user-row'}>
                                <Label className={'user-label'}>{b['table_header']}:</Label>
                                <Label className={'user-field'}>{user && user.items && user.items[b.alias] ? 'Yes' : 'No'}</Label>
                            </Row>
                        )
                    })}
                    {extraFields.enums && extraFields.enums.map(b => {
                        return(
                            <Row className={'user-row'}>
                                <Label className={'user-label'}>{b['table_header']}:</Label>
                                <Label className={'user-field'}>{user && user.items && user.items[b.alias]}</Label>
                            </Row>
                        )
                    })}
                    {extraFields._strings && extraFields._strings.map(b => {
                        return(
                            <Row className={'user-row'}>
                                <Label className={'user-label'}>{b['table_header']}:</Label>
                                <Label className={'user-field'}>{user && user.items && user.items[b.alias]}</Label>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
        </CardBody>
    )
};
export const mapStateToDetailProps = (state) => {
    const {users, user, unit, workorder, yards, jobs, user_preference} = state;
    let userRoles = [];
    let completeWork = [];
    let currentWork = [];
    let workorders = {};
    let workorders1 = {};
    let yardList = yards?.items?.map(y => ({value: y.id, label: y.name}));
    let jobList = jobs?.items?.map(j => ({value: j.id, label: j.name}));

    if (user && user.items && user.items.id && user.items.roles) {
        for (let i = 0; i < user.items.roles.length; i++) {
            let role = user.items.roles[i]['authority'];
            if (i === user.items.roles.length) {
                userRoles.push(role);
            } else {
                userRoles.push(role + ', ');
            }
        }
        user.items?.['userWorkorders']?.map(
            /**
             *
             * @param w
             * @param w.workorder_status_id
             * @returns {number}
             */
            w => w.workorder_status_id === 2 ? completeWork.push(w) : currentWork.push(w));
    }

    if (currentWork) {
        workorders1.items = currentWork;
    } else {
        workorders1.items = [];
    }

    if (completeWork) {
        workorders.items = completeWork;
    } else {
        workorders.items = [];
    }
    const BotModules = user_preference.items?.bot_modules;

    return {users, user, userRoles, completeWork, currentWork, unit, workorder, workorders, workorders1, yardList, jobList, user_preference, BotModules}
};
export const mapDispatchToDetailProps = (dispatch) => {
    return {
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getUser: (id) => dispatch(userActions.getDetail(id)),
        updateWorkorder: (wo) => dispatch(workorderActions.updateWorkorder(wo)),
        getAllJobs: () => dispatch(jobActions.getAll()),
        getAllYards: () => dispatch(yardActions.getAll())
    }
};
