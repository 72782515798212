import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from '../../components/bot-components';

const propTypes = {
    yard: PropTypes.object,
    saveYard: PropTypes.func,
    cancelEdit: PropTypes.func
};
const YardEdit = (props) => {
    const {yard, saveYard, cancelEdit} = props;
    const [state, updateState] = React.useState(_.cloneDeep(yard));
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const changeProps = React.useCallback((e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    }, []);
    React.useEffect(() => {
        console.log(yard);
        setState(_.cloneDeep(yard));
    }, [yard]);
    const {name, address, city, zip_code, phone_number} = state;
    return (
        <Form>
            <Row>
                <Col sm={6}>
                    {/*<div className={'input-group'}>
                        <div className={'input-group-prepend'}>
                            <span className={'input-group-text'}>Name</span>
                        </div>
                        <Input type={'text'} name={'name'} value={state.name} onChange={changeProps}/>
                    </div>
                    <div className={'custom-input-group'}>
                        <div className={'custom-input-group-prepend'}>
                            <span className={'custom-input-group-text'}>Address</span>
                        </div>
                        <Input type={'text'} name={'address'} value={state.address} onChange={changeProps}/>
                    </div>
                    <div className={'custom-input-group'}>
                        <div className={'custom-input-group-prepend'}>
                            <span className={'custom-input-group-text'}>City</span>
                        </div>
                        <Input type={'text'} name={'city'} value={state.city} onChange={changeProps}/>
                    </div>*/}
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Name</InputGroupText></InputGroupAddon>
                        <Input type={'text'} key={'yard-edit-name'} name={'name'} defaultValue={name} onChange={changeProps}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Address</InputGroupText></InputGroupAddon>
                        <Input type={'text'} key={'yard-edit-address'} name={'address'} defaultValue={address} onChange={changeProps}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>City</InputGroupText></InputGroupAddon>
                        <Input type={'text'} key={'yard-edit-city'} name={'city'} defaultValue={city} onChange={changeProps}/>
                    </InputGroup>
                </Col>
                <Col sm={6}>
                    {/*<div className={'custom-input-group'}>
                        <div className={'custom-input-group-prepend'}>
                            <span className={'custom-input-group-text'}>State</span>
                        </div>
                        <Input type={'text'} name={'state'} value={state.state} onChange={changeProps}/>
                    </div>
                    <div className={'custom-input-group'}>
                        <div className={'custom-input-group-prepend'}>
                            <span className={'custom-input-group-text'}>Zip</span>
                        </div>
                        <Input type={'text'} name={'zip_code'} value={state.zip_code} onChange={changeProps}/>
                    </div>
                    <div className={'custom-input-group'}>
                        <div className={'custom-input-group-prepend'}>
                            <span className={'custom-input-group-text'}>Phone#</span>
                        </div>
                        <Input type={'text'} name={'phone_number'} value={state.phone_number} onChange={changeProps}/>
                    </div>*/}
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>State</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'state'} key={'yard-edit-state'} defaultValue={state.state} onChange={changeProps}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Zip</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'zip_code'} defaultValue={zip_code} key={'yard-edit-zip'} onChange={changeProps}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Phone#</InputGroupText></InputGroupAddon>
                        <Input type={'text'} name={'phone_number'} defaultValue={phone_number} key={'yard-edit-phone'} onChange={changeProps}/>
                    </InputGroup>
                </Col>
            </Row>
            <Row className={'user-edit-footer'}>
                <Button outline color={'texts'} onClick={() => saveYard(state)}>Save</Button><Button outline color={'texts'} onClick={cancelEdit}>Cancel</Button>
            </Row>
        </Form>
    );
};
YardEdit.propTypes = propTypes;
export default YardEdit;
