import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const unitStatusService = {};

const apiUrl = baseUrl;

unitStatusService.createUnitStatus = (unit_status) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify({unit_status})
    };

    return fetch(`${apiUrl}/unit_status`, requestOptions).then(handleResponse);
};

unitStatusService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_statuses`, requestOptions).then(handleResponse)
};

unitStatusService.getStatus = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/unit_status/` + id, requestOptions).then(handleResponse);
};
