// export const baseUrl = 'https://api-pumpco-d.fleetmatrix.cc/api/v3';
// export const socketUrl = 'https://api-pumpco-d.fleetmatrix.cc';
// export const baseUrl = 'http://192.168.1.237:3000/api/v3';
// export const socketUrl = 'http://192.168.1.237:3000';
// export const baseUrl = 'https://api-test.fleetmatrix.cc:3000/api/v3';
// noinspection HttpUrlsUsage

export const socketUrl = 'https://api-test.fleetmatrix.cc';
export const baseUrl = 'https://api-test.fleetmatrix.cc/api/v3';
// export const socketUrl = 'https://api-test.fleetmatrix.cc';
// export const baseUrl = 'https://api-test.fleetmatrix.cc/api/v3';

// export const socketUrl = 'http://api-pumpco-t2.fleetmatrix.cc:8080';
// export const baseUrl = 'https://api-demo-sp.fleetmatrix.cc/api/v3';
// export const socketUrl = 'https://api-demo-sp.fleetmatrix.cc';
// export const baseUrl = 'https://api-pumpco-sp.fleetmatrix.cc/api/v3';
// export const socketUrl = 'https://api-pumpco-sp.fleetmatrix.cc';
