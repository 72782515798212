import {companyRoleConstants} from '../constants';

export const company_roles = (state = {}, action) => {
    switch (action.type) {
        case companyRoleConstants.GET_ALL_COMPANY_ROLES_REQUEST:
            return {
                loading: true
            };
        case companyRoleConstants.GET_ALL_COMPANY_ROLES_SUCCESS:
            return {
                items: action.company_roles
            };
        case companyRoleConstants.GET_ALL_COMPANY_ROLES_FAILURE:
            return {
                error: action.error
            };
        case companyRoleConstants.CREATE_COMPANY_ROLE_REQUEST:
            return {
                loading: true
            };
        case companyRoleConstants.CREATE_COMPANY_ROLE_SUCCESS:
            return {
                items: action.company_roles
            };
        case companyRoleConstants.CREATE_COMPANY_ROLE_FAILURE:
            return {
                error: action.error
            };
        case companyRoleConstants.UPDATE_COMPANY_ROLE_REQUEST:
            return {
                loading: true
            };
        case companyRoleConstants.UPDATE_COMPANY_ROLE_SUCCESS:
            return {
                items: action.company_roles
            };
        case companyRoleConstants.UPDATE_COMPANY_ROLE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const company_role = (state = {}, action) => {
    switch (action.type) {
        case companyRoleConstants.DETAIL_COMPANY_ROLE_REQUEST:
            return {
                loading: true
            };
        case companyRoleConstants.DETAIL_COMPANY_ROLE_SUCCESS:
            return {
                items: action.company_role
            };
        case companyRoleConstants.DETAIL_COMPANY_ROLE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
