import {plmAlertConstants} from '../constants';

export const plm_alerts = (state = {}, action) => {
    switch (action.type) {
        case plmAlertConstants.GET_ALL_PLM_ALERTS_REQUEST:
            return {
                loading: true
            };
        case plmAlertConstants.GET_ALL_PLM_ALERTS_SUCCESS:
            return {
                items: action.plm_alerts
            };
        case plmAlertConstants.GET_ALL_PLM_ALERTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const plm_alert = (state = {}, action) => {
    switch (action.type) {
        case plmAlertConstants.DETAIL_PLM_ALERT_REQUEST:
            return {
                loading: true
            };
        case plmAlertConstants.DETAIL_PLM_ALERT_SUCCESS:
            return {
                items: action.plm_alert
            };
        case plmAlertConstants.DETAIL_PLM_ALERT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
