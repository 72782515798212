import React from 'react';
import PropTypes from 'prop-types';
import {CardBody, Col, Row} from '../../components/bot-components';

const propTypes = {
    vendor: PropTypes.object
};
const VendorDetail = ({vendor}) => {

    return (
        <>
            <CardBody>
                <Row>
                    <Col sm={'6'}>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Name:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label style={{fontSize: 18}}>{vendor.items.name}</label>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Address:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label style={{fontSize: 18}}>{vendor.items.address}</label>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>City:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label style={{fontSize: 18}}>{vendor.items.city}</label>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Contact:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label
                                    style={{fontSize: 18}}>{vendor.items['contact_name']}</label>}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={'6'}>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>State:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label style={{fontSize: 18}}>{vendor.items.state}</label>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Zip:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label style={{fontSize: 18}}>{vendor.items['zip_code']}</label>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={'4'}>
                                <label className={'float-right'} style={{
                                    fontSize: 18,
                                    fontWeight: 'bold'
                                }}>Phone#:</label>
                            </Col>
                            <Col sm={'8'}>
                                {vendor.items &&
                                <label
                                    style={{fontSize: 18}}>{vendor.items['phone_number']}</label>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </>
    );
};
VendorDetail.propTypes = propTypes;
export default VendorDetail;
