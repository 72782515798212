import {JobConstants} from '../constants';

export const jobs = (state = {}, action) => {
    switch (action.type) {
        case JobConstants.GET_ALL_JOBS_REQUEST:
            return {
                loading: true
            };
        case JobConstants.GET_ALL_JOBS_SUCCESS:
            return {
                items: action.jobs
            };
        case JobConstants.GET_ALL_JOBS_FAILURE:
            return {
                error: action.error
            };
        case JobConstants.CREATE_JOB_REQUEST:
            return {
                loading: true
            };
        case JobConstants.CREATE_JOB_SUCCESS:
            return {
                items: action.jobs
            };
        case JobConstants.CREATE_JOB_FAILURE:
            return {
                error: action.error
            };
        case JobConstants.UPDATE_JOB_REQUEST:
            return {
                loading: true
            };
        case JobConstants.UPDATE_JOB_SUCCESS:
            return {
                items: action.jobs
            };
        case JobConstants.UPDATE_JOB_FAILURE:
            return {
                error: action.error
            };
        case JobConstants.CLEAR_JOBS:
            return {
                items: action.jobs
            };

        default:
            return state
    }
};
export const yard_jobs = (state = {}, action) => {
    switch (action.type) {
        case JobConstants.GET_YARD_JOBS_REQUEST:
            return {
                loading: true
            };
        case JobConstants.GET_YARD_JOBS_SUCCESS:
            return {
                items: action.yard_jobs
            };
        case JobConstants.GET_YARD_JOBS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
export const job = (state = {}, action) => {
    switch (action.type) {
        case JobConstants.DETAIL_JOB_REQUEST:
            return {
                loading: true
            };
        case JobConstants.DETAIL_JOB_SUCCESS:
            return {
                items: action.job
            };
        case JobConstants.DETAIL_JOB_FAILURE:
            return {
                error: action.error
            };
        case JobConstants.DELETE_JOB_REQUEST:
            return {
                loading: true
            };
        case JobConstants.DELETE_JOB_SUCCESS:
            return {
                items: undefined
            };
        case JobConstants.DELETE_JOB_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
