import React from "react";
import PropTypes from 'prop-types';
import {Button, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from '../../components/bot-components';
import {Modal, ModalBody} from '../../components/bot-components';
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    extraProps: PropTypes.any
};
const YardEditor = React.forwardRef((props, ref) => {
    const {row, toggle, onUpdate, extraProps, isOpen} = props;
    const [state, updateState] = React.useState({
        id: row.id,
        yard_id: row.yard_id,
        selectedYard: row.yard_id && row.yard && {label: row.yard, value: row.yard_id}
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const inputRef = React.createRef();

    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        state: state,
        setState: (newState) => setState(newState)
    }))

    const focusInput = () => {
        inputRef.current?.focus();
    }

    const updateData = () => {
        onUpdate({
            id: state.id,
            yard_id: state.yard_id
        });
        toggle();
    };
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');

    return (
        <Modal toggle={() => toggle()} centered={true} isOpen={isOpen} onOpened={() => focusInput()}>
            <ModalBody>
                <InputGroup>
                    <Input type={'react-select'}
                           options={extraProps}
                           ref={inputRef}
                           placeholder={'Select the location.'}
                           matchProp={'label'}
                           value={state.selectedYard}
                           onChange={(selectedYard) => {setState({yard_id: selectedYard ? selectedYard.value : null, selectedYard: selectedYard})}}/>
                    <InputGroupAddon id={'btn_save'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={updateData}><i className={'fa fa-check'}/></Button>
                    </InputGroupAddon>
                    <InputGroupAddon id={'btn_cancel'} addonType={'append'}>
                        <Button outline color={'texts'} onClick={() => toggle()}><i className={'fa fa-times'}/></Button>
                    </InputGroupAddon>
                    {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                        save</UncontrolledTooltip>}
                    {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                        editing</UncontrolledTooltip>}
                </InputGroup>
            </ModalBody>
        </Modal>
    );
});
YardEditor.propTypes = propTypes;
export default YardEditor;
