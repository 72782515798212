export const unitTypeConstants = {
    CREATE_UNIT_TYPE_REQUEST: 'UNIT_TYPE_CREATE_REQUEST',
    CREATE_UNIT_TYPE_SUCCESS: 'UNIT_TYPE_CREATE_SUCCESS',
    CREATE_UNIT_TYPE_FAILURE: 'UNIT_TYPE_CREATE_FAILURE',

    GET_ALL_UNIT_TYPES_REQUEST: 'UNIT_TYPES_GET_ALL_REQUEST',
    GET_ALL_UNIT_TYPES_SUCCESS: 'UNIT_TYPES_GET_ALL_SUCCESS',
    GET_ALL_UNIT_TYPES_FAILURE: 'UNIT_TYPES_GET_ALL_FAILURE',

    DETAIL_UNIT_TYPE_REQUEST: 'UNIT_TYPE_DETAIL_REQUEST',
    DETAIL_UNIT_TYPE_SUCCESS: 'UNIT_TYPE_DETAIL_SUCCESS',
    DETAIL_UNIT_TYPE_FAILURE: 'UNIT_TYPE_DETAIL_FAILURE',

    UPDATE_UNIT_TYPE_REQUEST: 'UNIT_TYPE_UPDATE_REQUEST',
    UPDATE_UNIT_TYPE_SUCCESS: 'UNIT_TYPE_UPDATE_SUCCESS',
    UPDATE_UNIT_TYPE_FAILURE: 'UNIT_TYPE_UPDATE_FAILURE',
};
