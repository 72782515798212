/* eslint-disable react-hooks/exhaustive-deps */
import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
// import {validateUniqueJobNum} from '../../helpers';
import {BotTable, DropdownItem} from '../../components/bot-components';
import CreateNewJob from './CreateNewJob';
import TableActions from '../../containers/table_action_component';
import {GenericSelectEditor, GenericTextEditor} from '../Editors';

let propTypes = {
    title: PropTypes.string,
    jobs: PropTypes.array,
    selectAction: PropTypes.bool,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    canEdit: PropTypes.bool,
    canCreate: PropTypes.bool,
    canDelete: PropTypes.bool,
    getJobs: PropTypes.func,
    getJob: PropTypes.func,
    jobStatusList: PropTypes.array,
    managerList: PropTypes.array,
    yardList: PropTypes.array,
    updateJob: PropTypes.func,
    createJob: PropTypes.func,
    deleteJobs: PropTypes.func,
    yard: PropTypes.object,
    job: PropTypes.object,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    job_statuses: PropTypes.array
};
let columns = [];
const defaultFilters = {
    name: '',
    number: '',
    job_status: '',
    supervisor_name: '',
    yard_name: '',
    deleted: ''
};
let myFilters = {...defaultFilters};
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 5,
    currPage: 1,
    columnModal: false,
    filters: {...myFilters}
};
let table_key_iterator = 0;

const JobTable = forwardRef(
    /**
     *
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.editAction
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.selectAction
     * @param props.alertAction
     * @param props.getJobs
     * @param props.getJob
     * @param props.selectedYard
     * @param props.createJob
     * @param props.deleteJob
     * @param props.updateJob
     * @param props.canSelect
     * @param props.canDelete
     * @param props.data
     * @param props.canCreate
     * @param props.job_statuses
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param props.yard
     * @param props.managerList
     * @param props.yardList
     * @param props.jobStatusList
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        //<editor-fold desc="Column Refs">
        const btnRef = React.useRef();
        const nameRef = React.createRef();
        const numberRef = React.createRef();
        const job_status_idRef = React.createRef();
        const supervisorRef = React.createRef();
        const yardRef = React.createRef();
        const deletedRef = React.createRef();
        //</editor-fold>

        const [state, setState] = useState({
            loaded: false,
            ...defaultState,
            jobs: null,
            tblId: props.tblId,
            lStorage: props.lStorage,
            remote: props.remote,
            jobNumberIsValid: true,
            pageSizes: props.pageSizes,
            jobModal: false,
            editorOpen: false
        });

        const updateState = (newState) => {
            setState({
                ...state,
                ...newState
            });
        };

        const setSelectedRows = (row, e) => {
            const {checked} = e.target;
            row.selected = checked;
            ref?.current?.setSelectedRows(row, e);
        };

        const table_keys = () => `${state.tblId}_${table_key_iterator++}`;

        const editButton = (cell, row) => {
            return (
                <TableActions ref={btnRef} key={table_keys()} row={row} edit={props.editAction} view={props.viewAction}
                              popout={props.popoutAction} alert={props.alertAction} select={props.selectAction}
                              onPopout={() => {
                                  props.getJob(row.id);
                              }}
                              onSelect={(row, e) => ref?.current?.setSelectedRows(row, e)}
                />
            );
        };

        const refreshJobs = () => {
            const tbl = ref?.current;
            const req = {
                sortName: tbl?.state.sortName,
                sortOrder: tbl?.state.sortOrder,
                currentPage: tbl?.state.currPage,
                sizePerPage: tbl?.state.currSize,
                filters: tbl?.state.filters
            };
            props.remote ? props.getJobs(req) : props.getJobs();
        };

        const createNewBtn = () => {
            return (
                <DropdownItem outline color={'texts'} disabled={!props.selectedYard} onClick={() => {
                    toggleJobModal();
                }}><i className={'fa fa-plus'}/> New</DropdownItem>
            );
        };

        const deleteJobs = (ids) => {
            Promise.all(ids.map(i => props.deleteJob(i))).then(() => props.getJobs());
        };

        const toggleJobModal = () => {
            updateState({
                jobModal: !state.jobModal
            });
        };

        const updateJob = (job) => {
            delete job.yard_name;
            delete job.created_at;
            delete job.updated_at;
            props.updateJob(job);
            setTimeout(() => props.getJobs(props.yard.items?.id), 1000);
        };

        const createJob = (job) => {
            delete job.jobNumberIsValid;
            props.createJob(job);
            setTimeout(() => props.getJobs(props.yard.items?.id), 1000);
        };

        const {title, jobStatusList, managerList, yardList} = props;
        const {jobModal} = state;
        const filters = defaultFilters;
        columns = props.canDelete ? [
            {
                field: 'button',
                text: 'Views',
                width: 1.5 * 40,
                hidden: false,
                format: (cell, row) => editButton(cell, row),
                sortable: false
            },
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: filters.name
                },
                width: 180,
                hidden: false,
                refProp: nameRef,
                editable: {
                    value: true,
                    onClick: (row) => toggleEditor(row, 'name')
                },
                sortable: true
            },
            {
                field: 'number',
                text: 'Number',
                filter: {
                    type: 'text',
                    placeholder: 'Number',
                    defaultValue: filters.number
                },
                width: 200,
                hidden: false,
                refProp: numberRef,
                editable: {
                    value: true,
                    onClick: (row) => toggleEditor(row, 'number')
                },
                sortable: true
            },
            {
                field: 'job_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: state.filters.job_status,
                    options: [{
                        value: '',
                        text: 'Select Status'
                    }, ...props.job_statuses.map ? props.job_statuses.map(js => {
                        return {value: js.status, text: js.status};
                    }) : []]
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row, cell) => toggleSelectEditor(row, 'job_status_id', cell),
                },
                refProp: job_status_idRef,
                sortable: true
            },
            {
                field: 'supervisor_name',
                text: 'Supervisor',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: filters.supervisor_name
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row, cell) => toggleSelectEditor(row, 'supervisor_id', cell),
                },
                refProp: supervisorRef,
                sortable: true
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: filters.yard_name
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row) => toggleSelectEditor(row, 'yard_id'),
                },
                refProp: yardRef,
                sortable: true
            },
            {
                field: 'deleted',
                text: 'Deleted',
                filter: {
                    type: 'select',
                    placeholder: 'deleted',
                    defaultValue: filters.deleted,
                    options: [{label: 'Yes', value: true}, {label: 'No', value: false}]
                },
                format: cell => cell === true ? 'Yes' : 'No',
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row) => toggleSelectEditor(row, 'deleted')
                },
                refProp: deletedRef,
                sortable: true
            }
        ] : [
            {
                field: 'name',
                text: 'Name',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: filters.name
                },
                width: 180,
                hidden: false,
                refProp: nameRef,
                editable: {
                    value: true,
                    onClick: (row) => toggleEditor(row, 'name')
                },
                sortable: true
            },
            {
                field: 'number',
                text: 'Number',
                filter: {
                    type: 'text',
                    placeholder: 'Number',
                    defaultValue: filters.number
                },
                width: 200,
                hidden: false,
                refProp: numberRef,
                editable: {
                    value: true,
                    onClick: (row) => toggleEditor(row, 'number')
                },
                sortable: true
            },
            {
                field: 'job_status',
                text: 'Status',
                filter: {
                    type: 'select',
                    placeholder: 'Status',
                    defaultValue: state.filters.job_status,
                    options: [{
                        value: '',
                        text: 'Select Status'
                    }, props.job_statuses ? props.job_statuses.map?.(js => {
                        return {value: js.status, text: js.status};
                    }) : []]
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row, cell) => toggleSelectEditor(row, 'job_status_id', cell),
                },
                refProp: job_status_idRef,
                sortable: true
            },
            {
                field: 'supervisor_name',
                text: 'Supervisor',
                filter: {
                    type: 'text',
                    placeholder: 'Name',
                    defaultValue: filters.supervisor_name
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row, cell) => toggleSelectEditor(row, 'supervisor_id', cell),
                },
                refProp: supervisorRef,
                sortable: true
            },
            {
                field: 'yard_name',
                text: 'Yard',
                filter: {
                    type: 'text',
                    placeholder: 'Yard',
                    defaultValue: filters.yard_name
                },
                width: 150,
                hidden: false,
                editable: {
                    value: true,
                    onClick: (row, cell) => toggleSelectEditor(row, 'yard_id', cell),
                },
                refProp: yardRef,
                sortable: true
            }
        ];

        const toggleEditor = (row, cell) => {
            updateState({
                selectedRow: row,
                selectedCell: cell,
                editorOpen: !state.editorOpen
            });
        };

        const toggleSelectEditor = (row, cell, name) => {
            const extraProps = {
                'job_status_id': jobStatusList,
                'supervisor_id': managerList,
                'yard_id': yardList,
                'deleted': [{label: 'Yes', value: true}, {label: 'No', value: false}]
            };
            updateState({
                selectedRow: row,
                selectedCell: cell,
                selectedName: name,
                extraProps: extraProps[cell],
                selectEditorOpen: !state.selectEditorOpen
            });
        };

        const filterActiveOnly = () => {
            const filter = deletedRef.current;
            const currValue = filter?.state.value;
            return (currValue === 1 || currValue === true) ? filter?.setFilter('') : filter?.setFilter(true);
        };
        const FilterDeletedBtn = () => (<DropdownItem outline color={'texts'}
                                                      className={ref?.current?.state?.filters?.deleted === true ? 'active' : ''}
                                                      key={table_keys()} onClick={() => {
            filterActiveOnly();
        }}>Deleted Only</DropdownItem>);

        return (
            <>
                <GenericTextEditor row={state.selectedRow} toggle={() => toggleEditor()} isOpen={state.editorOpen}
                                   cell={state.selectedCell} onUpdate={(ops) => {
                    const _job = {
                        ...state.selectedRow,
                        [state.selectedCell]: ops[state.selectedCell]
                    };
                    updateJob(_job);
                }}/>
                <GenericSelectEditor row={state.selectedRow} toggle={() => toggleSelectEditor()}
                                     isOpen={state.selectEditorOpen} extraProps={state.extraProps}
                                     cell={state.selectedCell} name={state.selectedName}
                                     onUpdate={(ops) => updateJob({...state.selectedRow, ...ops})}/>
                {props.yard?.items &&
                <CreateNewJob createJob={(job) => createJob(job)} isOpen={jobModal && props.selectedYard}
                              jobStatusList={props.jobStatusList}
                              managerList={props.managerList}
                              yardList={props.yardList}
                              toggle={toggleJobModal}
                              yard_id={props.yard?.items?.id}/>}
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          header={true}
                          data={props.data}
                          getData={(req) => props.getJobs(req)}
                          pageSizes={props.pageSizes}
                          ClearFiltersBtn={true}
                          ClearFiltersIcon={true}
                          ClearSortBtn={true}
                          ClearSortIcon={true}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          pagination={'both'}
                          headers={true}
                          expandable={false}
                          doRowUpdate={(job) => updateJob(job)}
                          refreshData={() => refreshJobs()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={defaultFilters}
                          defaultState={defaultState}
                          CreateNewBtn={props.canCreate && createNewBtn}
                          onlyFiltersIcons={[]}
                          canSelect={props.canDelete}
                          setSelectedRows={(row, e) => setSelectedRows(row, e)}
                          deleteRows={(rows) => deleteJobs(rows)}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                          loading={props.loading}
                          showTitle={props.showTitle}
                          lStorage={props.lStorage}
                          onlyFilters={props.canDelete && [FilterDeletedBtn]}
                />
            </>
        );
    });

JobTable.propTypes = propTypes;
export default JobTable;
