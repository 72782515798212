import {workorderStatusConstants} from '../constants';
import {workorderStatusService} from '../services';

export const workorderStatusActions = {};
workorderStatusActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_REQUEST});
    const success = (workorder_statuses) => ({type: workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_SUCCESS, workorder_statuses});
    const failure = (error) => ({type: workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderStatusService.getAll()
            .then(workorder_statuses => dispatch(success(workorder_statuses)), error => dispatch(failure(error)));
    };
};
workorderStatusActions.getStatus = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderStatusConstants.DETAIL_WORKORDER_STATUS_REQUEST});
    const success = (workorder_status) => ({type: workorderStatusConstants.DETAIL_WORKORDER_STATUS_SUCCESS, workorder_status});
    const failure = (error) => ({type: workorderStatusConstants.DETAIL_WORKORDER_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderStatusService.getStatus(id)
            .then(workorder_status => dispatch(success(workorder_status)), error => dispatch(failure(error)));
    };
};
workorderStatusActions.createStatus = (workorder_status) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: workorderStatusConstants.CREATE_WORKORDER_STATUS_REQUEST});
    const success = (workorder_status) => ({type: workorderStatusConstants.CREATE_WORKORDER_STATUS_SUCCESS, workorder_status});
    const failure = (error) => ({type: workorderStatusConstants.CREATE_WORKORDER_STATUS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        workorderStatusService.createWorkorderStatus(workorder_status)
            .then(workorder_status => dispatch(success(workorder_status)), error => dispatch(failure(error)));
    };
};
