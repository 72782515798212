/*Handles all attachments for the attachment model on the server*/
import {attachmentConstants} from '../constants';
import {attachmentService} from '../services';

export const attachmentActions = {};
attachmentActions.getAttachments = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: attachmentConstants.GET_ALL_ATTACHMENTS_REQUEST});
    const success = (attachments) => ({type: attachmentConstants.GET_ALL_ATTACHMENTS_SUCCESS, attachments});
    const failure = (error) => ({type: attachmentConstants.GET_ALL_ATTACHMENTS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        attachmentService.getAll()
            .then(attachments => dispatch(success(attachments)), error => dispatch(failure(error)));
    };
};
attachmentActions.getAttachment = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: attachmentConstants.DETAIL_ATTACHMENT_REQUEST});
    const success = (attachment) => ({type: attachmentConstants.DETAIL_ATTACHMENT_SUCCESS, attachment});
    const failure = (error) => ({type: attachmentConstants.DETAIL_ATTACHMENT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        attachmentService.getDetail(id)
            .then(attachment => dispatch(success(attachment)), error => dispatch(failure(error)));
    };
};
attachmentActions.createAttachment = (attachment) => {
    return dispatch => {
        dispatch(request());
        //TODO Need to implement Socket.io to update ui after attachment is created.
        attachmentService.createAttachment(attachment)
            .then(
                attachment => {
                    dispatch(success(attachment));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    //<editor-fold desc="Dispatch results to state">
    function request() {
        return {type: attachmentConstants.CREATE_ATTACHMENT_REQUEST}
    }

    function success(attachment) {
        return {type: attachmentConstants.CREATE_ATTACHMENT_SUCCESS, attachment}
    }

    function failure(error) {
        return {type: attachmentConstants.CREATE_ATTACHMENT_FAILURE, error}
    }

    //</editor-fold>
};
attachmentActions.updateAttachment = (attachment) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: attachmentConstants.UPDATE_ATTACHMENT_REQUEST});
    const success = (attachment) => ({type: attachmentConstants.UPDATE_ATTACHMENT_SUCCESS, attachment});
    const failure = (error) => ({type: attachmentConstants.UPDATE_ATTACHMENT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        attachmentService.updateAttachment(attachment)
            .then(attachment => dispatch(success(attachment)), error => dispatch(failure(error)));
    };
};
