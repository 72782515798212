import {companyConstants} from '../constants/';
import {companyService} from '../services/';

export const companyActions = {};
companyActions.getAll = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyConstants.GET_ALL_COMPANIES_REQUEST});
    const success = (companies) => ({type: companyConstants.GET_ALL_COMPANIES_SUCCESS, companies});
    const failure = (error) => ({type: companyConstants.GET_ALL_COMPANIES_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyService.getAll()
            .then(companies => dispatch(success(companies)), error => dispatch(failure(error)));
    };
};
companyActions.getCompany = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyConstants.DETAIL_COMPANY_REQUEST});
    const success = (company) => ({type: companyConstants.DETAIL_COMPANY_SUCCESS, company});
    const failure = (error) => ({type: companyConstants.DETAIL_COMPANY_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyService.getCompany(id)
            .then(company => dispatch(success(company)), error => dispatch(failure(error)));
    };
};
companyActions.createCompany = (company) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: companyConstants.CREATE_COMPANY_REQUEST});
    const success = (company) => ({type: companyConstants.CREATE_COMPANY_SUCCESS, company});
    const failure = (error) => ({type: companyConstants.CREATE_COMPANY_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        companyService.createCompany(company)
            .then(company => dispatch(success(company)), error => dispatch(failure(error)));
    };
};
