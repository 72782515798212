import React from 'react';
import WorkOrderDetail from "./WorkOrderDetail";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from '../../../components/bot-components';
import PropTypes from 'prop-types';

const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    headerTitle: PropTypes.string,
    ConfirmBtn: PropTypes.node,
    myWo: PropTypes.object,
    ids: PropTypes.array
};
const WorkOrderDetailModal = (props) => {
    const {isOpen, toggle, headerTitle, ConfirmBtn, ids} = props;
    return(
        <Modal size={'xl'} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {headerTitle}
            </ModalHeader>
            <ModalBody>
                <WorkOrderDetail modal ids={ids}/>
            </ModalBody>
            <ModalFooter>
                <Button outline color={'texts'} onClick={toggle}>Cancel</Button>{ConfirmBtn && <ConfirmBtn/>}
            </ModalFooter>
        </Modal>
    );
};
WorkOrderDetailModal.propTypes = propTypes;
export default WorkOrderDetailModal;
