import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, Nav, NavItem, NavLink as RsNavLink} from '../components/bot-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    navConfig: PropTypes.any,
    navFunc: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    isOpen: PropTypes.bool,
    staticContext: PropTypes.any,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'nav',
    navConfig: {
        items: [{
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer',
            badge: {variant: 'info', text: 'NEW'}
        }]
    },
    isOpen: false
};

let AppSidebarNav = function (_Component) {
    _inherits(AppSidebarNav, _Component);

    function AppSidebarNav(props) {
        _classCallCheck(this, AppSidebarNav);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.handleClick = _this.handleClick.bind(_this);
        _this.activeRoute = _this.activeRoute.bind(_this);
        _this.hideMobile = _this.hideMobile.bind(_this);
        return _this;
    }

    AppSidebarNav.prototype.handleClick = function handleClick(e) {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    };

    AppSidebarNav.prototype.activeRoute = function activeRoute(routeName, props) {
        return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    };

    AppSidebarNav.prototype.hideMobile = function hideMobile() {
        if (document.body.classList.contains('sidebar-show')) {
            document.body.classList.toggle('sidebar-show');
        }
    };

    // nav list


    AppSidebarNav.prototype.navList = function navList(items) {
        let _this2 = this;

        return items.map(function (item, index) {
            return _this2.navType(item, index);
        });
    };

    // nav type


    AppSidebarNav.prototype.navType = function navType(item, idx) {
        return item.title ? this.navTitle(item, idx) : item.divider ? this.navDivider(item, idx) : item.label ? this.navLabel(item, idx) : item.children ? this.navDropdown(item, idx) : this.navItem(item, idx);
    };

    // nav list section title


    AppSidebarNav.prototype.navTitle = function navTitle(title, key) {
        let classes = classNames('nav-title', title.class);
        return React.createElement(
            'li',
            {key: key, className: classes},
            this.navWrapper(title),
            ' '
        );
    };

    // simple wrapper for nav-title item


    AppSidebarNav.prototype.navWrapper = function navWrapper(item) {
        return item.wrapper && item.wrapper.element ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name) : item.name;
    };

    // nav list divider


    AppSidebarNav.prototype.navDivider = function navDivider(divider, key) {
        let classes = classNames('divider', divider.class);
        return React.createElement('li', {key: key, className: classes});
    };

    // nav label with nav link


    AppSidebarNav.prototype.navLabel = function navLabel(item, key) {
        let classes = {
            item: classNames('hidden-cn', item.class),
            link: classNames('nav-label', item.class ? item.class : ''),
            icon: classNames('nav-icon', !item.icon ? 'fa fa-circle' : item.icon, item.label.variant ? 'text-' + item.label.variant : '', item.label.class ? item.label.class : '')
        };
        return this.navLink(item, key, classes);
    };

    // nav dropdown


    AppSidebarNav.prototype.navDropdown = function navDropdown(item, key) {
        let classIcon = classNames('nav-icon', item.icon);
        return React.createElement(
            'li',
            {key: key, className: this.activeRoute(item.url, this.props)},
            React.createElement(
                'a',
                {className: 'nav-link nav-dropdown-toggle', href: '#', onClick: this.handleClick},
                React.createElement('i', {className: classIcon}),
                item.name
            ),
            React.createElement(
                'ul',
                {className: 'nav-dropdown-items'},
                this.navList(item.children)
            )
        );
    };

    // nav item with nav link


    AppSidebarNav.prototype.navItem = function navItem(item, key) {
        let classes = {
            item: classNames(item.class),
            link: classNames('nav-link', item.variant ? 'nav-link-' + item.variant : ''),
            icon: classNames('nav-icon', item.icon)
        };
        return this.navLink(item, key, classes);
    };

    // nav link


    AppSidebarNav.prototype.navLink = function navLink(item, key, classes) {
        let url = item.url ? item.url : '';
        return React.createElement(
            NavItem,
            {key: key, className: classes.item},
            this.isExternal(url) ? React.createElement(
                RsNavLink,
                {href: url, className: classes.link, active: true},
                React.createElement('i', {className: classes.icon}),
                item.name,
                this.navBadge(item.badge)
            ) : React.createElement(
                NavLink,
                {to: url, className: classes.link, activeClassName: 'active', onClick: this.hideMobile},
                React.createElement('i', {className: classes.icon}),
                item.name,
                this.navBadge(item.badge)
            )
        );
    };

    // badge addon to NavItem


    AppSidebarNav.prototype.navBadge = function navBadge(badge) {
        if (badge) {
            let classes = classNames(badge.class);
            return React.createElement(
                Badge,
                {className: classes, color: badge.variant},
                badge.text
            );
        }
        return null;
    };

    AppSidebarNav.prototype.isExternal = function isExternal(url) {
        let link = url ? url.substring(0, 4) : '';
        return link === 'http';
    };

    AppSidebarNav.prototype.filteredChildren = function filteredChildren(items, roles) {
        for (let item of items) {
            if (item.children) {
                item.children = this.filteredChildren(item.children, roles);
            }
        }

        return items.filter((item) => {
            return (item.roles && roles.some(role => item.roles.includes(role) || roles.includes('SuperAdmin')));
        });
    };

    AppSidebarNav.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            navConfig = _props.navConfig,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'navConfig']);

        for (let item of navConfig.items) {
            if (item.children) {
                item.children = this.filteredChildren(item.children, this.props.user.roles);
            }
        }

        navConfig.items = navConfig.items.filter((item) => {
            return (item.roles && this.props.user.roles.some(role => item.roles.includes(role)));
        });

        delete attributes.isOpen;
        delete attributes.staticContext;
        delete attributes.Tag;

        let navClasses = classNames(className, 'sidebar-nav');

        // sidebar-nav root
        return React.createElement(
            PerfectScrollbar,
            _extends({className: navClasses}, attributes, {option: {suppressScrollX: true}}),
            React.createElement(
                Nav,
                null,
                children || this.navList(navConfig.items)
            )
        );
    };

    return AppSidebarNav;
}(Component);

AppSidebarNav.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSidebarNav.defaultProps = defaultProps;

export default AppSidebarNav;
