import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const assetTypeService = {};

const apiUrl = baseUrl;

assetTypeService.createAssetType = (asset_type) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(asset_type)
    };

    return fetch(`${apiUrl}/asset_type`, requestOptions).then(handleResponse);
};

assetTypeService.updateAssetType = (asset_type) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(asset_type)
    };

    return fetch(`${apiUrl}/asset_type/${asset_type.id}`, requestOptions).then(handleResponse);
};

assetTypeService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/asset_types/`, requestOptions).then(handleResponse);
};

assetTypeService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/asset_type/${id}`, requestOptions).then(handleResponse);
};
