import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    type: PropTypes.string
};

let defaultProps = {
    tag: 'button',
    type: 'button'
};

let AppSidebarMinimizer = function (_Component) {
    _inherits(AppSidebarMinimizer, _Component);

    function AppSidebarMinimizer(props) {
        _classCallCheck(this, AppSidebarMinimizer);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }

    AppSidebarMinimizer.prototype.sidebarMinimize = function sidebarMinimize() {
        document.body.classList.toggle('sidebar-minimized');
        let sidebar = document.querySelector('.sidebar-nav');
        if (sidebar) {
            let toggleOn = sidebar.classList.toggle('ps');
            sidebar.classList.toggle('scrollbar-container', toggleOn);
            sidebar.classList.toggle('ps--active-y', toggleOn);
        }
    };

    AppSidebarMinimizer.prototype.brandMinimize = function brandMinimize() {
        document.body.classList.toggle('brand-minimized');
    };

    AppSidebarMinimizer.prototype.handleClick = function handleClick(e) {
        this.sidebarMinimize(e);
        this.brandMinimize(e);
    };

    AppSidebarMinimizer.prototype.render = function render() {
        let _this2 = this;

        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            type = _props.type,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag', 'type']);

        let classes = classNames(className, 'sidebar-minimizer', 'mt-auto');

        return React.createElement(
            Tag,
            _extends({className: classes, type: type}, attributes, {
                onClick: function onClick(event) {
                    return _this2.handleClick(event);
                }
            }),
            children
        );
    };

    return AppSidebarMinimizer;
}(Component);

AppSidebarMinimizer.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSidebarMinimizer.defaultProps = defaultProps;

export default AppSidebarMinimizer;
