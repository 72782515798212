import {unitConstants} from '../constants';

export const units = (state = {}, action) => {
    switch (action.type) {
        case unitConstants.GET_ALL_UNITS_REQUEST:
            return {
                loading: true
            };
        case unitConstants.GET_ALL_UNITS_SUCCESS:
            return {
                items: action.units
            };
        case unitConstants.GET_ALL_UNITS_FAILURE:
            return {
                error: action.error
            };
        case unitConstants.CREATE_UNIT_REQUEST:
            return {
                loading: true
            };
        case unitConstants.CREATE_UNIT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const unit = (state = unit, action) => {
    switch (action.type) {
        case unitConstants.DETAIL_UNIT_REQUEST:
            return {
                loading: true
            };
        case unitConstants.DETAIL_UNIT_SUCCESS:
            return {
                items: action.unit
            };
        case unitConstants.DETAIL_UNIT_FAILURE:
            return {
                error: action.error
            };
        case unitConstants.CREATE_UNIT_REQUEST:
            return {
                loading: true
            };
        case unitConstants.CREATE_UNIT_SUCCESS:
            return {
                items: action.unit
            };
        case unitConstants.CREATE_UNIT_FAILURE:
            return {
                error: action.error
            };
        case unitConstants.UPDATE_UNIT_REQUEST:
            return {
                loading: true
            };
        case unitConstants.UPDATE_UNIT_SUCCESS:
            return {
                items: action.unit
            };
        case unitConstants.UPDATE_UNIT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
export const dot_report = (state = {}, action) => {
    switch (action.type) {
        case unitConstants.DOT_REPORT_REQUEST:
            return {
                loading: true
            };
        case unitConstants.DOT_REPORT_SUCCESS:
            return {
                items: action.values
            };
        case unitConstants.DOT_REPORT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
