import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {sidebarCssClasses} from './Shared';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    compact: PropTypes.bool,
    display: PropTypes.string,
    fixed: PropTypes.bool,
    minimized: PropTypes.bool,
    isOpen: PropTypes.bool,
    offCanvas: PropTypes.bool,
    staticContext: PropTypes.any,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'div',
    compact: false,
    display: '',
    fixed: false,
    minimized: false,
    isOpen: false,
    offCanvas: false
};

let AppSidebar = function (_Component) {
    _inherits(AppSidebar, _Component);

    function AppSidebar(props) {
        _classCallCheck(this, AppSidebar);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.isCompact = _this.isCompact.bind(_this);
        _this.isFixed = _this.isFixed.bind(_this);
        _this.isMinimized = _this.isMinimized.bind(_this);
        _this.isOffCanvas = _this.isOffCanvas.bind(_this);
        _this.displayBreakpoint = _this.displayBreakpoint.bind(_this);
        return _this;
    }

    AppSidebar.prototype.componentDidMount = function componentDidMount() {
        this.displayBreakpoint(this.props.display);
        this.isCompact(this.props.compact);
        this.isFixed(this.props.fixed);
        this.isMinimized(this.props.minimized);
        this.isOffCanvas(this.props.offCanvas);
    };

    AppSidebar.prototype.isCompact = function isCompact(compact) {
        if (compact) {
            document.body.classList.add('sidebar-compact');
        }
    };

    AppSidebar.prototype.isFixed = function isFixed(fixed) {
        if (fixed) {
            document.body.classList.add('sidebar-fixed');
        }
    };

    AppSidebar.prototype.isMinimized = function isMinimized(minimized) {
        if (minimized) {
            document.body.classList.add('sidebar-minimized');
        }
    };

    AppSidebar.prototype.isOffCanvas = function isOffCanvas(offCanvas) {
        if (offCanvas) {
            document.body.classList.add('sidebar-off-canvas');
        }
    };

    AppSidebar.prototype.displayBreakpoint = function displayBreakpoint(display) {
        let cssTemplate = 'sidebar-' + display + '-show';
        let _sidebarCssClasses$ = sidebarCssClasses[0],
            cssClass = _sidebarCssClasses$[0];

        if (display && sidebarCssClasses.indexOf(cssTemplate) > -1) {
            cssClass = cssTemplate;
        }
        document.body.classList.add(cssClass);
    };

    AppSidebar.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        delete attributes.compact;
        delete attributes.display;
        delete attributes.fixed;
        delete attributes.minimized;
        delete attributes.offCanvas;
        delete attributes.isOpen;
        delete attributes.staticContext;

        let classes = classNames(className, 'sidebar');

        // sidebar-nav root
        return React.createElement(
            Tag,
            _extends({className: classes}, attributes),
            children
        );
    };

    return AppSidebar;
}(Component);

AppSidebar.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppSidebar.defaultProps = defaultProps;

export default AppSidebar;
