// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const taskPriorityService = {};

const apiUrl = baseUrl;

taskPriorityService.createTaskPriority = (task_priority) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(task_priority)
    };

    return fetch(`${apiUrl}/task_priority`, requestOptions).then(handleResponse);
};

taskPriorityService.updateTaskPriority = (task_priority) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(task_priority)
    };

    return fetch(`${apiUrl}/task_priority/` + task_priority.id, requestOptions).then(handleResponse);
};

taskPriorityService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/task_priorities`, requestOptions).then(handleResponse);
};

taskPriorityService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/task_priority/` + id, requestOptions).then(handleResponse);
};
