/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSCheckFunctionSignatures

import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {unitActions, vendorActions, workorderActions} from '../../network/actions';
import BotVendors from './BotVendors';
import {Card, CardBody, CardHeader, Col, Label, Row} from '../../components/bot-components';
import VendorDetail from './VendorDetail';
import VendorEdit from './VendorEdit';
import BotPurchaseOrders from '../PurchaseOrders/BotPurchaseOrders';
import BotParts from '../Parts/BotParts';
import BotWorkOrders from '../WorkOrders/BotWorkOrders/BotWorkOrders';
import {authHeader} from '../../helpers';
import {baseUrl} from '../../network/constants';

const Vendors = (props) => {
    const [state, updateState] = React.useState({
        edit: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const tblRef = React.useRef();
    const vpRef = React.useRef();
    const vpaRef = React.useRef();
    const vwRef = React.useRef();
    const {vendor, BotModules, updateVendor, user} = props;

    const deleteVendor = (_vendor) => {
        let requestOptions;
        switch (_vendor.deleted) {
            case true:
                return props.updateVendor({
                    ..._vendor,
                    deleted: false
                });
            default:
                requestOptions = {
                    method: 'DELETE',
                    headers: authHeader('GET')
                };
                return fetch(`${baseUrl}/vendors/${_vendor.id}`, requestOptions).then(response => {
                    if (!response.ok)  {
                        alert('There was an error saving your data. Please try again or contact a system Administrator');
                    } else props.getVendor(_vendor.id);
                });
        }
    };

    useEffect(() => {
        props.getVendors();
    }, []);

    return (
        <Card>
            <CardHeader>
                <h3 style={{textAlign: 'center'}}>Service Provider Management</h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={7}>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <h3 style={{textAlign: 'center'}}>Service Provider Info</h3>
                                    {vendor.items &&
                                    <Label className={'link-label-1'}
                                       onClick={() => setState({edit: !state.edit})}>{!state.edit ? 'Edit' : 'Cancel Edit'}</Label>}
                                    {vendor.items && (user?.roles?.includes('Administrator') || user?.roles?.includes('SuperAdmin')) &&
                                    <Label className={'link-label-1'}
                                           onClick={() => deleteVendor(vendor.items)}>{vendor.items.deleted ? 'Reactivate' : 'Delete'}</Label>}
                                </Row>
                            </CardHeader>
                            {!state.edit ?
                                <VendorDetail vendor={vendor}/> : <VendorEdit vendor={vendor} toggle={() => setState({edit: !state.edit})} updateVendor={updateVendor}/>}
                        </Card>
                        {!!BotModules?.find(m => m.name === 'purchase_orders') &&
                        <BotPurchaseOrders ref={vpRef}
                                            data={vendor?.items?.purchase_orders ?? null}
                                            workorder={props.workorder}
                                            tblId={'wpRef'}
                                            title={`Service Provider PO's`}
                                            showTitle={true}
                                            vendorList={props.vendorList}
                                            poStatusList={props.poStatusList}
                                            pageSizes={['5', '10', '20', '50', '100']}
                                            getPos={() => null}
                                            canCreate={false}
                                            remote={false}
                            // updatePo={(po) => onUpdatePo(po)}
                                            lStorage={'wo_detail_po_storage'}
                                            csvFile={`work_order_${vendor?.items?.name}_pos.csv`}
                                            u_preferences={props.user_preference}
                            // createPo={(po) => onCreatePo(po)}
                        />}
                        <br/>
                        {!!BotModules?.find(m => m.name === 'parts') &&
                        <BotParts ref={vpaRef}
                                   data={vendor?.items?.parts ?? null}
                                   workorder={props.workorder}
                                   tblId={'wpaRef'}
                                   title={`Service Provider Parts`}
                                   showTitle={true}
                                   vendorList={props.vendorList}
                                   pageSizes={['5', '10', '20', '50', '100']}
                                   getParts={() => null}
                                   canCreate={false}
                                   remote={false}
                            // updatePart={(pa) => onUpdatePart(pa)}
                                   lStorage={'wo_detail_part_storage'}
                                   csvFile={`work_order_${vendor?.items?.name}_parts.csv`}
                                   u_preferences={props.user_preference}
                            // createPart={(pa) => onCreatePart(pa)}
                        />}
                        <br/>
                        {!!BotModules?.find(m => m.name === 'workorders') &&
                        <BotWorkOrders
                            ref={vwRef}
                            data={vendor?.items?.workorders ?? null}
                            tblId={'uwRef'}
                            remote={false}
                            unit={props.unit}
                            actionCount={2}
                            viewAction={true}
                            popoutAction={true}
                            workorder={props.workorder}
                            title={'Service Provider Work Orders'}
                            showTitle
                            jobList={props.jobList}
                            yardList={props.yardList}
                            pageSizes={['5', '10', '20', '50', '100']}
                            updateWorkorder={(wo) => props.updateWorkorder(wo)}
                            getWorkorders={(id) => props.getMyUnit(id)}
                            getMyWorkOrder={(id) => props.getMyWorkOrder(id)}
                            canCreate={false}
                            unitStatusList={props.unitStatusList}
                            // createWorkorder={(wo) => props.createWorkorder(wo)}
                            lStorage={'unit_detail_work_order_storage'}
                            u_preferences={props.user_preference}
                            parentComp={'unit'}
                            csvFile={`equipment_${vendor?.items?.name}_work_orders.csv`}
                            getMyUnit={(id) => props.getMyUnit(id)} includeDateRange={false}
                        />}
                    </Col>
                    <Col sm={5}>
                        <BotVendors ref={tblRef}
                                    tblId={'allSP'}
                                    remote={false}
                                    loading={props.vendors.loading}
                                    data={props.vendors?.items}
                                    csvFile={'all_providers.csv'}
                                    getVendors={props.getVendors}
                                    createVendor={props.createVendor}
                                    lStorage={'all_provider_storage'}
                                    getVendor={props.getVendor}
                                    title={'All Service Providers'}
                                    pageSizes={['20', '50', '100', '200']}
                                    canCreate={true}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
};
const mapStateToProps = state => {
    const {vendors, vendor, unit, workorder, user_preference, jobs, yards, unit_statuses, authentication} = state;
    const {user} = authentication?.user || {};
    const vendorList = vendors?.items?.map(v => ({label: v.name, value: v.id}));
    let po_statuses = {items: [{id: 1, status: 'Requested'}, {id: 2, status: 'Approved'}, {id: 3, status: 'Denied'}]};
    let po_statusList = po_statuses?.items?.map(p => ({label: p.status, value: p.id}));
    const jobList = jobs.items?.map(j => ({label: j.name, value: j.id}));
    const yardList = yards.items?.map(y => ({label: y.name, value: y.id}));
    const unitStatusList = unit_statuses?.items?.map(us => ({label: us.status, value: us.id}));
    const BotModules = user_preference.items?.bot_modules;

    return {vendors, vendor, unit, workorder, vendorList, po_statusList, user_preference, jobList, yardList, unitStatusList, BotModules, user}
};
const mapDispatchToProps = dispatch => {
    return {
        getVendors: (req) => dispatch(vendorActions.getAll(req)),
        getVendor: (id) => dispatch(vendorActions.getVendor(id)),
        createVendor: (vendor) => dispatch(vendorActions.createVendor(vendor)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getMyWorkOrder: (id) => dispatch(workorderActions.getDetail(id)),
        updateWorkorder: (wo) => dispatch(workorderActions.createWorkorder(wo)),
        updateVendor: (_vendor) => dispatch(vendorActions.updateVendor(_vendor))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
