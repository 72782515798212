/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Col, BotTable, DropdownItem} from '../../components/bot-components';
import {tireSizeActions} from "../../network/actions/";
import _ from 'lodash';
import {GenericTextEditor} from '../Editors';

const defaultFilters = {
    size: ''
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};
const BotTireSizes = React.forwardRef((props, ref) => {
    const {tire_sizes, getTireSizes, createTireSize} = props;
    const sizeRef = React.useRef();
    const [newModal, setNeModal] = React.useState(false);
    React.useEffect(() => {
        getTireSizes();
        localStorage.setItem('lastComp', props?.history?.location?.pathname);
    }, []);
    const createNewTireButton = () => (<DropdownItem outline color={'texts'} onClick={() => setNeModal(!newModal)}><i className={'fa fa-plus'}/> New</DropdownItem>);
    const onSaveTireSize = (row) => {
        createTireSize({size: row.size});
        setTimeout(() => {
            getTireSizes();
        }, 500);
    };
    const columns = props?.columns ?? [
        {
            field: 'size',
            text: 'Size',
            filter: {
                type: 'text',
                placeholder: 'Size',
                defaultValue: defaultFilters.size
            },
            width: 700,
            hidden: false,
            sortable: true,
            refProp: sizeRef
        }
    ]
    return (
        <Col sm={6}>
            <GenericTextEditor onUpdate={(row) => onSaveTireSize(row)} row={{size: null}} cell={'size'} isOpen={newModal} toggle={() => setNeModal(!newModal)} />
            <BotTable ref={ref}
                      columns={columns}
                      title={true}
                      showTitle={false}
                      header={true}
                      data={tire_sizes?.items}
                      getData={() => getTireSizes()}
                      pageSizes={['20', '50', '100', '500']}
                      ClearFiltersBtn={true}
                      ClearFiltersIcon={true}
                      ClearSortBtn={true}
                      ClearSortIcon={true}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      pagination={'both'}
                      headers={true}
                      expandable={false}
                      lStorage={'all_tire_size_storage'}
                      loading={tire_sizes.loading}
                      tblId={'allTS'}
                      csvFile={'all_tire_sizes.csv'}
                      CreateNewBtn={createNewTireButton}
                      doRowUpdate={() => Promise.reject()}
                      refreshData={() => getTireSizes()}
                      btnGroupDirection={'left'}
                      remote={false}
                      defaultFilters={defaultFilters}
                      defaultState={defaultState}/>
        </Col>
    );
});

const mapStateToProps = state => {
    const {tire_sizes, tire_size} = state;
    return {tire_sizes, tire_size};
};

const mapDispatchToProps = dispatch => {
    return {
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes()),
        updateTireSize: (tire_size) => dispatch(tireSizeActions.updateTireSize(tire_size)),
        createTireSize: (tire_size) => dispatch(tireSizeActions.createTireSize(tire_size))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BotTireSizes);
