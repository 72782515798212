import moment from 'moment';
import _ from 'lodash';
//<editor-fold desc="Formatting Functions">

export function _dateFormat(cell) {
    return cell ? moment(cell).format('YYYY-MM-DD LT') : '';
}
export function _dateOnlyFormat(cell) {
    return cell ? moment(cell).format('YYYY-MM-DD') : '';
}

//</editor-fold>

export async function saveTableSettings(field, values, force, user_preference, updateUserPreference) {
    const prefClone = _.cloneDeep(user_preference.items);
    const fValues = _.cloneDeep(values);
    const sValues = _.cloneDeep(prefClone?.web_settings?.[field]);
    const submit = async () => {
        prefClone.web_settings[field] = fValues;
        if (prefClone.user_id !== undefined) {
            await updateUserPreference(prefClone);
            localStorage.setItem(field, JSON.stringify(fValues));
        }
    }
    if (prefClone?.web_settings) {
        !sValues ? await submit() :
        (!_.isEqual(JSON.stringify(sValues), JSON.stringify(fValues)) || force) && await submit();
    }
}
export const hasRoles = (roles, user) => roles?.map(r => !!(user?.roles?.includes(r) || user?.roles?.includes('SuperAdmin')));
export const getColumn = (field, text, width, format, hidden, refProp, sortable, filter, editable, tdStyle) => ({
    field,
    text,
    width,
    hidden,
    refProp,
    sortable,
    filter,
    format,
    editable,
    tdStyle
});
