/* eslint-disable react-hooks/exhaustive-deps */
// noinspection DuplicatedCode,JSIncompatibleTypesComparison,JSCheckFunctionSignatures

import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardText,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    ButtonGroup,
    ButtonDropdown
} from '../../components/bot-components';
import BotUsers from "../UserManagement/BotUsers/BotUsers";
import BotTasks from '../Tasks/BotTasks/BotTasks';
import BotGpsDataTable from '../GpsData/BotGpsDataTable';
import { exportComponentAsJPEG, exportComponentAsPDF } from 'react-component-export-image';
import ReduxBlockUi from 'react-block-ui';
import BotInspections from '../Inspections/BotInspections';

const propTypes = {
    myReport: PropTypes.any,
    getAllJobs: PropTypes.func,
    getAllYards: PropTypes.func,
    yards: PropTypes.array,
    jobs: PropTypes.array,
    updateTask: PropTypes.func,
    getKpis: PropTypes.func,
    getTireSizes: PropTypes.any,
    getAssetTypes: PropTypes.any,
    getUnitStatuses: PropTypes.func,
    getVendors: PropTypes.func,
    getMechanics: PropTypes.func,
    unit: PropTypes.any,
    user_preference: PropTypes.any,
    vendList: PropTypes.any,
    mechanicList: PropTypes.any,
    PriorityList: PropTypes.any,
    workorder: PropTypes.any,
    task: PropTypes.any,
    getAlert: PropTypes.func,
    getTask: PropTypes.func,
    asset_types: PropTypes.any,
    tire_sizes: PropTypes.any,
    loading: PropTypes.bool,
    report_id: PropTypes.any,
    Reps: PropTypes.any,
    getTaskPriorities: PropTypes.func,
    getMyUnit: PropTypes.func,
    getMyWorkorder: PropTypes.func,
    getMyLog: PropTypes.func,
    log: PropTypes.any,
    mechList: PropTypes.any,
    getUser: PropTypes.func
};
const InspectionPerformanceDashboard = forwardRef((props, ref) => {
    const [state, updateState] = useState({
        modalTitle: '',
        showMyModal: false,
        totalWorkOrders: undefined,
        activeUnits: undefined,
        closeTotal: undefined,
        openTotal: undefined,
        closeAverage: undefined,
        dotWorkOrders: undefined,
        dotTasks: undefined,
        nonDotTasks: undefined,
        totalUnits: undefined,
        unitStatuses: undefined,
        yard_id: null,
        job_id: null,
        report_id: null,
        last_loaded: null
    });
    const woModRef = React.createRef();
    const pRef = React.createRef();
    const setState = (newState) => {
        updateState({
            ...state,
            ...newState
        });
    };
    const {
        updateTask,
        myReport,
        Reps,
        report_id,
        loading,
        jobs,
        yards,
        getAllJobs,
        getAllYards,
        getTireSizes,
        getAssetTypes,
        getUnitStatuses,
        getVendors,
        getMechanics,
        getKpis,
        getTaskPriorities,
        unit,
        getMyUnit,
        getMyWorkorder,
        getMyLog,
        log,
        user_preference,
        vendList,
        mechanicList,
        mechList,
        PriorityList,
        workorder,
        task,
        getAlert,
        getTask,
        getUser
    } = props;

    const showModal = (data, field, title, modal) => {
        const myModal = () => {
            switch (modal) {
                case 'log':
                    return createLogModalBody(data, field, title);
                case 'ta':
                    return createTaskModalBody(data, field, title);
                case 'us':
                    return createUserModalBody(data, field, title);
                case 'un':
                    return createUnitModalBody(data, field, title);
                default:
                    break;
            }
        };

        setState({
            modalTitle: title,
            showMyModal: true,
            modalBody: myModal()
        });
    };

    const hideModal = () => {
        setState({
            modalTitle: '',
            showMyModal: false,
            modalBody: null
        });
    };

    React.useEffect(() => {
        const fetchInitData = () => {
            getAllJobs();
            getAllYards();
            getTireSizes();
            getAssetTypes();
            getUnitStatuses();
            getVendors();
            getMechanics();
            getTaskPriorities();
        };
        fetchInitData();
    }, []);

    const createUnitModalBody = (data, field) => {
        return(
            <BotGpsDataTable
                data={data}
                includeDateRange={false}
                getExceptions={() => null}
                csvFile={`${field}.csv`}
                lStorage={'dashboard_inspection_exceptions_storage'}
                title={'Inspection Exceptions'}
                loading={false}
                pageSizes={['20', '50', '100', '500', '1000']}
                tblId={'insTblRef'}
                remote={false}
                u_preferences={props['user_preference']}
                getUnit={(id) => getMyUnit(id)}
                unit={unit}
            />
        )
    };

    const createLogModalBody = (data, field, title) => {
        return (
            <BotInspections ref={woModRef}
                                getInspections={() => null}
                                getMyInspection={(id) => getMyLog(id)}
                                getMyUnit={(id) => getMyUnit(id)}
                                getMyWorkOrder={(id) => getMyWorkorder(id)}
                                title={title}
                                actionCount={2}
                                data={data ?? null}
                                viewAction={true}
                                popoutAction={true}
                                onRowSelected={(id) => {getMyLog(id)}}
                                unit={unit}
                                tblId={'woModRef'}
                                remote={false}
                                pageSizes={['20', '50', '100', '500', '1000']}
                                workorder={null}
                                csvFile={`${field}.csv`}
                                u_preferences={user_preference}
                                inspection={log}/>
        );
    };

    const createTaskModalBody = (data, field, title) => {
        return (
            <BotTasks ref={woModRef}
                      data={data}
                      workorder={workorder}
                      tblId={'woModRef'}
                      popoutAction={true}
                      viewAction={true}
                      actionCount={2}
                      title={title}
                      vendList={vendList}
                      vendorList={vendList}
                      mechList={mechList}
                      mechanicList={mechanicList}
                      pageSizes={['20', '50', '100', '500', '1000']}
                      getTasks={() => null}
                      unit={unit}
                      remote={false}
                      priorityList={PriorityList}
                      getMyWorkOrder={(id) => getMyWorkorder(id)}
                      getMyUnit={(id) => getMyUnit(id)}
                      updateTask={(t) => updateTask(t)}
                      createTask={(t) => console.debug(t)}
                      task={task} canCreate={false}
                      csvFile={`${field}.csv`}
                      u_preferences={user_preference}
                      getMyAlert={(id) => getAlert(id)}
                      getMyTask={(id) => getTask(id)}/>
        );
    };

    const createUserModalBody = (data, field, title) => {
        return (
            <BotUsers ref={woModRef}
                      getUsers={() => null}
                      onUserSelected={(id) => getUser(id)}
                      getMyUser={(id) => getUser(id)}
                      data={data}
                      remote={false}
                      pageSizes={['20', '50', '100', '250', '500', '1000']}
                      title={title}
                      csvFile={`${field}.csv`}
                      u_preferences={user_preference}
                      extraColumns={[]}
                      tblId={'woModRef'}/>
        );
    };

    React.useEffect(() => {
        if (myReport && Object.keys(myReport).includes('task_data')) {
            let totalInspections = myReport.inspection_data?.inspections_mtd ?? [];
            let inspectionsToday = myReport.inspection_data?.inspections_today ?? [];
            let dotInspections = totalInspections.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
            let dotInspectionsToday = inspectionsToday?.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK') ?? [];
            let totalTasks = myReport.task_data?.tasks_mtd ?? [];
            let tasksToday = myReport.task_data?.tasks_today ?? [];
            let dotTasks = totalTasks.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
            let dotTasksToday = tasksToday.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
            let unitsMissingInspections = myReport.missing_equipment_data?.missing_this_month ?? [];
            let unitsMissingInspectionsToday = myReport.missing_equipment_data?.missing_today ?? [];
            let dotUnitsMissingInspections = unitsMissingInspections.filter(r => r.dot === true);
            let dotUnitsMissingInspectionsToday = unitsMissingInspectionsToday.filter(r => r.dot === true);
            let heavyInspections = totalInspections.filter(r => r.unit_type === 'EQUIPMENT');
            let heavyInspectionsToday= inspectionsToday.filter(r => r.unit_type === 'EQUIPMENT');
            let heavyTasks = totalTasks.filter(r => r.unit_type === 'EQUIPMENT');
            let heavyTasksToday = tasksToday.filter(r => r.unit_type === 'EQUIPMENT');
            let usersMissingInspections = myReport.missing_operator_data?.missing_this_month ?? [];
            let usersMissingInspectionsToday = myReport.missing_operator_data?.missing_today ?? [];
            let otherInspections = totalInspections.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
            let otherInspectionsToday = inspectionsToday.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
            let otherTasks = totalTasks.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
            let otherTasksToday = tasksToday.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
            let allUsers = myReport.all_active_users?.users ?? [];
            let activeUsers = myReport.all_active_users?.active_users ?? [];
            let allUnits = myReport.all_active_units?.units ?? [];
            let activeUnits = myReport.all_active_units?.units ?? [];
            let jobs= myReport.inspection_data ? myReport.inspection_data.jobs : [];
            let yards = myReport.inspection_data ? myReport.inspection_data.yards : [];

            let myData = {
                totalInspections: totalInspections,
                inspectionsToday: inspectionsToday,
                totalTasks: totalTasks,
                tasksToday: tasksToday,
                dotInspections: dotInspections,
                dotInspectionsToday: dotInspectionsToday,
                dotTasks: dotTasks,
                dotTasksToday: dotTasksToday,
                unitsMissingInspections: unitsMissingInspections,
                unitsMissingInspectionsToday: unitsMissingInspectionsToday,
                dotUnitsMissingInspections: dotUnitsMissingInspections,
                dotUnitsMissingInspectionsToday: dotUnitsMissingInspectionsToday,
                heavyInspections: heavyInspections,
                heavyInspectionsToday: heavyInspectionsToday,
                heavyTasks: heavyTasks,
                heavyTasksToday: heavyTasksToday,
                usersMissingInspections: usersMissingInspections,
                usersMissingInspectionsToday: usersMissingInspectionsToday,
                otherInspections: otherInspections,
                otherInspectionsToday: otherInspectionsToday,
                otherTasks: otherTasks,
                otherTasksToday: otherTasksToday,
                allUsers: allUsers,
                activeUsers: activeUsers,
                allUnits: allUnits,
                activeUnits: activeUnits,
                jobs: jobs,
                yards: yards,
                last_loaded: myReport.last_loaded
            }
            setState({...myData});
        }
    }, [myReport]);

    const changeValue = (field, id) => {
        let myData = {};
        let totalInspections = myReport.inspection_data?.inspections_mtd ?? [];
        let inspectionsToday = myReport.inspection_data?.inspections_today ?? [];
        let dotInspections = totalInspections.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
        let dotInspectionsToday = inspectionsToday?.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK') ?? [];
        let totalTasks = myReport.task_data?.tasks_mtd ?? [];
        let tasksToday = myReport.task_data?.tasks_today ?? [];
        let dotTasks = totalTasks.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
        let dotTasksToday = tasksToday.filter(r => r.unit_type === 'Heavy Trailer' || r.unit_type === 'HEAVY TRUCK');
        let unitsMissingInspections = myReport.missing_equipment_data?.missing_this_month ?? [];
        let unitsMissingInspectionsToday = myReport.missing_equipment_data?.missing_today ?? [];
        let dotUnitsMissingInspections = unitsMissingInspections.filter(r => r.dot === true);
        let dotUnitsMissingInspectionsToday = unitsMissingInspectionsToday.filter(r => r.dot === true);
        let heavyInspections = totalInspections.filter(r => r.unit_type === 'EQUIPMENT');
        let heavyInspectionsToday= inspectionsToday.filter(r => r.unit_type === 'EQUIPMENT');
        let heavyTasks = totalTasks.filter(r => r.unit_type === 'EQUIPMENT');
        let heavyTasksToday = tasksToday.filter(r => r.unit_type === 'EQUIPMENT');
        let usersMissingInspections = myReport.missing_operator_data?.missing_this_month ?? [];
        let usersMissingInspectionsToday = myReport.missing_operator_data?.missing_today ?? [];
        let otherInspections = totalInspections.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
        let otherInspectionsToday = inspectionsToday.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
        let otherTasks = totalTasks.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
        let otherTasksToday = tasksToday.filter(r => r.unit_type !== 'EQUIPMENT' && r.unit_type !== 'Heavy Trailer' && r.unit_type !== 'HEAVY TRUCK');
        let allUsers = myReport.all_active_users?.users ?? [];
        let activeUsers = myReport.all_active_users?.active_users ?? [];
        let allUnits = myReport.all_active_units?.units ?? [];
        let activeUnits = myReport.all_active_units?.units ?? [];

        if (myReport) {
            if (!isNaN(parseInt(id))) {
                let fld = null;
                const yard_id = parseInt(id);
                if (field === 'yard_id') {
                    let yard = yards.filter(y => y.id === yard_id)[0];
                    fld = yard.name;
                } else {
                    const job = jobs.filter(j => j.id === yard_id)[0];
                    fld = job.name;
                }
                myData = {
                    totalInspections: totalInspections?.filter(r => r[field] === parseInt(id)),
                    inspectionsToday: inspectionsToday?.filter(r => r[field] === parseInt(id)),
                    totalTasks: totalTasks?.filter(r => r[field] === parseInt(id)),
                    tasksToday: tasksToday?.filter(r => r[field] === parseInt(id)),
                    dotInspections: dotInspections?.filter(r => r[field] === parseInt(id)),
                    dotInspectionsToday: dotInspectionsToday?.filter(r => r[field] === parseInt(id)),
                    dotTasks: dotTasks?.filter(r => r[field] === parseInt(id)),
                    dotTasksToday: dotTasksToday?.filter(r => r[field] === parseInt(id)),
                    unitsMissingInspections: unitsMissingInspections?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld),
                    unitsMissingInspectionsToday: unitsMissingInspectionsToday?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld),
                    dotUnitsMissingInspections: dotUnitsMissingInspections?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld),
                    dotUnitsMissingInspectionsToday: dotUnitsMissingInspectionsToday?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld),
                    heavyInspections: heavyInspections?.filter(r => r[field] === parseInt(id)),
                    heavyInspectionsToday: heavyInspectionsToday?.filter(r => r[field] === parseInt(id)),
                    heavyTasks: heavyTasks?.filter(r => r[field] === parseInt(id)),
                    heavyTasksToday: heavyTasksToday?.filter(r => r[field] === parseInt(id)),
                    usersMissingInspections: usersMissingInspections?.filter(r => r[field] === parseInt(id)),
                    usersMissingInspectionsToday: usersMissingInspectionsToday?.filter(r => r[field] === parseInt(id)),
                    otherInspections: otherInspections?.filter(r => r[field] === parseInt(id)),
                    otherInspectionsToday: otherInspectionsToday?.filter(r => r[field] === parseInt(id)),
                    otherTasks: otherTasks?.filter(r => r[field] === parseInt(id)),
                    otherTasksToday: otherTasksToday?.filter(r => r[field] === parseInt(id)),
                    allUsers: allUsers.filter(r => r[field] === parseInt(id)),
                    activeUsers: activeUsers.filter(r => r[field] === parseInt(id)),
                    allUnits: allUnits?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld),
                    activeUnits: activeUnits?.filter(r => (field === 'yard_id' ? r.yard : r.job) === fld)
                }
            } else {
                myData = {
                    totalInspections: totalInspections,
                    inspectionsToday: inspectionsToday,
                    totalTasks: totalTasks,
                    tasksToday: tasksToday,
                    dotInspections: dotInspections,
                    dotInspectionsToday: dotInspectionsToday,
                    dotTasks: dotTasks,
                    dotTasksToday: dotTasksToday,
                    unitsMissingInspections: unitsMissingInspections,
                    unitsMissingInspectionsToday: unitsMissingInspectionsToday,
                    dotUnitsMissingInspections: dotUnitsMissingInspections,
                    dotUnitsMissingInspectionsToday: dotUnitsMissingInspectionsToday,
                    heavyInspections: heavyInspections,
                    heavyInspectionsToday: heavyInspectionsToday,
                    heavyTasks: heavyTasks,
                    heavyTasksToday: heavyTasksToday,
                    usersMissingInspections: usersMissingInspections,
                    usersMissingInspectionsToday: usersMissingInspectionsToday,
                    otherInspections: otherInspections,
                    otherInspectionsToday: otherInspectionsToday,
                    otherTasks: otherTasks,
                    otherTasksToday: otherTasksToday,
                    allUsers: allUsers,
                    activeUsers: activeUsers,
                    allUnits: allUnits,
                    activeUnits: activeUnits
                }
            }
            setState({
                ...myData,
                yard_id: field === 'clear' ? 'All' : field === 'yard_id' ? id : !isNaN(parseInt(id)) ? 'All' : state.yard_id,
                job_id: field === 'clear' ? 'All' : field === 'job_id' ? id : !isNaN(parseInt(id)) ? 'All' : state.job_id
            });
        }

    }

    const changeYard = (id) => {
        changeValue('yard_id', id);
    };

    const changeJob = (id) => {
        changeValue('job_id', id);
    };

    const {totalInspections, inspectionsToday, totalTasks, tasksToday, dotInspections, dotInspectionsToday, dotTasks, dotTasksToday, yard_id, job_id,
        unitsMissingInspections, unitsMissingInspectionsToday, dotUnitsMissingInspections, dotUnitsMissingInspectionsToday, heavyInspectionsToday, heavyInspections,
        heavyTasksToday, heavyTasks, usersMissingInspectionsToday, usersMissingInspections, otherInspectionsToday, otherInspections, otherTasksToday, otherTasks,
        allUnits, last_loaded
    } = state;

    const clearFilters = () => {
        changeValue('clear');
    };

    const refreshData = () => {
        getKpis();
    };

    return(
        <Col ref={ref} sm={12} style={{paddingLeft: '0', paddingRight: '0'}}>
            <Modal size={'xl'} centered isOpen={state.showMyModal} toggle={hideModal}>
                <ModalHeader style={{textAlign: 'center !important'}} toggle={hideModal}>
                    {state.modalTitle}
                </ModalHeader>
                <ModalBody>
                    {state.modalBody ? state.modalBody : 'This will hold the details...'}
                </ModalBody>
                <ModalFooter>
                    <Button color={'texts'} onClick={() => hideModal()}>Close</Button>
                </ModalFooter>
            </Modal>
            <Card block body className={'kpi-main-card'}>
                <Row style={{justifyContent: 'flex-end', paddingRight: '15px', paddingLeft: '15px'}}>
                    <Col sm={12}>
                        <Col sm={6}/>
                            <Row style={{justifyContent: 'flex-end'}}>
                                <div className={'dashboard-report-lst-run-lbl-container'}>
                                    <Label className={'dashboard-report-lst-run-lbl1'}><span className={'text'}>Last loaded: </span></Label><Label className={'dashboard-report-lst-run-lbl2'}>{last_loaded ? last_loaded : (report_id && (report_id !== 'Now')) ? Reps.filter(r => r.id === report_id)[0].to_char : 'None Loaded'}</Label>
                                </div>
                                <ButtonGroup role={'group'} className={'dashboard-btn-group'}>
                                    <Button outline color={'texts'} onClick={() => refreshData()}>Refresh</Button>
                                    <Button className={`wo-dashboard-clr ${(
                                        (yard_id !== null && yard_id !== 'All')
                                        ||
                                        (job_id !== null && job_id !== 'All')
                                        ||
                                        (report_id !== undefined && report_id !== null && report_id !== 'Now'))
                                        ? 'active' : '' }`}
                                            onClick={() => clearFilters()}
                                            color={'texts'} outline>Clear Filters</Button>
                                    <ButtonDropdown className={'wo-dashboard-dropdown nav-item'} direction={'up'} isOpen={state.exportOpen} toggle={() => setState({exportOpen: !state.exportOpen})}>
                                        <DropdownToggle color={'texts'} outline caret>
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem color={'texts'} outline onClick={() => {
                                                exportComponentAsPDF(pRef, {fileName: 'dashboard_scorecard.pdf', pdfOptions: {unit: 'px', orientation: 'l', w: 600, h: 400, x: 15, y: 25}}).then();
                                                setState({exportOpen: !state.exportOpen});
                                            }}>
                                                PDF
                                            </DropdownItem>
                                            <DropdownItem color={'texts'} outline onClick={() => {
                                                exportComponentAsJPEG(pRef, {fileName: 'dashboard_scorecard.jpg'}).then();
                                                setState({exportOpen: !state.exportOpen});
                                            }}>
                                                JPEG
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                            </Row>
                    </Col>
                </Row>
                <div ref={pRef} style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                    <Row style={{justifyContent: 'flex-end', paddingRight: '15px'}}>
                        <Col sm={4}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText className={`ins-dashboard-selection ${(yard_id !== null && yard_id !== 'All') ? 'selected' : ''}`}>
                                        Select Yard
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id={'rep-history1'} type={'select'} value={yard_id}
                                       onChange={(e) => changeYard(e.target.value)}>
                                    <option value={'All'}>All</option>
                                    {yards?.map(r => <option value={r.id}>{r.name}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText className={`ins-dashboard-selection ${(job_id !== null && job_id !== 'All') ? 'selected' : ''}`}>
                                        Select Job
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id={'rep-history2'} type={'select'} value={job_id}
                                       onChange={(e) => changeJob(e.target.value)}>
                                    <option value={'All'}>All</option>
                                    {jobs?.map(r => <option value={r.id}>{r.name}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <Row>
                                <InputGroup inline>
                                    <InputGroupAddon addonType={'prepend'}>
                                        <InputGroupText>Report Date</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type={'text'} value={report_id && (report_id !== 'Now') ? Reps.filter(r => r.id === report_id)[0].to_char : last_loaded} disabled={true}/>
                                </InputGroup>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <ReduxBlockUi blocking={loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-1`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Total Inspections <span  className={'link-label-2'}
                                                    onClick={() => showModal(inspectionsToday, 'total_inspections_today', 'Total Inspections Today', 'log')}
                                                >
                                                         {inspectionsToday?.length ?? 0}
                                                     </span> / <span className={'link-label-2'}
                                                                     onClick={() => showModal(totalInspections, 'total_inspections_this_month', 'Total Inspections This Month', 'log')}
                                                >
                                                         {totalInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    New Issues <span className={'link-label-2'}
                                                                     onClick={() => showModal(tasksToday, 'total_tasks_today', 'Total Tasks Today', 'ta')}
                                                >
                                                    {tasksToday?.length ?? 0}
                                                </span> / <span className={'link-label-2'}
                                                                onClick={() => showModal(totalTasks, 'total_tasks_this_month', 'Total Tasks This Month', 'ta')}
                                                >
                                                    {totalTasks?.length ?? 0}
                                                </span>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-2`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Equip. Exceptions <span  className={'link-label-2'}
                                                                               onClick={() => showModal(unitsMissingInspectionsToday, 'equipment_missing_inspections_today', 'Equipment Missing Inspections Today', 'un')}
                                                >
                                                         {unitsMissingInspectionsToday?.length ?? 0}
                                                     </span> / <span  className={'link-label-2'}
                                                                      onClick={() => showModal(unitsMissingInspections, 'equipment_missing_inspections_this_month', 'Equipment Missing Inspections This Month', 'un')}
                                                >
                                                         {unitsMissingInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    D.O.T. <span className={'link-label-2'}
                                                                         onClick={() => showModal(dotUnitsMissingInspectionsToday, 'dot_equipment_missing_inspections_today', 'D.O.T. Equipment Missing Inspections Today', 'un')}>
                                                    {dotUnitsMissingInspectionsToday?.length ?? 0}
                                                </span> / <span className={'link-label-2'}
                                                                onClick={() => showModal(dotUnitsMissingInspections, 'dot_equipment_missing_inspections_this_month', 'D.O.T. Equipment Missing Inspections This Month', 'un')}>
                                                    {dotUnitsMissingInspections?.length ?? 0}
                                                </span>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-3`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Oper. Exceptions <span  className={'link-label-2'}
                                                                            onClick={() => showModal(usersMissingInspectionsToday, 'users_missing_inspections_today', 'Users Missing Inspections Today', 'us')}
                                                >
                                                         {usersMissingInspectionsToday?.length ?? 0}
                                                     </span> / <span  className={'link-label-2'}
                                                                      onClick={() => showModal(usersMissingInspections, 'users_missing_inspections_this_month', 'Users Missing Inspections This Month', 'us')}
                                                >
                                                         {usersMissingInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-4`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    D.O.T. Inspections <span  className={'link-label-2'}
                                                                              onClick={() => showModal(dotInspectionsToday, 'dot_inspections_today', 'D.O.T. Inspections Today', 'log')}
                                                >
                                                         {dotInspectionsToday?.length ?? 0}
                                                     </span> / <span  className={'link-label-2'}
                                                                      onClick={() => showModal(dotInspections, 'dot_inspections_this_month', 'D.O.T. Inspections This Month', 'log')}
                                                >
                                                         {dotInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    New Issues <span className={'link-label-2'}
                                                                     onClick={() => showModal(dotTasksToday, 'dot_tasks_today', 'D.O.T. Tasks Today', 'ta')}>
                                                    {dotTasksToday?.length ?? 0}
                                                </span> / <span className={'link-label-2'}
                                                                onClick={() => showModal(dotTasks, 'dot_tasks_this_month', 'D.O.T. Tasks This Month', 'ta')}>
                                                    {dotTasks?.length ?? 0}
                                                </span>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-5`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Heavy Equip. <span  className={'link-label-2'}
                                                                        onClick={() => showModal(heavyInspectionsToday, 'heavy_equipment_inspections_today', 'Heavy Equipment Inspections Today', 'log')}
                                                >
                                                         {heavyInspectionsToday?.length ?? 0}
                                                     </span> / <span  className={'link-label-2'}
                                                                      onClick={() => showModal(dotInspectionsToday, 'heavy_equipment_inspections_today', 'Heavy Equipment Inspections Today', 'log')}
                                                >
                                                         {heavyInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    New Issues <span className={'link-label-2'}
                                                                     onClick={() => showModal(heavyTasksToday, 'heavy_equipment_tasks_today', 'Heavy Equipment Tasks Today', 'ta')}>
                                                    {heavyTasksToday?.length ?? 0}
                                                </span> / <span className={'link-label-2'}
                                                                onClick={() => showModal(heavyTasks, 'heavy_equipment_tasks_today', 'Heavy Equipment Tasks Today', 'ta')}>
                                                    {heavyTasks?.length ?? 0}
                                                </span>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ins-6`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    All Other Equip. <span  className={'link-label-2'}
                                                                            onClick={() => showModal(otherInspectionsToday, 'other_equipment_inspections_today', 'Other Equipment Inspections Today', 'log')}
                                                >
                                                         {otherInspectionsToday?.length ?? 0}
                                                     </span> / <span  className={'link-label-2'}
                                                                      onClick={() => showModal(otherInspections, 'other_equipment_inspections_today', 'Other Equipment Inspections Today', 'log')}
                                                >
                                                         {otherInspections?.length ?? 0}
                                                     </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    New Issues <span className={'link-label-2'}
                                                                     onClick={() => showModal(otherTasksToday, 'other_equipment_tasks_today', 'Other Equipment Tasks Today', 'ta')}>
                                                    {otherTasksToday?.length ?? 0}
                                                </span> / <span className={'link-label-2'}
                                                                onClick={() => showModal(otherTasks, 'other_equipment_tasks_today', 'Other Equipment Tasks Today', 'ta')}>
                                                    {otherTasks?.length ?? 0}
                                                </span>
                                                </CardText>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className={'ticker-wrapper'}>
                                <div className={'ticker'}>
                                    <div className={'ticker-item'}>
                                        Active GPS Equipment: {allUnits?.length ?? 0}
                                    </div>
                                    <div className={'ticker-item'}>
                                        Active D.O.T. GPS Equip: {allUnits?.filter(u => u.dot === true).length ?? 0}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </ReduxBlockUi>
                </div>
            </Card>
        </Col>
    )
});

// noinspection JSValidateTypes
InspectionPerformanceDashboard.propTypes = propTypes;
export default InspectionPerformanceDashboard;

/** @namespace inspection_data **/
/** @namespace inspection_data.inspections_mtd **/
/** @namespace inspection_data.inspections_today **/
/** @namespace dot_inspections_mtd **/
/** @namespace dot_inspections_today **/
/** @namespace heavy_equipment_inspections_mtd **/
/** @namespace heavy_equipment_inspections_today **/
/** @namespace all_other_inspections_mtd **/
/** @namespace all_other_inspections_today **/
/** @namespace task_data **/
/** @namespace tasks_mtd **/
/** @namespace tasks_today **/
/** @namespace dot_tasks_mtd**/
/** @namespace dot_tasks_today **/
/** @namespace all_other_tasks_today **/
/** @namespace all_other_tasks_mtd **/
/** @namespace myReport.missing_equipment_data **/
/** @namespace missing_equipment_data.missing_this_month **/
/** @namespace missing_equipment_data.missing_today **/
/** @namespace missing_today **/
/** @namespace myReport.missing_operator_data **/
/** @namespace myReport.all_active_users **/
/** @namespace all_active_users.users **/
/** @namespace myReport.all_active_users.active_users **/
/** @namespace myReport.all_active_units **/
/** @namespace myReport.inspection_data **/
/** @namespace myReport.task_data.tasks_today **/
/** @namespace myReport.task_data.tasks_mtd **/
