/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {faultActions} from '../../network/actions';
import {Button, Row, Col, Card, CardHeader, CardBody, CardFooter} from '../../components/bot-components';
import Select from 'react-select';
// import {categoryActions} from '../../network/actions';
// import {actionActions} from '../../network/actions';

const defaultProps = {};
let currentCategories = [];
let currentActions = [];
let categoryList = [];
let actionList = [];
const FaultDetail = (props) => {
    const {fault, getActions} = props;
    const [state, updateState] = React.useState({
        code: '',
        description: '',
        selectedCategories: [],
        selectedActions: [],
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const updateFault = () => {
        let cats = [];
        let acts = [];
        state.selectedCategories.map(cat => cats.push(cat.value));
        state.selectedActions.map(act => acts.push(act.value));
        const Fault = {
            id: props.fault?.items?.id,
            categories: cats,
            actions: acts,
        };
        props.updateFault(Fault);
        setState({active: true});
    };
    React.useEffect(() => {
        props.getCategories();
        getActions();
        localStorage.setItem('lastComp', props.history?.location?.pathname);
    }, []);
    React.useEffect(() => {
        if (fault.items) {
            currentActions = [];
            currentCategories = [];
            let selectedActions = [];
            let selectedCategories = [];
            setState({
                code: fault.items.code,
                description: fault.items.description,
            });
            if (fault.items.categories) {
                const Faults = fault.items.categories.map(function (category) {
                    selectedCategories.push({label: category.name, value: category.id});
                    return currentCategories.push(<li>{category.name}</li>)
                });
                Promise.all(Faults).then(function () {
                    setState({
                        selectedCategories: selectedCategories,
                    });
                });
            }
            if (fault.items.actions) {
                const Faults = fault.items.actions.map(function (action) {
                    selectedActions.push({label: action.name, value: action.id});
                    return currentActions.push(<li>{action.name}</li>)
                });
                Promise.all(Faults).then(function () {
                    setState({
                        selectedActions: selectedActions,
                    });
                });
            }
        }
    }, [props.fault]);
    const {code, description, selectedCategories, selectedActions} = state;
    return(
        <div>
            <Row>
                <Col sm={'8'}>
                    <Col sm={'12'}>
                        <Card>
                            <CardHeader>
                                <h3>{code + ' -- ' + description}</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={'4'}>
                                        <Card>
                                            <CardHeader>
                                                <h4>Current Categories</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <ul>
                                                    {currentCategories}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm={'6'}>
                                        <Card>
                                            <CardHeader>
                                                <h4>Current Actions</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <ul>
                                                    {currentActions}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Col>
                <Col sm={'4'}>
                    <Card>
                        <CardHeader>
                            <h3>Assign Code</h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={'12'}>
                                    <Card>
                                        <CardHeader>
                                            <h3>Category</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Select multi={true} options={categoryList} placeholder={'Please select Category from list'}
                                            matchProp={'label'} onChange={(selectedCategories) => setState({selectedCategories: selectedCategories})}
                                            value={selectedCategories}/>
                                        </CardBody>
                                        <CardFooter>
                                            <Button outline color={'primary'} disabled={!selectedCategories} onClick={() => {updateFault()}}>Save</Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={'12'}>
                                    <Card>
                                        <CardHeader>
                                            <h3>Actions</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Select multi={true} options={actionList} placeholder={'Please select Action from list'}
                                            matchProp={'label'} onChange={(selectedActions) => setState({selectedActions: selectedActions})}
                                            value={selectedActions}/>
                                        </CardBody>
                                        <CardFooter>
                                            <Button outline color={'primary'} disabled={!selectedActions} onClick={() => {updateFault()}}>Save</Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
FaultDetail.defaultProps = defaultProps;
const mapStateToProps = (state) => {
    const {fault, categories, actions} = state;
    if (categories?.items) {
        categoryList = [];
        for (let i = 0; i < categories.items.length; i ++) {
            const cat_id = categories.items[i].id;
            const cat_name = categories.items[i].name;
            categoryList.push({label: cat_name, value: cat_id});
        }
    }
    if (actions?.items) {
        actionList = [];
        for (let i = 0; i < actions.items.length; i ++) {
            const act_id = actions.items[i].id;
            const act_name = actions.items[i].name;
            actionList.push({label: act_name, value: act_id});
        }
    }
    return {fault, categories, actions};
};
const mapDispatchToProps = (dispatch) => {
    return {
        // getCategories: () => dispatch(categoryActions.getCategories()),
        getCategories: () => console.log('get em'),
        // getActions: () => dispatch(actionActions.getActions()),
        getActions: () => console.log('get em'),
        getFault: (id) => dispatch(faultActions.getFault(id)),
        updateFault: (fault) => dispatch(faultActions.updateFault(fault)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FaultDetail);
