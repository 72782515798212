import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const vendorService = {};

const apiUrl = baseUrl;

vendorService.createVendor = (vendor) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(vendor)
    };

    return fetch(`${apiUrl}/vendor`, requestOptions).then(handleResponse);
};

vendorService.updateVendor = (vendor) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(vendor)
    };

    return fetch(`${apiUrl}/vendor/` + vendor.id, requestOptions).then(handleResponse);
};

vendorService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/vendors`, requestOptions).then(handleResponse)
};

vendorService.getVendor = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/vendor/` + id, requestOptions).then(handleResponse);
};
