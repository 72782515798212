export const userPreferenceConstants = {
    CREATE_USER_PREFERENCE_REQUEST: 'USER_PREFERENCE_CREATE_REQUEST',
    CREATE_USER_PREFERENCE_SUCCESS: 'USER_PREFERENCE_CREATE_SUCCESS',
    CREATE_USER_PREFERENCE_FAILURE: 'USER_PREFERENCE_CREATE_FAILURE',

    GET_ALL_USER_PREFERENCES_REQUEST: 'USER_PREFERENCES_GET_ALL_REQUEST',
    GET_ALL_USER_PREFERENCES_SUCCESS: 'USER_PREFERENCES_GET_ALL_SUCCESS',
    GET_ALL_USER_PREFERENCES_FAILURE: 'USER_PREFERENCES_GET_ALL_FAILURE',

    DETAIL_USER_PREFERENCE_REQUEST: 'USER_PREFERENCE_DETAIL_REQUEST',
    DETAIL_USER_PREFERENCE_SUCCESS: 'USER_PREFERENCE_DETAIL_SUCCESS',
    DETAIL_USER_PREFERENCE_FAILURE: 'USER_PREFERENCE_DETAIL_FAILURE',

    UPDATE_USER_PREFERENCE_REQUEST: 'USER_PREFERENCE_UPDATE_REQUEST',
    UPDATE_USER_PREFERENCE_SUCCESS: 'USER_PREFERENCE_UPDATE_SUCCESS',
    UPDATE_USER_PREFERENCE_FAILURE: 'USER_PREFERENCE_UPDATE_FAILURE',
};
