export const dataPlateConstants = {
    CREATE_DATA_PLATE_REQUEST: 'DATA_PLATE_CREATE_REQUEST',
    CREATE_DATA_PLATE_SUCCESS: 'DATA_PLATE_CREATE_SUCCESS',
    CREATE_DATA_PLATE_FAILURE: 'DATA_PLATE_CREATE_FAILURE',

    UPDATE_DATA_PLATE_REQUEST: 'DATA_PLATE_UPDATE_REQUEST',
    UPDATE_DATA_PLATE_SUCCESS: 'DATA_PLATE_UPDATE_SUCCESS',
    UPDATE_DATA_PLATE_FAILURE: 'DATA_PLATE_UPDATE_FAILURE',

    GET_ALL_DATA_PLATES_REQUEST: 'DATA_PLATES_GET_ALL_REQUEST',
    GET_ALL_DATA_PLATES_SUCCESS: 'DATA_PLATES_GET_ALL_SUCCESS',
    GET_ALL_DATA_PLATES_FAILURE: 'DATA_PLATES_GET_ALL_FAILURE',

    DETAIL_DATA_PLATE_REQUEST: 'DATA_PLATE_DETAIL_REQUEST',
    DETAIL_DATA_PLATE_SUCCESS: 'DATA_PLATE_DETAIL_SUCCESS',
    DETAIL_DATA_PLATE_FAILURE: 'DATA_PLATE_DETAIL_FAILURE',
};
