import {commentConstants} from '../constants';

export const comments = (state = {}, action) => {
    switch (action.type) {
        case commentConstants.GET_ALL_COMMENTS_REQUEST:
            return {
                loading: true
            };
        case commentConstants.GET_ALL_COMMENTS_SUCCESS:
            return {
                items: action.comments
            };
        case commentConstants.GET_ALL_COMMENTS_FAILURE:
            return {
                error: action.error
            };
        case commentConstants.CREATE_COMMENT_REQUEST:
            return {
                loading: true
            };
        case commentConstants.CREATE_COMMENT_SUCCESS:
            return {
                items: action.comments
            };
        case commentConstants.CREATE_COMMENT_FAILURE:
            return {
                error: action.error
            };
        case commentConstants.UPDATE_COMMENT_REQUEST:
            return {
                loading: true
            };
        case commentConstants.UPDATE_COMMENT_SUCCESS:
            return {
                items: action.comments
            };
        case commentConstants.UPDATE_COMMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const comment = (state = {}, action) => {
    switch (action.type) {
        case commentConstants.DETAIL_COMMENT_REQUEST:
            return {
                loading: true
            };
        case commentConstants.DETAIL_COMMENT_SUCCESS:
            return {
                items: action.comment
            };
        case commentConstants.DETAIL_COMMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
