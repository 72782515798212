import React from "react";
import PropTypes from 'prop-types';
import {BotTable, DropdownItem} from '../../components/bot-components';
import {VendorEditor, GenericTextEditor, PoStatusEditor} from '../Editors';
import _ from 'lodash';
import CreatePO from './CreatePO';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    vendorList: PropTypes.array,
    poStatusList: PropTypes.array,
    columns: PropTypes.array,
    getPos: PropTypes.func,
    updatePo: PropTypes.func,
    createPo: PropTypes.func,
    workorder: PropTypes.object,
    canCreate: PropTypes.bool,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any
};
const defaultFilters = {
    number: '',
    vendor: '',
    amount: '',
    po_status: '',
    description: ''
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const BotPurchaseOrders = React.forwardRef(
    /**
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getPos
     * @param props.updatePo
     * @param props.columns
     * @param props.canCreate
     * @param props.createPo
     * @param props.showTitle
     * @param props.loading
     * @param props.csvUrl
     * @param props.csvFile
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
    const [state, updateState] = React.useState({
        ...defaultState,
        tblId: props.tblId,
        lStorage: props.lStorage,
        remote: props.remote,
        selectedRow: null,
        statusModal: false,
        selectedCell: null,
        vendorEditor: false,
        pageSizes: props.pageSizes,
        newModal: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    //<editor-fold desc="Column Refs">
    const numberRef = React.useRef();
    const vendorIdRef = React.useRef();
    const amountRef = React.useRef();
    const statusRef = React.useRef();
    const descriptionRef = React.useRef();
    //</editor-fold>
    const vRef = React.useRef();
    const aRef = React.useRef();
    const sRef = React.useRef();
    const dRef = React.useRef();

    const editNumber = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell,
            statusModal: true
        });
    };

    const editVendor = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell,
            vendorEditor: true
        });
    };

    const editAmount = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell
        });
        setTimeout(() => aRef?.current?.toggle());
    };

    const editStatus = (row) => {
        setState({
            selectedRow: row,
            statusModal: true
        });
    };

    const editDescription = (row, cell) => {
        setState({
            selectedRow: row,
            selectedCell: cell
        });
        setTimeout(() => dRef?.current?.toggle());
    };

    const toggleNewModal = () => {
        setState({
            newModal: !state.newModal
        });
    };

    const toggleStatusEditor = () => {
        setState({
            statusModal: !state.statusModal,
            selectedRow: null,
        });
    };

    const toggleVendorEditor = () => {
        setState({
            vendorEditor: false,
            selectedRow: null
        })
    };

    const refreshPos = () => {
        const tbl = ref?.current;
        const req = {
            sortName: tbl.state.sortName,
            sortOrder: tbl.state.sortOrder,
            currentPage: tbl.state.currPage,
            sizePerPage: tbl.state.currSize,
            filters: tbl.state.filters
        };
        props.remote ? props.getPos(req) : props.getPos();
    };

    const createNewBtn = () => {
        return(
            <DropdownItem outline color={'texts'} onClick={() => {
                toggleNewModal();
            }}><i className={'fa fa-plus'}/> New</DropdownItem>
        )
    };

    const updatePo = (po) => {
        props.updatePo(po);
        setState({
            selectedRow: null,
            selectedCell: null
        });
    };

        let {pageSizes, selectedRow, selectedCell, statusModal, vendorEditor} = state;
        let {title, data, workorder, vendorList, poStatusList} = props;
        const filters = defaultFilters;
        const columns = props.columns ? props.columns : [
            {
                field: 'number',
                text: 'PO Number(Assigned from purchasing',
                width: 300,
                filter: {
                    type: 'text',
                    defaultValue: filters.number,
                    placeholder: 'Enter PO Number'
                },
                hidden: false,
                sortable: true,
                editable: {
                    value: true,
                    onClick: (row, cell) => editNumber(row, cell)
                },
                refProp: numberRef
            },
            {
                field: 'vendor',
                text: 'Vendor',
                width: 350,
                filter: {
                    type: 'text',
                    placeholder: 'Enter Vendor name',
                    defaultValue: filters.vendor
                },
                hidden: false,
                sortable: true,
                editable: {
                    value: true,
                    onClick: (row, cell) => editVendor(row, cell)
                },
                refProp: vendorIdRef
            },
            {
                field: 'amount',
                text: 'Amount',
                width: 200,
                filter: {
                    type: 'text',
                    placeholder: 'Enter Amount',
                    defaultValue: filters.amount
                },
                hidden: false,
                sortable: true,
                editable: {
                    value: true,
                    onClick: (row, cell) => editAmount(row, cell)
                },
                refProp: amountRef
            },
            {
                field: 'po_status',
                text: 'Status',
                width: 250,
                filter: {
                    type: 'text',
                    placeholder: 'Enter Status',
                    defaultValue: filters.po_status
                },
                hidden: false,
                sortable: true,
                editable: {
                    value: true,
                    onClick: (row, cell) => editStatus(row, cell)
                },
                refProp: statusRef
            },
            {
                field: 'description',
                text: 'Description',
                width: 500,
                filter: {
                    type: 'text',
                    placeholder: 'Enter Description',
                    defaultValue: filters.description
                },
                hidden: false,
                sortable: true,
                editable: {
                    value: true,
                    onClick: (row, cell) => editDescription(row, cell)
                },
                refProp: descriptionRef
            }
        ];
        return(
            <div>
                {selectedRow && vendorList && <VendorEditor ref={vRef} isOpen={vendorEditor} toggle={() => toggleVendorEditor()} onUpdate={(po) => updatePo(po)} row={selectedRow} extraProps={vendorList}/>}
                {selectedRow && poStatusList && <PoStatusEditor ref={sRef} isOpen={statusModal} toggle={() => toggleStatusEditor()} row={selectedRow} onUpdate={(po) => updatePo(po)} poStatusList={poStatusList}/>}
                {selectedRow && <GenericTextEditor ref={aRef} onUpdate={(po) => updatePo(po)} row={selectedRow} cell={selectedCell}/>}
                {selectedRow && <GenericTextEditor ref={dRef} onUpdate={(po) => updatePo(po)} row={selectedRow} cell={selectedCell}/>}
                {vendorList && poStatusList && workorder.items &&
                <CreatePO toggle={() => toggleNewModal()} isOpen={state.newModal} workorder={workorder} vendorList={vendorList}
                          poStatusList={poStatusList} onSavePo={(po) => props.createPo(po)}/>}
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => props.getPos(req)}
                          defaultState={defaultState}
                          pageSizes={pageSizes}
                          ClearFiltersBtn={true}
                          ClearFiltersIcon={true}
                          ClearSortBtn={true}
                          ClearSortIcon={true}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          CreateNewBtn={props.canCreate && createNewBtn}
                          pagination={'both'}
                          headers={true}
                          doRowUpdate={(po) => updatePo(po)}
                          refreshData={() => refreshPos()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={filters}
                          showTitle={props.showTitle}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}/>
            </div>
        );
});

BotPurchaseOrders.propTypes = propTypes;
export default BotPurchaseOrders;
