import React from 'react';
import ReportDashboard from './ReportDashboard';
const Reports = () => {
    React.useEffect(() => {
        localStorage.setItem('lastComp', '/reports');
    }, []);

    return (
        <ReportDashboard/>
    );
};
export default Reports;
