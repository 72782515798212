/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSCheckFunctionSignatures

import React from 'react';
import {connect} from 'react-redux';
import {
    Button,
    CardBody,
    CardFooter,
    Col,
    CustomInput,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from '../../../components/bot-components';
import {
    _dateOnlyFormat,
    validateNullableNumber,
    validateNumber,
    validateUniqueSerialNum,
    validateUniqueUnitNum,
    validateUnitDescription,
    validateUnitNum,
    validateUnitType
} from '../../../helpers';
import {defaultState} from './UnitEdit.constants';
import * as funcs from './UnitEdit.helpers';

const UnitEdit = (props) => {
    const [state, updateState] = React.useState(defaultState);
    const setState = (newState) => (updateState((prevState) => ({
        ...prevState,
        ...newState
    })));

    React.useEffect(() => {
        const myUnit = props.unit?.items;
        myUnit?.unit_type_id && props.getUnitType(myUnit.unit_type_id);
        myUnit && setState({
            ...myUnit,
            unit_id: myUnit.id,
            selectedUnitType: myUnit.unit_type_id ? {label: myUnit.unit_type, value: myUnit.unit_type_id} : null,
            selectedUnitSubType: myUnit.unit_subtype_id ? {
                label: myUnit.unit_subtype,
                value: myUnit.unit_subtype_id
            } : null,
            selectedStatus: myUnit.unit_status_id ? {label: myUnit.unit_status, value: myUnit.unit_status_id} : null,
            selectedCompany: myUnit.company_id ? {label: myUnit.company, value: myUnit.company_id} : null,
            unit_number: myUnit.number,
            selectedSize: myUnit.tire_size_id ? {label: myUnit.tire_size, value: myUnit.tire_size_id} : null,
            selectedAssetType: myUnit.asset_type_id ? {label: myUnit.asset_type, value: myUnit.asset_type_id} : null,
            selectedSize2: myUnit.tire_size_2_id ? {label: myUnit.tire_size_2, value: myUnit.tire_size_2_id} : null,
            selectedSize3: myUnit.tire_size_3_id ? {label: myUnit.tire_size_3, value: myUnit.tire_size_3_id} : null,
            selectedSize4: myUnit.tire_size_4_id ? {label: myUnit.tire_size_4, value: myUnit.tire_size_4_id} : null,
        });
    }, [props.unit]);

    const toggleModal = () => {
        setState({
            showModal: !state.showModal
        });
    };
    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };
    const changeNumberProps = async (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value,
            [name.concat('_valid')]: await validateNullableNumber(value)
        });
    };
    const changeDot = () => {
        setState({
            dot: !state.dot
        });
    };
    const changeActive = () => {
        setState({
            active: !state.active
        });
    };
    const cancelInactive = () => {
        setState({
            active: true
        });
    };
    const changeHours = async (e) => {
        const {value} = e.target;
        setState({
            hours: value,
            hoursValid: await validateNumber(value)
        });
    };
    const changeMiles = async (e) => {
        const {value} = e.target;
        setState({
            mileage: value,
            milesValid: await validateNumber(value)
        });
    };
    const changeDescription = (e) => {
        const {value} = e.target;
        setState({
            description: value,
            descriptionValid: validateUnitDescription(value)
        });
    };
    const changeUnitNumber = (e) => {
        const {value} = e.target;
        const {pass, exists} = validateUnitNum(value, null, false);
        setState({
            unit_number: value,
            unitNumValid: pass,
            unitExists: exists
        });
    };
    const checkUniqueUnitNumber = async (e) => {
        const {value} = e.target;
        const {pass, exists} = await validateUniqueUnitNum(value, props.unit.items.id, true);
        setState({
            unit_number: value,
            unitNumValid: pass,
            unitNumExists: exists
        });
    };
    const checkUniqueSerialNumber = async (e) => {
        const {value} = e.target;
        const {pass, exists} = await validateUniqueSerialNum(value, props.unit.items.id, true);
        setState({
            vin_sn: value,
            unitSerialValid: pass,
            unitSerialExists: exists
        });
    };
    const changeUnitType = async (selectedUnitType) => {
        selectedUnitType && props.getUnitType(selectedUnitType.value);
        setState({
            unit_type_id: selectedUnitType?.value,
            selectedUnitType: selectedUnitType,
            typeValid: await validateUnitType(selectedUnitType?.value)
        });
    };
    const submitUnit = () => funcs.submitUnit(state, props);

    const {unit, unitSubtypes, unitStatuses, companyList, unitTypes, tireSizeList, assetTypeList} = props;
    const {
        unit_number,
        plate,
        vin_sn,
        registration_date,
        description,
        location,
        mileage,
        hours,
        job,
        yard,
        operator,
        cadr,
        dot,
        active,
        tare_weight,
        gross_weight,
        height,
        wheelbase,
        axles,
        width,
        overall_length,
        horsepower,
        showModal,
        hoursValid,
        milesValid,
        descriptionValid,
        typeValid,
        tare_weight_valid,
        gross_weight_valid,
        axles_valid,
        unitNumValid,
        unitSerialValid,
        unitNumExists,
        unitSerialExists
    } = state;
    let canSave = unitNumValid && descriptionValid && typeValid && hoursValid && milesValid && tare_weight_valid && gross_weight_valid && axles_valid && unitSerialValid && unitNumExists && unitSerialExists;

    return (
        <>
            <Modal isOpen={showModal} toggle={() => cancelInactive()}>
                <ModalHeader toggle={() => cancelInactive()}>
                    Deactivating equipment# {unit?.items?.number}!
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to deactivate this piece of equipment?
                    By doing this you effectively remove the equipment from use in the system.
                </ModalBody>
                <ModalFooter>
                    <Button block color={'danger'} onClick={() => {
                        toggleModal();
                    }}>Confirm</Button><Button block color={'success'} onClick={() => {
                    cancelInactive();
                }}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <CardBody style={{padding: 0}}>
                <Form>
                    <Row>
                        <Col sm={5}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment Status</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       onChange={selectedStatus => setState({
                                           unit_status_id: selectedStatus?.value,
                                           selectedStatus: selectedStatus
                                       })}
                                       value={state.selectedStatus}
                                       options={unitStatuses}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Job</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'job_id'} id={'job_id'} disabled={true} value={job}/>
                            </InputGroup>
                        </Col>
                        <Col sm={1}>
                            <InputGroup>
                                <CustomInput className={'unit_dot-switch'} checked={dot} onChange={changeDot}
                                             type="switch" id="unit-edit-dot" name="unit-edit-dot" label="D.O.T."/>
                            </InputGroup>
                        </Col>
                        <Col sm={2}>
                            <InputGroup>
                                <CustomInput className={'unit_active-switch'} checked={!active} onChange={changeActive}
                                             type="switch" id="unit_active" name="active" label="Inactive"/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr className={'page-divider'}/>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText>Description</InputGroupText>
                                </InputGroupAddon>
                                <Input type={'text'} name={'description'} id={'description'} invalid={!descriptionValid}
                                       value={description} onChange={changeDescription}>
                                </Input>
                            </InputGroup>
                            {!descriptionValid &&
                            <small style={{color: 'var(--red)'}}>*The description must be filled in to save the piece of
                                equipment.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Miles</InputGroupText></InputGroupAddon>
                                <Input type={'number'} name={'mileage'} id={'mileage'} invalid={!milesValid}
                                       onChange={changeMiles} value={mileage}>
                                </Input>
                            </InputGroup>
                            {!milesValid &&
                            <small style={{color: 'var(--red)'}}>*The miles must be between 0 and 99999999.99.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Asset
                                    Type</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'asset_type_id'}
                                       id={'asset_type_id'}
                                       onChange={selectedAssetType => setState({
                                           asset_type_id: selectedAssetType?.value,
                                           selectedAssetType: selectedAssetType
                                       })}
                                       value={state.selectedAssetType}
                                       options={assetTypeList}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Unit
                                    Number</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'unit_number'} id={'number'}
                                       invalid={!unitNumValid || !unitNumExists}
                                       value={unit_number} onChange={(e) => changeUnitNumber(e)} onBlur={(e) => {
                                    return checkUniqueUnitNumber(e);
                                }}>
                                </Input>
                            </InputGroup>
                            {!unitNumValid && unitNumExists &&
                            <small style={{color: 'var(--red)'}}>*You must enter a unit number for the piece of
                                equipment.</small>}
                            {unitNumValid && !unitNumExists &&
                            <small style={{color: 'var(--red)'}}>*The equipment number is already in use for another
                                piece of equipment.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Hours</InputGroupText></InputGroupAddon>
                                <Input type={'number'} name={'hours'} id={'hours'} invalid={!hoursValid}
                                       onChange={changeHours} value={hours}>
                                </Input>
                            </InputGroup>
                            {!hoursValid &&
                            <small style={{color: 'var(--red)'}}>*The hours must be between 0 and 99999999.99.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Tare
                                    Weight</InputGroupText></InputGroupAddon>
                                <Input type={'number'} name={'tare_weight'} id={'tare_weight'}
                                       invalid={!tare_weight_valid}
                                       onChange={changeNumberProps} value={tare_weight}>
                                </Input>
                            </InputGroup>
                            {!tare_weight_valid &&
                            <small style={{color: 'var(--red)'}}>*The tare weight must be empty or between 0 and
                                99999999.99.</small>}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Vin# or
                                    SN</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'vin_sn'} invalid={!unitSerialValid || !unitSerialExists}
                                       id={'vin_sn'} value={vin_sn}
                                       onChange={changeProps} onBlur={(e) => {
                                    return checkUniqueSerialNumber(e);
                                }}>
                                </Input>
                            </InputGroup>
                            {unitSerialValid && !unitSerialExists &&
                            <small style={{color: 'var(--red)'}}>*The serial number is already in use for another piece
                                of equipment.</small>}
                            {!unitSerialValid && !unitSerialExists &&
                            <small style={{color: 'var(--red)'}}>*The serial number must not be blank.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Distinct
                                    Location</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'location'} id={'location'} value={location}
                                       onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Gross
                                    Weight</InputGroupText></InputGroupAddon>
                                <Input type={'number'} name={'gross_weight'} id={'gross_weight'}
                                       invalid={!gross_weight_valid}
                                       onChange={changeNumberProps} value={gross_weight}>
                                </Input>
                            </InputGroup>
                            {!gross_weight_valid &&
                            <small style={{color: 'var(--red)'}}>*The gross weight must be empty between 0 and
                                99999999.99.</small>}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment
                                    Type</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'unit_type_id'}
                                       id={'unit_type_id'}
                                       onChange={selectedUnitType => changeUnitType(selectedUnitType)}
                                       value={state.selectedUnitType}
                                       options={unitTypes}/>
                            </InputGroup>
                            {!typeValid &&
                            <small style={{color: 'var(--red)'}}>*You must select a equipment type before creating a
                                piece of equipment.</small>}
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>CADR</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'cadr'} id={'cadr'} value={cadr}
                                       onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Height</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'height'} id={'height'} value={height}
                                       onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Equipment Class</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'unit_subtype_id'}
                                       id={'unit_subtype_id'}
                                       onChange={selectedUnitSubType => setState({
                                           unit_subtype_id: selectedUnitSubType?.value,
                                           selectedUnitSubType: selectedUnitSubType
                                       })}
                                       value={state.selectedUnitSubType}
                                       options={unitSubtypes}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire
                                    Size</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'tire_size_id'}
                                       id={'tire_size_id'}
                                       onChange={selectedSize => setState({
                                           tire_size_id: selectedSize?.value,
                                           selectedSize: selectedSize
                                       })}
                                       value={state.selectedSize}
                                       options={tireSizeList}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Wheelbase</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'wheelbase'} id={'wheelbase'} value={wheelbase}
                                       onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Company</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'company_id'}
                                       id={'company_id'}
                                       onChange={selectedCompany => setState({
                                           company_id: selectedCompany?.value,
                                           selectedCompany: selectedCompany
                                       })}
                                       value={state.selectedCompany}
                                       options={companyList}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size
                                    2</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'tire_size_2_id'}
                                       id={'tire_size_2_id'}
                                       onChange={selectedSize => setState({
                                           tire_size_2_id: selectedSize?.value,
                                           selectedSize2: selectedSize
                                       })}
                                       value={state.selectedSize2}
                                       options={tireSizeList}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Axles</InputGroupText></InputGroupAddon>
                                <Input type={'number'} name={'axles'} id={'axles'} invalid={!axles_valid}
                                       onChange={changeNumberProps} value={axles}>
                                </Input>
                            </InputGroup>
                            {!axles_valid &&
                            <small style={{color: 'var(--red)'}}>*The axles must be empty or between 0 and
                                99999999.99.</small>}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Plate</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'plate'} id={'plate'} value={plate}
                                       onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size
                                    3</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'tire_size_3_id'}
                                       id={'tire_size_3_id'}
                                       onChange={selectedSize => setState({
                                           tire_size_3_id: selectedSize?.value,
                                           selectedSize3: selectedSize
                                       })}
                                       value={state.selectedSize3}
                                       options={tireSizeList}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Overall
                                    Length</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'overall_length'} id={'overall_length'}
                                       value={overall_length} onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Registration
                                    Date</InputGroupText></InputGroupAddon>
                                <Input type={'date'} name={'registration_date'} id={'registration_date'}
                                       value={_dateOnlyFormat(registration_date)} onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>Tire Size
                                    4</InputGroupText></InputGroupAddon>
                                <Input type={'react-select'}
                                       name={'tire_size_4_id'}
                                       id={'tire_size_4_id'}
                                       onChange={selectedSize => setState({
                                           tire_size_4_id: selectedSize?.value,
                                           selectedSize4: selectedSize
                                       })}
                                       value={state.selectedSize4}
                                       options={tireSizeList}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Horsepower</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'horsepower'} id={'horsepower'}
                                       value={horsepower} onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType={'prepend'}><InputGroupText>LK
                                    Operator</InputGroupText></InputGroupAddon>
                                <Input type={'text'} disabled={true} value={operator}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Location</InputGroupText></InputGroupAddon>
                                <Input type={'text'} disabled={true} value={yard}/>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon
                                    addonType={'prepend'}><InputGroupText>Width</InputGroupText></InputGroupAddon>
                                <Input type={'text'} name={'width'} id={'width'}
                                       value={width} onChange={changeProps}>
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter className={'unit-edit-footer'}>
                <Button outline color={'texts'} style={{marginLeft: '.5rem'}} disabled={!canSave}
                        onClick={submitUnit}>Submit</Button>
                <Button outline color={'texts'} onClick={props.cancelUpdate}>Cancel</Button>
            </CardFooter>
        </>
    );
};

export default connect(funcs.mapStateToProps, funcs.mapDispatchToProps)(UnitEdit);
