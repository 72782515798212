import {taskConstants} from '../constants/';
import {taskService} from '../services/';
import {workorderActions} from './';

export const taskActions = {};
taskActions.getAll = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.GET_ALL_TASKS_REQUEST});
    const success = (tasks) => ({type: taskConstants.GET_ALL_TASKS_SUCCESS, tasks});
    const failure = (error) => ({type: taskConstants.GET_ALL_TASKS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.getAll(req)
            .then(tasks => dispatch(success(tasks)), error => dispatch(failure(error)));
    };
};
taskActions.getOpen = (req) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.GET_OPEN_TASKS_REQUEST});
    const success = (open_tasks) => ({type: taskConstants.GET_OPEN_TASKS_SUCCESS, open_tasks});
    const failure = (error) => ({type: taskConstants.GET_OPEN_TASKS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.getOpen(req)
            .then(open_tasks => dispatch(success(open_tasks)), error => dispatch(failure(error)));
    };
};
taskActions.getTask = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.DETAIL_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.DETAIL_TASK_SUCCESS, task});
    const failure = (error) => ({type: taskConstants.DETAIL_TASK_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.getDetail(id)
            .then(
                task => {
                    dispatch(success(task));
                    task && localStorage.setItem('task_id', task.id);
                }, error => dispatch(failure(error)));
    };
};
taskActions.createTask = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.CREATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.CREATE_TASK_SUCCESS, task});
    const failure = (error) => ({type: taskConstants.CREATE_TASK_FAILURE, error});
    //</editor-fold>
    const woId = task.workorder_id;
    return dispatch => {
        dispatch(request());
        taskService.createTask(task)
            .then(
                task => {
                    dispatch(success(task));
                    task && dispatch(workorderActions.getDetail(woId))
                }, error => dispatch(failure(error)));
    };
};
taskActions.assignToVendor = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.UPDATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.UPDATE_TASK_REQUEST, task});
    const failure = (error) => ({type: taskConstants.UPDATE_TASK_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.assignToVendor(task)
            .then(task => {
                dispatch(success(task));
                task && dispatch(taskActions.getTask(task.id));
                task && dispatch(workorderActions.getDetail(task.workorders[0].id))
            }, error => dispatch(failure(error)));
    };
};
taskActions.assignToMechanic = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.UPDATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.UPDATE_TASK_REQUEST, task});
    const failure = (error) => ({type: taskConstants.UPDATE_TASK_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.assignToMechanic(task)
            .then(task => {
                dispatch(success(task));
                task && dispatch(taskActions.getTask(task.id));
                task?.workorders && dispatch(workorderActions.getDetail(task.workorders[0].id))
            }, error => dispatch(failure(error)));
    };
};
taskActions.arrivedAtVendor = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.UPDATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.UPDATE_TASK_REQUEST, task});
    const failure = (error) => ({type: taskConstants.UPDATE_TASK_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.arrivedAtVendor(task)
            .then(task => {
                dispatch(success(task));
                task && dispatch(taskActions.getTask(task.id));
                task && dispatch(workorderActions.getDetail(task.workorders[0].id))
                }, error => dispatch(failure(error)));
    };
};
taskActions.pickup = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.UPDATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.UPDATE_TASK_REQUEST, task});
    const failure = (error) => ({type: taskConstants.UPDATE_TASK_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.pickup(task)
            .then(task => {
                dispatch(success(task));
                task && dispatch(taskActions.getTask(task.id));
                task && dispatch(workorderActions.getDetail(task.workorders[0].id))
            }, error => dispatch(failure(error)));
    };
};
taskActions.updateTask = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.UPDATE_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.UPDATE_TASK_REQUEST, task});
    const failure = (error) => ({type: taskConstants.UPDATE_TASK_REQUEST, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        taskService.updateTask(task)
            .then(task => {
                dispatch(success(task));
                task && dispatch(taskActions.setTask(task));
                task && localStorage.getItem('workorder_id') && dispatch(workorderActions.getDetail(localStorage.getItem('workorder_id')));
                }, error => dispatch(failure(error)));
    };
};
taskActions.setTask = (task) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: taskConstants.DETAIL_TASK_REQUEST});
    const success = (task) => ({type: taskConstants.DETAIL_TASK_SUCCESS, task});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dispatch(success(task));
    };
};
