import {messageConstants} from '../constants/';
import {messageService} from '../services/';
import {workorderActions} from './';

export const messageActions = {};
messageActions.createMessage = (message) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: messageConstants.CREATE_MESSAGE_REQUEST});
    const success = (message) => ({type: messageConstants.CREATE_MESSAGE_SUCCESS, message});
    const failure = (error) => ({type: messageConstants.CREATE_MESSAGE_FAILURE, error});
    //</editor-fold>
    const workorder_id = message.workorder_id;
    return dispatch => {
        dispatch(request());
        messageService.createMessage(message).then(message => {
            dispatch(success(message));
            dispatch(workorderActions.getDetail(workorder_id));
            }, error => dispatch(failure(error)));
    };
};
