/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText, CardFooter
} from '../../../components/bot-components';
import {_dateFormat} from "../../../helpers";
import {propTypes} from './BotWorkOrderEdit.constants';
import {saveWO} from './BotWorkOrderEdit.helpers';

const WorkOrderEdit = (props) => {
    const [state, updateState] = React.useState({
        ...props.myWo,
        valid_general_status: props.myWo.general_status?.length < 50,
        dirty: false,
        confirmModal: false
    });
    const setState = newState => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));

    React.useEffect(() => {
        props.getMechanics();
        props.getVendors();
        props.getWorkorderStatuses();
        props.getUnitStatuses();
        props.getTaskPriorities();
    }, []);

    React.useEffect(() => {
        setState({
            ...props.myWo,
            selectedStatus: props.myWo?.unit?.unit_status ? {value: props.myWo.unit.unit_status.id, label: props.myWo.unit.unit_status.status} : null,
            selectedMech: props.myWo?.mechanic ? {value: props.myWo.mechanic.id, label: props.myWo.mechanic.name} : null,
            selectedVendor: props.myWo?.vendor ? {value: props.myWo.vendor.id, label: props.myWo.vendor.name} : null,
            selectedYard: props.myWo?.yard ? {value: props.myWo.yard_id, label: props.myWo.yard.name} : null,
            valid_general_status: props.myWo.general_status.length < 50,
            unit_status_id: props.myWo?.unit?.unit_status?.id ?? null,
            dirty: false
        });
    }, [props.myWo]);

    const setBriefDescription = (description) => {
        setState({
            other_description: description,
            dirty: true
        });
    };

    const setEstComplete = (value) => {
        setState({
            est_complete_date: value,
            dirty: true
        })
    };

    const cancelAll = () => {
        if (state.dirty === false) {
            props.cancelUpdate();
        } else {
            setState({confirmModal: true});
        }
    }

    const saveWorkOrder = () => saveWO(props, state);

    const changeProps = (e) => {
        const {value, name} = e.target;
        switch (name) {
            case 'brief_description':
                setBriefDescription(value);
                break;
            case 'wo_location':
                setState({yard_id: value});
                break;
            case 'est_complete_date':
                setEstComplete(value);
                break;
            case 'general_status':
                setState({
                    general_status: value,
                    valid_general_status: value.length < 50
                });
                break;
            default:
                return;
        }
    };
    const {myWo, mechanicList, vendList, unit_statusList, yardList} = props;

    return (
        <Card style={{overflow: 'visible !important'}}>
            <Modal isOpen={state.confirmModal}>
                <ModalHeader>
                    Leave without saving?
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to leave without saving your changes?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setState({dirty: false}); cancelAll();
                    }}>Yes</Button><Button onClick={() => setState({confirmModal: false})}>No</Button>
                </ModalFooter>
            </Modal>
            <CardBody style={{overflow: 'visible !important'}}>
                <Row>
                    <Col sm={'4'}>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Work Order#
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={state.id}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'} >
                                <InputGroupText>
                                    Date Created
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={_dateFormat(state.created_at)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    WO Status
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={state.workorder_status}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Priority
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={state.workorder_priority}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Mechanic
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'react-select'} options={mechanicList}
                                   value={state.selectedMech ?? ({label: 'Please select a mechanic from the list'})}
                                   onChange={(selectedMech) => setState({selectedMech: selectedMech, dirty: true, mechanic_id: selectedMech?.value ?? null})}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Service Provider
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'react-select'} options={vendList}
                                   value={state.selectedVendor ?? ({label: 'Please select a service provider'})}
                                   onChange={(selectedVendor) => setState({selectedVendor: selectedVendor, vendor_id: selectedVendor?.value ?? null, dirty: true})}/>
                        </InputGroup>
                    </Col>
                    <Col sm={'4'}>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Description
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={state.unit?.description}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Equipment Status
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'react-select'} options={unit_statusList}
                                   value={state.selectedStatus ?? {label: 'Please select an equipment status'}}
                                   onChange={(selectedStatus) => setState({
                                       unit_status_id: selectedStatus?.value ?? null,
                                       selectedStatus: selectedStatus
                                   })}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Brief Desc.
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'textarea'} value={state.other_description} onChange={changeProps} name={'brief_description'}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Location
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'react-select'} options={yardList}
                                   value={state.selectedYard ?? {label: 'Please select a location'}}
                                   onChange={(selectedYard) => setState({
                                           yard_id: selectedYard?.value,
                                           selectedYard: selectedYard,
                                           dirty: true
                                       })}/>
                        </InputGroup>
                    </Col>
                    {myWo && <Col sm={'4'}>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Est. Completion Date
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'date'} value={state.other_description} onChange={changeProps} name={'est_complete_date'}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Arrived At Vendor
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={_dateFormat(state.arrived_at_vendor)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Picked Up On
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={_dateFormat(state.picked_up_at)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Completed On
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type={'text'} disabled value={_dateFormat(myWo['completed_at'])}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}>
                                <InputGroupText>
                                    Status Notes
                                </InputGroupText>
                            </InputGroupAddon>
                                <Input type={'textarea'} value={state.general_status}
                                       name={'general_status'} onChange={changeProps}
                                       style={{borderColor: !state.valid_general_status && 'var(--red)'}}/>
                        </InputGroup>
                        {!state.valid_general_status &&
                        <small style={{color: 'var(--red)'}}>*Status must be 50 characters or less.</small>}
                    </Col>}
                </Row>
            </CardBody>
            <CardFooter className={'user-edit-footer'}>
                <Button color={'texts'} outline onClick={() => saveWorkOrder()}>Save</Button><Button color={'texts'} outline onClick={() => cancelAll()}>Cancel</Button>
            </CardFooter>
        </Card>
    );
};

WorkOrderEdit.propTypes = propTypes;
export default WorkOrderEdit;

