import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { mapToCssModules, deprecated, tagPropType } from './utils';
const propTypes = {
    className: PropTypes.string,
    cssModule: PropTypes.object,
    size: PropTypes.string,
    bordered: PropTypes.bool,
    borderless: PropTypes.bool,
    striped: PropTypes.bool,
    inverse: deprecated(PropTypes.bool, 'Please use the prop "dark"'),
    dark: PropTypes.bool,
    hover: PropTypes.bool,
    responsive: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    tag: tagPropType,
    responsiveTag: tagPropType,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
};
const defaultProps = {
    tag: 'bot-tbody',
    responsiveTag: 'div',
};
class BotTableBody extends React.Component {
    render() {
        const {
            className,
            cssModule,
            size,
            bordered,
            borderless,
            striped,
            inverse,
            dark,
            hover,
            responsive,
            tag: Tag,
            responsiveTag: ResponsiveTag,
            innerRef,
            ...attributes
        } = this.props;

        const classes = mapToCssModules(classNames(
            className,
            'bot-tbody',
            size ? 'bot-tbody-' + size : false,
            bordered ? 'bot-tbody-bordered' : false,
            borderless ? 'bot-tbody-borderless' : false,
            striped ? 'bot-tbody-striped' : false,
            (dark || inverse) ? 'bot-tbody-dark' : false,
            hover ? 'bot-tbody-hover' : false,
        ), cssModule);

        return (<Tag {...attributes} ref={innerRef} className={classes} />);
    }
}
BotTableBody.propTypes = propTypes;
BotTableBody.defaultProps = defaultProps;
export default BotTableBody;
customElements.define('bot-tbody', BotTableBody, {extends: 'div'});
