export const plmAlertConstants = {

    GET_ALL_PLM_ALERTS_REQUEST: 'PLM_ALERTS_GET_ALL_REQUEST',
    GET_ALL_PLM_ALERTS_SUCCESS: 'PLM_ALERTS_GET_ALL_SUCCESS',
    GET_ALL_PLM_ALERTS_FAILURE: 'PLM_ALERTS_GET_ALL_FAILURE',

    DETAIL_PLM_ALERT_REQUEST: 'PLM_ALERT_DETAIL_REQUEST',
    DETAIL_PLM_ALERT_SUCCESS: 'PLM_ALERT_DETAIL_SUCCESS',
    DETAIL_PLM_ALERT_FAILURE: 'PLM_ALERT_DETAIL_FAILURE',
};
