import {createHashHistory} from 'history';

export const history = createHashHistory();
export const blockNavigation = (history, dirty, uri) => unlock(history, dirty, uri) && blockReload(history, dirty);

const getConfirmation = () => window.confirm("Are you sure you want to leave without saving your changes?");

const handleBlock = (e) => e.preventDefault() && getConfirmation() ? e : '';

const blockReload = (history, dirty) => window.onbeforeunload = dirty ? (e) => handleBlock(e) : undefined;

const unlock = (history, dirty, uri) => history.block(tx => dirty && tx.pathname !== uri ? getConfirmation() : true);
