import {workorderStatusConstants} from '../constants';

export const workorder_statuses = (state = {}, action) => {
    switch (action.type) {
        case workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_REQUEST:
            return {
                loading: true
            };
        case workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_SUCCESS:
            return {
                items: action.workorder_statuses
            };
        case workorderStatusConstants.GET_ALL_WORKORDER_STATUSES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};
export const workorder_status = (state = {}, action) => {
    switch (action.type) {
        case workorderStatusConstants.CREATE_WORKORDER_STATUS_REQUEST:
            return {
                loading: true
            };
        case workorderStatusConstants.CREATE_WORKORDER_STATUS_SUCCESS:
            return {
                items: action.workorder_status
            };
        case workorderStatusConstants.CREATE_WORKORDER_STATUS_FAILURE:
            return {
                error: action.error
            };
        case workorderStatusConstants.DETAIL_WORKORDER_STATUS_REQUEST:
            return {
                loading: true
            };
        case workorderStatusConstants.DETAIL_WORKORDER_STATUS_SUCCESS:
            return {
                items: action.workorder_status
            };
        case workorderStatusConstants.DETAIL_WORKORDER_STATUS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
