import React from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav} from '../components/bot-components';
import PropTypes from 'prop-types';
import {AppAsideToggler, AppHeaderDropdown} from '../components/index';
import {connect} from 'react-redux';
import {userActions, userPreferenceActions} from "../network/actions";
import {history} from '../helpers/';
import {Link} from 'react-router-dom';
// import {userPreferenceActions, workorderActions} from "../network/actions";
// import {notificationActions} from "../network/actions";
// import io from 'socket.io-client';
// import {socketUrl} from '../network/constants';
// import {hasRoles} from '../helpers';

const propTypes = {
    children: PropTypes.node,
};

const Header = ({ user, logOut }) => {

    const logout = () => {
        logOut();
        history.push('/login');
    };

    return (
        <React.Fragment>
            <img className={'comp-logo'} src={""} alt=""/>
            <Nav className={'ml-auto'} navbar>
                <AppHeaderDropdown direction="down">
                    <DropdownToggle nav>
                        {user.picture ? <img src={user['picture']} className="img-avatar" alt={user.email}/> :
                            <label>{user.email}</label>}
                    </DropdownToggle>
                    <DropdownMenu className={'bot-logout'} right style={{right: 'auto'}}>
                        <DropdownItem header tag="div"
                                      className="text-center"><strong>Actions</strong></DropdownItem>
                        <DropdownItem outline color={'texts'} onClick={() => {
                            logout()
                        }}><i className="fa fa-lock"/> Logout</DropdownItem>
                        <DropdownItem outline color={'texts'}>
                            <Link to={'/configurations'}><i className="fa fa-wrench"/> Configurations</Link>
                        </DropdownItem>
                    </DropdownMenu>
                </AppHeaderDropdown>
            </Nav>
            <AppAsideToggler className="d-md-down-none"/>
            <AppAsideToggler className="d-lg-none" mobile/>
        </React.Fragment>
    )
}
Header.propTypes = propTypes;
Header.defaultProps = {
    user: {
        picture: null,
        email: null
    }
};

const mapStateToProps = (state) => {
    let {authentication} = state;
    let {user} = authentication;
    user = user?.user ?? Header.defaultProps.user

    return {
        user
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        // getNotifications: () => dispatch(notificationActions.getNotifications()),
        // confirmNotification: (id) => dispatch(notificationActions.confirm(id)),
        logOut: () => dispatch(userActions.logout()),
        // loadNotifications: (notifications) => dispatch(notificationActions.loadNotifications(notifications)),
        // getKPIData: (date) => dispatch(reportActions.getKPIData(date)),
        getLoginExceptions: () => dispatch(userActions.getLoginExceptions()),
        getUserPreference: (id) => dispatch(userPreferenceActions.getUserPreference(id))
    };
}

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export {connectedHeader as Header};
