import React from 'react';
import PropTypes from 'prop-types';
import {Button, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from '../../components/bot-components';
import {Modal, ModalBody} from '../../components/bot-components';
const propTypes = {
    ref: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    row: PropTypes.any,
    extraProps: PropTypes.any
};
const JobEditor = React.forwardRef((props, ref) => {
    const {row, toggle, onUpdate, isOpen, extraProps} = props;
    const [state, updateState] = React.useState({
        id: row.id,
        job_id: row.job_id,
        selectedJob: row.job_id && row.job && {label: row.job, value: row.job_id}
    });

    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));

    React.useImperativeHandle(ref, () => ({
        toggle: () => toggle(),
        state: state,
        setState: (newState) => setState(newState)
    }));

    const inputRef = React.useRef();

    const focusInput = () => {
        inputRef.current?.focus();
    }

    const updateData = () => {
        toggle();
        onUpdate({
            id: state.id,
            job_id: state.job_id
        });
    };
    const svBtn = document.querySelector('#btn_save');
    const cnBtn = document.querySelector('#btn_cancel');
    return (
            <Modal toggle={() => toggle()} centered={true} isOpen={isOpen} onOpened={() => focusInput()}>
                <ModalBody>
                    <InputGroup>
                        <Input type={'react-select'}
                               options={extraProps}
                               ref={inputRef}
                               placeholder={'Select the Job.'}
                               matchProp={'label'}
                               value={state.selectedJob}
                               onChange={(selectedJob) => {setState({job_id: selectedJob ? selectedJob.value : null, selectedJob: selectedJob})}}/>
                        <InputGroupAddon id={'btn_save'} addonType={'append'}><Button color={'texts'} outline onClick={updateData}><i className={'fa fa-check'}/></Button></InputGroupAddon>
                        <InputGroupAddon id={'btn_cancel'} addonType={'append'}><Button color={'texts'} outline onClick={toggle}><i className={'fa fa-times'}/></Button></InputGroupAddon>
                        {svBtn && <UncontrolledTooltip placement={'right'} target={`btn_save`}>Click here to
                            save</UncontrolledTooltip>}
                        {cnBtn && <UncontrolledTooltip placement={'right'} target={`btn_cancel`}>Click here to cancel
                            editing</UncontrolledTooltip>}
                    </InputGroup>
                </ModalBody>
            </Modal>
    );
})

JobEditor.propTypes = propTypes;
export default JobEditor;
