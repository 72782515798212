import {roleService} from '../services/';
import * as con from './role.actions.constants';

export const roleActions = {};

roleActions.getRoles = () => dispatch => {
    dispatch(con.getRolesRequest());
    roleService.getRoles()
        .then((roles, err) => err ? dispatch(con.getRolesFailure(err)) : dispatch(con.getRolesSuccess(roles)))
        .catch(error => dispatch(con.getRolesFailure(error)));
};

roleActions.getMechanics = () => dispatch => {
    dispatch(con.getMechanicsRequest());
    roleService.getMechanics()
        .then((mechanics, err) => err ? dispatch(con.getMechanicsFailure(err)) : dispatch(con.getMechanicsSuccess(mechanics)))
        .catch(error => dispatch(con.getMechanicsFailure(error)));
};

roleActions.getManagers = () => dispatch => {
    dispatch(con.getManagersRequest());
    roleService.getManagers()
        .then((managers, err) => err ? dispatch(con.getManagersFailure(err)) : dispatch(con.getManagersSuccess(managers)))
        .catch(error => dispatch(con.getManagersFailure(error)));
};

roleActions.getOperators = () => dispatch => {
    dispatch(con.getOperatorsRequest());
    roleService.getOperators()
        .then((operators, err) => err ? dispatch(con.getOperatorsFailure(err)) : dispatch(con.getOperatorsSuccess(operators)))
        .catch(error => dispatch(con.getOperatorsFailure(error)));
};
