// noinspection JSCheckFunctionSignatures

import { useRef, useReducer } from 'react';
import reducer, { initialState } from './ImageViewerImage.reducer';
import {click_zoom, pan, startPan, end_pan, zoom, _reset} from './ImageViewerImage.actions';
const usePanAndZoom = () => {
    const [state, dispatch] = useReducer(reducer, initialState), containerRef = useRef(null),
        reset = () => dispatch(_reset()), onMouseMoveInWindow = (event) => {
            event.preventDefault();
            dispatch(pan(event));
        }, onMouseUpInWindow = (event) => {
            dispatch(end_pan(event));
            window.removeEventListener('mouseup', onMouseUpInWindow);
            window.removeEventListener('mousemove', onMouseMoveInWindow);
        }, onMouseDown = (event) => {
            if (state.scale > 1) {
                dispatch(startPan(event));
                window.addEventListener('mouseup', onMouseUpInWindow);
                window.addEventListener('mousemove', onMouseMoveInWindow);
            }
        }, onWheel = (event) => {
            if (event.deltaY !== 0 && containerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();
                dispatch(zoom(event, containerRect));
            }
        }, onDoubleClick = (event) => {
            if (event.deltaY !== 0 && containerRef.current) {
                event.deltaY = state.scale < 1 ? 1 : state.scale < 2 ? 2 : 1;
                const containerRect = containerRef.current.getBoundingClientRect();
                dispatch(click_zoom(event, containerRect));
            }
        };
    return {
        ...state,
        containerRef,
        onMouseDown,
        onWheel,
        onDoubleClick,
        reset
    }
}

export default usePanAndZoom;
