import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { SortablePane } from 'react-sortable-pane';
import { BotTableBody, ColumnMenu, Label } from './';
import ReduxBlockUi from 'react-block-ui';
import {
  createActiveFilters,
  createButtonGroup,
  createColumns,
  createHeaders,
  createIconGroup,
  createPagination,
  createRows
} from './bot-table.helpers';
import _ from 'lodash';
import { saveTableSettings } from '../../helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userPreferenceActions } from '../../network/actions';

const propTypes = {
  columns:PropTypes.array.isRequired,
  title:PropTypes.string,
  showTitle:PropTypes.bool,
  data:PropTypes.object,
  onFiltersChanged:PropTypes.func,
  pageChanged:PropTypes.func,
  sizeChanged:PropTypes.func,
  currSize:PropTypes.number,
  currPage:PropTypes.number,
  ClearFiltersBtn:PropTypes.any,
  ClearSortBtn:PropTypes.any,
  CsvDownloadBtn:PropTypes.any,
  pagination:PropTypes.string,
  headers:PropTypes.bool,
  expandableComponent:PropTypes.any,
  expandable:PropTypes.bool,
  expandClick:PropTypes.bool,
  expandDoubleClick:PropTypes.bool,
  doRowUpdate:PropTypes.func,
  onFilterCleared:PropTypes.func,
  onSortChanged:PropTypes.func,
  onFilterChanged:PropTypes.func,
  btnGroupDirection:PropTypes.string,
  remote:PropTypes.bool,
  defaultFilters:PropTypes.any,
  onColumnResize:PropTypes.func,
  onColumnReorder:PropTypes.func,
  tblState:PropTypes.object,
  onColumnHidden:PropTypes.func,
  pageSizes:PropTypes.object,
  onlyFilters:PropTypes.object,
  onRowClick:PropTypes.func,
  onRowDoubleClick:PropTypes.func,
  CreateNewBtn:PropTypes.any,
  CustomBtns:PropTypes.any,
  CustomIcons:PropTypes.any,
  onRowRightClick:PropTypes.func,
  columnsShowAll:PropTypes.func,
  csvFile:PropTypes.string,
  csvUrl:PropTypes.string,
  user_preference:PropTypes.object,
  deleteRows:PropTypes.func,
  useLength:PropTypes.bool,
  canSelect:PropTypes.bool,
  canDelete:PropTypes.bool,
  collapsedHeader:PropTypes.bool
};

let table_key_iterator = 0;
let lastReq;

const BotTable = props => {
  let { columns, data } = props;
  const pagRef = React.useRef();
  const hPosition = React.useRef();

  const getStoredData = () => {
    const storedData = {};
    if (props.lStorage && localStorage.getItem( props.lStorage )) {
      if (props.u_preferences) {
        if (props.u_preferences.items && props.u_preferences.items['web_settings'] && props.u_preferences.items['web_settings'][props.lStorage]) {
          localStorage.setItem( props.lStorage, JSON.stringify( props.u_preferences.items['web_settings'][props.lStorage] ) );
          Object.assign( storedData, props.u_preferences.items['web_settings'][props.lStorage] );
        } else if (localStorage.getItem( props.lStorage )) Object.assign( storedData, JSON.parse( localStorage.getItem( props.lStorage ) ) );
        else Object.assign( storedData, props.defaultState );
      } else localStorage.getItem( props.lStorage ) ? Object.assign( storedData, JSON.parse( localStorage.getItem( props.lStorage ) ) ) : Object.assign( storedData, props.defaultState );
    } else Object.assign( storedData, props.defaultState );
    return storedData;
  };
  const [state, updateState] = useState( {
    ...getStoredData(),
    columnMenuOpen:false,
    pageSizes:props.pageSizes ? props.pageSizes : ['20', '50'],
    scrollLeft:0,
    blocked:false,
    remote:props.remote,
    data:props.data,
    showNav:true,
    pendingPromises:[],
    selectedRows:[]
  } );

  /**
   *
   * @param {Object} newState
   */
  const setState = newState => updateState( prevState => ({
    ...prevState,
    ...newState
  }) );

  const { pendingPromises } = state;

  const saveSettings = ( req, order ) => {
    if (!props.expandable) {
      const storage = {
        currPage:req ? req.currentPage : state.currPage,
        currSize:req ? req.sizePerPage : state.currSize,
        currentPage:req ? req.currentPage : state.currPage,
        filters:req ? req.filters : state.filters,
        order:order ? order : state.order,
        pageSizes:state.pageSizes,
        panes:state.panes,
        sortName:req ? req.sortName : state.sortName,
        sortOrder:req ? req.sortOrder : state.sortOrder
      };
      return saveTableSettings( props.lStorage, storage, null, props.user_preference, props.updateUserPreference );
    }
  };

  useImperativeHandle( props.refProp, () => ({
    setFilter:( field, value ) => filterChanged( { target:{ name:field, value:value } } ),
    state:state,
    rows:rows,
    setSelectedRows:( row, e ) => setSelectedRows( row, e )
  }) );

  const queryData = useCallback( async ( req ) => {
    props.getData( req );
    lastReq = _.cloneDeep( req );
  }, [] );

  const sendQuery = useCallback( async ( req ) => {
    if (props.remote && !_.isEqual( JSON.stringify( req ), JSON.stringify( lastReq ) )) {
      await queryData( req );
    }
    setState( {
      updated:true
    } );
    const storage = {
      currPage:req.currentPage,
      currSize:req.sizePerPage,
      currentPage:req.currentPage,
      filters:req.filters,
      order:state.order,
      pageSizes:state.pageSizes,
      panes:state.panes,
      sortName:req.sortName,
      sortOrder:req.sortOrder
    };
    props.remote && saveTableSettings( props.lStorage, storage, null, props.user_preference, props.updateUserPreference ).catch();
  }, [] );

  useEffect( () => {
    props.user_preference?.items?.web_settings?.keyboardNav && window.addEventListener( 'keydown', handleKeyNavigation );
    return () => window.removeEventListener( 'keydown', handleKeyNavigation );
  }, [] );

  useEffect( () => {
    const storedData = getStoredData();
    const sp = parseInt( storedData.currPage?.toString() );
    const pp = parseInt( storedData.currPage?.toString() );
    const joined = {
      ...props.defaultState,
      ...storedData
    };
    setState( {
      ...joined,
      columnMenuOpen:false,
      pageSizes:props.pageSizes ? props.pageSizes : ['20', '50'],
      currentPage:(!isNaN( sp ) && !isNaN( pp )) ? pp : !isNaN( pp ) ? pp : 1,
      order:order,
      panes:panes,
      scrollLeft:0,
      blocked:false,
      remote:props.remote,
      data:props.data,
      showNav:true
    } );
    const req = {
      sortName:joined.sortName,
      sortOrder:joined.sortOrder,
      currentPage:joined.currPage,
      sizePerPage:joined.currSize,
      filters:joined.filters
    };
    props.remote === true ? props.getData( req ) : props.getData && props.getData();
  }, [] );

  useEffect( () => {
    setState( {
      data:props.data ? props.data : state.data,
    } );
  }, [props.data] );

  useEffect( () => {
    const req = {
      sortName:state.sortName,
      sortOrder:state.sortOrder,
      currentPage:state.currPage,
      sizePerPage:state.currSize,
      filters:state.filters
    };
    sendQuery( req ).catch();
  }, [state.sortName, state.sortOrder, state.currPage, state.currSize, state.filters, sendQuery] );

  useEffect( () => {
    setState( {
      filters:{
        ...props.defaultFilters,
        ...state.filters
      }
    } );
  }, [props.defaultFilters] );

  useEffect( () => {
    const { panes, order, defaultOrder } = createColumns( props, state.panes, state.order );
    setState( {
      panes:panes,
      order:order,
      defaultOrder:defaultOrder
    } );
  }, [props.columns] );

  const setSelectedRows = ( row, e ) => {
    const { checked } = e.target;
    row.selected = checked;
    const selectedRows = checked ? [...state.selectedRows, row] : state.selectedRows.filter( r => r.id !== row.id );
    state.data.forEach( d => d.selected = d.id === row.id ? checked : d.selected );
    setState( { data:state.data, selectedRows:selectedRows } );
  };

  const selectAll = () => {
    const selectedRows = [...state.selectedRows];
    const selectedIds = selectedRows.map( r => r.id );
    const ids = rows?.map( r => r.props?.children?.[0]?.props?.children?.props?.row.id );
    const data = state.data.map( d => {
      d.selected = ids.includes( d.id ) ? true : d.selected;
      ids.includes( d.id ) && !selectedIds.includes( d.id ) && selectedRows.push( d );
      return d;
    } );
    setState( { data:data, selectedRows:selectedRows } );
  };

  const selectNone = () => {
    const ids = rows?.map( r => r.props?.children?.[0]?.props?.children?.props?.row.id );
    const selectedRows = [...state.selectedRows].filter( r => !ids.includes( r.id ) );
    const data = state.data.map( r => {
      r.selected = ids.includes( r.id ) ? false : r.selected;
      return r;
    } );
    setState( { data:data, selectedRows:selectedRows } );
  };

  const showSelected = async () => {
    const selectedRows = [...state.selectedRows];
    state.data.length !== selectedRows.length ?
      setState( {
        data:selectedRows,
        currSize:selectedRows.length,
        currPage:1
      } ) : setState( {
        data:props.data,
        currSize:parseInt( props.pageSizes[0] ?? '20' ),
        currPage:1
      } );
  };

  const deleteRows = () => {
    const ids = state.selectedRows.map( r => r.id );
    props.deleteRows( ids );
  };

  const cancellablePromise = promise => {
    let isCanceled = false;

    const wrappedPromise = new Promise( ( resolve, reject ) => {
      promise.then(
        value => (isCanceled ? reject( { isCanceled, value } ) : resolve( value )),
        error => reject( { isCanceled, error } ),
      );
    } );

    return {
      promise:wrappedPromise,
      cancel:() => (isCanceled = true),
    };
  };

  const delay = n => new Promise( resolve => setTimeout( resolve, n ) );

  const appendPendingPromise = promise => setState( {
    pendingPromises:[...pendingPromises, promise]
  } );

  const removePendingPromise = promise => setState( {
    pendingPromises:pendingPromises.filter( p => p !== promise )
  } );

  const clearPendingPromises = () => setState( {
    pendingPromises:[]
  } );

  const table_keys = () => {
    return `${ props.tblId ? props.tblId : 'unset' }_${ table_key_iterator++ }`;
  };

  const handleHScroll = () => {
    horizontalScroll( hPosition.current?.scrollLeft );
  };

  const horizontalScroll = ( scrollLeft ) => {
    setState( { scrollLeft } );
  };

  const toggleColumnMenu = () => {
    setState( {
      columnMenuOpen:!state.columnMenuOpen
    } );
  };

  const changeHidden = ( e ) => {
    const { name, checked } = e.target;
    setState( {
      panes:{
        ...state.panes,
        [name]:{
          ...state.panes[name],
          hidden:checked
        }
      }
    } );
  };

  const setShowAll = async () => {
    const Panes = {};
    await Object.keys( state.panes ).forEach( t => t !== 'expand' && t !== 'button' && state.panes[t].hidden === true ?
      Panes[t] = { ...state.panes[t], hidden:false } : Panes[t] = state.panes[t] );
    setState( {
      panes:Panes
    } );
  };

  const cleanFiltered = () => {
    const fil = {};
    state.panes && Object.keys( state.panes ).map( p => {
      const P = state.panes[p];
      const F = P.refProp && P.refProp.current && P.refProp.current.cleanFiltered();
      const key = F && Object.keys( F );
      const value = F && F[key];
      if (key) fil[key] = value;
      return null;
    } );
    const nf = {
      ...props.defaultFilters
    };
    if (state.filters.date_range) nf.date_range = state.filters.date_range;
    setState( {
      filters:nf,
      currPage:1
    } );
    return fil;
  };

  const clearSort = ( f ) => {
    if (f === undefined) {
      props.onSortChanged && props.onSortChanged( { sortName:'', sortOrder:'' } );
      setState( {
        sortName:'',
        sortOrder:''
      } );
    }
  };

  const filterCleared = ( fil ) => {
    const { remote } = props;
    const key = Object.keys( fil );
    if (remote) {
      props.onFilterCleared( fil );
    } else {
      data = props.data;
      props.onFilterCleared( fil );
      const filters = _.cloneDeep( state.filters );
      filters[key] = '';
      const Filtered = Object.keys( filters ).map( f => {
        if (filters[f] !== '""' && filters[f].length > 0) {
          if (filters[f] === typeof 'boolean') {
            data = data.filter( d => d[f] = filters[f] );
          } else {
            data = data.filter( d => d[f].toString().toLowerCase().includes( filters[f].toLowerCase() ) );
          }
        }
        return 0;
      } );
      Promise.all( Filtered ).then( () => {
        setState( {
          data:data,
        } );
      } );
    }
  };

  const filterChanged = ( e ) => {
    const { name, value } = e.target;
    props.onFiltersChanged && props.onFiltersChanged( e );
    setState( {
      filters:{
        ...state.filters,
        [name]:value
      },
      currPage:1
    } );
  };

  const sortChanged = ( sort ) => {
    props.onSortChanged && props.onSortChanged( sort );
    setState( {
      ...sort
    } );
  };

  const onPageChanged = ( pag ) => {
    if (!isNaN( pag )) {
      props.pageChanged && props.pageChanged( pag );
      setState( {
        currPage:pag
      } );
    }
  };

  const onSizeChange = ( pag ) => {
    props.sizeChanged && props.sizeChanged( pag );
    setState( {
      currSize:pag,
      currPage:1
    } );
  };

  const resetTable = async () => {
    const myPanes = _.cloneDeep( panes );
    await Object.keys( myPanes ).forEach( p => myPanes[p] = {
      ...myPanes[p],
      ...columns.find( c => c.field === p )
    } );

    setState( {
      ...props.defaultState,
      columnMenuOpen:false,
      pageSizes:props.pageSizes ? props.pageSizes : ['20', '50'],
      order:defaultOrder,
      panes:myPanes,
      rows:null,
      scrollLeft:0,
      blocked:false,
      remote:props.remote,
      data:data,
      showNav:true
    } );
    setTimeout( () => saveSettings( null, defaultOrder )?.catch(), 300 );

  };

  const setOrder = ( order ) => {
    setState( { order } );
  };

  useEffect( () => {
    props.onColumnReorder && props.onColumnReorder( order );
  }, [state.order] );

  const setColumnWidth = ( e, key, dir, ref, d ) => {
    setState( {
      panes:{
        ...state.panes,
        [key]:{
          width:state.panes[key].width + d.width,
          text:state.panes[key].text,
          filter:state.panes[key].filter,
          format:state.panes[key].format,
          tdStyle:state.panes[key].tdStyle,
          hidden:state.panes[key].hidden,
          sortable:state.panes[key].sortable
        }
      }
    } );
  };

  const handleKeyNavigation = ( e ) => e.key === 'ArrowRight' ? hPosition.current?.scrollBy( 200, 0 ) : e.key === 'ArrowLeft' && hPosition.current?.scrollBy( -200, 0 );

  const { title } = props;
  const { columnMenuOpen, panes, order, defaultOrder } = state;
  const headers = createHeaders( state.panes, state.filters, state.sortName, state.sortOrder, filterCleared, sortChanged, filterChanged, state.selectedRows, deleteRows );
  const rows = createRows( state.data, state.filters, state.currPage, state.currSize, state.sortName, state.sortOrder, props.remote, state.panes, state.order, table_keys, props.onRowDoubleClick, props.onRowRightClick, clearPendingPromises, cancellablePromise, removePendingPromise, appendPendingPromise, delay, props.onRowClick, props.expandable, props.expandableComponent, props.expandClick, props.expandDoubleClick, props.useLength, columns );
  const filters = _.cloneDeep( state.filters ) || {};
  delete filters?.date_range;
  Object.keys( filters ).forEach( f => (filters[f] === '' || filters[f] === '' || filters[f] === '""') && delete filters[f] );

  return (
    <ReduxBlockUi blocking={ props.loading } tag={ 'div' }
                  message={ <Label className={ 'blocking-loading-lbl' }>Please Wait</Label> }>
      <div id={ props.id } className={ 'bot-table' } ref={ props.refProp }>
        { title && props.collapsedHeader ? <div className={ 'bot-table-title-pane-collapsed' }>
          { createIconGroup( props.ClearFiltersBtn, props.ClearSortBtn, props.CsvDownloadBtn, props.onlyFiltersIcons, props.CustomIcons, 'up', props.remote, props.csvFile, props.csvUrl, data, props.specialFilters, props.selects, columns, state, table_keys, cleanFiltered, clearSort, hPosition ) }
        </div> : <div className={ 'bot-table-title-pane' }>
          <label className={ 'bot-table-title' } onClick={ ( e ) => {
            e.preventDefault();
            e.stopPropagation();
          }
          }>
            { props.pagination && createButtonGroup( state.filters, props.defaultFilters, props.CsvDownloadBtn, props.CreateNewBtn, props.CustomBtns, table_keys, props.onlyFilters, props.remote, props.csvFile, props.csvUrl, props.data, props.columns, state, saveSettings, props.dateRangeBtns, filterChanged, props.canSelect, props.canDelete, selectAll, selectNone, showSelected, deleteRows, props.selects, props.specialFilters ) }
            { props.showTitle && title }
            { columns.length > 0 &&
              <ColumnMenu columnMenuOpen={ columnMenuOpen } toggleColumnMenu={ toggleColumnMenu }
                          columns={ columns } panes={ state.panes } table_keys={ table_keys }
                          changeHidden={ changeHidden } setShowAll={ setShowAll } resetTable={ resetTable }/> }
          </label>
        </div> }
        <div className={ 'bot-table-nav-row' }>
          <div className={ 'bot-table-nav-row' }>
            <div style={ { display:'block' } }>
              { ((state?.sortName && state.sortName !== '') || Object.keys( filters || {} ).length > 0) && !props.collapsedHeader && createActiveFilters( {
                sortName:state.sortName,
                clearSort,
                filters,
                columns,
                cleanFiltered
              } ) }
              { (props.pagination === 'top'
                  ||
                  props.pagination === 'both') &&
                createPagination( state.data, 'down', props.remote, state.currPage, state.pageSizes, state.currSize, onPageChanged, onSizeChange, pagRef, state.filters, props.useLength ) }
            </div>
          </div>
        </div>
        { !props.collapsedHeader && <div className={ 'bot-table-scroll-row' }><i
          className={ 'fa fa-chevron-left float-left bot-table-scroller' }
          onClick={ () => hPosition.current?.scrollBy( -200, 0 ) }/><i
          className={ 'fa fa-chevron-right float-right bot-table-scroller' }
          onClick={ () => hPosition.current?.scrollBy( 200, 0 ) }/></div> }
        { headers && headers.length === state.order?.length ?
          <BotTableBody innerRef={ props.refProp }>
            <div className={ 'tbody-container' }
                 ref={ hPosition }
                 onScroll={ handleHScroll }>
              { props.headers === true &&
                <SortablePane className={ 'bot-table-headers' }
                              direction={ 'horizontal' }
                              order={ state.order }
                              onOrderChange={ order => setOrder( order ) }
                              onDragStart={ () => null }
                              onResizeStop={ ( e, key, dir, ref, d ) => setColumnWidth( e, key, dir, ref, d ) }
                              disableEffect={ true }>
                  { headers }
                </SortablePane> }
              <div className={ 'bot-table-data' }>
                { rows }
              </div>
            </div>
          </BotTableBody> : <div>Loading</div> }
        <div className={ 'bot-table-nav-row bottom' }>
          { (props.pagination === 'bottom'
              ||
              props.pagination === 'both') &&
            createPagination( state.data, 'up', props.remote, state.currPage, state.pageSizes, state.currSize, onPageChanged, onSizeChange, pagRef, state.filters, props.useLength ) }
        </div>
      </div>
    </ReduxBlockUi>
  );
};

BotTable.propTypes = propTypes;

const mapStateToProps = ( state ) => {
  const { user_preference } = state;
  return { user_preference };
};
const mapDispatchToProps = ( dispatch ) => {
  return {
    updateUserPreference:( values ) => dispatch( userPreferenceActions.updateUserPreference( values ) ),
  };
};
export default connect( mapStateToProps, mapDispatchToProps )( BotTable );
