import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from '../bot-components';

const PmListDeleteConfirmationModal = ({isOpen, list, finalize, cancel}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        Are you sure you want to delete {list?.name} and remove all of it's items?
      </ModalBody>
      <ModalFooter>
        <Button color={'texts'} outline onClick={() => finalize(list)}>Yes</Button>
        <Button color={'texts'} outline onClick={cancel}>No</Button>
      </ModalFooter>
    </Modal>
  );
};

export default PmListDeleteConfirmationModal;