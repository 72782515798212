export const companyConstants = {
    CREATE_COMPANY_REQUEST: 'COMPANY_CREATE_REQUEST',
    CREATE_COMPANY_SUCCESS: 'COMPANY_CREATE_SUCCESS',
    CREATE_COMPANY_FAILURE: 'COMPANY_CREATE_FAILURE',

    UPDATE_COMPANY_REQUEST: 'COMPANY_UPDATE_REQUEST',
    UPDATE_COMPANY_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
    UPDATE_COMPANY_FAILURE: 'COMPANY_UPDATE_FAILURE',

    GET_ALL_COMPANIES_REQUEST: 'COMPANIES_GET_ALL_REQUEST',
    GET_ALL_COMPANIES_SUCCESS: 'COMPANIES_GET_ALL_SUCCESS',
    GET_ALL_COMPANIES_FAILURE: 'COMPANIES_GET_ALL_FAILURE',

    DETAIL_COMPANY_REQUEST: 'COMPANY_DETAIL_REQUEST',
    DETAIL_COMPANY_SUCCESS: 'COMPANY_DETAIL_SUCCESS',
    DETAIL_COMPANY_FAILURE: 'COMPANY_DETAIL_FAILURE',
};
