/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {
    Button,
    CardBody,
    Form,
    Label,
    CardFooter
} from '../../../components/bot-components';
import {notEmptyText} from '../../../helpers';
import {defaultProps, defaultState} from './UserEdit.constants';
import {saveUser, mapStateToProps, mapDispatchToProps, getExtraInputs} from './UserEdit.helpers';
import {getExtraFields, getEnums, getUserFields} from '../shared.helpers';
import ReduxBlockUi from 'react-block-ui';

const UserEdit = (props) => {
    const {cancelUserEdit, Admin, Super} = props;
    const [state, updateState] = React.useState(defaultState);

    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    React.useEffect(() => {
        if (!props.user.loading && !props.user.items) {
            !props.user.items ? localStorage.getItem('edit_user') ? props.getUser(localStorage.getItem('edit_user')) : props.cancelUserEdit() : props.cancelUserEdit();
        }
        props.getRoles();
        props.getJobs();
        props.getYards();
        (Admin) && getEnums('enum_field_1').then((done, err) => !err && setState({enum1: done}));
        (Admin) && getEnums('enum_field_2').then((done, err) => !err && setState({enum2: done}));
        (Admin) && getEnums('enum_field_3').then((done, err) => !err && setState({enum3: done}));
        (Admin) && getEnums('enum_field_4').then((done, err) => !err && setState({enum4: done}));
    }, []);

    React.useEffect(() => {
        (Admin) ? getExtraFields().then((done, err) => {
            if (!err) {
                const fields = done;
                const enums = done.filter(d => {
                    if (d.field.startsWith('enum') && d.field !== d['table_header']) return d; else return null;
                });
                const _strings = done.filter(d => {
                    if (d.field.startsWith('string') && d.field !== d['table_header']) return d; else return null;
                });
                const bools = done.filter(d => {
                    if (d.field.startsWith('bool') && d.field !== d['table_header']) return d; else return null;
                });
                setState({
                    fields: fields,
                    enums: enums,
                    _strings: _strings,
                    bools: bools,
                    extraLoaded: true
                });
            }
        }) : setState({
            extraLoaded: true
        });
    }, [state.enum1, state.enum2, state.enum3, state.enum4]);

    const changeEnumValues = (name, alias, value) => {
        setState({
            [name]: value,
            [alias]: value,
            [`${name}_valid`]: value ? notEmptyText(value) : false,
            [`${alias}_valid`]: value ? notEmptyText(value) : false
        });
    };

    const changeExtraTextField = (name, alias, value) => {
        setState({
            [name]: value,
            [alias]: value,
            [`${name}_valid`]: value ? notEmptyText(value) : false,
            [`${alias}_valid`]: value ? notEmptyText(value) : false
        });
    };

    const savedPassword = () => {
        if (state.editPassword) {
            setState({
                editPassword: false,
            });
        }
    };

    React.useEffect(() => {
        props.user.items && setState({
            ...props.user.items,
            daily_inspections: props.user.items?.daily_inspections,
            selectedRoles: props.user.items?.roles && props.user.items?.roles.map(role => {return {label: role.authority, value: role.id}}),
            last_name: props.user.items?.last_name,
            first_name: props.user.items?.first_name,
            user_name: props.user.items?.name,
            phone_number: props.user.items?.phone_number,
            user_email: props.user.items?.email,
            user_job: props.user.items?.job_id,
            user_number: props.user.items?.employee_number,
            user_status: props.user.items?.user_status_id,
            selectedStatus: {label: props.user.items?.user_status, value: props.user.items?.user_status_id},
            selectedJob: props.user.items?.job_id ? {label: props.user.items?.job_number, value: props.user.items?.job_id} : null,
            crew: props.user.items?.crew ? props.user.items?.crew.id : '',
            validCompRole: props.user.items?.company_role !== null,
            selectedYard: props.user.items?.yard_id ? {label: props.user.items?.yard_name, value: props.user.items?.yard_id} : null
        });
    }, [props.user]);

    const {first_name_valid, last_name_valid, user_email_valid, user_email_unique, password_exists, password_confirmation_exists, passwords_match, user_number_exists, user_number_unique, validRoles} = state;
    const canSave = first_name_valid && last_name_valid && user_email_valid && user_number_exists && user_number_unique && user_email_unique && validRoles;
    const canSavePass = password_exists && password_confirmation_exists && passwords_match;

    UserEdit.defaultProps = props;
    UserEdit.defaultState = state;

    return (
        <ReduxBlockUi blocking={props.user?.loading || !state.extraLoaded} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
            <CardBody>
                <Form>
                    {getUserFields(props.statusList, props.roleList, props.yardList, props.jobList, state, setState, canSavePass, savedPassword, props, true)}
                    {Admin && getExtraInputs(Super, state, setState, changeEnumValues, changeExtraTextField)}
                </Form>
            </CardBody>
            <CardFooter className={'user-edit-footer'}>
                <Button disabled={!canSave} color={'texts'} outline onClick={() => {
                    saveUser(state, props)
                }}>Save</Button><Button color={'texts'} outline onClick={() => {cancelUserEdit()
            }}>Cancel</Button>
            </CardFooter>
        </ReduxBlockUi>
    );
};

UserEdit.defaultProps = defaultProps;
UserEdit.defaultState = defaultState;
export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
