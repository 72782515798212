export const reportConstants = {
  KPI_DATA_REQUEST: 'REPORT_KPI_DATA_REQUEST',
  KPI_DATA_SUCCESS: 'REPORT_KPI_DATA_SUCCESS',
  KPI_DATA_FAILURE: 'REPORT_KPI_DATA_FAILURE',

  WORKORDER_REPORT_REQUEST: 'WORKORDER_REPORT_REQUEST',
  WORKORDER_REPORT_SUCCESS: 'WORKORDER_REPORT_SUCCESS',
  WORKORDER_REPORT_FAILURE: 'WORKORDER_REPORT_FAILURE',

  MAINT_REPORT_WO_REQUEST: 'MAINT_REPORT_WO_REQUEST',
  MAINT_REPORT_WO_SUCCESS: 'MAINT_REPORT_WO_SUCCESS',
  MAINT_REPORT_WO_FAILURE: 'MAINT_REPORT_WO_FAILURE',

  MAINT_REPORT_TASK_REQUEST: 'MAINT_REPORT_TASK_REQUEST',
  MAINT_REPORT_TASK_SUCCESS: 'MAINT_REPORT_TASK_SUCCESS',
  MAINT_REPORT_TASK_FAILURE: 'MAINT_REPORT_TASK_FAILURE',

  MAINT_REPORT_WO_DETAIL_REQUEST: 'MAINT_REPORT_WO_DETAIL_REQUEST',
  MAINT_REPORT_WO_DETAIL_SUCCESS: 'MAINT_REPORT_WO_DETAIL_SUCCESS',
  MAINT_REPORT_WO_DETAIL_FAILURE: 'MAINT_REPORT_WO_DETAIL_FAILURE',

  INSPECTION_REPORT_REQUEST: 'INSPECTION_REPORT_REQUEST',
  INSPECTION_REPORT_SUCCESS: 'INSPECTION_REPORT_SUCCESS',
  INSPECTION_REPORT_FAILURE: 'INSPECTION_REPORT_FAILURE',

  FOREMAN_REPORT_REQUEST: 'FOREMAN_REPORT_REQUEST',
  FOREMAN_REPORT_SUCCESS: 'FOREMAN_REPORT_SUCCESS',
  FOREMAN_REPORT_FAILURE: 'FOREMAN_REPORT_FAILURE',

  WORK_ORDER_BY_JOB_REPORT_REQUEST: 'WORK_ORDER_BY_JOB_REPORT_REQUEST',
  WORK_ORDER_BY_JOB_REPORT_SUCCESS: 'WORK_ORDER_BY_JOB_REPORT_SUCCESS',
  WORK_ORDER_BY_JOB_REPORT_FAILURE: 'WORK_ORDER_BY_JOB_REPORT_FAILURE',

  MAINT_REPORT_TASK_DETAIL_REQUEST: 'MAINT_REPORT_TASK_DETAIL_REQUEST',
  MAINT_REPORT_TASK_DETAIL_SUCCESS: 'MAINT_REPORT_TASK_DETAIL_SUCCESS',
  MAINT_REPORT_TASK_DETAIL_FAILURE: 'MAINT_REPORT_TASK_DETAIL_FAILURE',

  DOT_INSPECTION_REPORT_REQUEST: 'DOT_INSPECTION_REPORT_REQUEST',
  DOT_INSPECTION_REPORT_SUCCESS: 'DOT_INSPECTION_REPORT_SUCCESS',
  DOT_INSPECTION_REPORT_FAILURE: 'DOT_INSPECTION_REPORT_FAILURE',

  DOT_TASK_REPORT_REQUEST: 'DOT_TASK_REPORT_REQUEST',
  DOT_TASK_REPORT_SUCCESS: 'DOT_TASK_REPORT_SUCCESS',
  DOT_TASK_REPORT_FAILURE: 'DOT_TASK_REPORT_FAILURE',
};
