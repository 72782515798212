/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {Col, BotTable, DropdownItem} from '../../components/bot-components';
import {assetTypeActions} from '../../network/actions/';
import _ from 'lodash';
import {GenericTextEditor} from '../Editors';

const defaultFilters = {
    size: ''
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};
const BotTireSizes = React.forwardRef((props, ref) => {
    const {asset_types, getAssetTypes, createAssetType} = props;
    const descriptionRef = React.useRef();
    const [newModal, setNeModal] = React.useState(false);
    React.useEffect(() => {
        getAssetTypes();
        localStorage.setItem('lastComp', props?.history?.location?.pathname);
    }, []);
    const createNewBtn = () => (<DropdownItem outline color={'texts'} onClick={() => setNeModal(!newModal)}><i className={'fa fa-plus'}/> New</DropdownItem>);
    const onSaveAssetType = (row) => {
        createAssetType({description: row.description});
        setTimeout(() => {
            getAssetTypes();
        }, 500);
    };
    const columns = props?.columns ?? [
        {
            field: 'description',
            text: 'Description',
            filter: {
                type: 'text',
                placeholder: 'Description',
                defaultValue: defaultFilters.size
            },
            width: 700,
            hidden: false,
            sortable: true,
            refProp: descriptionRef
        }
    ]
    return (
        <Col sm={6}>
            <GenericTextEditor onUpdate={(row) => onSaveAssetType(row)} row={{description: null}} cell={'size'} isOpen={newModal} toggle={() => setNeModal(!newModal)} />
            <BotTable ref={ref}
                      columns={columns}
                      title={true}
                      showTitle={false}
                      header={true}
                      data={asset_types?.items}
                      getData={() => getAssetTypes()}
                      pageSizes={['20', '50', '100', '500']}
                      ClearFiltersBtn={true}
                      ClearFiltersIcon={true}
                      ClearSortBtn={true}
                      ClearSortIcon={true}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      pagination={'both'}
                      headers={true}
                      expandable={false}
                      lStorage={'all_tire_size_storage'}
                      loading={asset_types.loading}
                      tblId={'allTS'}
                      csvFile={'all_tire_sizes.csv'}
                      CreateNewBtn={createNewBtn}
                      doRowUpdate={() => Promise.reject()}
                      refreshData={() => getAssetTypes()}
                      btnGroupDirection={'left'}
                      remote={false}
                      defaultFilters={defaultFilters}
                      defaultState={defaultState}/>
        </Col>
    );
});

const mapStateToProps = state => {
    const {asset_types, asset_type} = state;
    return {asset_types, asset_type};
};

const mapDispatchToProps = dispatch => {
    return {
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
        updateAssetType: (at) => dispatch(assetTypeActions.updateAssetType(at)),
        createAssetType: (at) => dispatch(assetTypeActions.createAssetType(at))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BotTireSizes);
