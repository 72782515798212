export const workorderStatusConstants = {
    CREATE_WORKORDER_STATUS_REQUEST: 'WORKORDER_STATUS_CREATE_REQUEST',
    CREATE_WORKORDER_STATUS_SUCCESS: 'WORKORDER_STATUS_CREATE_SUCCESS',
    CREATE_WORKORDER_STATUS_FAILURE: 'WORKORDER_STATUS_CREATE_FAILURE',

    GET_ALL_WORKORDER_STATUSES_REQUEST: 'WORKORDER_STATUSES_GET_ALL_REQUEST',
    GET_ALL_WORKORDER_STATUSES_SUCCESS: 'WORKORDER_STATUSES_GET_ALL_SUCCESS',
    GET_ALL_WORKORDER_STATUSES_FAILURE: 'WORKORDER_STATUSES_GET_ALL_FAILURE',

    DETAIL_WORKORDER_STATUS_REQUEST: 'WORKORDER_STATUS_DETAIL_REQUEST',
    DETAIL_WORKORDER_STATUS_SUCCESS: 'WORKORDER_STATUS_DETAIL_SUCCESS',
    DETAIL_WORKORDER_STATUS_FAILURE: 'WORKORDER_STATUS_DETAIL_FAILURE',
};
