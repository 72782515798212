import {dashboardConstants} from '../constants/';
import {dashboardService} from '../services/';
export const dashboardActions = {};

dashboardActions.getIns = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () =>({type: dashboardConstants.INS_DASHBOARD_REQUEST});
    const success = (insDashboard) => ({type: dashboardConstants.INS_DASHBOARD_SUCCESS, insDashboard});
    const failure = (error) => ({type: dashboardConstants.INS_DASHBOARD_FAILURE, error});

    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dashboardService.getIns()
            .then(insDashboard => dispatch(success(insDashboard)), error => dispatch(failure(error)));
    };
};

dashboardActions.getDot = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () =>({type: dashboardConstants.DOT_DASHBOARD_REQUEST});
    const success = (dotDashboard) => ({type: dashboardConstants.DOT_DASHBOARD_SUCCESS, dotDashboard});
    const failure = (error) => ({type: dashboardConstants.DOT_DASHBOARD_FAILURE, error});

    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dashboardService.getDot()
            .then(dotDashboard => dispatch(success(dotDashboard)), error => dispatch(failure(error)));
    };
};

dashboardActions.getWo = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () =>({type: dashboardConstants.WO_DASHBOARD_REQUEST});
    const success = (woDashboard) => ({type: dashboardConstants.WO_DASHBOARD_SUCCESS, woDashboard});
    const failure = (error) => ({type: dashboardConstants.WO_DASHBOARD_FAILURE, error});

    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dashboardService.getWo()
            .then(woDashboard => dispatch(success(woDashboard)), error => dispatch(failure(error)));
    };
};

dashboardActions.getSnapshot = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () =>({type: dashboardConstants.WO_DASHBOARD_REQUEST});
    const success = (woDashboard) => ({type: dashboardConstants.WO_DASHBOARD_SUCCESS, woDashboard});
    const failure = (error) => ({type: dashboardConstants.WO_DASHBOARD_FAILURE, error});

    //</editor-fold>
    return dispatch => {
        dispatch(request());
        dashboardService.getSnapshot(id)
            .then(woDashboard => dispatch(success(woDashboard.snapshot)), error => dispatch(failure(error)));
    };
};
