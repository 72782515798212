/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {baseUrl} from '../../network/constants';
import {authHeader, store} from '../../helpers';
import {logOut} from '../../network/services/responseHandler';
import {userActions} from '../../network/actions';
import XpiHistories from './XpiHistories';

const AllHistory = (props) => {
    const [state, updateState] = React.useState({
        loading: false,
        histories: []
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const tblRef = React.useRef();

    const getHistories = () => {
        setState({
            loading: true
        });

        const requestOptions = {
            method: 'GET',
            headers: authHeader('GET')
        };

        return fetch(`${baseUrl}/reports/xpi_history/`, requestOptions).then(response => {
            response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    setState({
                        loading: false,
                        histories: []
                    });
                    return alert('There was an error loading the work orders, please try again...');
                }

                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    // noinspection JSCheckFunctionSignatures
                    await store.dispatch(userActions.updateAuth(Auth, null));
                }
                if (data) {
                    return setState({
                        loading: false,
                        histories: data
                    });
                } else return setState({
                    loading: false,
                    histories: []
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };

    React.useEffect(() => {
        getHistories().catch();
        localStorage.setItem('lastComp', props.history?.location?.pathname);
    }, []);

    const {histories, loading} = state;
    const {user_preference} = props;
    return(
        <XpiHistories
            ref={tblRef}
            data={histories}
            title={'All History'}
            remote={false}
            loading={loading}
            lStorage={'all_xpi_history_storage'}
            u_preferences={user_preference}
            csvFile={'all_xpi_history.csv'}
            getHistories={getHistories}
            pageSizes={['20', '40', '60']}
        />
     );
};

const mapStateToProps = (state) => {
    const {user_preference} = state;
    return {user_preference};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllHistory);
