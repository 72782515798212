export const defaultProps = {};
export const defaultState = {
    user_status: '',
    daily_inspections: '',
    user_roles: [],
    first_name: '',
    last_name: '',
    user_name: '',
    phone_number: '',
    selectedCompRole: '',
    user_email: '',
    user_job: '',
    user_number: '',
    selectedJob: '',
    selectedYard: '',
    selectedRoles: '',
    selectedStatus: '',
    editPassword: false,
    password: '',
    password_confirmation: '',
    crew: '',
    loaded: false,
    first_name_valid: true,
    last_name_valid: true,
    user_email_valid: true,
    user_email_unique: true,
    password_exists: true,
    password_confirmation_exists: true,
    passwords_match: true,
    user_number_exists: true,
    user_number_unique: true,
    validRoles: true,
    validCompRole: false,
    bools: null,
    enums: null,
    _strings: null,
    extraLoaded: false,
    enum1: undefined,
    enum2: undefined,
    enum3: undefined,
    enum4: undefined
};
