import React from 'react';
import PropTypes from 'prop-types';
import {BotTable, DropdownItem} from '../../components/bot-components';
import CreatePart from './CreatePart';
import _ from 'lodash';

const propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    canCreate: PropTypes.bool,
    vendorList: PropTypes.any,
    getParts: PropTypes.func,
    getPart: PropTypes.func,
    updatePart: PropTypes.func,
    createPart: PropTypes.func,
    workorder: PropTypes.any,
    unit: PropTypes.any,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any
};
const defaultFilters = {
    number: '',
    description: '',
    unit_cost: '',
    qty: '',
    vendor_name: '',
    filter: '""',
    ordered: '""',
    received: '""'
};
const myFilters = _.cloneDeep(defaultFilters);
const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    filters: myFilters
};

const BotParts = React.forwardRef(
    /**
     *
     * @param props
     * @param props.tblId
     * @param props.lStorage
     * @param props.remote
     * @param props.pageSizes
     * @param props.getParts
     * @param props.updatePart
     * @param props.workorder
     * @param props.createPart
     * @param props.columns
     * @param props.unit
     * @param props.canCreate
     * @param props.getMyWorkOrder
     * @param props.showTitle
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.loading
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {

    //<editor-fold desc="Column Refs">
    const numberRef = React.useRef();
    const descriptionRef = React.useRef();
    const costRef = React.useRef();
    const qtyRef = React.useRef();
    const vendorRef = React.useRef();
    const filterRef = React.useRef();
    //</editor-fold>
    const [state, updateState] = React.useState({
        ...defaultState,
        tblId: props.tblId,
        lStorage: props.lStorage,
        remote: props.remote,
        selectedRow: null,
        selectedCell: null,
        pageSizes: props.pageSizes,
        newModal: false
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));

    const prepareBool = (cell) => {
        return cell ? 'Yes' : 'No';
    };

    const toggleNewModal = () => {
        setState({
            newModal: !state.newModal
        });
    };

    const refreshParts = () => {
        const tbl = ref?.current;
        const req = {
            sortName: tbl?.state.sortName,
            sortOrder: tbl?.state.sortOrder,
            currentPage: tbl?.state.currPage,
            sizePerPage: tbl?.state.currSize,
            filters: tbl?.state.filters
        };
        props.remote ? props.getParts(req) : props.getParts();
    };

    const createNewBtn = () => {
        return(
            <DropdownItem outline color={'texts'} onClick={() => {
                toggleNewModal();
            }}><i className={'fa fa-plus'}/> New</DropdownItem>
        )
    };

    const updatePart = (pa) => {
        props.updatePart(pa);
        setState({
            selectedRow: null,
            selectedCell: null
        });
    };

    const onSavePart = (pa) => {
        let part = pa;
        !props.workorder && delete part.workorder_id;
        (!props.unit && !props.workorder) && delete part.unit_id;
        props.createPart(part);
        toggleNewModal();
    };

    let {pageSizes} = state;
    let {title, data, workorder, unit, vendorList} = props;
    const filters = defaultFilters;
    const columns = props.columns ? props.columns : [
        {
            field: 'number',
            text: 'Part#',
            filter: {
                type: 'text',
                placeholder: 'Enter Part#',
                defaultValue: filters.number
            },
            width: 200,
            hidden: false,
            refProp: numberRef,
            sortable: true
        },
        {
            field: 'description',
            text: 'Description',
            filter: {
                type: 'text',
                placeholder: 'Enter Description',
                defaultValue: filters.description
            },
            width: 350,
            hidden: false,
            refProp: descriptionRef,
            sortable: true
        },
        {
            field: 'unit_cost',
            text: 'Cost',
            filter: {
                type: 'text',
                placeholder: 'Enter Cost',
                defaultValue: filters.unit_cost
            },
            width: 200,
            hidden: false,
            refProp: costRef,
            sortable: true
        },
        {
            field: 'qty',
            text: 'Qty',
            filter: {
                type: 'text',
                placeholder: 'Enter Qty',
                defaultValue: filters.qty
            },
            width: 200,
            hidden: false,
            refProp: qtyRef,
            sortable: true
        },
        {
            field: 'vendor_name',
            text: 'Service Provider',
            filter: {
                type: 'text',
                placeholder: 'Enter Service Provider Name',
                defaultValue: filters.vendor_name
            },
            width: 350,
            hidden: false,
            refProp: vendorRef,
            sortable: true
        },
        {
            field: 'filter',
            text: 'Filter',
            filter: {
                type: 'select',
                placeholder: 'Select Filter',
                defaultValue: filters.filter,
                options: [{value: '""', label: 'Select Filter'}, {value: true, label: 'Yes'}, {value: false, label: 'No'}]
            },
            width: 150,
            hidden: false,
            refProp: filterRef,
            sortable: true,
            format: (cell) => prepareBool(cell)
        }
    ];
    return (
        <div>
            {vendorList && vendorList.length > 0 &&
            <CreatePart toggle={() => toggleNewModal()} isOpen={state.newModal} vendorList={vendorList} workorder={workorder} unit={unit} onSavePart={(pa) => onSavePart(pa)}/>}
            <BotTable ref={ref}
                      columns={columns}
                      title={title}
                      data={data}
                      getData={(req) => props.getParts(req)}
                      pageSizes={pageSizes}
                      ClearFiltersBtn={true}
                      ClearFiltersIcon={true}
                      ClearSortBtn={true}
                      ClearSortIcon={true}
                      CsvDownloadBtn={true}
                      CsvDownloadIcon={true}
                      CreateNewBtn={props.canCreate && createNewBtn}
                      pagination={'both'}
                      headers={true}
                      doRowUpdate={(pa) => updatePart(pa)}
                      refreshData={() => refreshParts()}
                      btnGroupDirection={'left'}
                      remote={props.remote}
                      lStorage={props.lStorage}
                      defaultFilters={filters}
                      defaultState={defaultState}
                      showTitle={props.showTitle}
                      loading={props.loading}
                      csvFile={props.csvFile}
                      csvUrl={props.csvUrl}/>
        </div>
    );
});

BotParts.propTypes = propTypes;
export default BotParts;
