export const tireSizeConstants = {
    GET_ALL_TIRE_SIZES_REQUEST: 'GET_ALL_TIRE_SIZES_REQUEST',
    GET_ALL_TIRE_SIZES_SUCCESS: 'GET_ALL_TIRE_SIZES_SUCCESS',
    GET_ALL_TIRE_SIZES_FAILURE: 'GET_ALL_TIRE_SIZES_FAILURE',

    TIRE_SIZE_DETAIL_REQUEST: 'TIRE_SIZE_DETAIL_REQUEST',
    TIRE_SIZE_DETAIL_SUCCESS: 'TIRE_SIZE_DETAIL_SUCCESS',
    TIRE_SIZE_DETAIL_FAILURE: 'TIRE_SIZE_DETAIL_FAILURE',

    TIRE_SIZE_CREATE_REQUEST: 'TIRE_SIZE_CREATE_REQUEST',
    TIRE_SIZE_CREATE_SUCCESS: 'TIRE_SIZE_CREATE_SUCCESS',
    TIRE_SIZE_CREATE_FAILURE: 'TIRE_SIZE_CREATE_FAILURE',

    TIRE_SIZE_UPDATE_REQUEST: 'TIRE_SIZE_UPDATE_REQUEST',
    TIRE_SIZE_UPDATE_SUCCESS: 'TIRE_SIZE_UPDATE_SUCCESS',
    TIRE_SIZE_UPDATE_FAILURE: 'TIRE_SIZE_UPDATE_FAILURE',

    TIRE_SIZE_DELETE_REQUEST: 'TIRE_SIZE_DELETE_REQUEST',
    TIRE_SIZE_DELETE_SUCCESS: 'TIRE_SIZE_DELETE_SUCCESS',
    TIRE_SIZE_DELETE_FAILURE: 'TIRE_SIZE_DELETE_FAILURE',
};