import React from 'react';
import PropTypes from 'prop-types';
import {Input, InputGroup, InputGroupAddon} from './index';
const BotTableFilters = props => {
    const {
        field,
        type,
        options,
        placeholder,
        sendFilter,
        filter_value,
        changeSelectProps,
        changeProps,
        changeDateOpProp,
        changeDateProp
    } = props;

    let table_keys = 0;

    switch (type) {
        case 'select':
            return (
                <Input type={'react-select'} id={field + '_filter' + table_keys++} onChange={(e) => changeSelectProps(e)} value={filter_value !== "" ? options.find(o => o.value === filter_value) : null} options={options}/>
            );
        case 'text':
            return (
                <Input id={field + '-tooltip'}
                       name={field}
                       className={`bot-table-text-filter ${filter_value?.length > 0 && 'has-text'}`}
                       value={filter_value}
                       onMouseDown={e => e.stopPropagation()}
                       onKeyDown={e => e.key === 'Enter' && sendFilter({
                           value: e.target['value'], sent: true
                       })}
                       type={type}
                       placeholder={placeholder}
                       onChange={(e) => changeProps(e)}/>
            );
        case 'date':
            return (
                <InputGroup>
                    <InputGroupAddon
                        addonType={'prepend'}>
                        <Input
                            type={'select'}
                            className={'bot-table-date-select-filter'}
                            value={filter_value && filter_value.op}
                            onChange={(e) => changeDateOpProp(e)}>
                            <option
                                value={'>'}>
                                {'>'}
                            </option>
                            <option
                                value={'='}>
                                =
                            </option>
                            <option
                                value={'<'}>
                                {'<'}
                            </option>
                        </Input>
                    </InputGroupAddon>
                    <Input
                        className={'bot-table-datepicker'}
                        value={filter_value && filter_value.date}
                        type={'date'}
                        onChange={(e) => changeDateProp(e)}
                    />
                </InputGroup>
            );
        default:
            return (
                <div/>
            );
    }
};
BotTableFilters.propTypes = {
    field: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    changeSelectProps: PropTypes.func,
    changeProps: PropTypes.func,
    changeDateOpProp: PropTypes.func,
    changeDateProp: PropTypes.func
};
export default BotTableFilters;
