import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {logActions, unitActions, userActions, workorderActions} from '../../network/actions';
import BotInspectionTable from '../Inspections/BotInspections';
import ReduxBlockUi from 'react-block-ui';
import {authHeader, store} from '../../helpers';
import {baseUrl} from '../../network/constants';
import {Label} from '../../components/bot-components';

class InspectionExceptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            myLogs: []
        };
        this.leRef = React.createRef();
    }

    componentDidMount() {
        this.props.clearUnit();
        localStorage.setItem('lastComp', 'exceptions/inspections');
    }

    getLogs(req) {
        this.setState({
            loading: true
        }, () => {
            const requestOptions = {
                method: 'PUT',
                headers: authHeader('PUT'),
                body: JSON.stringify(req)
            };

            return fetch(`${baseUrl}/reports/dot_inspections/`, requestOptions).then(
              /**
               *
               * @param {Response} response
               * @returns {*|Promise<never>}
               */
              response => {
                return response.status !== 404 ? response.json().then(async data => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            alert('You are not authorized to access this information. This incident has been logged, please contact the administrator!');
                        }
                        return this.setState({
                            myLogs: [],
                            loading: false
                        }, () => alert('There was an error loading the inspections, please try again...'));
                    }

                    const Auth = JSON.parse(response.headers.get('Authentication'));
                    if (Auth) {
                        // noinspection JSCheckFunctionSignatures
                        await store.dispatch(userActions.updateAuth(Auth, null));
                    }
                    if (data) {
                        return this.setState({
                            myLogs: data,
                            loading: false
                        });
                    } else return this.setState({
                        myLogs: [],
                        loading: false
                    });
                }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
            });
        });
    }

    render() {
        const {workorder, unit, log} = this.props;
        const {myLogs, loading} = this.state;
        return(
            <ReduxBlockUi blocking={loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                <BotInspectionTable ref={this.leRef}
                                    getInspections={(req) => this.getLogs(req)}
                                    getMyInspection={(id) => this.props.getLog(id)}
                                    getMyUnit={(id) => this.props.getUnit(id)}
                                    getMyWorkOrder={(id) => this.props.getWorkorder(id)}
                                    title={'DOT - No Post-Trip'}
                                    actionCount={2}
                                    data={myLogs ? myLogs : null}
                                    viewAction={true}
                                    popoutAction={true}
                                    onRowSelected={(id) => {this.props.getMyLog(id)}}
                                    unit={unit} tblId={'leRef'}
                                    csvFile={'dot_inspection_exceptions.csv'}
                                    csvUrl={'reports/dot_inspections'}
                                    remote={true}
                                    pageSizes={['20', '50', '100', '500']}
                                    lStorage={'dot_inspection_exceptions_storage'}
                                    workorder={workorder}
                                    loading={loading}
                                    u_preferences={this.props.user_preference}
                                    inspection={log}
                />
            </ReduxBlockUi>
        )
    }
}

function mapStateToProps(state) {
    const{unit, workorder, log, user_preference} = state;
    return{unit, workorder, log, user_preference};
}
function mapDispatchToProps(dispatch) {
    return{
        clearUnit: () => dispatch(unitActions.clearUnit()),
        getLog: (id) => {dispatch(logActions.getDetail(id))},
        getUnit: (id) => {dispatch(unitActions.getUnit(id))},
        getWorkorder: (id) => {dispatch(workorderActions.getDetail(id))}
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionExceptions));
