/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from '../../components/bot-components';
import {faultActions} from '../../network/actions';
import BotFaults from "./BotFaults";
const FaultTable = (props) => {
    const [state, updateState] = React.useState({
        activeTab: localStorage.getItem('faultTab') ?? '1'
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const allRef = React.useRef();
    const usRef = React.useRef();
    React.useEffect(() => {
        props.getFaults();
        props.getUnassigned();
        localStorage.setItem('lastComp', props.history?.location?.pathname);
        localStorage.setItem('faultTab', state.activeTab);
        return () => localStorage.setItem('faultTab', state.activeTab);
    }, []);
    const toggle = (tab) => {
        setState({
            activeTab: tab,
        });
        localStorage.setItem('faultTab', tab);
    };
    return (
        <Card>
            <CardHeader>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({active: state.activeTab === '1'})}
                                 onClick={() => {toggle('1')}}
                        >All Fault Codes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({active: state.activeTab === '2'})}
                                 onClick={() => {toggle('2')}}
                        >Unassigned Fault Codes
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardHeader>
            <CardBody>
                <TabContent activetab={state.activeTab}>
                    <TabPane tabId={'1'}>
                        <BotFaults ref={allRef}
                                   faults={props.faults?.items}
                                   csvFile={'all_faults.csv'}
                                   showTitle={true}
                                   title={'PLM Fault List'}
                                   getFault={(id) => props.getFault(id)}
                                   remote={false} loading={props.faults.loading}
                                   data={props.faults?.items}
                                   pageSizes={['20', '50', '100', '500']}
                                   getFaults={props.getFaults}/>
                    </TabPane>
                    <TabPane tabId={'2'}>
                        <BotFaults ref={usRef}
                                   faults={props.unassigned_faults?.items}
                                   csvFile={'unassigned_faults.csv'}
                                   showTitle={true}
                                   title={'Unassigned Fault List'}
                                   getFault={(id) => props.getFault(id)}
                                   remote={false} loading={props.unassigned_faults.loading}
                                   data={props.unassigned_faults?.items}
                                   pageSizes={['20', '50', '100', '500']}
                                   getFaults={props.getUnassigned}/>
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    );
};
const mapStateToProps = (state) => {
    const {faults, unassigned_faults} = state;

    return {faults, unassigned_faults};
};
const mapDispatchToProps = (dispatch) => {
    return {
        getFaults: () => dispatch(faultActions.getFaults()),
        getFault: (id) => dispatch(faultActions.getFault(id)),
        getUnassigned: () => dispatch(faultActions.getUnassigned()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FaultTable);
