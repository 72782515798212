import React, { useState } from 'react';
import { attributeActions, unitSubTypeActions, unitTypeActions, userActions } from '../../network/actions';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import {
  Button,
  Col,
  DropdownItem,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from '../../components/bot-components';
import UnitClassTable from './BotUnitClasses';
import { authHeader, store } from '../../helpers';
import { baseUrl } from '../../network/constants';
import ReduxBlockUi from 'react-block-ui';

const UnitSubTypes = ( props ) => {
  const [newModal, setNewModal] = useState( false );
  const [newClassType, setNewClassType] = useState( null );
  const [newClassSubType, setNewClassSubType] = useState( '' );
  const [pagSubtypes, setPagSubtypes] = useState( [] );
  const [loading, setLoading] = useState( false );
  const [checkListCreated, setCheckListCreated] = useState( false );
  const [unit_subtype, setUnit_subtype] = useState( null );

  const unClass = React.useRef();

  const getUnitSubType = ( id ) => {
    if (id) {
      setLoading( true );
      const requestOptions = {
        method:'GET',
        headers:authHeader( 'GET' )
      };
      const url = new URL( `${ baseUrl }/unit_subtype/${ id }` );

      return fetch( url, requestOptions ).then( response => {
        return response.status !== 404 ? response.json().then( async data => {
          if (!response.ok) {
            if (response.status === 401) {
              alert( 'You are not authorized to access this information. This incident has been logged, please contact the administrator!' );
            }
            setUnit_subtype( null );
            setLoading( false );
            return alert( 'There was an error loading the work orders, please try again...' );
          }
          const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
          if (Auth) {
            await store.dispatch( userActions.updateAuth( Auth, null ) );
          }
          if (data) {
            setUnit_subtype( data );
            return setLoading( false );

          } else {
            setUnit_subtype( null );
            return setLoading( false );
          }
        } ).catch( error => Promise.reject( error ) ) : Promise.reject( {
          message:response.statusText,
          status:response.status
        } );
      } );
    } else return setLoading( false );
  };

  const updateUnitSubType = ( unit_subtype ) => {
    setLoading( true );
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( unit_subtype )
    };

    const url = new URL( `${ baseUrl }/unit_subtype/${ unit_subtype.id }` );
    return fetch( url, requestOptions ).then( response => {
      return response.status !== 404 ? response.json().then( async data => {
        if (!response.ok) {
          if (response.status === 401) {
            alert( 'You are not authorized to access this information. This incident has been logged, please contact the administrator!' );
          }
          setLoading( false );
          return alert( 'There was an error loading the work orders, please try again...' );
        }

        const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
        if (Auth) {
          await store.dispatch( userActions.updateAuth( Auth, null ) );
        }
        if (data) {
          return getUnitSubType( unit_subtype.id );
        } else return setLoading( false );
      } ).catch( error => Promise.reject( error ) ) : Promise.reject( {
        message:response.statusText,
        status:response.status
      } );
    } );
  };

  const toggleNew = () => {
    setNewModal( prevState => !prevState );
  };

  const createNewClass = async () => {
    const UnitClass = {
      subtype:newClassSubType,
      unit_type_id:newClassType,
    };
    await props.createUnitSubType( UnitClass );
    setNewClassSubType( '' );
    setNewClassType( null );
    setNewModal( false );
  };

  const getPagUnitSubTypes = ( req ) => {
    setLoading( true );
    const requestOptions = {
      method:'PUT',
      headers:authHeader( 'PUT' ),
      body:JSON.stringify( req )
    };
    const url = new URL( `${ baseUrl }/unit_subtypes` );

    return fetch( url, requestOptions ).then( response => {
      return response.status !== 404 ? response.json().then( async data => {
        if (!response.ok) {
          if (response.status === 401) {
            alert( 'You are not authorized to access this information. This incident has been logged, please contact the administrator!' );
          }
          setPagSubtypes( [] );
          setLoading( false );
          setCheckListCreated( false );
          return alert( 'There was an error loading the work orders, please try again...' );
        }

        const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
        if (Auth) {
          await store.dispatch( userActions.updateAuth( Auth, null ) );
        }
        if (data) {
          setPagSubtypes( [...data.map( d => ({ ...d, unit_type:d.unit_type?.utype }) )] );
          setCheckListCreated( false );
          return setLoading( false );
        } else {
          setPagSubtypes( [] );
          setCheckListCreated( false );
          return setLoading( false );
        }
      } ).catch( error => Promise.reject( error ) ) : Promise.reject( {
        message:response.statusText,
        status:response.status
      } );
    } );
  };

  const CreateNewTypeBtn = () => (
    <DropdownItem color={ 'texts' } outline onClick={ toggleNew }><i className={ 'fa fa-plus' } style={ {
      marginRight:'0.9rem',
      paddingLeft:'0.1rem'
    } }/>New Type</DropdownItem>);

  return (
    <ReduxBlockUi blocking={ props.unit_subtypes.loading || props.unit_subtype.loading || loading } tag={ 'div' }
                  message={ <Label className={ 'blocking-loading-lbl' }>Please Wait</Label> }>
      <Modal centered isOpen={ newModal } toggle={ () => toggleNew() }>
        <ModalHeader toggle={ () => toggleNew() }>
          Create New Class
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={ 12 }>
              <Label for={ 'new-class-type-select' }>Select the Type for the new Class</Label>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <Select id={ 'new-class-type-select' } options={ props.typeList }
                      value={ props.typeList?.find( tl => tl.value === newClassType ) }
                      onChange={ ( e ) => setNewClassType( e ? e.value : null ) }/>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <Label for={ 'new-class-subtype' }>Enter a new name for the Class</Label>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <Input id={ 'new-class-subtype' } type={ 'text' }
                     onChange={ e => setNewClassSubType( e.target.value ) }/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button disabled={ !newClassType || newClassSubType === '' } outline color={ 'texts' }
                  onClick={ () => createNewClass() }>Save</Button>
        </ModalFooter>
      </Modal>
      <UnitClassTable
        ref={ unClass }
        getUnitSubTypes={ ( req ) => getPagUnitSubTypes( req ) }
        getUnitSubType={ ( id ) => getUnitSubType( id ) }
        title={ 'Work Orders over 45 days' }
        actionCount={ 2 }
        unit_types={ props.unit_types }
        data={ pagSubtypes }
        viewAction={ true }
        popoutAction={ true }
        getUnitType={ ( id ) => getUnitSubType( id ) }
        unit_subtype={ unit_subtype }
        tblId={ 'unClass' }
        canCreate={ true }
        CreateNewBtn={ CreateNewTypeBtn }
        updateUnitSubType={ ( ust ) => updateUnitSubType( ust ) }
        csvFile={ 'equipment_classes.csv' }
        csvUrl={ 'unit_subtypes' }
        lStorage={ 'unit_subtype_storage' }
        remote={ true }
        checkListCreated={ checkListCreated }
        pageSizes={ ['20', '50', '100', '500', '1000'] }
        loading={ false }
        u_preferences={ props.user_preference }
        history={ props.history }
      />
    </ReduxBlockUi>
  );
};

function mapStateToProps( state ) {
  const { unit_subtype, pag_unit_subtypes, unit_subtypes, unit_types } = state;
  const typeList = unit_types?.items?.map( ut => ({ label:ut.utype, value:ut.id }) );
  const classList = [];
  const classWithList = [];
  unit_subtypes.items && unit_subtypes.items.map( ust => {
    ust.list_count === true && classWithList.push( { label:ust.subtype, value:ust.id } );
    return classList.push( { label:ust.subtype, value:ust.id } );
  } );

  return { unit_subtype, unit_subtypes, pag_unit_subtypes, typeList, classList, classWithList };
}

function mapDispatchToProps( dispatch ) {
  return {
    getUnitSubTypes:( req ) => dispatch( unitSubTypeActions.getAll( req ) ),
    getUnitTypes:() => dispatch( unitTypeActions.getAll() ),
    getChecks:( id ) => dispatch( attributeActions.getByClass( id ) ),
    createUnitSubType:( unit_subtype ) => dispatch( unitSubTypeActions.createSubType( unit_subtype ) ),
  };
}

export default connect( mapStateToProps, mapDispatchToProps )( UnitSubTypes );
