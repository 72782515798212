import PropTypes from 'prop-types';
import _ from 'lodash';
import {_dateFormat, getColumn} from '../../../helpers';
import {editButton} from './BotTasks.helpers';
import React from 'react';

export const propTypes = {
    ref: PropTypes.node,
    title: PropTypes.string,
    data: PropTypes.array,
    getTasks: PropTypes.func.isRequired,
    getMyWorkOrder: PropTypes.func.isRequired,
    getMyUnit: PropTypes.func.isRequired,
    getMyTask: PropTypes.func.isRequired,
    unit: PropTypes.object,
    editAction: PropTypes.bool,
    viewAction: PropTypes.bool,
    popoutAction: PropTypes.bool,
    alertAction: PropTypes.bool,
    actionCount: PropTypes.number,
    updateTask: PropTypes.func,
    priorityList: PropTypes.array,
    csvFile: PropTypes.string,
    csvUrl: PropTypes.string,
    lStorage: PropTypes.string,
    workorder: PropTypes.object,
    task: PropTypes.object,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    getMyAlert: PropTypes.func,
    canCreate: PropTypes.bool,
    createTask: PropTypes.func,
    loading: PropTypes.bool,
    u_preferences: PropTypes.any,
    parentComp: PropTypes.string
};
export const defaultFilters = {
    id: "",
    workorder_id: "",
    unit_number: "",
    description: "",
    yard: "",
    related_id: "",
    complete: "",
    dot: "",
    certified: "",
    certified_by: "",
    certified_on: "",
    unit_status: "",
    task_priority: "",
    vendor: "",
    completed_by: ""
};
const defaultDateRange = {
    date_range: "30"
};
const myFilters = {..._.cloneDeep(defaultFilters), ..._.cloneDeep(defaultDateRange)};
export const defaultState = {
    sortName: '',
    sortOrder: '',
    currSize: 20,
    currPage: 1,
    pageSizes: ['20', '50'],
    filters: myFilters,
    unitModal: false,
    workorderModal: false,
    alertModal: false,
    taskModal: false,
    selectedVendor: null,
    selectedMechanic: null,
    assigned_to_mechanic: null,
    detailType: true
};
const prepareTask = (cell, row, props, toggleTaskModal) => {
    const modalTask = () => {
        props.getMyTask(cell);
        toggleTaskModal(row);
    }
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={modalTask}>{cell}</div>
    );
};
const prepareAlert = (cell, props, toggleAlertModal) => {
    const handleClick = () => {
        props.getMyAlert(cell);
        toggleAlertModal();
    };
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={handleClick}>{cell}</div>
    );
};

/**
 *
 * @param cell
 * @param row
 * @param row.mechanic_name
 * @param row.vendor_name
 * @returns {string|*}
 */
const prepareVendor = (cell, row) => {
    if (row.mechanic_name) {
        return row.mechanic_name;
    } else if (row.vendor_name) {
        return row.vendor_name;
    } else {
        return '""';
    }
};
const prepareUnit = (cell, row, props, toggleUnitModal) => {
    const modalUnitClick = () => {
        props.getMyUnit(row.unit_id);
        toggleUnitModal();
    }
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={modalUnitClick}>{cell}</div>
    );
};
const prepareWorkorder = (cell, props, toggleWorkorderModal) => {
    const handleWorkorderClick = () => {
        props.getMyWorkOrder(cell);
        localStorage.setItem('workorder_id', cell);
        toggleWorkorderModal();
    }
    return (
        <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={handleWorkorderClick}>{cell}</div>
    );
};
const prepareCompleted = (cell) => {
    return cell ? 'Complete' : 'Incomplete';
};
const prepareCertified = (cell) => {
    return cell ? 'Certified' : 'Opened';
};
const prepareDOT = (cell) => {
    return cell ? (<img className="dot-logo" src="" alt=""/>) : 'Non-DOT';
};
const styleUnitStatus = (cell) => {
    switch (cell) {
        case 'Down':
            return {color: 'black', backgroundColor: 'var(--light-red)', fontWeight: 'bold', textAlign: 'center'};
        case 'Needs Attention':
            return {color: 'black', backgroundColor: 'var(--light-yellow)', fontWeight: 'bold', textAlign: 'center'};
        case 3:
            return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
        default:
            return {color: 'black', backgroundColor: 'var(--light-green)', fontWeight: 'bold', textAlign: 'center'};
    }
};
const editAssigned = (cell, row, setState) => {
    setState({
        selectedRow: row,
        selectedCell: cell,
        AssignedEditor: true
    });
};
const editDescription = (row, cell, setState) => {
    setState({
        selectedRow: row,
        selectedCell: cell,
        descriptionEditor: true
    });
};
const editPriority = (row, cell, setState) => {
    setState({
        selectedRow: row,
        selectedCell: cell,
        priorityEditor: true
    });
};
export const getColumns = (props, defaultFilters, refs, setState, toggleTaskModal, toggleWorkorderModal, toggleUnitModal, toggleAlertModal) => [
    getColumn('button', 'Views', props.actionCount * 40, (cell, row) => editButton(cell, row, props, toggleTaskModal), false, null, false, null, null, null),
    getColumn('id', 'ID', 180, (cell, row) => prepareTask(cell, row, props, toggleTaskModal), false, refs[0], true, {
        type: 'text',
        placeholder: 'ID',
        defaultValue: defaultFilters.id
    }, null, null),
    getColumn('created_at', 'Created', 150, (cell) => _dateFormat(cell), false, null, true, null, null, null),
    getColumn('updated_at', 'Last Updated', 150, (cell) => _dateFormat(cell), false, null, true, null, null, null),
    getColumn('workorder_id', 'Work Order#', 100, props.parentComp !== 'workorder' ? (cell) => prepareWorkorder(cell, props, toggleWorkorderModal) : null, false, refs[1], true, {
        type: 'text',
        placeholder: 'Work Order#',
        defaultValue: defaultFilters.workorder_id
    }, null, null),
    getColumn('unit_number', 'Equipment#', 150, (cell, row) => prepareUnit(cell, row, props, toggleUnitModal), false, refs[2], true, {
        type: 'text',
        placeholder: 'Equipment#',
        defaultValue: defaultFilters.unit_number
    }, null, null),
    getColumn('description', 'Description', 200, null, false, refs[3], true, {
        type: 'text',
        placeholder: 'Description',
        defaultValue: defaultFilters.description
    }, {
        value: true,
        type: 'text',
        onClick: (row, cell) => editDescription(row, cell, setState)
    }, null),
    getColumn('yard', 'Location', 150, null, false, refs[4], true, {
        type: 'text',
        placeholder: 'Location',
        defaultValue: defaultFilters.yard
    }, null, null),
    getColumn('related_id', 'Source', 150, props.parentComp !== 'plm' ? cell => prepareAlert(cell, props, toggleAlertModal) : null, false, refs[5], true, {
        type: 'text',
        placeholder: 'Source',
        defaultValue: defaultFilters.related_id
    }, null, null),
    getColumn('complete', 'Status', 150, cell => prepareCompleted(cell), false, refs[6], true, {
        type: 'select',
        placeholder: 'Status',
        options: [{value: "", text: 'Select Status'}, {value: true, text: 'Complete'}, {value: false, text: 'Incomplete'}],
        defaultValue: defaultFilters.complete,
    }, null, null),
    getColumn('dot', 'D.O.T.', 150, cell => prepareDOT(cell), false, refs[7], true, {
        type: 'select',
        placeholder: 'DOT',
        options: [{value: "", text: 'Select DOT'}, {value: true, text: 'DOT'}, {value: false, text: 'Not-DOT'}],
        defaultValue: defaultFilters.dot,
    }, null, null),
    getColumn('certified', 'Certified', 150, cell => prepareCertified(cell), false, refs[8], true, {
        type: 'select',
        placeholder: 'Certified',
        options: [{value: "", text: 'Select Certified'}, {value: true, text: 'Certified'}, {value: false, text: 'Opened'}],
        defaultValue: defaultFilters.certified,
    }, null, null),
    getColumn('certified_by', 'Certified By', 150, null, false, refs[9], true, {
        type: 'text',
        placeholder: 'Certified By',
        defaultValue: defaultFilters.certified_by
    }, null, null),
    getColumn('certified_on', 'Certified On', 150, cell => _dateFormat(cell), false, null, true, null, null, null),
    getColumn('unit_status', 'Equip. Status', 150, null, false, refs[10], true, {
        type: 'text',
        placeholder: 'Equip. Status',
        defaultValue: defaultFilters.unit_status
    }, null, cell => styleUnitStatus(cell)),
    getColumn('task_priority', 'Priority', 150, null, false, refs[11], true, {
        type: 'select',
        placeholder: 'Priority',
        defaultValue: defaultFilters.task_priority,
        options: [{value: "", text: 'Select Priority'}, {value: 'High', text: 'High'}, {value: 'Medium', text: 'Medium'}, {value: 'Low', text: 'Low'}]
    }, {
        value: true,
        onClick: (row, cell) => editPriority(row, cell, setState)
    }, null),
    getColumn('vendor', 'Assigned', 150, (cell, row) => prepareVendor(cell, row), false, refs[12], true, {
        type: 'text',
        placeholder: 'Assigned',
        defaultValue: defaultFilters.vendor
    }, {
        value: true,
        onClick: (cell, row) => editAssigned(row, cell, setState)
    }, null),
    getColumn('completed_by', 'Completed By', 150, null, false, refs[13], true, {
        type: 'text',
        placeholder: 'Completed By',
        defaultValue: defaultFilters.completed_by
    }, null, null),
    getColumn('completed_on', 'Completed On', 150, cell => _dateFormat(cell), false, null, true, null, null, null)
];
