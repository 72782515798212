export const attachmentConstants = {
    CREATE_ATTACHMENT_REQUEST: 'ATTACHMENT_CREATE_REQUEST',
    CREATE_ATTACHMENT_SUCCESS: 'ATTACHMENT_CREATE_SUCCESS',
    CREATE_ATTACHMENT_FAILURE: 'ATTACHMENT_CREATE_FAILURE',

    UPDATE_ATTACHMENT_REQUEST: 'ATTACHMENT_UPDATE_REQUEST',
    UPDATE_ATTACHMENT_SUCCESS: 'ATTACHMENT_UPDATE_SUCCESS',
    UPDATE_ATTACHMENT_FAILURE: 'ATTACHMENT_UPDATE_FAILURE',

    GET_ALL_ATTACHMENTS_REQUEST: 'ATTACHMENTS_GET_ALL_REQUEST',
    GET_ALL_ATTACHMENTS_SUCCESS: 'ATTACHMENTS_GET_ALL_SUCCESS',
    GET_ALL_ATTACHMENTS_FAILURE: 'ATTACHMENTS_GET_ALL_FAILURE',

    DETAIL_ATTACHMENT_REQUEST: 'ATTACHMENT_DETAIL_REQUEST',
    DETAIL_ATTACHMENT_SUCCESS: 'ATTACHMENT_DETAIL_SUCCESS',
    DETAIL_ATTACHMENT_FAILURE: 'ATTACHMENT_DETAIL_FAILURE',
};
