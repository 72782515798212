export const JobConstants = {
    CREATE_JOB_REQUEST: 'JOB_CREATE_REQUEST',
    CREATE_JOB_SUCCESS: 'JOB_CREATE_SUCCESS',
    CREATE_JOB_FAILURE: 'JOB_CREATE_FAILURE',

    UPDATE_JOB_REQUEST: 'JOB_UPDATE_REQUEST',
    UPDATE_JOB_SUCCESS: 'JOB_UPDATE_SUCCESS',
    UPDATE_JOB_FAILURE: 'JOB_UPDATE_FAILURE',

    GET_ALL_JOBS_REQUEST: 'JOBS_GET_ALL_REQUEST',
    GET_ALL_JOBS_SUCCESS: 'JOBS_GET_ALL_SUCCESS',
    GET_ALL_JOBS_FAILURE: 'JOBS_GET_ALL_FAILURE',

    GET_YARD_JOBS_REQUEST: 'JOBS_GET_YARD_REQUEST',
    GET_YARD_JOBS_SUCCESS: 'JOBS_GET_YARD_SUCCESS',
    GET_YARD_JOBS_FAILURE: 'JOBS_GET_YARD_FAILURE',

    DETAIL_JOB_REQUEST: 'JOB_DETAIL_REQUEST',
    DETAIL_JOB_SUCCESS: 'JOB_DETAIL_SUCCESS',
    DETAIL_JOB_FAILURE: 'JOB_DETAIL_FAILURE',

    DELETE_JOB_REQUEST: 'JOB_DELETE_REQUEST',
    DELETE_JOB_SUCCESS: 'JOB_DELETE_SUCCESS',
    DELETE_JOB_FAILURE: 'JOB_DELETE_FAILURE',

    CLEAR_JOBS: 'CLEAR_JOBS',
};
