import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Nav, NavItem, NavLink, TabContent, TabPane/*, TabContent, TabPane*/} from '../../components/bot-components';
import classnames from 'classnames';
import {
    assetTypeActions,
    jobActions,
    roleActions,
    taskActions, taskPriorityActions,
    tireSizeActions, unitActions,
    unitStatusActions,
    userActions, vendorActions, workorderActions,
    yardActions
} from '../../network/actions';
import PerformanceDashboard from './PerformanceDashboard';
import InspectionPerformanceDashboard from './InspectionPerformanceDashboard';
import DOTDashboard from './DOTDashboard';
import BotGpsDataTable from '../GpsData/BotGpsDataTable';
import {dashboardActions} from '../../network/actions/dashboard.actions';
import {authHeader} from '../../helpers';
import {baseUrl} from '../../network/constants';
import {logOut} from '../../network/services/responseHandler';

const Dashboard = (props) => {
    const [state, updateState] = useState({
        activeTab: '1',
        kpiLoading: false,
        kpiData: null,
        kpiReportList: [],
        report_id: null,
        activities: [],
        activitiesLoading: false
    });

    /**
     *
     * @param {Object} newState
     */
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));
    const dGpsRef = React.useRef();

    useEffect(() => {
        if (localStorage.getItem('dbTab')) {
            const tab = localStorage.getItem('dbTab');
            setState({activeTab: tab});
        }
        localStorage.setItem('lastComp', '/dashboard');
    }, []);

    useEffect(() => {
        localStorage.setItem('dbTab', state.activeTab);
    }, [state.activeTab]);

    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab,
                kpiData: null
            });
            localStorage.setItem('dbTab', tab);
        }
    };

    const getEquipmentActivityReport = (filters, date) => {
        const days = filters.filters?.date_range;
        setState({
            activitiesLoading: true
        });
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify(days ? {days: days} : {date: date || new Date()})
        };

        return fetch(`${baseUrl}/reports/equipment_activity`, requestOptions).then(response => {
            response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    setState({
                        activities: [],
                        activitiesLoading: false
                    });
                    return alert('There was an error loading the work orders, please try again...');
                }

                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    props.updateAuth(Auth, null);
                }
                if (data) {
                    return setState({
                        activities: data,
                        activitiesLoading: false
                    });
                } else return setState({
                    activities: [],
                    activitiesLoading: false
                });
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };

    const changeReport = (id) => {
        const repId = parseInt(id);
        const getSn = !isNaN(repId);
        if (getSn) {
            props.getSnapshot(id);
        } else {
            props.getWo();
        }
    };

    const getReportList = (list) => {
        const requestOptions = {
            method: 'PUT',
            headers: authHeader('PUT'),
            body: JSON.stringify({list: list})
        };
        return fetch(`${baseUrl}/reports/get_list/`, requestOptions).then(response => {
            response.status === 401 ? logOut() : response.status !== 404 ? response.json().then(async data => {
                if (!response.ok) {
                    alert('There was an error loading the work orders, please try again...')
                }

                const Auth = JSON.parse(response.headers.get('Authentication'));
                if (Auth) {
                    await props.updateAuth(Auth, null);
                }
                if (data) {
                    return setState({
                        kpiReportList: data
                    });
                }
            }).catch(error => Promise.reject(error)) : Promise.reject({message: response.statusText, status: response.status});
        });
    };

    const updateMyTask = (row) => {
        const {updateTask} = props;
        const task = {
            id: row.id,
            description: row.description,
            task_priority_id: row.task_priority_id,
            vendor_id: row.vendor_id,
            mechanic_id: row.mechanic_id
        };
        updateTask(task);
    };

    return (
        <div>
            <Nav tabs>
                {<NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '1'})}
                             onClick={() => {
                                 toggle('1');
                             }}>
                        Work Orders
                    </NavLink>
                </NavItem>}
                {<NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '2'})}
                             onClick={() => {
                                 toggle('2');
                             }}>
                        Inspections
                    </NavLink>
                </NavItem>}
                {<NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '4'})}
                             onClick={() => {
                                 toggle('4');
                             }}>
                        DOT
                    </NavLink>
                </NavItem>}
                {<NavItem>
                    <NavLink style={{textDecoration: 'underline'}}
                             className={classnames({active: state.activeTab === '3'})}
                             onClick={() => {
                                 toggle('3');
                             }}>
                        Equipment Activity
                    </NavLink>
                </NavItem>}
            </Nav>
            <TabContent activetab={state.activeTab}>
                {<TabPane tabId={'1'}>
                    {state.activeTab === '1' &&
                    <PerformanceDashboard
                        myReport={props.woDashboard}
                        getKpis={() => props.getWo()}
                        getReportList={(list) => getReportList(list)}
                        changeReport={(id, dashboard) => changeReport(id, dashboard)}
                        getAllJobs={() => props.getAllJobs()}
                        getAllYards={() => props.getAllYards()}
                        getAssetTypes={() => props.getAssetTypes()}
                        getMyUnit={(id) => props.getMyUnit(id)}
                        getMyWorkorder={(id) => props.getMyWorkorder(id)}
                        Reps={state.kpiReportList}
                        unit={props.unit}
                        user_preference={props.user_preference}
                        report_id={state.report_id}
                        loading={props.woDashboard?.loading}
                        jobList={props.jobList}
                        yardList={props.yardList}
                        jobs={props.jobs && props.jobs.items}
                        getVendors={() => props.getVendors()}
                        getMechanics={() => props.getMechanics()}
                        getTireSizes={() => props.getTireSizes()}
                        getUnitStatuses={() => props.getUnitStatuses()}
                    />}
                </TabPane>}
                {<TabPane tabId={'2'}>
                    {state.activeTab === '2' &&
                    <TabPane tabId={'2'}>
                        <InspectionPerformanceDashboard
                            getKpis={() => props.getIns()}
                            getAllJobs={() => props.getAllJobs()}
                            getAllYards={() => props.getAllYards()}
                            user_preference={props.user_preference}
                            yards={props.yards && props.yards.items}
                            jobs={props.jobs && props.jobs.items}
                            report_id={state.report_id}
                            task={props.task}
                            unit={props.unit}
                            Reps={state.kpiReportList}
                            loading={props.insDashboard?.loading}
                            getMyUnit={(id) => props.getMyUnit(id)}
                            getMyWorkorder={(id) => props.getMyWorkorder(id)}
                            myReport={props.insDashboard}
                            getTaskPriorities={() => props.getTaskPriorities()}
                            updateTask={t => updateMyTask(t)}
                            getAssetTypes={() => props.getAssetTypes()}
                            mechanicList={props.mechanicList}
                            mechList={props.mechList}
                            PriorityList={props.PriorityList}
                            vendList={props.vendList}
                            workorder={props.workorder}
                            getTask={(id) => props.getTask(id)}
                            asset_types={props.asset_types}
                            tire_sizes={props.tire_sizes}
                            getUser={(id) => props.getUser(id)}
                            getAlert={(id) => props.getAlert(id)}
                            getVendors={() => props.getVendors()}
                            getMechanics={() => props.getMechanics()}
                            getTireSizes={() => props.getTireSizes()}
                            getUnitStatuses={() => props.getUnitStatuses()}/>
                    </TabPane>}
                </TabPane>}
                {<TabPane tabId={'3'}>
                    <BotGpsDataTable
                        ref={dGpsRef}
                        data={state.activities}
                        includeDateRange={true}
                        csvFile={'equipment_activity.csv'}
                        lStorage={'equipment_activity_storage'}
                        title={'Equipment Activity'}
                        loading={state.activitiesLoading}
                        pageSizes={['20', '50', '100', '500', '1000']}
                        tblId={'eaTblRef'}
                        remote={true}
                        useLength={true}
                        u_preferences={props.user_preference}
                        getExceptions={(days, date) => getEquipmentActivityReport(days, date)}
                        getUnit={(id) => props.getMyUnit(id)}
                        unit={props.unit}
                    />
                </TabPane>}
                {<TabPane tabId={'4'}>
                    <DOTDashboard myReport={props.dotDashboard}
                                  getKpis={() => props.getDot()}
                                  getAllJobs={() => props.getAllJobs()}
                                  getAllYards={() => props.getAllYards()}
                                  getAssetTypes={() => props.getAssetTypes()}
                                  getMyUnit={(id) => props.getMyUnit(id)}
                                  getMyWorkorder={(id) => props.getMyWorkorder(id)}
                                  unit={props.unit}
                                  Reps={state.kpiReportList}
                                  user_preference={props.user_preference}
                                  loading={props.dotDashboard?.loading}
                                  jobList={props.jobList}
                                  yardList={props.yardList}
                                  yards={props.yards && props.yards.items}
                                  jobs={props.jobs && props.jobs.items}
                                  getVendors={() => props.getVendors()}
                                  getMechanics={() => props.getMechanics()}
                                  getTireSizes={() => props.getTireSizes()}
                                  getUnitStatuses={() => props.getUnitStatuses()}
                                  report_id={state.report_id}
                                  task={props.task}
                                  getTaskPriorities={() => props.getTaskPriorities()}
                                  updateTask={t => updateMyTask(t)}
                                  mechanicList={props.mechanicList}
                                  mechList={props.mechList}
                                  PriorityList={props.PriorityList}
                                  vendList={props.vendList}
                                  workorder={props.workorder}
                                  getTask={(id) => props.getTask(id)}
                                  asset_types={props.asset_types}
                                  tire_sizes={props.tire_sizes}
                                  getUser={(id) => props.getUser(id)}
                                  getAlert={(id) => props.getAlert(id)}/>
                </TabPane>}
            </TabContent>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {workorder, unit, yards, asset_types, tire_sizes, jobs, user_preference, vendors, mechanics, task_priorities, insDashboard, woDashboard, dotDashboard, pag_users, users, authentication} = state;

    const vendorList = vendors?.items ? [{text: 'Select Service Provider', value: null}, ...vendors.items.map(v => ({text: v.name, value: v.id}))] : [{text: 'Select Service Provider', value: null}];
    const vendList = vendors?.items ? [{text: 'Select Service Provider', value: null}, ...vendors.items.map(v => ({text: v.name, value: v.id}))] : [{text: 'Select Service Provider', value: null}];
    const jobList = jobs?.items ? [{label: 'Select Job', value: null}, ...jobs.items.map(j => ({label: j.name, value: j.id}))] : [{label: 'Select Job', value: null}];
    const yardList = yards?.items ? [{label: 'Select Location', value: null}, ...yards.items.map(y => ({label: y.name, value: y.id}))] : [{label: 'Select Location', value: null}];
    const mechanicList = mechanics?.items ? [{label: 'Select Mechanic', value: null}, ...mechanics.items.map(m => ({label: m.name, value: m.id}))] : [{label: 'Select Mechanic', value: null}];
    const mechList = mechanics?.items ? [{text: 'Select Mechanic', value: null}, ...mechanics.items.map(m => ({text: m.name, value: m.id}))] : [{text: 'Select Mechanic', value: null}];
    let PriorityList = task_priorities?.items ? [{label: 'Select a priority', value: null}, task_priorities.items.map(t => ({label: t.priority, value: t.id}))] : [{label: 'Select a priority', value: null}];
    const BotModules = user_preference.items?.bot_modules;

    return {workorder, BotModules, jobList, asset_types, vendorList, mechList, tire_sizes, yardList, unit, user_preference, yards, jobs, vendList, mechanicList, PriorityList, insDashboard, woDashboard, dotDashboard, pag_users, users, authentication};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        getMyUnit: (id) => dispatch(unitActions.getUnit(id)),
        getAllYards: () => dispatch(yardActions.getAll()),
        getAllJobs: () => dispatch(jobActions.getAll()),
        updateWorkorder: (wo) => dispatch(workorderActions.updateWorkorder(wo)),
        getTireSizes: () => dispatch(tireSizeActions.getTireSizes()),
        getUnitStatuses: () => dispatch(unitStatusActions.getAll()),
        getVendors: () => dispatch(vendorActions.getAll()),
        getMechanics: () => dispatch(roleActions.getMechanics()),
        getAssetTypes: () => dispatch(assetTypeActions.getAssetTypes()),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
        getUser: (id) => dispatch(userActions.getDetail(id)),
        updateTask: (task) => dispatch(taskActions.updateTask(task)),
        updateAuth: (user, err) => dispatch(userActions.updateAuth(user, err)),
        getUsers: (req) => dispatch(userActions.getPag(req)),
        getAllUsers: () => dispatch(userActions.getAll()),
        getIns: () => dispatch(dashboardActions.getIns()),
        getWo: () => dispatch(dashboardActions.getWo()),
        getDot: () => dispatch(dashboardActions.getDot()),
        getSnapshot: (id) => dispatch(dashboardActions.getSnapshot(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
