import {logConstants} from '../constants';

export const logs = (state = {}, action) => {
    switch (action.type) {
        case logConstants.GET_ALL_LOGS_REQUEST:
            return {
                loading: true
            };
        case logConstants.GET_ALL_LOGS_SUCCESS:
            return {
                items: action.logs
            };
        case logConstants.GET_ALL_LOGS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const log = (state = {}, action) => {
    switch (action.type) {
        case logConstants.DETAIL_LOG_REQUEST:
            return {
                loading: true
            };
        case logConstants.DETAIL_LOG_SUCCESS:
            return {
                items: action.log
            };
        case logConstants.DETAIL_LOG_FAILURE:
            return {
                error: action.error
            };
        case logConstants.CREATE_LOG_REQUEST:
            return {
                loading: true
            };
        case logConstants.CREATE_LOG_SUCCESS:
            return {
                items: action.log
            };
        case logConstants.CREATE_LOG_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
};

export const attributes = (state = {}, action) => {
    switch (action.type) {
        case logConstants.GET_ALL_CHECKS_REQUEST:
            return {
                loading: true
            };
        case logConstants.GET_ALL_CHECKS_SUCCESS:
            return {
                items: action.inspection_checks
            };
        case logConstants.GET_ALL_CHECKS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
};
