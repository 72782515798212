import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input, Button} from '../../components/bot-components';
const propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    createYard: PropTypes.func
};
const CreateYard = (props) => {
    const {isOpen, toggle, createYard} = props;
    const [state, updateState] = React.useState({
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: ''
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };
    return (
        <Modal toggle={toggle} isOpen={isOpen}>
            <ModalHeader toggle={toggle}>
                Create New Location
            </ModalHeader>
            <ModalBody>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Name</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'name'} value={state.name} onChange={changeProps}/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Address</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'address'} value={state.address} onChange={changeProps}/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>City</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'city'} value={state.city} onChange={changeProps}/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>State</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'state'} value={state.state} onChange={changeProps}/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Zip</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'zip_code'} value={state.zip_code} onChange={changeProps}/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType={'prepend'}><InputGroupText>Phone#</InputGroupText></InputGroupAddon>
                    <Input type={'text'} name={'phone_number'} value={state.phone_number} onChange={changeProps}/>
                </InputGroup>
            </ModalBody>
            <ModalFooter>
                <Button outline color={'texts'} onClick={() => {createYard(state);toggle();}}>Save</Button><Button outline color={'texts'} onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
CreateYard.propTypes = propTypes;
export default CreateYard;
