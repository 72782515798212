/* eslint-disable react-hooks/exhaustive-deps */
// noinspection JSIncompatibleTypesComparison,JSCheckFunctionSignatures

import React, {forwardRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    CardTitle,
    CardText,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Toast,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    ButtonGroup
} from '../../components/bot-components';
import BotWorkOrders from "../WorkOrders/BotWorkOrders/BotWorkOrders";
import { exportComponentAsJPEG, exportComponentAsPDF } from 'react-component-export-image';
import ReduxBlockUi from 'react-block-ui';
import moment from 'moment';
import {_dateFormat} from '../../helpers';

const propTypes = {
    myReport: PropTypes.any,
    getAllJobs: PropTypes.func,
    getAllYards: PropTypes.func,
    getKpis: PropTypes.func,
    getReportList: PropTypes.func,
    getTireSizes: PropTypes.any,
    getAssetTypes: PropTypes.any,
    getUnitStatuses: PropTypes.func,
    getVendors: PropTypes.func,
    getMechanics: PropTypes.func,
    changeReport: PropTypes.func,
    jobList: PropTypes.any,
    yardList: PropTypes.any,
    unit: PropTypes.any,
    user_preference: PropTypes.any,
    loading: PropTypes.bool,
    report_id: PropTypes.any,
    Reps: PropTypes.array,
    getMyUnit: PropTypes.func,
    getMyWorkorder: PropTypes.func,
    updateWorkorder: PropTypes.func,
    jobs: PropTypes.array
};
const PerformanceDashboard = forwardRef((props, ref) => {
    const [state, updateState] = useState({
        modalTitle: '',
        showMyModal: false,
        autoDeleteToast: true,
        autoDeleteTime: 30000,
        toastPosition: 'top-left',
        totalWorkOrders: undefined,
        activeUnits: undefined,
        closeTotal: undefined,
        openTotal: undefined,
        dotWorkOrders: undefined,
        dotTasks: undefined,
        totalUnits: undefined,
        unitStatuses: undefined,
        yard_id: null,
        job_id: null,
        last_loaded: null,
        report_id: 'Now'
    });
    const tRef = React.createRef();
    const woModRef = React.createRef();
    const pRef = React.createRef();
    const setState = (newState) => {
        updateState({
            ...state,
            ...newState
        });
    };
    const {
        changeReport,
        myReport,
        Reps,
        loading,
        jobList,
        yardList,
        unit,
        getMyUnit,
        getMyWorkorder,
        user_preference,
        getAllJobs,
        getAllYards,
        getTireSizes,
        getAssetTypes,
        getUnitStatuses,
        getVendors,
        getMechanics,
        getReportList,
        updateWorkorder,
        jobs
    } = props;

    const showModal = (data, field, title) => {
        setState({
            modalTitle: title,
            showMyModal: true,
            modalBody: createWoModalBody(data, field)
        });
    };

    const hideModal = () => {
        setState({
            modalTitle: '',
            showMyModal: false,
            modalBody: null
        });
    };

    React.useEffect(() => {
        const fetchInitData = () => {
            getReportList('dashboard_scorecard');
            getAllJobs();
            getAllYards();
            getTireSizes();
            getAssetTypes();
            getUnitStatuses();
            getVendors();
            getMechanics();
        };
        fetchInitData();
    }, []);

    const createWoModalBody = (data, title) => {
        return (
            <BotWorkOrders
                ref={woModRef}
                data={data}
                tblId={'woModRef'}
                remote={false}
                unit={unit}
                actionCount={2}
                viewAction={true}
                popoutAction={true}
                title={'Unit Work Orders'}
                jobList={jobList}
                yardList={yardList}
                pageSizes={['20', '50', '100', '500', '1000']}
                updateWorkorder={(wo) => updateWorkOrder(wo)}
                getWorkorders={(id) => getMyUnit(id)}
                getMyWorkOrder={(id) => getMyWorkorder(id)}
                canCreate={false}
                // unitStatusList={this.props.unitStatusList}
                lStorage={'unit_detail_work_order_storage'}
                u_preferences={user_preference}
                csvFile={`${title}.csv`}
                getMyUnit={(id) => getMyUnit(id)}
                includeDateRange={false}/>
        );
    };

    const getTaskAgeAve = (tasks) => {
        let ave_days_open = 0;
        if (tasks && tasks.length > 0) {
            tasks.map(w => ave_days_open = ave_days_open + w.days_open);
            return Math.round(ave_days_open / tasks.length);
        } else return ave_days_open;
    };

    const createWorkOrderArray = (tasks, wos) => {
        if (tasks && tasks.length > 0 && wos) {
            const mySet = new Set();
            tasks.map(t => {
                const myWo = wos.filter(w => w.id === t.workorder_id)[0];
                if (myWo) return mySet.add(myWo); else return null;
            });
            return Array.from(mySet);
        } else return [];
    };

    React.useEffect(() => {
        if (myReport && Object.keys(myReport).includes('last_loaded')) {
            let totalTasks =  myReport.task_aging?.tasks ?? [];
            let totalWos = createWorkOrderArray(totalTasks, myReport.current_work_orders?.open);
            let totalAve = getTaskAgeAve(totalTasks);
            let dotTasks = myReport.dot_task_aging?.tasks ?? [];
            let dotWOs = createWorkOrderArray(dotTasks, myReport.current_work_orders?.open);
            let dotOpenAve = getTaskAgeAve(dotTasks);
            let allDot = totalWos.filter(w => w.dot === true);
            let heavyTasks = totalTasks.filter(t => t.unit_type === 'EQUIPMENT');
            let heavyWos = totalWos.filter(w => w.unit_type === 'EQUIPMENT');
            let heavyAve = getTaskAgeAve(heavyTasks);
            let otherTasks = totalTasks.filter(t => t.unit_type !== 'EQUIPMENT' && t.unit_type !== 'Heavy Trailer' && t.unit_type !== 'HEAVY TRUCK');
            let otherWos = totalWos.filter(t => t.unit_type !== 'EQUIPMENT' && t.dot === false);
            let otherAve = getTaskAgeAve(otherTasks);
            const inService = myReport?.unit_statuses?.filter(r => r.unit_status === 'In Service')[0] ?? null
            const needsAttention = myReport?.unit_statuses?.filter(r => r.unit_status === 'Needs Attention')[0] ?? null
            const atVendor = myReport?.unit_statuses?.filter(r => r.unit_status === 'At Vendor')[0] ?? null
            const down = myReport?.unit_statuses?.filter(r => r.unit_status === 'Down')[0] ?? null

            let myData = {
                totalWorkOrders: totalWos,
                totalAve: totalAve,
                pastDueWorkOrders: myReport.current_work_orders?.past_due ?? [],
                heavyWorkOrders: heavyWos,
                heavyOpenAve: heavyAve,
                activeUnits: myReport.active_units ?? [],
                closeTotal: myReport.closed_work_orders?.work_orders ?? [],
                openTotal: myReport.new_work_orders?.work_orders ?? [],
                dotWorkOrders: dotWOs,
                dotOpenAve: dotOpenAve,
                dotTasks: dotTasks,
                dotAverage: dotOpenAve,
                allDot: allDot,
                unitStatuses: myReport.unit_statuses,
                jobs: myReport.current_work_orders?.jobs ?? [],
                yards: myReport.current_work_orders?.yards ?? [],
                otherWorkOrders: otherWos,
                otherOpenAve: otherAve,
                down: down,
                inService: inService,
                needsAttention: needsAttention,
                atVendor: atVendor,
                last_loaded: myReport.last_loaded ? myReport.last_loaded : (report_id !== undefined && report_id !== 'Now')
            }
            setState({...myData});
        }
    }, [myReport]);

    const changeValue = (field, id) => {
        let myData = {};
        if (myReport) {
            let totalTasks = myReport.task_aging?.tasks ?? [];
            let totalWos = createWorkOrderArray(totalTasks, myReport.current_work_orders?.open);
            let totalAve = getTaskAgeAve(totalTasks);
            let dotTasks = myReport.dot_task_aging?.tasks ?? [];
            let dotWOs = createWorkOrderArray(dotTasks, myReport.current_work_orders?.open);
            let dotOpenAve = getTaskAgeAve(dotTasks);
            let allDot = totalWos.filter(w => w.dot === true);
            let heavyTasks = totalTasks.filter(t => t.unit_type === 'EQUIPMENT');
            let heavyWos = totalWos.filter(w => w.unit_type === 'EQUIPMENT');
            let heavyAve = getTaskAgeAve(heavyTasks);
            let otherTasks = totalTasks.filter(t => t.unit_type !== 'EQUIPMENT' && t.unit_type !== 'Heavy Trailer' && t.unit_type !== 'HEAVY TRUCK');
            let otherWos = totalWos.filter(t => t.unit_type !== 'EQUIPMENT' && t.dot === false);
            let otherAve = getTaskAgeAve(otherTasks);
            let inService = myReport.unit_statuses?.filter(r => r.unit_status === 'In Service')[0] ?? null
            let needsAttention = myReport.unit_statuses?.filter(r => r.unit_status === 'Needs Attention')[0] ?? null
            let atVendor = myReport.unit_statuses?.filter(r => r.unit_status === 'At Vendor')[0] ?? null
            let down = myReport.unit_statuses?.filter(r => r.unit_status === 'Down')[0] ?? null
            if (!isNaN(parseInt(id))) {
                const f_id = [];
                const yard_id = parseInt(id);
                if (field === 'yard_id') {
                    jobs.map(j => {
                        if (j.yard_id === yard_id) return f_id.push(j.id); else return null;
                    });
                } else f_id.push(yard_id);
                totalTasks = totalTasks.filter(r => r[field] === parseInt(id));
                totalWos = totalWos.filter(r => r[field] === parseInt(id));
                totalAve = getTaskAgeAve(totalTasks);
                dotWOs = dotWOs.filter(r => r[field] === parseInt(id));
                dotTasks = dotTasks.filter(r => r[field] === parseInt(id));
                dotOpenAve = getTaskAgeAve(dotTasks);
                allDot = allDot.filter(r => r[field] === parseInt(id));
                heavyTasks = heavyTasks.filter(r => r[field] === parseInt(id));
                heavyWos = heavyWos.filter(r => r[field] === parseInt(id));
                heavyAve = getTaskAgeAve(heavyTasks);
                otherTasks = otherTasks.filter(r => r[field] === parseInt(id));
                otherWos = otherWos.filter(r => r[field] === parseInt(id));
                otherAve = getTaskAgeAve(otherTasks);
                inService = inService.units ? {units: inService.units.filter(r => f_id.includes(r.job_id))} : {units: []};
                needsAttention = needsAttention.units ? {units: needsAttention.units.filter(r => f_id.includes(r.job_id))} : {units: []};
                atVendor = atVendor.units ? {units: atVendor.units.filter(r => f_id.includes(r.job_id))} : {units: []};
                down = down.units ? {units: down.units.filter(r => f_id.includes(r.job_id))} : {units: []};
                myData = {
                    totalWorkOrders: totalWos,
                    totalAve: totalAve,
                    heavyWorkOrders: heavyWos,
                    heavyOpenAve: heavyAve,
                    pastDueWorkOrders: myReport.current_work_orders?.past_due?.filter(w => w[field] === parseInt(id)) ?? [],
                    activeUnits: myReport.active_units?.filter(r => f_id.includes(r.job_id)) ?? null,
                    closeTotal: myReport.closed_work_orders?.work_orders?.filter(w => w[field] === parseInt(id)) ?? [],
                    openTotal: myReport.new_work_orders?.work_orders?.filter(w => w[field] === parseInt(id)) ?? [],
                    dotWorkOrders: dotWOs,
                    dotOpenAve: dotOpenAve,
                    dotTasks: dotTasks,
                    allDot: allDot,
                    otherWorkOrders: otherWos,
                    otherOpenAve: otherAve,
                    unitStatuses: myReport.unit_statuses,
                    down: down,
                    inService: inService,
                    needsAttention: needsAttention,
                    atVendor: atVendor
                }
            } else {
                myData = {
                    totalWorkOrders: totalWos,
                    totalAve: totalAve,
                    heavyWorkOrders: heavyWos,
                    heavyOpenAve: heavyAve,
                    pastDueWorkOrders:myReport.current_work_orders?.past_due ?? [],
                    activeUnits: myReport.active_units ?? [],
                    closeTotal: myReport.closed_work_orders?.work_orders ?? [],
                    openTotal: myReport.new_work_orders?.work_orders ?? [],
                    allDot: allDot,
                    dotWorkOrders: dotWOs,
                    dotOpenAve: dotOpenAve,
                    dotTasks: dotTasks,
                    otherWorkOrders: otherWos,
                    otherOpenAve: otherAve,
                    unitStatuses: myReport.unit_statuses,
                    down: down,
                    inService: inService,
                    needsAttention: needsAttention,
                    atVendor: atVendor
                }
            }
            setState({
                ...myData,
                yard_id: field === 'clear' ? 'All' : field === 'yard_id' ? id : !isNaN(parseInt(id)) ? 'All' : state.yard_id,
                job_id: field === 'clear' ? 'All' : field === 'job_id' ? id : !isNaN(parseInt(id)) ? 'All' : state.job_id,
                report_id: field === 'clear' ? 'Now' : report_id
            });
        }
    };

    const changeYard = (id) => {
        changeValue('yard_id', id);
    };

    const changeJob = (id) => {
        changeValue('job_id', id);
    };

    const updateWorkOrder = (wo) => {
        delete wo.show;
        updateWorkorder(wo);
    };

    const getCardStyle = (value, warn, danger) => {
        switch (true) {
            case value < warn:
                return 'good';
            case value < danger:
                return 'warn';
            default:
                return 'danger';
        }
    };

    const {totalWorkOrders, report_id, activeUnits, closeTotal, openTotal, dotWorkOrders, yard_id, job_id, pastDueWorkOrders, heavyWorkOrders, otherWorkOrders, last_loaded} = state;
    const openWoPercent = Math.round(((100 / (activeUnits?.length ?? 0)) * (totalWorkOrders?.length ?? 0)));
    const pastDuePercent = Math.round(((100 / (totalWorkOrders?.length ?? 0)) * (pastDueWorkOrders?.length ?? 0)));

    const clearFilters = () => {
        changeValue('clear');
        return (report_id !== undefined && report_id !== 'Now') && changeReport('Now', 'wo');
    };

    return(
        <Col ref={ref} sm={12} style={{paddingLeft: '0', paddingRight: '0'}}>
            <Toast
                ref={tRef}
                position={'top-left'}
                autoDelete={true}
                dismissTime={3000}
            />
            <Modal size={'xl'} centered isOpen={state.showMyModal} toggle={hideModal}>
                <ModalHeader style={{textAlign: 'center !important'}} toggle={hideModal}>
                    {state.modalTitle}
                </ModalHeader>
                <ModalBody>
                    {state.modalBody ? state.modalBody : 'This will hold the details...'}
                </ModalBody>
                <ModalFooter>
                    <Button outline color={'texts'} onClick={() => hideModal()}>Close</Button>
                </ModalFooter>
            </Modal>
            <Card block body className={'kpi-main-card'}>
                <Row style={{justifyContent: 'flex-end', paddingRight: '15px', paddingLeft: '15px'}}>
                    <Col sm={12}>
                        <Col sm={6}/>
                        <Row style={{justifyContent: 'flex-end'}}>
                            <div className={'dashboard-report-lst-run-lbl-container'}>
                                <Label className={'dashboard-report-lst-run-lbl1'}><span className={'text'}>Last loaded: </span></Label><Label className={'dashboard-report-lst-run-lbl2'}>{last_loaded ? last_loaded : 'None Loaded'}</Label>
                            </div>
                            <ButtonGroup role={'group'} className={'dashboard-btn-group'}>
                                <Button color={'texts'} outline onClick={() => {
                                    setState({
                                        yard_id: 'All',
                                        job_id: 'All',
                                        report_id: 'Now'
                                    });
                                    changeReport('Now', 'wo');
                                }}>Refresh</Button>
                                <Button outline className={`wo-dashboard-clr ${(
                                    (yard_id !== null && yard_id !== 'All')
                                    ||
                                    (job_id !== null && job_id !== 'All')
                                    ||
                                    (report_id !== undefined && report_id !== 'Now'))
                                    ? 'active' : '' }`} disabled={!(
                                    (yard_id !== null && yard_id !== 'All')
                                    ||
                                    (job_id !== null && job_id !== 'All')
                                    ||
                                    (report_id !== undefined && report_id !== 'Now'))}
                                        onClick={() => clearFilters()}
                                        color={'texts'}>Clear Filters</Button>
                                <ButtonDropdown color={'texts'} outline className={'wo-dashboard-dropdown nav-item'} isOpen={state.exportOpen} direction={'up'} toggle={() => setState({exportOpen: !state.exportOpen})}>
                                    <DropdownToggle color={'texts'} outline caret>
                                        Export
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem color={'texts'} outline onClick={() => {
                                            exportComponentAsPDF(pRef, {fileName: 'dashboard_scorecard.pdf', pdfOptions: {unit: 'px', orientation: 'l', w: 600, h: 400, x: 15, y: 25}}).then();
                                        }}>
                                            PDF
                                        </DropdownItem>
                                        <DropdownItem color={'texts'} outline onClick={() => {
                                            exportComponentAsJPEG(pRef, {fileName: 'dashboard_scorecard.jpg'}).then();
                                        }}>
                                            JPEG
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                        </Row>
                    </Col>
                </Row>
                <div ref={pRef} style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                    <Row>
                        <Col sm={3}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText className={`wo-dashboard-selection ${(report_id && report_id !== 'Now') ? 'selected' : ''}`}>
                                        Select Report
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id={'rep-history'} type={'select'} value={report_id} onChange={(e) => {
                                    setState({
                                        yard_id: 'All',
                                        job_id: 'All',
                                        report_id: e.target.value
                                    });
                                    changeReport(e.target.value, 'wo');
                                }}>
                                    <option value={''}>{'Now'}</option>
                                    {Reps?.map(r => <option value={r.id}>{r.to_char}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText className={`wo-dashboard-selection ${(yard_id !== null && yard_id !== 'All') ? 'selected' : ''}`}>
                                        Select Yard
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id={'rep-history1'} type={'select'} value={yard_id ?? null}
                                       onChange={(e) => changeYard(e.target.value)}>
                                    <option value={'All'}>All</option>
                                    {state.yards?.map(r => <option value={r.id}>{r.name}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText className={`wo-dashboard-selection ${(job_id !== null && job_id !== 'All') ? 'selected' : ''}`}>
                                        Select Job
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input id={'rep-history2'} type={'select'} value={job_id ?? null}
                                       onChange={(e) => changeJob(e.target.value)}>
                                    <option value={'All'}>All</option>
                                    {state.jobs?.map(r => <option value={r.id}>{r.name}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={3}>
                            <InputGroup inline>
                                <InputGroupAddon addonType={'prepend'}>
                                    <InputGroupText>Report Date</InputGroupText>
                                </InputGroupAddon>
                                <Input type={'text'} value={report_id && (report_id !== 'Now') ? Reps.filter(r => r.id === report_id)[0].to_char : _dateFormat(moment.now())} disabled={true}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <ReduxBlockUi blocking={loading} tag={'div'} message={<Label className={'blocking-loading-lbl'}>Please Wait</Label>}>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(openWoPercent, 11, 20)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Open Work Orders <span  className={'link-label-2'}
                                                                            onClick={() => showModal(totalWorkOrders, 'open_workorders', 'Open Work Orders', 'wo')}>{totalWorkOrders?.length ?? 0}</span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    Average Time Open {state.totalAve || 0} Days
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(state.totalAve, 14, 21)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Opened <span className={'link-label-2'}
                                                               onClick={() => showModal(openTotal, 'open_new_workorders', 'Work Orders open since last report', 'wo')}>
                                                {openTotal?.length ?? 0}
                                            </span> / Closed <span className={'link-label-2'}
                                                                   onClick={() => showModal(closeTotal, 'closed_workorders', 'Work Orders closed since last report', 'wo')}>
                                                {closeTotal?.length ?? 0}
                                            </span>
                                                </CardTitle>
                                                <CardText tag={'h6'} className={'text-center'}>
                                                    (This week)
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(pastDuePercent, 11, 25)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Past Due Work Orders <span className={'link-label-2'}
                                                                               onClick={() => showModal(pastDueWorkOrders, 'past_due_workorders', 'Past Due Work Orders', 'wo')}>{pastDueWorkOrders?.length ?? 0}</span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    {isNaN(pastDuePercent) ? 0 : pastDuePercent}%
                                                    of Open Work Orders!
                                                </CardText>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(state.dotOpenAve, 2, 4)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    D.O.T. Work Orders <span className={'link-label-2'}
                                                                             onClick={() => showModal(state.allDot, 'all_dot_work_orders', 'D.O.T. Work Orders', 'wo')}>
                                                {state.allDot?.length ?? 0}
                                            </span> / <span className={'link-label-2'}
                                                            onClick={() => showModal(dotWorkOrders, 'dot_work_orders', 'D.O.T. Work Orders with D.O.T. issues', 'wo')}>{dotWorkOrders?.length ?? 0}</span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    Average Time Open {state.dotOpenAve || 0} Days
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(state.heavyOpenAve, 14, 21)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    Heavy Equipment <span className={'link-label-2'}
                                                                          onClick={() => showModal(heavyWorkOrders, 'heavy_equipment_workorders', 'Heavy Equipment Work Orders', 'wo')}>{heavyWorkOrders?.length ?? 0}</span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    Average Time Open {state.heavyOpenAve ?? 0} Days
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col sm={4}>
                                            <Card block className={`kpi-quad ${getCardStyle(state.otherOpenAve, 14, 21)}`}>
                                                <CardTitle tag={'h3'} className={'text-center'}>
                                                    All Other Work Orders <span className={'link-label-2'}
                                                                                onClick={() => showModal(otherWorkOrders, 'other_equipment_workorders', 'Other-Equipment Work Orders', 'wo')}>
                                                {otherWorkOrders?.length ?? 0}
                                            </span>
                                                </CardTitle>
                                                <CardText tag={'h5'} className={'text-center'}>
                                                    Average Time Open {state.otherOpenAve ?? 0} Days
                                                </CardText>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className={'ticker-wrapper wo'}>
                                <div className={'ticker'}>
                                    {state.inService &&
                                    <div className={'ticker-item'}>
                                        In Service: {state.inService.units.length} ({activeUnits && activeUnits.length ? (Math.round(((100 / activeUnits.length) * (state.inService?.units?.length ?? 0)))) : 0}%)
                                    </div>}
                                    {state.needsAttention &&
                                    <div className={'ticker-item'}>
                                        Needs Attention: {state.needsAttention.units.length} ({activeUnits && activeUnits.length ? (Math.round(((100 / activeUnits.length) * (state.needsAttention?.units?.length ?? 0)))) : 0}%)
                                    </div>}
                                    {state.atVendor &&
                                    <div className={'ticker-item'}>
                                        At Vendor: {state.atVendor.units.length} ({activeUnits && activeUnits.length ? (Math.round(((100 / activeUnits.length) * (state.atVendor?.units?.length ?? 0)))) : 0}%)
                                    </div>}
                                    {state.down &&
                                    <div className={'ticker-item'}>
                                        Down: {state.down.units.length} ({activeUnits && activeUnits.length ? (Math.round(((100 / activeUnits.length) * (state.down?.units?.length ?? 0)))) : 0}%)
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    </ReduxBlockUi>
                </div>
            </Card>
        </Col>
    )
});

// noinspection JSValidateTypes
PerformanceDashboard.propTypes = propTypes;
export default PerformanceDashboard;

/** @namespace myReport */
/** @namespace myReport.current_work_orders **/
/** @namespace current_work_orders.past_due **/
/** @namespace myReport.active_units **/
/** @namespace myReport.closed_work_orders **/
/** @namespace myReport.new_work_orders **/
/** @namespace myReport.work_order_aging **/
/** @namespace closed_work_orders.work_orders **/
/** @namespace work_order_aging.average **/
/** @namespace myReport.dot_task_aging **/
/** @namespace w.days_open **/
/** @namespace r.to_char **/
/** @namespace myReport.task_aging **/
