import {commentConstants} from '../constants';
import {commentService} from '../services';
import {workorderActions} from "./workorder.actions";
import {attachmentActions} from "./attachment.actions";
import {unitActions} from "./unit.actions";

export const commentActions = {};
commentActions.getComments = () => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: commentConstants.GET_ALL_COMMENTS_REQUEST});
    const success = (comments) => ({type: commentConstants.GET_ALL_COMMENTS_SUCCESS, comments});
    const failure = (error) => ({type: commentConstants.GET_ALL_COMMENTS_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        commentService.getAll()
            .then(comments => dispatch(success(comments)), error => dispatch(failure(error)));
    };
};
commentActions.getComment = (id) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: commentConstants.DETAIL_COMMENT_REQUEST});
    const success = (comment) => ({type: commentConstants.DETAIL_COMMENT_SUCCESS, comment});
    const failure = (error) => ({type: commentConstants.DETAIL_COMMENT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        commentService.getDetail(id)
            .then(comment => dispatch(success(comment)), error => dispatch(failure(error)));
    };
};
commentActions.createComment = (Comment, attachment) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: commentConstants.CREATE_COMMENT_REQUEST});
    const success = (comment) => ({type: commentConstants.CREATE_COMMENT_SUCCESS, comment});
    const failure = (error) => ({type: commentConstants.CREATE_COMMENT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        commentService.createComment(Comment)
            .then(
                comment => {
                    dispatch(success(comment));
                    comment && (Comment.workorder_id ? dispatch(workorderActions.getDetail(Comment.workorder_id)) : Comment.unit_id && dispatch(unitActions.getUnit(Comment.unit_id)));
                    comment && attachment && dispatch(attachmentActions.getAttachment(Comment.image_id));
                }, error => dispatch(failure(error)));
    };
};
commentActions.updateComment = (comment) => {
    //<editor-fold desc="Dispatch results to state">
    const request = () => ({type: commentConstants.UPDATE_COMMENT_REQUEST});
    const success = (comment) => ({type: commentConstants.UPDATE_COMMENT_SUCCESS, comment});
    const failure = (error) => ({type: commentConstants.UPDATE_COMMENT_FAILURE, error});
    //</editor-fold>
    return dispatch => {
        dispatch(request());
        commentService.updateComment(comment)
            .then(comment => dispatch(success(comment)), error => dispatch(failure(error)));
    };
};
