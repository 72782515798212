import React, {forwardRef, useRef} from 'react';
import PropTypes from 'prop-types';
import {_dateFormat} from '../../helpers';
import {BotTable} from '../../components/bot-components';
import _ from 'lodash';

const propTypes = {
    ref: PropTypes.node,
    lStorage: PropTypes.string,
    csvFile: PropTypes.string,
    loading: PropTypes.bool,
    remote: PropTypes.bool,
    tblId: PropTypes.string,
    pageSizes: PropTypes.array,
    title: PropTypes.string,
    u_preferences: PropTypes.any,
    getHistories: PropTypes.func.isRequired
};

const XpiHistories = forwardRef((props, ref) => {

    const {loading, lStorage, csvFile, u_preferences, remote, getHistories, data, pageSizes} = props;

    const defaultFilters = {
        project: '',
        source: '',
        records: '',
        active: '',
        errors: '',
        created_at: ''
    };

    const columnRefs = {
        sourceRef: useRef(),
        projectRef: useRef(),
        recordsRef: useRef(),
        activeRef: useRef(),
        errorsRef: useRef(),
        dateRef: useRef()
    };

    const defaultColumns = () => {
        return [
            {
                field: 'project',
                text: 'Project',
                filter: {
                    type: 'text',
                    placeholder: 'Project',
                    defaultValue: ''
                },
                width: 180,
                hidden: false,
                refProp: columnRefs.projectRef,
                sortable: true
            },
            {
                field: 'source',
                text: 'Source',
                filter: {
                    type: 'text',
                    placeholder: 'Source',
                    defaultValue: ''
                },
                width: 180,
                hidden: false,
                refProp: columnRefs.sourceRef,
                sortable: true
            },
            {
                field: 'records',
                text: 'Records',
                filter: {
                    type: 'text',
                    placeholder: 'Records',
                    defaultValue: ''
                },
                width: 180,
                hidden: false,
                refProp: columnRefs.recordsRef,
                sortable: true
            },
            {
                field: 'active',
                text: 'Active',
                filter: {
                    type: 'text',
                    placeholder: 'Active',
                    defaultValue: ''
                },
                width: 180,
                hidden: false,
                refProp: columnRefs.activeRef,
                sortable: true
            },
            {
                field: 'errors',
                text: 'Errors',
                filter: {
                    type: 'text',
                    placeholder: 'Errors',
                    defaultValue: ''
                },
                width: 180,
                hidden: false,
                refProp: columnRefs.errorsRef,
                sortable: true
            },
            {
                field: 'created_at',
                text: 'Date',
                filter: {
                    type: 'text',
                    placeholder: 'Date',
                    defaultValue: ''
                },
                width: 180,
                format: (cell => _dateFormat(cell)),
                hidden: false,
                refProp: columnRefs.dateRef,
                sortable: true
            }
        ];
    };

    const myFilters = _.cloneDeep(defaultFilters);

    const defaultState = {
        sortName: '',
        sortOrder: '',
        currSize: 20,
        currPage: 1,
        filters: myFilters
    };

    let columns = defaultColumns();

    columns.map(c => {
        if (c.filter && defaultFilters[c.field]) c.filter.defaultValue = defaultFilters[c.field];
        return c;
    });

    return (
        <BotTable
            ref={ref}
            columns={columns}
            title={true}
            showTitle={false}
            data={data}
            getData={(req) => getHistories(req)}
            pageSizes={pageSizes}
            ClearFiltersBtn={true}
            ClearFiltersIcon={true}
            ClearSortBtn={true}
            ClearSortIcon={true}
            CsvDownloadBtn={true}
            CsvDownloadIcon={true}
            pagination={'both'}
            refreshData={(req) => getHistories(req)}
            btnGroupDirection={'left'}
            headers={true}
            lStorage={lStorage}
            u_preferences={u_preferences}
            remote={remote}
            csvFile={csvFile}
            loading={loading}
            defaultFilters={defaultFilters}
            defaultState={defaultState}/>
    );
});

XpiHistories.propTypes = propTypes;
export default XpiHistories;
