// noinspection JSCheckFunctionSignatures,JSValidateTypes

import {forwardRef, useRef, useState} from "react";
import {
    BotTable,
    Button,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../components/bot-components';
import TableActions from "../../../containers/table_action_component";
import {useHistory} from 'react-router-dom';
import {_dateOnlyFormat} from "../../../helpers";
import UnitDetail from "../BotUnit/BotUnit";
import UnitCreate from '../UnitCreate/UnitCreate';
import {defaultFilters, defaultState, propTypes} from './BotUnits.constants';

let table_key_iterator = 0;

const BotUnits = forwardRef(
    /**
     * @param props
     * @param props.tblId
     * @param props.remote
     * @param props.lStorage
     * @param props.pageSizes
     * @param props.columns
     * @param props.editAction
     * @param props.viewAction
     * @param props.popoutAction
     * @param props.alertAction
     * @param props.getMyUnit
     * @param props.alertUnit
     * @param props.getUnits
     * @param props.actionCount
     * @param props.asset_types
     * @param props.tire_sizes
     * @param props.className
     * @param props.canCreate
     * @param props.customBtns
     * @param props.showTitle
     * @param props.loading
     * @param props.csvFile
     * @param props.csvUrl
     * @param props.updateUnit
     * @param props.saveUnit
     * @param ref
     * @returns {JSX.Element}
     */
    (props, ref) => {
        const [state, updateState] = useState({
            ...defaultState,
            newModal: false,
            unitModal: false
        });
        const setState = (newState) => updateState((prevState) => ({
            ...prevState,
            ...newState
        }));

        //<editor-fold desc="Column Refs">
        const selectRef = useRef();
        const numberRef = useRef();
        const hoursRef = useRef();
        const mileageRef = useRef();
        const utypeRef = useRef();
        const subtypeRef = useRef();
        const statusRef = useRef();
        const yardRef = useRef();
        const vinRef = useRef();
        const descriptionRef = useRef();
        const locationRef = useRef();
        const tireRef = useRef();
        const assetRef = useRef();
        const activeRef = useRef();
        const dotRef = useRef();
        const tire2Ref = useRef();
        const tire3Ref = useRef();
        const tire4Ref = useRef();
        const plateRef = useRef();
        const regRef = useRef();
        const tareRef = useRef();
        const grossRef = useRef();
        const heightRef = useRef();
        const wheelbaseRef = useRef();
        const axleRef = useRef();
        const widthRef = useRef();
        const overallRef = useRef();
        const horsepowerRef = useRef();
        const companyRef = useRef();
        const cadrRef = useRef();
        const jobRef = useRef();
        const operatorRef = useRef();
        //</editor-fold>

        const table_keys = () => `${props.tblId}_${table_key_iterator++}`;

        const history = useHistory();

        const editButton = (cell, row) => {
            return (
                <TableActions ref={selectRef} key={table_keys()} row={row} edit={props.editAction}
                              view={props.viewAction}
                              popout={props.popoutAction} alert={props.alertAction}
                              onView={() => {
                                  props.getMyUnit(row.id);
                                  history.push({pathname: '/equipment/detail', state: {edit: false}});
                              }}
                              onEdit={() => {
                                  props.getMyUnit(row.id);
                                  history.push({pathname: '/equipment/detail', state: {edit: true}});
                              }}
                              onPopout={() => {
                                  props.getMyUnit(row.id);
                                  toggleUnitModal()
                              }}
                              onAlert={() => {
                                  props.alertUnit(row)
                              }}
                />
            )
        };

        const toggleUnitModal = () => {
            setState({
                unitModal: !state.unitModal
            });
        };

        const toggleNewModal = () => {
            setState({
                newModal: !state.newModal
            });
        };

        const prepareUnit = (cell, row) => {
            function modalUnitClick() {
                props.getMyUnit(row.id);
                toggleUnitModal();
            }

            return (
                <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                    modalUnitClick()
                }}>{cell}</div>
            )
        };

        const prepareDOT = (cell) => {
            return cell ? (<img className="dot-logo" src="" alt=""/>) : 'Non-DOT';
        };

        const styleUnitStatus = (cell) => {
            switch (cell) {
                case 'Down':
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-red)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                case 'Needs Attention':
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-yellow)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                case 3:
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-green)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
                default:
                    return {
                        color: 'black',
                        backgroundColor: 'var(--light-green)',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    };
            }
        };

        const refreshUnits = () => {
            const req = {
                sortName: state.sortName,
                sortOrder: state.sortOrder,
                currentPage: state.currPage,
                sizePerPage: state.currSize,
                filters: state.filters
            };
            props.remote ? props.getUnits(req) : props.getUnits();
        };

        const createNewBtn = () => (<DropdownItem outline color={'texts'} onClick={() => toggleNewModal()}><i
            className={'fa fa-plus'}/> New</DropdownItem>);

        const FilterDotBtn = () => (
            <DropdownItem outline color={'texts'} className={ref?.current?.state?.filters?.dot === true ? 'active' : ''}
                          key={table_keys()} onClick={() => {
                filterDotOnly()
            }}>DOT Only</DropdownItem>);

        const filterDotOnly = () => {
            const filter = dotRef.current;
            const currValue = filter?.state.value;
            return currValue !== true ? filter?.setFilter(true) : filter?.setFilter("");
        };

        const createUnit = (u) => {
            props.saveUnit(u);
            setTimeout(() => {
                refreshUnits();
            }, 2000);
        };

        const selects = ['asset_type', 'active', 'dot', 'tire_size', 'tire_size_2', 'tire_size_3', 'tire_size_4'];

        let {title, data, unit, pageSizes} = props;
        const columns = props.columns ? props.columns : [
            {
                field: 'button',
                text: 'Views',
                width: props.actionCount * 40,
                hidden: false,
                format: (cell, row) => editButton(cell, row),
                sortable: false
            },
            {
                field: 'number',
                text: 'Equipment#',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment#',
                    defaultValue: defaultFilters.number
                },
                width: 150,
                format: (cell, row) => prepareUnit(cell, row),
                hidden: false,
                refProp: numberRef,
                sortable: true
            },
            {
                field: 'hours',
                text: 'Hours',
                filter: {
                    type: 'text',
                    placeholder: 'Hours',
                    defaultValue: defaultFilters.hours
                },
                width: 150,
                hidden: false,
                sortable: true,
                refProp: hoursRef
            },
            {
                field: 'mileage',
                text: 'Miles',
                filter: {
                    type: 'text',
                    placeholder: 'Miles',
                    defaultValue: defaultFilters.mileage
                },
                width: 150,
                hidden: false,
                sortable: true,
                refProp: mileageRef
            },
            {
                field: 'unit_type',
                text: 'Equipment Type',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment Type',
                    defaultValue: defaultFilters.unit_type
                },
                width: 150,
                hidden: false,
                refProp: utypeRef,
                sortable: true
            },
            {
                field: 'asset_type',
                text: 'Asset Type',
                filter: {
                    type: 'select',
                    placeholder: 'Asset Type',
                    defaultValue: defaultFilters.asset_type,
                    options: [{
                        value: "",
                        label: 'Select Type',
                        text: 'Select Type'
                    }, ...props.asset_types?.items ? props.asset_types.items.map(at => {
                        return {value: at.description, label: at.description, text: at.description}
                    }) : []]
                },
                width: 180,
                hidden: false,
                refProp: assetRef,
                sortable: true
            },
            {
                field: 'active',
                text: 'Active',
                filter: {
                    type: 'select',
                    placeholder: 'Active',
                    defaultValue: defaultFilters.active,
                    options: [{value: "", label: 'Select Status', text: 'Select Status'}, {
                        value: true,
                        label: 'Active',
                        text: 'Active'
                    }, {value: false, label: 'Inactive', text: 'Inactive'}]
                },
                width: 150,
                hidden: false,
                refProp: activeRef,
                sortable: true,
                format: cell => cell ? 'Active' : 'Inactive'
            },
            {
                field: 'dot',
                text: 'DOT',
                filter: {
                    type: 'select',
                    placeholder: 'DOT',
                    options: [{value: "", text: 'Select DOT', label: 'Select DOT'}, {
                        value: true,
                        text: 'DOT',
                        label: 'DOT'
                    }, {value: false, text: 'Non-DOT', label: 'Non-DOT'}],
                    defaultValue: defaultFilters.dot
                },
                width: 150,
                hidden: false,
                format: cell => prepareDOT(cell),
                refProp: dotRef,
                sortable: true
            },
            {
                field: 'unit_subtype',
                text: 'Equipment Class',
                filter: {
                    type: 'text',
                    placeholder: 'Equipment#',
                    defaultValue: defaultFilters.unit_subtype
                },
                width: 250,
                hidden: false,
                refProp: subtypeRef,
                sortable: true
            },
            {
                field: 'company',
                text: 'Owned By',
                filter: {
                    type: 'text',
                    placeholder: 'Owned By',
                    defaultValue: defaultFilters.company
                },
                width: 200,
                hidden: false,
                refProp: companyRef,
                sortable: true
            },
            {
                field: 'unit_status',
                text: 'Equip. Status',
                filter: {
                    type: 'text',
                    placeholder: 'Equip. Status',
                    defaultValue: defaultFilters.unit_status
                },
                width: 150,
                hidden: false,
                tdStyle: (cell) => styleUnitStatus(cell),
                refProp: statusRef,
                sortable: true
            },
            {
                field: 'yard',
                text: 'Location',
                filter: {
                    type: 'text',
                    placeholder: 'Location',
                    defaultValue: defaultFilters.yard
                },
                width: 150,
                hidden: false,
                refProp: yardRef,
                sortable: true
            },
            {
                field: 'vin_sn',
                text: 'Vin-S/N',
                filter: {
                    type: 'text',
                    placeholder: 'Vin-S/N',
                    defaultValue: defaultFilters.vin_sn
                },
                width: 200,
                hidden: false,
                refProp: vinRef,
                sortable: true
            },
            {
                field: 'description',
                text: 'Description',
                filter: {
                    type: 'text',
                    placeholder: 'Description',
                    defaultValue: defaultFilters.description
                },
                width: 200,
                hidden: false,
                refProp: descriptionRef,
                sortable: true
            },
            {
                field: 'tire_size',
                text: 'Tire Size',
                filter: {
                    type: 'select',
                    placeholder: 'Tire Size',
                    defaultValue: defaultFilters.tire_size,
                    options: [{
                        value: "",
                        label: 'Select Tire Size',
                        text: 'Select Tire Size'
                    }, ...props.tire_sizes?.items ? props.tire_sizes.items.map(ts => ({
                        value: ts.size,
                        label: ts.size,
                        text: ts.size
                    })) : []]
                },
                width: 200,
                hidden: false,
                refProp: tireRef,
                sortable: true
            },
            {
                field: 'tire_size_2',
                text: 'Tire Size 2',
                filter: {
                    type: 'select',
                    placeholder: 'Tire Size 2',
                    defaultValue: defaultFilters.tire_size_2,
                    options: [{
                        value: "",
                        label: 'Select Tire Size 2',
                        text: 'Select Tire Size 2'
                    }, ...props.tire_sizes?.items ? props.tire_sizes.items.map(ts => ({
                        value: ts.size,
                        label: ts.size,
                        text: ts.size
                    })) : []]
                },
                width: 200,
                hidden: false,
                refProp: tire2Ref,
                sortable: true
            },
            {
                field: 'tire_size_3',
                text: 'Tire Size 3',
                filter: {
                    type: 'select',
                    placeholder: 'Tire Size 3',
                    defaultValue: defaultFilters.tire_size_3,
                    options: [{
                        value: "",
                        label: 'Select Tire Size 3',
                        text: 'Select Tire Size 3'
                    }, ...props.tire_sizes?.items ? props.tire_sizes.items.map(ts => ({
                        value: ts.size,
                        label: ts.size,
                        text: ts.size
                    })) : []]
                },
                width: 200,
                hidden: false,
                refProp: tire3Ref,
                sortable: true
            },
            {
                field: 'tire_size_4',
                text: 'Tire Size 4',
                filter: {
                    type: 'select',
                    placeholder: 'Tire Size 4',
                    defaultValue: defaultFilters.tire_size_4,
                    options: [{
                        value: "",
                        label: 'Select Tire Size 4',
                        text: 'Select Tire Size 4'
                    }, ...props.tire_sizes?.items ? props.tire_sizes.items.map(ts => ({
                        value: ts.size,
                        label: ts.size,
                        text: ts.size
                    })) : []]
                },
                width: 200,
                hidden: false,
                refProp: tire4Ref,
                sortable: true
            },
            {
                field: 'plate',
                text: 'Plate',
                filter: {
                    type: 'text',
                    placeholder: 'Plate',
                    defaultValue: defaultFilters.plate
                },
                width: 200,
                hidden: false,
                refProp: plateRef,
                sortable: true
            },
            {
                field: 'registration_date',
                text: 'Registration Date',
                filter: {
                    type: 'text',
                    placeholder: 'Registration Date',
                    defaultValue: defaultFilters.registration_date
                },
                width: 200,
                hidden: false,
                refProp: regRef,
                sortable: true,
                format: (cell) => _dateOnlyFormat(cell)
            },
            {
                field: 'cadr',
                text: 'CADR',
                filter: {
                    type: 'text',
                    placeholder: 'CADR',
                    defaultValue: defaultFilters.cadr
                },
                width: 200,
                hidden: false,
                refProp: cadrRef,
                sortable: true
            },
            {
                field: 'tare_weight',
                text: 'Tare Weight',
                filter: {
                    type: 'text',
                    placeholder: 'Tare Weight',
                    defaultValue: defaultFilters.tare_weight
                },
                width: 150,
                hidden: false,
                refProp: tareRef,
                sortable: true
            },
            {
                field: 'gross_weight',
                text: 'Gross Weight',
                filter: {
                    type: 'text',
                    placeholder: 'Gross Weight',
                    defaultValue: defaultFilters.gross_weight
                },
                width: 200,
                hidden: false,
                refProp: grossRef,
                sortable: true
            },
            {
                field: 'height',
                text: 'Height',
                filter: {
                    type: 'text',
                    placeholder: 'Height',
                    defaultValue: defaultFilters.height
                },
                width: 200,
                hidden: false,
                refProp: heightRef,
                sortable: true
            },
            {
                field: 'wheelbase',
                text: 'Wheelbase',
                filter: {
                    type: 'text',
                    placeholder: 'Wheelbase',
                    defaultValue: defaultFilters.wheelbase
                },
                width: 200,
                hidden: false,
                refProp: wheelbaseRef,
                sortable: true
            },
            {
                field: 'axles',
                text: 'Axles',
                filter: {
                    type: 'text',
                    placeholder: 'Axles',
                    defaultValue: defaultFilters.axles
                },
                width: 150,
                hidden: false,
                refProp: axleRef,
                sortable: true
            },
            {
                field: 'width',
                text: 'Width',
                filter: {
                    type: 'text',
                    placeholder: 'Width',
                    defaultValue: defaultFilters.width
                },
                width: 150,
                hidden: false,
                refProp: widthRef,
                sortable: true
            },
            {
                field: 'overall_length',
                text: 'Overall Length',
                filter: {
                    type: 'text',
                    placeholder: 'Overall Length',
                    defaultValue: defaultFilters.overall_length
                },
                width: 200,
                hidden: false,
                refProp: overallRef,
                sortable: true
            },
            {
                field: 'horsepower',
                text: 'Horsepower',
                filter: {
                    type: 'text',
                    placeholder: 'Horsepower',
                    defaultValue: defaultFilters.horsepower
                },
                width: 200,
                hidden: false,
                refProp: horsepowerRef,
                sortable: true
            },
            {
                field: 'location',
                text: 'Distinct Location',
                filter: {
                    type: 'text',
                    placeholder: 'Distinct Location',
                    defaultValue: defaultFilters.location
                },
                width: 200,
                hidden: false,
                refProp: locationRef,
                sortable: true
            },
            {
                field: 'operator',
                text: 'LK Operator',
                filter: {
                    type: 'text',
                    placeholder: 'LK Operator',
                    defaultValue: defaultFilters.operator
                },
                width: 200,
                hidden: false,
                refProp: operatorRef,
                sortable: true
            },
            {
                field: 'job',
                text: 'Job',
                filter: {
                    type: 'text',
                    placeholder: 'Job',
                    defaultValue: defaultFilters.job
                },
                width: 200,
                hidden: false,
                refProp: jobRef,
                sortable: true
            },
        ];

        return (
            <div id={'unit-table'}>
                <Modal size={'xl'} toggle={() => toggleNewModal()} isOpen={state.newModal}>
                    <ModalHeader toggle={() => toggleNewModal()}>
                        Create new Unit
                    </ModalHeader>
                    <ModalBody>
                        <UnitCreate saveUnit={(u) => createUnit(u)} toggle={() => toggleNewModal()}/>
                    </ModalBody>
                </Modal>
                <Modal size={'xl'} isOpen={state.unitModal} toggle={() => {
                    toggleUnitModal()
                }} className={props.className}>
                    <ModalHeader toggle={() => {
                        toggleUnitModal()
                    }}>
                        Unit detail for {unit.items ? unit.items.number : ''}
                    </ModalHeader>
                    <ModalBody>
                        <UnitDetail isModal/>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color={'texts'} onClick={() => {
                            toggleUnitModal()
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <BotTable ref={ref}
                          columns={columns}
                          title={title}
                          data={data}
                          getData={(req) => props.getUnits(req)}
                          defaultState={defaultState}
                          pageSizes={pageSizes}
                          pagination={'both'}
                          headers={true}
                          doRowUpdate={(u) => props.updateUnit(u)}
                          refreshData={() => refreshUnits()}
                          btnGroupDirection={'left'}
                          remote={props.remote}
                          defaultFilters={defaultFilters}
                          onlyFilters={[FilterDotBtn]}
                          CsvDownloadBtn={true}
                          CsvDownloadIcon={true}
                          CreateNewBtn={props.canCreate && createNewBtn}
                          CustomBtns={props.customBtns && props.customBtns}
                          onRowDoubleClick={(row) => {
                              props.getMyUnit(row.id);
                              toggleUnitModal()
                          }}
                          lStorage={props.lStorage}
                          showTitle={props.showTitle}
                          loading={props.loading}
                          csvFile={props.csvFile}
                          csvUrl={props.csvUrl}
                          selects={selects}/>
            </div>
        );
    });

BotUnits.propTypes = propTypes;
export default BotUnits;
