// noinspection JSCheckFunctionSignatures

import React from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import {
    ButtonToolbar,
    Container,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from '../components/bot-components';
import {AppAside, AppFooter, AppHeader} from '../components/index';
import navigation from '../../_nav';
import _routes from '../../routes';
import {Header} from './header';
import Aside from './aside';
import Footer from './footer';
import {connect} from 'react-redux';
import {hasRoles} from '../helpers';

let table_key_iterator = 0;
const DefaultLayout = ({user, history, BotModules}) => {
    const myNav = {};
    Object.assign(myNav, navigation);
    const [state, updateState] = React.useState({
        lastComponent: localStorage.getItem('lastComp') ? localStorage.getItem('lastComp') : '/dashboard',
        route: history.location.pathname,
        mods: [],
        routes: _routes(BotModules),
    });
    const table_keys = () => `dfl_${table_key_iterator++}`;

    function setState(newState) {
        updateState((prevState) => ({
            ...prevState,
            ...newState
        }));
    }

    React.useEffect(() => {
        localStorage.setItem('lastComp', history?.location?.pathname);
    }, [history.location]);

    const {lastComponent, route, routes, mods} = state;

    React.useEffect(() => {
        if (BotModules) {
            const mods = [];
            BotModules.forEach(m => m.enabled && mods.push(m.name));
            setState({
                mods: mods,
                routes: _routes(mods),
                route: history.location.pathname
            });
        }
    }, [BotModules, history]);

    React.useEffect(() => {
        history.listen((location) => {
            setState({
                route: location.pathname
            });
        });
    }, [history]);

    return (
        <div className={'app'}>
            <AppHeader fixed><Header/></AppHeader>
            <ButtonToolbar role={'group'} style={{width: '100% !important'}} className={'navigation-bar'}>
                <img className={'app-logo'} src={'assets/img/FleetMatrix-new.svg'} alt=""/>
                {myNav.items?.map(i => {
                    let createItem = hasRoles(i.roles, user);
                    createItem = i.modules ? !!mods.some(m => i.modules.includes(m)) : createItem;
                    if (createItem) {
                        if (i.children && i.children.length > 0) return (
                            <UncontrolledDropdown key={table_keys()} size={'sm'} direction={'down'}>
                                <DropdownToggle key={table_keys()} caret outline color={'texts'}
                                                className={route.includes(i.url) ? 'active' : ''}>{i.name}</DropdownToggle>
                                <DropdownMenu key={table_keys()}>
                                    {i.children.map(c => {
                                        let createChild = hasRoles(c.roles, user);
                                        createChild = c.modules ? !!mods.some(m => c.modules.includes(m)) : createChild;
                                        createItem = c.modules ? !!mods.some(m => c.modules.includes(m)) : createItem;
                                        if (createChild) {
                                            if (c.children && c.children.length > 0) return (
                                                <UncontrolledDropdown key={table_keys()} size={'sm'} direction={'down'}
                                                                      className={route.includes(i.url) ? 'active' : ''}>
                                                    <DropdownToggle key={table_keys()} caret outline
                                                                    color={'texts'}>{i.name}</DropdownToggle>
                                                    <DropdownMenu key={table_keys()}>
                                                        {i.children.map(c => {
                                                            let createChild = hasRoles(c.roles, user);
                                                            createItem = c.modules ? !!mods.some(m => c.modules.includes(m)) : createItem;
                                                            if (createChild) {
                                                                return (<Link key={table_keys()}
                                                                              disabled={route === c.url}
                                                                              className={route === c.url ? 'dropdown-item active' : 'dropdown-item'}
                                                                              role={'menuitem'}
                                                                              to={c.url}>{c.name}</Link>);
                                                            } else return null;
                                                        })}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            ); else return (<Link key={table_keys()}
                                                                  disabled={route === c.url}
                                                                  className={route === c.url ? 'dropdown-item active' : 'dropdown-item'}
                                                                  role={'menuitem'} to={c.url}>{c.name}</Link>);
                                        } else return null;
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        ); else {
                            return (<Link key={table_keys()}
                                          disabled={route === i.url}
                                          className={route === i.url ? 'bot-nav-link active' : 'bot-nav-link'}
                                          to={i.url}>{i.name}</Link>);
                        }
                    } else return null;
                })}
                <a key={table_keys()}
                   className={'bot-nav-link'} target={'_blank'}
                   href={`assets/release_notes/FleetMatrix-MajorUpdateFeb2021.pdf.pdf`} rel={'noopener noreferrer'}>
                    Release Notes
                </a>
            </ButtonToolbar>
            <div className={'app-body'}>
                <main className={'main'}>
                    <Container fluid>
                        <Switch>
                            {routes.map((route, idx) => route.component ?
                                (<Route key={idx} path={route.path} exact={route.exact} name={route.name}
                                        render={props => (<route.component {...props}/>)}/>) : null,)}
                            <Redirect from={'/'} to={lastComponent}/>
                        </Switch>
                    </Container>
                </main>
                <AppAside><Aside/></AppAside>
            </div>
            <AppFooter fixed display={'md'}><Footer/></AppFooter>
        </div>
    );
};

DefaultLayout.defaultProps = {};
const mapStateToProps = (state) => {
    /**
     * @param items.bot_modules
     */
    const {authentication, user_preference} = state;
    let {user} = authentication;
    user = user?.user ?? null;
    const BotModules = user_preference.items?.bot_modules;
    return {user, user_preference, BotModules};
};

export default connect(mapStateToProps)(DefaultLayout);

