export const dashboardConstants = {
    INS_DASHBOARD_REQUEST: 'INS_DASHBOARD_REQUEST',
    INS_DASHBOARD_SUCCESS: 'INS_DASHBOARD_SUCCESS',
    INS_DASHBOARD_FAILURE: 'INS_DASHBOARD_FAILURE',

    WO_DASHBOARD_REQUEST: 'WO_DASHBOARD_REQUEST',
    WO_DASHBOARD_SUCCESS: 'WO_DASHBOARD_SUCCESS',
    WO_DASHBOARD_FAILURE: 'WO_DASHBOARD_FAILURE',

    DOT_DASHBOARD_REQUEST: 'DOT_DASHBOARD_REQUEST',
    DOT_DASHBOARD_SUCCESS: 'DOT_DASHBOARD_SUCCESS',
    DOT_DASHBOARD_FAILURE: 'DOT_DASHBOARD_FAILURE'
};
