import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {_extends, _inherits, _classCallCheck, _objectWithoutProperties, _possibleConstructorReturn} from './util';

let propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    fixed: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

let defaultProps = {
    tag: 'footer',
    fixed: false
};

let AppFooter = function (_Component) {
    _inherits(AppFooter, _Component);

    function AppFooter(props) {
        _classCallCheck(this, AppFooter);

        let _this = _possibleConstructorReturn(this, _Component.call(this, props));

        _this.isFixed = _this.isFixed.bind(_this);
        return _this;
    }

    AppFooter.prototype.componentDidMount = function componentDidMount() {
        this.isFixed(this.props.fixed);
    };

    AppFooter.prototype.isFixed = function isFixed(fixed) {
        if (fixed) {
            document.body.classList.add('footer-fixed');
        }
    };

    AppFooter.prototype.render = function render() {
        let _props = this.props,
            className = _props.className,
            children = _props.children,
            Tag = _props.tag,
            attributes = _objectWithoutProperties(_props, ['className', 'children', 'tag']);

        delete attributes.fixed;

        let classes = classNames(className, 'app-footer');

        return React.createElement(
            Tag,
            _extends({className: classes}, attributes),
            children
        );
    };

    return AppFooter;
}(Component);

AppFooter.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
AppFooter.defaultProps = defaultProps;

export default AppFooter;
