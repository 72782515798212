import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter} from '../../../components/bot-components';
import {messageActions} from "../../../network/actions";
import {_dateFormat} from '../../../helpers';
import UserDetail from "../../UserManagement/BotUser/BotUser";

let defaultProps = {};

const WorkorderMessages = (props) => {
    const [state, updateState] = React.useState({
        new_message: '',
        message: '',
        workorder_id: '',
        userModal: false,
        messageModal: false,
        messages: null
    });
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));

    const toggleUserModal = (edit_user) => {
        localStorage.setItem('edit_user', edit_user);
        setState({
            userModal: !state.userModal,
        });
    };
    const toggleMessageModal = () => {
        setState({
            messageModal: !state.messageModal
        });
    }
     WorkorderMessages.defaultProps = props;
    const {workorder, createMessage} = props;
    let messages = [];

    if (workorder.items) {
        localStorage.setItem('workorder_id', workorder.items.id);
        localStorage.setItem('unit_id', workorder.items['unit_id']);
        if (workorder.items['messages']) {
            messages = [];
            workorder.items.messages.map(message => {
                return messages.push(
                    <Card key={message.id}>
                        <CardHeader>
                            <Row>
                                <Col sm={'6'}>
                                    <label className={'message-label'}>Made By:</label>
                                    <label className={'message-user-field'} onClick={() => {toggleUserModal(message.users.id)}}>{message.users ? message.users.name : ''}</label>
                                    {/*<label className={'message-user-field'} onClick={() => {toggleUserModal(message.users.id)}}>{message.users ? message.users.name : ''}</label>*/}
                                </Col>
                                <Col sm={'6'} className={'float-right'}>
                                    <label className={'message-label'}>Made On:</label>
                                    <label className={'message-field'}>{_dateFormat(message['created_at'])}</label>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <span className={'message-field'}>{message.message}</span>
                        </CardBody>
                    </Card>)
            });
        }
    }
    const setMessage = (e) => {
        const {value} = e.target;
        setState({
            new_message: value,
        });
    }

    const saveMessage = () => {
        const nMessage = {
            message: state.new_message,
            workorder_id: workorder.items.id,
        };
        createMessage(nMessage);
        setState({
            new_message: '',
            messageModal: !state.messageModal
        });
    }

    return (
        <div>
            <Card>
                <Modal size={'xl'} isOpen={state.userModal} toggle={() => {toggleUserModal()}}>
                    <ModalHeader toggle={() => {toggleUserModal(null)}}>
                        <h1>User Detail</h1>
                    </ModalHeader>
                    <ModalBody>
                        <UserDetail/>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color={'texts'} onClick={() => {toggleUserModal()}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal size={'xl'} isOpen={state.messageModal} toggle={() => toggleMessageModal()}>
                    <ModalHeader toggle={() => toggleMessageModal()}>
                        <span style={{textAlign: 'center'}}>Add new message</span>
                    </ModalHeader>
                    <ModalBody>
                        <Input ref={React.useRef()} type={'textarea'} onChange={setMessage}
                               className={'message'} value={state.new_message}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color={'texts'} onClick={saveMessage}>Save</Button>
                    </ModalFooter>
                </Modal>
                <CardHeader>
                    <i className={'add-icon'} onClick={() => toggleMessageModal()}/>
                </CardHeader>
                <CardBody>
                    {messages}
                </CardBody>
            </Card>
        </div>
    );
};

WorkorderMessages.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {state};
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMessage: (message) => dispatch(messageActions.createMessage(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderMessages);
