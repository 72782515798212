import {authHeader} from '../../helpers/index';
import {baseUrl} from "../constants/";
import {handleResponse} from './responseHandler';

export const userPreferenceService = {};

const apiUrl = baseUrl;

userPreferenceService.createUserPreference = (user_preference) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(user_preference)
    };

    return fetch(`${apiUrl}/user_preference`, requestOptions).then(handleResponse);
};

userPreferenceService.updateUserPreference = (user_preference) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(user_preference)
    };

    return fetch(`${apiUrl}/user_preference/` + user_preference.user_id, requestOptions).then(handleResponse);
};

userPreferenceService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/user_preferences`, requestOptions).then(handleResponse);
};

userPreferenceService.getUserPreference = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/user_preference/detail/` + id, requestOptions).then(handleResponse);
};
