// import config from 'config';
import {authHeader} from '../../helpers/index';
import {baseUrl} from '../constants';
import {handleResponse} from './responseHandler';

export const dataPlateService = {};

const apiUrl = baseUrl;

dataPlateService.createDataPlate = (data_plate) => {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: JSON.stringify(data_plate)
    };

    return fetch(`${apiUrl}/component`, requestOptions).then(handleResponse)
};

dataPlateService.updateDataPlate = (data_plate) => {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(data_plate)
    };

    return fetch(`${apiUrl}/component/` + data_plate.id, requestOptions).then(handleResponse);
};

dataPlateService.getAll = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/components`, requestOptions).then(handleResponse);
};

dataPlateService.getDetail = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader('GET')
    };

    return fetch(`${apiUrl}/component/` + id, requestOptions).then(handleResponse);
};
