/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {connect} from 'react-redux';
import {_dateFormat, _dateOnlyFormat} from '../../../helpers';
import {
    Button,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup, InputGroupAddon, InputGroupText
} from '../../../components/bot-components';
import * as funcs from './TaskDetail.helpers';

const TaskDetail = (props) => {
    const [state, updateState] = React.useState({
        selectedVendor: undefined,
        selectedMech: undefined,
        selectedPriority: props.task.items?.task_priority_id ? ({value: props.task.items?.task_priority_id, label: props.task.items?.task_priority}) : null,
        vendor_id: undefined,
        mechanic_id: undefined,
        complete: undefined,
        vendor: undefined,
        description: undefined,
        completed_by: undefined,
        completed_on: undefined,
        arrived_at_vendor: undefined,
        assigned_to_mechanic: undefined,
        est_complete_date: undefined,
        picked_up_at: undefined,
        edit_comp_date: false,
        edit_priority: false,
        gps_location: undefined,
        task_priority_id: undefined,
        task_priority: undefined,
        modal: false,
        closing_message: null,
        modal_title: '',
        task_id: null
    });
    const setState = newState => (updateState(prevState => ({
        ...prevState,
        ...newState
    })));
    React.useEffect(() => {
            !props.vendors?.items && props.getVendors();
            !props.mechanics?.items && props.getMechanics();
            !props.task_priorities?.items && props.getTaskPriorities();
    }, []);
    React.useEffect(() => {
        props.task.items && setState({
            ...props.task.items,
            selectedPriority: props.task.items?.task_priority_id ? ({value: props.task.items?.task_priority_id, label: props.task.items?.task_priority}) : null,
            selectedMech: props.task.items?.mechanic[0] ? ({value: props.task.items.mechanic[0].id, label: props.task.items.mechanic[0].name}) : null,
            selectedVendor: props.task.items?.vendors[0] ? ({value: props.task.items.vendors[0].id, label: props.task.items.vendors[0].name}) : null
        })
    }, [props.task, props.workorder]);
    const setMech = () => funcs.setMechanic(props, state, setState);
    const setVendor = () => funcs.setVendor(props, state, setState);
    const setPriority = () => funcs.setPriority(props, state, setState);
    const complete_task = (value) => funcs.completeTask(props, state, setState, toggle, value);
    const toggle = () => funcs.toggle(state, setState);
    const closeTask = () => funcs.closeTask(state, setState);
    const reopenTask = () => funcs.reopenTask(state, setState);
    const setCompletionDate = () => funcs.setCompletionDate(props, task);
    const changeProps = (e) => funcs.changeProps(e, setState, props, task);
    const {task, mechList, vendorList, workorder, arrivedAtVendor, priorityList} = props;
    const {complete, completed_by, completed_on, edit_priority} = state;

    /**
     * @param task.items.mechanic.name
     */
    return (
        <Card>
            <Modal size={'lg'} isOpen={state.modal} toggle={toggle}
                   className={props.className}>
                <ModalHeader toggle={toggle}>{state.modal_title}</ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <InputGroupAddon addonType={'prepend'}><InputGroupText>Notes</InputGroupText></InputGroupAddon>
                        <Input name={'closing_message'} value={state.closing_message} onChange={changeProps} type="textarea" id={'closing_message'}/>
                    </InputGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color={'texts'} outline onClick={() => complete_task(state.modal_title === 'Close Task')}>Submit</Button>
                    <Button color={'texts'} outline onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <CardBody>
                <Row><h3>{task?.items?.description ?? ''}</h3></Row>
                <hr/>
                <Row>
                    <Col sm={'6'}>
                        <InputGroup style={{marginLeft: '-0.5rem', paddingTop: '0', paddingBottom: '0.5rem'}}>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Status</InputGroupText></InputGroupAddon>
                            <Input type={'text'} disabled value={complete ? ' Complete' : ' Incomplete'}/>
                            <InputGroupAddon addonType={'append'}><Button color={'texts'} outline onClick={complete ? reopenTask : closeTask}>{complete ? 'Reopen' : 'Close'} Task</Button></InputGroupAddon>
                        </InputGroup>
                        {funcs.createSelectInput(true, 'Priority', priorityList, !edit_priority, (selectedPriority) => setState({selectedPriority: selectedPriority}), 'selectedPriority', 'edit_priority', 'task_priority', setState, setPriority, props.task.items?.task_priority_id ? ({value: props.task.items?.task_priority_id, label: props.task.items?.task_priority}) : null, state)}
                        {funcs.createSelectInput(true, 'Provider', vendorList, !state.edit_provider, (selectedVendor) => {setState({selectedVendor: selectedVendor, vendor_id: selectedVendor ? selectedVendor.value : null, vendor: !!selectedVendor, mechanic_id: selectedVendor ? null : state.mechanic_id})}, 'selectedVendor', 'edit_provider', 'task_provider', setState, setVendor, props.task.items?.vendors[0] ? ({value: props.task.items.vendors[0].id, label: props.task.items.vendors[0].name}) : null, state)}
                        {funcs.createSelectInput(true, 'Mechanic', mechList, !state.edit_mechanic, (selectedMechanic) => {setState({selectedMech: selectedMechanic, mechanic_id: selectedMechanic ? selectedMechanic.value : null, vendor: selectedMechanic ? false : state.vendor, vendor_id: selectedMechanic ? null : state.vendor_id})}, 'selectedMech', 'edit_mechanic', 'task_mechanic', setState, setMech, props.task.items?.mechanic[0] ? ({value: props.task.items.mechanic[0].id, label: props.task.items.mechanic[0].name}) : null, state)}
                        {funcs.createDateInput(true, 'Assigned On', !state.edit_mechanic_date, 'assigned_to_mechanic', 'edit_mechanic_date', 'task_mechanic_date', setState, setMech, state, props, task)}
                    </Col>
                    <Col sm={6}>
                        <Row className={'task_detail-row'} style={{paddingBottom: '0.4rem', paddingTop: '0.3rem'}}>
                            <label className={'task_detail-label'}>GPS Location: </label>
                            {task?.items?.gps_location &&
                            <lable className={'task_detail-field'}><a name="gps_location" href={`https://maps.google.com/?q=${task.items.gps_location}`} target={'_blank'} rel={'noopener noreferrer'}>{workorder.items.gps_location}</a></lable>
                            }
                        </Row>
                        {funcs.createDateInput(true, 'Est. Comp. Date', !state.edit_comp_date, 'est_complete_date', 'edit_comp_date', 'task_comp_date', setState, setCompletionDate, state, props, task)}
                        <InputGroup style={{marginLeft: '-0.5rem', paddingTop: '0', paddingBottom: '0.5rem'}}>
                            <InputGroupAddon addonType={'prepend'}>
                                <Button disabled={!task?.items?.vendor} color={'texts'} outline onClick={() => {
                                    arrivedAtVendor({
                                        id: task.items.id,
                                        arrived_at_vendor: state.arrived_at_vendor
                                    })
                                }}>Arrived At Service Provider</Button>
                            </InputGroupAddon>
                            <Input disabled={!task?.items?.vendor} defaultValue={_dateOnlyFormat(task.items?.arrived_at_vendor)} type={'date'} name={'arrived_at_vendor'}
                                   onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup style={{marginLeft: '-0.5rem', paddingTop: '0', paddingBottom: '0.5rem'}}>
                            <InputGroupAddon addonType={'prepend'}>
                                <Button disabled={!task?.items?.vendor} color={'texts'} outline onClick={() => {
                                    props.pickupUnit({
                                        id: task.items.id,
                                        picked_up_at: state.picked_up_at
                                    })
                                }}>Picked Up</Button>
                            </InputGroupAddon>
                            <Input disabled={!task?.items?.vendor} defaultValue={_dateOnlyFormat(task.items?.picked_up_at)} type={'date'} name={'picked_up_at'}
                                   onChange={changeProps}/>
                        </InputGroup>
                    </Col>
                </Row>
            </CardBody>
            {task.items && complete &&
                <CardFooter>
                    <label style={{fontSize: 18, fontWeight: 'bold'}}>Completed By: </label>
                    <label style={{fontSize: 18, paddingLeft: 5}}>{completed_by?.name}</label>
                    <label style={{fontWeight: 'bold', fontSize: 18, paddingLeft: 20}}>On:</label>
                    <label style={{fontSize: 18, paddingLeft: 5}}>{_dateFormat(completed_on)}</label>
                </CardFooter>
            }
        </Card>
    );
};

export default connect(funcs.mapStateToProps, funcs.mapDispatchToProps)(TaskDetail);
