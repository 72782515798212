import { authHeader, store } from '../../helpers';
import { baseUrl } from '../../network/constants';
import { userActions } from '../../network/actions';

const getUnitTypes = () => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/unit_types/` );
  return sendRequest( url, options );
};
const getUnitSubtypes = () => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/unit_subtypes/` );
  return sendRequest( url, options );
};

const getPmLists = ( { unit_type = undefined, unit_subtype = undefined } ) => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/pm_list/` );
  (unit_type || unit_subtype) && url.searchParams.append( unit_subtype ? 'unit_subtype_id' : 'unit_type_id', unit_subtype ? unit_subtype.id : unit_type.id );
  url.searchParams.append( 'deleted', 'false' );
  return sendRequest( url, options );
};

const createPMList = ( { body } ) => {
  const options = {
    method:'POST',
    headers:authHeader( 'POST' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_list/` );
  return sendRequest( url, options );
};

const getPmListItems = ( { unit_subtype, unit_type, list } ) => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/pm_list_item/` );
  url.searchParams.append( unit_subtype ? 'unit_subtype_id' : 'unit_type_id', unit_subtype ? unit_subtype.id : unit_type.id );
  url.searchParams.append( 'pm_list_id', list.id );
  return sendRequest( url, options );
};

const updateAvailableCheck = ( body ) => {
  const options = {
    method:'PUT',
    headers:authHeader( 'PUT' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_item/${ body.id }` );
  return sendRequest( url, options );
};

const getPmChecks = () => {
  const options = {
    method:'GET',
    headers:authHeader( 'GET' )
  };
  const url = new URL( `${ baseUrl }/pm_item/` );
  return sendRequest( url, options );
};

const updatePMList = ( body ) => {
  const options = {
    method:'PUT',
    headers:authHeader( 'PUT' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_list/${ body.id }` );
  return sendRequest( url, options );
};

const createPmItem = ( body ) => {
  const options = {
    method:'POST',
    headers:authHeader( 'POST' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_item/` );
  return sendRequest( url, options );
};

const createPmListItem = ( body ) => {
  const options = {
    method:'POST',
    headers:authHeader( 'POST' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_list_item/` );
  return sendRequest( url, options );
};

const updatePmListItems = ( id, body ) => {
  const options = {
    method:'PUT',
    headers:authHeader( 'PUT' ),
    body:JSON.stringify( body )
  };
  const url = new URL( `${ baseUrl }/pm_list/items/${ id }` );
  return sendRequest( url, options );
};

const deletePmList = ( id ) => {
  const options = {
    method:'DELETE',
    headers:authHeader( 'PUT' )
  };
  const url = new URL( `${ baseUrl }/pm_list/${ id }` );
  return sendRequest( url, options );
};

const sendRequest = ( url, options ) => fetch( url, options ).then( handleResponse );

const handleResponse = ( response ) => new Promise( ( resolve, reject ) => {
  response.status !== 404 ? response.json().then( data => {
    if (!response.ok) {
      reject();
    }
    const Auth = JSON.parse( response.headers.get( 'Authentication' ) );
    if (Auth) {
      store.dispatch( userActions.updateAuth( Auth, null ) );
    }
    resolve( data );
  } ) : reject();
} );

export {
  getUnitTypes,
  getUnitSubtypes,
  getPmLists,
  createPMList,
  createPmListItem,
  deletePmList,
  getPmChecks,
  getPmListItems,
  updatePMList,
  updateAvailableCheck,
  createPmItem,
  updatePmListItems
};