import React, {Component} from 'react';
import {connect} from 'react-redux';
import {hasRoles, store} from '../../helpers';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from '../../components/bot-components';
import classnames from 'classnames';
import JobTable from '../Jobs/BotJobs';
import {jobActions, jobStatusActions, roleActions, yardActions} from '../../network/actions';
import YardTable from './BotYards';
import YardDetail from './YardDetail';
import YardEdit from './YardEdit';
// import YardEdit from './yard_edit';
// import {jobService, jobStatusService, userService, yardService} from '../../network';

let defaultProps = {};

class Yards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: '1',
            edit: false
        };
        this.ayRef = React.createRef();
        this.ymRef = React.createRef();
        this.ajRef = React.createRef();
        this.yjRef = React.createRef();
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    createJob = (row) => {
        const Job = {
            yard_id: row.yard_id,
            job_status_id: !isNaN(parseInt(row.job_status_id)) ? parseInt(row.job_status_id) : null,
            name: row.name,
            number: row.number,
            supervisor_id: !isNaN(parseInt(row.supervisor_id)) ? parseInt(row.supervisor_id) : null,
        };
        this.props.createJob(Job);
    };

    deleteJobs = (rows) => Promise.all(rows.map(row => this.props.deleteJob(row))).then(() => this.props.getJobs());

    deleteYards = (rows) => Promise.all(rows.map(row => this.props.deleteYard(row))).then(() => this.props.getYards());

    componentDidMount() {
        this.props.getAllJobs();
        this.props.getYards();
        this.props.getManagers();
        this.props.getJobStatuses();
        localStorage.setItem('lastComp', this.props.history?.location?.pathname);
    }

    toggleTab = (tab) => {
        if (this.state.tab !== tab) this.setState({tab: tab});
    };

    cancelEdit = () => {
        this.setState({edit: false});
    };


    render() {
        Yards.defaultProps = this.props;
        const {edit} = this.state;
        let {yard} = this.props;
        return (
            <Card>
                <CardHeader>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({active: this.state.tab === '1'})}
                                     onClick={() => {
                                         this.toggleTab('1');
                                     }}>
                                Management
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({active: this.state.tab === '2'})}
                                     onClick={() => {
                                         this.toggleTab('2');
                                     }}>
                                Locations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({active: this.state.tab === '3'})}
                                     onClick={() => {
                                         this.toggleTab('3');
                                     }}>
                                Jobs
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent activetab={this.state.tab}>
                        {this.state.tab === '1' && <TabPane tabId={'1'}>
                            {this.state.tab === '1' &&
                            <Card>
                                <CardHeader>
                                    <h3 style={{textAlign: 'center'}}>Location Management</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={'7'}>
                                            <Card>
                                                <CardHeader>
                                                    <Row>
                                                        <h3>Location Info</h3>
                                                        {yard.items &&
                                                        <Label className={'link-label-1'}
                                                               onClick={() => !edit ? this.setState({edit: true}) : this.cancelEdit()}>{!edit ? 'Edit' : 'Cancel Edit'}</Label>}
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    {!edit ?
                                                        <YardDetail yard={yard.items}/> : <YardEdit yard={yard.items}
                                                                                                    saveYard={(yard) => this.props.updateYard(yard)}
                                                                                                    cancelEdit={this.cancelEdit}/>}
                                                </CardBody>
                                            </Card>
                                            <JobTable ref={this.yjRef}
                                                      title={yard ? `${yard.name} Jobs` : null}
                                                      data={this.props.yard?.items?.jobs}
                                                      canCreate={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                                      canEdit={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                                      csvUrl={'/jobs'}
                                                      csvFile={'location_jobs.csv'}
                                                      lStorage={'location_job_storage'}
                                                      remote={false}
                                                      tblId={'yjRef'}
                                                      yard={this.props.yard}
                                                      job={this.props.job}
                                                      job_statuses={this.props.job_statuses}
                                                      jobStatusList={this.props.jobStatusList}
                                                      managerList={this.props.managerList}
                                                      yardList={this.props.yardList}
                                                      u_preferences={this.props.user_preference}
                                                      remot={false}
                                                      pageSizes={['20', '50', '100', '500', '1000']}
                                                      canDelete={false}
                                                      createYard={(yard) => this.props.createYard(yard)}
                                                      getJobs={(id) => id && this.props.getYard(id)}
                                                      getJob={(id) => id && this.props.getJob(id)}
                                                      selectedYard={!!this.props.yard?.items}
                                                      updateJob={(row) => this.props.updateJob(row)}
                                                      createJob={(row) => this.createJob(row)}
                                                      deleteJob={(job) => this.props.deleteJobs(job)}/>
                                        </Col>
                                        <Col sm={'5'}>
                                            <YardTable ref={this.ymRef}
                                                       title={'All Locations'}
                                                       selectAction={true}
                                                       actionCount={1.5}
                                                       canCreate={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                                       canEdit={hasRoles([hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)], store.authentication)}
                                                       csvFile={'all_locations.csv'}
                                                       csvUrl={'/yards'}
                                                       lStorage={'all_yard_storage'}
                                                       remote={false}
                                                       tblId={'ymRef'}
                                                       u_preferences={this.state.user_preference}
                                                       createYard={(row) => this.props.createYard(row)}
                                                       pageSizes={['20', '50', '100', '500', '1000']}
                                                       updateYard={(row) => this.updateYard(row)}
                                                       data={this.props.activeYards}
                                                       getYard={(id) => id && this.props.getYard(id)}
                                                       yard={this.state.yard}
                                                       canDelete={false}
                                                       getYards={this.props.getYards}
                                                       deleteYards={(row) => this.props.deleteYards(row)}/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>}
                        </TabPane>}
                        {this.state.tab === '2' && <TabPane tabId={'2'}>
                            {this.state.tab === '2' &&
                            <YardTable ref={this.ayRef}
                                       title={'All Locations'}
                                       selectAction={true}
                                       actionCount={1.5}
                                       canCreate={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                       canEdit={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                       csvFile={'all_locations.csv'}
                                       csvUrl={'/yards'}
                                       lStorage={'all_yard_storage'}
                                       remote={false}
                                       tblId={'ayRef'}
                                       data={this.props.yards?.items}
                                       getYards={this.props.getYards}
                                       u_preferences={this.props.user_preference}
                                       createYard={(row) => this.props.createYard(row)}
                                       pageSizes={['20', '50', '100', '500', '1000']}
                                       updateYard={(row) => this.props.updateYard(row)}
                                       getYard={(id) => id && this.props.getYard(id)}
                                       yard={this.state.yard}
                                       canDelete={hasRoles(['Administrator'], store.authentication)}
                                       deleteYard={(row) => this.props.deleteYard(row)}/>}
                        </TabPane>}
                        {this.state.tab === '3' && <TabPane tabId={'3'}>
                            {this.state.tab === '3' &&
                            <JobTable ref={this.ajRef}
                                      title={'All Jobs'}
                                      canCreate={false}
                                      data={this.props.jobs?.items}
                                      getJobs={() => this.props.getAllJobs()}
                                      canEdit={hasRoles(['Administrator', 'Manger', 'Staff'], store.authentication)}
                                      csvUrl={'/jobs'}
                                      csvFile={'all_jobs.csv'}
                                      lStorage={'all_job_storage'}
                                      remote={false}
                                      tblId={'ajRef'}
                                      yard={this.props.yard}
                                      selectAction
                                      job={this.props.job}
                                      job_statuses={this.props.job_statuses}
                                      jobStatusList={this.props.jobStatusList}
                                      managerList={this.props.managerList}
                                      yardList={this.props.yardList}
                                      u_preferences={this.props.user_preference}
                                      selectedYard={yard}
                                      pageSizes={['5', '10']/*['20', '50', '100', '500', '1000']*/}
                                      canDelete={hasRoles(['Administrator'], store.authentication)}
                                      getJob={(id) => id && this.props.getJob(id)}
                                      updateJob={(row) => this.props.updateJob(row)}
                                      createJob={(row) => this.createJob(row)}
                                      deleteJob={(job) => this.props.deleteJob(job)}/>}
                        </TabPane>}
                    </TabContent>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {jobs, job, job_statuses, managers, yards, yard, user_preference} = state;
    const jobStatusList = job_statuses?.items?.map?.(js => ({label: js.status, value: js.id}));
    const managerList = managers?.items?.map(m => ({label: m.name, value: m.id}));
    const yardList = yards?.items?.map(y => ({label: y.name, value: y.id}));
    const activeYards = yards?.items?.filter(y => y.deleted === false);

    return {jobs, job, job_statuses, jobStatusList, yards, activeYards, yard, user_preference, managerList, yardList};
};

const mapDispatchToProps = dispatch => {
    return {
        getAllJobs: () => dispatch(jobActions.getAll()),
        getJob: (id) => dispatch(jobActions.getJob(id)),
        getJobStatuses: () => dispatch(jobStatusActions.getAll()),
        getManagers: () => dispatch(roleActions.getManagers()),
        getYards: () => dispatch(yardActions.getAll()),
        getYard: (id) => dispatch(yardActions.getYard(id)),
        updateJob: (job) => dispatch(jobActions.updateJob(job)),
        createJob: (job) => dispatch(jobActions.createJob(job)),
        deleteJob: (job) => dispatch(jobActions.deleteJob(job)),
        updateYard: (yard) => dispatch(yardActions.updateYard(yard)),
        deleteYard: (job) => dispatch(yardActions.deleteYard(job)),
        createYard: (yard) => dispatch(yardActions.createYard(yard))
    };
};

Yards.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Yards);

/**
 * @namespace store.authentication
 */
