import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, Col, Row, Label} from '../../components/bot-components';
import {userActions} from "../../network/actions";
import Proptypes from 'prop-types';
import moment from 'moment';

const propTypes = {
    setUpload: Proptypes.func,
    viewHistory: Proptypes.func,
    summaryData: Proptypes.object.isRequired,
    setTableData: Proptypes.func,
    is_committed: Proptypes.bool
}

class NewUpload extends Component {
    constructor(props) {
        super(props);
        const currentTab = localStorage.getItem('userTab') ? localStorage.getItem('userTab') : '1';
        this.state = {
            modal: false,
            file: this.importUsers ? this.importUsers.files[0] : null,
            timeHidden: true,
            timeDisabled: true,
            tab: currentTab,
            confirmA: false,
            userDropdown: false
        };
        localStorage.removeItem('userTab');
        this.auRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
    }

    confirmOldFile() {
        this.setState({
            confirmA: false
        })
    }

    setFiles(e) {
        const {files} = e.target;
        const today = new Date();
        const oldDate = today.setDate(today.getDate() - 3);
        const tooOld = files[0].lastModified < oldDate;
        if (tooOld === true) {
            this.setState({
                confirmA: true,
                files: files[0]
            });
        } else {
            this.setState({
                files: files[0],
            });
        }
    }

    cancelFiles() {
        this.setState({
            files: null,
            confirmA: false
        });
        this.toggle();
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    toggleTab(tab) {
        this.setState({
            tab: tab,
        });
        localStorage.setItem('userTab', tab);
    }

    toggleUserDropdown () {
        this.setState({
            userDropdown: !this.state.userDropdown
        })
    }

    componentDidMount() {
        localStorage.setItem('lastComp', '/setup/users');
    }

    handleUserImport() {
        const data = new FormData();
        data.append('files', this.importUsers.files[0]);
        this.props.importUsers(data);
        this.toggle();
        this.setState({
            files: null,
        });
    }

    render() {
        const {summaryData, is_committed} = this.props;
        return (
            <Card>
                <CardBody>
                    <Col sm={12}>
                        <Row>
                            <Col sm={5}/>
                            <Col sm={2}>
                                <Button className={'bot-3d-user-import-button'} onClick={() => this.props.setUpload()} block={true}>Upload New File</Button>
                            </Col>
                            <Col sm={5}/>
                        </Row>
                        <Row>
                            <Col sm={3}/>
                            <Col sm={6}>
                                {is_committed === 'N' ? <Label sm={12} className={'uil-warning-lbl'}>Last Update Canceled - See History for Last Data</Label> : is_committed === 'Y' && <Label sm={12} className={'uil-warning-lbl'}>{`Last Updated: ${summaryData && summaryData.upload_date ? moment(summaryData.upload_date).format('MM/DD/YYYY') : ''}`}</Label>}
                            </Col>
                            <Col sm={3}/>
                        </Row>
                        <br/>
                        <br/>
                        <br/>
                        <Row>
                            <Col sm={1}/>
                            <Col sm={5}>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-dark-left-lbl'}>
                                            Last Vista File Date:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-dark-right-date-lbl'}>
                                            {summaryData && summaryData.vista_date ? moment(summaryData.vista_date).format('MM/DD/YYYY') : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Active Vista Users:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_active_vista', 'vistaColumns')}>
                                            {summaryData && summaryData.active_vista_users ? summaryData.active_vista_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            New Users Added:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_new_fm', 'vistaColumns')}>
                                            {summaryData && summaryData.new_users ? summaryData.new_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Inactive Users Re-activated:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_reactivated', 'raColumns')}>
                                            {summaryData && summaryData.inactive_users ? summaryData.inactive_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            First Names Changed:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_f_name_changes', 'fnColumns')}>
                                            {summaryData && summaryData.firstname_changes ? summaryData.firstname_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Last Names Changed:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_l_name_changes', 'lnColumns')}>
                                            {summaryData && summaryData.lastname_changes ? summaryData.lastname_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Job Numbers Changed:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_job_changes', 'jnColumns')}>
                                            {summaryData && summaryData.job_changes ? summaryData.job_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Vista Jobs not in FM:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_job_missing', 'jnColumns')}>
                                            {summaryData && summaryData.job_missing ? summaryData.job_missing : ''}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={5}>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-dark-left-lbl'}>
                                            Last Upload Date:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-dark-right-date-lbl'}>
                                            {summaryData && summaryData.upload_date ? moment(summaryData.upload_date).format('MM/DD/YYYY') : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            * Active FleetMatrix Users:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_fm_active', 'fmColumns')}>
                                            {summaryData && summaryData.active_fm_users ? summaryData.active_fm_users : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Phone Number Added:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_phone_add', 'pnColumns')}>
                                            {summaryData && summaryData.phone_blank ? summaryData.phone_blank : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Phone #s not matching Vista:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_phone_mismatch', 'pnColumns')}>
                                            {summaryData && summaryData.phone_diffs ? summaryData.phone_diffs : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Company Roles Added:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_role_blank', 'crColumns')}>
                                            {summaryData && summaryData.role_blank ? summaryData.role_blank : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Company Roles not Matching Vista:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_comp_role_changes', 'crColumns')}>
                                            {summaryData && summaryData.role_diffs ? summaryData.role_diffs : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Approved Driver Changed:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_ad_changes', 'adColumns')}>
                                            {summaryData && summaryData.approved_driver_changes ? summaryData.approved_driver_changes : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-left-lbl'}>
                                            Approved Drivers not Matching Vista:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_fm_ad_not_in_vista', 'adColumns')}>
                                            {summaryData && summaryData.fm_approved_driver_users_not_in_vista ? summaryData.fm_approved_driver_users_not_in_vista : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-dark-left-lbl'}>
                                            FM Users not in Vista:
                                        </Label>
                                    </Col>
                                    <Col sm={4}>
                                        <Label className={'uil-dark-right-lbl'} onClick={() => this.props.setTableData('old_fm_active_not_in_vista', 'fmColumns')}>
                                            {summaryData && summaryData.active_fm_users_not_in_vista ? summaryData.active_fm_users_not_in_vista : ''}
                                        </Label>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col sm={8}>
                                        <Label className={'uil-light-little-left-lbl'}>
                                            * Includes New and Reactivated Users
                                        </Label>
                                    </Col>
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col sm={8}>*/}
                                {/*        <Label className={'uil-dark-left-lbl'}>*/}
                                {/*            FM Users Updated:*/}
                                {/*        </Label>*/}
                                {/*    </Col>*/}
                                {/*    <Col sm={4}>*/}
                                {/*        <Label className={'uil-dark-right-lbl'}>*/}
                                {/*            <Label className={'uil-light-right-lbl'} onClick={() => this.props.setTableData('old_total', 'fmColumns')}>*/}
                                {/*                {summaryData && summaryData.total_affected_fm_users ? summaryData.total_affected_fm_users : ''}*/}
                                {/*            </Label>*/}
                                {/*        </Label>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </Col>
                            <Col sm={1}/>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const {authentication, user_preference} = state;
    const user = authentication.user;
    return {user, user_preference};
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (id) => dispatch(userActions.getDetail(id)),
        importUsers: (file) => dispatch(userActions.importUsers(file)),
    }
}

NewUpload.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(NewUpload);
