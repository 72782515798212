import React from 'react';
import {roleActions, taskActions, taskPriorityActions, vendorActions, workorderActions} from '../../../network/actions';
import {
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    UncontrolledTooltip
} from '../../../components/bot-components';
import {_dateOnlyFormat} from '../../../helpers';

export const mapStateToProps = (state) => {
    const {task, mechanics, vendors, workorder, task_priorities} = state;
    let mechList = mechanics?.items?.map(m => ({label: m.name, value: m.id}));
    let vendorList = vendors?.items?.map(v => ({label: v.name, value: v.id}));
    let priorityList = task_priorities?.items?.map(priority => ({label: priority.priority, value: priority.id}));

    return {task, mechanics, vendors, mechList, vendorList, workorder, priorityList}
};
export const mapDispatchToProps = (dispatch) => {
    return {
        getMechanics: () => dispatch(roleActions.getMechanics()),
        getVendors: () => dispatch(vendorActions.getAll()),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getMyWorkorder: (id) => dispatch(workorderActions.getDetail(id)),
        completeTask: (task) => dispatch(taskActions.updateTask(task)),
        assignToVendor: (task) => dispatch(taskActions.assignToVendor(task)),
        assignToMechanic: (task) => dispatch(taskActions.assignToMechanic(task)),
        arrivedAtVendor: (task) => dispatch(taskActions.arrivedAtVendor(task)),
        pickupUnit: (task) => dispatch(taskActions.pickup(task)),
        getTaskPriorities: () => dispatch(taskPriorityActions.getAll()),
    }
};
export const setVendor = (props, state, setState) => {
    const Task = {
        id: props.task.items.id,
        vendor: state.vendor_id !== null,
        vendor_id: state.vendor_id,
        mechanic_id: state.mechanic_id
    };
    props.isModal ? props.updateTask(Task) : props.assignToVendor(Task);
    setState({
        vendor_id: null,
        edit_provider: false
    });
};
export const setPriority = (props, state, setState) => {
    if (state.selectedPriority) {
        const Task = {
            id: props.task.items.id,
            task_priority_id: state.selectedPriority.value,
        };
        setState({
            edit_priority: false,
        });
        props.updateTask(Task);
    }
};
export const setMechanic = (props, state, setState) => {
    let assigned_to_mechanic = new Date();
    if (state.assigned_to_mechanic !== undefined) {
        assigned_to_mechanic = state.assigned_to_mechanic;
    }
    const Task = {
        id: props.task.items.id,
        mechanic_id: state.mechanic_id,
        vendor: state.vendor_id !== null,
        vendor_id: state.vendor_id,
        assigned_to_mechanic: state.mechanic_id !== null ? assigned_to_mechanic : null,
    };
    props.isModal ? props.updateTask(Task) : props.assignToMechanic(Task);
    setState({
        mechanic_id: null,
        edit_mechanic: false,
        edit_mechanic_date: false
    });
};
export const completeTask = (props, state, setState, toggle,value) => {
    if (props.task.items && value !== undefined) {
        setState({
            complete: value,
        });
        const Task = {
            id: props.task.items.id,
            complete: value,
            closing_message: state.closing_message,
        };
        setState({
            completed_on: undefined,
        });
        props.completeTask(Task);
        if (state.modal) {
            toggle();
        }
    }
};
export const toggle = (state, setState) => {
    setState({
        modal: !state.modal,
    })
};
export const closeTask = (state, setState) => {
    setState({
        modal_title: 'Close Task'
    });
    toggle(state, setState);
};
export const reopenTask = (state, setState) => {
    setState({
        modal_title: 'Reopen Task'
    });
    toggle(state, setState);
};
export const setCompletionDate = (props, task) => {
    if (props.task.items && task) {
        props.completeTask(task);
    }
};
export const changeProps = (e, setState, props, task) => {
    const {value, name} = e.target;
    if (name === 'est_complete_date') {
        setState({
            edit_comp_date: false,
            [name]: value,
        });
        const Task = {
            id: task.items.id,
            [name]: value,
        };
        setCompletionDate(props, Task);
    } else {
        setState({
            [name]: value,
        });
    }
};
export const createSelectInput = (prepend, prependText, options, isDisabled, onChange, value, edit_value, append_value, setState, setValue, cancelProps, state) => {
    return (
        <InputGroup style={{marginLeft: '-0.5rem', paddingTop: '0', paddingBottom: '0.5rem'}}>
            {prepend &&
            <InputGroupAddon addonType={'prepend'}><InputGroupText>{prependText}</InputGroupText></InputGroupAddon>}
            <Input type={'react-select'}
                   options={options}
                   isDisabled={isDisabled}
                   matchProp={'label'}
                   onChange={selectedValue => onChange(selectedValue)}
                   value={state[value]}
            />
            {!state[edit_value] &&
            <InputGroupAddon id={`${append_value}_edit`} addonType={'append'}><Button color={'texts'} outline onClick={() => setState({
                [edit_value]: true
            })}><i className={'fa fa-pencil'}/></Button></InputGroupAddon>}
            {state[edit_value] &&
            <InputGroupAddon id={`${append_value}_save`} addonType={'append'}><Button color={'texts'} outline onClick={setValue}><i className={'fa fa-check'}/></Button></InputGroupAddon>}
            {state[edit_value] &&
            <InputGroupAddon id={`${append_value}_cancel`} addonType={'append'}><Button color={'texts'} outline onClick={() => setState({
                [edit_value]: false,
                [value]: cancelProps
            })}><i className={'fa fa-times'}/></Button></InputGroupAddon>}
            {!state[edit_value] &&
            <UncontrolledTooltip delay={{show: 500, hide: 1}} placement={'right'} target={`${append_value}_edit`}>Click here to edit {prependText}</UncontrolledTooltip>}
            {state[edit_value] &&
            <UncontrolledTooltip delay={{show: 500, hide: 1}} placement={'right'} target={`${append_value}_save`}>Click here to save {prependText}</UncontrolledTooltip>}
            {state[edit_value] &&
            <UncontrolledTooltip delay={{show: 500, hide: 1}} placement={'right'} target={`${append_value}_cancel`}>Click here to cancel editing {prependText}</UncontrolledTooltip>}
        </InputGroup>
    );
};
export const createDateInput = (prepend, prependText, disabled, value, edit_value, append_value, setState, setValue, state, props, task) => {
    return (
        <InputGroup style={{marginLeft: '-0.5rem', paddingTop: '0', paddingBottom: '0.5rem'}}>
            {prepend && <InputGroupAddon addonType={'prepend'}><InputGroupText>{prependText}</InputGroupText></InputGroupAddon>}
            <Input type={'date'} name={'assigned_to_mechanic'}
                   disabled={disabled}
                   defaultValue={_dateOnlyFormat(state[value])}
                   onChange={(e) => changeProps(e, setState, props, task)}/>
            {!state[edit_value] &&
            <InputGroupAddon id={`${append_value}_edit`} addonType={'append'}><Button color={'texts'} outline onClick={() => setState({[edit_value]: true})}><i className={'fa fa-pencil'}/></Button></InputGroupAddon>}
            {state[edit_value] &&
            <InputGroupAddon id={`${append_value}_save`} addonType={'append'}><Button color={'texts'} outline onClick={() => setValue()}><i className={'fa fa-check'}/></Button></InputGroupAddon>}
            {state[edit_value] &&
            <InputGroupAddon id={`${append_value}_cancel`} addonType={'append'}><Button color={'texts'} outline onClick={() => setState({[edit_value]: false})}><i className={'fa fa-times'}/></Button></InputGroupAddon>}
            {!state[edit_value] &&
            <UncontrolledTooltip placement={'right'} target={`${append_value}_edit`}>Click here to edit {prependText}</UncontrolledTooltip>}
            {state[edit_value] &&
            <UncontrolledTooltip placement={'right'} target={`${append_value}_save`}>Click here to save {prependText}</UncontrolledTooltip>}
            {state[edit_value] &&
            <UncontrolledTooltip placement={'right'} target={`${append_value}_cancel`}>Click here to cancel editing {prependText}</UncontrolledTooltip>}
        </InputGroup>
    );
};
