export const unitStatusConstants = {
    CREATE_UNIT_STATUS_REQUEST: 'UNIT_STATUS_CREATE_REQUEST',
    CREATE_UNIT_STATUS_SUCCESS: 'UNIT_STATUS_CREATE_SUCCESS',
    CREATE_UNIT_STATUS_FAILURE: 'UNIT_STATUS_CREATE_FAILURE',

    GET_ALL_UNIT_STATUSES_REQUEST: 'UNIT_STATUSES_GET_ALL_REQUEST',
    GET_ALL_UNIT_STATUSES_SUCCESS: 'UNIT_STATUSES_GET_ALL_SUCCESS',
    GET_ALL_UNIT_STATUSES_FAILURE: 'UNIT_STATUSES_GET_ALL_FAILURE',

    DETAIL_UNIT_STATUS_REQUEST: 'UNIT_STATUS_DETAIL_REQUEST',
    DETAIL_UNIT_STATUS_SUCCESS: 'UNIT_STATUS_DETAIL_SUCCESS',
    DETAIL_UNIT_STATUS_FAILURE: 'UNIT_STATUS_DETAIL_FAILURE',
};
