import React, { useState, useEffect, Fragment } from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from '../components/bot-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {AppSwitch} from '../components/';
import {connect} from 'react-redux';
import {userPreferenceActions} from "../network/actions/";
// import {workorderActions} from "../network/actions/workorder.actions";

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};

const defaultState = {
    activeTab: '1',
    new_workorders: false,
    old_workorders: false,
    open_workorders: false,
    created_units: false,
    keyboardNav: false
};

const Aside = (props) => {
    const [state, updateState] = useState(defaultState);

    /**
     *
     * @param {Object} newState
     */
    const setState = newState => updateState(prevState => ({
        ...prevState,
        ...newState
    }));

    const toggle = tab => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab,
            });
        }
    };

    const togglePrefs = e => {
        const {name, checked} = e.target;
        console.log(name);
        console.log(checked);
        if (props.user_preference.items) {
            const user_preference = {
                user_id: props.user_preference.items.user_id,
                [name]: checked,
            };
            setState({
                [name]: checked,
            });
            props.updateUserPreference(user_preference);
        }
    };

    const toggleKeyboardNav = (e) => {
        const {checked} = e.target;
        const prefs = {
            ...props.user_preference.items,
            web_settings: {
                ...props.user_preference.items?.web_settings,
                keyboardNav: checked
            },
            user_id: props.user.user?.id
        }
        setState({
            keyboardNav: checked
        });
        props.updateUserPreference(prefs);
    };

    useEffect(() => {
        if (props.user) {
            props.getPreferences(props.user.user?.id);
        }
    }, []);

    useEffect(() => {
        const {user_preference} = props;
        user_preference?.items === {} && props.getPreferences(props.user.user?.id);
        if (user_preference?.items) {
            if (user_preference.items.new_workorders !== state.new_workorders) {
                const user_prefs = user_preference.items;
                setState({
                    new_workorders: user_prefs.new_workorders,
                    old_workorders: user_prefs.old_workorders,
                    open_workorders: user_prefs.open_workorders,
                    keyboardNav: user_prefs.web_settings?.keyboardNav ?? false
                });
            }
        }
    }, [props.user_preference]);

    const {new_workorders, old_workorders, open_workorders, keyboardNav} = state;

    return (
      <Fragment>
          <Nav tabs>
              <NavItem>
                  <NavLink className={classNames({active: state.activeTab === '1'})}
                           onClick={() => toggle('1')}>
                      <i style={{border: '0 !important'}} className={'configuration-icon'}/>
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className={classNames({active: state.activeTab === '2'})}
                           onClick={() => toggle('2')}>
                      <i className={'filter-list-icon'}/>
                  </NavLink>
              </NavItem>
          </Nav>
          <TabContent activetab={state.activeTab}>
              <TabPane tabId="1" className="p-3">
                  <h6 style={{fontWeight: 'bold', fontStyle: 'italic'}}>Dashboard Settings</h6>

                  <div className="aside-options">
                      <div className="clearfix mt-4">
                          <small><b>Keyboard Navigation: </b>
                          </small>
                          <AppSwitch name={'keyboardNav'} className={'float-right'} variant={'pill'} label
                                     color={'success'} onChange={toggleKeyboardNav}
                                     checked={keyboardNav} size={'sm'}/>
                      </div>
                      <div>
                          <small className="text-muted">Enable scrolling tables using left and right arrow keys.
                          </small>
                      </div>
                  </div>

                  <div className="aside-options">
                      <div className="clearfix mt-4">
                          <small><b>New Workorders: {props.open_count?.items &&
                            <span style={{fontSize: 16}}>{props.open_count?.items['new']}</span>}</b>
                          </small>
                          <AppSwitch name={'new_workorders'} className={'float-right'} variant={'pill'} label
                                     color={'success'} onChange={togglePrefs}
                                     checked={new_workorders} size={'sm'}/>
                      </div>
                      <div>
                          <small className="text-muted">Show table with all workorders created within the last 24
                              hours.
                          </small>
                      </div>
                  </div>

                  <div className="aside-options">
                      <div className="clearfix mt-3">
                          <small><b>Old Workorders: {props.open_count?.items &&
                            <span style={{fontSize: 16}}>{props.open_count?.items['old']}</span>}</b>
                          </small>
                          <AppSwitch name={'old_workorders'} className={'float-right'} variant={'pill'} label
                                     color={'success'} onChange={togglePrefs}
                                     checked={old_workorders} size={'sm'}/>
                      </div>
                      <div>
                          <small className="text-muted">Show table with workorders older than 10 days.
                          </small>
                      </div>
                  </div>

                  <div className="aside-options">
                      <div className="clearfix mt-3">
                          <small><b>Open Workorders: {props.open_count?.items &&
                            <span style={{fontSize: 16}}>{props.open_count?.items['total']}</span>}</b>
                          </small>
                          <AppSwitch name={'open_workorders'} className={'float-right'} variant={'pill'} label
                                     color={'success'} onChange={togglePrefs}
                                     checked={open_workorders} size={'sm'}/>
                          <div>
                              <small className="text-muted">Show table with all current open workorders.</small>
                          </div>
                      </div>
                  </div>
              </TabPane>
              <TabPane tabId={'2'} className={'p3'}>
                  <div id={'filter-control-container'}/>
              </TabPane>
          </TabContent>
      </Fragment>
    );
};

Aside.propTypes = propTypes;
Aside.defaultProps = defaultProps;

const mapStateToProps = state => {
    const {authentication, user_preference, open_count} = state;
    const {user} = authentication;
    return {user_preference, user, open_count};
};

const mapDispatchToProps = dispatch => {
    return {
        getPreferences: (id) => dispatch(userPreferenceActions.getUserPreference(id)),
        updateUserPreference: (user_preference) => dispatch(userPreferenceActions.updateUserPreference(user_preference)),
        // getOpenCount: () => dispatch(workorderActions.getOpenCount()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Aside);
