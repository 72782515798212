export const faultConstants = {
    CREATE_FAULT_REQUEST: 'FAULT_CREATE_REQUEST',
    CREATE_FAULT_SUCCESS: 'FAULT_CREATE_SUCCESS',
    CREATE_FAULT_FAILURE: 'FAULT_CREATE_FAILURE',

    UPDATE_FAULT_REQUEST: 'FAULT_UPDATE_REQUEST',
    UPDATE_FAULT_SUCCESS: 'FAULT_UPDATE_SUCCESS',
    UPDATE_FAULT_FAILURE: 'FAULT_UPDATE_FAILURE',

    GET_UNASSIGNED_FAULTS_REQUEST: 'FAULTS_GET_UNASSIGNED_REQUEST',
    GET_UNASSIGNED_FAULTS_SUCCESS: 'FAULTS_GET_UNASSIGNED_SUCCESS',
    GET_UNASSIGNED_FAULTS_FAILURE: 'FAULTS_GET_UNASSIGNED_FAILURE',

    GET_ALL_FAULTS_REQUEST: 'FAULTS_GET_ALL_REQUEST',
    GET_ALL_FAULTS_SUCCESS: 'FAULTS_GET_ALL_SUCCESS',
    GET_ALL_FAULTS_FAILURE: 'FAULTS_GET_ALL_FAILURE',

    DETAIL_FAULT_REQUEST: 'FAULT_DETAIL_REQUEST',
    DETAIL_FAULT_SUCCESS: 'FAULT_DETAIL_SUCCESS',
    DETAIL_FAULT_FAILURE: 'FAULT_DETAIL_FAILURE',
};
