import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CardBody, CardFooter,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from '../../components/bot-components';
const propTypes = {
    vendor: PropTypes.object,
    toggle: PropTypes.func
}
const VendorEdit = ({vendor, toggle, updateVendor}) => {
    const [state, updateState] = React.useState({
        ...vendor.items
    });
    const setState = newState => updateState((prevState) => ({
        ...prevState,
        ...newState
    }));
    const changeProps = (e) => {
        const {name, value} = e.target;
        setState({
            [name]: value
        });
    };
    const {name, address, contact_name, city, zip_code, phone_number} = state;
    return(
        <>
            <CardBody>
                <Row>
                    <Col sm={6}>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Name</InputGroupText></InputGroupAddon>
                            <Input name={'name'} value={name} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Address</InputGroupText></InputGroupAddon>
                            <Input name={'address'} value={address} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>City</InputGroupText></InputGroupAddon>
                            <Input name={'city'} value={city} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Contact</InputGroupText></InputGroupAddon>
                            <Input name={'contact_name'} value={contact_name} onChange={changeProps}/>
                        </InputGroup>
                    </Col>
                    <Col sm={6}>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>State</InputGroupText></InputGroupAddon>
                            <Input name={'state'} value={state.state} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Zip</InputGroupText></InputGroupAddon>
                            <Input name={'zip_code'} value={zip_code} onChange={changeProps}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroupAddon addonType={'prepend'}><InputGroupText>Phone</InputGroupText></InputGroupAddon>
                            <Input name={'phone_number'} value={phone_number} onChange={changeProps}/>
                        </InputGroup>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className={'user-edit-footer'}>
                <Button outline color={'texts'} onClick={toggle}>Cancel</Button><Button outline color={'texts'} onClick={() => {
                    toggle();
                    updateVendor(state);
            }}>Save</Button>
            </CardFooter>
        </>
    )
};
VendorEdit.propTypes = propTypes;
export default VendorEdit;
